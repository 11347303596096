export const FLOWS = {
  AuthStack: {
    id: 0,
    name: 'AuthStack',
  },
  OnboardingStack: {
    id: 1,
    name: 'OnboardingStack',
  },
  PostOnboardingStack: {
    id: 2,
    name: 'PostOnboardingStack',
  },
  MainStack: {
    id: 3,
    name: 'MainStack',
  },
}

export const NAVIGATORS = {
  AuthNavigator: {
    id: 0,
    name: 'AuthNavigator',
  },
  SettingsNavigator: {
    id: 1,
    name: 'SettingsNavigator',
  },
  MainNavigator: {
    id: 2,
    name: 'MainNavigator',
  },
  SignupFamilyInviteNavigator: {
    id: 3,
    name: 'SignupFamilyInviteNavigator',
  },
  SignupClinicianNavigator: {
    id: 4,
    name: 'SignupClinicianNavigator',
  },
  SignupFamilySharingSettingsNavigator: {
    id: 5,
    name: 'SignupFamilySharingSettingsNavigator',
  },
  PersonalDetailsNavigator: {
    id: 6,
    name: 'PersonalDetailsNavigator',
  },
  RelativesNavigator: {
    id: 7,
    name: 'RelativesNavigator',
  },
  RelationshipInfoTutorialNavigator: {
    id: 8,
    name: 'RelationshipInfoTutorialNavigator',
  },
  MainStack: {
    id: 9,
    name: 'MainStack',
  },
  RelativeDiseasesNavigator: {
    id: 10,
    name: 'RelativeDiseasesNavigator',
  },
  RelativeGeneTestNavigator: {
    id: 11,
    name: 'RelativeGeneTestNavigator',
  },
  SelectParentNavigator: {
    id: 12,
    name: 'SelectParentNavigator',
  },
  SelectParentBloodRelationNavigator: {
    id: 13,
    name: 'SelectParentBloodRelationNavigator',
  },
  SelectTwinNavigator: {
    id: 14,
    name: 'SelectTwinNavigator',
  },
  Main: {
    id: 15,
    name: 'Main',
  },
  BottomTabNavigator: {
    id: 16,
    name: 'BottomTabNavigator',
  },
  Home: {
    id: 17,
    name: 'Home',
  },
  Family: {
    id: 18,
    name: 'Family',
  },
  Risks: {
    id: 19,
    name: 'Risks',
  },
  Surveys: {
    id: 20,
    name: 'Surveys',
  },
  AddMemberNavigator: {
    id: 21,
    name: 'AddMemberNavigator',
  },
  InviteFamilyMemberNavigator: {
    id: 22,
    name: 'InviteFamilyMemberNavigator',
  },
  SurveyCancerNavigator: {
    id: 23,
    name: 'SurveyCancerNavigator',
  },
  SurveyCustomNavigator: {
    id: 24,
    name: 'SurveyCustomNavigator',
  },
  GeneralSettingsNavigator: {
    id: 25,
    name: 'GeneralSettingsNavigator',
  },
  ChangePasswordNavigator: {
    id: 26,
    name: 'ChangePasswordNavigator',
  },
  UpdatePhoneNumberNavigator: {
    id: 27,
    name: 'UpdatePhoneNumberNavigator',
  },
  ResearchDataSettingsNavigator: {
    id: 28,
    name: 'ResearchDataSettingsNavigator',
  },
  ContactUsNavigator: {
    id: 29,
    name: 'ContactUsNavigator',
  },
  LoginSettings: {
    id: 30,
    name: 'LoginSettings',
  },
  SubmitIdeas: {
    id: 31,
    name: 'SubmitIdeas',
  },
  AddClinicianNavigator: {
    id: 32,
    name: 'AddClinicianNavigator',
  },
  SurveyCustom: {
    id: 33,
    name: 'SurveyCustom',
  },
  CustomScreen: {
    id: 34,
    name: 'CustomScreen',
  },
}

export const ROUTES = {
  CustomLoginScreen: {
    id: 0,
    name: 'CustomLoginScreen',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  WelcomeScreen: {
    id: 1,
    name: 'WelcomeScreen',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  CustomValidateDOB: {
    id: 2,
    name: 'CustomValidateDOB',
    stacks: [NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  CustomCreatePassword: {
    id: 3,
    name: 'CustomCreatePassword',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  CustomInputScreen: {
    id: 4,
    name: 'CustomInputScreen',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  SignupMainScreen: {
    id: 5,
    name: 'SignupMainScreen',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  SignupScreenPhone: {
    id: 6,
    name: 'SignupScreenPhone',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  SignupScreenPhoneCode: {
    id: 7,
    name: 'SignupScreenPhoneCode',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  ForgotPWSelectOption: {
    id: 8,
    name: 'ForgotPWSelectOption',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  ForgotPWEnterEmail: {
    id: 9,
    name: 'ForgotPWEnterEmail',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  ForgotPWEnterPhoneNumber: {
    id: 10,
    name: 'ForgotPWEnterPhoneNumber',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  ForgotPWEnterCode: {
    id: 11,
    name: 'ForgotPWEnterCode',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  ForgotPWEnterNewPassword: {
    id: 12,
    name: 'ForgotPWEnterNewPassword',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  EnvSecretScreen: {
    id: 13,
    name: 'EnvSecretScreen',
    stacks: [NAVIGATORS.MainNavigator, NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  SignupFamilyInviteCode: {
    id: 14,
    name: 'SignupFamilyInviteCode',
    stacks: [
      NAVIGATORS.MainNavigator,
      NAVIGATORS.AuthNavigator,
      NAVIGATORS.SignupFamilyInviteNavigator,
    ],
    flows: [FLOWS.AuthStack],
  },
  SignupFamilyInvitePhoneCode: {
    id: 15,
    name: 'SignupFamilyInvitePhoneCode',
    stacks: [
      NAVIGATORS.MainNavigator,
      NAVIGATORS.AuthNavigator,
      NAVIGATORS.SignupFamilyInviteNavigator,
    ],
    flows: [FLOWS.AuthStack],
  },
  SuccessScreenFamilyInvite: {
    id: 16,
    name: 'SuccessScreenFamilyInvite',
    stacks: [NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  SignupClinicianCode: {
    id: 17,
    name: 'SignupClinicianCode',
    stacks: [NAVIGATORS.AuthNavigator, NAVIGATORS.SignupClinicianNavigator],
    flows: [FLOWS.AuthStack],
  },
  SignupFamilySharingSettingsMain: {
    id: 18,
    name: 'SignupFamilySharingSettingsMain',
    stacks: [
      NAVIGATORS.AuthNavigator,
      NAVIGATORS.SignupFamilySharingSettingsNavigator,
    ],
    flows: [FLOWS.AuthStack],
  },
  SignupFamilySharingSettingsDetail: {
    id: 19,
    name: 'SignupFamilySharingSettingsDetail',
    stacks: [
      NAVIGATORS.AuthNavigator,
      NAVIGATORS.SignupFamilySharingSettingsNavigator,
    ],
    flows: [FLOWS.AuthStack],
  },
  SuccessScreenAccountCreated: {
    id: 20,
    name: 'SuccessScreenAccountCreated',
    stacks: [NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  SuccessScreenResetPassword: {
    id: 21,
    name: 'SuccessScreenResetPassword',
    stacks: [NAVIGATORS.AuthNavigator],
    flows: [FLOWS.AuthStack],
  },
  SelectLanguage: {
    id: 22,
    name: 'SelectLanguage',
    stacks: [NAVIGATORS.SettingsNavigator],
    flows: [FLOWS.AuthStack],
  },
  SuccessScreenPersonalHealthInfo: {
    id: 23,
    name: 'SuccessScreenPersonalHealthInfo',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalGender: {
    id: 24,
    name: 'PersonalGender',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalGenderIdentity: {
    id: 25,
    name: 'PersonalGenderIdentity',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalDiagnosis: {
    id: 26,
    name: 'PersonalDiagnosis',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalGeneticTesting: {
    id: 27,
    name: 'PersonalGeneticTesting',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalScreenDiagnosisC: {
    id: 28,
    name: 'PersonalScreenDiagnosisC',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalScreenDiagnosisD: {
    id: 29,
    name: 'PersonalScreenDiagnosisD',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalEthnicity: {
    id: 30,
    name: 'PersonalEthnicity',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalAncestry: {
    id: 31,
    name: 'PersonalAncestry',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  skipLogic: {
    id: 32,
    name: 'skipLogic',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  DiseaseListMainScreen: {
    id: 33,
    name: 'DiseaseListMainScreen',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  OtherDiseasesList: {
    id: 34,
    name: 'OtherDiseasesList',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  DiseaseSkipLogic: {
    id: 35,
    name: 'DiseaseSkipLogic',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  ParentsGrandparentsList: {
    id: 36,
    name: 'ParentsGrandparentsList',
    stacks: [NAVIGATORS.RelativesNavigator, NAVIGATORS.MainStack],
    flows: [FLOWS.PostOnboardingStack],
  },
  ChildrenList: {
    id: 37,
    name: 'ChildrenList',
    stacks: [NAVIGATORS.RelativesNavigator, NAVIGATORS.MainStack],
    flows: [FLOWS.PostOnboardingStack],
  },
  SiblingsList: {
    id: 38,
    name: 'SiblingsList',
    stacks: [NAVIGATORS.RelativesNavigator, NAVIGATORS.MainStack],
    flows: [FLOWS.PostOnboardingStack],
  },
  PaternalAuntsUnclesList: {
    id: 39,
    name: 'PaternalAuntsUnclesList',
    stacks: [NAVIGATORS.RelativesNavigator, NAVIGATORS.MainStack],
    flows: [FLOWS.PostOnboardingStack],
  },
  MaternalAuntsUnclesList: {
    id: 40,
    name: 'MaternalAuntsUnclesList',
    stacks: [NAVIGATORS.RelativesNavigator, NAVIGATORS.MainStack],
    flows: [FLOWS.PostOnboardingStack],
  },
  EditProfileView: {
    id: 41,
    name: 'EditProfileView',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.MainStack,
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Family,
    ],
    flows: [FLOWS.PostOnboardingStack, FLOWS.MainStack],
  },
  DiseasesTypeView: {
    id: 42,
    name: 'DiseasesTypeView',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.MainStack,
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Family,
    ],
    flows: [FLOWS.PostOnboardingStack, FLOWS.MainStack],
  },
  DiseasesListView: {
    id: 43,
    name: 'DiseasesListView',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.MainStack,
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Family,
    ],
    flows: [FLOWS.PostOnboardingStack, FLOWS.MainStack],
  },
  DiseasesSkipLogicView: {
    id: 44,
    name: 'DiseasesSkipLogicView',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.MainStack,
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Family,
    ],
    flows: [FLOWS.PostOnboardingStack, FLOWS.MainStack],
  },
  PositiveGeneTestingMain: {
    id: 45,
    name: 'PositiveGeneTestingMain',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.MainStack,
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Family,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  NegativeGeneTesting: {
    id: 46,
    name: 'NegativeGeneTesting',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.MainStack,
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Family,
    ],
    flows: [FLOWS.PostOnboardingStack, FLOWS.MainStack],
  },
  DiseaseTypesMain: {
    id: 47,
    name: 'DiseaseTypesMain',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.Main,
      NAVIGATORS.RelativeDiseasesNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  DiseasesList: {
    id: 48,
    name: 'DiseasesList',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.Main,
      NAVIGATORS.RelativeDiseasesNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  EditMemberDisease: {
    id: 49,
    name: 'EditMemberDisease',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.Main,
      NAVIGATORS.RelativeDiseasesNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  RelativePositiveGeneTestScreen: {
    id: 50,
    name: 'RelativePositiveGeneTestScreen',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.RelativeGeneTestNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  RelativeNegativeGeneTestScreen: {
    id: 51,
    name: 'RelativeNegativeGeneTestScreen',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.RelativeGeneTestNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  SelectParent: {
    id: 52,
    name: 'SelectParent',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.Main,
      NAVIGATORS.SelectParentNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  SelectParentsBloodRelation: {
    id: 53,
    name: 'SelectParentsBloodRelation',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.Main,
      NAVIGATORS.SelectParentBloodRelationNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  SelectTwin: {
    id: 54,
    name: 'SelectTwin',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.Main,
      NAVIGATORS.SelectTwinNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  SelectTwinType: {
    id: 55,
    name: 'SelectTwinType',
    stacks: [
      NAVIGATORS.RelativesNavigator,
      NAVIGATORS.Main,
      NAVIGATORS.SelectTwinNavigator,
    ],
    flows: [FLOWS.PostOnboardingStack],
  },
  RelationshipInfoTutorial: {
    id: 56,
    name: 'RelationshipInfoTutorial',
    stacks: [NAVIGATORS.RelationshipInfoTutorialNavigator],
    flows: [FLOWS.PostOnboardingStack],
  },
  DashboardTab: {
    id: 57,
    name: 'DashboardTab',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  FamilyMainView: {
    id: 58,
    name: 'FamilyMainView',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  PositiveGeneTesting: {
    id: 59,
    name: 'PositiveGeneTesting',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  RisksScreenView: {
    id: 60,
    name: 'RisksScreenView',
    stacks: [
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.PersonalDetailsNavigator,
    ],
    flows: [FLOWS.MainStack, FLOWS.OnboardingStack],
  },
  RiskInfoView: {
    id: 61,
    name: 'RiskInfoView',
    stacks: [
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Risks,
    ],
    flows: [FLOWS.MainStack],
  },
  SurveyScreenView: {
    id: 62,
    name: 'SurveyScreenView',
    stacks: [
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Family,
    ],
    flows: [FLOWS.MainStack],
  },
  SharingSettingsMain: {
    id: 63,
    name: 'SharingSettingsMain',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  SharingSettingsDetail: {
    id: 64,
    name: 'SharingSettingsDetail',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  ClinicianListView: {
    id: 65,
    name: 'ClinicianListView',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  ClinicianDetailsView: {
    id: 66,
    name: 'ClinicianDetailsView',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  GeneticResources: {
    id: 67,
    name: 'GeneticResources',
    stacks: [
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Risks,
      NAVIGATORS.Risks,
    ],
    flows: [FLOWS.MainStack],
  },
  GeneticResourcesWebView: {
    id: 68,
    name: 'GeneticResourcesWebView',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  NotificationsMain: {
    id: 69,
    name: 'NotificationsMain',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  NotificationDetail: {
    id: 70,
    name: 'NotificationDetail',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  InviteMain: {
    id: 71,
    name: 'InviteMain',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  InviteFamilyMemberMain: {
    id: 72,
    name: 'InviteFamilyMemberMain',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Home],
    flows: [FLOWS.MainStack],
  },
  CustomWebView: {
    id: 73,
    name: 'CustomWebView',
    stacks: [
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Home,
      NAVIGATORS.Risks,
    ],
    flows: [FLOWS.MainStack],
  },
  FamilyMainViewTab: {
    id: 74,
    name: 'FamilyMainViewTab',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Family],
    flows: [FLOWS.MainStack],
  },
  RisksTab: {
    id: 75,
    name: 'RisksTab',
    stacks: [NAVIGATORS.Main, NAVIGATORS.BottomTabNavigator, NAVIGATORS.Risks],
    flows: [FLOWS.MainStack],
  },
  SurveysTab: {
    id: 76,
    name: 'SurveysTab',
    stacks: [
      NAVIGATORS.Main,
      NAVIGATORS.BottomTabNavigator,
      NAVIGATORS.Surveys,
    ],
    flows: [FLOWS.MainStack],
  },
  AddFamilyMemberView: {
    id: 77,
    name: 'AddFamilyMemberView',
    stacks: [NAVIGATORS.Main, NAVIGATORS.AddMemberNavigator],
    flows: [FLOWS.MainStack],
  },
  InviteFamilyMember: {
    id: 78,
    name: 'InviteFamilyMember',
    stacks: [NAVIGATORS.Main, NAVIGATORS.InviteFamilyMemberNavigator],
    flows: [FLOWS.MainStack],
  },
  SurveyCancer: {
    id: 79,
    name: 'SurveyCancer',
    stacks: [NAVIGATORS.Main, NAVIGATORS.SurveyCancerNavigator],
    flows: [FLOWS.MainStack],
  },
  SurveyCustom: {
    id: 80,
    name: 'SurveyCustom',
    stacks: [
      NAVIGATORS.Main,
      NAVIGATORS.SurveyCustomNavigator,
      NAVIGATORS.SurveyCustom,
      NAVIGATORS.CustomScreen,
    ],
    flows: [FLOWS.MainStack, FLOWS.OnboardingStack],
  },
  ViewOnWeb: {
    id: 81,
    name: 'ViewOnWeb',
    stacks: [NAVIGATORS.Main, NAVIGATORS.GeneralSettingsNavigator],
    flows: [FLOWS.MainStack],
  },
  UpdatePhoneNumber: {
    id: 82,
    name: 'UpdatePhoneNumber',
    stacks: [NAVIGATORS.Main, NAVIGATORS.UpdatePhoneNumberNavigator],
    flows: [FLOWS.MainStack],
  },
  ResearchDataSettings: {
    id: 83,
    name: 'ResearchDataSettings',
    stacks: [NAVIGATORS.Main, NAVIGATORS.ResearchDataSettingsNavigator],
    flows: [FLOWS.MainStack],
  },
  ContactUs: {
    id: 84,
    name: 'ContactUs',
    stacks: [NAVIGATORS.Main, NAVIGATORS.ContactUsNavigator],
    flows: [FLOWS.MainStack],
  },
  BiometricsSettings: {
    id: 85,
    name: 'BiometricsSettings',
    stacks: [NAVIGATORS.Main, NAVIGATORS.LoginSettings],
    flows: [FLOWS.MainStack],
  },
  SubmitIdeasSettings: {
    id: 86,
    name: 'SubmitIdeasSettings',
    stacks: [NAVIGATORS.Main, NAVIGATORS.SubmitIdeas],
    flows: [FLOWS.MainStack],
  },
  AddClinicianCode: {
    id: 87,
    name: 'AddClinicianCode',
    stacks: [NAVIGATORS.Main, NAVIGATORS.AddClinicianNavigator],
    flows: [FLOWS.MainStack],
  },
  FamilyMembersCancer: {
    id: 88,
    name: 'FamilyMembersCancer',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  AddFamilyMemberViewCancer: {
    id: 89,
    name: 'AddFamilyMemberViewCancer',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  FamilyMainViewCancer: {
    id: 90,
    name: 'FamilyMainViewCancer',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  EditProfileCancer: {
    id: 91,
    name: 'EditProfileCancer',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  CustomSuccessScreen: {
    id: 92,
    name: 'CustomSuccessScreen',
    stacks: [NAVIGATORS.MainStack],
    flows: [FLOWS.MainStack],
  },
  SwitchToFamgenixAccount: {
    id: 93,
    name: 'SwitchToFamgenixAccount',
    stacks: [NAVIGATORS.MainStack, NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.MainStack],
  },
  OnboardingNegativeGeneTesting: {
    id: 94,
    name: 'OnboardingNegativeGeneTesting',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  OnboardingPositiveGeneTesting: {
    id: 95,
    name: 'OnboardingPositiveGeneTesting',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  DiseasesTypeViewCancer: {
    id: 96,
    name: 'DiseasesTypeViewCancer',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  DiseaseListMainScreenCancer: {
    id: 97,
    name: 'DiseaseListMainScreenCancer',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
  PersonalGeneticTestingCancer: {
    id: 98,
    name: 'PersonalGeneticTestingCancer',
    stacks: [NAVIGATORS.PersonalDetailsNavigator],
    flows: [FLOWS.OnboardingStack],
  },
}

export const CUSTOM_FLOW_ROUTES = {
  SuccessScreenAccountCreated: {
    ...ROUTES.SuccessScreenAccountCreated,
  },

  SuccessScreenPersonalHealthInfo: {
    ...ROUTES.SuccessScreenPersonalHealthInfo,
  },

  PersonalGender: {
    ...ROUTES.PersonalGender,
  },

  PersonalGenderIdentity: {
    ...ROUTES.PersonalGenderIdentity,
  },

  PersonalGeneticTesting: {
    ...ROUTES.PersonalGeneticTesting,
  },

  PersonalEthnicity: {
    ...ROUTES.PersonalEthnicity,
  },

  DiseaseListMainScreen: {
    ...ROUTES.DiseaseListMainScreen,
  },

  PersonalAncestry: {
    ...ROUTES.PersonalAncestry,
  },

  ParentsGrandparentsList: {
    ...ROUTES.ParentsGrandparentsList,
  },
  // TODO: determine is we show or not the back arrow by default

  SurveyCustom: {
    ...ROUTES.SurveyCustom,
  },

  FamilyMembersCancer: {
    ...ROUTES.FamilyMembersCancer,
  },

  AddFamilyMemberViewCancer: {
    ...ROUTES.AddFamilyMemberViewCancer,
  },

  FamilyMainViewCancer: {
    ...ROUTES.FamilyMainViewCancer,
  },

  EditProfileCancer: {
    ...ROUTES.EditProfileCancer,
  },

  PersonalGeneticTestingCancer: {
    ...ROUTES.PersonalGeneticTestingCancer,
  },

  DiseaseListMainScreenCancer: {
    ...ROUTES.DiseaseListMainScreenCancer,
  },

  DiseasesTypeViewCancer: {
    ...ROUTES.DiseasesTypeViewCancer,
  },

  RisksScreenView: {
    ...ROUTES.RisksScreenView,
  },
  CustomSuccessScreen: {
    ...ROUTES.CustomSuccessScreen,
  },
}

export default ROUTES
