import Constants from 'expo-constants'

import { IS_WEB, IS_IOS, IS_ANDROID } from '$utils/Platforms.js'
import { envConstants } from '$constants'

export const {
  HIDE_RISK,
  SHOW_DOCUMENTS,
  GENETIC_RESOURCES_URL,
  SHOW_NEGATIVE_TEST,
  SHOW_POWERED_BY_LOGO,
  CUSTOM_SURVEY_URL,
  MULTI_LANGUAGE,
  BASE_URL,
  SHOW_NETWORK_LOGS,
  SHOW_ONLY_CONSOLE_LOGS,
  WEB_BUILD_NUMBER,
} = envConstants

const now = new Date(Date.now())
const dateString = now.toLocaleString()

function getAppBuildNumber() {
  if (IS_WEB()) return WEB_BUILD_NUMBER || dateString
  if (IS_IOS()) return Constants.expoConfig.ios.buildNumber
  if (IS_ANDROID()) return Constants.expoConfig.android.versionCode

  return dateString
}

export const APP_BUILD_NUMBER = getAppBuildNumber()

function logEnvVariables() {
  if (!__DEV__) return

  console.groupCollapsed('%capp.json extra (env constants):', 'color: green')
  console.log(`HIDE_RISK:\n  ${HIDE_RISK}\n`)
  console.log(`SHOW_DOCUMENTS:\n  ${SHOW_DOCUMENTS}\n`)
  console.log(`GENETIC_RESOURCES_URL:\n  ${GENETIC_RESOURCES_URL}\n`)
  console.log(`SHOW_NEGATIVE_TEST:\n  ${SHOW_NEGATIVE_TEST}\n`)
  console.log(`SHOW_POWERED_BY_LOGO:\n  ${SHOW_POWERED_BY_LOGO}\n`)
  console.log(`CUSTOM_SURVEY_URL:\n  ${CUSTOM_SURVEY_URL}\n`)
  console.log(`MULTI_LANGUAGE:\n  ${MULTI_LANGUAGE}\n`)
  console.log(`BASE_URL:\n  ${BASE_URL}\n`)
  console.log(`APP_BUILD_NUMBER:\n  ${APP_BUILD_NUMBER}`)
  console.log(`SHOW_NETWORK_LOGS:\n  ${SHOW_NETWORK_LOGS}`)
  console.log(`SHOW_ONLY_CONSOLE_LOGS:\n  ${SHOW_ONLY_CONSOLE_LOGS}`)
  console.groupEnd()
}

logEnvVariables()
