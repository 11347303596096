import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  image: {
    width: 120,
    height: 120,
    resizeMode: 'contain',
    marginTop: 50,
  },
})

export default styles
