import { View } from 'react-native'
import React, { useRef } from 'react'
import styles from '../main.styles'
import { i18n } from '$localization/config.js'
import { FIELD_NAMES } from '$components/SignupCustomInput/constants'
import SignupCustomInput from '$components/SignupCustomInput'

function LoginInputs({
  hasUserEmailEntered,
  email,
  setEmail,
  password,
  setPassword,
}) {
  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  return (
    <View style={styles.signUpContainer}>
      <SignupCustomInput
        key={FIELD_NAMES.EMAIL}
        handleInputChange={(_, value) => setEmail(value)}
        inputValue={email}
        inputName={FIELD_NAMES.EMAIL}
        ref={emailRef}
        hasUerEmailEntered={hasUserEmailEntered}
        onChangeRefAction={() => passwordRef.current?.focusTextInput()}
        inputLabel={i18n.t('email.default')}
      />
      <SignupCustomInput
        key={FIELD_NAMES.PASSWORD}
        handleInputChange={(_, value) => setPassword(value)}
        inputValue={password}
        inputName={FIELD_NAMES.PASSWORD}
        ref={passwordRef}
        inputLabel={i18n.t('password.default')}
      />
    </View>
  )
}

export default LoginInputs
