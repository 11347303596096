import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  pronounOptionsButton: (colorSettings) => ({
    marginVertical: 5,
    justifyContent: 'center',
    width: '100%',
    height: 60,
    borderWidth: 2,
    borderRadius: 30,
    backgroundColor: colorSettings?.selectable_btn_inactive_1 || 'transparent',
    alignItems: 'center',
    borderColor: colorSettings?.selectable_btn_border_inactive_1 || 'black',
  }),
  selectedPronounOption: (colorSettings) => ({
    marginVertical: 5,
    justifyContent: 'center',
    width: '100%',
    height: 60,
    borderWidth: 2,
    borderRadius: 30,
    backgroundColor:
      colorSettings?.selectable_btn_active_1 || 'rgba(74, 74, 74, 0.1)',
    alignItems: 'center',
    borderColor: colorSettings?.selectable_btn_border_active_1 || 'black',
  }),
  pronounOptionsButtonText: (colorSettings) => ({
    color: colorSettings?.selectable_btn_text_active_1 || 'black',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  }),
})

export default styles
