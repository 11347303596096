import { i18n } from '$localization/config.js'

import { convertTextToSnakeCase, capitalizeAndTranslate } from '$utils'

import {
  constructNotificationHeader,
  constructNotificationBody,
} from './helpers/index.js'

const EMPTY_FIRST_NAME = '{--}'
const EMPTY_VALUE = i18n.t('blank').title
const NONE_VALUE = i18n.t('none').title
const DECEASED_VALUE = 'deceased'
const ALIVE_VALUE = 'alive'
const EMPTY_STRING = ''

export default function familyMemberUpdatedNonAppUserData(data, params) {
  const notificationData = data
  const notificationType = notificationData?.notification_type

  const senderRelationshipToReceiver =
    params.sender_relationship_to_receiver || EMPTY_FIRST_NAME
  let senderFirstName = params.sender_first_name || EMPTY_FIRST_NAME
  const targetMemberRelationshipToReceiver =
    params.target_member_relationship_to_receiver || EMPTY_FIRST_NAME
  let targetMemberFirstName =
    params.target_member_first_name || EMPTY_FIRST_NAME

  senderFirstName =
    senderFirstName.trim() === EMPTY_STRING ? EMPTY_FIRST_NAME : senderFirstName
  targetMemberFirstName =
    targetMemberFirstName.trim() === EMPTY_STRING
      ? EMPTY_FIRST_NAME
      : targetMemberFirstName

  const updatedField = convertTextToSnakeCase(
    params?.updated_field || EMPTY_STRING
  )
  const oldValue = params.old_value
  const newValue = params.new_value

  const newDeceasedValue =
    params.new_status_value?.toLowerCase() === 'true'
      ? DECEASED_VALUE
      : ALIVE_VALUE
  const newCODValue = params?.new_cod_value || EMPTY_VALUE
  const newDOBValue = params?.new_dob_value || NONE_VALUE
  const newAgeValue = params?.new_age_value || NONE_VALUE

  const translatedNotificationType = capitalizeAndTranslate(notificationType)
  const notificationHeader = constructNotificationHeader(
    senderRelationshipToReceiver,
    senderFirstName,
    targetMemberRelationshipToReceiver,
    targetMemberFirstName
  )
  const notificationBody = constructNotificationBody(
    senderFirstName,
    newDeceasedValue,
    newAgeValue,
    newDOBValue,
    newCODValue,
    oldValue,
    newValue,
    updatedField
  )

  notificationData.notification_type = translatedNotificationType
  notificationData.message_header = notificationHeader
  notificationData.message_body = notificationBody

  return notificationData
}
