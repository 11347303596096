import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'

const SHOW_ASHKANAZI_RACE = 'show_ashkanazi_and_race'

const getRaceEthnicityDisplay = (currentClinician) =>
  currentClinician
    ? currentClinician.race_ethnicity_display
    : SHOW_ASHKANAZI_RACE

const getRouteName = (displayedEthnicity) => {
  const isHideAshkanaziAndRaceSelected =
    displayedEthnicity === SHOW_ASHKANAZI_RACE

  return isHideAshkanaziAndRaceSelected
    ? CUSTOM_FLOW_ROUTES.PersonalAncestry.name
    : CUSTOM_FLOW_ROUTES.PersonalEthnicity.name
}

const getSavedAndRemovedGeneTestData = (
  currentGeneTest,
  initialSelectedGenes
) => {
  const savedGeneTestData = currentGeneTest.filter(
    (testA) => !initialSelectedGenes.some((testB) => testA.CUI === testB.CUI)
  )
  const removedGeneTestData = initialSelectedGenes.filter(
    (testA) => !currentGeneTest.some((testB) => testA.CUI === testB.CUI)
  )

  return { savedGeneTestData, removedGeneTestData }
}

export { getRaceEthnicityDisplay, getRouteName, getSavedAndRemovedGeneTestData }
