import { i18n } from '$localization/config.js'

export default function generateChangeDetails(
  updatedField,
  oldValue,
  updatedNewValue
) {
  return `${i18n.t('changed_col').capitalize} ${
    i18n.t(updatedField).default
  }\n${i18n.t('from_col').capitalize} ${oldValue}\n${
    i18n.t('to_col').capitalize
  } ${updatedNewValue}`
}
