const EMPTY_STRING = ''
const PADDING_STRING = 'padding'
const KEYBOARD_VERTICAL_OFFSET = 0

const DEFAULT_PROFILE_DATA = {}
const IS_FALSE = false

export {
  DEFAULT_PROFILE_DATA,
  IS_FALSE,
  EMPTY_STRING,
  PADDING_STRING,
  KEYBOARD_VERTICAL_OFFSET,
}
