import { View, Text, TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/Feather'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { i18n } from '$localization/config'

import styles from '../../styles'

import { NAME_INFO_STRING, ICON_SIZE, BLACK_COLOR } from './constants'

export default function TitleQuestion({ colorSettings }) {
  const WHAT_IS_YOUR_ASSIGNED_AT_BIRTH_TEXT = i18n.t(
    'whats_your_biological_sex'
  ).default
  const BIOLOGICAL_SEX_TITLE = i18n.t('biological_sex').default
  const BIOLOGICAL_SEX_DESCRIPTION = i18n.t(
    'this_is_the_sex_you_were_assigned_at_birth_if_your_gender_identity_differs_from_your_biological_sex_you_can_indicate_that_using_the_link_below'
  ).default

  const showBiologicalSexAlert = () => {
    showDefaultAlert(BIOLOGICAL_SEX_TITLE, BIOLOGICAL_SEX_DESCRIPTION)
  }

  return (
    <View style={[styles.content, styles.outerTitleQuestion]}>
      <View style={styles.innerTitleQuestion}>
        <Text style={styles.titleText(colorSettings)}>
          {WHAT_IS_YOUR_ASSIGNED_AT_BIRTH_TEXT}
        </Text>

        <View style={styles.titleIconTextSpacer} />

        <TouchableOpacity
          style={styles.genderButton}
          onPress={showBiologicalSexAlert}
        >
          <Icon
            name={NAME_INFO_STRING}
            size={ICON_SIZE}
            color={colorSettings?.text_input_title_color_2 || BLACK_COLOR}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
}
