import React from 'react'
import { Text, View, Image } from 'react-native'
import { i18n } from '$localization/config.js'

import RoundedButton from '$components/RoundedButton'
import styles from './styles'

const DEFAULTS = {
  SURVEY_STRING: i18n.t('survey_complete').title ?? 'Survey Complete',
  EDIT_TEXT: i18n.t('edit').uppercase ?? 'EDIT',
  GO_TO_SURVEYS_TEXT: i18n.t('go_to_surveys').uppercase ?? 'GO TO SURVEYS',
  ACTIVE_OPACITY: 0.2,
}

function SurveyCompleteScreen({
  colorSettings,
  handleEditCompletedSurvey = () => null,
  onExitSurveyClicked = () => null,
  areButtonsShown,
}) {
  return (
    <View style={styles.container}>
      <Image
        source={require('$assets/images/new_assets/illus-survey-complete.png')}
        style={styles.image}
      />
      <Text style={styles.titleText(colorSettings)}>
        {DEFAULTS.SURVEY_STRING}
      </Text>

      {areButtonsShown && (
        <RoundedButton
          buttonStyle={styles.button(colorSettings)}
          activeOpacity={DEFAULTS.ACTIVE_OPACITY}
          onPress={handleEditCompletedSurvey}
        >
          <Text style={styles.nextButtonText(colorSettings)}>
            {DEFAULTS.EDIT_TEXT}
          </Text>
        </RoundedButton>
      )}

      {areButtonsShown && (
        <RoundedButton
          buttonStyle={styles.button(colorSettings)}
          activeOpacity={DEFAULTS.ACTIVE_OPACITY}
          onPress={onExitSurveyClicked}
        >
          <Text style={styles.nextButtonText(colorSettings)}>
            {DEFAULTS.GO_TO_SURVEYS_TEXT}
          </Text>
        </RoundedButton>
      )}
    </View>
  )
}

export default SurveyCompleteScreen
