import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Image, View, Text, ScrollView } from 'react-native'

import { i18n } from '$localization/config.js'

import { IS_WEB } from '$utils/Platforms'
import { DefaultFullHeight } from '$assets/styles/global.styles.js'

import NextButtonAbsolute from '$components/NextButtonAbsolute'

import styles from './styles'
import { FLOWS } from '$navigation/constants/routes'

export default function SuccessScreenResetPassword({ navigation }) {
  const colorSettings = useSelector((state) => state.colorSettings)
  const [scrollViewHeight, setScrollViewHeight] = useState(null)

  const successMessage = i18n.t('success').default
  const resetPasswordMessage = i18n.t('your_password_has_been_reset').default
  const loginButtonText = i18n.t('login_to_your_account').default

  const screenResizeHandler = () => {
    const height = DefaultFullHeight()

    setScrollViewHeight(height)
  }

  const nextButtonAction = () => {
    navigation.replace(FLOWS.AuthStack.name)
  }

  useEffect(() => {
    if (!IS_WEB()) return

    window.addEventListener('resize', screenResizeHandler)

    return () => {
      window.removeEventListener('resize', screenResizeHandler)
    }
  }, [])

  return (
    <View style={styles.container(colorSettings)}>
      <ScrollView
        style={styles.scrollViewHeight(scrollViewHeight)}
        contentContainerStyle={styles.contentContainer}
        scrollEnabled={false}
      >
        <View style={styles.content}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image}
              source={require('$assets/images/new_assets/illus-sent.png')}
            />
          </View>

          <View style={styles.textContainer}>
            <Text style={styles.titleText(colorSettings)}>
              {`${successMessage}!`}
            </Text>

            <Text style={styles.subTitleText(colorSettings)}>
              {resetPasswordMessage}
            </Text>
          </View>
        </View>
      </ScrollView>

      <View style={styles.buttonContainer}>
        <View style={styles.nextButtonContainer}>
          <NextButtonAbsolute
            onPressAction={nextButtonAction}
            buttonText={loginButtonText}
          />
        </View>
      </View>
    </View>
  )
}
