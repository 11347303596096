import { StyleSheet } from 'react-native'

const FADED_WHITE = 'rgb(234,234,234)'
const WHITE = '#fff'
const LIGHT_GREY = 'rgb(153,153,153)'

const styles = StyleSheet.create({
  modalFooterContainer: {
    borderTopWidth: 2,
    borderColor: FADED_WHITE,
    height: 50,
    flexDirection: 'row',
  },
  modalFooterButton: {
    backgroundColor: WHITE,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.5,
    height: '100%',
  },
  modalFooterButtonCancelButtonText: {
    textAlign: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    color: LIGHT_GREY,
  },
  modalFooterButtonSeparator: {
    backgroundColor: FADED_WHITE,
    height: '100%',
    width: 2,
  },
  modalFooterButtonSubmitButtonText: (allowSubmit, colorSettings) => ({
    textAlign: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    color: allowSubmit ? colorSettings?.linked_text : LIGHT_GREY,
  }),
})

export default styles
