import { useEffect, useState, useRef } from 'react'
import { useNavigation, useRoute } from '@react-navigation/core'
import { useDispatch, useSelector } from 'react-redux'
import { SafeAreaView, View } from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'
import OtherFamilyMembersTutorial from '../../../tutorials/family_screen/OtherFamilyMembers.tutorial'
import MainWrapper from '$components/MainWrapper'
import MemberSearchComponent from './components/MemberSearchComponent'
import FamilyScreenWalkthrough from '../../../tutorials/family_screen/FamilyScreenWalkthrought'
import ScreeningWorkflowHeaderText from './components/ScreeningWorkflowHeaderText'
import styles from './styles'
import NextButtonAbsolute from '$components/NextButtonAbsolute'
import AuthCredentials from '$data_models/AuthCredentials'
import Proband from '$data_models/Proband'
import { relationshipTypes } from '$constants'
import { apiFetchHandler } from '$api'
import {
  setDelayOtherFamilyMembersTutorial,
  setError,
  setIsFirstLogin,
  setShouldShowOtherFamilyMembersTutorial,
  setShowLoadingView as _setShowLoadingView,
} from '$redux/defaults/actions'
import FamilyList from './components/FamilyList'
import {
  setAddNewMemberPayload,
  setDidAddNewMember,
  setInvitedMember,
  storeCurrentMember,
} from '$redux/member_profile/actions'
import {
  setShouldReloadFamilyList,
  setFamilySummaryData,
  setBasicFamilyMembers,
} from '$redux/proband/actions'

import sectionListOrder from './constants/sectionListOrder'
import existingAllSections from './constants/existingSections'
import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { NAVIGATORS } from '$navigation/constants/routes'
import { IS_WEB, IS_MOBILE } from '$constants/platforms'

import {
  timeStampDifference,
  createAndNavigateToCreatedUser,
  setNavigationEventAction,
  onSearchFamilyList,
  requestAddRelative,
} from './helpers'
import AvatarsBottomSheet from './components/AvatarsBottomSheet'

function FamilyListScreen() {
  const navigation = useNavigation()
  const route = useRoute()
  const dispatch = useDispatch()
  const avatarBottomSheetRef = useRef(null)
  const [expandedSections, setExpandedSections] = useState(
    new Set(['you_and_your_children'])
  )
  const [currentMemberAvatar, setCurrentMemberAvatar] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const isCustomWorkflow = useSelector(
    (state) => state.customFlowRoutesStore.isCustomWorkflow
  )
  const [filteredFamilyData, setFilteredFamilyData] = useState([])

  const [familyData, setFamilyData] = useState([])

  const [sectionListRefreshing, setSectionListRefreshing] = useState(false)

  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const {
    colorSettings,
    isFirstLogin,
    shouldShowOtherFamilyMembersTutorial,
    shouldDelayOtherFamilyMembersTutorial,
    tutorialWithSurvey,
  } = useSelector((state) => state.store)

  const { authCredentials } =
    useSelector((state) => state.accountStore) ?? new AuthCredentials()

  const setShowLoadingView = (data) => dispatch(_setShowLoadingView(data))

  const { didAddNewMember, addNewMemberPayload } = useSelector(
    (state) => state.memberProfileStore
  )
  const saveFamilySummaryData = (data) => dispatch(setFamilySummaryData(data))
  const saveInvitedMember = (data) => dispatch(setInvitedMember(data))
  const saveBaseFamilyMembers = (data) => dispatch(setBasicFamilyMembers(data))
  const saveCurrentMemberToStore = (data) => dispatch(storeCurrentMember(data))
  const currentProband =
    useSelector((state) => state.probandStore.proband) ?? new Proband()

  const shouldReloadFamilyList = useSelector(
    (state) => state.probandStore.shouldReloadFamilyList
  )

  const saveDidAddNewMember = (data) => dispatch(setDidAddNewMember(data))
  const saveAddNewMemberPayload = (data) =>
    dispatch(setAddNewMemberPayload(data))
  const saveDelayOtherFamilyMembersTutorial = (data) =>
    dispatch(setDelayOtherFamilyMembersTutorial(data))
  const saveError = (data) => dispatch(setError(data))
  const saveShouldShowOtherFamilyMembersTutorial = (data) =>
    dispatch(setShouldShowOtherFamilyMembersTutorial(data))
  const saveIsFirstLogin = (data) => dispatch(setIsFirstLogin(data))
  const saveShouldReloadFamilyList = (data) =>
    dispatch(setShouldReloadFamilyList(data))

  const firstScreenInTabViewIndex = 0

  const getFamilyMembers = async () => {
    if (!currentProband?.probandID) return
    const familyDataStructure = [
      { title: relationshipTypes.YOU_AND_YOUR_CHILDREN, data: [] },
      { title: relationshipTypes.BROTHERS_AND_SISTERS, data: [] },
      { title: relationshipTypes.PARENTS, data: [] },
      { title: relationshipTypes.AUNTSUNCLES_DADS_SIDE, data: [] },
      { title: relationshipTypes.AUNTSUNCLES_MOMS_SIDE, data: [] },
      { title: relationshipTypes.GRANDPARENTS_DADS_SIDE, data: [] },
      { title: relationshipTypes.GRANDPARENTS_MOMS_SIDE, data: [] },
      { title: relationshipTypes.OTHER_FAMILY_MEMBERS, data: [] },
    ]

    setShowLoadingView(true)

    try {
      const probandID = currentProband?.probandID

      const getReduxTree = {
        path: `refinery/redux-tree/${probandID}/app_redux_tree/`,
        method: 'get',
        token: authCredentials.accessToken,
        pageDetails: {
          page: 'FamilyListScreen.js',
        },
      }

      const response = await apiFetchHandler(getReduxTree)

      if (response.isError) {
        setSectionListRefreshing(false)
        setShowLoadingView(false)

        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        return
      }

      setShowLoadingView(false)

      const keys = Object.keys(sectionListOrder).filter((key) =>
        Object.keys(response).includes(key)
      )

      const newFamilyList = keys.map((familyType) => {
        const patientsData = response
        const relationshipToProband = familyType.toLowerCase()
        const isProband = relationshipToProband === 'proband'
        const SECTION_KEY = sectionListOrder[relationshipToProband]

        return {
          title: familyDataStructure[SECTION_KEY]?.title,
          data: isProband
            ? [patientsData[relationshipToProband]]
            : [...patientsData[relationshipToProband]],
        }
      })

      saveBaseFamilyMembers(newFamilyList)
      saveFamilySummaryData(newFamilyList)
      setFamilyData(newFamilyList)
      setFilteredFamilyData(newFamilyList)
    } catch (error) {
      setShowLoadingView(false)
      console.log(error)
    } finally {
      saveShouldReloadFamilyList(false)
    }
  }

  const onFamilyScreenWalkthroughCloseAction = () => {
    saveIsFirstLogin(false)
    saveDelayOtherFamilyMembersTutorial(false)

    setTimeout(() => {
      if (saveShouldShowOtherFamilyMembersTutorial) {
        saveShouldShowOtherFamilyMembersTutorial(true)
      }
    }, 500)
  }

  const onRefreshSectionList = async () => getFamilyMembers()

  const handleExpandCollapsibleSections = () =>
    setExpandedSections(new Set(existingAllSections))

  const handleCollapseCollapsibleSections = () => setExpandedSections(new Set())

  const nextButtonAction = () => {
    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: NAVIGATORS.Main.name,
    })
    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }
  const handleAddRelatives = async () => {
    const addedMembers = []

    for (const payload of addNewMemberPayload) {
      // eslint-disable-next-line no-await-in-loop
      const member = await requestAddRelative(
        payload,
        authCredentials,
        saveError,
        setSectionListRefreshing
      )
      addedMembers.push(member)
    }

    if (addedMembers?.length !== 1) return getFamilyMembers()

    createAndNavigateToCreatedUser(
      addedMembers?.[0],
      saveShouldReloadFamilyList,
      saveCurrentMemberToStore,
      saveInvitedMember,
      navigation,
      route,
      saveDidAddNewMember,
      saveAddNewMemberPayload
    )
  }

  useEffect(() => {
    const routeParameters = route.params?.familyTabsNavigationButtonTypes

    navigation?.setParams({
      handleExpandFamilyMembers: () => handleExpandCollapsibleSections(),
      handleCollapseFamilyMembers: () => handleCollapseCollapsibleSections(),
    })

    if (isFirstLoad) return setIsFirstLoad(false)
    if (routeParameters !== firstScreenInTabViewIndex) return
    const getTimeFamilyTimeStamp = async () => {
      try {
        const getTimeStampPayload = {
          path: `family/family_last_modified/`,
          method: 'get',
          token: authCredentials.accessToken,
          pageDetails: {
            page: 'FamilyListScreen.js',
          },
        }

        const response = await apiFetchHandler(getTimeStampPayload)

        if (response.isError) {
          setSectionListRefreshing(false)
          setShowLoadingView(false)

          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }
        const maxTimeRangeInSec = 300
        const recentTimeInSec = timeStampDifference(
          response,
          new Date()
        ).seconds

        if (maxTimeRangeInSec > recentTimeInSec) {
          getFamilyMembers()
        }
        setShowLoadingView(false)
      } catch (err) {
        setShowLoadingView(false)
      }
    }
    getTimeFamilyTimeStamp()
  }, [route.params?.familyTabsNavigationButtonTypes, setIsFirstLoad])

  useEffect(() => {
    let unsubscribe
    if (IS_WEB()) {
      unsubscribe = navigation.addListener('focus', () => getFamilyMembers())
    }

    getFamilyMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return unsubscribe
  }, [shouldReloadFamilyList])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (!isCustomWorkflow) {
        const familyTabsNavigationButtonTypes =
          route.params?.familyTabsNavigationButtonTypes ??
          firstScreenInTabViewIndex

        if (familyTabsNavigationButtonTypes !== firstScreenInTabViewIndex)
          return
      }

      setNavigationEventAction(
        shouldDelayOtherFamilyMembersTutorial,
        saveDelayOtherFamilyMembersTutorial,
        saveShouldShowOtherFamilyMembersTutorial,
        saveShouldReloadFamilyList,
        shouldReloadFamilyList,
        getFamilyMembers
      )

      if (didAddNewMember) {
        handleAddRelatives()
      }

      saveDidAddNewMember(false)
    })

    return unsubscribe
  }, [didAddNewMember])

  const handleAvatarClick = (member) => {
    setCurrentMemberAvatar(member.member_id)
    if (IS_MOBILE()) {
      avatarBottomSheetRef.current.expand()
    } else {
      setModalVisible(true)
    }
  }

  const sendAvatarPayload = async (avatarData) => {
    const payload = {
      member_id: currentMemberAvatar,
      skin_tone: avatarData?.skinTone,
      icon: avatarData?.icon,
    }

    const getAvatarPayload = {
      path: `member_avatar/add_member_avatar/`,
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'FamilyListScreen.js',
      },
      body: {
        ...payload,
      },
    }

    try {
      const response = await apiFetchHandler(getAvatarPayload)

      if (response.isError) {
        setTimeout(() => {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
        }, 100)

        return null
      }

      saveShouldReloadFamilyList(true)
      return IS_MOBILE() && avatarBottomSheetRef.current.close()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ErrorBoundary>
      <MainWrapper navigation={navigation}>
        <SafeAreaView style={styles.safeAreaContainer(colorSettings)}>
          <View style={styles.viewContainer}>
            <MemberSearchComponent
              onChangeText={(value) =>
                onSearchFamilyList(
                  value,
                  setFilteredFamilyData,
                  familyData,
                  setExpandedSections
                )
              }
            />

            {isCustomWorkflow && <ScreeningWorkflowHeaderText />}

            <FamilyList
              expandedSections={expandedSections}
              filteredFamilyData={filteredFamilyData}
              setExpandedSections={setExpandedSections}
              sectionListRefreshing={sectionListRefreshing}
              onRefreshSectionList={onRefreshSectionList}
              isCustomWorkflow={isCustomWorkflow}
              handleAvatarClick={handleAvatarClick}
            />
          </View>

          {isCustomWorkflow && (
            <NextButtonAbsolute onPressAction={nextButtonAction} />
          )}

          {isFirstLogin && (
            <FamilyScreenWalkthrough
              colorSettings={colorSettings}
              isFirstLogin={isFirstLogin}
              tutorialWithSurvey={tutorialWithSurvey}
              onFamilyScreenWalkthroughCloseAction={
                onFamilyScreenWalkthroughCloseAction
              }
            />
          )}

          {shouldShowOtherFamilyMembersTutorial && (
            <OtherFamilyMembersTutorial />
          )}

          <AvatarsBottomSheet
            avatarBottomSheetRef={avatarBottomSheetRef}
            sendAvatarPayload={sendAvatarPayload}
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
          />
        </SafeAreaView>
      </MainWrapper>
    </ErrorBoundary>
  )
}

export default FamilyListScreen
