import {
  capitalizeFirstLetter,
  fixDiseaseName,
  getFamilySideAndRelationshipGender,
  translateLongRelationshipName,
} from '$utils'
import { i18n } from '$localization/config.js'
import {
  AJUSTED_GENDERS_NAMES,
  excludedRelationshipToCount,
  GENDER_IDENTITY_VALUES,
  GENDER_VALUES,
} from '../../edit_profile/constants'
import { store } from '$redux/configureStore'

const familyMemberCounter = (relationship, listOfRelationships) => {
  if (listOfRelationships.length < 1) {
    return [true, true]
  }

  const getRelationshipStatus = listOfRelationships.find(
    (childrenRelationship) =>
      childrenRelationship?.relationshipName === relationship
  )

  if (!getRelationshipStatus) {
    return [true, true]
  }

  return [false, false, true]
}

const handleShortenedGenderIdentity = (genderIdentity) => {
  switch (genderIdentity) {
    case GENDER_IDENTITY_VALUES.MALE:
      return `m`
    case GENDER_IDENTITY_VALUES.FEMALE:
      return `f`
    case GENDER_IDENTITY_VALUES.NON_BINARY:
      return `nb`
    default:
      return null
  }
}

const adjustRelationshipNameWithGenderIdentity = (gender) => {
  switch (gender) {
    case GENDER_VALUES.MALE:
      return `(${AJUSTED_GENDERS_NAMES.AMAB})`
    case GENDER_VALUES.FEMALE:
      return `(${AJUSTED_GENDERS_NAMES.AFAB})`
    default:
      return ''
  }
}

const calculateRelationship = (member, relationshipList) => {
  const { gender_identity: genderIdentity = null } = member
  const { isOnboarding } = store.getState().store
  const _relationshipToProband = getFamilySideAndRelationshipGender(
    member?.relationship_to_proband,
    genderIdentity
      ? handleShortenedGenderIdentity(genderIdentity)
      : member?.gender
  )

  const _relationship = _relationshipToProband[0]?.toLowerCase()

  const _familySide = _relationshipToProband[1]?.toLowerCase()
  let _greatCounter = _relationshipToProband[2]

  let relationship =
    i18n.t(fixDiseaseName(_relationship))?.default ??
    capitalizeFirstLetter(fixDiseaseName(_relationship), true)

  relationship = translateLongRelationshipName(relationship)

  relationship = `${relationship} `

  const item = {
    relationshipName: _familySide
      ? `${relationship}${_familySide}`
      : relationship,
    count: 1,
  }
  const isGenderIdentitySameAsGender =
    handleShortenedGenderIdentity(genderIdentity) === member.gender

  if (excludedRelationshipToCount.includes(member?.relationship_to_proband)) {
    const relationShipWithAdjustedGenderIdentity = `${relationship} ${
      genderIdentity && !isGenderIdentitySameAsGender
        ? adjustRelationshipNameWithGenderIdentity(member.gender)
        : ''
    }`

    relationship = relationShipWithAdjustedGenderIdentity
    return { relationship, item }
  }

  const [
    hasRelationshipItem = false,
    hasRelationshipCount = false,
    hasRelationshipIndex = false,
  ] = familyMemberCounter(item?.relationshipName, relationshipList)

  if (hasRelationshipItem && !isOnboarding) relationshipList.push(item)

  if (hasRelationshipCount && !isOnboarding) relationship += 1

  if (hasRelationshipIndex && !isOnboarding) {
    const getRelationshipIndex = relationshipList.findIndex(
      (childrenRelationship) =>
        childrenRelationship?.relationshipName === item.relationshipName
    )

    const count = relationshipList[getRelationshipIndex].count + 1
    relationship += count

    relationshipList[getRelationshipIndex].count += 1
  }

  if (_familySide && _greatCounter === 0) {
    relationship = `${relationship} (${i18n.t(_familySide)?.default})`
  }

  while (_greatCounter > 0) {
    const grandFatherParents =
      member?.partners[0]?.children[0]?.relationship_to_proband.includes(
        'grandfather'
      )
    const grandMotherParents =
      member?.partners[0]?.children[0]?.relationship_to_proband.includes(
        'grandmother'
      )

    let relationshipCount =
      grandFatherParents || grandMotherParents
        ? `${relationship} (${
            i18n.t(grandFatherParents ? 'grandfather' : 'grandmother')?.default
          } - ${i18n.t(_familySide)?.default})`
        : relationship

    if (
      relationshipCount.includes('Grandfather') ||
      relationshipCount.includes('Grandmother')
    ) {
      relationshipCount = relationshipCount.replace(/\d/g, '')
    }

    relationship = `${i18n.t('great')?.default} ${relationshipCount}`
    _greatCounter--
  }

  const relationShipWithAdjustedGenderIdentity = `${relationship} ${
    genderIdentity && !isGenderIdentitySameAsGender
      ? adjustRelationshipNameWithGenderIdentity(member.gender)
      : ''
  }`

  relationship = relationShipWithAdjustedGenderIdentity

  relationship = capitalizeFirstLetter(relationship, true, ' ')

  return {
    relationship,
    item,
  }
}

export default calculateRelationship
