const PASTEL_GREY = '#ccc'

const htmlStyle = `
@media screen and (max-width: 1600px), 
(min-width: 1600px) and (max-width: 9000px) {
  #web_scaled_main_container > div:first-child,
  #modal_scaled_main_container > div {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }
}

#forgot_password_button {
  position: relative;
  margin-top: 70px;
}
#login_view_content_container {
  height: initial;
}
#family_section_title {
  padding-left: 15px;
}
#login_screen_input_email {
  outline: none;
  margin-bottom: -4px;
}
#login_screen_input_container > div {
  min-height: 62px;
}
#login_screen_input_password {
  outline: none;
  margin-bottom: -4px;
}
#login_screen_input_container > div {
  min-height: 62px;
}
#text_input_web {
  width: 330px;
  margin: 0 auto;
}
#text_input_web:focus,
#web_text_input:focus {
  outline: none !important;
  border: 2px solid ${PASTEL_GREY};
}
#signup_clinician_logo_container {
  border-width: 0px;
}
#signup_gender_selection_tile {
  width: 170px;
  height: 170px;
}
#gender_id_tile {
  width: 110px;
}
#risk_genetic_resources_button {
  margin: 0 auto;
}
#phone_no_down_arrow {
  margin-left: 11px;
  margin-bottom: 2px;
  border: solid currentColor;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: .20em;
  transform: rotate(45deg);
}
#phone_flag_and_ccode_signup_process input {
  width: 24px;
}
#phone_input_signup_process input {
  width: 200px;
}
#phone_input_row_signup_process {
  padding-left: 166px;
}
#yes_no_toggle_button_container > div {
  flex-direction: row;
  justify-content: center;
  border: none;
}
`

export { htmlStyle }
