import moment from 'moment'

export default function parseDateOfBirth(dob) {
  let parsedDate = moment(dob, 'MM-DD-YYYY', true)

  if (!parsedDate.isValid()) {
    parsedDate = moment(dob, 'YYYY-MM-DD', true)
  }

  if (!parsedDate.isValid()) {
    parsedDate = moment(dob, 'MM/DD/YYYY', true)
  }

  if (!parsedDate.isValid()) {
    parsedDate = new Date()
  } else {
    parsedDate = parsedDate.toDate()
  }

  return parsedDate
}
