import { Component } from 'react'
import { View, SafeAreaView, Text, TouchableOpacity } from 'react-native'

import { connect } from 'react-redux'

import { WebView } from 'react-native-webview'
import ErrorBoundary from 'react-native-error-boundary'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import { envConstants } from '$constants'
import { DOMAIN_CLIENT_NAME } from '$utils/globalVariables.js'

import { i18n, locale } from '$localization/config.js'

import { isEmptyObject, webIframe } from '$utils'

import global_styles, {
  DefaultFullHeight,
} from '$assets/styles/global.styles.js'
import { IS_WEB } from '$utils/Platforms.js'

import GeneticResourcesGenerator from './generator.js'
import MainWrapper from '$components/MainWrapper'

const WEBVIEW_IFRAME_CONTAINER_ID = 'genetic_resources_iframe_container'

const styles = {
  itemView: {
    backgroundColor: 'white',
    marginVertical: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'rgba(200,200,200,0.5)',
    borderWidth: 2,
    borderRadius: 10,
  },
  itemViewButton: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemDescriptionText: {
    fontFamily: 'montserrat',
    fontSize: 14,
    color: 'rgba(74,74,74,1.0)',
    textAlign: 'center',
  },
}

function getCountryCode() {
  const _locale = `${locale}`.toUpperCase()
  const splitLocale = _locale.split('-')
  let countryCode = splitLocale[splitLocale.length - 1]

  switch (countryCode) {
    case 'GB':
    case 'EU':
    case 'IE':
      countryCode = 'UK'
      break
    case 'AU':
      countryCode = 'AU'
      break
    default:
      countryCode = 'US'
      break
  }

  return countryCode
}

class GeneticResourcesView extends Component {
  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  authCredentials = this.props.authCredentials

  account = this.props.currentAccount || new Account()

  proband = this.props.currentProband || new Proband()

  listData = GeneticResourcesGenerator(getCountryCode())

  static navigationOptions = ({ navigation }) => {
    const { colorSettings } = this.props

    const options = DefaultNavigationOptions({
      navigation,
      headerTitle: i18n.t('genetic_resources').title,
      headerTintColor: colorSettings?.nav_title_light,
      headerBackgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
    })

    const countryCodeTitle = getCountryCode()
    options.headerRight = () => (
      <View style={global_styles.headerButton}>
        <Text
          style={[
            global_styles.headerRightButtonText,
            {
              color: colorSettings?.nav_title_light,
            },
          ]}
        >
          {countryCodeTitle === 'UK' ? 'EUR' : countryCodeTitle}
        </Text>
      </View>
    )

    return options
  }

  constructor(props) {
    super(props)

    // Set shared instance
    this.constructor.setSharedInstance(this)

    this.state = {
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    const { customWebViewUri, selectedLanguage } = this.props
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)

      webIframe().openInIframe(this.generatedUrl(), WEBVIEW_IFRAME_CONTAINER_ID)
    }
    this.handleHeaderNavigation()
  }

  componentWillUnmount() {
    // This event happens before component is removed from memory.
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  handleHeaderNavigation = () => {
    const { navigation, colorSettings } = this.props
    const countryCodeTitle = getCountryCode()

    navigation.setOptions({
      headerRight: () => (
        <View style={global_styles.headerButton}>
          <Text
            style={[
              global_styles.headerRightButtonText,
              {
                color: colorSettings?.nav_title_light,
              },
            ]}
          >
            {countryCodeTitle === 'UK' ? 'EUR' : countryCodeTitle}
          </Text>
        </View>
      ),
    })
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  navigateToWebView = (title = '', siteURI = '') => {
    this.props._setCustomWebViewUri_(siteURI)

    // this.props.navigation.navigate("CustomWebView", {
    this.props.navigation.navigate('GeneticResourcesWebView', {
      t: title,
    })
  }

  toggleLoadingView = (start = true) => {
    this.props.navigation.setParams({ lv: start })
  }

  renderItem = ({ item, index, section }) => {
    if (isEmptyObject(item)) return null

    const { colorSettings } = this.props
    const { title, site, content } = item

    return (
      <View style={global_styles.contentContainerPadded}>
        <View
          style={{
            flex: 1.0,
            width: '100%',
            paddingTop: index == 0 ? 16 : 0,
            paddingBottom: index == this.listData.length - 1 ? 16 : 0,
          }}
        >
          <View style={styles.itemView}>
            <TouchableOpacity
              onPress={() => this.navigateToWebView(title, site)}
              style={styles.itemViewButton}
              activeOpacity={0.5}
            >
              <Text
                style={{
                  ...global_styles.linkedText,
                  textDecorationLine: 'underline',
                  textAlign: 'center',
                  color: colorSettings?.linked_text,
                }}
              >
                {title}
              </Text>

              <View style={{ height: 16 }} />

              <Text style={styles.itemDescriptionText}>{content}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  generatedUrl = () => {
    const { selectedLanguage } = this.props

    const languageList = {
      en: 'english',
      nl: 'dutch',
      de: 'german',
      fr: 'french',
      es: 'spanish',
    }

    let urlOrgDirectory = {
      US: '/famhis_us',
      UK: '/eu_uk',
      AU: '/aus',
    }

    const urlCountryDirectory = {
      US: '/us-main-page-',
      UK: '/uk-main-page-',
      AU: '/au-main-page-',
    }

    if (DOMAIN_CLIENT_NAME?.toLowerCase() === 'city of hope') {
      urlOrgDirectory = {}
    }

    const getUrlOrgDirectory = urlOrgDirectory[getCountryCode()] || ''
    const language = !languageList[selectedLanguage]
      ? 'english'
      : languageList[selectedLanguage]

    const getGeneratedURL = `${
      envConstants.GENETIC_RESOURCES_URL
    }${getUrlOrgDirectory}${
      urlCountryDirectory[getCountryCode()]
    }${language}.html`

    return getGeneratedURL
  }

  render() {
    const { navigation, colorSettings } = this.props

    if (IS_WEB()) {
      const content = (
        <MainWrapper navigation={navigation}>
          <View
            style={{
              height: this.state.scrollViewHeight,
              backgroundColor: colorSettings?.onboarding_bgcolor,
            }}
          >
            <View
              style={{
                flex: 1.0,
                height: '100%',
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              nativeID={WEBVIEW_IFRAME_CONTAINER_ID}
              onLayout={() => this.toggleLoadingView(false)}
            />
          </View>
        </MainWrapper>
      )

      return content
    }

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            nativeID="web_scaled_main_container"
            style={[
              global_styles.container,
              { backgroundColor: 'rgb(245,245,245)' },
            ]}
          >
            <WebView
              style={{ flex: 1.0, width: '100%' }}
              source={{
                uri: this.generatedUrl(),
              }}
              onLoadStart={() => this.toggleLoadingView(true)}
              onLoad={() => this.toggleLoadingView(false)}
            />
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    customWebViewUri: store.customWebViewUri,
    selectedLanguage: store.selectedLanguage,

    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
    /** Proband Store */
    currentProband: probandStore.proband,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _setCustomWebViewUri_: (data) =>
    dispatch({ type: 'SET_CUSTOM_WEB_VIEW_URI', data }),
})

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(GeneticResourcesView)
