const EMPTY_STRING = ''
const UNDERSCORE = '_'
const SINGLE_SPACE = ' '

export default function removeGreatFromRelationship(relationship) {
  let newRelationship = relationship
    .toLowerCase()
    .replaceAll(UNDERSCORE, SINGLE_SPACE)
  const greatCounter = (newRelationship.match(/great/g) || []).length

  newRelationship = newRelationship.replace(/great/g, EMPTY_STRING)

  return {
    newRelationship: newRelationship
      .trim()
      .replaceAll(SINGLE_SPACE, UNDERSCORE),
    greatCounter,
  }
}
