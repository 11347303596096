import AsyncStorage from '@react-native-async-storage/async-storage'

const ERROR_MESSAGE = 'Error in getItemAsyncStorage:'

export default async function getItemAsyncStorage(key) {
  if (!key || key.trim() === '') return null

  try {
    const value = await AsyncStorage.getItem(`@App:${key}`)
    return value ? JSON.parse(value) : null
  } catch (error) {
    console.log(ERROR_MESSAGE, error)
    return null
  }
}
