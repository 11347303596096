import { apiFetchHandler } from '$api'

async function addAncestry({
  memberId,
  ancestryId,
  authCredentials,
  errorHandler,
}) {
  if (!memberId) return 'memberId does not exist'
  if (!ancestryId) return 'memberId does not exist'

  const getMemberProfilePayload = {
    path: 'member_ancestry/',
    method: 'post',
    token: authCredentials.accessToken,
    body: { ancestry_id: ancestryId, member_id: memberId },
    pageDetails: {
      page: 'memberAncestry.js',
    },
  }

  const response = await apiFetchHandler(getMemberProfilePayload)

  if (response.isError) {
    errorHandler(response)
    return
  }

  return response
}

async function getAncestry({ memberId, authCredentials, errorHandler }) {
  if (!memberId) return 'memberId does not exist'

  const getMemberProfilePayload = {
    path: 'member_ancestry/',
    method: 'get',
    token: authCredentials.accessToken,
    params: { member_id: memberId },
    pageDetails: {
      page: 'memberAncestry.js',
    },
  }

  const response = await apiFetchHandler(getMemberProfilePayload)

  if (response.isError) {
    errorHandler(response)
    return
  }

  return response
}

async function removeAncestry({
  authCredentials,
  errorHandler,
  selectedAncestryId,
}) {
  if (!selectedAncestryId) return 'selectedAncestryId does not exist'

  const getMemberProfilePayload = {
    path: `member_ancestry/${selectedAncestryId}/`,
    method: 'delete',
    token: authCredentials.accessToken,
    pageDetails: {
      page: 'memberAncestry.js',
    },
  }

  const response = await apiFetchHandler(getMemberProfilePayload)

  if (response.isError) {
    errorHandler(response)
    return
  }

  return response
}

function memberAncestry(memberData) {
  const add = () => addAncestry(memberData)
  const get = () => getAncestry(memberData)
  const remove = () => removeAncestry(memberData)

  return {
    add,
    get,
    remove,
  }
}

export default memberAncestry
