import { StyleSheet, Platform } from 'react-native'
import { platforms } from '$constants'

const WHITE = 'rgb(245,245,245)'
const DARKER_GREY = 'rgb(74,74,74)'

export default StyleSheet.create({
  emptyMessageText: {
    fontFamily: 'karla-bold',
    fontSize: 14,
    color: DARKER_GREY,
    textAlign: 'center',
    paddingHorizontal: 24,
  },
  containerWhite: (colorSettings, scrollViewHeight) => ({
    backgroundColor: colorSettings?.post_onboarding_bgcolor ?? WHITE,
    ...Platform.select({
      [platforms.WEB]: {
        flex: null,
        height: scrollViewHeight,
      },
    }),
    width: '100%',
    flex: 1.0,
  }),
  surveyListScrollView: { width: '100%' },
  surveyListContainer: {
    paddingHorizontal: 16,
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  clinicianCard: { paddingTop: 16 },
})
