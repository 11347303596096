import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'
import styles from './styles'

function InvitedPersonHeaderInfo({ firstName }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const memberFirstName = `${firstName ?? i18n.t('unknown')?.default}`.trim()
  const titleText = [
    i18n.t('you_are_about_to_set_up_an_account_as_')?.default,
    `"${memberFirstName}".`,
    i18n.t(
      '_if_this_is_not_correct_contact_the_family_member_who_invited_you_and_ask_them_to_send_another_invitation'
    )?.default,
  ].join(' ')

  return (
    <View style={styles.invitedPersonHeaderInfoContainer(colorSettings)}>
      <Text style={styles.invitedPersonHeaderInfoText(colorSettings)}>
        {titleText}
      </Text>
    </View>
  )
}

export default InvitedPersonHeaderInfo
