/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react'

import { ScrollView } from 'react-native'
import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import ButtonDone from '$components/ButtonDone'
import FlexContainer from '$components/FlexContainer'

import pageBuilder from '../../pageBuilder'

import { DefaultFullHeight } from '$assets/styles/global.styles.js'
import { IS_WEB, IS_MOBILE_DEVICE_WEB_BROWSER } from '$utils/Platforms.js'

import styles from '../../assets/styles.js'

const WHITE_COLOR = '#fff'

export default function SurveyPageGenerator({
  surveyData,
  currentSurveyPage,
  surveyAnswers,
  storeFieldSurveyAnswers,
  submitSurveyResponse,
  deleteSurveyAnswer,
  nextPageHandler,
  allowedSurveyPages,
  isOnboarding,
  deleteAnswer,
}) {
  const { colorSettings = null } = useSelector((state) => state.store)

  const [scrollViewHeight, setScrollViewHeight] = useState(DefaultFullHeight())

  const screenResizeHandler = useCallback(() => {
    setScrollViewHeight(DefaultFullHeight())
  }, [])

  const removeFalsyValues = (item) => Boolean(item)

  const getButtonLabel = (currentPage, totalPages) => {
    const getTranslation = (key, defaultValue) => {
      const translation = i18n.t(key)
      return translation || defaultValue
    }

    const doneTranslation = getTranslation('survey_btn_label_done', 'Done')
    const nextTranslation = getTranslation('survey_btn_label_next', 'Next')

    const isLastPage = currentPage === totalPages

    return isLastPage ? doneTranslation : nextTranslation
  }

  useEffect(() => {
    if (IS_WEB() && !IS_MOBILE_DEVICE_WEB_BROWSER()) {
      window.addEventListener('resize', screenResizeHandler)
    }

    return () => {
      if (IS_WEB() && !IS_MOBILE_DEVICE_WEB_BROWSER()) {
        window.removeEventListener('resize', screenResizeHandler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buildPagePayload = {
    current_page: currentSurveyPage,
    survey: surveyData.survey,
    proband: surveyData.proband,
    proband_diseases: surveyData.proband_diseases,
    proband_disease_skip_logics: surveyData.proband_disease_skip_logics,
    completed_survey_answers: surveyAnswers,
    storeFieldSurveyAnswers,
    submitSurveyResponse,
    deleteCompletedSurveyAnswer: deleteSurveyAnswer,
    colorSettings,
    deleteAnswer,
  }

  const surveyFieldsCanvas = pageBuilder(buildPagePayload)
  const content = surveyFieldsCanvas.filter(removeFalsyValues)

  const isLastSurveyPage =
    currentSurveyPage > allowedSurveyPages[allowedSurveyPages.length - 1]

  if (isLastSurveyPage && !isOnboarding) {
    return nextPageHandler(currentSurveyPage)
  }

  return (
    <>
      <ScrollView
        style={{
          height: scrollViewHeight,
          backgroundColor: colorSettings?.onboarding_bgcolor ?? WHITE_COLOR,
        }}
      >
        <FlexContainer style={styles.flexContainer}>{content}</FlexContainer>
      </ScrollView>

      <ButtonDone
        title={getButtonLabel(currentSurveyPage, surveyData.survey.total_pages)}
        onPress={() => nextPageHandler(currentSurveyPage + 1)}
        colorSettings={colorSettings}
      />
    </>
  )
}
