const ALIVE_KEY = 'alive'
const DECEASED_KEY = 'deceased'
const BLANK_KEY = 'blank'
const NONE_KEY = 'none'
const CHANGED_COL_KEY = 'changed_col'
const STATUS_KEY = 'vital_status current_age birthdate cause_of_death'
const ADDED_COL_KEY = 'added_col'
const REMOVED_COL_KEY = 'removed_col'

export {
  ALIVE_KEY,
  DECEASED_KEY,
  BLANK_KEY,
  NONE_KEY,
  CHANGED_COL_KEY,
  STATUS_KEY,
  ADDED_COL_KEY,
  REMOVED_COL_KEY,
}
