import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  subQuestionTitle: (colorSettings) => ({
    textAlign: 'center',
    marginBottom: 20,
    fontFamily: 'karla-bold',
    fontSize: 24,
    color: colorSettings?.text_input_label_color_2,
  }),
  toolTipButton: { alignSelf: 'center' },
})

export default styles
