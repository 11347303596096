import { useMemo } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'

import BottomSheet, { BottomSheetView } from '@gorhom/bottom-sheet'

import { i18n } from '$localization/config.js'

import {
  DEFAULT_SHADOW_COLOR,
  DEFAULT_SHADOW_OFFSET_WIDTH,
  DEFAULT_SHADOW_OFFSET_HEIGHT,
  DEFAULT_SHADOW_OPACITY,
  DEFAULT_SHADOW_RADIUS,
  DEFAULT_ELEVATION,
  DEFAULT_Z_INDEX,
  DEFAULT_INDEX,
  SELECTED_BUTTON_COLOR,
  DEFAULT_BUTTON_COLOR,
  CONTENT_HEIGHT,
} from './constants'

import styles from './styles'

const defaultShadowOptions = {
  shadowColor: DEFAULT_SHADOW_COLOR,
  shadowOffset: {
    width: DEFAULT_SHADOW_OFFSET_WIDTH,
    height: DEFAULT_SHADOW_OFFSET_HEIGHT,
  },
  shadowOpacity: DEFAULT_SHADOW_OPACITY,
  shadowRadius: DEFAULT_SHADOW_RADIUS,
  elevation: DEFAULT_ELEVATION,
  zIndex: DEFAULT_Z_INDEX,
}
export default function BottomSheetComponent({
  children,
  handleSheetChanges = () => {},
  startPoint = 20,
  endPoint,
  reference,
  index = DEFAULT_INDEX,
  listItems,
  selectedItem,
  onSelectItemAction,
}) {
  const snapPoints = useMemo(
    () => [`${startPoint}%`, endPoint ? `${endPoint}%` : '5%'],
    []
  )

  const defaultContent = () => {
    const defaultContentButtons = (handlePressButton, buttonName) => {
      const isButtonSelected = selectedItem === buttonName
      const isSelectedButton = isButtonSelected
        ? SELECTED_BUTTON_COLOR
        : DEFAULT_BUTTON_COLOR
      const buttonLabel = i18n.t(buttonName).default

      return (
        <View style={styles.bottomSheetButtonContainer(isSelectedButton)}>
          <TouchableOpacity onPress={() => handlePressButton(buttonName)}>
            <Text style={styles.buttonNameText}>{buttonLabel}</Text>
          </TouchableOpacity>
        </View>
      )
    }

    return (
      <View style={styles.bottomSheetContainer}>
        {listItems?.map((buttonName) =>
          defaultContentButtons(onSelectItemAction, buttonName)
        )}
      </View>
    )
  }

  const hasSpecifiedHeight = snapPoints
  const renderDefaultOrChildrenContent = children || defaultContent()

  return (
    <BottomSheet
      index={index}
      ref={reference}
      snapPoints={hasSpecifiedHeight}
      onChange={handleSheetChanges}
      enablePanDownToClose
      enableDynamicSizing
      style={defaultShadowOptions}
    >
      <BottomSheetView>{renderDefaultOrChildrenContent}</BottomSheetView>
    </BottomSheet>
  )
}
