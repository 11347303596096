import { TouchableOpacity, View } from 'react-native'
import styles from './FamilyCardButton.style'

import {
  DEFAULT_ICON,
  DEFAULT_CUSTOM_STYLES,
  DEFAULT_FUNCTION,
} from './constants'

export default function FamilyCardButton({
  icon = DEFAULT_ICON,
  customStyles = DEFAULT_CUSTOM_STYLES,
  addToMemberButtonAction = DEFAULT_FUNCTION,
}) {
  return (
    <TouchableOpacity
      onPress={addToMemberButtonAction}
      style={[customStyles.itemAccessoryButton, styles.iconButton]}
    >
      <View style={styles.iconContainer}>{icon}</View>
    </TouchableOpacity>
  )
}
