import { i18n } from '$localization/config.js'

const checkForMoreButtons = (UMLSGenes) => {
  const moreButtonTranslation = `${i18n.t('more').default}...`
  const unsureButtonTranslation = i18n.t('unsure').default

  UMLSGenes.length > 9 &&
    UMLSGenes.push({ CUI: 'more', Name: moreButtonTranslation })
  UMLSGenes.push({ CUI: 'unsure', Name: unsureButtonTranslation })
}

export default checkForMoreButtons
