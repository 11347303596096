import { useDispatch, useSelector } from 'react-redux'
import { apiFetchHandler } from '$api'
import { setError } from '$redux/defaults/actions.js'
import { memberAncestry, sortAncestryData } from '../helpers'
import { i18n } from '$localization/config.js'
import { formatText } from '$screens/utils'
import { setShowLoadingView } from '$redux/defaults/actions'
import { PARENTS } from '../index'

const useAncestry = () => {
  const dispatch = useDispatch()
  const setLoadingView = (data) => dispatch(setShowLoadingView(data))
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const probandProfile = useSelector(
    (state) => state.probandStore.probandProfile
  )

  const saveError = (error) => dispatch(setError(error))

  const getAncestryList = async () => {
    const getAncestryPayload = {
      path: `ancestry/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'index.js',
      },
    }

    try {
      const response = await apiFetchHandler(getAncestryPayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        return []
      }

      return response
    } catch (error) {
      console.log(error)
    }
  }

  const sortAncestryList = async () => {
    const ancestries = await getAncestryList()

    const translatedAncestry = ancestries.map((ancestryItem) => {
      const {
        display_name: displayName,
        id,
        ancestry_id: ancestryID,
      } = ancestryItem

      let modifiedAncestryItem

      if (!ancestryID) {
        modifiedAncestryItem = {
          ...ancestryItem,
          ancestry_id: id,
          display_name: i18n.t(formatText(displayName)).default || displayName,
        }
      } else {
        modifiedAncestryItem = {
          ...ancestryItem,
          display_name: i18n.t(formatText(displayName)).default || displayName,
        }
      }
      delete modifiedAncestryItem.id
      return modifiedAncestryItem
    })

    const sortedAncestry = sortAncestryData(translatedAncestry)

    return sortedAncestry
  }

  const errorHandler = (response) =>
    saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })

  const updateAncestry = async (
    parent,
    probandID,
    currentMemberID,
    ancestryData
  ) => {
    if (!probandID || probandID === '') {
      return
    }

    try {
      setLoadingView(true)

      const ancestryString = ancestryData
        .map((item) => item.originalKey)
        .join(',')

      const updateAncestryPayload = {
        path: `member/${currentMemberID}/update_ancestry/`,
        method: 'post',
        token: authCredentials.accessToken,
        body: {
          proband_id: probandID,
          ancestry: ancestryString,
        },
        pageDetails: {
          page: 'AncestryScreen.js',
        },
      }

      const response = await apiFetchHandler(updateAncestryPayload)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingView(false)
    }
  }

  const getParentDetails = ({ ancestryId, parent, selectedAncestryId }) => {
    if (!parent) return
    const parentId =
      parent === PARENTS.MOTHER
        ? probandProfile?.relationshipData?.mother?.id
        : probandProfile?.relationshipData?.father?.id

    const getParentAncestry = memberAncestry({
      memberId: parentId,
      ancestryId,
      authCredentials,
      errorHandler,
      selectedAncestryId,
    })

    return getParentAncestry
  }

  const updateParentData = (parentData) => {
    if (!parentData) return []

    const newSelection = parentData.map((item) => ({
      ...item,
      selected: true,
      display_name:
        i18n.t(formatText(item.display_name)).default || item.display_name,
    }))

    return newSelection
  }

  const getParentAncestry = async (parent) => {
    const getMothersAncestry = getParentDetails({
      parent: PARENTS.MOTHER,
    })
    const getFathersAncestry = getParentDetails({
      parent: PARENTS.FATHER,
    })
    const mothersAncestry =
      updateParentData(await getMothersAncestry.get()) ?? []
    const fathersAncestry =
      updateParentData(await getFathersAncestry.get()) ?? []

    if (parent === PARENTS.MOTHER) {
      return mothersAncestry
    }

    if (parent === PARENTS.FATHER) {
      return fathersAncestry
    }

    return {
      mothersAncestry,
      fathersAncestry,
    }
  }

  const addAncestries = async (parent, ancestries) => {
    for (const data of ancestries) {
      const ancestry = getParentDetails({
        parent,
        ancestryId: data.ancestry_id,
      })
      await ancestry.add()
    }
  }

  const removeAncestry = async (parent, ancestryId) => {
    const ancestry = getParentDetails({
      parent,
      selectedAncestryId: ancestryId,
    })
    await ancestry.remove()
  }

  const removeAncestries = async (parent, ancestries) => {
    for (const data of ancestries) {
      await removeAncestry(parent, data?.id)
    }
  }

  const removeParentsAncestries = async (parent, ancestry) =>
    removeAncestry(parent, ancestry.id)

  return {
    getAncestryList,
    sortAncestryList,
    getParentDetails,
    getParentAncestry,
    addAncestries,
    removeAncestries,
    removeParentsAncestries,
    updateAncestry,
  }
}

export default useAncestry
