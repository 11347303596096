import { Image, Text, TouchableOpacity, View } from 'react-native'
import { useEffect, useState } from 'react'
import { authenticateAsync } from 'expo-local-authentication'
import { useNavigation } from '@react-navigation/core'
import { useDispatch } from 'react-redux'
import biometricsTypeName from '$utils/BiometricsType'
import { i18n } from '$localization/config.js'
import styles from './styles'
import {
  biometricsSupportHandler,
  checkBiometricPermissionDataEnrolled,
  getBiometricName,
} from '$utils/Biometrics'
import { getData } from '$utils/SecureStore.js'
import { IS_ANDROID, IS_WEB } from '$utils/Platforms.js'
import { checkApiVersion } from '../../helpers'
import useAuth from '$hooks/useAuth'
import biometricTypeMapping from './constants'
import { setShowLoadingView } from '$redux/defaults/actions'

function Biometrics() {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [biometricsType, setBiometricsType] = useState(null)
  const [isBiometricEnabled, setIsBiometricEnabled] = useState(false)
  const { handleLoginUser } = useAuth()
  const setLoadingView = (data) => dispatch(setShowLoadingView(data))

  const handleBiometricsCheck = async () => {
    try {
      const isBiometricsSupported = await biometricsSupportHandler()

      if (!isBiometricsSupported.length)
        return { success: false, error: 'Biometrics not supported' }

      const isBiometricsEnrolled = await checkBiometricPermissionDataEnrolled()

      if (!isBiometricsEnrolled)
        return { success: false, error: 'Permissions not granted' }

      const authenticationResult = await authenticateAsync({
        promptMessage: i18n.t('authenticate').default,
        cancelLabel: i18n.t('cancel').default,
      })

      if (!authenticationResult.success) return

      setLoadingView(true)

      const email = await getData({ key: 'email' })
      const password = await getData({ key: 'password' })

      await handleLoginUser(navigation, email, password)
    } catch (error) {
      console.log(
        '🚀 \n\n file: Login.controller.js:563 \n\n LoginController \n\n handleBiometricsCheck= \n\n error:',
        error
      )
    } finally {
      setLoadingView(false)
    }
  }

  const renderBiometricIcon = () => {
    const selectedBiometricType = biometricTypeMapping[biometricsType]

    if (!selectedBiometricType) {
      return biometricTypeMapping?.defaultIcon
    }

    return IS_ANDROID()
      ? selectedBiometricType.android
      : selectedBiometricType.ios
  }

  const handleBiometricsOnMount = async () => {
    try {
      const biometricType = await getBiometricName()
      setBiometricsType(biometricType)

      const biometricAnswer = await getData({ key: 'hasUserAcceptedBiometric' })
      const isBiometricEnrolled = await checkBiometricPermissionDataEnrolled()

      const shouldSetBiometricsEnabled =
        biometricAnswer === 'true' && !IS_WEB() && isBiometricEnrolled

      if (shouldSetBiometricsEnabled) {
        setIsBiometricEnabled(true)
      }

      const isLogout = await getData({ key: 'isLogout' })
      const isApiMismatchWithApp = await checkApiVersion()

      const isNotLoggedOut = !isLogout || isLogout === 'false'

      const shouldCheckBiometrics =
        !isApiMismatchWithApp && isNotLoggedOut && biometricAnswer === 'true'

      if (shouldCheckBiometrics) {
        await handleBiometricsCheck()
      }
    } catch (error) {
      console.log(
        '🚀 \n\n file: Biometrics \n\n handleBiometricsOnMount= \n\n error:',
        error
      )
    }
  }

  useEffect(() => {
    handleBiometricsOnMount()
  }, [])

  if (!isBiometricEnabled || !biometricsType) {
    return null
  }

  return (
    <View style={styles.biometricsContainer}>
      <TouchableOpacity
        onPress={handleBiometricsCheck}
        style={styles.biometricsButton}
      >
        <Image source={renderBiometricIcon()} style={styles.biometricsIcon} />
        <Text style={styles.biometricsText}>
          {i18n.t('biometricsTitle.default', {
            biometricsType: biometricsTypeName(biometricsType),
          })}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default Biometrics
