import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainContainer: {
    height: 60,
    width: '100%',
  },
  textColor: (labelColor) => ({
    marginBottom: 0,
    color: labelColor,
  }),
  nextButtonContainer: (initialBackgroundColor) => ({
    backgroundColor: initialBackgroundColor || 'white',
  }),
})

export default styles
