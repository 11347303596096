/* eslint-disable max-classes-per-file */
import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  Platform,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'

import { connect } from 'react-redux'

import {
  setProbandEthnicity,
  setProbandAshkenazi,
  clearOtherProbandDetails,
  setProbandRace,
} from 'redux/proband/actions.js'
import Icon from 'react-native-vector-icons/Feather'
import { i18n } from '$localization/config.js'

import ResponsiveDarkGraySwitch from '$components/CustomSwitches/ResponsiveDarkGraySwitch'
import { apiFetchHandler } from '$api'

// Components
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { DefaultFullHeight } from '$assets/styles/global.styles.js'
import { platforms } from '$constants'
import FlexContainer from '$components/FlexContainer'

import { setError } from '$redux/defaults/actions.js'
import { saveLastScreen } from '$screens/utils'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

let hasGenderIdentity = false
let isLastScreenBeforeLogin = false

const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.PersonalEthnicity?.name
const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

class PersonalScreenEthnicity extends Component {
  colorSettings = this.props.colorSettings

  selectedClinician = this.props.selectedClinician

  selectedValue = {
    categoryID: '',
    categoryName: '',
    subCategoryID: '',
    subCategoryName: '',
  }

  ethnicityValues = [
    {
      categoryID: 1,
      categoryName: 'american_indian_or_alaska_native',
    },
    {
      categoryID: 2,
      categoryName: 'asian',
    },
    {
      categoryID: 3,
      categoryName: 'black_or_african_american',
    },
    {
      categoryID: 4,
      categoryName: 'hispanic_or_latino',
    },
    {
      categoryID: 5,
      categoryName: 'native_hawaiian',
    },
    {
      categoryID: 6,
      categoryName: 'other_pacific_islander',
    },
    {
      categoryID: 7,
      categoryName: 'other__unknown',
    },
    {
      categoryID: 8,
      categoryName: 'white',
    },
  ]

  ethnicitySubValues = [
    {
      subCategoryID: 1,
      subCategoryName: 'chinese',
    },
    {
      subCategoryID: 2,
      subCategoryName: 'filipino',
    },
    {
      subCategoryID: 3,
      subCategoryName: 'japanese',
    },
    {
      subCategoryID: 4,
      subCategoryName: 'other_asian',
    },
  ]

  constructor(props) {
    super(props)

    this.state = {
      selectedValue: null,
      ashkJewAncestry: false,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (platforms.WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    const { probandProfile, lastScreenLogin, navigation, colorSettings } =
      this.props

    if (probandProfile && probandProfile.profileData.genderIdentity) {
      hasGenderIdentity = true
    } else {
      hasGenderIdentity = false
    }

    isLastScreenBeforeLogin = lastScreenLogin === 'ethnicity'

    navigation.setParams({
      hasGenderIdentity,
      isLastScreenBeforeLogin,
    })

    if (probandProfile) {
      this.setEthnicity(probandProfile)
      this.setAshkenazi(probandProfile)
    }
  }

  componentWillUnmount() {
    if (platforms.WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  setEthnicity = (probandProfile) => {
    const { profileData } = probandProfile
    if (!profileData.ethnicity) return

    const selectedValue = {
      categoryID: '',
      categoryName: '',
      subCategoryID: '',
      subCategoryName: '',
    }

    if (profileData.ethnicity.includes(i18n.t('asian').default)) {
      const stringSplit = profileData.ethnicity.split(':')
      const mainCategory = stringSplit[0]
      const subCategory = stringSplit[1]

      const selectedMainCategory = this.ethnicityValues.find(
        (val) => i18n.t(val.categoryName).default === mainCategory
      )
      const selectedSubCategory = this.ethnicitySubValues.find(
        (val) => i18n.t(val.subCategoryName).default === subCategory
      )

      selectedValue.categoryID = selectedMainCategory.categoryID
      selectedValue.categoryName = mainCategory
      selectedValue.subCategoryID = selectedSubCategory.subCategoryID
      selectedValue.subCategoryName = subCategory
    } else {
      const selectedEthnicity = this.ethnicityValues.find(
        (val) => i18n.t(val.categoryName).default === profileData.ethnicity
      )
      selectedValue.categoryID = selectedEthnicity.categoryID
      selectedValue.categoryName = profileData.ethnicity
    }

    this.setState({
      selectedValue,
    })
  }

  setAshkenazi = (probandProfile) => {
    const { profileData } = probandProfile
    let hasAshkenazi = false

    if (profileData.ashkenazi) {
      hasAshkenazi = true
    } else {
      hasAshkenazi = false
    }

    this.setState({
      ashkJewAncestry: hasAshkenazi,
    })
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  toggleAshkJewAncestry = (value) => {
    this.setState({ ashkJewAncestry: value })
  }

  nextButtonAction = async () => {
    const { race_ethnicity_display = 'show_ashkanazi_and_race' } =
      this.selectedClinician || 'show_ashkanazi_and_race'
    const { account, authCredentials, saveError, navigation } = this.props

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.ethnicity,
    }

    if (race_ethnicity_display !== 'show_ashkanazi') {
      if (this.state.selectedValue === null) return

      const { selectedValue } = this.state
      let ethnicity = selectedValue.categoryName

      if (selectedValue.subCategoryName != '') {
        ethnicity += `:${selectedValue.subCategoryName}`
      }

      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })

      this.props._saveProbandEthnicity_(ethnicity)
      this.props._saveProbandAshkenazi_(this.state.ashkJewAncestry)

      await this.updatePersonalInfo(ethnicity)

      return
    }

    await this.updatePersonalInfo(null)

    this.props._saveProbandAshkenazi_(this.state.ashkJewAncestry)

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })
  }

  updatePersonalInfo = async (ethnicity) => {
    const { proband, authCredentials, saveError } = this.props
    const { ashkJewAncestry } = this.state

    const updatePersonalInfoPayload = {
      path: 'member/update_personal_info/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        proband_id: proband.probandID,
        member_id: proband.probandID,
        member_info: {
          ethnicity,
          ashkenazi: ashkJewAncestry,
        },
      },
      pageDetails: {
        page: 'PersonalScreenEthnicity.js',
      },
    }

    const response = await apiFetchHandler(updatePersonalInfoPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.props._clearOtherProbandDetails_()

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.PersonalAncestry.name,
    })

    handleNavigateToNextScreen({
      navigation: this.props.navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  onSelectItem = (value) => {
    const selectedValue = {
      categoryID: value.categoryID,
      categoryName: value.categoryName,
      subCategoryID: value.subCategoryID,
      subCategoryName: value.subCategoryName,
    }

    this.setState({ selectedValue })
  }

  createProband = () => {
    this.props._clearOtherProbandDetails_()
    this.props.navigation.navigate('PersonalAncestry')
  }

  toolTipHandler = () => {
    showDefaultAlert(
      i18n.t('ethnicity').default,
      i18n.t(
        'these_broad_categories_are_used_for_risk_assessment_please_choose_the_one_that_most_accurately_describes_you'
      ).default
    )
  }

  toggleNextButtonColor = () => {
    const { race_ethnicity_display = 'show_ashkanazi_and_race' } =
      this.selectedClinician || 'show_ashkanazi_and_race'
    const style = {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled || 'white',
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }

    const isValid = this.state.selectedValue
    if (isValid !== null || race_ethnicity_display === 'show_ashkanazi') {
      style.backgroundColor =
        this.colorSettings?.bottom_next_btn_enabled || 'white'
      style.borderColor = this.colorSettings?.bottom_next_btn_enabled
    }

    return style
  }

  toggleNextButtonText = () => {
    const { race_ethnicity_display = 'show_ashkanazi_and_race' } =
      this.selectedClinician || 'show_ashkanazi_and_race'
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const isValid = this.state.selectedValue
    if (isValid !== null || race_ethnicity_display === 'show_ashkanazi') {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  renderAncestryText = () => (
    <View style={{ flex: 1.0, flexDirection: 'row', marginTop: 20 }}>
      <View style={{ flex: 1.0, marginHorizontal: 20 }}>
        <Text
          style={[
            styles.subInfoLabelText,
            {
              color: this.colorSettings?.text_input_title_color_2 || 'black',
            },
          ]}
        >
          {
            i18n.t(
              'choose_only_one_that_best_describes_you__you_can_enter_more_detailed_ancestry_on_the_next_page'
            ).default
          }
        </Text>
      </View>
    </View>
  )

  renderAshkenaziJewishText = () => (
    <View style={{ flex: 1.0, flexDirection: 'row', marginTop: 20 }}>
      <View style={{ flex: 1.0, marginHorizontal: 20 }}>
        <Text
          style={[
            styles.subInfoLabelText,
            {
              color: this.colorSettings?.text_input_title_color_2 || 'black',
            },
          ]}
        >
          {
            i18n.t(
              'ashkenazi_jewish_ancestry_can_affect_risk_for_certain_conditions_this_will_help_more_accurately_assess_your_risk'
            ).default
          }
        </Text>
      </View>
    </View>
  )

  renderRaceOptions = () => (
    <View style={{ marginBottom: 20, marginTop: 20 }}>
      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={1}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('american_indian_or_alaska_native').default}
      />

      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={2}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('asian').default}
        collapsible
        subItemList={[
          i18n.t('chinese').default,
          i18n.t('filipino').default,
          i18n.t('japanese').default,
          i18n.t('other_asian').default,
        ]}
      />

      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={3}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('black_or_african_american').default}
      />

      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={4}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('hispanic_or_latino').default}
      />

      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={5}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('native_hawaiian').default}
      />

      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={6}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('other_pacific_islander').default}
      />

      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={7}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('other__unknown').default}
      />

      <ItemButton
        colorSettings={this.colorSettings}
        categoryID={8}
        selectedValue={this.state.selectedValue}
        onSelectItem={this.onSelectItem}
        labelText={i18n.t('white').default}
      />
    </View>
  )

  render() {
    const { race_ethnicity_display = 'show_ashkanazi_and_race' } =
      this.selectedClinician || 'show_ashkanazi_and_race'

    const isShowAshkanaziSelected = race_ethnicity_display === 'show_ashkanazi'
    const isShowBothAshkenaziAndRaceOrNullSelected =
      race_ethnicity_display === 'show_ashkanazi_and_race' ||
      race_ethnicity_display === null

    return (
      <SafeAreaView
        style={[
          styles.container,
          {
            backgroundColor: this.colorSettings?.onboarding_bgcolor || 'white',
          },
        ]}
      >
        {/* Content Body */}
        <ScrollView
          style={{ height: this.state.scrollViewHeight }}
          contentContainerStyle={styles.contentContainer}
        >
          <FlexContainer>
            <View
              style={{
                marginVertical: 30,
                width: '100%',
                backgroundColor: 'transparent',
              }}
            >
              {/* <Title text container> */}
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Text
                  style={[
                    styles.titleText,
                    {
                      color:
                        this.colorSettings?.text_input_title_color_2 || 'black',
                    },
                  ]}
                >
                  {i18n.t('what_is_your_raceethnicity').default}
                </Text>
                <View>
                  <TouchableOpacity
                    style={{ padding: 10 }}
                    onPress={() => this.toolTipHandler()}
                  >
                    <Icon
                      name="info"
                      size={20}
                      color={
                        this.colorSettings?.text_input_title_color_2 || 'black'
                      }
                    />
                  </TouchableOpacity>
                </View>
              </View>
              {/* </Title text container> */}

              {/* <Ashkenazi Jewish ancestry> */}
              <View
                style={{
                  flex: 1.0,
                  flexDirection: 'row',
                  marginBottom: 5,
                  marginTop: 10,
                }}
              >
                <View style={{ flex: 0.8 }}>
                  <Text
                    style={[
                      styles.switchLabelText,
                      {
                        color:
                          this.colorSettings?.text_input_title_color_2 ||
                          'black',
                      },
                    ]}
                  >
                    {
                      i18n.t('do_you_have_any_ashkenazi_jewish_ancestry')
                        .default
                    }
                  </Text>
                </View>
                <View
                  style={{
                    flex: 0.2,
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  <ResponsiveDarkGraySwitch
                    onValueChange={(value) => this.toggleAshkJewAncestry(value)}
                    value={this.state.ashkJewAncestry}
                  />
                </View>
              </View>
              {/* </Ashkenazi Jewish ancestry> */}

              {isShowAshkanaziSelected && this.renderAshkenaziJewishText()}
              {isShowBothAshkenaziAndRaceOrNullSelected && (
                <>
                  {this.renderAncestryText()}
                  {this.renderRaceOptions()}
                </>
              )}
            </View>
          </FlexContainer>
        </ScrollView>

        {/* Next button */}
        <View style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}>
          <View style={styles.nextButtonContainer}>
            <TouchableOpacity
              style={[
                styles.nextButton,
                this.toggleNextButtonColor(),
                {
                  borderWidth: 1.0,
                },
              ]}
              onPress={this.nextButtonAction}
              disabled={
                race_ethnicity_display !== 'show_ashkanazi' &&
                this.state.selectedValue == null
              }
            >
              <Text
                style={[
                  styles.nextButtonText,
                  {
                    color: this.toggleNextButtonText(),
                  },
                ]}
              >
                {i18n.t('next').default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }
}

class ItemButton extends Component {
  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      buttonActive: false,
      subItemList: this.props.subItemList ? this.props.subItemList : [],
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.selectedValue !== this.props.selectedValue) return true

    return false
  }

  componentDidUpdate() {
    const { categoryID, selectedValue } = this.props
    if (categoryID === selectedValue.categoryID) {
      // this.buttonOnPress()
    }
  }

  itemIsSelected = () => {
    let buttonActive = false
    const { selectedValue } = this.props

    if (selectedValue) {
      if (selectedValue.categoryID === this.props.categoryID) {
        buttonActive = true
      }
    }

    return buttonActive
  }

  getButtonBackgroundColor = () =>
    this.itemIsSelected()
      ? this.colorSettings?.selectable_btn_active_1 || 'rgba(0, 0, 0, 0.2)'
      : this.colorSettings?.selectable_btn_inactive_1 || 'white'

  buttonOnPress = () => {
    const buttonActive = !this.state.buttonActive
    const selectedValue = {
      categoryID: this.props.categoryID,
      categoryName: this.props.labelText,
      subCategoryID: '',
      subCategoryName: '',
    }

    this.setState({
      buttonActive,
      selectedValue,
    })

    this.props.onSelectItem(selectedValue)
  }

  onSelectSubItem = (value) => {
    const selectedValue = {
      categoryID: value.categoryID,
      categoryName: value.categoryName,
      subCategoryID: value.subCategoryID,
      subCategoryName: value.subCategoryName,
    }

    this.props.onSelectItem(selectedValue)
    this.setState({ selectedValue })
  }

  getSubItems = () => {
    const subItems = []
    const { subItemList } = this.state

    if (subItemList.length > 0) {
      for (let i = 0; i < subItemList.length; i++) {
        subItems.push(
          <SubItemButton
            colorSettings={this.colorSettings}
            key={i}
            subCategoryID={i + 1}
            selectedValue={this.props.selectedValue}
            onSelectSubItem={this.onSelectSubItem}
            labelText={subItemList[i]}
            buttonActive={false}
          />
        )
      }

      return subItems
    }
  }

  renderCollapsibleView = () => {
    if (this.props.collapsible && this.itemIsSelected()) {
      return (
        <View
          style={{
            marginBottom: 20,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={{ marginVertical: 20 }}>
            <Text
              style={[
                styles.switchLabelText,
                {
                  textAlign: 'center',
                  color:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                },
              ]}
            >
              {`${i18n.t('please_specify').default}:`}
            </Text>
          </View>

          {this.getSubItems()}
        </View>
      )
    }
  }

  render() {
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TouchableOpacity
          activeOpacity={1.0}
          style={[
            styles.itemButton,
            {
              backgroundColor: this.getButtonBackgroundColor(),
              borderColor:
                this.colorSettings?.selectable_btn_border_inactive_1 || 'black',
            },
          ]}
          onPress={this.buttonOnPress}
        >
          <Text
            style={[
              styles.itemButtonText,
              {
                color:
                  this.colorSettings?.selectable_btn_text_active_1 || 'black',
              },
            ]}
          >
            {this.props.labelText}
          </Text>
        </TouchableOpacity>

        {this.renderCollapsibleView()}
      </View>
    )
  }
}

class SubItemButton extends Component {
  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)
    this.state = {
      buttonActive: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.selectedValue !== this.props.selectedValue) {
      return true
    }
    return false
  }

  componentDidMount() {
    if (this.props.selectedValue) {
      this.itemIsSelected()
    }
  }

  itemIsSelected = () => {
    let buttonActive = false
    const { selectedValue } = this.props

    if (selectedValue) {
      if (selectedValue.subCategoryID === this.props.subCategoryID) {
        buttonActive = true
      }
    }

    return buttonActive
  }

  getButtonBackgroundColor = () =>
    this.itemIsSelected()
      ? this.colorSettings?.selectable_btn_active_1 || 'rgba(0, 0, 0, 0.2)'
      : this.colorSettings?.selectable_btn_inactive_1 || 'white'

  buttonOnPress = () => {
    const buttonActive = !this.state.buttonActive
    const parentSelectedValue = this.props.selectedValue

    const selectedValue = {
      categoryID: parentSelectedValue.categoryID,
      categoryName: parentSelectedValue.categoryName,
      subCategoryID: this.props.subCategoryID,
      subCategoryName: this.props.labelText,
    }

    this.setState({
      buttonActive,
      selectedValue,
    })

    this.props.onSelectSubItem(selectedValue)
  }

  render() {
    return (
      <ErrorBoundary>
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TouchableOpacity
            activeOpacity={1.0}
            style={[
              styles.subItemButton,
              {
                backgroundColor: this.getButtonBackgroundColor(),
                borderColor:
                  this.colorSettings?.selectable_btn_border_inactive_1 ||
                  'black',
              },
            ]}
            onPress={this.buttonOnPress}
          >
            <Text
              style={[
                styles.subItemButtonText,
                {
                  color:
                    this.colorSettings?.selectable_btn_text_active_1 || 'black',
                },
              ]}
            >
              {this.props.labelText}
            </Text>
          </TouchableOpacity>
        </View>
      </ErrorBoundary>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
  },
  contentContainer: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: 'rgba(155,89,182,1.0)',
    overflow: 'hidden',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  progressText: {
    justifyContent: 'center',
    color: 'rgba(255,255,255,0.25)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },

  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
  },

  itemButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 60,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 30,
  },
  itemButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },

  subItemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 195,
    height: 40,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 20,
  },
  subItemButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },

  switchLabelText: {
    color: '#fff',
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'left',
  },

  subInfoLabelText: {
    color: '#fff',
    fontFamily: 'karla',
    fontSize: 18,
    textAlign: 'center',
  },

  nextButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  nextButtonContainer: {
    height: 60,
  },
})

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore, clinicianStore } = state
  return {
    colorSettings: store.colorSettings,
    account: accountStore.account,
    selectedClinician: clinicianStore.selectedClinician,
    authCredentials: accountStore.authCredentials,
    probandProfile: probandStore.probandProfile,
    lastScreenLogin: store.lastScreenLogin,
    proband: probandStore.proband,
  }
}

const actionCreator = {
  _saveProbandEthnicity_: setProbandEthnicity,
  _saveProbandAshkenazi_: setProbandAshkenazi,
  _clearOtherProbandDetails_: clearOtherProbandDetails,
  _saveProbandRace_: setProbandRace,
  saveError: setError,
}

export default connect(
  mapGlobalStateToProps,
  actionCreator
)(PersonalScreenEthnicity)
