function updateSurveyAnswers({ surveyData, hasRendered }) {
  const surveyDataWithClearedAnswers = {
    ...surveyData,
    completed_survey_answers: [],
  }

  const newSurveyData = hasRendered ? surveyDataWithClearedAnswers : surveyData

  return newSurveyData
}

export default updateSurveyAnswers
