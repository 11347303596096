import moment from 'moment'

import { dateFormats } from '$constants'

import { fixedTimeZoneOffset, calculateAge, parseDateOfBirth } from '$utils'

export default function yearIntervalFromNow(
  date,
  isBirthday = false,
  useUnits = false
) {
  const parsedDate = parseDateOfBirth(date)

  const targetDate = moment(
    moment(parsedDate).format(dateFormats.DEFAULT_DATE_FORMAT_API)
  )

  if (isBirthday && useUnits) {
    const calculatedAgeWithUnit = calculateAge(new Date(targetDate))

    return calculatedAgeWithUnit
  }

  if (isBirthday) {
    return moment().diff(targetDate, 'years')
  }

  const timezoneOffset = fixedTimeZoneOffset()

  const now = moment(moment().format(dateFormats.DEFAULT_DATE_FORMAT_API)).add(
    timezoneOffset,
    'hours'
  )
  const targetDateForSingupScreen = moment(
    moment(date).format(dateFormats.DEFAULT_DATE_FORMAT_API)
  )
  const intervalYears = now.diff(targetDateForSingupScreen, 'years')

  if (!intervalYears) return 0

  return intervalYears
}
