import { StyleSheet } from 'react-native'

const GRAY = 'rgba(153,153,153,0.7)'
const GREYISH_BLACK = 'rgba(74,74,74,0.2)'

const styles = StyleSheet.create({
  buttonContainer: {
    width: '100%',
    marginTop: 8,
  },
  button: {
    borderWidth: 2,
    flexDirection: 'row',
    width: '100%',
    height: 50,
    borderColor: GREYISH_BLACK,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: GRAY,
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },
})

export default styles
