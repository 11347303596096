import { StyleSheet } from 'react-native'

const BLACK = '#000'
const GREYISH_BROWN = 'rgb(102,102,102)'
const VIOLET = 'rgb(155,89,182)'

const styles = StyleSheet.create({
  modalContentRowContainer: {
    borderColor: BLACK,
    flex: 0.6,
    paddingBottom: 45,
  },
  modalContentRowLinkContainer: { height: 38, flexDirection: 'row' },
  modalContentRowLinkTextContainer: { flex: 0.75, justifyContent: 'center' },
  modalContentRowLinkText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    paddingRight: 5,
    color: GREYISH_BROWN,
  },
  modalContentRowLinkTextLinked: (colorSettings) => ({
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: colorSettings?.linked_text || VIOLET,
  }),
  modalContentRowLinkSwitchContainer: {
    flex: 0.25,
    justifyContent: 'center',
  },
  modalContainerRowConfirmationTextContainer: {
    flex: 1.0,
    flexDirection: 'row',
  },
  modalContainerRowConfirmationText: {
    fontFamily: 'montserrat',
    fontSize: 15,
    lineHeight: 24,
    color: GREYISH_BROWN,
  },
})

export default styles
