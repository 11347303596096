import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import styles from './styles'
import { SHORTEN_GENDER_IDENTITIES } from '../../../../constants'

function GenderIdentityOptionsItem({
  buttonLabel,
  onSelect,
  selected,
  iconType,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const getIconStyle = () => {
    switch (iconType) {
      case SHORTEN_GENDER_IDENTITIES.MALE:
        return styles.maleIcon(colorSettings)
      case SHORTEN_GENDER_IDENTITIES.FEMALE:
        return styles.femaleIcon(colorSettings)
      case SHORTEN_GENDER_IDENTITIES.NON_BINARY:
        return styles.nonBinaryIcon(colorSettings)
      default:
        return styles.maleIcon(colorSettings)
    }
  }

  return (
    <TouchableOpacity
      style={
        selected
          ? styles.selectedGenderIdentityOptionsItemButton(colorSettings)
          : styles.genderIdentityOptionsItemsButton
      }
      onPress={() => onSelect(iconType)}
    >
      <View style={getIconStyle()} />
      {iconType === SHORTEN_GENDER_IDENTITIES.NON_BINARY && (
        <View style={styles.nonBinaryIconBottom(colorSettings)} />
      )}

      <Text style={styles.genderIdentityOptionsItemsButtonText(colorSettings)}>
        {buttonLabel}
      </Text>
    </TouchableOpacity>
  )
}

export default GenderIdentityOptionsItem
