import { View, TextInput } from 'react-native'
import { useSelector } from 'react-redux'

import global_styles from '$assets/styles/global.styles.js'
import styles from './styles'

import {
  PLACEHOLDER,
  MAX_LENGTH,
  KEYBOARD_TYPE,
  AUTO_CAPITALIZE,
  CLEAR_TEXT_ON_FOCUS,
  DEFAULT_PLACEHOLDER_TEXT_COLOR,
} from './constants'

export default function DiseaseTextInput({ containerStyle }, props) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const placeholderTextColor =
    colorSettings?.text_input_placeholder_color_2 ||
    DEFAULT_PLACEHOLDER_TEXT_COLOR
  const selectionColor = colorSettings?.text_input_color_2

  return (
    <View
      style={[
        global_styles.mainTextInputContainer,
        containerStyle,
        styles.container(colorSettings),
      ]}
    >
      <TextInput
        {...props}
        style={[
          global_styles.mainTextInput,
          styles.textInputColor(colorSettings),
        ]}
        placeholder={PLACEHOLDER}
        placeholderTextColor={placeholderTextColor}
        selectionColor={selectionColor}
        maxLength={MAX_LENGTH}
        keyboardType={KEYBOARD_TYPE}
        autoCapitalize={AUTO_CAPITALIZE}
        clearTextOnFocus={CLEAR_TEXT_ON_FOCUS}
      />
    </View>
  )
}
