import { StyleSheet } from 'react-native'

const WHITE = '#fff'
const BLACK = '#000'

const styles = StyleSheet.create({
  modalHeaderContainer: (colorSettings) => ({
    backgroundColor: colorSettings?.modal_top_title_bar_bg || WHITE,
    paddingHorizontal: 16,
    paddingVertical: 8,
    height: 170,
  }),
  modalHeader: { flex: 1.0, flexDirection: 'row' },
  modalHeaderTextContainer: { justifyContent: 'center', flex: 0.7 },
  modalHeaderTitleText: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 18,
    color: colorSettings?.modal_top_title_bar_text || BLACK,
  }),
  modalHeaderIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.3,
  },
  modalHeaderIcon: {
    width: 99,
    height: 99,
    resizeMode: 'contain',
  },
})

export default styles
