import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, View, Dimensions, InteractionManager } from 'react-native'
import useStateWithCallback from 'use-state-with-callback'

import { i18n } from '$localization/config.js'
import RelativeSideCounter from './components/RelativeSideCounter'

import styles from './styles'
import DarkGraySwitch from '../CustomSwitches/DarkGraySwitch'

import {
  PARENTS_TITLE,
  OPERATION,
  FAMILY_MEMBER,
  EMPTY_STRING,
  WINDOW_DIMENSION,
  DECIMAL_RADIX,
  INCREMENT,
  DECREMENT,
  COUNT_ZERO,
  PARENT_COUNT_WHEN_ADDED,
  PARENT_COUNT_WHEN_NOT_ADDED,
  PARENT_COUNT_WHEN_SWITCH_OFF,
  PARENT_COUNT_WHEN_SWITCH_ON,
} from './constants'

export default function FamilyMembersCounter({
  isActive: isActiveProp,
  onPress: onPressHandler,
  relativeSide: relativeSideProp,
  keyRef,
  handleRelativeCount,
  titleText,
  hasMemberParents,
  relationToProband,
  triggerParentSelect,
  parentsSwitch,
  setParentsSwitch,
}) {
  const [relativeCount, setRelativeCount] = useState(COUNT_ZERO)
  const [momSideCount, setMomSideCount] = useState(COUNT_ZERO)
  const [dadSideCount, setDadSideCount] = useState(COUNT_ZERO)
  const [isActive, setIsActive] = useStateWithCallback(
    isActiveProp ?? false,
    (prevState) => {
      if (isActive && relativeCount && momSideCount && dadSideCount) {
        setIsActive(prevState)
        setRelativeCount(relativeCount)
        setMomSideCount(momSideCount)
        setDadSideCount(dadSideCount)
        return () => setRelativeCountHandler()
      }
    }
  )
  const familyMemberTitleText = i18n?.t(`${titleText}`)?.title ?? titleText
  const isParentsRelationship = titleText === PARENTS_TITLE

  const colorSettings = useSelector((state) => state.store.colorSettings)

  const performOperation = (operator, value) => {
    if (operator === OPERATION.ADDITION)
      return parseInt(value, DECIMAL_RADIX) + INCREMENT
    if (operator === OPERATION.SUBSTRACTION)
      return parseInt(value, DECIMAL_RADIX) - DECREMENT
    return parseInt(value, DECIMAL_RADIX)
  }

  const handleAddParentsWhileSiblingsAdded = (shouldAddParents) => {
    const isParentAndSelectedSiblings =
      relationToProband === FAMILY_MEMBER.GRANDPARENT &&
      (keyRef === FAMILY_MEMBER.SISTER || keyRef === FAMILY_MEMBER.BROTHER) &&
      !hasMemberParents

    if (isParentAndSelectedSiblings) {
      triggerParentSelect()
      setParentsSwitch(shouldAddParents)
      handleRelativeCount({
        relative: FAMILY_MEMBER.PARENT,
        count: shouldAddParents
          ? PARENT_COUNT_WHEN_ADDED
          : PARENT_COUNT_WHEN_NOT_ADDED,
      })
    }
  }

  const setRelativeCountHandler = (relativeSide, operator) => {
    const isSubstraction = operator === OPERATION.SUBSTRACTION

    switch (relativeSide) {
      case FAMILY_MEMBER.MOM:
        if (momSideCount <= COUNT_ZERO && isSubstraction) return

        const momCount = performOperation(operator, momSideCount)

        handleRelativeCount({ relative: keyRef, count: momCount, relativeSide })

        setMomSideCount(momCount)
        break
      case FAMILY_MEMBER.DAD:
        if (dadSideCount <= COUNT_ZERO && isSubstraction) return

        const dadCount = performOperation(operator, dadSideCount)

        handleRelativeCount({ relative: keyRef, count: dadCount, relativeSide })

        setDadSideCount(dadCount)
        break
      default:
        if (relativeCount <= COUNT_ZERO && isSubstraction) return

        const count = performOperation(operator, relativeCount)

        const shouldAddParents = handleRelativeCount({
          relative: keyRef,
          count,
          relativeSide,
        })

        handleAddParentsWhileSiblingsAdded(shouldAddParents)
        setRelativeCount(count)
    }
  }

  const onPress = ({ relativeSide, operator }) => {
    if (isActive) {
      setRelativeCountHandler(relativeSide, operator)
      return
    }

    onPressHandler(keyRef)

    setIsActive(() => {
      setRelativeCount(relativeCount)
      setMomSideCount(momSideCount)
      setDadSideCount(dadSideCount)

      return InteractionManager.runAfterInteractions(() =>
        setRelativeCountHandler(relativeSide, operator)
      )
    })
  }

  const handleTypedRelations = (relativeSide, value) => {
    switch (relativeSide) {
      case FAMILY_MEMBER.MOM:
        handleRelativeCount({ relative: keyRef, count: value, relativeSide })

        setMomSideCount(value)
        break
      case FAMILY_MEMBER.DAD:
        handleRelativeCount({ relative: keyRef, count: value, relativeSide })

        setDadSideCount(value)
        break
      default:
        const shouldAddParents = handleRelativeCount({
          relative: keyRef,
          count: value,
          relativeSide,
        })
        handleAddParentsWhileSiblingsAdded(shouldAddParents)
        setRelativeCount(value)
    }
  }

  const handleParentRelative = () => {
    triggerParentSelect()
    setParentsSwitch(!parentsSwitch)
    handleRelativeCount({
      relative: FAMILY_MEMBER.PARENT,
      count: parentsSwitch
        ? PARENT_COUNT_WHEN_SWITCH_ON
        : PARENT_COUNT_WHEN_SWITCH_OFF,
    })
  }

  const onChange = ({ value, relativeSide }) =>
    handleTypedRelations(relativeSide, +value)

  const onFocus = (relativeSide) => {
    onPressHandler()
    handleTypedRelations(relativeSide, EMPTY_STRING)
  }

  const inputValidation = (relativeSide) => {
    const isInputFilled =
      relativeCount > COUNT_ZERO || Number.isNaN(relativeCount)

    const isDadOrMomSideInputFilled =
      momSideCount > COUNT_ZERO || dadSideCount > COUNT_ZERO
    if (isInputFilled || isDadOrMomSideInputFilled) return

    handleTypedRelations(relativeSide, COUNT_ZERO)
  }

  const { width: screenWidth } = Dimensions.get(WINDOW_DIMENSION)

  const familyMembersCount = {
    relativeCount,
    momSideCount,
    dadSideCount,
  }

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.emptyContainer} />

        <View style={styles.textContainer(screenWidth)}>
          <Text style={styles.buttonText(colorSettings)}>
            {familyMemberTitleText}
          </Text>
        </View>

        <View style={styles.buttonContainer}>
          {isParentsRelationship && !hasMemberParents ? (
            <View style={styles.switchContainer}>
              <DarkGraySwitch
                key={parentsSwitch}
                value={parentsSwitch}
                onValueChange={handleParentRelative}
              />
            </View>
          ) : (
            <RelativeSideCounter
              relativeSide={relativeSideProp}
              colorSettings={colorSettings}
              familyMembersCount={familyMembersCount}
              inputValidation={inputValidation}
              onPress={onPress}
              onChange={onChange}
              isEditable
              onFocus={onFocus}
              onPressHandler={onPressHandler}
            />
          )}
        </View>

        <View style={styles.emptyContainer} />
      </View>
    </View>
  )
}
