import { View, Text, TouchableOpacity } from 'react-native'
import { i18n } from '$localization/config.js'

import styles from './styles'

export default function LearnMore({
  navigateToSwitchToFamgenixAccount,
  colorSettings,
}) {
  const continueTrackingMessage = i18n.t(
    'want_to_continue_to_track_your_family_health_history_and_share_with_other_clinicians_or_family_members'
  )?.default

  const learnMoreButtonText = i18n.t('learn_more')?.default

  return (
    <View style={styles.learnMoreButtonContainer}>
      <Text style={styles.bodyText(colorSettings)}>
        {continueTrackingMessage}
      </Text>

      <TouchableOpacity onPress={navigateToSwitchToFamgenixAccount}>
        <Text style={styles.learnMoreButtonText}>{learnMoreButtonText}</Text>
      </TouchableOpacity>
    </View>
  )
}
