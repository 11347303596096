import { Platform, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  genderIdentityContainer: { zIndex: 1 },
  genderIdentitySeparatorLine: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  genderIdentityContentItem: {
    marginHorizontal: 16,
    marginVertical: 8,
    flexDirection: 'row',
    height: 50,
  },
  genderIdentityLabelContainer: {
    justifyContent: 'center',
    flex: 0.4,
  },
  genderIdentityLabelText: (colorSettings) => ({
    color: colorSettings?.text_input_label_color_1 ?? 'rgba(153,153,153,1.0)',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'left',
  }),
  genderIdentityInputContainer: {
    flex: 0.6,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  genderIdentityInputButton: {
    width: '50%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  genderIdentityInputText: (hasValue, colorSettings) => ({
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    textAlign: 'right',
    color: hasValue
      ? colorSettings?.text_input_color_1
      : colorSettings?.text_input_placeholder_color_1,
  }),
})

export default styles
