import { SafeAreaView } from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'
import { useNavigation } from '@react-navigation/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MainWrapper from '$components/MainWrapper'
import styles from './styles'
import ListContainer from './components/ListContainer'
import { setError, setShowLoadingView } from '$redux/defaults/actions'
import { i18n } from '$localization/config.js'
import { apiFetchHandler } from '$api'

function NotificationScreen() {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const [notificationList, setNotificationList] = useState([])

  const {
    proband: { probandID = null },
    authCredentials: { accessToken = '' },
  } = useSelector((state) => ({
    authCredentials: state.accountStore.authCredentials,
    proband: state.probandStore.proband,
  }))

  const showLoadingView = (data) => dispatch(setShowLoadingView(data))
  const saveError = (err) => dispatch(setError(err))
  const getNotificationList = async () => {
    if (!probandID) return

    const notificationLanguage = i18n.currentLocale().slice(0, 2)

    try {
      showLoadingView(true)

      const getMemberNotificationsPayload = {
        path: 'member/notification/',
        method: 'post',
        token: accessToken,
        body: {
          proband_id: probandID,
          lang: notificationLanguage,
        },
        pageDetails: {
          page: 'ListContainer.main.js',
        },
      }

      const response = await apiFetchHandler(getMemberNotificationsPayload)

      if (response?.isError) {
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }

      setNotificationList([{ title: 'Notifications', data: response }])
    } catch (err) {
      console.log(
        '🚀 \n\n file: NotificationScreen \n\n getNotificationList:',
        err
      )
    } finally {
      showLoadingView(false)
    }
  }

  const handleChangeNotification = (notificationID) => {
    const notifications = notificationList[0].data

    const filteredListData = notifications.filter(
      (notification) => notification.id !== notificationID
    )

    const newNotificationList = [
      { ...notificationList[0], data: filteredListData },
    ]

    setNotificationList(newNotificationList)
  }

  useEffect(() => {
    getNotificationList().catch((error) => console.log(error))
  }, [])

  return (
    <ErrorBoundary>
      <MainWrapper navigation={navigation}>
        <SafeAreaView style={styles.container}>
          <ListContainer
            listData={notificationList}
            handleChangeNotification={handleChangeNotification}
            getNotificationList={getNotificationList}
          />
        </SafeAreaView>
      </MainWrapper>
    </ErrorBoundary>
  )
}

export default NotificationScreen
