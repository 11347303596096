import { envConstants } from '$constants'

import { i18n } from '$localization/config.js'

const GeneticResourcesGenerator = (countryCode = 'US') => {
  const data = {
    US: [
      {
        title: i18n.t('about_genetic_counseling')?.default,
        content: i18n.t(
          'learn_about_the_healthcare_professionals_who_specialize_in_genetics_and_how_it_affects_health'
        )?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/us-gene-1.html`,
      },
      {
        title: i18n.t('find_a_genetic_counselor')?.default,
        content: i18n.t(
          'find_a_genetic_counselor_near_you_or_schedule_an_appointment_to_talk_to_a_genetic_counselor_online_or_on_the_phone_to_have_your_questions_answered'
        )?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/us-gene-2.html`,
      },
      {
        title: i18n.t('genetics_and_health')?.default,
        content: i18n.t('resources_for_learning_about_genetics_and_health')
          ?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/us-gene-3.html`,
      },
      {
        title: i18n.t('hereditary_cancer')?.default,
        content: i18n.t('find_out_more_about_cancers_that_run_in_families')
          ?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/us-gene-4.html`,
      },
    ],

    UK: [
      {
        title: i18n.t('about_genetic_counseling')?.default,
        content: i18n.t(
          'learn_about_the_healthcare_professionals_who_specialize_in_genetics_and_how_it_affects_health'
        )?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/uk-gene-1.html`,
      },
      {
        title: i18n.t('genetics_and_health')?.default,
        content: i18n.t('resources_for_learning_about_genetics_and_health')
          ?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/uk-gene-2.html`,
      },
      {
        title: i18n.t('hereditary_cancer')?.default,
        content: i18n.t('find_out_more_about_cancers_that_run_in_families')
          ?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/uk-gene-3.html`,
      },
      {
        title: i18n.t('peer_support')?.default,
        content: i18n.t(
          'connect_with_organizations_or_individuals_and_find_resources'
        )?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/uk-gene-4.html`,
      },
    ],

    AU: [
      {
        title: i18n.t('about_genetic_counseling')?.default,
        content: i18n.t(
          'learn_about_the_healthcare_professionals_who_specialize_in_genetics_and_how_it_affects_health'
        )?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/au-gene-1.html`,
      },
      {
        title: i18n.t('find_a_genetic_counselor')?.default,
        content: i18n.t(
          'find_a_genetic_counselor_near_you_or_schedule_an_appointment_to_talk_to_a_genetic_counselor_online_or_on_the_phone_to_have_your_questions_answered'
        )?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/au-gene-2.html`,
      },
      {
        title: i18n.t('genetics_and_health')?.default,
        content: i18n.t('resources_for_learning_about_genetics_and_health')
          ?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/au-gene-3.html`,
      },
      {
        title: i18n.t('hereditary_cancer')?.default,
        content: i18n.t('find_out_more_about_cancers_that_run_in_families')
          ?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/au-gene-5.html`,
      },
      {
        title: i18n.t('peer_support')?.default,
        content: i18n.t(
          'connect_with_organizations_or_individuals_and_find_resources'
        )?.default,
        site: `${envConstants.GENETIC_RESOURCES_URL}/au-gene-5.html`,
      },
    ],
  }

  return data[countryCode]
}

export default GeneticResourcesGenerator
