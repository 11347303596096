import {
  Image,
  View,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  ScrollView,
  Platform,
  SafeAreaView,
} from 'react-native'

import { connect } from 'react-redux'

import { StackActions } from '@react-navigation/native'
import { i18n } from '$localization/config.js'

import { platforms } from '$constants'

import BaseController from '$components/BaseController.js'

import LoadingView from '$components/LoadingView'

import { apiFetchHandler } from '$api'

import { showGenericServerError } from '$navigation/_utils/NavigationUtils.js'

import {
  storeCurrentMember,
  setShouldReloadParents,
} from '$redux/member_profile/actions.js'

import { setShouldReloadFamilyList } from '$redux/proband/actions.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import custom_styles from './_styles/custom.styles.js'

const webHeightAdjustment = -50
const getScrollViewHeight = () =>
  calculateAvailableContentHeight(webHeightAdjustment)

class SelectParent extends BaseController {
  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  proband = this.props.currentProband

  currentMember = this.props.currentMember

  dataForEditParent = this.currentMember?.dataForEditParent ?? null

  currentParentData = this.dataForEditParent?.currentParentData ?? null

  partnerDataSource = this.dataForEditParent?.partnerDataSource ?? null

  currentParentGender = this.currentParentData?.gender ?? null

  state = {
    shouldRenderComponent: false,
    showLoadingView: false,
    showModalBackground: false,
    showEnterParentNameModal: false,
    nextButtonDisabled: true,
    selectedItem: null,
    parentProfile: null,
    partnerList: [],
    scrollViewHeight: getScrollViewHeight(),
    addNewPartner: false,
    temporaryProfile: {
      first_name: '',
      last_name: '',
    },
  }

  constructor(props) {
    super(props)

    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  async componentDidMount() {
    this.props.navigation.setParams({
      cpg: this.currentParentGender,
    })

    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)

    // Initiate API request to get all Partners of selected Parent.
    await this.getPartners()
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  onDone = () => {
    if (!this.state.selectedItem?.partner_id) return

    const { selectedItem } = this.state

    const _currentMember_ = JSON.parse(JSON.stringify(this.currentMember))

    const _dataForEditParent_ = _currentMember_?.dataForEditParent

    _dataForEditParent_.newParentData = selectedItem

    // /* Trigger needed Dispatch events */
    this.props._saveCurrentMemberToStore_(_currentMember_)
    this.props._setShouldReloadFamilyList_(true)
    this.props._setShouldReloadParents_(true)

    this.props.navigation.pop()
  }

  onPressItem = (item) => {
    const selectedItem = item
    const selectedItemID = item?.partner_id

    if (selectedItemID) {
      this.setState({
        selectedItem,
        nextButtonDisabled: false,
      })
    } else {
      this.setState({
        selectedItem: null,
        nextButtonDisabled: true,
      })
    }

    this.setState({ refresh: !this.state.refresh }) // after clicking on a row let the web version refresh (re-render) the list items with a selected or not selected style
  }

  getPartners = async () => {
    /* API request to get all Partners of selected Parent. */

    // Get Parent ID that will be used to fetch the Partner list.
    const partnerDataSourceID = this.partnerDataSource?.id

    const getPartnersPayload = {
      path: 'family/partners/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband?.probandID,
        member_id: partnerDataSourceID,
      },
      pageDetails: {
        page: 'SelectParents.js',
      },
    }

    const response = await apiFetchHandler(getPartnersPayload)
    const { saveError } = this.props

    if (response.isError) {
      // Exit Edit Parent Modal, if needed
      this.toggleEnterParentNameModal(false)

      // Exit loading view and render component
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const partnerList = response?.partners ?? []

    this.setState({ partnerList })

    try {
      const { selectedItem } = this.state
      let selectedItemID = selectedItem?.partner_id ?? null

      if (!selectedItemID) {
        selectedItemID = this.currentParentData?.id ?? null
      }

      // Update Partner list
      if (partnerList.length > 0) {
        // Check if there's already a previously selected item.
        // Exit loop once a match has been found.
        for (const i in partnerList) {
          const _partnerData_ = partnerList[i]
          const partnerID = _partnerData_?.partner_id

          if (partnerID === selectedItemID) {
            const selectedItem = _partnerData_
            this.setState({
              selectedItem,
              nextButtonDisabled: false,
            })
            break
          }
        }
      }
    } catch (e) {
      console.log(
        '🚀 \n\n file: SelectParent.js:211 \n\n SelectParent \n\n getPartners= \n\n e:',
        e
      )
    }

    // Exit Edit Parent Modal, if needed
    this.toggleEnterParentNameModal(false)

    // Exit loading view and render component
    this.setState({
      shouldRenderComponent: true,
      showLoadingView: false,
    })
  }

  showAddNewPartnerModal = () => {
    this.setState({
      showModalBackground: true,
      showEnterParentNameModal: true,
      addNewPartner: true,
      parentProfile: [],
    })
  }

  setFalsePartner = () => {
    this.setState({
      addNewPartner: false,
    })
  }

  addNewPartnerHandler = async () => {
    this.setState({
      showLoadingView: true,
      addPartnerButtonDisabled: true,
    })

    const partnerDataSourceID = this.partnerDataSource?.id

    const addPartnerPayload = {
      path: 'member/add_partner/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband?.probandID,
        member_id: partnerDataSourceID,
      },
      pageDetails: {
        page: 'SelectParent.js',
      },
    }

    const addPartnerResponse = await apiFetchHandler(addPartnerPayload)
    const { saveError, selectedLanguage } = this.props

    if (addPartnerResponse.isError) {
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
      saveError({
        isShown: true,
        status: addPartnerResponse.status,
        message: addPartnerResponse.error,
      })
      return
    }

    const partnerData = addPartnerResponse

    const newSelectedItem = {
      bp_id: partnerData?.bp_id,
      partner_id: partnerData?.member_id,
      gender: partnerData?.gender,
      name: '',
    }

    if (!newSelectedItem?.partner_id) return

    // Get Member ID of selected Partner.
    const memberID = newSelectedItem?.partner_id

    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband?.probandID,
        member_id: memberID,
        lang: selectedLanguage,
      },
      pageDetails: {
        page: '',
      },
    }

    const getMemberProfileResponse = await apiFetchHandler(
      getMemberProfilePayload
    )

    if (getMemberProfileResponse.isError) {
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
      saveError({
        isShown: true,
        status: getMemberProfileResponse.status,
        message: getMemberProfileResponse.error,
      })
      return
    }

    const memberInfo = getMemberProfileResponse.member_info

    const parentProfile = {
      member_id: memberInfo?.member_id,
      first_name: memberInfo?.first_name,
      last_name: memberInfo?.last_name,
      gender: memberInfo?.gender,
    }

    const { temporaryProfile } = this.state

    if (!parentProfile?.member_id) return

    const updatePersonalInfoPayload = {
      path: 'member/update_personal_info/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband.probandID,
        member_id: parentProfile?.member_id,
        member_info: {
          first_name: temporaryProfile?.first_name,
          last_name: temporaryProfile?.last_name,
          email: '',
          is_dead: false,
          age: null,
          dob: null,
          cause_of_death: '',
        },
      },
      pageDetails: {
        page: 'SelectParent.js',
      },
    }

    const updatePersonalInfoResponse = await apiFetchHandler(
      updatePersonalInfoPayload
    )

    if (updatePersonalInfoResponse.isError) {
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
      saveError({
        isShown: true,
        status: updatePersonalInfoResponse.status,
        message: updatePersonalInfoResponse.error,
      })
      return
    }

    const getPartnersPayload = {
      path: 'family/partners/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband?.probandID,
        member_id: partnerDataSourceID,
      },
      pageDetails: {
        page: 'SelectParent.js',
      },
    }

    const response = await apiFetchHandler(getPartnersPayload)

    if (response.isError) {
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const partnerList = response.partners

    const selectedItem = newSelectedItem
    let selectedItemID = selectedItem?.partner_id ?? null

    if (!selectedItemID) {
      selectedItemID = this.currentParentData?.id ?? null
    }

    // Update Partner list
    if (partnerList.length > 0) {
      // Check if there's already a previously selected item.
      // Exit loop once a match has been found.
      for (const i in partnerList) {
        const _partnerData_ = partnerList[i]
        const partnerID = _partnerData_?.partner_id

        if (partnerID === selectedItemID) {
          const selectedItem = _partnerData_
          this.setState({
            showLoadingView: false,
            showEnterParentNameModal: false,
            selectedItem,
            parentProfile,
            partnerList,
            addPartnerButtonDisabled: false,
            showModalBackground: false,
            shouldRenderComponent: true,
            addNewPartner: false,
            nextButtonDisabled: false,
          })
          break
        }
      }
    }
  }

  addNewPartnerToMember = async (memberID) => {
    /* API request to add new Partner to Parent. */

    // Disable Add Partner button temporarily
    this.setState({ addPartnerButtonDisabled: true })

    const addPartnerPayload = {
      path: 'member/add_partner/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband?.probandID,
        member_id: memberID,
      },
      pageDetails: {
        page: 'SelectParent.js',
      },
    }

    const response = await apiFetchHandler(addPartnerPayload)
    const { saveError } = this.props

    if (response.isError) {
      // Enable Add Partner button again.
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
        addPartnerButtonDisabled: false,
      })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const data = response

    const newSelectedItem = {
      bp_id: data?.bp_id,
      partner_id: data?.member_id,
      gender: data?.gender,
      name: '',
    }

    const newParentProfile = {
      member_id: data?.member_id,
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender: data?.gender,
    }

    this.setState({
      selectedItem: newSelectedItem,
      parentProfile: newParentProfile,
    })
  }

  addEditParent = async (selectedItem = null) => {
    if (!selectedItem?.partner_id) return

    // Get Member ID of selected Partner.
    const memberID = selectedItem?.partner_id
    const { selectedLanguage } = this.props

    const payload = {
      proband_id: this.proband?.probandID,
      member_id: memberID,
    }

    // Show modal background and loading view
    this.setState({
      showModalBackground: true,
      showLoadingView: true,
    })

    // Get existing Partner's info
    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        ...payload,
        lang: selectedLanguage,
      },
      pageDetails: {
        page: 'SelectParent.js',
      },
    }

    const response = await apiFetchHandler(getMemberProfilePayload)
    const { saveError } = this.props

    if (response.isError) {
      // Enable Add Partner button again.

      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
        addPartnerButtonDisabled: false,
      })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const memberInfo = response?.member_info

    // Initialize Parent Profile to be used later on Update.
    const parentProfile = {
      member_id: memberInfo?.member_id,
      first_name: memberInfo?.first_name,
      last_name: memberInfo?.last_name,
      gender: memberInfo?.gender,
    }

    // Show Edit Parent modal and stop the loading view
    this.setState({
      showLoadingView: false,
      showEnterParentNameModal: true,
      parentProfile,
      addPartnerButtonDisabled: false,
    })
  }

  updateParentProfile = async (parentProfile = null) => {
    /* API request to edit a Parent Profile */
    if (!parentProfile?.member_id) return

    const payload = {
      proband_id: this.proband.probandID,
      member_id: parentProfile?.member_id,
      member_info: {
        first_name: parentProfile?.first_name,
        last_name: parentProfile?.last_name,
        email: '',
        is_dead: false,
        age: null,
        dob: null,
        cause_of_death: '',
      },
    }

    // Update Member Personal Info
    const updatePersonalInfoPayload = {
      path: 'member/update_personal_info/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'SelectParent.js',
      },
    }

    const response = await apiFetchHandler(updatePersonalInfoPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    // Reload Partners of selected Parent.
    await this.getPartners()
  }

  updateState = (key, value) => {
    const { addNewPartner } = this.state
    const newState = this.state
    if (addNewPartner) {
      newState.temporaryProfile[key] = value
    } else {
      newState.parentProfile[key] = value
    }
    this.setState(newState)
  }

  toggleEnterParentNameModal = (visible = false) => {
    this.setState({
      showModalBackground: visible,
      showEnterParentNameModal: visible,
    })
  }

  renderAddNewPartnerButton = () => {
    /* Button to add a new Parent. */

    const { currentParentGender } = this
    const partnerDataSourceID = this.partnerDataSource?.id

    let title = ''

    switch (`${currentParentGender}`.toLowerCase()) {
      case 'm':
        title = i18n.t('add_another_father')?.default
        break
      case 'f':
        title = i18n.t('add_another_mother')?.default
        break
    }

    return (
      <TouchableHighlight
        key="addNewPartnerButton"
        disabled={this.state.addPartnerButtonDisabled}
        onPress={() => this.showAddNewPartnerModal()}
      >
        <View
          style={[
            custom_styles.sectionItemContainer,
            { backgroundColor: 'white' },
          ]}
        >
          <View
            style={[
              custom_styles.sectionItemLabelTextContainer,
              { paddingHorizontal: 16 },
            ]}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image
                style={{
                  marginRight: 8,
                  width: 24,
                  height: 24,
                  resizeMode: 'contain',
                }}
                source={require('$assets/images/icon/plus/black.png')}
              />
              <Text style={custom_styles.sectionItemLabelText}>{title}</Text>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    )
  }

  render() {
    const listData = this.state.partnerList

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView style={global_styles.containerWhite}>
          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </SafeAreaView>
      )
    }

    return (
      <SafeAreaView
        style={[
          global_styles.containerWhite,
          {
            backgroundColor: 'white',
            ...Platform.select({
              [platforms.WEB]: {
                flex: null,
                height: getScrollViewHeight(),
              },
            }),
          },
        ]}
      >
        <View nativeID="web_scaled_main_container" style={{ flex: 1.0 }}>
          {this.renderAddNewPartnerButton()}

          <FlatList
            nativeID="web_scaled_main_container"
            style={{
              ...Platform.select({
                [platforms.WEB]: {
                  height: getScrollViewHeight(),
                },
              }),
            }}
            data={listData}
            showsVerticalScrollIndicator
            keyExtractor={(item) => `${item?.partner_id}`}
            renderItem={({ item, index }) => {
              const { selectedItem } = this.state
              const selectedItemID = selectedItem?.partner_id

              const itemID = item?.partner_id
              const itemName = item?.name

              if (!itemID) return null

              const backgroundColor =
                selectedItemID === itemID ? '#ccc' : 'white'
              let memberName = i18n.t('unnamed')?.title

              if (itemName && itemName?.trim() !== '') {
                memberName = itemName
              }

              return (
                <View
                  key={`${item?.partner_id}`}
                  style={[
                    custom_styles.sectionItemContainer,
                    { backgroundColor },
                  ]}
                >
                  <View style={custom_styles.sectionItemHorizontalContainer}>
                    <TouchableOpacity
                      style={custom_styles.sectionItemTitle}
                      activeOpacity={1.0}
                      onPress={() => this.onPressItem(item)}
                    >
                      <Text style={custom_styles.sectionItemLabelText}>
                        {memberName}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => this.addEditParent(item)}
                      style={custom_styles.sectionItemAccessory}
                    >
                      <Image
                        source={require('$assets/images/new_assets/icon-edit.png')}
                        style={{ width: 14, height: 16, resizeMode: 'contain' }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              )
            }}
          />
        </View>

        <View style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}>
          <TouchableOpacity
            style={[
              global_styles.nextButton,
              this.inversedToggleNextButtonColor(
                this.state.nextButtonDisabled,
                this.colorSettings
              ),
            ]}
            onPress={this.onDone}
            disabled={this.state.nextButtonDisabled}
          >
            <Text
              style={[
                global_styles.nextButtonText,
                {
                  color: this.colorSettings?.bottom_next_btn_text ?? 'black',
                },
              ]}
            >
              {i18n.t('done')?.default}
            </Text>
          </TouchableOpacity>
        </View>

        {this.state.showEnterParentNameModal && (
          <EnterParentNameModal
            colorSettings={this.colorSettings}
            parentController={this}
            parentGender={this.parentGender}
            parentProfile={this.state.parentProfile}
            tempProfile={this.state.temporaryProfile}
            visible={this.state.showEnterParentNameModal}
          />
        )}

        {this.state.showModalBackground && (
          <ModalBackground visible={this.state.showModalBackground} />
        )}

        {this.state.showLoadingView && (
          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        )}
      </SafeAreaView>
    )
  }
}

function ModalBackground({ visible = false }) {
  if (!visible) {
    return null
  }
  return (
    <View
      style={{
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.6)',
        height: '100%',
        width: '100%',
      }}
    />
  )
}

function EnterParentNameModal({
  colorSettings,
  parentController,
  parentGender = '',
  parentProfile = null,
  visible = false,
}) {
  let firstNameTextInput = null
  let lastNameTextInput = null

  const { addNewPartner } = parentController.state
  const profile = JSON.parse(JSON.stringify(parentProfile))
  const firstName = profile?.first_name
  const lastName = profile?.last_name

  let titleText = ''

  switch (parentGender.toLowerCase()) {
    case 'm':
      titleText = i18n.t('add_slash_edit_father')?.default
      break
    case 'f':
      titleText = i18n.t('add_slash_edit_mother')?.default
      break
    default:
      titleText = i18n.t('add_slash_edit_parent')?.default
      break
  }

  function updateState(key, value) {
    parentController?.updateState(key, value)
  }

  function submitChanges(data) {
    parentController?.toggleEnterParentNameModal(false)
    setTimeout(() => {
      if (parentController.state.addNewPartner) {
        parentController?.addNewPartnerHandler()
      } else {
        parentController?.updateParentProfile(data)
      }
    }, 100)
  }

  function cancelAction() {
    parentController?.toggleEnterParentNameModal(false)
    setTimeout(() => {
      // parentController?.getPartners();
      parentController?.setFalsePartner()
    }, 100)
  }

  let scrollContainerHeight = '150%'
  if (Platform.OS === 'web') {
    scrollContainerHeight = '100%'
  }

  return (
    <Modal animationType="slide" transparent visible={visible}>
      <ScrollView
        scrollEnabled={false}
        style={custom_styles.modalBackgroundContainer}
        contentContainerStyle={{
          alignItems: 'center',
          height: scrollContainerHeight,
        }}
      >
        <View style={[custom_styles.modalContentContainer, { width: 350 }]}>
          <View style={{ marginHorizontal: 0, marginVertical: 0 }}>
            <View style={custom_styles.formTitleContainer}>
              <Text style={custom_styles.formTitleText}>{titleText}</Text>
            </View>

            <View style={custom_styles.formContainer}>
              <View style={custom_styles.horizontalLabeledTextInputContainer}>
                <View style={custom_styles.textInputLabelContainer}>
                  <Text style={custom_styles.textInputLabel}>
                    {i18n.t('first_name')?.title}
                  </Text>
                </View>

                <View style={custom_styles.textInputContainer}>
                  <TextInput
                    nativeID="web_text_input"
                    ref={(ref) => (firstNameTextInput = ref)}
                    style={custom_styles.textInputField}
                    maxLength={45}
                    placeholder={i18n.t('type_first_name')?.default}
                    placeholderTextColor="rgba(74,74,74,0.5)"
                    autoCorrect={false}
                    autoCapitalize="words"
                    value={firstName}
                    onChangeText={(val) => updateState('first_name', val)}
                    onSubmitEditing={() => lastNameTextInput?.focus()}
                  />
                </View>
              </View>

              <View style={{ height: 0.5, backgroundColor: '#ccc' }} />

              <View style={custom_styles.horizontalLabeledTextInputContainer}>
                <View style={custom_styles.textInputLabelContainer}>
                  <Text style={custom_styles.textInputLabel}>
                    {i18n.t('last_name')?.title}
                  </Text>
                </View>

                <View style={custom_styles.textInputContainer}>
                  <TextInput
                    nativeID="web_text_input"
                    ref={(ref) => (lastNameTextInput = ref)}
                    style={custom_styles.textInputField}
                    maxLength={45}
                    placeholder={i18n.t('type_last_name')?.default}
                    placeholderTextColor="rgba(74,74,74,0.5)"
                    autoCorrect={false}
                    autoCapitalize="words"
                    value={lastName}
                    onChangeText={(val) => updateState('last_name', val)}
                  />
                </View>
              </View>
            </View>

            <TouchableOpacity
              style={[
                global_styles.normalButtonFull,
                {
                  backgroundColor: colorSettings?.btn_filled_enabled_1,
                  borderColor: colorSettings?.btn_filled_border_1,
                },
              ]}
              onPress={() => submitChanges(profile)}
            >
              <Text
                style={[
                  global_styles.normalButtonText,
                  {
                    color: colorSettings?.btn_filled_text_1,
                  },
                ]}
              >
                {i18n.t('save')?.title}
              </Text>
            </TouchableOpacity>

            <View style={{ height: 8 }} />

            <TouchableOpacity
              style={[
                global_styles.normalButtonFull,
                {
                  backgroundColor: colorSettings?.btn_no_fill_enabled_3,
                  borderColor: colorSettings?.btn_no_fill_border_3,
                },
              ]}
              onPress={() => cancelAction()}
            >
              <Text
                style={[
                  global_styles.normalButtonText,
                  {
                    color: colorSettings?.btn_no_fill_text_3,
                  },
                ]}
              >
                {i18n.t('cancel')?.title}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </Modal>
  )
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    selectedLanguage: store.selectedLanguage,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    currentProband: probandStore.proband,
    /** MemberProfile Store */
    memberProfile: memberProfileStore.memberProfile,
    currentMember: memberProfileStore.currentMember,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    /** Refactored actions */
    _setShouldReloadFamilyList_: (data) =>
      dispatch(setShouldReloadFamilyList(data)),
    _saveCurrentMemberToStore_: (data) => dispatch(storeCurrentMember(data)),
    _setShouldReloadParents_: (data) => dispatch(setShouldReloadParents(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectParent)
