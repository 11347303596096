import {
  getItemAsyncStorage,
  setItemAsyncStorage,
  removeItemAsyncStorage,
} from './helpers'

const ERROR_MESSAGE = 'Error in getItem:'

export default function simpleStorage() {
  const getItem = async (key, jsonify = false) => {
    try {
      const response = await getItemAsyncStorage(key)
      return jsonify ? JSON.parse(response) : response
    } catch (error) {
      console.log(ERROR_MESSAGE, error)
    }
  }

  const setItem = (key, value, stringify = true) => {
    if (stringify) {
      return setItemAsyncStorage(key, JSON.stringify(value))
    }
    setItemAsyncStorage(key, value)
  }

  const removeItem = (key) => {
    removeItemAsyncStorage(key)
  }

  return {
    getItem,
    setItem,
    removeItem,
  }
}
