import { Image, Text, TouchableOpacity, View } from 'react-native'
import { DOMAIN_CLIENT_NAME } from '$utils/globalVariables.js'

import { i18n } from '$localization/config.js'

import styles from './_static/styles.js'

function AppDownloadMessage({
  isOpen = false,
  onPressPrimary = function () {},
}) {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        activeOpacity={1.0}
        onPress={() => onPressPrimary()}
        style={[
          styles.contentContainer,
          {
            borderColor: '#ccc',
            borderBottomWidth: isOpen == true ? 1.0 : 0,
          },
        ]}
      >
        <View
          style={[
            styles.logoContainer,
            {
              flex: 0.2,
            },
          ]}
        >
          <Image
            style={styles.logoImage}
            source={
              DOMAIN_CLIENT_NAME?.toLowerCase()?.includes('famgenix')
                ? require('$assets/images/app_icon/icon_40pt.png')
                : require('$assets/images/_domain_app_icons/_coh_app_icon/icon_40pt.png')
            }
          />
        </View>
        <View
          style={[
            styles.titleContainer,
            {
              flex: 0.8,
            },
          ]}
        >
          <Text style={styles.titleText}>
            {`${i18n.t('get_the')?.default} ${DOMAIN_CLIENT_NAME} ${
              i18n.t('app')?.lowercase
            }`}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default AppDownloadMessage
