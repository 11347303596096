import moment from 'moment'

import { Component } from 'react'
import {
  View,
  Text,
  Keyboard,
  Platform,
  TextInput,
  TouchableOpacity,
  Appearance,
} from 'react-native'

import { i18n } from '$localization/config.js'
import { formatDate } from '$screens/utils'
import { DEFAULT_DATE_FORMAT } from '$constants/dateFormats'

import { dateFormats } from '$constants'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import { parseDateOfBirth } from '$utils'

const customDateFormat = formatDate(false, ' / ')
const DOB = 'dob'

class BirthdateField extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  memberProfile = this.props.memberProfile

  profileData = this.memberProfile?.profileData

  state = {
    memberProfile: this.memberProfile,
    profileData: this.profileData,
    currentAge: null,
    dob: '',
  }

  constructor(props) {
    super(props)

    if (Platform.OS === 'ios') {
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardWillHide',
        this._keyboardDismissed
      )
    } else {
      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this._keyboardDismissed
      )
    }

    const { profileData } = this.state

    if (profileData) {
      const dob = profileData?.dob
      let formattedDOB = null

      if (dob) {
        const newDob = parseDateOfBirth(dob)
        formattedDOB = moment(newDob).format(customDateFormat)

        formattedDOB = formattedDOB !== 'Invalid date' ? formattedDOB : null
      }

      this.state = {
        ...this.state,
        dob: formattedDOB,
        currentAge: profileData?.currentAge,
      }
    }
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const { profileData } = memberProfile
    const stateDob = moment(parseDateOfBirth(prevState.dob)).format(
      DEFAULT_DATE_FORMAT
    )
    const profileDob = moment(parseDateOfBirth(profileData.dob)).format(
      DEFAULT_DATE_FORMAT
    )

    if (stateDob === profileDob) return null
    if (prevState.dob) if (!newProps) return null
    if (profileData?.currentAge) {
      const dob = profileData?.dob
      let formattedDOB = null

      if (dob) {
        const newDob = parseDateOfBirth(dob)
        formattedDOB = moment(newDob).format(customDateFormat)

        formattedDOB = formattedDOB !== 'Invalid date' ? formattedDOB : null
      }

      const newState = {
        ...prevState,
        memberProfile,
        profileData,
        currentAge: profileData?.currentAge,
        dob: formattedDOB,
      }

      return newState
    }
  }

  componentDidMount() {
    const bgAppearance = Appearance.getColorScheme()
    if (bgAppearance !== 'no-preference' || bgAppearance !== null) {
      this.setState({ colorScheme: bgAppearance })
    }
  }

  componentWillUnmount() {
    this.keyboardDidHideListener.remove()
  }

  _keyboardDismissed = () => {
    this.updateBirthdate(this.state.dob)
  }

  updateBirthdate = (dob) => {
    let formattedDOB = null

    if (dob) {
      const newDob = parseDateOfBirth(dob)

      formattedDOB = moment(newDob).format(dateFormats.DEFAULT_DATE_FORMAT)
      formattedDOB = formattedDOB !== 'Invalid date' ? formattedDOB : null
    }

    this.parentController?._updateState_(DOB, formattedDOB)
  }

  toggleDatePicker = () => {
    this.parentController?.toggleDatePicker()
  }

  render() {
    if (!this.state.profileData) return null

    return (
      <View>
        <View style={section_styles.contentSeparatorLine} />

        <View style={section_styles.contentItem}>
          <View style={section_styles.contentItemLabelTextContainer}>
            <Text
              style={[
                section_styles.contentItemLabelText,
                { color: this.colorSettings?.text_input_label_color_1 },
              ]}
              onPress={() => this.toggleDatePicker()}
            >
              {i18n.t('birthdate')?.default}
            </Text>
          </View>

          <View
            style={[
              section_styles.contentItemTextInputContainer,
              {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              },
            ]}
          >
            <TextInput
              editable={false}
              style={[
                section_styles.contentItemTextInput,
                { color: this.colorSettings?.text_input_color_1 },
              ]}
              maxLength={45}
              placeholder={i18n.t('select_date')?.default}
              placeholderTextColor={
                this.colorSettings?.text_input_placeholder_color_1 ||
                'rgba(74,74,74,0.5)'
              }
              autoCorrect={false}
              autoCapitalize="none"
              value={this.state?.dob ?? ''}
            />

            <TouchableOpacity
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
              onPress={() => this.toggleDatePicker()}
              activeOpacity={1}
            />

            {/* <ClearButton /> */}
          </View>
        </View>
      </View>
    )
  }
}

export default BirthdateField
