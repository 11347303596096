const PLACEHOLDER = '0'
const MAX_LENGTH = 3
const KEYBOARD_TYPE = 'number-pad'
const AUTO_CAPITALIZE = 'none'
const CLEAR_TEXT_ON_FOCUS = true
const DEFAULT_PLACEHOLDER_TEXT_COLOR = 'rgba(0,0,0,0.5)'

export {
  PLACEHOLDER,
  MAX_LENGTH,
  KEYBOARD_TYPE,
  AUTO_CAPITALIZE,
  CLEAR_TEXT_ON_FOCUS,
  DEFAULT_PLACEHOLDER_TEXT_COLOR,
}
