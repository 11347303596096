import { StyleSheet } from 'react-native'

const PURPLE = 'rgba(155,89,182,1.0)'
const GREY = 'rgba(255,255,255,0.25)'
const WHITE = '#fff'
const NULL_COLOR = null

export default StyleSheet.create({
  mainContainer: {
    backgroundColor: PURPLE,
    flex: 1.0,
  },
  categoryTitleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderColor: NULL_COLOR,
    height: 36,
    marginTop: 25,
    alignSelf: 'center',
  },
  categoryTitleTxt: {
    textAlign: 'right',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    paddingHorizontal: 25,
  },
  selectButton: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: WHITE,
    height: 60,
    width: '45%',
  },
  selectButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 30,
  },
  selectButtonText: {
    color: WHITE,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  nxtBtnContainer: {
    height: 60,
    width: '100%',
    justifyContent: 'flex-end',
  },
  nxtBtn: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderWidth: 1,
  },
  nxtBtnText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  scrollView: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressText: {
    justifyContent: 'center',
    color: GREY,
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: PURPLE,
    overflow: 'hidden',
  },
})
