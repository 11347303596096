import { getTimezoneOffset } from '$utils'

const RADIX = 10
const NO_OFFSET = 0
const NEGATIVE_OFFSET = -1
const POSITIVE_OFFSET = 1

export default function fixedTimeZoneOffset() {
  const timezoneOffset = parseInt(getTimezoneOffset(new Date()), RADIX)

  const absOffset = Math.abs(timezoneOffset)
  const resultSign =
    timezoneOffset > NO_OFFSET ? NEGATIVE_OFFSET : POSITIVE_OFFSET
  const result = absOffset * resultSign

  return result
}
