import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  formContainer: {
    marginTop: 16,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formSectionContainer: {
    width: '100%',
    paddingHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  formSection: {
    marginTop: 5,
    paddingHorizontal: 16,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formSectionRowContainer: { flexDirection: 'row', width: '100%' },
  loginErrorContainer: {
    width: '100%',
    height: 20,
    justifyContent: 'center',
    marginTop: 2,
    marginBottom: 15,
  },
  errorRequiredText: {
    fontFamily: 'montserrat',
    fontSize: 12,
    color: 'red',
    fontWeight: '500',
    marginLeft: 3,
  },
  labeledSwitchContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 35,
    alignItems: 'center',
  },
  labeledSwitchText: (colorSettings) => ({
    fontFamily: 'montserrat',
    fontSize: 14,
    color: colorSettings?.text_input_label_color_1 || 'rgba(74,74,74,0.8)',
  }),
  rememberMeTextContainer: {
    flex: 0.8,
    height: '100%',
    justifyContent: 'center',
  },
  rememberMeButtonContainer: {
    flex: 0.2,
    height: '100%',
    justifyContent: 'center',
  },
})

export default styles
