import { View, TouchableOpacity } from 'react-native'
import styles from './styles'
import { IS_WEB } from '$constants/platforms'

export default function RoundedButton({
  buttonStyle,
  innerStyle,
  children,
  ...props
}) {
  const buttonHeightStyle = IS_WEB() ? { minHeight: 60.0 } : { height: 60.0 }

  return (
    <TouchableOpacity
      {...props}
      style={[styles.roundedButtonContainer, buttonStyle, buttonHeightStyle]}
      activeOpacity={1.0}
    >
      <View style={[styles.roundedButtonTitleTextContainer, innerStyle]}>
        {children}
      </View>
    </TouchableOpacity>
  )
}
