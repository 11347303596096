export default function handleDisplayShorterAgeUnits(unit) {
  const unitShortenings = {
    months: 'm',
    days: 'd',
    weeks: 'w',
  }

  const isUnitShortened = unitShortenings[unit] || ''

  return isUnitShortened
}
