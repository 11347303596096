import {
  calculateMonthDifference,
  calculateYearDifference,
  formatResult,
  calculateTimeDifference,
} from './helpers'

import { MINIMUM_DIFFERENCE, TIME } from './constants'

export default function calculateAge(dateOfBirth) {
  const currentDate = new Date()
  const birthDate = new Date(
    dateOfBirth.getFullYear(),
    dateOfBirth.getMonth(),
    dateOfBirth.getDate()
  )

  const { weeks, days } = calculateTimeDifference(currentDate, birthDate)

  const birthYear = birthDate.getFullYear()
  const currentYear = currentDate.getFullYear()

  const calculateDifferencePayload = {
    currentYear,
    birthYear,
    currentDate,
    birthDate,
  }

  const diffInYears = calculateYearDifference(calculateDifferencePayload)

  if (diffInYears > MINIMUM_DIFFERENCE) {
    return formatResult(diffInYears, TIME.YEARS)
  }

  const monthsDifference = calculateMonthDifference(calculateDifferencePayload)

  if (monthsDifference >= MINIMUM_DIFFERENCE) {
    return formatResult(monthsDifference, TIME.MONTHS)
  }

  if (weeks.toFixed() >= MINIMUM_DIFFERENCE) {
    return formatResult(weeks.toFixed(), TIME.WEEKS)
  }

  return formatResult(days.toFixed(), TIME.DAYS)
}
