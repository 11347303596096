import React from 'react'
import { View, SafeAreaView } from 'react-native'
import styles from '../styles'

export default function Wrapper({
  children,
  useSafeAreaView,
  isDimBackground,
  containerStyle,
}) {
  const styledContainer = [styles.container(isDimBackground), containerStyle]

  if (useSafeAreaView) {
    return <SafeAreaView style={styledContainer}>{children}</SafeAreaView>
  }

  return <View style={styledContainer}>{children}</View>
}
