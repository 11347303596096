import { useSelector } from 'react-redux'
import uuid from 'react-native-uuid'
import ChildrenView from '$screens/main/family/_includes/children/Children.view.js'
import FamilyCard from '$components/FamilyCard'
import {
  formatDisplayedMemberName,
  getGenderIcon,
  getGenderIconBorderRadius,
} from '$utils'

import customStyles from '$screens/main/family/_styles/main.styles.js'
import calculateRelationship from '../../FamilyListScreen/helpers/calculateRelationship'

const excludedRelationship = ['proband', 'uncle', 'aunt', 'mother', 'father']

const excludedOtherMembersRelationships = [
  'paternal grandmother',
  'paternal grandfather',
  'maternal grandmother',
  'maternal grandfather',
  'great grandmother',
  'great grandfather',
  'great aunt',
  'great uncle',
  'cousin',
]

function PartnersView({
  mainMember,
  partners,
  navigateToMemberProfile,
  addToMemberButtonAction,
  handleAvatarClick,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const relationshipList = []

  if (!partners || partners.length === 0) return null

  const filteredPartners = partners.filter((partner) => !partner.is_deleted)

  const shouldDisplayPartner = (partner) =>
    !excludedRelationship.some((rel) =>
      partner.relationship_to_proband.includes(rel)
    ) &&
    !excludedOtherMembersRelationships.some((rel) =>
      mainMember.relationship_to_proband.includes(rel)
    )

  return (
    <>
      {filteredPartners.map((partner) => {
        const titleText = formatDisplayedMemberName(
          partner?.name,
          partner?.age_string ?? partner?.age,
          partner?.is_dead
        )

        const { relationship } = calculateRelationship(
          partner,
          relationshipList
        )

        if (!shouldDisplayPartner(partner)) return null

        return (
          <FamilyCard
            key={uuid.v4()}
            listKey={uuid.v4()}
            member={partner}
            handleAvatarClick={handleAvatarClick}
            navigateToMemberProfile={() =>
              navigateToMemberProfile(partner, false)
            }
            getGenderIcon={getGenderIcon(partner?.gender, partner?.is_dead)}
            getGenderIconBorderRadius={getGenderIconBorderRadius(
              partner?.gender,
              partner?.is_dead
            )}
            addToMemberButtonAction={() =>
              addToMemberButtonAction(partner, partner?.relationship)
            }
            relationship={relationship}
            customStyles={customStyles}
            titleText={titleText}
            colorSettings={colorSettings}
            component={
              <ChildrenView
                handleAvatarClick={handleAvatarClick}
                listKey={`${uuid.v4()}`}
                colorSettings={colorSettings}
                navigateToMemberProfile={navigateToMemberProfile}
                addToMemberButtonAction={addToMemberButtonAction}
                mainMember={mainMember}
                fromPartner={partner}
              />
            }
          />
        )
      })}
    </>
  )
}

export default PartnersView
