import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web

import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import DiseaseTypesMain from '$screens/relatives/profile/sections/health_history/DiseaseTypes.main.js'
import DiseasesList from '$screens/relatives/profile/sections/health_history/DiseasesList.js'
import EditMemberDisease from '$screens/relatives/profile/sections/health_history/EditMemberDisease.js'

import { navigationConstants } from '$constants'

import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'
import { isEmptyObject } from '$utils'

const Stack = createStackNavigator()

function RelativeDiseasesNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="DiseaseTypesMain"
      screenOptions={{
        ...navigationConstants.DEFAULT_HEADER_SETTINGS,
        mode: 'card',
      }}
    >
      <Stack.Screen
        name="DiseaseTypesMain"
        component={DiseaseTypesMain}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('disease_types').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DiseasesList"
        component={DiseasesList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('diseases').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
        })}
      />
      <Stack.Screen
        name="EditMemberDisease"
        component={EditMemberDisease}
        options={({ route }) => {
          const diseaseObj = route.params?.diseaseList ?? null
          let diseaseName = i18n.t('add_slash_edit_disease').default

          if (!isEmptyObject(diseaseObj)) {
            if (diseaseObj.short_name && diseaseObj.short_name !== '') {
              let _diseaseShortName_ = `${diseaseObj.short_name}`.toLowerCase()
              _diseaseShortName_ = _diseaseShortName_
                .replace(/[\s\/']/g, '_')
                .replace(/[.()]/g, '')
              diseaseName = i18n.t(_diseaseShortName_).default
            }
          }

          return {
            ...headerNavigationDefaultOptions(
              colorSettings?.nav_title_light,
              colorSettings?.onboarding_nav_bgcolor
            ),
            headerTitle: () => (
              <HeaderTitleComponent
                headerTitle={diseaseName}
                headerTintColor={colorSettings?.nav_title_light}
                headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
              />
            ),
          }
        }}
      />
    </Stack.Navigator>
  )
}

export default RelativeDiseasesNavigator
