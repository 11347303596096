import { setError, setShowLoadingView } from '$redux/defaults/actions.js'
import { setSelectedClinicianAction } from '$redux/clinician/actions.js'

import { i18n } from '$localization/config.js'

import apiFetchHandler from '$api/apiFetchHandler'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

const saveClinician = async (payload, headers, dispatch) => {
  const setIsLoadingViewShown = (data) => dispatch(setShowLoadingView(data))

  const serverErrorMessage = i18n.t('server_error').default
  const requestErrorMessage = i18n.t(
    'sorry_there_was_an_error_processing_your_request_please_try_again_later'
  ).default

  const showCodeError = () => {
    showDefaultAlert(serverErrorMessage, requestErrorMessage)
  }

  setIsLoadingViewShown(true)

  try {
    const clinicianCheckPayload = {
      path: 'clinician/check/',
      method: 'post',
      token: headers.accessToken,
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SuccessScreenAccountCreated - index.js',
      },
    }

    const response = await apiFetchHandler(clinicianCheckPayload)

    if (response.isError) {
      showCodeError()

      dispatch(
        setError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      )
      return
    }

    dispatch(setSelectedClinicianAction(response))
  } catch (error) {
    showCodeError()
  } finally {
    setIsLoadingViewShown(false)
  }
}

export default saveClinician
