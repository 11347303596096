import React, { useState, useEffect, useRef } from 'react'
import { View, SafeAreaView, FlatList } from 'react-native'

import { useDispatch } from 'react-redux'
import { setShowLoadingView } from '$redux/defaults/actions'

import LanguageItem from './components/LanguageItem'

import { translations } from '$localization/config.js'

import styles from '$assets/styles/global.styles.js'

export default function SelectLanguage({ route, navigation }) {
  const selectedItemRef = useRef(null)

  const [selectedItem, setSelectedItem] = useState(null)
  const listData = Object.keys(translations)

  const dispatch = useDispatch()
  const setLoadingView = (data) => dispatch(setShowLoadingView(data))

  const onSelectedLanguage = route?.params?.onLanguageSelected ?? function () {}

  const onDone = () => {
    if (!selectedItemRef.current) return null

    setLoadingView(true)

    setTimeout(() => {
      if (onSelectedLanguage) {
        onSelectedLanguage(selectedItemRef.current)
      }

      navigation.goBack()

      setLoadingView(false)
    }, 300)
  }

  useEffect(() => {
    navigation.setParams({
      onDone: () => onDone(),
    })
  }, [])

  const onPressItem = (item) => {
    setSelectedItem(item)
    selectedItemRef.current = item
  }

  return (
    <SafeAreaView style={styles.containerWhite}>
      <View style={styles.content}>
        <FlatList
          data={listData}
          renderItem={({ item, index }) => (
            <LanguageItem
              item={item}
              index={index}
              selectedItem={selectedItem}
              onPressItem={onPressItem}
            />
          )}
        />
      </View>
    </SafeAreaView>
  )
}
