import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View, Platform, SafeAreaView, Modal, Text } from 'react-native'
import { WebView } from 'react-native-webview'
import * as FileSystem from 'expo-file-system'
import { shareAsync } from 'expo-sharing'
import { useSelector, useDispatch } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'
import { useRoute } from '@react-navigation/native'
import { IS_WEB, IS_MOBILE } from '$utils/Platforms.js'
import { platforms } from '$constants'
import MainWrapper from '$components/MainWrapper'
import LoadingView from '$components/LoadingView'
import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'
import styles from './assets/styles'
import { apiFetchHandler } from '$api'
import { setError } from '$redux/defaults/actions.js'
import { setProbandProfileAction } from '$redux/proband/actions.js'
import MemberProfile from '$data_models/MemberProfile.js'
import {
  storeCurrentMember,
  setDidAddNewMember,
} from '$redux/member_profile/actions.js'

import sectionListOrder from '$screens/main/family/FamilyListScreen/constants/sectionListOrder.js'
import { requestAddRelative } from '$screens/main/family/FamilyListScreen/helpers/handleAddRelatives.js'

import { CLIN_PORTAL_URL } from '$utils/globalVariables.js'
import updateMemberProfile from './helpers/updateMemberProfile'
import { webIframe } from '$utils'

const WEB_IFRAME_CONTAINER_ID = 'web-pedigree-iframe'

function FamilyPedigreeScreen({ navigation }) {
  const route = useRoute()
  const webView = useRef(null)
  const dispatch = useDispatch()

  const [scrollViewHeight, setScrollViewHeight] = useState(
    calculateAvailableContentHeight()
  )
  const [showCloseButton, setShowCloseButton] = useState(
    calculateAvailableContentHeight()
  )
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const [memberId, setMemberId] = useState(null)
  const [navItem, setNavItem] = useState(null)

  const proband = useSelector((state) => state.probandStore.proband)
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)
  const { didAddNewMember, addNewMemberPayload } = useSelector(
    (state) => state.memberProfileStore
  )

  const saveError = (data) => dispatch(setError(data))
  const saveProbandProfileToStore = (data) =>
    dispatch(setProbandProfileAction(data))

  const saveCurrentMemberToStore = (data) =>
    new Promise((resolve, reject) => {
      dispatch(storeCurrentMember(data))
      resolve('done')
    })
  const saveDidAddNewMember = (data) => dispatch(setDidAddNewMember(data))

  const [showLoadingView, setShowLoadingView] = useState(true)

  const updateMemberProfilePayload = {
    probandID: proband?.probandID,
    authCredentials,
    selectedLanguage,
    saveError,
    saveProbandProfileToStore,
    saveCurrentMemberToStore,
  }

  useEffect(() => {
    setShowLoadingView(false)
    saveDidAddNewMember(null)
    const { probandID } = proband

    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        setScrollViewHeight(calculateAvailableContentHeight())
      )

      window.addEventListener('message', () => {}, true)

      window.addEventListener('message', handleGetFamilyDetails)

      const token = authCredentials?.accessToken

      webIframe().openInIframe(
        `/pedigree/${probandID}/#family-history`,
        WEB_IFRAME_CONTAINER_ID,
        token
      )
    }

    navigation.setParams({
      printPedigree: IS_MOBILE() ? onPrintClickMobile : onPrintClickWeb,
    })

    saveDidAddNewMember(false)

    return () =>
      IS_WEB()
        ? window.removeEventListener('message', handleGetFamilyDetails)
        : () => {}
  }, [])

  useEffect(() => {
    if (!didAddNewMember) return
    if (!addNewMemberPayload || addNewMemberPayload.length <= 0) return

    const loadPlatform = async (reloadPlatform) => {
      await delay(1000)
      saveDidAddNewMember(false)
      reloadPlatform()
    }

    const memberAdd = async (reloadPlatform) => {
      for (const payload of addNewMemberPayload) {
        await requestAddRelative(payload, authCredentials, saveError)
      }

      loadPlatform(reloadPlatform)
    }

    if (IS_WEB()) {
      memberAdd(pedigreeReloadWeb)
      return () => {}
    }

    memberAdd(pedigreeReloadMobile)
  }, [didAddNewMember, addNewMemberPayload])

  useEffect(() => {
    const { familyTabsNavigationButtonTypes } = route.params || {}

    if (familyTabsNavigationButtonTypes !== 1) return
    if (IS_WEB()) {
      const iFrameContainer = document.getElementById(WEB_IFRAME_CONTAINER_ID)

      if (!iFrameContainer.children[0]?.contentWindow) return
      if (isFirstLoad) return setIsFirstLoad(false)

      pedigreeReloadWeb()
      return () => {}
    }

    pedigreeReloadMobile()
  }, [
    route.params.familyTabsNavigationButtonTypes,
    setIsFirstLoad,
    isFirstLoad,
  ])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (IS_WEB()) {
        pedigreeReloadWeb()
        return () => {}
      }
      pedigreeReloadMobile()
    })

    return unsubscribe
  }, [navigation])

  const handleGetFamilyDetails = useCallback(
    async (event) => {
      if (isJsonString(event.data)) {
        const data = JSON.parse(event.data)

        setShowLoadingView(true)

        await updateMemberProfile({
          ...updateMemberProfilePayload,
          memberID: data.selected_member_id,
          navigationHandler: () => navigationHandler(data),
        })
      }
    },
    [showLoadingView, navItem]
  )

  const navigationHandler = (data) => {
    if (data.type === 'add-member') {
      navigation.navigate('AddMemberNavigator', {
        screen: 'AddFamilyMemberView',
        params: {
          fromFamilyPedigreeScreen: true,
        },
      })
    } else if (data.type === 'edit-member') {
      navigation.navigate('EditProfileView', {
        isFamilyScreen: true,
      })
    }
    setShowLoadingView(false)
  }

  const onPrintClickWeb = () => {
    const iFrameContainer = document.getElementById(WEB_IFRAME_CONTAINER_ID)
    if (iFrameContainer) {
      iFrameContainer.children[0].contentWindow.postMessage(
        'web-pedigree-iframe-print',
        '*'
      )
    }
  }

  const pedigreeReloadWeb = () => {
    const iFrameContainer = document.getElementById(WEB_IFRAME_CONTAINER_ID)

    if (iFrameContainer) {
      iFrameContainer.children[0].contentWindow.postMessage(
        'web-reload-pedigree',
        '*'
      )
    }
  }

  const delay = (time) => new Promise((resolve) => setTimeout(resolve, time))

  const pedigreeReloadMobile = () => {
    // trigger event on clinician portal iframe
    const myInjectedJs = `(function(){
          const event = new Event("mobile-pedigree-reload");
          window.dispatchEvent(event);
        })();`
    webView.current.injectJavaScript(myInjectedJs)
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const onPrintClickMobile = () => {
    // trigger event on clinician portal iframe
    const myInjectedJs = `(function(){
      const event = new Event("mobile-print");
      window.dispatchEvent(event);
    })();`
    webView.current.injectJavaScript(myInjectedJs)
  }

  // download for Android
  const downloadForAos = async (pdfBase64Str, fileName) => {
    const folder =
      FileSystem.StorageAccessFramework.getUriForDirectoryInRoot('test')
    const permissions =
      await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync(
        folder
      )
    if (!permissions.granted) return
    const filePath = await FileSystem.StorageAccessFramework.createFileAsync(
      permissions.directoryUri,
      `${fileName}.pdf`,
      'application/pdf'
    )
    try {
      await FileSystem.StorageAccessFramework.writeAsStringAsync(
        filePath,
        pdfBase64Str,
        { encoding: FileSystem.EncodingType.Base64 }
      )
    } catch (err) {
      console.log(err)
    }
  }
  // download for iOS
  const downloadForIos = async (pdfBase64Str, fileName) => {
    try {
      const filePath = `${FileSystem.documentDirectory}${fileName}.pdf`
      await FileSystem.StorageAccessFramework.writeAsStringAsync(
        filePath,
        pdfBase64Str,
        { encoding: FileSystem.EncodingType.Base64 }
      )
      await shareAsync(filePath)
    } catch (err) {
      console.log(err)
    }
  }

  const handleMessage = async (event) => {
    const data = JSON.parse(event.nativeEvent.data)
    if (data.type === 'print-data') {
      const dataURL = data.data
      const fileName = data.file_name
      if (Platform.OS === 'ios') {
        downloadForIos(dataURL, fileName)
      } else {
        downloadForAos(dataURL, fileName)
      }
      return
    }
    setShowLoadingView(true)
    await updateMemberProfile({
      ...updateMemberProfilePayload,
      memberID: data.selected_member_id,
      navigationHandler: () => navigationHandler(data),
    })
  }

  const token = authCredentials?.accessToken
  const myInjectedJs = `(function(){
      sessionStorage.setItem('patient-pedigree-token', '${token}');
      sessionStorage.setItem('render-patient-pedigree', 'true');
    })();`

  const clinicianPortalDomain = CLIN_PORTAL_URL

  if (IS_WEB()) {
    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <View
            style={[
              styles.containerWeb,
              {
                backgroundColor: colorSettings?.onboarding_bgcolor,
              },
            ]}
          >
            <View
              style={styles.webViewContent}
              nativeID={WEB_IFRAME_CONTAINER_ID}
            />
          </View>
          <LoadingView
            visible={showLoadingView}
            tintColor={colorSettings?.post_onboarding_nav_bgcolor}
            textColor={colorSettings?.post_onboarding_nav_bgcolor}
          />
        </MainWrapper>
      </ErrorBoundary>
    )
  }

  if (IS_MOBILE()) {
    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.containerWhite,
              {
                backgroundColor:
                  colorSettings?.post_onboarding_bgcolor ?? 'rgb(245,245,245)',
                ...Platform.select({
                  [platforms.WEB]: {
                    flex: null,
                    height: scrollViewHeight,
                  },
                }),
              },
            ]}
          >
            {/* {this.renderSurveyList()} */}
            <WebView
              ref={webView}
              style={styles.webViewMainContainer}
              containerStyle={styles.webViewInnerContainer}
              onLoadEnd={() => {
                setShowLoadingView(false)
                setShowCloseButton(false)
              }}
              onError={() => {
                setShowLoadingView(false)
                setShowCloseButton(true)
              }}
              onHttpError={() => {
                setShowLoadingView(false)
                setShowCloseButton(true)
              }}
              bounces={false}
              source={{
                uri: `${clinicianPortalDomain}/pedigree/${proband?.probandID}/#family-history`,
              }}
              injectedJavaScriptBeforeContentLoaded={myInjectedJs}
              javaScriptEnabled
              domStorageEnabled
              sharedCookiesEnabled
              allowUniversalAccessFromFileURLs
              allowingReadAccessToURL
              data
              allowFileAccess
              mixedContentMode="always"
              onMessage={(event) => {
                handleMessage(event)
              }}
            />

            {showLoadingView && (
              <LoadingView
                tintColor={colorSettings?.post_onboarding_nav_bgcolor}
                textColor={colorSettings?.post_onboarding_nav_bgcolor}
              />
            )}
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

export default FamilyPedigreeScreen
