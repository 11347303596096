const MALE_PRONOUN = 'he/him'
const FEMALE_PRONOUN = 'she/her'
const THEY_THEM_PRONOUN = 'they/them'
const OTHER_PRONOUN = 'other'

const PRONOUN_LIST = [
  {
    key: 1,
    name: 'He/Him',
    value: MALE_PRONOUN,
  },
  {
    key: 2,
    name: 'She/Her',
    value: FEMALE_PRONOUN,
  },
  {
    key: 3,
    name: 'They/Them',
    value: THEY_THEM_PRONOUN,
  },
  {
    key: 4,
    name: 'Other',
    value: OTHER_PRONOUN,
  },
]

const SHORTEN_GENDER_IDENTITIES = {
  FEMALE: 'f',
  MALE: 'm',
  NON_BINARY: 'nb',
}

const GENDER_IDENTITIES = {
  FEMALE: 'female',
  MALE: 'male',
  NON_BINARY: 'non-binary',
}

export {
  PRONOUN_LIST,
  FEMALE_PRONOUN,
  MALE_PRONOUN,
  OTHER_PRONOUN,
  THEY_THEM_PRONOUN,
  SHORTEN_GENDER_IDENTITIES,
  GENDER_IDENTITIES,
}
