import { Dimensions, StyleSheet, Platform } from 'react-native'

import Constants from 'expo-constants'

import { IS_WEB, IS_MOBILE } from '$utils/Platforms.js'

const calculateAvailableContentHeight = (extraHeightForWeb = 0) => {
  let height = IS_WEB() ? window.innerHeight : Dimensions.get('window').height

  let statusBarHeight = 20
  const topNavBarHeight = 44
  const nextBtnHeight = 60

  if (IS_MOBILE()) {
    statusBarHeight = Constants.statusBarHeight
  }

  height -= statusBarHeight
  height -= topNavBarHeight
  height -= nextBtnHeight

  if (IS_WEB()) {
    height += 10
    height -= extraHeightForWeb
  }

  return height
}

const DefaultFullHeight = (defaultHeight = 20) => {
  let height = IS_WEB() ? window.innerHeight : Dimensions.get('window').height

  const { statusBarHeight } = Constants
  const navigationBarHeight = 44
  const primaryBtnHeight = 60

  height -= statusBarHeight
  height -= navigationBarHeight
  height -= primaryBtnHeight

  if (IS_WEB()) {
    height -= defaultHeight
  }

  return height
}

const styles = StyleSheet.create({
  // Main screen
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)', // Purple background
    width: '100%',
    flex: 1.0,
  },
  containerWhite: {
    backgroundColor: 'rgb(245,245,245)', // White background
    width: '100%',
    flex: 1.0,
  },
  containerClear: {
    flex: 1.0,
    width: '100%',
  },
  contentContainerFullHeight: {
    height: DefaultFullHeight(),
    paddingHorizontal: 16,
  },
  contentContainer: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    marginHorizontal: 16,
  },
  contentContainerPadded: {
    marginTop: 20,
    width: '100%',
    maxWidth: 700,
    alignSelf: 'center',
    paddingHorizontal: 16,
  },
  content: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
  contentWithPadding: {
    left: 0,
    right: 0,
    padding: 16,
    justifyContent: 'center',
  },
  centerContent: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  horizontalContent: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  horizontalCenterContent: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomHorizontalContentFull: {
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    alignItems: 'flex-end',
  },

  /* Navigation header */
  headerBarTitleContainer: {
    // backgroundColor: "pink",
    alignSelf: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  headerBarMainTitle: {
    marginHorizontal: 10,
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'center',
  },
  headerBarSubtitle: {
    fontFamily: 'karla-bold',
    fontSize: 12,
    textAlign: 'center',
  },
  backButton: {
    paddingLeft: 2,
    marginRight: 16,
    height: '100%',
    justifyContent: 'center',
    minWidth: 50,
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButtonText: {
    paddingHorizontal: 10,
    fontSize: 18,
    fontFamily: 'montserrat-medium',
  },
  headerPlaceholderButton: {
    // backgroundColor: "yellow",
    height: 25,
    width: 25,
  },
  headerButton: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    height: '100%',
    alignItems: 'center',
  },
  headerLeftButtonText: {
    fontFamily: 'montserrat',
    fontSize: 18,
    textAlign: 'left',
  },
  headerRightButtonText: {
    fontFamily: 'montserrat',
    fontSize: 18,
    textAlign: 'right',
  },

  // Navigation Bar Button
  navigationBarButton: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    height: '100%',
    alignItems: 'center',
  },
  navigationBarButtonText: {
    color: '#fff',
    // fontFamily: "montserrat-medium",
    fontFamily: 'montserrat',
    fontSize: 18,
    textAlign: 'right',
  },

  // Header Progress Bar
  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: 'rgba(155,89,182,1.0)',
    overflow: 'hidden',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  progressText: {
    justifyContent: 'center',
    color: 'rgba(255,255,255,0.25)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },

  // Main Title Text
  titleTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'karla-bold',
    fontSize: 24,
  },

  titleHelperText: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'karla-bold',
    fontSize: 18,
  },

  // Main Text Input
  mainTextInputContainer: {
    width: 60,
    height: 35,
    borderBottomWidth: 1.0,
    borderColor: 'rgba(255,255,255,0.5)',
    justifyContent: 'center',
  },
  mainTextInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    ...Platform.select({
      web: {
        outlineColor: '#ccc',
      },
    }),
  },

  helperText: {
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
  },

  headerContainer: {
    backgroundColor: 'rgb(101,209,185)',
    paddingHorizontal: 20,
    paddingVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 14,
  },
  diagnosisList: {
    width: '30%',
    minWidth: 350,
  },
  formSectionContainer: {
    width: '100%',
    paddingHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },

  formSection: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // borderWidth: 1,
  },

  // Next Button
  nextButtonContainer: {
    justifyContent: 'flex-end',
    height: 60,
    width: '100%',
  },
  nextButtonContainerAbsolute: {
    height: 60,
  },
  nextButton: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderWidth: 1.0,
  },
  nextButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },

  normalButtonFullBorderless: {
    width: '100%',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  normalButtonFull: {
    borderWidth: 2.0,
    flexDirection: 'row',
    width: '100%',
    height: 50,
    borderColor: 'rgba(74,74,74,0.2)',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  normalButtonText: {
    color: 'rgba(153,153,153,0.7)',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },

  normalTealButtonFull: {
    backgroundColor: 'rgba(132,209,192,1.0)',
    flexDirection: 'row',
    width: '100%',
    height: 50,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  normalTealButtonText: {
    color: 'white',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },

  emptyListHelperText: {
    fontFamily: 'karla-bold',
    fontSize: 14,
    color: 'rgb(74,74,74)',
    textAlign: 'center',
  },

  linkedText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: 'rgb(155,89,182)',
  },
  regularText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: 'rgb(102,102,102)',
  },

  sectionItemContainer: (backgroundColor) => ({
    backgroundColor,
    width: '100%',
    height: 65,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderTopColor: '#ccc',
    borderBottomColor: '#ccc',
  }),
  sectionItemHorizontalContainer: {
    flexDirection: 'row',
    flex: 1.0,
    height: '100%',
  },
  sectionItem: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  sectionItemTitle: {
    flex: 0.8,
    justifyContent: 'center',
    paddingLeft: 32,
  },
  sectionItemLabelText: {
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },

  contentBulletPointsContainer: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    paddingTop: 5,
  },
  contentBulletPoints: {
    backgroundColor: 'rgb(102,102,102)',
    height: 8,
    width: 8,
  },
  contentBulletedText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 12,
    ...Platform.select({
      web: {
        marginBottom: 10,
      },
    }),
  },

  buttonOverTextInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  webContainer: {
    ...Platform.select({
      web: {
        maxWidth: 500,
        minWidth: 350,
        width: '100%',
        alignSelf: 'center',
      },
      default: {
        width: '100%',
      },
    }),
  },
  webContainerMaxWidth: {
    ...Platform.select({
      web: {
        maxWidth: 800,
        minWidth: 500,
        width: '100%',
        alignSelf: 'center',
      },
    }),
  },
  // webTopBarWidth: {
  // ...Platform.select({
  //   web: {
  //     height: '100%',
  //     width: '300%',
  //     position: 'absolute',
  //     left: "-100%"
  //     }
  //   })
  // }
})

export default styles

export { calculateAvailableContentHeight, DefaultFullHeight }
