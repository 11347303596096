import React from 'react'
import { SafeAreaView, Text } from 'react-native'

function ContactUs() {
  return (
    <SafeAreaView>
      <Text>Contact Us</Text>
    </SafeAreaView>
  )
}

export default ContactUs
