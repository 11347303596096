import AsyncStorage from '@react-native-async-storage/async-storage'

const ERROR_MESSAGE = 'Error in setItemAsyncStorage:'

export default async function setItemAsyncStorage(key, value) {
  if (!key || key.trim() === '' || !value) return

  try {
    await AsyncStorage.setItem(`@App:${key}`, JSON.stringify(value))
  } catch (error) {
    console.log(ERROR_MESSAGE, error)
  }
}
