import { apiFetchHandler } from '$api'
import { mapDataToMemberProfile } from '$utils'

import MemberProfile from '$data_models/MemberProfile.js'
import getRelationshipToProband from './getRelationshipToProband'
import sectionListOrder from '$screens/main/family/FamilyListScreen/constants/sectionListOrder.js'
import { relationshipTypes } from '$constants'

async function updateMemberProfile({
  probandID,
  authCredentials,
  memberID,
  selectedLanguage,
  saveError,
  saveProbandProfileToStore,
  saveCurrentMemberToStore,
  navigationHandler,
}) {
  try {
    if (!memberID) return
    const fetchMembers = async () => {
      try {
        const getReduxTree = {
          path: `refinery/redux-tree/${probandID}/app_redux_tree/`,
          method: 'get',
          token: authCredentials.accessToken,
          pageDetails: {
            page: 'FamilyPedigreeScreen.js',
          },
        }

        const response = await apiFetchHandler(getReduxTree)

        if (response.isError) {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })

          return
        }

        const familyDataStructure = [
          { title: relationshipTypes.YOU_AND_YOUR_CHILDREN, data: [] },
          { title: relationshipTypes.BROTHERS_AND_SISTERS, data: [] },
          { title: relationshipTypes.PARENTS, data: [] },
          { title: relationshipTypes.AUNTSUNCLES_DADS_SIDE, data: [] },
          { title: relationshipTypes.AUNTSUNCLES_MOMS_SIDE, data: [] },
          { title: relationshipTypes.GRANDPARENTS_DADS_SIDE, data: [] },
          { title: relationshipTypes.GRANDPARENTS_MOMS_SIDE, data: [] },
          { title: relationshipTypes.OTHER_FAMILY_MEMBERS, data: [] },
        ]

        const keys = Object.keys(sectionListOrder).filter((key) =>
          Object.keys(response).includes(key)
        )

        const newFamilyList = keys.map((familyType) => {
          const patientsData = response
          const relationshipToProband = familyType.toLowerCase()
          const isProband = relationshipToProband === 'proband'
          const SECTION_KEY = sectionListOrder[relationshipToProband]

          return {
            title: familyDataStructure[SECTION_KEY]?.title,
            data: isProband
              ? [patientsData[relationshipToProband]]
              : [...patientsData[relationshipToProband]],
          }
        })

        return newFamilyList
      } catch (error) {
        console.log('error: ', error)
      }
    }

    const getMemberProfile = async () => {
      try {
        // const probandID = proband?.probandID ?? null
        const selectedMemberId = Number(memberID) ?? null
        if (!probandID) return

        // Get MemberProfile of Proband from API
        const getMemberProfilePayload = {
          path: 'member/get/',
          method: 'post',
          token: authCredentials.accessToken,
          body: {
            proband_id: probandID,
            member_id: selectedMemberId,
            lang: selectedLanguage,
          },
          pageDetails: {
            page: 'DashboardScreen.js',
          },
          params: {
            lang: selectedLanguage,
          },
        }

        const response = await apiFetchHandler(getMemberProfilePayload)

        if (response.isError) {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return { isMemberSaved: false }
        }

        const memberProfile = mapDataToMemberProfile(response)

        const profileData = memberProfile?.profileData

        saveProbandProfileToStore(memberProfile)

        let age = profileData?.currentAge
        if (profileData?.deceased) {
          age = profileData?.ageOfDeath
        }

        const familyMembers = await fetchMembers()

        const currentMember = new MemberProfile().constructBasicProfile({
          relationship_to_proband: getRelationshipToProband(
            memberProfile?.memberID,
            familyMembers
          ),
          member_id: memberProfile?.memberID,
          first_name: profileData?.firstName,
          last_name: profileData?.lastName,
          gender: profileData?.biologicalGender,
          is_dead: profileData?.deceased,
          age,
        })

        saveCurrentMemberToStore(currentMember)
        navigationHandler()

        return { isMemberSaved: true }
      } catch (error) {
        console.log(
          '🚀 \n\n file: DashboardScreen.js:596 \n\n DashboardScreen \n\n getMemberProfile= \n\n error:',
          error
        )
        return { isMemberSaved: false }
      }
    }

    getMemberProfile()
  } catch (error) {
    console.log(error)
  }
}

export default updateMemberProfile
