import { useState, useEffect } from 'react'
import {
  SafeAreaView,
  View,
  Text,
  KeyboardAvoidingView,
  ScrollView,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'
import { useNavigation } from '@react-navigation/core'
import { i18n } from '$localization/config.js'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { updateMemberProfile, saveLastScreen } from '$screens/utils'

import styles from './styles.js'

import { useReduxDispatch, useReduxSelectors } from './reduxHandlers.js'

import PersonalGenderIdentity from '../PersonalGenderIdentity'
import NextButtonAbsolute from '$components/NextButtonAbsolute/index.js'
import DifferentGenderIdentityButton from './components/DifferentGenderIdentityButton/index.js'
import TitleQuestion from './components/TitleQuestion/index.js'
import MaleAndFemaleButtons from './components/MaleAndFemaleButtons/index.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'

import {
  CUSTOM_FLOW_ROUTES,
  NAVIGATORS,
  ROUTES,
} from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import {
  updatePersonalInformation,
  updateProbandProfileStorage,
} from './fetchHandlers.js'

import {
  EMPTY_STRING,
  PADDING_STRING,
  KEYBOARD_VERTICAL_OFFSET,
  DEFAULT_PROFILE_DATA,
  IS_FALSE,
} from './constants.js'

export default function PersonalGender() {
  const navigation = useNavigation()

  const OOPS_ALERT_TITLE = i18n.t('oops').default
  const PLEASE_SELECT_YOUR_BIOLOGICAL_SEX_FIRST_TITLE = i18n.t(
    'please_select_your_biological_sex_first'
  ).default
  const OPTIONAL_TITLE = i18n.t('optional').default

  const [selectedGender, setSelectedGender] = useState(null)
  const [genderIdentity, setGenderIdentity] = useState(EMPTY_STRING)
  const [pronoun, setPronoun] = useState(EMPTY_STRING)
  const [otherPronoun, setOtherPronoun] = useState(EMPTY_STRING)

  const {
    colorSettings,
    account,
    proband,
    selectedClinician,
    probandProfile,
    authCredentials,
  } = useReduxSelectors()

  const {
    saveProbandBiologicalGender,
    saveProbandDisease,
    clearProbandDiseases,
    clearProbandSkipLogics,
    saveFromOnboardingDiseases,
    saveFromOnboardingGeneTest,
    saveProbandProfileToStore,
    saveError,
    showLoadingView,
  } = useReduxDispatch()

  useEffect(() => {
    if (probandProfile) {
      const { biologicalGender } =
        probandProfile?.profileData ?? DEFAULT_PROFILE_DATA

      if (biologicalGender) {
        setSelectedGender(biologicalGender)
      }
    }
  }, [])

  const isNextButtonDisabled = () => !selectedGender

  const handleGenderSelection = (gender) => {
    const genderSelected = gender.toLowerCase()
    setSelectedGender(genderSelected)
    saveProbandBiologicalGender(genderSelected)
  }

  const navigateToGenderIdentity = async () => {
    const { show_gender_identity: showGenderIdentity = IS_FALSE } =
      selectedClinician || IS_FALSE

    if (!selectedGender) {
      showDefaultAlert(
        OOPS_ALERT_TITLE,
        PLEASE_SELECT_YOUR_BIOLOGICAL_SEX_FIRST_TITLE
      )
      return
    }

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.gender_selection,
    }

    showLoadingView(true)

    try {
      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })

      await updatePersonalInformation({
        proband,
        account,
        selectedGender,
        genderIdentity,
        pronoun,
        otherPronoun,
        authCredentials,
        saveError,
      })
      await updateProbandProfileStorage({
        proband,
        authCredentials,
        updateMemberProfile,
        saveProbandProfileToStore,
      })

      if (!showGenderIdentity) {
        navigation.navigate(NAVIGATORS?.PersonalDetailsNavigator?.name, {
          screen: ROUTES?.PersonalGenderIdentity?.name,
        })
      }
    } catch (error) {
      saveError(error)
    } finally {
      showLoadingView(false)
    }
  }

  const nextButtonAction = async () => {
    if (!selectedGender) return

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.gender_selection,
    }

    showLoadingView(true)

    try {
      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })

      await updatePersonalInformation({
        proband,
        account,
        selectedGender,
        genderIdentity,
        pronoun,
        otherPronoun,
        authCredentials,
        saveError,
      })
      await updateProbandProfileStorage({
        proband,
        authCredentials,
        updateMemberProfile,
        saveProbandProfileToStore,
      })

      saveProbandDisease([])
      clearProbandDiseases()
      clearProbandSkipLogics()
      saveFromOnboardingDiseases(true)
      saveFromOnboardingGeneTest(true)

      const nextRoute = getNextWorkflowRoute({
        defaultNextRoute: CUSTOM_FLOW_ROUTES.DiseaseListMainScreen.name,
      })

      handleNavigateToNextScreen({
        navigation,
        routeName: nextRoute,
        selectedScreen: EMPTY_STRING,
      })
    } catch (error) {
      saveError(error)
    } finally {
      showLoadingView(false)
    }
  }

  const getGenderIdentityInformation = (identityGender) => {
    setGenderIdentity(identityGender)
  }

  const getGenderPronounInformation = (pronounInformation) => {
    setPronoun(pronounInformation)
  }

  const getGenderOtherPronounInformation = (value) => {
    setOtherPronoun(value)
  }

  const { show_gender_identity: showGenderIdentity = IS_FALSE } =
    selectedClinician || IS_FALSE

  return (
    <ErrorBoundary>
      <SafeAreaView style={styles.container(colorSettings)}>
        <KeyboardAvoidingView
          style={[styles.keyboardAvoidingView]}
          behavior={PADDING_STRING}
          keyboardVerticalOffset={KEYBOARD_VERTICAL_OFFSET}
          enabled
        >
          <ScrollView style={styles.spacer}>
            <View style={styles.outerContentContainer}>
              <View style={[styles.content, styles.innerContentContainer]}>
                <TitleQuestion colorSettings={colorSettings} />

                <MaleAndFemaleButtons
                  handleGenderSelection={handleGenderSelection}
                  selectedGender={selectedGender}
                  colorSettings={colorSettings}
                />

                {!showGenderIdentity && (
                  <DifferentGenderIdentityButton
                    navigateToGenderIdentity={navigateToGenderIdentity}
                    colorSettings={colorSettings}
                  />
                )}
              </View>

              {showGenderIdentity && (
                <Text
                  style={[
                    styles.titleText(colorSettings),
                    styles.genderIdentityText,
                  ]}
                >
                  {OPTIONAL_TITLE}
                </Text>
              )}
            </View>

            {showGenderIdentity && (
              <PersonalGenderIdentity
                navigation={navigation}
                getGenderIdentityInfo={getGenderIdentityInformation}
                getGenderPronounInfo={getGenderPronounInformation}
                getGenderOtherPronounInfo={getGenderOtherPronounInformation}
              />
            )}
          </ScrollView>

          <NextButtonAbsolute
            onPressAction={nextButtonAction}
            isDisabled={isNextButtonDisabled()}
          />
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ErrorBoundary>
  )
}
