import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import DarkGraySwitch from '../../../CustomSwitches/DarkGraySwitch'
import { i18n } from '$localization/config.js'
import styles from './styles'
import { DOMAIN_CLIENT_NAME } from '$utils/globalVariables.js'

function ModalContentRow({
  onLinkPress,
  onSwitchAction,
  switchValue,
  isWhiteLabeled = false,
  linkText,
  confirmationText,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const whiteLabelConfirmationText = `${i18n.t(
    'i_have_read_and_accept_the_.default'
  )} ${DOMAIN_CLIENT_NAME} ${i18n.t('terms_and_conditions.default')}`

  return (
    <View style={styles.modalContentRowContainer}>
      <View style={styles.modalContentRowLinkContainer}>
        <View style={styles.modalContentRowLinkTextContainer}>
          <Text>
            <Text style={styles.modalContentRowLinkText}>
              {isWhiteLabeled
                ? `${i18n.t('view_the_.default')} ${DOMAIN_CLIENT_NAME}`
                : i18n.t('view_our_.default')}
            </Text>

            <Text
              style={styles.modalContentRowLinkTextLinked(colorSettings)}
              onPress={onLinkPress}
            >
              {linkText}
            </Text>
          </Text>
        </View>

        <View style={styles.modalContentRowLinkSwitchContainer}>
          <DarkGraySwitch value={switchValue} onValueChange={onSwitchAction} />
        </View>
      </View>

      <View style={styles.modalContainerRowConfirmationTextContainer}>
        <Text style={styles.modalContainerRowConfirmationText}>
          {isWhiteLabeled ? whiteLabelConfirmationText : confirmationText}
        </Text>
      </View>
    </View>
  )
}

export default ModalContentRow
