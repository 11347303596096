import { View, Modal, Text, FlatList, TouchableOpacity } from 'react-native'

import { i18n } from '$localization/config.js'

import styles from '../../../../styles'

export default function AvatarsModalWeb({
  genderAvatarOptions,
  modalVisible,
  setModalVisible,
  sendAvatarPayload,
  colorSettings,
  getAvatarComponent,
}) {
  const AVATAR_SELECT_TITLE = i18n.t('select_avatar').title
  const CANCEL_TEXT = i18n.t('cancel')?.default
  return (
    <View>
      <Modal animationType="none" transparent visible={modalVisible}>
        <View style={styles.overlay}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.textAvatarsSheet}>{AVATAR_SELECT_TITLE}</Text>

              <FlatList
                data={genderAvatarOptions}
                numColumns={3}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={async () => {
                      await sendAvatarPayload({
                        icon: item.gender,
                        skinTone: item.skinTone,
                      })
                      setModalVisible(false)
                    }}
                  >
                    <View style={styles.avatarsContainer}>
                      {getAvatarComponent(item.image)}
                    </View>
                  </TouchableOpacity>
                )}
              />

              <TouchableOpacity
                style={styles.cancelButtonContainer}
                onPress={() => setModalVisible(false)}
              >
                <Text style={styles.cancelButtonText(colorSettings)}>
                  {CANCEL_TEXT}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}
