export default function formatResult(value, unit) {
  const formatValue = Number(value)

  if (Number.isNaN(formatValue)) {
    return 'Invalid input'
  }

  const fixedValue = Math.floor(formatValue)

  const formatResultString = `${fixedValue} ${unit}`

  return formatResultString
}
