import apiFetchHandler from '$api/apiFetchHandler'

async function removeGeneTest({
  removedGeneTestData,
  probandProfile,
  authCredentials,
  setLoadingView,
  saveError,
}) {
  await Promise.all(
    removedGeneTestData.map(async (item) => {
      const removeMemberGeneTestingPayload = {
        path: `member/${probandProfile.memberID}/remove_genetic_testing/`,
        method: 'post',
        token: authCredentials.accessToken,
        body: {
          proband_id: probandProfile?.memberID,
          member_genetic_testing_id: item.member_gene_id,
        },
        pageDetails: {
          page: 'PositiveGeneTestOnboarding.js',
        },
      }

      const response = await apiFetchHandler(removeMemberGeneTestingPayload)

      if (response?.isError) {
        setLoadingView(false)
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }
    })
  )
}

export default removeGeneTest
