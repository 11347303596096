import { View, Text, StyleSheet } from 'react-native'

import CustomizableButton from '$components/CustomizableButton'
import InputAge from '$screens/personal_details/onboarding_disease_selection/components/input_age'

function ToggleWithInputView({
  colorSettings,
  buttonTitle,
  inputTitle,
  currentState,
  onToggle,
  inputAge,
  setToggle,
  setOnPress,
  getCurrentAge,
  memberAge,
  memberAgeOfDeath,
  onActiveScreen,
}) {
  function RenderInputAge({ toggled }) {
    if (toggled) {
      return (
        <View style={styles.inputContainer}>
          <InputAge
            colorSettings={colorSettings}
            headerTitle={inputTitle}
            currentAge={inputAge}
            onChange={getCurrentAge}
            memberAge={memberAge}
            memberAgeOfDeath={memberAgeOfDeath}
            onActiveScreen={onActiveScreen}
          />
        </View>
      )
    }
    return <View />
  }

  return (
    <View style={styles.mainContainer}>
      <CustomizableButton
        colorSettings={colorSettings}
        title={buttonTitle}
        // currentState={currentState}
        onPress={(val) => setOnPress(val)}
        setToggle={setToggle}
      />
      <RenderInputAge toggled={setToggle} />
    </View>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  inputContainer: {
    marginVertical: 10,
  },
})

export default ToggleWithInputView
