export default function debounce(func, timeout = 700) {
  let timer

  return (...args) => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
