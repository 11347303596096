const MONTHS_IN_YEAR = 12
const ADJUST_MONTH = 1
const NO_ADJUSTMENT = 0

export default function calculateMonthDifference({
  currentYear,
  birthYear,
  currentDate,
  birthDate,
}) {
  const monthsElapsed =
    (currentYear - birthYear) * MONTHS_IN_YEAR +
    (currentDate.getMonth() - birthDate.getMonth())

  const currentMonthDays = currentDate.getDate()
  const birthMonthDays = birthDate.getDate()

  return (
    monthsElapsed -
    (currentMonthDays < birthMonthDays ? ADJUST_MONTH : NO_ADJUSTMENT)
  )
}
