import { StyleSheet } from 'react-native'

const BLACK = '#000'
const GREYISH_BLACK = 'rgba(74,74,74,0.5)'

const styles = StyleSheet.create({
  inputContainer: { paddingVertical: 15 },
  passwordInputContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  showPasswordContainer: (colorSettings) => ({
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -26,
    borderBottomColor: colorSettings?.text_input_label_color_1 || BLACK,
  }),
  showPasswordImage: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  passwordHelperText: (colorSettings) => ({
    marginVertical: 10,
    color: colorSettings?.text_input_label_color_1 || GREYISH_BLACK,
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  }),
})

export default styles
