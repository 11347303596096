import { Text, TouchableOpacity, View } from 'react-native'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Dropdown from '$components/Dropdown'
import { IS_WEB } from '$utils/Platforms'
import { i18n } from '$localization/config.js'
import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import { AGE_TYPE_VALUES } from '$screens/main/family/edit_profile/constants'

function AgeDropdown({ inputRef, onButtonPress, buttonValue }) {
  const [isDropdownShown, setIsDropdownShown] = useState(false)
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const availableDiagnosisValues = Object.values(AGE_TYPE_VALUES)

  const handleShowingChoice = () => {
    if (IS_WEB()) return setIsDropdownShown(!isDropdownShown)

    const isExpanded = inputRef.current.expand()

    if (!isExpanded) {
      return inputRef.current.expand()
    }

    return inputRef.current.close()
  }

  return (
    <View styles={section_styles.choiceOfDiagnosisAgeContainer}>
      <TouchableOpacity
        style={section_styles.choiceOfDiagnosisAgeButton}
        onPress={handleShowingChoice}
      >
        <Text
          style={section_styles.choiceOfDiagnosisAgeButtonText(colorSettings)}
        >
          ({i18n.t(buttonValue).default})
        </Text>
      </TouchableOpacity>

      {IS_WEB() && (
        <Dropdown
          data={availableDiagnosisValues}
          isShown={isDropdownShown}
          onPress={onButtonPress}
          selectedItem={buttonValue}
        />
      )}
    </View>
  )
}

export default AgeDropdown
