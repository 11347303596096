import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome'
import { Sae } from 'react-native-textinput-effects'
import React, { forwardRef, useRef } from 'react'

import styles from './styles'

import {
  DEFAULT_ICON_NAME,
  DEFAULT_ICON_COLOR,
  INPUT_PADDING,
  BORDER_HEIGHT,
  AUTO_CAPITALIZE,
  AUTO_CORRECT,
  RETURN_KEY_TYPE,
  LABEL_HEIGHT,
} from './constants'

const CustomSaeTextInput = forwardRef(
  (
    { colorSettings, inputAccessoryViewID, value = '', onChangeText, ...props },
    ref
  ) => {
    const textInputRef = useRef(null)

    React.useImperativeHandle(ref, () => ({
      focusTextInput: () => {
        textInputRef.current.focus()
      },
    }))

    return (
      <Sae
        ref={textInputRef}
        style={styles.textInputContainer(colorSettings)}
        labelStyle={styles.textInputLabel(colorSettings)}
        inputStyle={styles.textInputField(colorSettings)}
        iconClass={FontAwesomeIcon}
        iconName={DEFAULT_ICON_NAME}
        iconColor={DEFAULT_ICON_COLOR}
        inputPadding={INPUT_PADDING}
        labelHeight={LABEL_HEIGHT}
        borderHeight={BORDER_HEIGHT}
        autoCapitalize={AUTO_CAPITALIZE}
        autoCorrect={AUTO_CORRECT}
        returnKeyType={RETURN_KEY_TYPE}
        inputAccessoryViewID={inputAccessoryViewID}
        value={value}
        onChangeText={onChangeText}
        {...props}
      />
    )
  }
)

export default CustomSaeTextInput
