const biometricTypeMapping = {
  fingerprint: {
    android: require('$assets/images/biometric/AndroidBiometricsIcon.png'),
    ios: require('$assets/images/biometric/TouchIDios.png'),
  },
  'face-recognition': {
    android: require('$assets/images/biometric/AndroidBiometricsIcon.png'),
    ios: require('$assets/images/biometric/FaceIDios.png'),
  },
  defaultIcon: require('$assets/images/biometric/TouchIDios.png'),
}

export default biometricTypeMapping
