import { Text, View } from 'react-native'
import React from 'react'
import styles from '../../styles'
import { i18n } from '$localization/config.js'

function LoginError({ shouldShowError }) {
  return (
    <View style={styles.loginErrorContainer}>
      {shouldShowError && (
        <Text style={styles.errorRequiredText}>
          *{i18n.t('required').capitalized}
        </Text>
      )}
    </View>
  )
}

export default LoginError
