import { Text, View } from 'react-native'

import styles from './styles'

export default function TopAndMiddleTexts({
  topScreenText,
  middleScreenText,
  colorSettings,
}) {
  return (
    <>
      <View style={styles.containerText}>
        <Text style={styles.titleText(colorSettings)}>{topScreenText}</Text>
      </View>
      <View style={styles.containerText}>
        <Text style={styles.bodyText(colorSettings)}>{middleScreenText}</Text>
      </View>
    </>
  )
}
