import { i18n } from '$localization/config.js'

const REPLACEMENTS = {
  '1st': '1st',
  '2nd': '2nd',
  '3rd': '3rd',
  cousin: 'cousin',
  removed: 'removed',
  '1x': 'once',
  '2x': 'twice',
}

export default function wordReplacements(item) {
  const translatedWords = item.map(
    (word) => i18n.t(REPLACEMENTS[word])?.default || word
  )

  const translatedString = translatedWords.join(' ')

  return translatedString
}
