import { useState } from 'react'
import { View } from 'react-native'
import uuid from 'react-native-uuid'

import CustomizableButton from '$components/CustomizableButton'

import { i18n } from '$localization/config.js'
import { formatText } from '$screens/utils'
import {
  convertTextToBool,
  convertBoolToText,
  decreaseFontSize,
} from '$screens/CustomScreen/helpers'

import ComponentGenerator from '../../ComponentGenerator'

import FieldViewer from '../../FieldViewer'

import updateSurveyAnswers from '../../../helpers/updateSurveyAnswers'

const DEFAULTS = {
  ANSWER: false,
  BACKGROUND_COLOR: 'rgba(0,0,0,0.2)',
  CHOICE_ID: null,
}

const isDisabled = false

export default function ToggleField({
  masterQuestion,
  answer = '',
  colorSettings = [],
  surveyData,
  storeFieldSurveyAnswers = () => {},
}) {
  const [isToggled, setIsToggled] = useState(
    convertTextToBool(answer?.answer || DEFAULTS.ANSWER)
  )
  const [hasRendered, setHasRendered] = useState(false)

  const {
    question_label: questionLabel,
    id: masterQuestionId,
    translation_key: translationKey,
  } = masterQuestion

  const styleHandler = () => {
    if (isToggled) {
      return {
        backgroundColor:
          colorSettings?.selectable_btn_active_1 || DEFAULTS.BACKGROUND_COLOR,
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  }

  const onToggle = () => {
    setIsToggled((prevItem) => !prevItem)

    const payload = {
      master_question_id: masterQuestionId,
      master_question_choice_id: DEFAULTS.CHOICE_ID,
      answer: convertBoolToText(!isToggled),
    }

    storeFieldSurveyAnswers(payload)

    if (!hasRendered) {
      setHasRendered(true)
    }
  }

  const title = i18n.t(translationKey).default ?? questionLabel

  return (
    <View>
      <FieldViewer fieldName="toggle field" />

      <CustomizableButton
        key={uuid.v4()}
        colorSettings={colorSettings}
        onPress={onToggle}
        styleHandler={styleHandler}
        fontSizeHandler={() =>
          decreaseFontSize(i18n.t(formatText(questionLabel)).default)
        }
        title={title}
        isDisabled={isDisabled}
      />

      {isToggled && (
        <ComponentGenerator
          childQuestionnaire={masterQuestion.child_questionnaire}
          surveyObject={updateSurveyAnswers({ surveyData, hasRendered })}
        />
      )}
    </View>
  )
}

// ** typescript reference **

// ToggleField.propTypes = {
//   masterQuestion: PropTypes.isRequired,
//   colorSettings: PropTypes.objectOf(PropTypes.string),
//   answer: PropTypes.number,
//   storeFieldSurveyAnswers: PropTypes.func,
// }

// ToggleField.defaultProps = {
//   colorSettings: [],
//   answer: '',
//   storeFieldSurveyAnswers: () => {},
// }
