import * as actionTypes from './actionTypes'

const setIsFirstLogin = (data) => ({
  type: actionTypes.SET_IS_FIRST_LOGIN,
  data,
})

const setLanguage = (data) => ({
  type: actionTypes.CHANGE_LANGUAGE,
  data,
})

const setBottomTabBarTitles = () => ({
  type: actionTypes.SET_BOTTOM_TAB_BAR_TITLES,
})

const setBackgroundRefreshAll = (data) => ({
  type: actionTypes.SET_BG_REFRESH_ALL,
  data,
})

const setNavigationState = (data) => ({
  type: actionTypes.SET_NAVIGATION_STATE,
  data,
})

const setBackgroundRefreshIntervalAll = (data) => ({
  type: actionTypes.SET_BG_REFRESH_INTERVAL_ALL,
  data,
})

const setShouldProceedToMain = (data) => ({
  type: actionTypes.SET_SHOULD_PROCEED_TO_MAIN,
  data,
})

const setShouldShowOtherFamilyMembersTutorial = (data) => ({
  type: actionTypes.SET_SHOULD_SHOW_OTHER_FAMILY_MEMBERS_TUTORIAL,
  data,
})

const setDelayOtherFamilyMembersTutorial = (data) => ({
  type: actionTypes.SET_DELAY_OTHER_FAMILY_MEMBERS_TUTORIAL,
  data,
})

const setLastLoginScreen = (data) => ({
  type: actionTypes.SET_LAST_LOGIN_SCREEN,
  data,
})

const setInviteID = (data) => ({
  type: actionTypes.SET_INVITE_ID,
  data,
})

const setUserHasPassword = (data) => ({
  type: actionTypes.SET_USER_HAS_PASSWORD,
  data,
})

const setError = (data) => ({
  type: actionTypes.SET_NOTIFICATION_ERROR,
  data,
})

const setShowLoadingView = (data) => ({
  type: actionTypes.SET_SHOW_LOADING_VIEW,
  data,
})

const setIsOnboarding = (data) => ({
  type: actionTypes.SET_IS_ONBOARDING,
  data,
})

const setCurrentScreenStack = (data) => ({
  type: actionTypes.SET_CURRENT_SCREEN_STACK,
  data,
})

const setAppSettings = (data) => ({
  type: actionTypes.SET_APP_SETTINGS,
  data,
})

const setSplashSettings = (data) => ({
  type: actionTypes.SET_SPLASH_SETTINGS,
  data,
})

const setColorSettings = (data) => ({
  type: actionTypes.SET_COLOR_SETTINGS,
  data,
})

const setTurnOnIsFirstLogin = () => ({
  type: actionTypes.TURN_ON_IS_FIRST_LOGIN,
})

export {
  setIsFirstLogin,
  setLanguage,
  setBottomTabBarTitles,
  setBackgroundRefreshAll,
  setBackgroundRefreshIntervalAll,
  setShouldProceedToMain,
  setShouldShowOtherFamilyMembersTutorial,
  setDelayOtherFamilyMembersTutorial,
  setLastLoginScreen,
  setInviteID,
  setUserHasPassword,
  setError,
  setShowLoadingView,
  setIsOnboarding,
  setCurrentScreenStack,
  setNavigationState,
  setAppSettings,
  setSplashSettings,
  setColorSettings,
  setTurnOnIsFirstLogin,
}
