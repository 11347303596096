const MINIMUM_YEARS_AGO = 184
const MINIMUM_DATE_FOR_ANDROID = new Date(1840, 0, 1)
const DEFAULT_COLOR_SCHEME = 'light'
const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_UNIT = 'hours'
const DAY_UNIT = 'days'
const YEAR_UNIT = 'years'
const MINUTES_UNIT = 'minutes'

const NO_PREFERENCE = 'no-preference'
const SET_EVENT_TYPE = 'set'
const DISMISS_TRUE = true

const DATE_MODE = 'date'
const SPINNER_DISPLAY = 'spinner'

export {
  MINIMUM_YEARS_AGO,
  MINIMUM_DATE_FOR_ANDROID,
  DEFAULT_COLOR_SCHEME,
  DATE_FORMAT,
  TIME_UNIT,
  DAY_UNIT,
  YEAR_UNIT,
  MINUTES_UNIT,
  NO_PREFERENCE,
  SET_EVENT_TYPE,
  DISMISS_TRUE,
  DATE_MODE,
  SPINNER_DISPLAY,
}
