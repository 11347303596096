import { StyleSheet } from 'react-native'

const GREY = 'rgb(74, 74, 74)'
const FADED_WHITE = 'rgb(204, 204, 204)'
const BLACK = 'black'

export default StyleSheet.create({
  datePickerContainer: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  datePickerButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  yearStyle: {
    borderStyle: 'solid',
    borderColor: FADED_WHITE,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: GREY,
    flex: 1,
    alignSelf: 'center',
  },
  monthStyle: {
    borderStyle: 'solid',
    borderColor: FADED_WHITE,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: GREY,
    flex: 1,
  },
  dayStyle: {
    borderStyle: 'solid',
    borderColor: FADED_WHITE,
    borderRadius: 3,
    marginTop: 7,
    height: 34,
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: GREY,
    flex: 1,
  },
  dateContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  inputField: (colorSettings) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: colorSettings?.text_input_color_2 || BLACK,
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    height: 50,
    borderBottomWidth: 0.5,
    alignSelf: 'center',
    borderBottomColor: colorSettings?.btn_no_fill_border_2 || BLACK,
  }),
  mainContainer: {
    width: '100%',
  },
})
