import { Component } from 'react'
import { Text, View, SafeAreaView } from 'react-native'

/* Custom Components and Constants */
import LoadingView from '$components/LoadingView'

/* Custom Utilities */
import { IS_WEB } from '$utils/Platforms.js'
import { i18n, translations } from '$localization/config.js'

/* Custom Stylesheets */
import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

class BaseController extends Component {
  /* This is the basic React Native Component to be used when adding a new screen */

  /* Use setSharedInstance to provide `static navigationOptions` access to the component's properties */
  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  /* Initial component state */
  state = {
    shouldRenderComponent: false,
    showLoadingView: true,
    scrollViewHeight: calculateAvailableContentHeight(),
  }

  /* Initial component refs */
  parentRefs = {
    someRefKey: null,
  }

  colorSettings = {}

  componentDidMount() {
    // This component event happens after render().
    this.toggleLoadingView(false, 1000)
  }

  setNavigationEventListener = (event = 'focus') => {
    // Set the component's navigation event listeners
    // Default event: 'didFocus'
    const { navigation } = this.props
    navigation.addListener(event, async () => {
      this.setNavigationEventAction()
    })
  }

  setNavigationEventAction = () => {
    /* Override this function in your own component... */
  }

  setScrollViewHeightListenerForWeb = (
    setValue = true,
    extraHeightForWeb = 0
  ) => {
    /* Set listener to adjust scrollview height for Web */

    const scrollViewHeight = calculateAvailableContentHeight(extraHeightForWeb)

    if (IS_WEB()) {
      if (setValue) {
        window.addEventListener('resize', () =>
          this.setState({ scrollViewHeight })
        )
      } else {
        window.removeEventListener('resize', () =>
          this.setState({ scrollViewHeight })
        )
      }
    }
  }

  render() {
    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView style={global_styles.containerWhite}>
          <LoadingView visible={this.state.showLoadingView} />
        </SafeAreaView>
      )
    }

    return (
      <SafeAreaView style={global_styles.containerWhite}>
        <View
          style={[
            global_styles.content,
            {
              flex: 1.0,
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <Text style={global_styles.emptyListHelperText}>
            Default Base Controller View
          </Text>
        </View>
      </SafeAreaView>
    )
  }

  setParentRef(key, ref) {
    // Add new refs by adding objects in this.parentRefs
    // and then passing an object using the Component's 'ref' prop
    if (key && ref) {
      this.parentRefs[key] = ref
    }
  }

  toggleLoadingView = (shouldReloadView = false, delay = 0) => {
    setTimeout(() => {
      this.setState({
        shouldRenderComponent: !shouldReloadView,
        showLoadingView: shouldReloadView,
      })
    }, delay)
  }

  reloadAndRenderView = (
    showLoadingView = true,
    shouldRenderComponent = true,
    delay = 0
  ) => {
    setTimeout(() => {
      this.setState({
        shouldRenderComponent,
        showLoadingView,
      })
    }, delay)
  }

  toggleNextButtonColor = (enabled = true) => {
    let style = {
      backgroundColor: this.colorSettings?.bottom_next_btn_disabled ?? 'white',
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }
    if (enabled) {
      style = {
        backgroundColor: this.colorSettings?.bottom_next_btn_enabled ?? 'white',
        borderColor: this.colorSettings?.bottom_next_btn_enabled,
      }
    }
    return style
  }

  inversedToggleNextButtonColor = (disabled = false, colorSettings = {}) => {
    let style = {
      backgroundColor: colorSettings?.bottom_next_btn_enabled ?? 'white',
      borderColor: colorSettings?.bottom_next_btn_enabled,
    }
    if (disabled) {
      style = {
        backgroundColor: colorSettings?.bottom_next_btn_disabled ?? 'white',
        borderColor: colorSettings?.bottom_next_btn_disabled,
      }
    }
    return style
  }

  validateInput = () =>
    // Override to use this function.
    true

  onChangeLanguage = () => {
    this.storage.getItem('LANGUAGE').then((value) => {
      // let index = Math.floor(Math.random() * 2)
      const selectedLanguage = value === 'en' ? 'es' : 'en'

      i18n.translations.en = translations[selectedLanguage]

      this.storage.setItem('LANGUAGE', selectedLanguage, false)

      this.setState(this.state)
    })
  }

  navigateTo = (routeName, params = {}) => {
    const { navigate } = this.props.navigation

    navigate(routeName, { ...params })
  }
}

export default BaseController
