import { apiFetchHandler } from '$api'

export const getMemberDetails = async ({
  authCredentials,
  memberId,
  probandId,
  APIfetchAction,
  selectedLanguage,
}) => {
  const headers = authCredentials
  const payload = {
    proband_id: probandId,
    member_id: memberId,
  }
  // let diseaseMethods = new DiseaseList()

  const getMemberProfilePayload = {
    path: 'member/get/',
    method: 'post',
    token: authCredentials.accessToken,
    body: {
      proband_id: probandId,
      member_id: memberId,
      lang: selectedLanguage,
    },
    pageDetails: {
      page: '_utils.js',
    },
  }

  const response = await apiFetchHandler(getMemberProfilePayload)

  if (response.isError) return

  APIfetchAction(response)
}

export const convertIncomingGeneTest = (memberGeneTest, geneTestType) => {
  const memberGeneTestList = memberGeneTest
  const geneTestVal = geneTestType === 'positive' ? 'p' : 'n'
  const filteredGeneTest = geneTestFilter(memberGeneTestList, geneTestVal)
  const convertGeneTest = filteredGeneTest.map((item) => ({
    gene: item.gene,
    id: item.gene_id,
  }))

  return convertGeneTest
}

const geneTestFilter = (memberGeneTestList, geneTestType) => {
  const result = memberGeneTestList.filter(
    (item) => item.result === geneTestType
  )
  return result
}

export const convertOutgoingGeneTest = (
  selectedGeneTest,
  geneTestType,
  memberData
) => {
  const geneTestVal = geneTestType === 'positive' ? 'p' : 'n'
  const memberGeneTest = selectedGeneTest
  const convertedGeneTest = memberGeneTest.map((val) => ({
    type: '',
    result: geneTestVal,
    variant: '',
    status: '',
    member: memberData.member_id,
    gene_id: val.id,
  }))

  return convertedGeneTest
}

export const memberGeneticTesting = (prevData, currentData, geneTestType) => {
  const positiveResult = prevData.filter((val) => val.result === 'p')
  const negativeResult = prevData.filter((val) => val.result === 'n')

  let filteredData = []

  if (geneTestType === 'positive') {
    filteredData = negativeResult
  } else {
    filteredData = positiveResult
  }

  const filterSave = currentData.concat(filteredData)
  const finalConversion = filterSave.map((val) => ({
    genetic_testing_id: val.gene_id,
    result: val.result,
  }))

  return finalConversion
}

export const convertGeneTest = (geneTests) => {
  if (!geneTests) return {}
  const convertedGeneTests = geneTests.map((val) => ({
    genetic_testing_id: val.gene_id,
    result: val.result,
  }))

  return convertedGeneTests
}

export const addRemoveDataGeneTest = (current, prev) => {
  // current doesn't exist to previous
  // if both exist no change
  const pointer1 = 0
  const pointer2 = 0
  // combine data
  const combinedData = current.concat(prev)
  // remove duplicates
  const removeDuplicate = rmvDuplicate(combinedData, 1)

  // current duplicates vs duplicate
  const addData = geneTestAddRemove(current, removeDuplicate)
  const removeData = geneTestAddRemove(prev, removeDuplicate)
  const payload = {
    addGeneTest: addData,
    removeGeneTest: removeData,
  }

  return payload
}

const geneTestAddRemove = (data, removeDuplicate) => {
  const combineCurrentVSDuplicates = data.concat(removeDuplicate)
  const getCurrentDuplicates = rmvDuplicate(combineCurrentVSDuplicates, 2)
  const removeCurrentDuplicate = Array.from(
    new Set(getCurrentDuplicates.map((a) => a.genetic_testing_id))
  ).map((id) => getCurrentDuplicates.find((a) => a.genetic_testing_id === id))
  return removeCurrentDuplicate
}

const rmvDuplicate = (memberGeneTest, duplicates) => {
  const data = memberGeneTest.filter(
    (el, _, arr) =>
      memberGeneTest.filter(
        (el2) => el2.genetic_testing_id === el.genetic_testing_id
      ).length === duplicates
  )

  return data
}

export const findGeneTestMemberID = (memberGeneTest, geneID) => {
  const memberGeneTestID = memberGeneTest.find(
    (memberData) => memberData.gene_id === geneID
  )
  return memberGeneTestID.id
}
