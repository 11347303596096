import { Text, View } from 'react-native'

import styles from './styles'

export default function Content({
  topScreenText,
  middleScreenText,
  bottomScreenText,
  colorSettings,
}) {
  return (
    <>
      <View style={styles.topScreenTextContainer}>
        <Text style={styles.titleText(colorSettings)}>{topScreenText}</Text>
      </View>

      <View style={styles.middleAndBottomScreenTextContainer}>
        <Text style={styles.middleText(colorSettings)}>{middleScreenText}</Text>

        <Text style={styles.bottomText(colorSettings)}>{bottomScreenText}</Text>
      </View>
    </>
  )
}
