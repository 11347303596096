import { StyleSheet } from 'react-native'

const GREY = 'rgb(65,70,97)'

export default StyleSheet.create({
  sectionTitleText: (colorSettings) => ({
    color: colorSettings?.member_section_title || GREY,
    fontFamily: 'karla-bold',
    fontSize: 18,
    marginHorizontal: 16,
    marginTop: 12,
  }),
  sectionSubTitleText: (colorSettings) => ({
    color: colorSettings?.member_section_title || GREY,
    fontFamily: 'karla-bold',
    fontSize: 12,
    marginHorizontal: 16,
  }),
  clinicianCard: { paddingTop: 16 },
})
