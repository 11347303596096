import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  containerStyle: {
    flex: 1,
    flexDirection: 'row',
  },
  yearStyle: {
    flex: 1,
    marginRight: 5,
  },
  monthStyle: {
    flex: 1,
    marginRight: 5,
  },
  dayStyle: {
    flex: 1,
  },
  textStyle: {
    fontSize: 16,
  },
})

export default styles
