import { useCallback, useState } from 'react'
import { View, TextInput } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'
import styles from './Styles'
import { debounce } from '$utils'
import { i18n } from '$localization/config.js'

const SearchBar = ({ colorSettings, searchValue, onChange }) => {
  const [diseaseName, setDiseaseName] = useState(searchValue)

  const verifyOnChange = useCallback(debounce(onChange), [])

  const handleChange = (text) => {
    setDiseaseName(text)
    verifyOnChange(text)
  }

  return (
    <View style={styles.mainContainer}>
      <View
        style={[
          styles.searchBar,
          {
            backgroundColor: colorSettings?.selectable_btn_active_1,
            borderColor:
              colorSettings?.selectable_btn_border_active_1 ||
              'rgba(255, 255, 255, 0.5)',
          },
        ]}
      >
        <TextInput
          style={[
            styles.textInput,
            {
              color:
                colorSettings?.selectable_btn_text_active_1 ||
                'rgba(74, 74, 74, 1)',
            },
          ]}
          value={diseaseName}
          onChangeText={handleChange}
          placeholder={i18n.t('search_diseases').default}
          placeholderTextColor={colorSettings?.selectable_btn_text_active_1}
        />
        <View style={styles.imageContainer}>
          <Icon
            name="search"
            size={20}
            color={colorSettings?.text_input_label_color_2 || 'black'}
          />
        </View>
      </View>
    </View>
  )
}

// SearchBar.propTypes = {
//   colorSettings: PropTypes.shape({
//     selectable_btn_text_active_1: PropTypes.string,
//     text_input_label_color_2: PropTypes.string,
//     selectable_btn_active_1: PropTypes.string,
//     selectable_btn_border_active_1: PropTypes.string,
//   }),
//   searchValue: PropTypes.string,
//   onChange: PropTypes.func,
// }

SearchBar.defaultProps = {
  colorSettings: {},
  searchValue: '',
  onChange: () => {},
}

export default SearchBar
