export default function removePunctuationMarksFromString(
  text,
  removeSpaces = true
) {
  let regex = /[&\/\\#,+()$~%.'":*?<>{}]/g
  if (removeSpaces) {
    regex = /\W+/g
  }
  return text.replace(regex, '')
}
