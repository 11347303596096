import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  bottomSheetContainer: {
    flex: 1,
    marginBottom: 20,
    paddingBottom: 20,
  },
  bottomSheetButtonContainer: (isSelectedButton) => ({
    width: '100%',
    height: 45,
    paddingHorizontal: 7,
    justifyContent: 'center',
    backgroundColor: isSelectedButton,
    borderRadius: 6,
  }),
  buttonNameText: {
    fontSize: 15,
    fontWeight: '400',
  },
})

export default styles
