import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  biometricsContainer: {
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  biometricsButton: {
    alignItems: 'center',
  },
  biometricsIcon: { width: 50, height: 50, resizeMode: 'contain' },
  biometricsText: { fontSize: 12, marginTop: 12 },
})

export default styles
