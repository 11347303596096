import { StyleSheet } from 'react-native'

const GREEN = 'rgb(101,209,185)'
const WHITE = '#fff'

export default StyleSheet.create({
  container: {
    backgroundColor: GREEN,
    width: '100%',
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    color: WHITE,
  },
})
