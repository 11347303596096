import Alert from '$components/Alert'
import { i18n } from '$localization/config.js'

const underageAsyncAlert = () => {
  const asyncAlertTitle = i18n.t('birthday')?.default
  const asyncAlertSubtitle = i18n.t('underage_disclaimer')?.default

  return new Promise((resolve) => {
    Alert.alert(
      asyncAlertTitle,
      asyncAlertSubtitle,
      [
        { text: i18n.t('cancel').default, onPress: () => resolve(false) },
        { text: i18n.t('continue').default, onPress: () => resolve(true) },
      ],
      { cancelable: false }
    )
  })
}

export { underageAsyncAlert }
