import { View } from 'react-native'
import { RNChipView } from 'react-native-chip-view'
import { useSelector } from 'react-redux'
import styles from './styles'

const DARK_SHADE = 'rgba(41,43,47,0.6)'

function Chips({ selectedGeneTest, handleDeleteSelectedGene }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.chipContainer}>
      {selectedGeneTest &&
        selectedGeneTest.map((data) => (
          <View style={styles.chipWrapContainer}>
            <RNChipView
              avatar={false}
              backgroundColor={colorSettings?.pill_bgcolor || DARK_SHADE}
              cancelable
              cancelableStyle={styles.cancelableStyle(colorSettings)}
              title={data.Name}
              titleStyle={styles.chipText(colorSettings)}
              onPress={() => handleDeleteSelectedGene(data)}
            />
          </View>
        ))}
    </View>
  )
}

export default Chips
