import { View, Text, TouchableOpacity } from 'react-native'

import global_styles from '$assets/styles/global.styles.js'
import styles from './styles.js'

export default function NextButton({
  primaryAction,
  buttonStyle,
  buttonTitleStyle,
  buttonTitle,
  disabled,
}) {
  return (
    <View style={styles.mainContainer}>
      <View style={global_styles.nextButtonContainer}>
        <TouchableOpacity
          style={buttonStyle}
          disabled={disabled}
          onPress={primaryAction}
        >
          <Text style={buttonTitleStyle}>{buttonTitle}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
