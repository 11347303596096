import { Image, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import styles from './styles'
import { i18n } from '$localization/config.js'

function ModalHeader() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.modalHeaderContainer(colorSettings)}>
      <View style={styles.modalHeader}>
        <View style={styles.modalHeaderTextContainer}>
          <Text style={styles.modalHeaderTitleText(colorSettings)}>
            {i18n.t(
              'please_review_our_terms_and_privacy_policy_carefully.default'
            )}
          </Text>
        </View>

        <View style={styles.modalHeaderIconContainer}>
          <Image
            source={require('$assets/images/new_assets/illusTerms.png')}
            style={styles.modalHeaderIcon}
          />
        </View>
      </View>
    </View>
  )
}

export default ModalHeader
