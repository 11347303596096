import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'

export const PERMISSION_STATUS = {
  GRANTED: 'granted',
  UNDETERMINED: 'undetermined',
  DENIED: 'denied',
}

export const requestPermission = async () => {
  const { status } = await Notifications.requestPermissionsAsync()

  return status
}

export const setNotificationChannel = () => {
  Notifications.setNotificationChannelAsync('default', {
    name: 'default',
    importance: Notifications.AndroidImportance.MAX,
  })
}

export const getNotificationsStatus = async () => {
  const isIos = Platform.OS === 'ios'

  if (isIos) {
    const { ios } = await Notifications.getPermissionsAsync()

    return ios.status
  }

  const { status } = await Notifications.getPermissionsAsync()

  return status
}
