import { View } from 'react-native'
import CustomizableButton from '$components/CustomizableButton'
import SubList from '../sub_list'

function ButtonWithListView({
  colorSettings,
  updateState,
  isToggled,
  buttonTitle,
  preventToggleEffect,
  data,
  onEdit,
}) {
  function ShowList({ currentData }) {
    if (isToggled && !preventToggleEffect) {
      return (
        <SubList
          colorSettings={colorSettings}
          currentData={currentData}
          onEdit={onEdit}
        />
      )
    }
    return <View />
  }

  return (
    <View style={{ width: '100%' }}>
      <CustomizableButton
        colorSettings={colorSettings}
        onPress={() => updateState()}
        title={buttonTitle}
        preventToggleEffect={preventToggleEffect}
        // currentState={val => currentState(val)}
        setToggle={isToggled}
      />
      <ShowList currentData={data} />
    </View>
  )
}

export default ButtonWithListView
