import { fixDiseaseName, getFamilySideAndRelationshipGender } from '$utils'
import { i18n } from '$localization/config.js'
import { excludedRelationships } from '../../FamilyListScreen/constants/excludedRelationships'

export const handleRelationshipName = (member) => {
  const relationshipToProband = getFamilySideAndRelationshipGender(
    member?.relationship_to_proband,
    member?.gender
  )

  const relationship = relationshipToProband[0]?.toLowerCase()
  const familySide = relationshipToProband[1]?.toLowerCase()
  const _greatCounter = relationshipToProband[2]

  let relationshipCount = relationship

  let relationshipName = `${
    i18n.t(fixDiseaseName(relationship)).default ?? relationship
  }`

  if (familySide && _greatCounter === 0)
    return `${i18n.t(fixDiseaseName(familySide))?.default} ${relationship}`

  if (_greatCounter) {
    if (
      relationshipCount.includes('Grandfather') ||
      relationshipCount.includes('Grandmother')
    ) {
      relationshipCount = relationshipCount.replace(/\d/g, '')
    }

    const greatCounter =
      _greatCounter > 0
        ? `${i18n.t('great.lowercase')} `.repeat(_greatCounter).trim()
        : ''

    relationshipName = `${greatCounter} ${relationshipCount}`

    if (familySide)
      relationshipName = `${
        i18n.t(fixDiseaseName(familySide))?.default
      } ${greatCounter} ${relationshipCount}`
  }

  return relationshipName
}

export const getSearchedFamilyMembersWithDiseaseOrGeneticTestingData = (
  object
) => {
  const result = []

  object.forEach((member) => {
    const currentMember = member
    if (
      currentMember?.is_blood_related_to_proband ||
      excludedRelationships.includes(currentMember?.is_blood_related_to_proband)
    )
      currentMember.children = []

    if (currentMember?.relationship_to_proband === 'partner')
      currentMember.partners = []

    const hasCurrentFamilyMemberChildren = currentMember.children?.length > 0
    const hasCurrentFamilyMemberPartners = currentMember.partners?.length > 0
    const hasMemberDiseasesOrGeneticTestingData =
      currentMember?.diseases?.length > 0 || currentMember?.genes?.length > 0

    if (hasMemberDiseasesOrGeneticTestingData) {
      result.push({ ...currentMember, partners: [] })
    }
    if (hasCurrentFamilyMemberPartners) {
      const correctPartners = currentMember.partners.filter(
        (partner) =>
          !excludedRelationships.some((rel) =>
            partner.relationship_to_proband.includes(rel)
          )
      )

      result.push(
        ...getSearchedFamilyMembersWithDiseaseOrGeneticTestingData(
          correctPartners
        )
      )
    }
    if (hasCurrentFamilyMemberChildren) {
      result.push(
        ...getSearchedFamilyMembersWithDiseaseOrGeneticTestingData(
          currentMember.children
        )
      )
    }
  })

  return result
}
