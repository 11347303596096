import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainContainer: (colorSettings) => ({
    flex: 1.0,
    backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
  }),
  flexContainer: (scrollViewHeight) => ({
    height: scrollViewHeight,
    justifyContent: 'flex-start',
  }),
  scrollViewContainer: {
    flex: 1.0,
  },
  container: {
    flex: 1.0,
  },
})

export default styles
