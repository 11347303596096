import { StyleSheet } from 'react-native'
import { IS_WEB } from '$constants/platforms'

const WHITE = 'white'
const TRANSPARENT = 'transparent'
const BLACK = 'black'
const RGBA_WHITE_HALF_OPACITY = 'rgba(255,255,255,0.5)'
const KARLA_BOLD = 'karla-bold'
const MONTSERRAT_MEDIUM = 'montserrat-medium'
const MONTSERRAT = 'montserrat'

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    flex: 1.0,
    backgroundColor: colorSettings?.onboarding_bgcolor || WHITE,
  }),
  content: {
    backgroundColor: TRANSPARENT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalContent: {
    flexDirection: 'row',
    height: '100%',
  },
  titleText: (colorSettings) => ({
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: colorSettings?.text_input_title_color_2 || BLACK,
    fontFamily: KARLA_BOLD,
    fontSize: IS_WEB() ? 24 : 22,
  }),
  roundedTileButton: {
    borderRadius: 20,
    borderWidth: 2.0,
    borderColor: RGBA_WHITE_HALF_OPACITY,
    alignItems: 'center',
    justifyContent: 'center',
  },
  roundedTileButtonText: (colorSettings) => ({
    textAlign: 'center',
    color: colorSettings?.selectable_btn_text_active_1 || BLACK,
    fontFamily: MONTSERRAT_MEDIUM,
    fontSize: 14,
  }),

  maleIcon: (colorSettings) => ({
    backgroundColor: colorSettings?.selectable_btn_text_active_1 || BLACK,
    marginTop: 8,
    marginBottom: 16,
    width: 40,
    height: 40,
  }),
  femaleIcon: (colorSettings) => ({
    backgroundColor: colorSettings?.selectable_btn_text_active_1 || BLACK,
    marginTop: 8,
    marginBottom: 16,
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
  }),
  linkedText: (colorSettings) => ({
    textAlign: 'center',
    color: colorSettings?.text_input_label_color_2 || BLACK,
    fontFamily: MONTSERRAT,
    fontSize: 14,
    textDecorationLine: 'underline',
  }),
  genderButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  genderContent: {
    marginHorizontal: 50,
    flex: 0.7,
  },
  genderContentContainer: {
    flex: 1.0,
  },
  genderDivider: {
    height: '100%',
    width: 10,
  },
  genderIdentityDivider: {
    height: 135,
  },
  genderIdentityText: {
    textAlign: 'center',
    fontSize: 18,
  },
  innerContentContainer: {
    flex: 1.0,
    width: '100%',
  },
  innerGenderContent: {
    height: 140,
  },
  innerTitleQuestion: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  keyboardAvoidingView: {
    paddingTop: 40,
    height: '100%',
  },
  outerContentContainer: {
    paddingHorizontal: 16,
  },
  outerTitleQuestion: {
    flex: 0.25,
    marginBottom: 25,
  },
  spacer: {
    flex: 0.1,
  },
  titleIconTextSpacer: {
    width: 15,
  },
})

export default styles
