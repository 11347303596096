import React from 'react'
import { View } from 'react-native'
import uuid from 'react-native-uuid'
import {
  MultiSelectField,
  SingleSelectField,
  NumberField,
  TextShort,
  YesOrNoField,
  LabelField,
  ToggleField,
  TextLong,
  PageContent,
} from '../components'
import { FIELD_TYPES } from '../constants.js'
import { getPageCategories, handleIsFieldShown } from './helpers.js'
import checkMasterQuestionPrerequisites from './masterQuestionPrerequisitesHandlers.js'
import checkDiseasePrerequisites from './diseasePrerequisitesHandlers.js'
import checkMemberPrerequisites from './memberPrerequisitesHandlers.js'

function handleRenderField(
  master_question,
  surveyObject,
  shouldCheckMasterPrerequisite = true
) {
  const {
    completed_survey_answers,
    deleteCompletedSurveyAnswer,
    storeFieldSurveyAnswers,
    submitSurveyResponse,
    colorSettings,
    deleteAnswer,
  } = surveyObject

  const currentMasterQuestionAnswers = completed_survey_answers?.filter(
    (answer) =>
      parseInt(answer.master_question_id, 10) ===
      parseInt(master_question.id, 10)
  )

  const prerequisitesChecksHandlers = [
    checkMemberPrerequisites,
    checkDiseasePrerequisites,
  ]

  if (shouldCheckMasterPrerequisite) {
    prerequisitesChecksHandlers.push(checkMasterQuestionPrerequisites)
  }

  const didPassAllPrerequisites = prerequisitesChecksHandlers.every(
    (prerequisiteChecksHandler) =>
      prerequisiteChecksHandler(master_question, surveyObject)
  )

  if (!didPassAllPrerequisites) {
    currentMasterQuestionAnswers.forEach((answer) => {
      deleteCompletedSurveyAnswer(answer, surveyObject)
    })
    return null
  }

  const fieldComponents = {
    [FIELD_TYPES.LABEL]: LabelField,
    [FIELD_TYPES.TEXT_SHORT]: TextShort,
    [FIELD_TYPES.TEXT_LONG]: TextLong,
    [FIELD_TYPES.INTEGER]: NumberField,
    [FIELD_TYPES.FLOAT]: NumberField,
    [FIELD_TYPES.YES_OR_NO]: YesOrNoField,
    [FIELD_TYPES.SELECT_ALL_THAT_APPLY]: MultiSelectField,
    [FIELD_TYPES.SELECT_ONE]: SingleSelectField,
    [FIELD_TYPES.PHONE]: View, // TODO: Replace with PhoneField when ready
    [FIELD_TYPES.LONG_LIST_SEARCH]: View, // TODO: Replace with LongListSearchField when ready
    [FIELD_TYPES.DATE]: View, // TODO: Replace with DateField when ready
    [FIELD_TYPES.TOGGLE]: ToggleField,
  }

  const FieldType = fieldComponents[master_question.type]

  if (!FieldType) return null
  const commonProps = {
    key: uuid.v4(),
    masterQuestion: master_question,
    colorSettings,
    storeFieldSurveyAnswers: (payload) => storeFieldSurveyAnswers(payload),
    submitSurveyResponse: (payload) => submitSurveyResponse(payload),
    surveyData: surveyObject,
    deleteAnswer,
  }

  const answerProps = {
    answer:
      currentMasterQuestionAnswers.length === 1
        ? currentMasterQuestionAnswers[0]
        : undefined,
    answers:
      master_question.type === FIELD_TYPES.SELECT_ALL_THAT_APPLY
        ? currentMasterQuestionAnswers
        : undefined,
  }

  const fieldProps = {
    [FIELD_TYPES.LABEL]: commonProps,
    [FIELD_TYPES.TEXT_SHORT]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.TEXT_LONG]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.INTEGER]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.FLOAT]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.YES_OR_NO]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.SELECT_ALL_THAT_APPLY]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.SELECT_ONE]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.PHONE]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.LONG_LIST_SEARCH]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.DATE]: { ...commonProps, ...answerProps },
    [FIELD_TYPES.TOGGLE]: { ...commonProps, ...answerProps },
  }

  return <FieldType {...fieldProps[master_question.type]} />
}

const rearrangeQuestionnaires = (questionItem) => {
  const questionnaires = [...questionItem]
  const removeItem = new Set()
  const questionnairesMap = new Map()

  questionItem.forEach((obj, index) => {
    questionnairesMap.set(obj.master_question_id, index)
  })

  questionItem.forEach((obj) => {
    const prerequisite = obj.master_question.question_prerequisites

    prerequisite.forEach((prerequisiteItem) => {
      const index = questionnairesMap.get(
        prerequisiteItem.master_question_prerequisite_id
      )
      if (index !== undefined) {
        questionnaires[index].master_question.child_questionnaire.push(obj)
        removeItem.add(prerequisiteItem.master_question_id)
      }
    })
  })

  const newQuestionnaire = questionnaires.filter(
    (obj) => !removeItem.has(obj.master_question_id)
  )

  return newQuestionnaire
}

function rearrangeWorkflow(questionWorkflows) {
  const addChildQuestionnaire = questionWorkflows.map((questionnaire) => ({
    ...questionnaire,
    master_question: {
      ...questionnaire.master_question,
      child_questionnaire: [],
    },
  }))

  const modifiedQuestionnaireData = rearrangeQuestionnaires(
    addChildQuestionnaire
  )

  return modifiedQuestionnaireData
}

function generateComponentDataPerSurveyCategory(
  category,
  surveyObject,
  shouldCheckMasterPrerequisite
) {
  let questionWorkflows = []

  if (category.question_workflows) {
    questionWorkflows = category.question_workflows.sort(
      (a, b) => a.order - b.order
    )
  } else {
    questionWorkflows = category.sort((a, b) => a.order - b.order)
  }

  const newWorkFlow = rearrangeWorkflow(questionWorkflows)

  const mappedWorkFlow = newWorkFlow
    .map((questionWorkflow) => {
      const isFieldShown = handleIsFieldShown(questionWorkflow)

      return isFieldShown
        ? handleRenderField(
            questionWorkflow.master_question,
            surveyObject,
            shouldCheckMasterPrerequisite
          )
        : null
    })
    .filter(Boolean)

  return mappedWorkFlow
}

function buildPageContent(pageCategories, surveyObject) {
  return pageCategories.flatMap((category) => {
    const componentDataPerSurveyCategory =
      generateComponentDataPerSurveyCategory(category, surveyObject)

    if (componentDataPerSurveyCategory.length > 0) {
      return PageContent(category, componentDataPerSurveyCategory)
    }
    return []
  })
}

function pageBuilder(surveyObject) {
  const survey = { ...surveyObject.survey }
  const pageCategories = getPageCategories(survey, surveyObject.current_page)
  const pageContent = buildPageContent(pageCategories, surveyObject)

  return pageContent
}

export default pageBuilder
export { generateComponentDataPerSurveyCategory }
