import { Text, TouchableOpacity, View } from 'react-native'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { IS_WEB } from '$utils/Platforms'
import Dropdown from '$components/Dropdown'
import { i18n } from '$localization/config'
import styles from './styles'
import { GENDER_IDENTITY_VALUES } from '$screens/main/family/edit_profile/constants'

function GenderIdentity({
  value,
  onChange,
  label,
  hasSeparator = true,
  bottomSheetGenderIdentityRef,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const [isDropdownShown, setIsDropdownShown] = useState(false)

  const availableGenderIdentityValues = Object.values(GENDER_IDENTITY_VALUES)

  const handleShowingChoice = () => {
    if (IS_WEB()) return setIsDropdownShown(!isDropdownShown)

    const isExpanded = bottomSheetGenderIdentityRef.current?.expand()

    if (!isExpanded) {
      return bottomSheetGenderIdentityRef.current?.expand()
    }

    return bottomSheetGenderIdentityRef.current?.close()
  }

  const onDropdownSelect = (genderIdentityValue) => {
    onChange(genderIdentityValue)
    setIsDropdownShown(false)
  }

  return (
    <View style={styles.genderIdentityContainer}>
      {hasSeparator && <View style={styles.genderIdentitySeparatorLine} />}

      <View style={styles.genderIdentityContentItem}>
        <View style={styles.genderIdentityLabelContainer}>
          <Text style={styles.genderIdentityLabelText(colorSettings)}>
            {label}
          </Text>
        </View>

        <View style={styles.genderIdentityInputContainer}>
          <TouchableOpacity
            onPress={handleShowingChoice}
            style={styles.genderIdentityInputButton}
          >
            <Text style={styles.genderIdentityInputText(value, colorSettings)}>
              {i18n.t(value || 'optional').default}
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      {IS_WEB() && (
        <Dropdown
          data={availableGenderIdentityValues}
          isShown={isDropdownShown}
          onPress={onDropdownSelect}
          selectedItem={value}
          right={0}
          top={55}
        />
      )}
    </View>
  )
}

export default GenderIdentity
