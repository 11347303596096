import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
} from 'react-native'

import AgeInput from '$screens/personal_details/personalScreenDiagnosis_components/ageInputComponent.js'
import OptionButton from '$screens/personal_details/personalScreenDiagnosis_components/optionButtonComponent.js'
import RenderMultipleQuestion from '$screens/personal_details/personalScreenDiagnosis_components/renderButtonQuestionComponent.js'
import xs from '$assets/styles/style'

import SkipLogic from '$data_models/SkipLogic.js'

import { i18n } from '$localization/config.js'

export default class SkipLogicScreen extends Component {
  edit = this.props.route.params?.edit ?? false

  selectButton = this.props.route.params?.selectButton ?? null

  diseaseData = this.props.route.params?.data ?? null

  mainScreen = this.props.route.params?.mainScreenData ?? null

  diseaseId = this.diseaseData.id

  newSkipList = this.props.route.params?.disease ?? new SkipLogic()

  constructor(props) {
    super(props)

    this.state = {
      selectedSkipLogic: [],
      diseaseAgeDiagnosed: 1,
      disabledNext: false,
    }
  }

  singleLayoutVal = {
    oneQuestion: false,
    value: '',
  }

  skipLogic = []

  didSelectSkipLogicButton = (id, answer) => {
    const selectedSkip = this.state.selectedSkipLogic

    for (const val in selectedSkip) {
      if (selectedSkip[val].id == id) {
        selectedSkip[val].answer = answer
        this.setState({ selectedSkip })
      }
    }
  }

  addToggleToSkipLogic = (skipLogic) => {
    const diseases = this.state.selectedSkipLogic
    const skip = skipLogic

    if (skipLogic.question_type == 'yes_or_no') {
      skip.answer = 'y'
      diseases.push(skip)
      this.setState({ diseases })
    } else {
      diseases.push(skipLogic)
      skip.answer = 1
      this.setState({ diseases })
    }
  }

  removeFromSelectedSkipLogic = (id) => {
    const diseases = this.state.selectedSkipLogic

    for (const val in diseases) {
      if (diseases[val].id == id) {
        diseases.splice(val, 1)
      }
    }
  }

  AgeDiagnosed = (age) => {
    this.setState({ diseaseAgeDiagnosed: age })
  }

  toggleAnswer = (answer, id) => {
    const skipLogic = this.state.selectedSkipLogic

    for (const val in skipLogic) {
      if ((skipLogic[val].id = id)) {
        skipLogic[val].answer = answer
        this.setState({ skipLogic })
      }
    }
  }

  addToSkip = () => {
    const skip = this.state.selectedSkipLogic
    const singleQuestion = this.singleLayoutVal

    if (singleQuestion.oneQuestion) {
      skip.push(singleQuestion.value)
      this.setState({ skip })
    }
  }

  removeToSkip = (id) => {
    const skip = this.state.selectedSkipLogic

    for (const val in skip) {
      if (skip[val]?.id == id) {
        skip.splice(val, 1)
      }
    }
  }

  componentWillMount() {
    const skipList = this.newSkipList.skip_logic_list
    const { selectedSkipLogic } = this.state
    // filter skipLogic by view_in_app
    const filter = this.diseaseData.skip_logistics
    for (const val in filter) {
      const txtFilter = filter[val].view_in_app

      if (txtFilter) {
        this.skipLogic.push(filter[val])
      }
    }

    if (this.edit) {
      for (const val in skipList) {
        if (skipList[val].disease_list_id == this.diseaseId) {
          selectedSkipLogic.push(skipList[val])
        }
      }
      this.setState({ selectedSkipLogic })
    }
  }

  renderQuestionType = () => {
    const filter = this.skipLogic
    const renderSkipLogic = []

    for (const val in filter) {
      const questionType = filter[val].question_type
      const { question } = filter[val]
      const { length } = filter
      const skipId = filter[val].id

      switch (length) {
        case 0:
          return ''
        case 1:
          return this.renderSingleQuestion(
            question,
            filter[val],
            questionType,
            skipId
          )
        default:
          renderSkipLogic.push(
            <RenderMultipleQuestion
              question={question}
              questionType={questionType}
              parent={this}
              skipDetails={filter[val]}
              toggleEdit={this.edit}
              skipId={skipId}
              skipLogicList={this.newSkipList}
            />
          )
      }
    }
    return renderSkipLogic
  }

  renderSingleQuestion = (question, details, questionType, skipId) => {
    this.singleLayoutVal.oneQuestion = true
    this.singleLayoutVal.value = details

    return (
      <View>
        <Text
          style={{
            fontFamily: 'karla-bold',
            fontSize: 24,
            color: '#fff',
            textAlign: 'center',
            marginBottom: 30,
          }}
        >
          {question}
        </Text>
        {this.renderQuestion(questionType, details, skipId, true)}
      </View>
    )
  }

  renderQuestion = (type, details, skipId, singleQuestion = false) => {
    if (singleQuestion) {
      if (type == 'yes_or_no_then_number') {
        return (
          <View style={{ alignItems: 'center' }}>
            <AgeInput
              subCategory
              skipDetails={details}
              parent={this}
              toggleEdit={this.edit}
              skipId={skipId}
            />
          </View>
        )
      }
      if (type == 'yes_or_no') {
        return (
          <View style={{ alignItems: 'center' }}>
            <OptionButton
              skipDetails={details}
              parent={this}
              toggleEdit={this.edit}
              skipId={skipId}
            />
          </View>
        )
      }
    } else if (type == 'yes_or_no_then_number') {
      return (
        <View style={{ alignItems: 'center' }}>
          <AgeInput
            subCategory
            skipDetails={details}
            parent={this}
            toggleEdit={this.edit}
            skipId={skipId}
          />
        </View>
      )
    }
  }

  disableNextButton = () => {
    this.setState({ disabledNext: true })
  }

  enableNextButton = () => {
    this.setState({ disabledNext: false })
  }

  titleSelectAll = () => {
    const { diseaseData } = this
    const mainQuestion = diseaseData.main_question
      ? diseaseData.main_question
      : 'none'

    switch (mainQuestion) {
      case 'none':
        return
      case 'Select all that apply':
        return (
          <Text style={styles.titleText}>
            {i18n.t('select_all_that_apply').default}
          </Text>
        )
      default:
        return (
          <Text style={[styles.titleText, { fontSize: 24 }]}>
            {mainQuestion}
          </Text>
        )
    }
  }

  storeDisease = () => {
    const passedDisease = this.newSkipList.diseases_list
    const disease = JSON.parse(JSON.stringify(this.diseaseData))
    disease.skip_logistics = []
    disease.age_diagnosed = this.state.diseaseAgeDiagnosed
    let dataStored = false

    // if exist
    for (const val in passedDisease) {
      if (passedDisease[val].id == this.diseaseId) {
        passedDisease.splice(val, 1)
        passedDisease.push(disease)
        dataStored = true
      }
    }

    if (!dataStored) {
      passedDisease.push(disease)
    }
  }

  nextButtonAction = () => {
    const { navigation } = this.props
    this.storeDisease()

    this.mainScreen.skipLogicList(this.state.selectedSkipLogic, this.diseaseId)
    this.mainScreen.accessSelectButton(this.diseaseId)
    this.mainScreen.addDiseaseToState()

    navigation.navigate('PersonalDetailsNavigator', {
      screen: 'PersonalDiagnosis',
      params: {
        ageDiagnosed: this.state.diseaseAgeDiagnosed,
        skipLogic: this.state.selectedSkipLogic,
      },
    })
  }

  render() {
    const diseaseName = this.diseaseData.disease_name

    return (
      <View style={xs.mainContainer}>
        {/* Progress Bar */}
        <View style={xs.headerProgressBarContainer}></View>
        <Text style={xs.progressText}>{i18n.t('x_out_of_x').default}</Text>

        {/* Content Body */}
        <ScrollView contentContainerStyle={xs.scrollView}>
          <View style={{ width: '100%', alignItems: 'center', marginTop: 40 }}>
            <AgeInput
              diseaseId={this.diseaseId}
              parent={this}
              mainAge
              toggleEdit={this.edit}
            />
          </View>

          {/* horizontal border */}
          <View style={[styles.horizontalBorder, { marginBottom: 15 }]} />

          {/* category title */}
          <View style={[xs.categoryTitleContainer, { marginBottom: 30 }]}>
            <Text style={[xs.categoryTitleTxt, { textAlign: 'center' }]}>
              {diseaseName}
            </Text>
          </View>

          {/* main title */}
          {this.titleSelectAll()}
          <View style={{ height: 30 }} />
          {this.renderQuestionType()}

          <View
            style={[
              styles.buttonContainerAdopted,
              {
                flexDirection: 'column',
                marginBottom: 10,
                justifyContent: 'center',
              },
            ]}
          />
        </ScrollView>

        {/* Next button */}
        <KeyboardAvoidingView behavior="padding">
          <View style={xs.nxtBtnContainer}>
            <TouchableOpacity
              disabled={this.state.disabledNext}
              style={[
                xs.nxtBtn,
                this.state.disabledNext
                  ? { backgroundColor: 'rgba(132,209,192,.5)' }
                  : {},
              ]}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  xs.nxtBtnText,
                  this.state.disabledNext
                    ? { color: 'rgba(255,255,255,.5)' }
                    : {},
                ]}
              >
                {i18n.t('done').default}
              </Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 18,
    marginBottom: 10,
    width: '100%',
  },
  buttonContainerAdopted: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 30,
  },
  controlButton: {
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#fff',
    height: 60,
    width: '100%',
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  inputField: {
    // backgroundColor: "black",
    width: 80,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
  },
  horizontalBorder: {
    width: '120%',
    borderBottomWidth: 0.7,
    borderBottomColor: 'rgba(255,255,255,.3)',
    marginTop: 32,
  },
})
