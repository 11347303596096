const DEFAULT_MEMBER = {}
const DEFAULT_FUNCTION = () => {}
const DEFAULT_RELATIONSHIP = ''
const DEFAULT_CUSTOM_STYLES = {}
const DEFAULT_TITLE_TEXT = ''
const DEFAULT_COLOR_SETTINGS = {}
const DEFAULT_COMPONENT = <></>
const DEFAULT_CHILDREN_VIEW = false

const PERSON_ADD_ICON_NAME = 'person-add'
const PENCIL_OUTLINE_ICON_NAME = 'pencil-outline'

const WIDTH_AND_HEIGHT_ADJUSTMENT = 42
const VIEW_BOX_ADJUSTMENT = '0 0 105 105'

export {
  DEFAULT_MEMBER,
  DEFAULT_FUNCTION,
  DEFAULT_RELATIONSHIP,
  DEFAULT_CUSTOM_STYLES,
  DEFAULT_TITLE_TEXT,
  DEFAULT_COLOR_SETTINGS,
  DEFAULT_COMPONENT,
  DEFAULT_CHILDREN_VIEW,
  PERSON_ADD_ICON_NAME,
  PENCIL_OUTLINE_ICON_NAME,
  WIDTH_AND_HEIGHT_ADJUSTMENT,
  VIEW_BOX_ADJUSTMENT,
}
