import { StyleSheet } from 'react-native'

const WHITE = 'rgba(245, 245, 245, 1)'
const GREEN = 'rgba(101, 209, 185, 1)'
const DARK = 'rgba(0, 0, 0, 0.6)'
const TRANSPARENT = 'transparent'

export default StyleSheet.create({
  container: (isDark) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isDark ? DARK : TRANSPARENT,
  }),
  loaderContainer: {
    backgroundColor: WHITE,
    borderColor: GREEN,
    borderWidth: 1.0,
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderMessageText: {
    fontFamily: 'karla',
    color: GREEN,
    textAlign: 'center',
    fontSize: 12,
  },
  animatedImage: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
  spacer: {
    height: 5,
  },
})
