import {
  KeyboardAvoidingView,
  LayoutAnimation,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  UIManager,
  View,
  findNodeHandle,
  NativeModules,
} from 'react-native'
import { createRef } from 'react'
import { connect } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'
import Alert from '$components/Alert'
import { IS_WEB, IS_MOBILE } from '$utils/Platforms.js'
import { platforms } from '$constants'

import { hasInputMask, getLanguage } from '$utils'
import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import {
  showDefaultAlert,
  showGenericError,
} from '$navigation/_utils/NavigationUtils.js'
import { setError } from '$redux/defaults/actions.js'

import AuthCredentials from '$data_models/AuthCredentials.js'
import BaseController from '$components/BaseController.js'
import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$components/LoadingView'
import MainWrapper from '$components/MainWrapper'
import MemberProfile from '$data_models/MemberProfile.js'
import NextButtonAccessoryView from '$components/NextButtonAccessoryView'
import CountryCodePhoneInput from '$components/CountryCodePhoneInput'
import Proband from '$data_models/Proband'
import custom_styles from '$screens/main/dashboard/invite/_styles/main.styles.js'
import { i18n } from '$localization/config.js'
import { setShouldReloadInviteStatus } from '$redux/member_profile/actions.js'
import styles from './_styles/inviteFamily.styles'
import { apiFetchHandler } from '$api'

if (Platform.OS === platforms.ANDROID) {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true)
  }
}

const customHeaderHeightDefault = 160
const headerHeightPadding = 10

class InviteFamilyMember extends BaseController {
  inputAccessoryViewID = 'InviteFamilyMember'

  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.currentProband ?? new Proband()

  memberProfile = this.props.memberProfile ?? new MemberProfile()

  currentMember = this.props.invitedMember ?? null

  email = ''

  phoneNumber = ''

  formattedPhoneNumber = ''

  numSet1 = ''

  numSet2 = ''

  numSet3 = ''

  initialHeaderMessage = [
    i18n.t(
      'to_make_sure_your_data_gets_to_the_right_person_we_will_send_them_an_email_with_the_invitation_and_a_text_message_with_their_unique_code'
    )?.default,
    i18n.t('please_enter_mobile_phone_and_email_address_below')?.default,
  ].join('\n\n')

  PHONE_NUMBER_HEADER_MESSAGE = i18n.t('mobile_number_of_member_to_invite')
    ?.default

  EMAIL_HEADER_MESSAGE = i18n.t('email_address_of_member_to_invite')?.default

  state = {
    ...this.state,
    countryPickerVisible: false,
    headerMessage: this.initialHeaderMessage,
    showHeaderContainer: true,
    activeTextInput: null,
    phoneNumber: null,
    formattedPhoneNumber: null,
    email: null,
    cca2: this.props.appSettings?.default_country_code,
    phonePrefix: this.props.appSettings?.default_dialing_code,
    scrollViewHeight: calculateAvailableContentHeight(
      customHeaderHeightDefault + headerHeightPadding
    ),
    customHeaderHeight: customHeaderHeightDefault,
  }

  constructor(props) {
    super(props)

    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  textInputRef = createRef()

  autoFocusTextInput = () => {
    if (!this.textInputRef.current) return

    const textInputNode = findNodeHandle(this.textInputRef.current)

    if (!textInputNode) return

    NativeModules.UIManager.measureInWindow(textInputNode, (height) => {
      height > 0 && this.textInputRef.current?.focus()
    })
  }

  componentDidMount() {
    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)

    this.toggleLoadingView(false)

    setTimeout(() => {
      this.autoFocusTextInput()
    }, 0)
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  submitButtonAction = async () => {
    this.setState({ showLoadingView: true })

    const probandID = this.proband?.probandID
    const memberID = this.currentMember?.member_id
    const headers = this.authCredentials
    const payload = {
      proband_id: probandID,
      member_id: memberID,
      dialing_code: this.state.phonePrefix,
      email: this.email,
      phone_number: this.phoneNumber,
      lang: getLanguage(),
    }

    await this.checkPhoneNumberAvailability(payload, headers)
  }

  checkPhoneNumberAvailability = async (payload, headers) => {
    /* Check if phone number already exists on database. */

    const phoneNumberAvailabilityPayload = {
      path: 'account/check_phone_number/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'InviteFamilyMember.js',
      },
    }

    const response = await apiFetchHandler(phoneNumberAvailabilityPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      const { status } = response

      if (status === 409) {
        return showDefaultAlert(
          i18n.t('phone_number_not_available')?.default,
          i18n.t(
            'you_have_entered_a_phone_number_that_is_already_in_use_by_another_account_please_use_a_different_one'
          )?.default
        )
      }
      return saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }

    await this.checkEmailAvailability(payload, headers)
  }

  checkEmailAvailability = async (payload, headers) => {
    /* Check if email address already exists on database. */

    const emailAvailabilityPayload = {
      path: 'account/check_email/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'InviteFamilyMember.js',
      },
    }

    const response = await apiFetchHandler(emailAvailabilityPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      if (response.status === 409) {
        return showDefaultAlert(
          i18n.t('email_not_available')?.default,
          i18n.t(
            'the_email_address_that_you_entered_is_already_being_used_by_another_user'
          )?.default
        )
      }
      return saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }

    await this.inviteMember(payload, headers)
  }

  inviteMember = async (payload, headers) => {
    /* Proceed on sending Family Invite. */
    const { _setShouldReloadInviteStatus_, navigation } = this.props

    const inviteMemberPayload = {
      path: 'invite/member/',
      method: 'post',
      body: payload,
      token: headers.accessToken,
      pageDetails: {
        page: 'InviteFamilyMember.js',
      },
    }

    const response = await apiFetchHandler(inviteMemberPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })

      return showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t(
          'there_was_an_error_sending_your_invitation_please_try_again_later'
        )?.default
      )
    }

    /* Reload current member's invite status upon dismiss */
    _setShouldReloadInviteStatus_(true)

    this.setState({ showLoadingView: false })

    Alert.alert(
      `${i18n.t('success')?.default}!`,
      i18n.t('your_invitation_has_been_sent')?.default,
      [
        {
          text: i18n.t('okay')?.default,
          style: 'default',
          onPress: () => {
            navigation.pop(2)
          },
        },
      ]
    )
  }

  onFocusTextInput = (textInputID = '') => {
    const headerMessage = this.getHeaderMessage()
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    this.setState({
      showHeaderContainer: false,
      headerMessage,
    })

    this.setCustomHeaderHeight()
  }

  onEndEditingTextInput = (textInputID = '') => {
    const headerMessage = this.getHeaderMessage()
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    this.setState({
      showHeaderContainer: true,
      headerMessage,
    })

    this.setCustomHeaderHeight()
  }

  getHeaderMessage = (activeTextInput = '') => {
    let message = this.initialHeaderMessage
    if (activeTextInput && activeTextInput.trim() !== '') {
      switch (activeTextInput) {
        case 'phone':
          message = this.PHONE_NUMBER_HEADER_MESSAGE
          break
        case 'email':
          message = this.EMAIL_HEADER_MESSAGE
          break
      }
    }
    return message
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    if (this.validateInput()) nextButtonEnabled = true
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    const { colorSettings } = this.props

    const style = {
      backgroundColor: colorSettings?.bottom_next_btn_disabled,
      borderColor: colorSettings?.bottom_next_btn_disabled,
    }

    if (this.validateInput()) {
      style.backgroundColor = colorSettings?.bottom_next_btn_enabled
      style.borderColor = colorSettings?.bottom_next_btn_disabled
    }

    return style
  }

  toggleNextButtonText = () => {
    const { colorSettings } = this.props

    let style = colorSettings?.bottom_next_btn_text_disabled ?? 'black'

    if (this.validateInput()) {
      style = colorSettings?.bottom_next_btn_text_enabled ?? 'black'
    }

    return style
  }

  validateInput = () => {
    let emailIsValid = false
    let phoneNumberIsValid = false
    const validator = require('email-validator')

    if (validator.validate(this.email)) {
      emailIsValid = true
    }

    if (this.phoneNumber && this.phoneNumber.length >= 5) {
      phoneNumberIsValid = true
    }

    return emailIsValid && phoneNumberIsValid
  }

  phoneNumberDidChanged = (value) => {
    this.phoneNumber = value.replace(/[^0-9]/gi, '')

    const len = this.phoneNumber.length
    this.formattedPhoneNumber = this.phoneNumber

    if (!hasInputMask(this.state.cca2)) {
      this.setState({
        formattedPhoneNumber: this.formattedPhoneNumber,
      })
      return
    }

    if (len <= 10) {
      this.numSet1 = this.phoneNumber.substring(0, 3).trim()
      this.numSet2 = this.phoneNumber.substring(3, 6).trim()
      this.numSet3 = this.phoneNumber.substring(6).trim()
    }

    if (len === 11) {
      this.numSet1 = this.phoneNumber.substring(0, 3).trim()
      this.numSet2 = this.phoneNumber.substring(3, 7).trim()
      this.numSet3 = this.phoneNumber.substring(7).trim()
    }

    if (len === 12) {
      this.numSet1 = this.phoneNumber.substring(0, 4).trim()
      this.numSet2 = this.phoneNumber.substring(4, 8).trim()
      this.numSet3 = this.phoneNumber.substring(8).trim()
    }

    this.formattedPhoneNumber = this.numSet1

    if (len > 3) {
      this.formattedPhoneNumber = `(${this.numSet1})` + ` ${this.numSet2}`
    }
    if (len > 6) {
      this.formattedPhoneNumber =
        `(${this.numSet1})` + ` ${this.numSet2} ${this.numSet3}`
    }

    this.setState({
      formattedPhoneNumber: this.formattedPhoneNumber,
    })
  }

  onPressFlag = () => {
    this.toggleCountryPicker(true)
  }

  toggleCountryPicker = (visible = false) => {
    this.setState({ countryPickerVisible: visible })
  }

  countryPickerOnSelect = (country) => {
    const _cca2 = country.cca2.toLowerCase()
    this.phone.selectCountry(_cca2)
    this.setState(
      {
        cca2: _cca2,
        phonePrefix: country.callingCode[0],
      },
      function () {
        this.phoneNumberDidChanged(this.phoneNumber)
      }
    )
  }

  setCustomHeaderHeight = (showFullHeight = true) => {
    const height = customHeaderHeightDefault
    this.setState({
      customHeaderHeight: showFullHeight ? height : height / 2.67,
    })
  }

  render() {
    const { navigation, colorSettings } = this.props

    const setContainerStyleByPlatform = () => {
      switch (Platform.OS) {
        case platforms.WEB:
          return {
            flex: null,
            height: calculateAvailableContentHeight(
              this.state.customHeaderHeight + 10
            ),
          }
        default:
          return {
            flex: 6,
          }
      }
    }

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <KeyboardAvoidingView
            style={custom_styles.container}
            behavior="height"
          >
            <View
              style={[
                styles.headerContainer,
                {
                  backgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
                  ...Platform.select({
                    [platforms.WEB]: {
                      height: this.state.customHeaderHeight,
                    },
                  }),
                },
              ]}
            >
              <Text
                style={[
                  styles.headerText,
                  {
                    color: colorSettings?.nav_title_light,
                  },
                ]}
              >
                {this.state.headerMessage}
              </Text>
            </View>
            <ScrollView
              style={[
                custom_styles.familyInviteTextInputContainer,
                setContainerStyleByPlatform(),
                styles.scrollView,
              ]}
              contentContainerStyle={styles.scrollViewContentContainer}
              bounces={false}
            >
              <View>
                <Text style={styles.inputLabelText}>
                  {i18n.t('mobile_number')?.default}
                </Text>

                <View style={styles.textInputContainer}>
                  <View
                    style={[
                      styles.textInputContent,
                      styles.phoneInputViewOuterContainer,
                    ]}
                  >
                    <View style={styles.phoneInputViewContainer}>
                      <CountryCodePhoneInput
                        sender={this}
                        colorSettings={colorSettings}
                        initialCountryCode={this.state.cca2}
                        countryPickerVisible={this.state.countryPickerVisible}
                      />
                    </View>

                    <View
                      style={[
                        styles.textInputContent,
                        styles.textInputInnerContainer,
                      ]}
                    >
                      <TextInput
                        ref={this.textInputRef}
                        nativeID="web_text_input"
                        style={[
                          styles.textInput,
                          {
                            color:
                              colorSettings?.text_input_color_1 ??
                              'rgba(74, 74, 74, 1)',
                            textAlign: 'center',
                          },
                        ]}
                        inputAccessoryViewID={this.inputAccessoryViewID}
                        maxLength={20}
                        selectionColor="black"
                        keyboardType={IS_MOBILE() ? 'phone-pad' : 'default'}
                        placeholderTextColor={
                          colorSettings?.text_input_placeholder_color_1 ??
                          'rgba(74, 74, 74, 0.5)'
                        }
                        placeholder={
                          hasInputMask(this.state.cca2)
                            ? '(000) 000 0000'
                            : i18n.t('enter_number')?.default
                        }
                        clearButtonMode="while-editing"
                        onFocus={() => this.onFocusTextInput('phone')}
                        onEndEditing={() => this.onEndEditingTextInput('phone')}
                        onBlur={() => this.onEndEditingTextInput('phone')}
                        onChangeText={this.phoneNumberDidChanged}
                        value={this.state.formattedPhoneNumber}
                      />
                    </View>
                  </View>

                  <View style={styles.textInputline} />
                </View>
              </View>
              <View>
                <Text style={styles.inputLabelText}>
                  {i18n.t('email')?.default}
                </Text>

                <View style={styles.textInputContainer}>
                  <View
                    style={[
                      styles.textInputContent,
                      styles.textInputInnerContainer,
                    ]}
                  >
                    <TextInput
                      nativeID="web_text_input"
                      style={[
                        styles.textInput,
                        {
                          color:
                            colorSettings?.text_input_color_1 ??
                            'rgba(74, 74, 74, 1)',
                          textAlign: IS_WEB() ? 'center' : 'left',
                        },
                      ]}
                      inputAccessoryViewID={this.inputAccessoryViewID}
                      maxLength={100}
                      placeholder={i18n.t('enter_email_address')?.title}
                      placeholderTextColor={
                        colorSettings?.text_input_placeholder_color_1 ??
                        'rgba(74, 74, 74, 0.5)'
                      }
                      selectionColor="black"
                      autoCapitalize="none"
                      autoComplete="off"
                      autoCorrect={false}
                      clearButtonMode="while-editing"
                      keyboardType={IS_MOBILE() ? 'email-address' : 'default'}
                      onFocus={() => this.onFocusTextInput('email')}
                      onEndEditing={() => this.onEndEditingTextInput('email')}
                      onBlur={() => this.onEndEditingTextInput('email')}
                      onChangeText={(value) => {
                        this.email = value
                        this.setState({ email: value })
                      }}
                      value={this.state.email}
                    />
                  </View>

                  <View style={styles.textInputline} />
                </View>
              </View>
            </ScrollView>
            <View
              style={[
                global_styles.nextButtonContainer,
                styles.buttonContainer,
              ]}
            >
              <TouchableOpacity
                style={[
                  global_styles.nextButton,
                  {
                    backgroundColor: colorSettings?.bottom_next_btn_enabled,
                  },
                  this.toggleNextButtonColor(),
                ]}
                disabled={this.toggleNextButton()}
                onPress={() => this.submitButtonAction()}
              >
                <Text
                  style={[
                    global_styles.nextButtonText,
                    {
                      color: this.toggleNextButtonText(),
                    },
                  ]}
                >
                  {i18n.t('submit')?.default}
                </Text>
              </TouchableOpacity>
            </View>

            <NextButtonAccessoryView
              style={[global_styles.nextButton, this.toggleNextButtonColor()]}
              disabled={this.toggleNextButton()}
              backgroundColor="white"
              labelText={i18n.t('submit')?.default}
              labelColor={this.toggleNextButtonText()}
              nativeID={this.inputAccessoryViewID}
              onPress={() => this.submitButtonAction()}
            />

            <LoadingView
              backgroundColor={colorSettings?.splash_bgcolor}
              tintColor={colorSettings?.btn_no_fill_border_1}
              textColor={colorSettings?.btn_no_fill_text_1}
              visible={this.state.showLoadingView}
              message={i18n.t('please_wait')?.default}
            />
          </KeyboardAvoidingView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    currentProband: probandStore.proband,
    /** MemberProfile Store */
    memberProfile: memberProfileStore.memberProfile,
    invitedMember: memberProfileStore.invitedMember,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    /** Refactored actions */
    _setShouldReloadInviteStatus_: (data) =>
      dispatch(setShouldReloadInviteStatus(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteFamilyMember)
