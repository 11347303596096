import { Component } from 'react'
import { View, Text, TouchableOpacity, Modal } from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import DarkGraySwitch from '$components/CustomSwitches/DarkGraySwitch'

import helper_styles from './_styles/helper.styles'

import { IS_WEB, IS_MOBILE } from '$utils/Platforms.js'

class HelperModal extends Component {
  parentController = this.props.parentController

  colorSettings = this.props.colorSettings

  bulletedTextList = [
    i18n.t(
      'each_family_member_you_invite_will_be_sent_a_unique_code_that_can_only_be_used_by_them_to_sign_up_as_a_part_of_your_family'
    )?.default,
    i18n.t(
      'by_inviting_them_you_are_sending_them_a_copy_of_your_personal_health_and_family_information_so_they_dont_have_to_re-enter_it'
    )?.default,
    i18n.t(
      'they_will_also_receive_updates_you_make_unless_you_change_this_in_sharing_settings_later'
    )?.default,
  ]

  state = {
    visible: this.props.visible,
    preventVisible: this.props.preventVisible,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      visible: newProps.visible,
      preventVisible: newProps.preventVisible,
    }
  }

  toggleShouldShowHelperModal = (value) => {
    if (!this.parentController) return
    this.parentController?.toggleShouldShowHelperModal(value)
  }

  toggleHelperModalVisible = (value) => {
    if (!this.parentController) return
    this.parentController?.toggleHelperModalVisible(value)
  }

  handleTextColor = () => {
    const textColor =
      this.props.colorSettings?.modal_top_title_bar_text ?? 'black'
    return { color: textColor }
  }

  handlerBorderColor = () => {
    const borderColor =
      this.props.colorSettings?.modal_top_title_bar_bg ?? 'white'
    return { borderBottomColor: borderColor }
  }

  render() {
    const renderBulletedTexts = () => {
      const layout = []

      for (const i in this.bulletedTextList) {
        const message = this.bulletedTextList[i]
        layout.push(
          <View style={{ marginBottom: 16 }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={helper_styles.contentBulletPointsContainer}>
                <View style={helper_styles.contentBulletPoints} />
              </View>
              <Text style={helper_styles.contentBulletedText}>{message}</Text>
            </View>
          </View>
        )
      }
      return layout
    }

    /* Set default content width for Web */
    let contentStyle = {}
    if (IS_WEB()) {
      contentStyle = {
        width: 375,
      }
    }

    /* Set default item alignment for Mobile */
    let switchContainerStyle = {}
    if (IS_MOBILE()) {
      switchContainerStyle = {
        alignItems: 'center',
      }
    }

    return (
      <Modal animationType="fade" visible={this.state.visible} transparent>
        <View style={helper_styles.container}>
          <View style={[helper_styles.content, contentStyle]}>
            <View
              style={[
                helper_styles.contentHeaderContainer,
                {
                  backgroundColor:
                    this.colorSettings?.modal_top_title_bar_bg || 'white',
                },
                this.handlerBorderColor(),
              ]}
            >
              <Text
                style={[
                  helper_styles.contentHeaderTitle,
                  this.handleTextColor(),
                ]}
              >
                {i18n.t('invite_family_member')?.default}
              </Text>
            </View>

            <View style={helper_styles.contentBodyContainer}>
              <View style={{ marginBottom: 16 }}>
                <Text style={helper_styles.contentHelperText}>
                  {
                    i18n.t(
                      'make_your_record_more_accurate_by_sharing_with_family_members_and_updating_each_other_with_changes'
                    )?.default
                  }
                </Text>
              </View>

              <View style={{ marginLeft: 8, marginRight: 16 }}>
                {renderBulletedTexts()}
              </View>

              <View
                style={[
                  helper_styles.switchableFieldContainer,
                  switchContainerStyle,
                ]}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 1.0,
                    height: '100%',
                  }}
                >
                  <View
                    style={{
                      flex: 0.8,
                      height: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={helper_styles.switchableFieldLabelText}>
                      {i18n.t('do_not_show_this_message_again')?.default}
                    </Text>
                  </View>

                  <View style={[helper_styles.switchableField, { flex: 0.2 }]}>
                    <View style={{ height: '100%', width: '100%' }}>
                      <DarkGraySwitch
                        onValueChange={(value) =>
                          this.toggleShouldShowHelperModal(value)
                        }
                        value={this.state.preventVisible}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={helper_styles.contentFooterContainer}>
              <TouchableOpacity
                style={helper_styles.footerButton}
                onPress={() => this.toggleHelperModalVisible(false)}
              >
                <Text
                  style={[
                    helper_styles.footerButtonText,
                    {
                      color: this.colorSettings?.linked_text,
                    },
                  ]}
                >
                  {i18n.t('okay')?.default}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(HelperModal)
