import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  webView: {
    flex: 1.0,
    width: '100%',
  },
  background: (colorSettings) => ({
    backgroundColor: colorSettings?.post_onboarding_bgcolor,
  }),
})

export default styles
