import {
  RefreshControl,
  ScrollView,
  SectionList,
  Text,
  View,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../styles'
import NotificationItem from '../NotificationItem'
import { i18n } from '$localization/config.js'
import { setError, setShowLoadingView } from '$redux/defaults/actions'
import {
  fetchArchiveNotifications,
  fetchRespondToNotification,
} from '../../fetchHandlers'

const NOTIFICATION_RESPONSE_TYPE = {
  ACCEPT: 'accept',
  DENY: 'deny',
}
const WEB_SCALED_CONTAINER_NATIVE_ID = 'web_scaled_main_container'
function ListContainer({
  listData,
  handleChangeNotification,
  getNotificationList,
}) {
  const dispatch = useDispatch()

  const {
    colorSettings,
    showLoadingView: isShowLoadingView,
    account,
    proband: { probandID = null },
    authCredentials: { accessToken = '' },
  } = useSelector((state) => ({
    colorSettings: state.store.colorSettings,
    account: state.accountStore.account,
    authCredentials: state.accountStore.authCredentials,
    showLoadingView: state.store.showLoadingView,
    proband: state.probandStore.proband,
  }))

  const saveError = (err) => dispatch(setError(err))
  const showLoadingView = (data) => dispatch(setShowLoadingView(data))

  const isListFilled = listData?.[0]?.data?.length > 0

  const setNotificationToArchive = async (notificationItem) => {
    const notificationID = notificationItem?.id

    if (!notificationID) return

    handleChangeNotification(notificationID)
    showLoadingView(true)
    try {
      const response = await fetchArchiveNotifications(
        notificationID,
        accessToken,
        probandID
      )

      if (response.isError) {
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }
    } catch (err) {
      console.log(
        '🚀 \n\n file: NotificationScreen -> ListContainer \n\n setNotificationToArchive:',
        err
      )
    } finally {
      showLoadingView(false)
    }
  }

  const respondToNotification = async (itemID, payload) => {
    handleChangeNotification(itemID)
    showLoadingView(true)

    try {
      const response = await fetchRespondToNotification(
        itemID,
        accessToken,
        payload
      )

      if (response.isError) {
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }
    } catch (err) {
      console.log(
        '🚀 \n\n file: NotificationScreen -> ListContainer \n\n respondToNotification:',
        err
      )
    } finally {
      showLoadingView(false)
    }
  }

  const respondButtonAction = async (item, responseStatus) => {
    if (!item?.id) return

    const itemID = item?.id
    const payload = {
      proband_id: probandID,
      user_id: account?.userID,
      response: responseStatus,
    }

    return respondToNotification(itemID, payload)
  }

  if (isListFilled) {
    return (
      <ScrollView
        nativeID={WEB_SCALED_CONTAINER_NATIVE_ID}
        style={styles.container}
        contentContainerStyle={styles.scrollViewRenderListContainer2}
        refreshControl={
          <RefreshControl
            refreshing={isShowLoadingView}
            onRefresh={getNotificationList}
          />
        }
      >
        <View style={styles.content}>
          <SectionList
            sections={listData}
            keyExtractor={(item) => `${item?.id}`}
            renderItem={({ item }) => (
              <NotificationItem
                colorSettings={colorSettings}
                onDismiss={() => setNotificationToArchive(item)}
                onAccept={() =>
                  respondButtonAction(item, NOTIFICATION_RESPONSE_TYPE.ACCEPT)
                }
                onDeny={() =>
                  respondButtonAction(item, NOTIFICATION_RESPONSE_TYPE.DENY)
                }
                item={item}
              />
            )}
          />
        </View>
      </ScrollView>
    )
  }

  return (
    <ScrollView
      style={styles.content}
      contentContainerStyle={styles.scrollViewRenderListContainer}
      refreshControl={
        <RefreshControl
          refreshing={isShowLoadingView}
          onRefresh={getNotificationList}
        />
      }
    >
      <Text style={[styles.headerTitleText, styles.listDataHeaderContainer]}>
        {i18n.t('no_notifications_found')?.default}
      </Text>
    </ScrollView>
  )
}

export default ListContainer
