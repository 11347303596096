import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { connect } from 'react-redux'
import { setError } from '$redux/defaults/actions.js'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import NextButtonAccessoryView from '$components/NextButtonAccessoryView'
import LoadingView from '$components/LoadingView'

import { i18n } from '$localization/config.js'
import { IS_WEB } from '$utils/Platforms.js'

import { apiFetchHandler } from '$api'

import {
  showDefaultAlert,
  showGenericServerError,
} from '$navigation/_utils/NavigationUtils.js'

import {
  storeCurrentMember,
  clearCurrentMember,
} from '$redux/member_profile/actions.js'

import global_styles, {
  DefaultFullHeight,
} from '$assets/styles/global.styles.js'

const inputAccessoryViewID = 'SignupFamilyInviteCode'

const CHARACTER_SPACING = Array(3).join(' ')
const SKIP = 'skip'
const NEXT = 'next'

const getScrollViewHeight = () => DefaultFullHeight()

class SignupFamilyInviteCode extends React.Component {
  mainTextInput = null

  nextButtonTitle = SKIP

  state = {
    currentMember: this.props?.currentMember,
    showLoadingView: false,
    proceedToVerification: false,
    mainTextValue: '',
    mainTextValueLocked: '',
    bottomButtonHeight: 0,
    scrollViewHeight: getScrollViewHeight(),
  }

  componentDidMount() {
    const { _clearCurrentMember_ } = this.props

    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    /* Clear initial current member data on Redux store */
    _clearCurrentMember_()
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    this.setState({ scrollViewHeight: getScrollViewHeight() })
  }

  verifyFamilyCode = async () => {
    /* Prepare API request to verify Family Invitation code. */

    const familyCode = this.getFormattedValue(this.state.mainTextValueLocked)

    let langua = i18n.locale
    langua = langua.substr(0, 2)
    const payload = {
      family_code: familyCode,
      lang: langua,
    }

    this.setState({ showLoadingView: true })

    const verifyFamilyCodePayload = {
      path: 'invite/family_code/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SignupFamilyInviteCode.js',
      },
    }

    const response = await apiFetchHandler(verifyFamilyCodePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      const status = response?.status
      switch (status) {
        case 500:
          showGenericServerError()
          break

        case 404:
          showDefaultAlert(
            i18n.t('oops')?.default,
            i18n.t(
              'the_code_you_entered_is_not_valid_please_try_again_or_ask_the_family_member_who_invited_you_to_send_you_a_new_invite'
            )?.default
          )
          break

        default:
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          break
      }
      return
    }

    this.setState({ showLoadingView: false })

    const currentMember = {
      member_id: response?.member_id,
      is_invited: true,
    }

    /* Save member ID to redux store */
    this.props._storeCurrentMember_(currentMember)

    /* Proceed to next screen */
    this.navigateToPhoneCode()
  }

  navigateToPhoneCode = () => {
    const { navigation, colorSettings } = this.props

    navigation.navigate('SignupFamilyInviteNavigator', {
      screen: 'SignupFamilyInvitePhoneCode',
    })
  }

  toggleNextButton = () => {
    const isValid = this.validateInput()
    return !isValid
  }

  toggleNextButtonColor = () => {
    const { colorSettings } = this.props

    let style = {
      backgroundColor: colorSettings?.btn_filled_disabled_1 ?? 'white',
      borderColor: colorSettings?.btn_filled_disabled_1,
    }

    if (this.validateInput()) {
      style = {
        backgroundColor: colorSettings?.btn_filled_enabled_1 ?? 'white',
        borderColor: colorSettings?.btn_filled_enabled_1,
      }
    }

    return style
  }

  nextButtonAction = async () => {
    /* Dismiss main text input */
    const { navigation, colorSettings } = this.props

    this.mainTextInput?.blur()

    if (this.state?.proceedToVerification) {
      await this.verifyFamilyCode()
      return
    }

    navigation?.navigate('SignupMainScreen')
  }

  validateInput = () => {
    const textValue = this.state?.mainTextValueLocked ?? ''
    const newVal = this.getFormattedValue(textValue)

    /* If no value, return true, to allow 'SKIP' */
    if (newVal?.length == 0) return true

    return newVal?.length >= 4
  }

  getFormattedValue = (textValue) => {
    const val = textValue?.replace(/ /g, '')?.split('') ?? ''
    const newVal = val?.join('') ?? ''
    return newVal ?? ''
  }

  onChangeMainTextInput = (e) => {
    const val = e?.replace(/ /g, '')?.split('') ?? ''
    const newVal = val?.join(CHARACTER_SPACING) ?? ''

    /* Update next button title */
    this.nextButtonTitle = val?.length == 0 ? SKIP : NEXT

    if (val?.length <= 10) {
      this.setState({
        proceedToVerification: true,
        mainTextValue: newVal,
        mainTextValueLocked: newVal,
      })
    }

    /* If no value, disable verification mode */
    /* Should always be at the bottom */
    if (val?.length == 0) {
      this.setState({ proceedToVerification: false })
    }
  }

  getBottomButtonLayout = ({ nativeEvent }) => {
    // this.setState({ bottomButtonHeight: nativeEvent.layout.height -40})
  }

  render() {
    const { colorSettings } = this.props
    const nextButtonTitle =
      i18n.t(this.nextButtonTitle)?.default ?? this.nextButtonTitle

    const textValue = this.state?.mainTextValueLocked

    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            global_styles.container,
            {
              backgroundColor: colorSettings?.onboarding_bgcolor,
            },
          ]}
        >
          <KeyboardAwareScrollView
            contentContainerStyle={[
              global_styles.contentContainer,
              {
                height: this.state?.scrollViewHeight,
              },
            ]}
          >
            <View
              style={[
                global_styles.content,
                {
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 0.9,
                },
              ]}
            >
              <Text
                style={[
                  global_styles.titleText,
                  { color: colorSettings?.text_input_label_color_2 },
                ]}
              >
                {`${
                  i18n.t('did_you_receive_an_email_invite_from_a_family_member')
                    ?.default ?? ''
                }\n`}
                {`${i18n.t('if_so_enter_the_code_below')?.default ?? ''}\n`}
                {`${i18n.t('otherwise_skip_this_step')?.default ?? ''}`}
              </Text>

              <View style={{ marginVertical: 30 }}>
                <TextInput
                  nativeID="text_input_web"
                  style={[
                    global_styles.mainTextInput,
                    {
                      color: colorSettings?.text_input_color_2 ?? 'black',
                      fontSize: 21,
                      marginHorizontal: 16,
                      height: 50,
                    },
                  ]}
                  ref={(ref) => (this.mainTextInput = ref)}
                  inputAccessoryViewID={inputAccessoryViewID}
                  selectionColor={colorSettings?.text_input_color_2}
                  placeholder={i18n.t('enter_family_code')?.default ?? ''}
                  placeholderTextColor={
                    colorSettings?.text_input_placeholder_color_2 ??
                    'rgba(74,74,74,0.5)'
                  }
                  autoCorrect={false}
                  maxLength={28}
                  onChange={({ nativeEvent: { text } }) =>
                    this.onChangeMainTextInput(text)
                  }
                  value={textValue}
                  contextMenuHidden={false}
                  caretHidden={false}
                />

                <View
                  style={{
                    borderBottomColor:
                      colorSettings?.text_input_placeholder_color_2,
                    borderBottomWidth: 1,
                    minWidth: 300,
                    alignSelf: 'center',
                  }}
                />
              </View>
            </View>

            <View style={{ flex: 0.1 }} />
          </KeyboardAwareScrollView>

          <View
            onLayout={(event) => this.getBottomButtonLayout(event)}
            style={global_styles.nextButtonContainer}
          >
            <TouchableOpacity
              style={[global_styles.nextButton, this.toggleNextButtonColor()]}
              disabled={this.toggleNextButton()}
              onPress={() => this.nextButtonAction()}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: colorSettings?.btn_filled_text_1,
                  },
                ]}
              >
                {nextButtonTitle}
              </Text>
            </TouchableOpacity>
          </View>

          <NextButtonAccessoryView
            nativeID={inputAccessoryViewID}
            style={[global_styles.nextButton, this.toggleNextButtonColor()]}
            disabled={this.toggleNextButton()}
            backgroundColor={colorSettings?.onboarding_bgcolor}
            labelColor={colorSettings?.btn_filled_text_1}
            labelText={nextButtonTitle}
            onPress={() => this.nextButtonAction()}
          />

          {this.state?.showLoadingView && (
            <LoadingView
              backgroundColor="white"
              tintColor={colorSettings?.btn_no_fill_border_1}
              textColor={colorSettings?.btn_no_fill_text_1}
              visible={this.state?.showLoadingView}
            />
          )}
        </SafeAreaView>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const { store, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Proband Store */
    currentMember: memberProfileStore.currentMember,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _storeCurrentMember_: (data) => dispatch(storeCurrentMember(data)),
  _clearCurrentMember_: () => dispatch(clearCurrentMember()),
  saveError: (data) => dispatch(setError(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupFamilyInviteCode)
