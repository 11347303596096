/* eslint-disable camelcase */

function generateSelectedAnswersArray(
  answersForPrerequisiteQuestion,
  masterQuestionPrerequisiteId,
  surveyObject
) {
  const { survey } = surveyObject

  const questionIdToWorkflowMap = survey.survey_categories
    .flatMap((category) => category.question_workflows)
    .reduce(
      (map, question_workflow) =>
        map.set(question_workflow.master_question.id, question_workflow),
      new Map()
    )

  const prerequisiteQuestionWorkflow = questionIdToWorkflowMap.get(
    masterQuestionPrerequisiteId
  )

  if (!prerequisiteQuestionWorkflow) return []

  const choiceIdToChoiceMap =
    prerequisiteQuestionWorkflow.master_question.master_question_choices.reduce(
      (map, choice) => map.set(parseInt(choice.id, 10), choice),
      new Map()
    )

  const answersWithChoiceId = answersForPrerequisiteQuestion.filter(
    (prerequisite_answer) => prerequisite_answer.master_question_choice_id
  )

  const selectedChoices = answersWithChoiceId.map((prerequisite_answer) => {
    const master_question_choice_id = parseInt(
      prerequisite_answer.master_question_choice_id,
      10
    )
    return choiceIdToChoiceMap.get(master_question_choice_id)
  })

  const validChoices = selectedChoices.filter(Boolean)

  const selectedChoiceValues = validChoices.map((choice) => choice.value)

  return selectedChoiceValues
}

function getAnswer(prerequisiteAnswer, survey, masterQuestionPrerequisiteId) {
  const questionWorkflow = survey.survey_categories.find((category) =>
    category.question_workflows.some(
      (workflow) =>
        parseInt(workflow.master_question.id, 10) ===
        masterQuestionPrerequisiteId
    )
  )

  if (!questionWorkflow) {
    return null
  }

  const prerequisiteQuestionWorkflow = questionWorkflow.question_workflows.find(
    (workflow) =>
      parseInt(workflow.master_question.id, 10) === masterQuestionPrerequisiteId
  )

  const hasChoiceId = Boolean(prerequisiteAnswer.master_question_choice_id)

  if (hasChoiceId) {
    const choiceId = parseInt(prerequisiteAnswer.master_question_choice_id, 10)
    const selectedChoice =
      prerequisiteQuestionWorkflow.master_question.master_question_choices.find(
        (choice) => parseInt(choice.id, 10) === choiceId
      )

    return selectedChoice ? selectedChoice.value : prerequisiteAnswer.answer
  }

  return prerequisiteAnswer.answer
}

function checkPrerequisiteWithOperator(prerequisite, answer) {
  const operators = {
    '==': (a, b) => a === b,
    '!=': (a, b) => a !== b,
    '>': (a, b) => a > b,
    '>=': (a, b) => a >= b,
    '<': (a, b) => a < b,
    '<=': (a, b) => a <= b,
    IN: (a, b) => b.includes(a),
    'NOT IN': (a, b) => !b.includes(a),
  }

  const operation = operators[prerequisite.operator]

  if (!operation) {
    return false
  }

  const isPrerequisiteOperatorValid = ['IN', 'NOT IN'].includes(
    prerequisite.operator
  )
  const isPrerequisiteValueString = typeof prerequisite.value === 'string'
  const isAnswerArray = Array.isArray(answer)

  const isValidPrerequisite =
    isPrerequisiteOperatorValid && isPrerequisiteValueString && isAnswerArray

  if (isValidPrerequisite) {
    const valuesArray = prerequisite.value.split(',')

    const isAnyAnswerValid = answer.some((ans) => operation(ans, valuesArray))

    return isAnyAnswerValid
  }

  const isOperationSuccessful = operation(answer, prerequisite.value)

  return isOperationSuccessful
}

function checkMasterQuestionPrerequisites(masterQuestion, surveyObject) {
  const { completed_survey_answers, survey } = surveyObject

  return masterQuestion.question_prerequisites.every((prerequisite) => {
    const masterQuestionPrerequisiteId = parseInt(
      prerequisite.master_question_prerequisite_id,
      10
    )

    const answersForPrerequisiteQuestion = completed_survey_answers.filter(
      (answer) =>
        parseInt(answer.master_question_id, 10) === masterQuestionPrerequisiteId
    )

    if (answersForPrerequisiteQuestion.length === 0) return false

    const answer =
      answersForPrerequisiteQuestion.length > 1
        ? generateSelectedAnswersArray(
            answersForPrerequisiteQuestion,
            masterQuestionPrerequisiteId,
            surveyObject
          )
        : getAnswer(
            answersForPrerequisiteQuestion[0],
            survey,
            masterQuestionPrerequisiteId
          )

    const isAnswerValid = answer !== null && answer !== undefined

    const doesAnswerMeetPrerequisite = checkPrerequisiteWithOperator(
      prerequisite,
      answer
    )

    return isAnswerValid && doesAnswerMeetPrerequisite
  })
}

export default checkMasterQuestionPrerequisites
export { checkPrerequisiteWithOperator }
