import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'

import headerNavigationDefaultOptions from './_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from './_components/HeaderTitleComponent'
import LeftButtonComponent from './_components/LeftButtonComponent'

import FamilyMembersCancer from '$screens/onboarding/FamilyMembersCancer'

import AuthNavigator from './authentication/AuthNavigator'
import MainTabNavigator from './main/MainTabNavigator'
import PersonalDetailsNavigator from './personal_details/PersonalDetailsNavigator'
import RelativesNavigator from './relatives/RelativesNavigator'
import SettingsNavigator from './settings/Settings.navigator.js'

import RelationshipInfoTutorialNavigator from './tutorials/RelationshipInfoTutorialNavigator.js'
import CustomSuccessScreen from '$screens/SuccessScreens/CustomSuccessScreen'
import SwitchToFamgenixAccount from '$screens/onboarding/SwitchToFamgenixAccount'

const Stack = createStackNavigator()

function AuthStack() {
  return (
    <Stack.Navigator
      initialRouteName="AuthNavigator"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="AuthNavigator" component={AuthNavigator} />
      <Stack.Screen name="SettingsNavigator" component={SettingsNavigator} />
    </Stack.Navigator>
  )
}

function OnboardingStack() {
  return (
    <Stack.Navigator
      initialRouteName="PersonalDetailsNavigator"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="PersonalDetailsNavigator"
        component={PersonalDetailsNavigator}
      />
    </Stack.Navigator>
  )
}

function PostOnboardingStack() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="RelativesNavigator"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="RelativesNavigator" component={RelativesNavigator} />
      <Stack.Screen
        name="RelationshipInfoTutorialNavigator"
        component={RelationshipInfoTutorialNavigator}
      />
      <Stack.Screen
        name="FamilyMembersCancer"
        component={FamilyMembersCancer}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('family').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function MainStack() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="Main"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="Main" component={MainTabNavigator} />
      <Stack.Screen
        name="CustomSuccessScreen"
        component={CustomSuccessScreen}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light || 'black',
            colorSettings?.onboarding_nav_bgcolor || 'white'
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle=""
              headerTintColor={colorSettings?.nav_title_light || 'black'}
              headerBackgroundColor={
                colorSettings?.onboarding_nav_bgcolor || 'white'
              }
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.onboarding_nav_bgcolor || 'white'}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SwitchToFamgenixAccount"
        component={SwitchToFamgenixAccount}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark || 'black',
            colorSettings?.splash_nav_bgcolor || 'white'
          ),
          headerShown: true,
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle="FamGenix Account"
              headerTintColor={colorSettings?.nav_title_dark || 'black'}
              headerBackgroundColor={
                colorSettings?.splash_nav_bgcolor || 'white'
              }
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="custom"
              backArrowColor={colorSettings?.nav_title_dark || 'black'}
              customAction={() => navigation.navigate('CustomSuccessScreen')}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function AppSwitchNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="AuthStack"
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
    >
      <Stack.Screen name="AuthStack" component={AuthStack} />
      <Stack.Screen name="OnboardingStack" component={OnboardingStack} />
      <Stack.Screen
        name="PostOnboardingStack"
        component={PostOnboardingStack}
      />
      <Stack.Screen name="MainStack" component={MainStack} />
    </Stack.Navigator>
  )
}

export default AppSwitchNavigator
