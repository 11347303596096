const DEFAULT_ICON_NAME = ''
const DEFAULT_ICON_COLOR = ''
const INPUT_PADDING = 8
const BORDER_HEIGHT = 0
const AUTO_CAPITALIZE = 'none'
const AUTO_CORRECT = false
const RETURN_KEY_TYPE = 'next'
const LABEL_HEIGHT = 18

export {
  DEFAULT_ICON_NAME,
  DEFAULT_ICON_COLOR,
  INPUT_PADDING,
  BORDER_HEIGHT,
  AUTO_CAPITALIZE,
  AUTO_CORRECT,
  RETURN_KEY_TYPE,
  LABEL_HEIGHT,
}
