import { Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from './styles'
import { i18n } from '$localization/config.js'

function DoneButton({ handleSubmitAncestryList }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.doneButtonContainer}>
      <TouchableOpacity
        style={styles.doneButton(colorSettings)}
        onPress={handleSubmitAncestryList}
      >
        <Text style={styles.doneButtonText(colorSettings)}>
          {i18n.t('done').default}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default DoneButton
