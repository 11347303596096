const relationshipList = {
  child: { m: 'son', f: 'daughter', default: 'child' },
  sibling: { m: 'brother', f: 'sister', default: 'sibling' },
  brother: { m: 'brother', f: 'sister', default: 'sibling' },
  sister: { m: 'brother', f: 'sister', default: 'sibling' },
  son: { m: 'son', f: 'daughter', default: 'child' },
  daughter: { m: 'son', f: 'daughter', default: 'child' },
  grandson: {
    m: 'grandson',
    f: 'granddaughter',
    default: 'grandchild',
  },
  granddaughter: {
    m: 'grandson',
    f: 'granddaughter',
    default: 'grandchild',
  },
  halfsibling: {
    m: 'half_brother',
    f: 'half_sister',
    default: 'half_sibling',
  },
  half_niece: {
    m: 'half_nephew',
    f: 'half_niece',
    default: 'half_niece_nephew',
  },
  father: { m: 'father', f: 'mother', default: 'parent' },
  mother: { m: 'father', f: 'mother', default: 'parent' },
  nephew: { m: 'nephew', f: 'niece', default: 'niece_slash_nephew' },
  niece: { m: 'nephew', f: 'niece', default: 'niece_slash_nephew' },
  aunt: { m: 'uncle', f: 'aunt', default: 'aunt_slash_uncle' },
  uncle: { m: 'uncle', f: 'aunt', default: 'aunt_slash_uncle' },
  half_aunt: { m: 'half_uncle', f: 'half_aunt', default: 'half_aunt_uncle' },
  nibling: { m: 'nephew', f: 'niece', default: 'nephew_niece' },
  parent: { m: 'father', f: 'mother', default: 'parent' },
  pibling: { m: 'uncle', f: 'aunt', default: 'aunt_uncle' },
  grandparent: {
    m: 'grandfather',
    f: 'grandmother',
    default: 'grandparent',
  },
  grandmother: {
    m: 'grandfather',
    f: 'grandmother',
    default: 'grandparent',
  },
  grandfather: {
    m: 'grandfather',
    f: 'grandmother',
    default: 'grandparent',
  },
  grandchild: { m: 'grandson', f: 'granddaughter', default: 'grandchild' },
  grandnephew: {
    m: 'grand_nephew',
    f: 'grand_niece',
    default: 'grand_nephew_niece',
  },
  cousin: { default: 'cousin' },
  // Add other mapping cases for different relationships and genders
}

export default relationshipList
