import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginVertical: 10,
  },
  incrementButton: { paddingVertical: 10 },
  inputContainer: (colorSettings) => ({
    borderBottomColor: colorSettings?.text_input_color_border_2 || 'black',
    borderBottomWidth: 1,
    width: '20%',
    paddingVertical: 10,
    justifyContent: 'center',
  }),
  input: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    color: colorSettings?.text_input_color_2 || 'black',
    width: '100%',
    textAlign: 'center',
  }),
  decrementButton: { paddingVertical: 10 },
  textTitle: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    color: colorSettings?.selectable_btn_text_active_1 || 'black',
  }),
  outerInputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
