const EMPTY_STRING = ''
const SPACE = ' '
const INDEX_ZERO = 0
const INDEX_ONE = 1
const FALSE = false

export default function capitalizeFirstLetter(
  string,
  multiple = FALSE,
  separator = SPACE
) {
  if (!string) return EMPTY_STRING

  if (!multiple) {
    const capitalizedString =
      string.charAt(INDEX_ZERO).toUpperCase() + string.slice(INDEX_ONE)

    return capitalizedString
  }

  const capitalizedWords = string
    .split(separator)
    .map(
      (word) => word.charAt(INDEX_ZERO).toUpperCase() + word.slice(INDEX_ONE)
    )

  const joinedString = capitalizedWords.join(separator)

  return joinedString
}
