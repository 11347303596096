import { store } from '$redux/configureStore'
import {
  clearAuthCredentialsAction,
  clearAccountAction,
} from '$redux/account/actions.js'
import {
  clearProbandAction,
  clearProbandProfileAction,
  clearBasicFamilyMembers,
} from '$redux/proband/actions.js'
import { clearMemberProfileAction } from '$redux/member_profile/actions.js'
import { clearRiskFactorsAction } from '$redux/risk_factor/actions.js'
import { clearCliniciansAction } from '$redux/clinician/actions.js'
import { clearSurveysAction } from '$redux/survey/actions.js'

const resetReduxStoreData = () => {
  store.dispatch(clearAuthCredentialsAction())
  store.dispatch(clearAccountAction())
  store.dispatch(clearProbandAction())
  store.dispatch(clearProbandProfileAction())
  store.dispatch(clearMemberProfileAction())
  store.dispatch(clearRiskFactorsAction())
  store.dispatch(clearCliniciansAction())
  store.dispatch(clearSurveysAction())
  store.dispatch(clearBasicFamilyMembers())
}

export default resetReduxStoreData
