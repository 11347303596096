import { Alert } from 'react-native'
import * as Linking from 'expo-linking'
import { store } from '$redux/configureStore'
import {
  clearAccountAction,
  clearAuthCredentialsAction,
} from '$redux/account/actions'
import {
  clearBasicFamilyMembers,
  clearProbandAction,
  clearProbandProfileAction,
} from '$redux/proband/actions'
import {
  clearCurrentMember,
  clearMemberProfileAction,
} from '$redux/member_profile/actions'
import { clearRiskFactorsAction } from '$redux/risk_factor/actions'
import { clearSurveysAction } from '$redux/survey/actions'
import { setLastLoginScreen, setNavigationState } from '$redux/defaults/actions'
import { resetDiseasesStore } from '$redux/diseases/actions'
import { resetGeneTestingStore } from '$redux/gene_testing/actions'
import { resetClinicianStore } from '$redux/clinician/actions'
import packageJsonFile from '../../../package.json'
import { IS_ANDROID, IS_IOS, IS_MOBILE } from '$utils/Platforms.js'
import { apiFetchHandler } from '$api'
import { i18n } from '$localization/config.js'
import { APP_STORE_LINK, PLAY_STORE_LINK } from '$utils/globalVariables.js'

const clearReduxDataAfterLogout = () => {
  store.dispatch(clearAuthCredentialsAction())
  store.dispatch(clearAccountAction())
  store.dispatch(clearProbandAction())
  store.dispatch(clearProbandProfileAction())
  store.dispatch(clearMemberProfileAction())
  store.dispatch(clearCurrentMember())
  store.dispatch(clearRiskFactorsAction())
  store.dispatch(clearSurveysAction())
  store.dispatch(clearBasicFamilyMembers())

  store.dispatch(setLastLoginScreen(null))
  store.dispatch(setNavigationState(null))
  store.dispatch(resetDiseasesStore())
  store.dispatch(resetGeneTestingStore())
  store.dispatch(resetClinicianStore())
}

const openLink = async (url) => {
  try {
    const supported = await Linking.canOpenURL(url)
    if (supported) {
      await Linking.openURL(url)
    }
  } catch (err) {
    console.error(err)
  }
}

const handleOpenLink = async () => {
  if (IS_IOS()) {
    return openLink(APP_STORE_LINK)
  }
  if (IS_ANDROID()) {
    return openLink(PLAY_STORE_LINK)
  }
}

const checkApiVersion = async () => {
  if (__DEV__) return

  const apiCheckPayload = {
    path: 'account/api_version/',
    method: 'get',
    pageDetails: {
      page: 'Login.controller.js',
    },
  }

  const apiVersion = await apiFetchHandler(apiCheckPayload)
  const appVersion = packageJsonFile?.version

  if (!IS_MOBILE()) return

  if (apiVersion !== appVersion) {
    Alert.alert(
      i18n.t('update_required').default,
      i18n.t('you_must_update_to_the_latest_version_to_continue').default,
      [
        {
          text: i18n.t('update').default,
          onPress: () => handleOpenLink(),
        },
      ],
      { cancelable: true }
    )
    return true
  }
  return false
}

const fetchUserID = async () => {
  let userID = null
  await Linking.getInitialURL().then((url) => {
    const { queryParams } = Linking.parse(url)
    userID = queryParams?.id
  })
  return userID
}

export { clearReduxDataAfterLogout, checkApiVersion, fetchUserID }
