import { StyleSheet } from 'react-native'

const TRANSPARENT = 'transparent'
const BLACK = 'black'

const styles = StyleSheet.create({
  autoCompleteContainer: {
    width: '100%',
    backgroundColor: TRANSPARENT,
  },
  autocompleteInputContainer: (colorSettings) => ({
    borderRadius: 40,
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    height: 60,
    borderWidth: 0,
    marginBottom: 5,
    marginHorizontal: 18,
    backgroundColor: colorSettings.selectable_btn_border_inactive_1 || BLACK,
  }),
  autocompleteList: {
    backgroundColor: TRANSPARENT,
    borderWidth: 0,
    display: 'flex',
    position: 'relative',
  },
  listContainer: {
    flex: 1,
  },
})

export default styles
