import React from 'react'
import { Image, ImageBackground, Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import { NAVIGATORS, ROUTES } from '$navigation/constants/routes'

import styles from './styles'

import NextButtonAbsolute from '$components/NextButtonAbsolute'

export default function SuccessScreenFamilyInvite({ navigation }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const welcomeMessage = i18n.t('welcome_to_the_family')?.default
  const continueText = i18n.t('continue')?.default

  const nextButtonAction = () => {
    navigation.navigate(
      NAVIGATORS?.SignupFamilySharingSettingsNavigator?.name,
      {
        screen: ROUTES?.SignupFamilySharingSettingsMain?.name,
      }
    )
  }

  return (
    <View style={styles.container}>
      <ImageBackground
        style={styles.fullBackgroundImage(colorSettings)}
        resizeMode="cover"
      >
        <View style={styles.contentContainer}>
          <Image
            source={require('$assets/images/icon/success/badge.png')}
            style={styles.image}
          />

          <Text style={styles.titleText(colorSettings)}>{welcomeMessage}</Text>

          <View style={styles.nextButtonContainer}>
            <NextButtonAbsolute
              onPressAction={nextButtonAction}
              buttonText={continueText}
            />
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}
