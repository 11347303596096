import { useCallback } from 'react'
import { View, Text } from 'react-native'
import uuid from 'react-native-uuid'

import CustomizableButton from '$components/CustomizableButton'
import LabelField from '../label-field/LabelField'
import styles from './MultiSelectField'

export default function MultiSelectField({ inputFieldData, colorSettings }) {
  const choiceListHandler = () => {
    const { input_choices } = inputFieldData
    const choiceList = []

    const onPressHandler = useCallback((item, selectedItem) => {
      console.log(
        '🚀 \n\n file: MultiSelectField.js:17 \n\n onPressHandler \n\n item:',
        item
      )
      console.log(
        '🚀 \n\n file: MultiSelectField.js:17 \n\n onPressHandler \n\n selectedItem:',
        selectedItem
      )
    })

    input_choices.forEach((itemChoice) => {
      choiceList.push(
        <CustomizableButton
          key={uuid.v4()}
          title={itemChoice.choice}
          colorSettings={colorSettings}
          onPress={onPressHandler}
          selectedItem={itemChoice}
        />
      )
    })

    return choiceList
  }

  return (
    <View style={styles.container}>
      <LabelField title={inputFieldData.label} colorSettings={colorSettings} />
      {choiceListHandler()}
    </View>
  )
}

// MultiSelectField.propTypes = {
//   inputFieldData: PropTypes.shape({
//     input_choices: PropTypes.arrayOf(
//       PropTypes.shape({
//         id: PropTypes.number,
//         choice: PropTypes.string,
//         order: PropTypes.number,
//       })
//     ),
//     label: PropTypes.string,
//     placeholder: PropTypes.string,
//   }),
//   colorSettings: PropTypes.objectOf(PropTypes.string),
// }

MultiSelectField.defaultProps = {
  inputFieldData: {},
  colorSettings: {},
}
