import { View } from 'react-native'

import NumericalTextInput from '$components/NumericalTextInput'

export default function RelativeSideCounter({
  colorSettings,
  relativeSide,
  familyMembersCount,
  inputValidation,
  onPress,
  onChange,
  isEditable = false,
  onPressHandler,
  onFocus,
}) {
  const { relativeCount, momSideCount, dadSideCount } = familyMembersCount

  const getCount = () => {
    if (relativeSide === 'mom') return momSideCount?.toString() ?? ''
    if (relativeSide === 'dad') return dadSideCount?.toString() ?? ''

    return relativeCount?.toString() ?? ''
  }
  const count = getCount()

  return (
    <View>
      <NumericalTextInput
        isEditable={isEditable}
        colorSettings={colorSettings}
        subtractCount={() =>
          onPress({
            relativeSide,
            operator: 'substraction',
          })
        }
        relativeSide={relativeSide}
        addCount={() =>
          onPress({
            relativeSide,
            operator: 'addition',
          })
        }
        onFocus={onFocus}
        onPressHandler={onPressHandler}
        inputValidation={inputValidation}
        count={count}
        onChangeText={(text) =>
          onChange({
            value: text,
            relativeSide,
          })
        }
      />
    </View>
  )
}
