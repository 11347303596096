import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: 30,
  },
  title: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
    color: colorSettings?.selectable_btn_text_active_1,
  }),
  buttonContainerStyle: {
    flexDirection: 'row',
  },
  buttonStyle: {
    flex: 1,
    marginHorizontal: 10,
  },
  roundedButtonTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'center',
  },
})

export default styles
