import { Text, TouchableOpacity } from 'react-native'
import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'

import styles from './styles'

const CustomizableButton = forwardRef(
  (
    {
      setToggle,
      currentState,
      preventToggleEffect,
      onPress,
      colorSettings,
      isDisabled,
      title,
      styleHandler,
      customStyle,
    },
    ref
  ) => {
    const [isSelected, setIsSelected] = useState(setToggle)

    useEffect(() => {
      if (currentState) currentState(isSelected)
    }, [isSelected])

    useEffect(() => {
      if (!preventToggleEffect) setIsSelected(setToggle)
    }, [setToggle])

    useImperativeHandle(ref, () => ({
      test() {
        handleOnPress()
      },
    }))

    const handleOnPress = useCallback(() => {
      onPress(!isSelected)
      if (!preventToggleEffect) setIsSelected((selected) => !selected)
    }, [isSelected, preventToggleEffect])

    const internalStyleHandler = () => {
      if (isSelected) {
        return styles.activeButton(colorSettings)
      }

      return styles.inactiveButton(colorSettings)
    }

    const TOGGLE_BUTTON_TEST_ID = 'toggleButton'
    const BUTTON_TITLE_TEST_ID = 'buttonTitle'

    return (
      <TouchableOpacity
        style={[
          styles.buttonSelect,
          styleHandler ? styleHandler() : internalStyleHandler(),
          { ...customStyle },
        ]}
        activeOpacity={1}
        disabled={isDisabled}
        onPress={handleOnPress}
        testID={TOGGLE_BUTTON_TEST_ID}
      >
        <Text
          style={[styles.selectButtonText(colorSettings)]}
          testID={BUTTON_TITLE_TEST_ID}
        >
          {title}
        </Text>
      </TouchableOpacity>
    )
  }
)

export default CustomizableButton
