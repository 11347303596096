import { relationshipTypes } from '$constants'
import { i18n } from '$localization/config.js'

const generalizeRelationshipToProband = (relationshipToProband = '') => {
  switch (true) {
    case relationshipToProband === 'father':
    case relationshipToProband === 'mother':
      return relationshipTypes.PARENT

    case relationshipToProband?.includes('brother'):
    case relationshipToProband?.includes('sister'):
      return relationshipTypes.SIBLING

    case relationshipToProband?.includes('son'):
    case relationshipToProband?.includes('daughter'):
      return relationshipTypes.CHILD

    case relationshipToProband?.includes('nephew'):
    case relationshipToProband?.includes('niece'):
      return relationshipTypes.NIBLING

    case relationshipToProband?.includes('cousin'):
      return relationshipTypes.COUSIN

    case relationshipToProband?.includes('aunt'):
    case relationshipToProband?.includes('uncle'):
      return relationshipTypes.PIBLING

    case relationshipToProband?.includes('grandfather'):
    case relationshipToProband?.includes('grandmother'):
      return relationshipTypes.GRANDPARENT

    default:
      return relationshipToProband
  }
}

const getRelativeTitle = (relativeTitle, relativeSide) => {
  const relativeTitleFormated = `${relativeTitle}`
    .replace(/\s/g, '_')
    .toLowerCase()

  if (relativeSide === 'dad')
    return (
      `${i18n.t(relativeTitleFormated)?.default} (${
        i18n.t('dads_side')?.title
      })` ?? `${relativeTitle} (${i18n.t('dads_side')?.title})`
    )

  if (relativeSide === 'mom')
    return (
      `${i18n.t(relativeTitleFormated)?.default} (${
        i18n.t('moms_side')?.title
      })` ?? `${relativeTitle} (${i18n.t('moms_side')?.title})`
    )

  return `${i18n.t(relativeTitleFormated)?.default}` ?? relativeTitle
}

const handleFilterSelectedFamilyMembers = (selectedFamilyMembers) => {
  const formattedFamilyMembers = selectedFamilyMembers.map((member) => {
    const isUncleOrAunt =
      member?.selectedItemKeyRef?.includes('uncle') ||
      member?.selectedItemKeyRef?.includes('aunt')

    const removedUnderscoreName = member?.selectedItemKeyRef?.split('_')[0]

    return {
      ...member,
      selectedItemKeyRef: isUncleOrAunt
        ? removedUnderscoreName
        : member?.selectedItemKeyRef,
    }
  })

  const filteredFamilyMembers = formattedFamilyMembers.filter((member) => {
    if (
      member?.selectedItemKeyRef === 'uncle' ||
      member?.selectedItemKeyRef === 'aunt'
    ) {
      return member?.dadSideCount > 0 || member?.momSideCount > 0
    }

    return member?.count > 0
  })

  const parentObject = filteredFamilyMembers?.find(
    (member) => member?.selectedItemKeyRef === 'parent'
  )
  const parentObjectWithoutParent = filteredFamilyMembers?.filter(
    (element) => element.selectedItemKeyRef !== 'parent'
  )

  const familyMembers = [parentObject, ...parentObjectWithoutParent].filter(
    (member) => member !== undefined
  )

  return familyMembers
}

export {
  generalizeRelationshipToProband,
  getRelativeTitle,
  handleFilterSelectedFamilyMembers,
}
