import { Component } from 'react'
import { SafeAreaView, ScrollView, Platform } from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import InviteCard from '$screens/main/dashboard/invite/InviteCard.js'

import Proband from '$data_models/Proband'

import { IS_WEB } from '$utils/Platforms.js'
import { platforms } from '$constants'

import { calculateAvailableContentHeight } from '$assets/styles/global.styles.js'

import MainWrapper from '$components/MainWrapper'

class InviteMain extends Component {
  proband = this.props.currentProband ?? new Proband()

  state = {
    scrollViewHeight: calculateAvailableContentHeight(),
  }

  componentDidMount() {
    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: calculateAvailableContentHeight() })
      )
    }
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: calculateAvailableContentHeight() })
      )
    }
  }

  navigateToInviteFamilyMember = () => {
    const { navigation, colorSettings } = this.props

    // TODO: Probably need to change to InviteFamilyMemberNavigator
    navigation.navigate('InviteFamilyMemberMain', {
      screen: 'InviteFamilyMember',
    })
  }

  render() {
    const { navigation } = this.props
    return (
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={{
            backgroundColor: 'rgb(238,238,238)',
            flex: 1.0,
          }}
        >
          <ScrollView
            style={{
              ...Platform.select({
                [platforms.WEB]: {
                  height: this.state.scrollViewHeight,
                },
              }),
            }}
            contentContainerStyle={{ padding: 16 }}
            nativeID="web_scaled_main_container"
            ref={(ref) => (this.scrollView = ref)}
            bounces={false}
          >
            <InviteCard
              contentTitle={i18n.t('invite_a_family_member')?.default}
              contentMessage={
                i18n.t(
                  'make_your_record_more_accurate_by_collaborating_with_family_members_and_notifying_each_other_of_updates_to_your_health'
                )?.default
              }
              buttonTitle={i18n.t('invite_family_member')?.default}
              backgroundImage={require('$assets/images/background/bluesmall.png')}
              cardIcon={require('$assets/images/new_assets/illus-family.png')}
              buttonOnPress={() => this.navigateToInviteFamilyMember()}
              colorSettings={this.props.colorSettings}
            />
          </ScrollView>
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore } = state
  return {
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    currentProband: probandStore.proband,
  }
}

export default connect(mapGlobalStateToProps, null)(InviteMain)
