import { i18n } from '$localization/config.js'

export default function getLanguage() {
  const { locale } = i18n
  let lang = 'en'

  if (locale && typeof locale === 'string') {
    const [primaryLang] = locale.split('-')
    lang = primaryLang || lang
  }

  return lang
}
