import React from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'

import { i18n } from '$localization/config.js'
import { navigationConstants } from '$constants'

import SignupScreenPhone from '$screens/onboarding/SignupScreenPhone'
import SignupScreenPhoneCode from '$screens/onboarding/SignupScreenPhoneCode'

import SignupClinicianCode from '$screens/onboarding/clinician_code/SignupClinicianCode.js'
import SignupFamilyInviteCode from '$screens/onboarding/family_invite/SignupFamilyInviteCode.js'
import SignupFamilyInvitePhoneCode from '$screens/onboarding/family_invite/SignupFamilyInvitePhoneCode.js'

import SignupFamilySharingSettingsMain from '$screens/onboarding/family_invite/SignupFamilySharingSettings.main.js'
import SignupFamilySharingSettingsDetail from '$screens/onboarding/family_invite/SignupFamilySharingSettings.detail.js'

import ForgotPWScreenSelectOption from '$screens/onboarding/forgot_password/ForgotPWScreenSelectOption'
import ForgotPWScreenEnterPhoneNumber from '$screens/onboarding/forgot_password/ForgotPWScreenEnterPhoneNumber'
import ForgotPWScreenEnterEmail from '$screens/onboarding/forgot_password/ForgotPWScreenEnterEmail'
import ForgotPWScreenEnterCode from '$screens/onboarding/forgot_password/ForgotPWScreenEnterCode'
import ForgotPWScreenEnterNewPassword from '$screens/onboarding/forgot_password/ForgotPWScreenEnterNewPassword'
import SuccessScreenAccountCreated from '$screens/SuccessScreens/SuccessScreenAccountCreated'
import SuccessScreenResetPassword from '$screens/SuccessScreens/SuccessScreenResetPassword'
import SuccessScreenFamilyInvite from '$screens/SuccessScreens/SuccessScreenFamilyInvite'

import HeaderTitleComponent from '../_components/HeaderTitleComponent'
import LeftButtonComponent from '../_components/LeftButtonComponent'

import headerNavigationDefaultOptions from '../_utils/HeaderNavigationDefaultOptions'

import WelcomeScreen from '$screens/onboarding/welcomeScreen/WelcomeScreen'
import CustomLoginScreen from '$screens/onboarding/customLogin/CustomLoginScreen'
import CustomInputScreen from '$screens/onboarding/customInputScreen/CustomInputScreen'
import CustomValidateDOB from '$screens/onboarding/customValidateDOB/CustomValidateDOB'
import CustomCreatePassword from '$screens/onboarding/customCreatePassword/customCreatePassword'
import EnvSecretScreen from '$screens/EnvSecretScreen'
import CustomScreen from '$screens/CustomScreen'
import SignUpScreen from '$screens/onboarding/SignUpScreen'

const Stack = createStackNavigator()
function SignupFamilySharingSettingsNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="SignupFamilySharingSettingsMain"
      screenOptions={{ ...navigationConstants.DEFAULT_HEADER_SETTINGS }}
    >
      <Stack.Screen
        name="SignupFamilySharingSettingsMain"
        component={SignupFamilySharingSettingsMain}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('sharing_settings').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType={null}
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SignupFamilySharingSettingsDetail"
        component={SignupFamilySharingSettingsDetail}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('sharing_settings').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function SignupFamilyInviteNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator initialRouteName="SignupFamilyInviteCode">
      <Stack.Screen
        name="SignupFamilyInviteCode"
        component={SignupFamilyInviteCode}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('family_code').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'invite_code'
      />
      <Stack.Screen
        name="SignupFamilyInvitePhoneCode"
        component={SignupFamilyInvitePhoneCode}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('phone_verification').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function SignupClinicianNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SignupClinicianCode"
        component={SignupClinicianCode}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('create_an_account').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'singup/clinician'
      />
    </Stack.Navigator>
  )
}
// TODO: try to separate this to another screen and connect it to redux store
function MainNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator initialRouteName="LoginView">
      <Stack.Screen
        name="CustomLoginScreen"
        component={CustomLoginScreen}
        options={{
          headerShown: false,
        }}
        // path: ''
      />
      <Stack.Screen
        name="WelcomeScreen"
        component={WelcomeScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="CustomValidateDOB"
        component={CustomValidateDOB}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark || 'black',
            colorSettings?.splash_nav_bgcolor || 'white'
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details')?.default}
              headerTintColor={colorSettings?.nav_title_dark || 'black'}
              headerBackgroundColor={
                colorSettings?.splash_nav_bgcolor || 'white'
              }
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
        // path: 'signup'
      />
      <Stack.Screen
        name="CustomCreatePassword"
        component={CustomCreatePassword}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark || 'black',
            colorSettings?.splash_nav_bgcolor || 'white'
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('create_an_account')?.default}
              headerTintColor={colorSettings?.nav_title_dark || 'black'}
              headerBackgroundColor={
                colorSettings?.splash_nav_bgcolor || 'white'
              }
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
        // path: 'signup'
      />
      <Stack.Screen
        name="CustomInputScreen"
        component={CustomInputScreen}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light || 'black',
            colorSettings?.onboarding_nav_bgcolor || 'white'
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle=""
              headerTintColor={colorSettings?.nav_title_light || 'black'}
              headerBackgroundColor={
                colorSettings?.onboarding_nav_bgcolor || 'white'
              }
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.onboarding_nav_bgcolor || 'white'}
            />
          ),
        })}
        // path: 'signup'
      />
      <Stack.Screen
        name="SurveyCustom"
        component={CustomScreen}
        options={({ navigation }) => ({
          headerShown: true,
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
        })}
      />
      <Stack.Screen
        name="CustomScreen"
        component={CustomScreen}
        options={({ navigation, route }) => ({
          headerShown: true,
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={route?.params?.title ?? ''}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SignupMainScreen"
        component={SignUpScreen}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('create_an_account').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
        // path: 'signup'
      />
      <Stack.Screen
        name="SignupScreenPhone"
        component={SignupScreenPhone}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('create_an_account').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'signup/phone_verification'
      />
      <Stack.Screen
        name="SignupScreenPhoneCode"
        component={SignupScreenPhoneCode}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('create_an_account').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
        // path: 'signup/phone_verification_code'
      />
      <Stack.Screen
        name="ForgotPWSelectOption"
        component={ForgotPWScreenSelectOption}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('forgot_password').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
      <Stack.Screen
        name="ForgotPWEnterEmail"
        component={ForgotPWScreenEnterEmail}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('forgot_password').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
      <Stack.Screen
        name="ForgotPWEnterPhoneNumber"
        component={ForgotPWScreenEnterPhoneNumber}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('forgot_password').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
      <Stack.Screen
        name="ForgotPWEnterCode"
        component={ForgotPWScreenEnterCode}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('forgot_password').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
      <Stack.Screen
        name="ForgotPWEnterNewPassword"
        component={ForgotPWScreenEnterNewPassword}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('forgot_password').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SignupFamilyInviteNavigator"
        component={SignupFamilyInviteNavigator}
        options={{
          headerTintColor: 'black',
          headerShown: false,
          headerBackTitle: null,
          gesturesEnabled: false,
        }}
        // path: ''
      />
      <Stack.Screen
        name="EnvSecretScreen"
        component={EnvSecretScreen}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  )
}

export default function AuthNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="MainNavigator"
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        headerBackTitle: null,
      }}
    >
      <Stack.Screen
        name="MainNavigator"
        component={MainNavigator}
        // path: ''
      />
      <Stack.Screen
        name="SuccessScreenFamilyInvite"
        component={SuccessScreenFamilyInvite}
        // path: ''
      />
      <Stack.Screen
        name="SignupClinicianNavigator"
        component={SignupClinicianNavigator}
        // path: ''
      />
      <Stack.Screen
        name="SignupFamilySharingSettingsNavigator"
        component={SignupFamilySharingSettingsNavigator}
        // path: ''
      />
      <Stack.Screen
        name="SuccessScreenAccountCreated"
        component={SuccessScreenAccountCreated}
        // path: 'signup/success'
      />
      <Stack.Screen
        name="SuccessScreenResetPassword"
        component={SuccessScreenResetPassword}
      />
    </Stack.Navigator>
  )
}
