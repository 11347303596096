import { StyleSheet } from 'react-native'
import { IS_WEB } from '$constants/platforms'

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    flex: 1,
    backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
    width: '100%',
  }),
  contentContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  scaledContainer: { width: '100%' },
  scrollViewContainer: { maxWidth: 800, width: '100%', paddingHorizontal: 15 },
  personalGenderIdentityTitleText: (colorSettings) => ({
    textAlign: 'center',
    color: colorSettings?.text_input_title_color_2 || 'black',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: IS_WEB() ? 50 : 0,
  }),
  personalGenderIdentityPronounText: (colorSettings) => ({
    textAlign: 'center',
    color: colorSettings?.text_input_title_color_2 || 'black',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginBottom: 15,
  }),
})

export default styles
