import { Image } from 'react-native'
import styles from './styles'

export default function SuccessIcon() {
  return (
    <Image
      source={require('$assets/images/icon/success/badge.png')}
      style={styles.image}
    />
  )
}
