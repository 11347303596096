export const DEFAULT_CONTENT_TYPE = 'application/json'

export const ONE_SECOND = 1000
export const NETWORK_TIMEOUT_VALUE = ONE_SECOND * 30

export const DEFAULT_NETWORK_ERROR = {
  MESSAGE: 'Network request failed',
  STATUS: 0,
  RESPONSE: '',
}
