import { Platform } from 'react-native'

import Ionicons from '@expo/vector-icons/Ionicons'
import { platforms } from '$constants'

const setStyleByPlatform = () => {
  switch (Platform.OS) {
    case platforms.WEB:
      return {
        marginLeft: 13,
        alignSelf: 'center',
        justifyContent: 'center',
      }
    case platforms.ANDROID:
      return {
        marginLeft: 5,
        alignSelf: 'center',
        justifyContent: 'center',
      }
    case platforms.IOS:
      return {
        marginLeft: 13,
        alignSelf: 'center',
        justifyContent: 'center',
      }
  }
}

const HeaderBackImage = ({ color }) => (
  <Ionicons
    name="arrow-back-outline"
    size={22}
    color={color}
    style={setStyleByPlatform()}
  />
)

export default HeaderBackImage
