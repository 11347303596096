import { StyleSheet } from 'react-native'

const FALLBACK_GREEN_COLOR = 'rgba(101,209,185,1)'

const styles = StyleSheet.create({
  forgotButtonBorderless: {
    width: '100%',
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  forgotPasswordButtonText: (colorSettings) => ({
    color: colorSettings?.bottom_next_btn_enabled || FALLBACK_GREEN_COLOR,
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  }),
})

export default styles
