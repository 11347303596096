import ErrorBoundary from 'react-native-error-boundary'
import {
  KeyboardAvoidingView,
  SafeAreaView,
  View,
  ScrollView,
} from 'react-native'
import { useDispatch } from 'react-redux'
import React, { useMemo, useRef, useState } from 'react'
import moment from 'moment/moment'
import { useNavigation } from '@react-navigation/core'
import styles from './styles'
import InvitedPersonHeaderInfo from './components/InvitedPersonHeaderInfo'
import TermsAndPrivacyModal from '$components/TermsAndPrivacyModal'
import { IS_IOS } from '$utils/Platforms.js'
import SignupCustomInput from '$components/SignupCustomInput'
import { i18n } from '$localization/config.js'
import { FIELD_NAMES } from '$components/SignupCustomInput/constants'
import SignUpDatePicker from '$components/SignUpDatePicker'
import CustomDatePicker from '$components/CustomDatePicker'
import NextButtonAbsolute from '$components/NextButtonAbsolute'
import { setShowLoadingView, setError } from '$redux/defaults/actions'
import useAuth from '$hooks/useAuth'
import { fetchSendInvitedMember, validateDobAction } from './fetchHandlers'
import { emailToLowerCase, yearIntervalFromNow } from '$utils'
import { errorResponseHandler } from '../authorizationHelpers'
import { underageAsyncAlert } from './helpers'
import { setAccountSignupDetailsAction } from '$redux/account/actions'

import { dateFormats } from '$constants'
import { selectorHelpers } from './reduxHelpers'

const initialFormStateData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  dateOfBirthdate: null,
}

function SignUpScreen() {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const emailRef = useRef(null)
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const passwordRef = useRef(null)
  const dateOfBirthdateRef = useRef(null)

  const [formData, setFormData] = useState(initialFormStateData)
  const [isMobileDatepickerShown, setIsMobileDatepickerShown] = useState(false)
  const [showTermsAndPrivacy, setShowTermsAndPrivacy] = useState(false)

  const {
    colorSettings,
    inviteID,
    firstName,
    isInvited,
    selectedLanguage,
    currentMember,
  } = selectorHelpers()

  const showLoadingView = (data) => dispatch(setShowLoadingView(data))
  const saveAccountSignupDetails = (data) =>
    dispatch(setAccountSignupDetailsAction(data))
  const saveError = (data) => dispatch(setError(data))

  const { handleLoginUser, checkPasswordValidity, checkEmailAvailability } =
    useAuth()

  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const onMobileDatePickerChange = ({ selectedDate, dismiss }) => {
    if (dismiss && !selectedDate) {
      return setIsMobileDatepickerShown(!isMobileDatepickerShown)
    }

    handleInputChange(FIELD_NAMES.DATE_OF_BIRTHDATE, selectedDate)
    setIsMobileDatepickerShown(!isMobileDatepickerShown)
  }

  const isSubmitButtonEnabled = useMemo(
    () => Object.values(formData).every((value) => value),
    [formData]
  )

  const proceedToSignUp = async () => {
    setShowTermsAndPrivacy(false)

    try {
      showLoadingView(true)
      if (isInvited) {
        const fetchSendInvitedMemberResponse = await fetchSendInvitedMember(
          formData,
          currentMember
        )

        if (fetchSendInvitedMemberResponse.isError) {
          return saveError({
            isShown: true,
            status: fetchSendInvitedMemberResponse.status,
            message: fetchSendInvitedMemberResponse.error,
          })
        }

        return handleLoginUser(
          navigation,
          formData.email,
          formData.password,
          isInvited
        )
      }

      return navigation.navigate('SignupScreenPhone')
    } catch (error) {
      console.log('🚀 \n\n file: SignUpScreen \n\n proceedToSignUp:', error)
    } finally {
      showLoadingView(false)
    }
  }

  const handleSubmitButton = async () => {
    showLoadingView(true)

    const accountSignupDetails = {
      email: emailToLowerCase(formData.email),
      password: formData.password,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dob: moment(formData.dateOfBirthdate).format(
        dateFormats.DEFAULT_DATE_FORMAT_API
      ),
      age: yearIntervalFromNow(formData.dateOfBirthdate),
    }

    try {
      saveAccountSignupDetails(accountSignupDetails)

      if (yearIntervalFromNow(formData.dateOfBirthdate) < 18) {
        return underageAsyncAlert()
      }

      const isPasswordValid = checkPasswordValidity(formData.password)
      const isEmailValid = await checkEmailAvailability(formData.email)

      if (!isPasswordValid || !isEmailValid) return

      if (inviteID) {
        const response = await validateDobAction(
          formData.dateOfBirthdate,
          inviteID
        )

        const successStatusCode = 200

        if (response?.isError) {
          return errorResponseHandler(response, saveError)
        }

        setShowTermsAndPrivacy(response?.status_code === successStatusCode)
      }

      setShowTermsAndPrivacy(true)
    } catch (error) {
      console.log('🚀 \n\n file: SignUpScreen \n\n handleSubmitButton:', error)
    } finally {
      showLoadingView(false)
    }
  }

  const selectedDateOfBirthdate = formData.dateOfBirthdate ?? new Date(0)

  return (
    <ErrorBoundary>
      <SafeAreaView style={styles.container(colorSettings)}>
        {isInvited && <InvitedPersonHeaderInfo firstName={firstName} />}
        <KeyboardAvoidingView
          style={styles.contentContainer}
          contentContainerStyle={styles.contentContainer}
          behavior="padding"
          keyboardVerticalOffset={60}
          enabled={IS_IOS()}
        >
          <ScrollView showsVerticalScrollIndicator>
            <View style={styles.signUpSectionContainer}>
              <SignupCustomInput
                key={FIELD_NAMES.FIRST_NAME}
                handleInputChange={handleInputChange}
                inputValue={formData.firstName}
                inputName={FIELD_NAMES.FIRST_NAME}
                ref={firstNameRef}
                onChangeRefAction={() => lastNameRef.current?.focusTextInput()}
                inputLabel={i18n.t('first_name.default')}
              />

              <SignupCustomInput
                key={FIELD_NAMES.LAST_NAME}
                handleInputChange={handleInputChange}
                inputValue={formData.lastName}
                inputName={FIELD_NAMES.LAST_NAME}
                ref={lastNameRef}
                onChangeRefAction={() => emailRef.current?.focusTextInput()}
                inputLabel={i18n.t('last_name.default')}
              />

              <SignupCustomInput
                key={FIELD_NAMES.EMAIL}
                handleInputChange={handleInputChange}
                inputValue={formData.email}
                inputName={FIELD_NAMES.EMAIL}
                ref={emailRef}
                onChangeRefAction={() => passwordRef.current?.focusTextInput()}
                inputLabel={i18n.t('email.default')}
              />

              <SignupCustomInput
                key={FIELD_NAMES.PASSWORD}
                handleInputChange={handleInputChange}
                inputValue={formData.password}
                inputName={FIELD_NAMES.PASSWORD}
                ref={passwordRef}
                inputLabel={i18n.t('password.default')}
              />

              <SignUpDatePicker
                handleInputChange={handleInputChange}
                dateOfBirthday={formData.dateOfBirthdate}
                dateOfBirthdateRef={dateOfBirthdateRef}
                toggleDateMobileDatePicker={() =>
                  setIsMobileDatepickerShown(!isMobileDatepickerShown)
                }
              />
            </View>
          </ScrollView>
        </KeyboardAvoidingView>

        <NextButtonAbsolute
          buttonText={i18n.t('sign_up')?.title}
          isDisabled={!isSubmitButtonEnabled}
          onPressAction={() => handleSubmitButton()}
        />

        <TermsAndPrivacyModal
          modalVisible={showTermsAndPrivacy}
          primaryButtonAction={proceedToSignUp}
          secondaryButtonAction={() =>
            setShowTermsAndPrivacy(!showTermsAndPrivacy)
          }
        />

        {isMobileDatepickerShown && (
          <CustomDatePicker
            colorSettings={colorSettings}
            onDatePickerChange={onMobileDatePickerChange}
            date={selectedDateOfBirthdate}
            visible={isMobileDatepickerShown}
            selectedLanguage={selectedLanguage}
          />
        )}
      </SafeAreaView>
    </ErrorBoundary>
  )
}

export default SignUpScreen
