import { StyleSheet } from 'react-native'

const FALLBACK_BACKGROUND_COLOR = 'rgba(0,0,0,0.2)'

const styles = StyleSheet.create({
  activeButton: (colorSettings) => ({
    backgroundColor:
      colorSettings?.selectable_btn_active_1 || FALLBACK_BACKGROUND_COLOR,
    borderColor: colorSettings?.selectable_btn_border_active_1,
  }),
  inactiveButton: (colorSettings) => ({
    borderColor: colorSettings?.selectable_btn_border_inactive_1,
  }),
  selectButtonText: (colorSettings) => ({
    color: colorSettings?.selectable_btn_text_active_1 || 'rgba(74, 74, 74, 1)',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  }),
  buttonSelect: {
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,.5)',
    minHeight: 60,
    marginBottom: 10,
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
})

export default styles
