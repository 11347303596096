const INITIAL_COUNTRY_CODE = 'us'
const COUNTRY_PICKER_VISIBLE = false
const ACTIVE_OPACITY = 1.0
const OFFSET = 0
const ANIMATION_TYPE = 'slide'
const FILTER_PLACEHOLDER = 'Search country'
const MAIN_CONTAINER_ID = 'web_scaled_main_container'
const COUNTRY_PICKER_ID = 'country_picker_element'
const PHONE_FLAG_AND_CODE_ID = 'phone_flag_and_ccode_signup_process'
const TEXT_INPUT_ID = 'text_input_web'
const LANGUAGE_FR = 'fr'
const LANGUAGE_NL = 'nl'
const LANGUAGE_DE = 'de'
const TRANSLATION_KEY_FR = 'fra'
const TRANSLATION_KEY_NL = 'nld'
const TRANSLATION_KEY_DE = 'deu'
const TRANSLATION_KEY_COMMON = 'common'
const PREFIX_TEXT_COLOR = null
const EMPTY_STRING = ''

export {
  INITIAL_COUNTRY_CODE,
  COUNTRY_PICKER_VISIBLE,
  ACTIVE_OPACITY,
  OFFSET,
  ANIMATION_TYPE,
  FILTER_PLACEHOLDER,
  MAIN_CONTAINER_ID,
  COUNTRY_PICKER_ID,
  PHONE_FLAG_AND_CODE_ID,
  TEXT_INPUT_ID,
  LANGUAGE_FR,
  LANGUAGE_NL,
  LANGUAGE_DE,
  TRANSLATION_KEY_FR,
  TRANSLATION_KEY_NL,
  TRANSLATION_KEY_DE,
  TRANSLATION_KEY_COMMON,
  PREFIX_TEXT_COLOR,
  EMPTY_STRING,
}
