import { getFamilySide, getRelationshipGender } from './helpers'

export default function getFamilySideAndRelationshipGender(
  relationshipToProband,
  gender = null,
  separator = ' '
) {
  if (!relationshipToProband) return ''

  let relationship = relationshipToProband.split(separator)

  const familySide = getFamilySide(relationship) || ''

  relationship = relationship.join(' ')

  const { greatCounter, relationshipGender } = getRelationshipGender(
    relationship,
    gender
  )

  const values = [relationshipGender.toLowerCase(), familySide, greatCounter]

  return values
}
