//* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'

import { SafeAreaView, KeyboardAvoidingView } from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'

import MainWrapper from '../../components/MainWrapper/index.js'
import FlexContainer from '$components/FlexContainer'
import { SurveyCompleteScreen } from './components'
import styles from './assets/styles.js'
import mock_survey_data from './data/mock-survey-data'

import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import { saveLastScreen } from '$screens/utils'
import { IS_ANDROID } from '$utils/Platforms.js'

import {
  fetchSurvey,
  fetchSaveSurvey,
  fetchDeleteSurveyAnswer,
  fetchSendClinicianNotification,
  fetchCompletedSurveyNotification,
} from './helpers/fetchHandlers.js'

import { exitSurvey, getSurveyId } from './helpers'

import {
  calculateCurrentPage,
  calculatePreviousPage,
  handleGetAllowedSurveyPages,
} from './helpers/surveyPagesHandlers'

import {
  handleSelectAllThatApply,
  handleMatchingAnswers,
  handleConditionalSurveyAnswerDeletion,
} from './helpers/surveyAnswersHandlers'

import { useReduxData, useReduxActions } from './helpers/useReduxData'

import SurveyPageGenerator from './components/SurveyPageGenerator'

import { SURVEY_STATE } from './constants.js'
import { handleNavigateToPreviousScreen } from '$navigation/_utils/custom_workflow'

import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes.js'
import LoadingView from '../../components/LoadingView/index'

function CustomInputScreen({ navigation }) {
  const [surveyData, setSurveyData] = useState({
    ...mock_survey_data.empty_survey,
  })
  const [surveyAnswers, setSurveyAnswers] = useState([])
  const [allowedSurveyPages, setAllowedSurveyPages] = useState([])
  const [currentSurveyPage, setCurrentSurveyPage] = useState(1)
  const [isCompletedScreenShown, setIsCompletedScreenShown] = useState(false)

  const [surveyPageRenderControl, setSurveyPageRenderControl] = useState({
    canRenderSurveyPage: false,
    renderCount: 0,
  })
  const [isFetching, setIsFetching] = useState(false)

  const fieldSurveyAnswers = useRef([])
  const reduxActions = useReduxActions()
  const reduxStore = useReduxData()
  const previousAnswerHolder = useRef([])

  useEffect(() => {
    fieldSurveyAnswers.current = []
    setSurveyPageRenderControl((prevState) => ({
      ...prevState,
      renderCount: prevState.renderCount + 1,
    }))
  }, [
    currentSurveyPage,
    allowedSurveyPages,
    surveyAnswers,
    surveyData,
    isCompletedScreenShown,
  ])

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setSurveyPageRenderControl((prevState) => ({
        ...prevState,
        canRenderSurveyPage: true,
      }))
    }, 100)

    return () => clearTimeout(timer)
  }, [surveyPageRenderControl.renderCount])

  const surveyId = getSurveyId({
    selectedSurvey: reduxStore.selectedSurvey,
    selectedScreens: reduxStore.selectedScreens,
  })

  const completeSurvey = async (surveyApiResponse) => {
    const hasSurveyBeenCompletedBefore =
      surveyData.completed_survey.status === SURVEY_STATE.COMPLETED

    const surveyHasClinician = surveyData.survey?.survey_organization?.id

    const isSurveyCompleted =
      surveyApiResponse.status === SURVEY_STATE.COMPLETED &&
      !hasSurveyBeenCompletedBefore

    const shouldSendNotification = surveyHasClinician && isSurveyCompleted

    if (shouldSendNotification) {
      await fetchCompletedSurveyNotification({
        payload: {
          survey_organization_id: surveyData.survey.survey_organization.id,
          proband_id: reduxStore.proband.probandID,
        },
      })
    }

    if (reduxStore.isOnboarding) {
      return exitSurvey({ isOnboarding: reduxStore.isOnboarding, navigation })
    }

    setIsCompletedScreenShown(true)
  }

  const saveSurveyProgress = async ({
    payload,
    nextPage = null,
    isNextPageTheLast = false,
    allowedPages = [],
  }) => {
    const response = await fetchSaveSurvey({
      payload,
      setIsFetching,
    })

    if (!response) return

    setAllowedSurveyPages(allowedPages)

    if (isNextPageTheLast) {
      completeSurvey(response)

      return
    }

    if (!nextPage) return

    setCurrentSurveyPage(nextPage)
  }

  const setSurveyTitleAndInitialStates = (surveyApiResponse) => {
    if (!surveyApiResponse) return

    navigation.setParams({
      title: surveyApiResponse?.survey?.name,
    })

    setSurveyData(surveyApiResponse)
    setSurveyAnswers(surveyApiResponse.completed_survey_answers)

    const initialAllowedPages = handleGetAllowedSurveyPages({
      surveyApiResponse,
      callback: setAllowedSurveyPages,
    })

    const currentPage = calculateCurrentPage({
      currentPage: surveyApiResponse?.completed_survey?.page_num ?? 1,
      allowedSurveyPages: initialAllowedPages,
    })

    const isSurveyCompleted =
      surveyApiResponse?.completed_survey?.status === SURVEY_STATE.COMPLETED

    const isCurrentPageMajorThanLastPage =
      currentPage >= initialAllowedPages[initialAllowedPages.length - 1]

    const currentPageValue =
      reduxStore.isOnboarding && isCurrentPageMajorThanLastPage
        ? 1
        : currentPage

    setCurrentSurveyPage(currentPageValue)

    if (!isSurveyCompleted || reduxStore.isOnboarding) return

    setIsCompletedScreenShown(isSurveyCompleted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const submitSurveyAnswersCallback = async (answers, master_question_id) => {
    if (!surveyAnswers) return

    setSurveyAnswers(answers)

    const shouldSendNotification =
      master_question_id &&
      surveyData?.completed_survey?.status === SURVEY_STATE.COMPLETED

    if (!shouldSendNotification) return

    await fetchSendClinicianNotification({
      payload: {
        master_question_id,
        proband_id: surveyData.proband.probandID,
      },
    })
  }

  const customBackAction = () => {
    if (!reduxStore.isOnboarding) {
      navigation.popToTop()

      return
    }

    const { dispatchPreviousRoute } = handleNavigateToPreviousScreen(
      CUSTOM_FLOW_ROUTES.SurveyCustom.name
    )

    dispatchPreviousRoute()

    navigation.pop()
  }

  const configureSurveyBackClick = () => {
    const isSurveyBoundaryBackClickReached =
      currentSurveyPage <= 1 ||
      (!reduxStore.isOnboarding && isCompletedScreenShown)

    if (isSurveyBoundaryBackClickReached) {
      navigation.setParams({
        customAction: () => customBackAction(),
      })

      return
    }

    const newPreviousPage = calculatePreviousPage({
      currentPage: currentSurveyPage,
      allowedPages: allowedSurveyPages,
    })

    navigation.setParams({
      customAction: async () => {
        setCurrentSurveyPage(newPreviousPage)

        await saveSurveyProgress({
          payload: {
            member_id: reduxStore.proband.probandID,
            survey_id: surveyId,
            status: SURVEY_STATE.IN_PROGRESS,
            page_num: newPreviousPage,
          },
          nextPage: null,
          isNextPageTheLast: false,
        })
      },
    })
  }

  const submitSurveyResponse = async (payload) => {
    payload.member_id = surveyData.proband.id

    if (typeof payload.answer === 'string') {
      payload.answer = payload.answer.trim()
    }

    const answersMatchingPayloadQuestion = surveyAnswers.filter(
      (answer) =>
        parseInt(payload.master_question_id, 10) ===
        parseInt(answer.master_question_id, 10)
    )

    const isSelectAllThatApplyField =
      payload?.field_type === 'select_all_that_apply'

    if (isSelectAllThatApplyField) {
      await handleSelectAllThatApply({
        payload,
        matchingAnswers: answersMatchingPayloadQuestion,
        callback: submitSurveyAnswersCallback,
      })
      return
    }

    const hasAnswerBeenDeletedByHandler =
      await handleConditionalSurveyAnswerDeletion({
        payload,
        matchingAnswers: answersMatchingPayloadQuestion,
        callback: submitSurveyAnswersCallback,
      })

    if (hasAnswerBeenDeletedByHandler) return

    await handleMatchingAnswers({
      answers: answersMatchingPayloadQuestion,
      payload,
      callback: submitSurveyAnswersCallback,
    })
  }

  const mapSurveyAnswersToSurveyResponse = async () => {
    if (fieldSurveyAnswers.current.length === 0) return

    try {
      await Promise.all(
        fieldSurveyAnswers.current.map(async (answer) => {
          await submitSurveyResponse(answer)
        })
      )
    } catch (error) {
      console.error('Error submitting survey responses:', error)
    }
  }

  const getUpdatedAllowedPages = async () => {
    const surveyApiResponse = await fetchSurvey({
      proband: reduxStore?.proband,
      surveyId,
      setIsFetching,
    })

    const allowedPages = handleGetAllowedSurveyPages({
      surveyApiResponse,
      callback: () => null,
    })

    return allowedPages
  }

  const nextPageHandler = async (nextPage) => {
    setSurveyPageRenderControl((prevState) => ({
      ...prevState,
      canRenderSurveyPage: false,
    }))

    await mapSurveyAnswersToSurveyResponse()

    const updatedAllowedSurveyPages = await getUpdatedAllowedPages()
    const isLastPage =
      nextPage > updatedAllowedSurveyPages[updatedAllowedSurveyPages.length - 1]

    const newNextPage = calculateCurrentPage({
      currentPage: nextPage,
      allowedPages: updatedAllowedSurveyPages,
    })

    await saveSurveyProgress({
      payload: {
        member_id: reduxStore.proband.probandID,
        survey_id: surveyId,
        status: isLastPage ? SURVEY_STATE.COMPLETED : SURVEY_STATE.IN_PROGRESS,
        page_num: newNextPage,
      },
      nextPage: newNextPage,
      isNextPageTheLast: isLastPage,
      allowedPages: updatedAllowedSurveyPages,
    })

    fieldSurveyAnswers.current = []
  }

  const deleteSurveyAnswer = async (answer) => {
    await fetchDeleteSurveyAnswer({
      payload: {
        id: answer.id,
        master_question_id: answer.master_question_id,
      },
      callback: submitSurveyAnswersCallback,
    })
  }

  const storeFieldSurveyAnswers = (answers) => {
    const fieldType = answers.field_type
    let existingIndex

    if (fieldType === 'select_all_that_apply') {
      existingIndex = fieldSurveyAnswers.current.findIndex(
        (answer) =>
          answer.master_question_id === answers.master_question_id &&
          answers.master_question_choice_id === answer.master_question_choice_id
      )
    } else {
      existingIndex = fieldSurveyAnswers.current.findIndex(
        (answer) => answer.master_question_id === answers.master_question_id
      )
    }

    if (existingIndex !== -1) {
      fieldSurveyAnswers.current[existingIndex] = answers
      return
    }
    fieldSurveyAnswers.current.push(answers)
  }

  const handleEditCompletedSurvey = () => {
    const currentPage = calculateCurrentPage({
      currentPage: 1,
      allowedPages: allowedSurveyPages,
    })

    setCurrentSurveyPage(currentPage)
    setIsCompletedScreenShown(false)
  }

  const getOnLoadSurveyAnswers = (answerList) => {
    const results = []

    surveyAnswers.forEach((obj2) => {
      answerList.child_questionnaire.forEach((child) => {
        if (obj2.master_question_id === child.master_question.id) {
          const matchingChoice =
            child.master_question.master_question_choices.find(
              (choice) => choice.id === obj2.master_question_choice_id
            )
          if (matchingChoice) {
            results.push({
              field_type: child.master_question.type,
              master_question_id: obj2.master_question_id,
              master_question_choice_id: obj2.master_question_choice_id,
              answer: obj2.answer,
            })
          }
        }
      })
    })

    return results
  }

  const deleteAnswerHandler = (answerList) => {
    if (!answerList && !surveyAnswers) return

    const results = getOnLoadSurveyAnswers(answerList)

    if (results.length === 0) return

    const commonFieldType = results[0].field_type
    const commonMasterQuestionId = results[0].master_question_id

    fieldSurveyAnswers.current.forEach((item) => {
      if (
        item.field_type === commonFieldType &&
        item.master_question_id === commonMasterQuestionId
      ) {
        const match = results.find(
          (obj2Item) =>
            obj2Item.master_question_choice_id ===
            item.master_question_choice_id
        )
        if (match) {
          item.shouldDeleteExistingItem = true
          item.shouldSubmitAnswer = false
        } else {
          item.shouldDeleteExistingItem = false
          item.shouldSubmitAnswer = false
        }
      }
    })
  }

  const savePreviousAnswer = (answer) => {
    previousAnswerHolder.current = answer
  }

  const _renderingHandler = () => {
    const isSurveyNotFound = surveyData?.id === null

    if (
      isSurveyNotFound ||
      !surveyPageRenderControl.canRenderSurveyPage ||
      isFetching
    )
      return <LoadingView />

    if (isCompletedScreenShown && !reduxStore.isOnboarding) {
      return (
        <FlexContainer style={styles.innerContainer}>
          <SurveyCompleteScreen
            colorSettings={reduxStore.colorSettings}
            handleEditCompletedSurvey={handleEditCompletedSurvey}
            onExitSurveyClicked={() =>
              exitSurvey({ isOnboarding: reduxStore.isOnboarding, navigation })
            }
            areButtonsShown={!reduxStore.isOnboarding}
          />
        </FlexContainer>
      )
    }

    return (
      <SurveyPageGenerator
        surveyData={surveyData}
        currentSurveyPage={currentSurveyPage}
        allowedSurveyPages={allowedSurveyPages}
        surveyAnswers={surveyAnswers}
        submitSurveyResponse={submitSurveyResponse}
        storeFieldSurveyAnswers={storeFieldSurveyAnswers}
        deleteSurveyAnswer={deleteSurveyAnswer}
        nextPageHandler={nextPageHandler}
        isOnboarding={reduxStore.isOnboarding}
        deleteAnswer={deleteAnswerHandler}
        previousAnswerHolder={savePreviousAnswer}
      />
    )
  }

  const fetchSurveyData = async () => {
    const response = await fetchSurvey({
      proband: reduxStore?.proband,
      surveyId,
      setIsFetching,
    })

    setSurveyTitleAndInitialStates(response)
  }

  const saveLastVisitedScreen = async () => {
    await saveLastScreen({
      account: reduxStore.account,
      authCredentials: reduxStore.authCredentials,
      item: {
        last_screen: RAW_LAST_VISITED_SCREENS.survey_custom,
      },
      saveError: reduxActions.saveError,
      navigation,
    })
  }

  useEffect(() => {
    configureSurveyBackClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSurveyPage, isCompletedScreenShown])

  useEffect(() => {
    fetchSurveyData()

    if (!reduxStore.isOnboarding) return

    saveLastVisitedScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ErrorBoundary>
      <MainWrapper navigation={navigation}>
        <KeyboardAvoidingView
          behavior={IS_ANDROID ? 'height' : 'padding'}
          style={styles.innerContainer}
          enabled
        >
          <SafeAreaView
            style={styles.safeAreaContainer(reduxStore.colorSettings)}
          >
            {_renderingHandler()}
          </SafeAreaView>
        </KeyboardAvoidingView>
      </MainWrapper>
    </ErrorBoundary>
  )
}

export default CustomInputScreen
