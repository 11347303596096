import { useReduxDevToolsExtension } from '@react-navigation/devtools'
import { NavigationContainer } from '@react-navigation/native'

import 'react-native-polyfill'
import React from 'react'
import * as Linking from 'expo-linking'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import * as Sentry from 'sentry-expo'

import ErrorBoundary from 'react-native-error-boundary'
import { store, persistor } from '$redux/configureStore'
import AppDelegate from '$screens/AppDelegate/AppDelegate.js'

import urlLinkingConfig from '$navigation/_utils/urlLinking/urlLinkingConfig'
import LoadingViewWrapper from './wrappers/LoadingViewWrapper/LoadingViewWrapper'
import NavigationStateManagement from './wrappers/NavigationStateManagement'

//* Disable console error and warn to be able to see just console.logs and make debugging easier
if (
  (__DEV__ || process.env.NODE_ENV === 'development') &&
  process.env?.APP_MANIFEST?.extra?.SHOW_ONLY_CONSOLE_LOGS == 'true'
) {
  console.error = () => {}
  console.warn = () => {}
  console.info = () => {}
}

Sentry.init({
  dsn: 'https://fe36f8b0ffc4495296f5c33842603321@o4504572083175424.ingest.sentry.io/4504645335842816',
  enableInExpoDevelopment: false,
  debug: false,
  // /* change this configurations when doing builds */
  // environment: 'staging',
  // release: 'DEVELOPMENT',
  // dist: '1',
  // /* end */
  // beforeBreadcrumb(breadcrumb) {
  //   if (breadcrumb.category === 'console') {
  //     return null
  //   }
  //   return breadcrumb
  // },
})

const prefix = Linking.createURL('/')
export const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1'

function App() {
  const navigationRef = React.useRef(null)

  const linking = {
    prefixes: [prefix],
    config: urlLinkingConfig,
  }

  useReduxDevToolsExtension(navigationRef)

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <NavigationContainer
          ref={navigationRef}
          linking={linking}
          documentTitle={{ enabled: false }}
        >
          <PersistGate loading={null} persistor={persistor}>
            <NavigationStateManagement navigationRef={navigationRef}>
              <SafeAreaProvider>
                <LoadingViewWrapper>
                  <AppDelegate />
                </LoadingViewWrapper>
              </SafeAreaProvider>
            </NavigationStateManagement>
          </PersistGate>
        </NavigationContainer>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
