import { Alert } from 'react-native'

import { relationshipTypes } from '$constants'
import { apiFetchHandler } from '$api'
import { i18n } from '$localization/config.js'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils'

export const requestAddRelative = async (
  familyMember,
  authCredentials,
  saveError,
  setSectionListRefreshing
) => {
  const { accessToken } = authCredentials
  const payload = familyMember

  if (!payload?.member_id) return

  const relationshipToProband = payload?.relationship_to_proband

  switch (relationshipToProband) {
    case relationshipTypes.PARTNER: {
      const addPartnerPayload = {
        path: 'member/add_partner/',
        method: 'post',
        token: accessToken,
        body: { ...payload },
        pageDetails: {
          page: 'FamilyMainController.js',
        },
      }

      const addPartnerResponse = await apiFetchHandler(addPartnerPayload)

      if (addPartnerResponse.isError) {
        setSectionListRefreshing(false)
        saveError({
          isShown: true,
          status: addPartnerResponse.status,
          message: addPartnerResponse.message,
        })
        return
      }

      return {
        ...addPartnerResponse,
        relationshipType: relationshipTypes.PARTNER,
      }
    }

    case relationshipTypes.SON:
    case relationshipTypes.DAUGHTER: {
      const addChildPayload = {
        path: 'member/add_child/',
        method: 'post',
        token: accessToken,
        body: { ...payload },
        pageDetails: {
          page: 'FamilyMainController.js',
        },
      }

      const addChildResponse = await apiFetchHandler(addChildPayload)

      if (addChildResponse.isError) {
        setSectionListRefreshing(false)
        saveError({
          isShown: true,
          status: addChildResponse.status,
          message: addChildResponse.message,
        })
        return
      }

      return {
        ...addChildResponse,
        relationshipType: relationshipToProband,
      }
    }

    case relationshipTypes.PREGNANCY:
      Alert.alert(
        i18n.t('add_pregnancy')?.default,
        i18n.t('coming_soon')?.default,
        [
          {
            text: i18n.t('okay').title,
            style: 'cancel',
            onPress: () => this.setState({ showLoadingView: false }),
          },
        ]
      )
      break

    case relationshipTypes.BROTHER:
    case relationshipTypes.SISTER: {
      // API request to add sibling
      const addSiblingPayload = {
        path: 'member/add_sibling/',
        method: 'post',
        token: accessToken,
        body: { ...payload },
        pageDetails: {
          page: 'FamilyMainController.js',
        },
      }

      const addSiblingResponse = await apiFetchHandler(addSiblingPayload)

      if (addSiblingResponse.isError) {
        setSectionListRefreshing(false)
        saveError({
          isShown: true,
          status: addSiblingResponse.status,
          message: addSiblingResponse.message,
        })
        return
      }

      return {
        ...addSiblingResponse,
        relationshipType: relationshipToProband,
      }
    }

    case relationshipTypes.PARENT:
    case relationshipTypes.FATHER:
    case relationshipTypes.MOTHER: {
      const addParentsPayload = {
        path: 'member/add_parents/',
        method: 'post',
        token: accessToken,
        body: { ...payload },
        pageDetails: {
          page: 'FamilyMainController.js',
        },
      }

      const addParentsResponse = await apiFetchHandler(addParentsPayload)

      if (addParentsResponse.isError) {
        setSectionListRefreshing(false)
        saveError({
          isShown: true,
          status: addParentsResponse.status,
          message: addParentsResponse.message,
        })
        return
      }

      return {
        ...addParentsResponse,
        relationshipType: relationshipToProband,
      }
    }

    case relationshipTypes.UNCLE:
    case relationshipTypes.AUNT: {
      const addAuntUnclePayload = {
        path: 'member/add_aunt_uncle/',
        method: 'post',
        token: accessToken,
        body: { ...payload },
        pageDetails: {
          page: 'FamilyMainController.js',
        },
      }
      const addAuntUncleResponse = await apiFetchHandler(addAuntUnclePayload)
      if (addAuntUncleResponse.isError) {
        setSectionListRefreshing(false)
        saveError({
          isShown: true,
          status: addAuntUncleResponse.status,
          message: addAuntUncleResponse.message,
        })
        return
      }

      return {
        ...addAuntUncleResponse,
        relationshipType: relationshipToProband,
      }
    }

    default:
      showDefaultAlert(
        'Error 400',
        'requestAddRelative(): Unknown relationship type'
      )
      break
  }
}
