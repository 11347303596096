import { StyleSheet } from 'react-native'
import { STYLE_CONSTANTS } from './constants'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  buttonContainer: {
    flex: 1,
    minWidth: STYLE_CONSTANTS.BUTTON_CONTAINER_MIN_WIDTH,
  },
  buttonText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: STYLE_CONSTANTS.FONT_SIZE,
    lineHeight: STYLE_CONSTANTS.LINE_HEIGHT,
    marginHorizontal: STYLE_CONSTANTS.MARGIN_HORIZONTAL,
    color:
      colorSettings?.selectable_btn_text_active_1 ||
      STYLE_CONSTANTS.BLACK_COLOR,
  }),
  textContainer: (screenWidth) => ({
    flex: 1,
    minWidth:
      screenWidth < STYLE_CONSTANTS.SMALL_SCREEN_WIDTH
        ? STYLE_CONSTANTS.SMALL_SCREEN_MIN_WIDTH
        : STYLE_CONSTANTS.LARGE_SCREEN_MIN_WIDTH,
  }),
  emptyContainer: {
    flex: 1,
  },
  switchContainer: {
    width: '100%',
    height: STYLE_CONSTANTS.SWITCH_CONTAINER_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default styles
