import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import styles from '../styles'
import { DOMAIN_CLIENT_NAME } from '$utils/globalVariables.js'
import { i18n } from '$localization/config'

function HeaderText({ hasUserPassword }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const headerTextWithPassword = hasUserPassword
    ? 'log_in_with_your_existing_username_and_password_at_url_or_with_the_client_name_ios_or_android_apps_access_all_the_client_name_features_like_updating_your_family_health_history_and_sharing_with_other_family_members_or_clinicians.default'
    : 'create_a_password_so_you_can_log_in_at_url_or_with_the_client_name_ios_or_android_apps_access_all_the_client_name_features_like_updating_your_family_health_history_and_sharing_with_other_family_members_or_clinicians.default'
  const BASE_URL = window?.location?.origin ?? 'https://account.famgenix.com'

  return (
    <View style={styles.switchToFGAccountHeaderTextContainer}>
      <Text style={styles.switchToFGAccountHeaderText(colorSettings)}>
        {i18n.t(headerTextWithPassword, {
          baseURL: BASE_URL,
          clientName: DOMAIN_CLIENT_NAME,
        })}
      </Text>
    </View>
  )
}

export default HeaderText
