import { Text, View } from 'react-native'
import styles from '../../styles'

function VersionBuild({ versionBuildText }) {
  return (
    <View style={styles.appInfoRightContent}>
      <Text style={styles.appInfoVersion}>{versionBuildText}</Text>
    </View>
  )
}

export default VersionBuild
