import apiFetchHandler from '$api/apiFetchHandler'

const filterGeneList = ({ memberGeneTest, GENE_TEST_TYPE }) =>
  memberGeneTest.member_genetic_testing.filter(
    (geneTest) => geneTest.result === GENE_TEST_TYPE
  )

const handleGeneTest = ({
  filteredMemberGeneList,
  handleSelectedGeneTest,
  setOnLoadSelectedGenes,
}) => {
  const loadedGeneTest = []

  filteredMemberGeneList.forEach((item) => {
    const payload = {
      CUI: item.umls_id,
      Name: item.umls_name,
      member_gene_id: item.id,
    }
    loadedGeneTest.push(payload)
    handleSelectedGeneTest(payload)
  })

  setOnLoadSelectedGenes(loadedGeneTest)
}

const fetchOnboardingMemberDetails = async ({
  account,
  authCredentials,
  saveError,
}) => {
  const getOnboardingDetailsPayload = {
    path: `onboarding/${account.accountID}/get_info/`,
    method: 'get',
    token: authCredentials.accessToken,
    pageDetails: {
      page: 'PositiveGeneTestOnboarding.js',
    },
  }

  const onboardingMemberDetails = await apiFetchHandler(
    getOnboardingDetailsPayload
  )

  if (onboardingMemberDetails?.isError) {
    saveError({
      isShown: true,
      status: onboardingMemberDetails.status,
      message: onboardingMemberDetails.error,
    })

    return null
  }

  return onboardingMemberDetails
}

const fetchMemberProfile = async ({
  authCredentials,
  probandProfile,
  selectedLanguage,
  saveError,
}) => {
  const getMemberProfilePayload = {
    path: 'member/get/',
    method: 'post',
    token: authCredentials.accessToken,
    body: {
      proband_id: probandProfile?.memberID,
      member_id: probandProfile?.memberID,
      lang: selectedLanguage,
    },
    pageDetails: {
      page: 'PositiveGeneTestOnboarding.js',
    },
  }

  const memberGeneTest = await apiFetchHandler(getMemberProfilePayload)

  if (memberGeneTest?.isError) {
    saveError({
      isShown: true,
      status: memberGeneTest.status,
      message: memberGeneTest.error,
    })

    return false
  }

  return memberGeneTest
}

const fetchDetails = async ({
  fetchMemberProfilePayload,
  fetchOnboardingMemberDetailsPayload,
}) => {
  const onboardingMemberDetails = await fetchOnboardingMemberDetails(
    fetchOnboardingMemberDetailsPayload
  )
  const memberGeneTest = await fetchMemberProfile(fetchMemberProfilePayload)

  return { onboardingMemberDetails, memberGeneTest }
}

export { filterGeneList, handleGeneTest, fetchDetails }
