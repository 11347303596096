export default function calculateYearDifference({
  currentYear,
  birthYear,
  currentDate,
  birthDate,
}) {
  const isBeforeBirthDate =
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())

  const yearElapsed = currentYear - birthYear - (isBeforeBirthDate ? 1 : 0)

  return yearElapsed
}
