import { StyleSheet } from 'react-native'

const colors = {
  darkBackground: 'rgba(0,0,0,0.8)',
  lightBackground: '#fff',
  blackColorBackground: '#000',
  darkColor: 'dark',
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: colors.darkBackground,
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  pickerContainer: (getColorScheme) => ({
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor:
      getColorScheme() === colors.darkColor
        ? colors.darkBackground
        : colors.lightBackground,
  }),
  toolbarContainer: {
    backgroundColor: colors.lightBackground,
    flexDirection: 'row',
    height: 45,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.darkBackground,
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
  },
  toolbarButton: {
    height: '100%',
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 20,
  },
  toolbarButtonText: {
    color: colors.blackColorBackground,
    fontFamily: 'montserrat-semibold',
    fontSize: 15,
    textAlign: 'center',
  },
  toolbarButtonTextDone: (colorSettings) => ({
    color:
      colorSettings?.bottom_next_btn_enabled || colors.blackColorBackground,
    fontFamily: 'montserrat-semibold',
    fontSize: 15,
    textAlign: 'center',
  }),
  modalContainer: {
    flex: 1.0,
  },
  titleStyle: {
    flex: 60,
    fontSize: 15,
  },
})

export default styles
