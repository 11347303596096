import { StyleSheet } from 'react-native'
import { IS_WEB } from '$utils/Platforms.js'

const styles = StyleSheet.create({
  logoContainer: {
    height: '20%',
    marginTop: 50,
    alignItems: 'center',
    marginBottom: IS_WEB() ? 50 : 0,
  },
})

export default styles
