import { Text, TouchableOpacity } from 'react-native'

import styles from './styles'

export default function ContinueButton({
  nextButtonAction,
  colorSettings,
  continueText,
}) {
  return (
    <TouchableOpacity
      style={styles.nextButton(colorSettings)}
      onPress={nextButtonAction}
    >
      <Text style={styles.nextButtonText(colorSettings)}>{continueText}</Text>
    </TouchableOpacity>
  )
}
