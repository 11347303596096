const LIGHT_GREY_COLOR = 'rgba(74, 74, 74, 0.1)'
const TRANSPATENT = 'transparent'
const BLACK = 'black'
const SIGNUP_GENDER_SELECTION_TILE = 'signup_gender_selection_tile'
const MALE = 'm'
const FEMALE = 'f'

export {
  LIGHT_GREY_COLOR,
  TRANSPATENT,
  BLACK,
  SIGNUP_GENDER_SELECTION_TILE,
  MALE,
  FEMALE,
}
