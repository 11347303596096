// ** Main Imports
import React from 'react'
import { View, Text, FlatList } from 'react-native'

// ** Styles Imports
import styles from './styles'

// ** Components Imports
import SurveyButtonComponent from '$screens/main/survey/survey_screen_components/SurveyButtonComponent.js'

export default function RenderClinician({
  clinician,
  colorSettings,
  navigateToScreen,
}) {
  if (!clinician?.clinician_list?.length) return null

  const renderSurveyItem = ({ item }) => {
    const onPressItem = () => {
      navigateToScreen(
        item?.navItem,
        item?.dataItems,
        item?.isCustomSurvey,
        item
      )
    }

    return (
      <SurveyButtonComponent
        key={`${item?.id}-<SurveyButtonComponent>`}
        colorSettings={colorSettings}
        onPressItem={onPressItem}
        buttonTitle={item?.buttonTitle}
        buttonIcon={item?.buttonIcon}
        navItem={item?.navItem}
        dataItems={item?.dataItems}
        isCustomSurvey={item?.isCustomSurvey}
      />
    )
  }

  return (
    <View>
      {clinician?.clinician_header && (
        <Text style={styles.sectionTitleText(colorSettings)}>
          {clinician?.clinician_header}
        </Text>
      )}

      {clinician?.clinician_subheader && (
        <Text style={styles.sectionSubTitleText(colorSettings)}>
          {clinician?.clinician_subheader}
        </Text>
      )}

      <FlatList
        style={styles.clinicianCard}
        data={clinician?.clinician_list}
        renderItem={renderSurveyItem}
      />
    </View>
  )
}
