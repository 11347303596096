export default function timeStampDifference(date1, date2) {
  const timeStamp1 = new Date(date1)
  const timeStamp2 = new Date(date2)

  // Calculate the time difference in milliseconds
  const timeDifferenceMs = timeStamp2 - timeStamp1

  // Convert milliseconds to seconds, minutes, hours, etc., as needed
  const seconds = Math.floor(timeDifferenceMs / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  return { seconds, minutes, hours, days }
}
