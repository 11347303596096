import { StyleSheet } from 'react-native'

import { FALLBACK_STYLES } from './constants'

const styles = StyleSheet.create({
  successScreencontainer: (colorSettings) => ({
    flex: 1.0,
    backgroundColor:
      colorSettings?.success_screen_account_bg ?? FALLBACK_STYLES.WHITE_COLOR,
  }),
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
  },
})

export default styles
