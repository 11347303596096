export default function formatAge(age, format) {
  let daysToSubtract

  switch (format) {
    case 'days':
      daysToSubtract = age
      break
    case 'weeks':
      daysToSubtract = age * 7
      break
    case 'months':
      daysToSubtract = age * 30 // Approximate value
      break
    case 'years':
      daysToSubtract = age * 365 // Approximate value
      break
    default:
      throw new Error(
        'Invalid age type. Please use "days", "weeks", "months", or "years".'
      )
  }

  const resultDate = new Date()
  resultDate.setDate(resultDate.getDate() - daysToSubtract)
  return resultDate
}
