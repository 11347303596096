import { Component } from 'react'

import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  FlatList,
  SafeAreaView,
  Modal,
} from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import { envConstants } from '$constants'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$components/LoadingView'
import RelativeGeneTestButton from './relative_gene_test_components/RelativeGeneTestButton.js'

import { getLanguage } from '$utils'
import { apiFetchHandler } from '$api'

import Account from '$data_models/Account'
import Proband from '$data_models/Proband'
import MemberProfile from '$data_models/MemberProfile'
import GeneTesting from '$data_models/GeneTesting'
import AccountGeneTesting from '$data_models/AccountGeneTesting.js'

import {
  getStackRouterRoot,
  getCurrentAppContainer,
  showDefaultAlert,
  showGenericServerError,
  handleDefaultError,
} from '$navigation/_utils/NavigationUtils.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles from '$assets/styles/global.styles.js'
import styles from '$screens/personal_details/relative_gene_test/_styles/main.styles.js'

class RelativePositiveGeneTest extends Component {
  colorSettings = this.props.colorSettings

  currentMemberID = this.props.route.params?.currentMemberID ?? false

  proband = this.props.route.params?.proband ?? new Proband()

  onDismiss = this.props.route.params?.onDismiss ?? ''

  individualScreen = this.props.route.params?.individualScreen ?? false

  finalGenelist = ''

  geneListStorage = []

  storageCount = ''

  showNegativeTest = envConstants.SHOW_NEGATIVE_TEST == 'true'

  localRefs = {}

  tempToken = {
    accessToken: this.proband?.account?.authCredentials?.accessToken,
  }

  memberdetails = {
    proband_id: this.proband.probandID,
    member_id: this.currentMemberID,
  }

  state = {
    toggled: false,
    memberGeneTest: '',
    loadMemberGeneTest: true,
    geneTestList: '',
    loadGeneTestList: true,
    // showLayer1: true,
    showLayer2: '',
    // toggleAfterRender: false,
    // showGeneList: false,
    toggleAfterRender: true,
    showGeneList: true,
    modalVisible: false,
  }

  async componentDidMount() {
    const getMemberProfileResponse = await this.getMemberProfileHandler(
      this.memberdetails
    )
    const { saveError } = this.props

    if (getMemberProfileResponse.isError) {
      saveError({
        isShown: true,
        status: getMemberProfileResponse.status,
        message: getMemberProfileResponse.error,
      })
      return
    }

    this.setState({
      memberGeneTest: getMemberProfileResponse.member_genetic_testing,
      loadMemberGeneTest: false,
    })

    const geneTestingPayload = await this.getGeneListHandler()

    if (geneTestingPayload.isError) {
      saveError({
        isShown: true,
        status: geneTestingPayload.status,
        message: geneTestingPayload.error,
      })
      return
    }

    this.setState({ geneTestList: geneTestingPayload, loadGeneTestList: false })
  }

  getMemberProfileHandler = (payload) => {
    const { selectedLanguage } = this.props
    const getMemberProfilePayload = {
      path: 'member/get/',
      method: 'post',
      token: this.tempToken.accessToken,
      body: {
        ...payload,
        lang: selectedLanguage,
      },
      pageDetails: {
        page: 'RelativePositiveGeneTest.js',
      },
    }

    return apiFetchHandler(getMemberProfilePayload)
  }

  getGeneListHandler = () => {
    const getGeneTestingListPayload = {
      path: 'genetic_testing/',
      method: 'get',
      body: {
        params: {
          lang: getLanguage().toString(),
        },
      },
      pageDetails: {
        page: 'RelativePositiveGeneTest.js',
      },
    }

    return apiFetchHandler(getGeneTestingListPayload)
  }

  addToTempStorageOnLoad = () => {
    // add To temp data on Load
    const geneList = this.finalGenelist
    const tempStorage = this.geneListStorage

    const mapGeneTest = this.finalGenelist.map((item) => {
      if (item.result == 'p') {
        return { ...item }
      }
    })

    const filteredmapGeneTest = mapGeneTest.filter((item) => item != null)

    this.geneListStorage = JSON.parse(JSON.stringify(filteredmapGeneTest))
  }

  addToTempList = (data) => {
    // add to temp list
    // a. add data to list

    this.geneListStorage.push(data)

    if (this.geneListStorage.length > 0) {
      this.setState({ toggled: true })
    }
  }

  removeToTempList = (data) => {
    // a. remove data from list
    // b. if list is empty disable next button

    const list = this.geneListStorage
    for (const val in list) {
      if (data.id == list[val].id) {
        list.splice(val, 1)
      }
    }

    if (this.geneListStorage.length <= 0) {
      this.setState({ toggled: false })
    }
  }

  mapMemberGenesToGeneList = (memberGeneTest, geneTestList) => {
    // updated gene list
    const newList = geneTestList.map((item) => {
      const returnedData = this.geneTestChecker(item, memberGeneTest)
      return returnedData
    })

    // this.finalGenelist = newList
    const filteredNewList = newList.filter((item) => item != null)

    this.finalGenelist = filteredNewList
  }

  geneTestChecker = (item, memberGeneTest) => {
    if (memberGeneTest.length > 0) {
      for (const val in memberGeneTest) {
        if (memberGeneTest[val].gene_id == item.id) {
          // if (memberGeneTest[val]['result'] == 'p') {
          //   item['result'] = 'p'
          //
          //   return { ...item }
          // } else {
          //   return null
          // }

          return null
        }

        // delete item.result
        // return { ...item }
      }
    }
    return { ...item }
  }

  nextButtonAction = async () => {
    // Add items to API and navigate to next screen
    // a. add each gene test to API
    // b. navigate to next screen or dismiss

    const { navigate } = this.props.navigation
    const { saveError } = this.props

    const { geneListStorage } = this
    const storageLength = geneListStorage.length

    if (geneListStorage?.length > 0) {
      // Toggle-on loading view
      this.setState({ modalVisible: true })

      for (const val in geneListStorage) {
        const payload = {
          proband_id: this.memberdetails.proband_id,
          gene_id: geneListStorage[val].id,
          result: 'p',
        }
        const addGeneTestToMemberOnboardingPayload = {
          path: `member/${this.memberdetails.member_id}/add_genetic_testing/`,
          method: 'post',
          token: this.tempToken.accessToken,
          body: {
            proband_id: this.memberdetails.proband_id,
            gene_id: geneListStorage[val].id,
            result: 'p',
          },
          pageDetails: {
            page: '',
          },
        }

        const response = await apiFetchHandler(
          addGeneTestToMemberOnboardingPayload
        )

        if (response.isError) {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        this.storageCount++

        if (this.storageCount == storageLength) {
          // Toggle-off loading view
          this.setState({ modalVisible: false })

          if (!this.individualScreen && this.showNegativeTest) {
            navigate('RelativeNegativeGeneTestScreen', {
              proband: this.proband,
              account: this.account,
              onDismiss: this.onDismiss,
              currentMemberID: this.currentMemberID,
            })
          } else {
            this.props.navigation.dismiss()
            this.onDismiss()
          }
        }
      }
    } else if (!this.individualScreen && this.showNegativeTest) {
      navigate('RelativeNegativeGeneTestScreen', {
        proband: this.proband,
        account: this.account,
        onDismiss: this.onDismiss,
        currentMemberID: this.currentMemberID,
      })
    } else {
      this.props.navigation.dismiss()
      this.onDismiss()
    }
  }

  renderList = (layerName, refName) => (
    <View style={{ width: '100%' }}>
      <Text
        style={[
          styles.titleText,
          {
            color: this.colorSettings?.text_input_label_color_2,
          },
        ]}
      >
        {i18n.t('did_this_person_test_positive_for_any_genes').default}
      </Text>

      <Collapse style={{ width: '100%' }} isCollapsed>
        <CollapseHeader />

        <CollapseBody>
          <Text
            style={[
              styles.titleText,
              {
                color: this.colorSettings?.text_input_label_color_2,
                fontSize: 18,
              },
            ]}
          >
            {i18n.t('select_all_that_apply').default}
          </Text>

          <View
            style={[
              styles.buttonContainerAdopted,
              {
                flexDirection: 'column',
                marginBottom: 10,
                justifyContent: 'center',
              },
            ]}
          >
            <FlatList
              style={{ width: '100%' }}
              keyExtractor={(item) => `${item.id}`}
              data={this.finalGenelist}
              renderItem={({ item }) => (
                <RelativeGeneTestButton
                  colorSettings={this.colorSettings}
                  ref={(ref) => (this.localRefs[`geneTest${item.id}`] = ref)}
                  parent={this}
                  id={item.id}
                  genesAPI={item}
                />
              )}
            />
          </View>
        </CollapseBody>
      </Collapse>
    </View>
  )

  nextButtonTextHandler = () => {
    // TODO: REFORMAT THIS CODE (IF HELL)
    if (this.state.toggled) {
      if (this.showNegativeTest) {
        if (this.individualScreen) {
          return i18n.t('done').title
        }
        return i18n.t('next').title
      }
      return i18n.t('done').title
    }
    return i18n.t('skip').title
  }

  render() {
    const {
      memberGeneTest,
      loadMemberGeneTest,
      geneTestList,
      loadGeneTestList,
      toggleAfterRender,
    } = this.state

    if (!loadMemberGeneTest && !loadGeneTestList) {
      this.mapMemberGenesToGeneList(
        memberGeneTest,
        JSON.parse(JSON.stringify(geneTestList))
      )

      if (!toggleAfterRender) {
        this.addToTempStorageOnLoad()
      }

      return (
        <SafeAreaView
          style={[
            global_styles.container,
            {
              backgroundColor: this.colorSettings?.onboarding_bgcolor,
            },
          ]}
        >
          <ScrollView contentContainerStyle={styles.contentContainer}>
            {this.renderList()}
          </ScrollView>

          <View
            style={{
              width: '100%',
              alignSelf: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <View style={{ height: 60, width: '100%' }}>
              <TouchableOpacity
                style={[
                  global_styles.nextButton,
                  {
                    backgroundColor:
                      (this.state.toggled
                        ? this.colorSettings?.bottom_next_btn_enabled
                        : this.colorSettings?.bottom_next_btn_disabled) ||
                      'white',
                    borderColor: this.colorSettings?.bottom_next_btn_enabled,
                  },
                ]}
                onPress={this.nextButtonAction}
              >
                <Text
                  style={[
                    global_styles.nextButtonText,
                    {
                      color: this.colorSettings?.btn_filled_text_1,
                    },
                  ]}
                >
                  {this.nextButtonTextHandler()}
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <Modal
            visible={this.state.modalVisible}
            animationType="none"
            transparent
          >
            <View
              style={{
                flex: 1.0,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingView
                backgroundColor={this.colorSettings?.onboarding_bgcolor}
                tintColor={this.colorSettings?.btn_no_fill_text_2}
                textColor={this.colorSettings?.btn_no_fill_text_2}
                visible={this.state.modalVisible}
                message={i18n.t('saving_datanplease_wait').default}
              />
            </View>
          </Modal>
        </SafeAreaView>
      )
    }
    return (
      <SafeAreaView
        style={[
          global_styles.container,
          {
            backgroundColor: this.colorSettings?.onboarding_bgcolor,
          },
        ]}
      >
        <LoadingView
          backgroundColor={this.colorSettings?.onboarding_bgcolor}
          tintColor={this.colorSettings?.btn_no_fill_text_2}
          textColor={this.colorSettings?.btn_no_fill_text_2}
          visible
        />
      </SafeAreaView>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    selectedLanguage: store.selectedLanguage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(RelativePositiveGeneTest)
