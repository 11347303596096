import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  contentContainer: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    width: '105%',
  },
  subQuestionTitle: {
    textAlign: 'center',
    marginBottom: 20,
    fontFamily: 'karla-bold',
    fontSize: 24,
  },
  toolTipImage: { width: 20, height: 20 },
  toolTipButton: { alignSelf: 'center' },
  buttonToggleCustomStyle: { marginBottom: 40 },
  autocompleteInput: {
    borderRadius: 20,
  },
  chipContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15,
    flexWrap: 'wrap',
    minHeight: 50,
  },
  chipWrapContainer: {
    margin: 5,
  },
  chipText: (colorSettings) => ({
    paddingLeft: 10,
    color: colorSettings?.pill_textcolor || 'white',
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'montserrat',
  }),
  autocompleteContainer: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  autocompleteInputContainer: (colorSettings) => ({
    borderRadius: 40,
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    height: 60,
    borderWidth: 0,
    marginBottom: 5,
    marginHorizontal: 18,
    backgroundColor: colorSettings.selectable_btn_border_inactive_1 || 'black',
  }),
  textInputContainerCentered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  autocompleteTextInput: (colorSettings) => ({
    height: 50,
    paddingLeft: 5,
    fontSize: 16,
    fontWeight: '500',
    width: '80%',
    paddingHorizontal: 18,
    color: colorSettings?.text_input_label_color_2 || 'black',
  }),
  autocompleteList: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    display: 'flex',
    position: 'relative',
  },
  autocompleteListButtons: (item, colorSettings) => ({
    borderRadius: 40,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    height: 50,
    width: '85%',
    borderWidth: 1,
    marginBottom: 5,
    alignSelf: 'center',
    backgroundColor: item.isSelected
      ? colorSettings.selectable_btn_active_1 || 'white'
      : colorSettings.selectable_btn_inactive_1 || 'black',
    borderColor: colorSettings.selectable_btn_border_inactive_1 || 'black',
  }),
  autocompleteListButtonText: (colorSettings) => ({
    height: 50,
    fontSize: 16,
    paddingTop: 5,
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colorSettings?.text_input_label_color_2 || 'black',
  }),
})

export default styles
