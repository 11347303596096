import React from 'react'
import { View, Text } from 'react-native'

export default function FieldViewer({ fieldName }) {
  const show = false

  if (show) {
    return <Text>{fieldName}</Text>
  }

  return <View />
}
