import { View, Text, TouchableOpacity, FlatList, Platform } from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'
import { platforms } from '$constants'

import BaseController from '$components/BaseController.js'

import LoadingView from '$components/LoadingView'

import { apiFetchHandler } from '$api'

import { getParentBloodRelationTypes } from '$utils'

import { setShouldReloadParentsBloodRelation } from '$redux/member_profile/actions.js'
import { setError } from '$redux/defaults/actions.js'

import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import custom_styles from './_styles/custom.styles.js'
import MainWrapper from '$components/MainWrapper'

const RelationshipTypes = getParentBloodRelationTypes()
const RELATIONSHIP_DATA = 'relationshipData'
const PARENT_SPECIFIC = 'parentSpecific'
const RELATIONSHIP_INFO = 'relationshipInfo'
const BLOOD_RELATED_PARENTS = 'bloodRelatedParents'

const API_IS_PARENTS_BLOOD_RELATED = 'is_parent_blood_related'
const API_BLOOD_RELATION_TYPE = 'blood_relation_type'

const webHeightAdjustment = -50
const getScrollViewHeight = () =>
  calculateAvailableContentHeight(webHeightAdjustment)

class SelectParentsBloodRelation extends BaseController {
  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  proband = this.props.currentProband

  memberProfile = this.props.memberProfile

  relationshipData = this.memberProfile?.[RELATIONSHIP_DATA]

  parentSpecific = this.relationshipData?.[PARENT_SPECIFIC] ?? null

  relationshipInfo = this.parentSpecific?.[RELATIONSHIP_INFO] ?? null

  state = {
    shouldRenderComponent: false,
    showLoadingView: false,
    doneButtonDisabled: true,
    relationshipInfo: this.relationshipInfo,
    scrollViewHeight: getScrollViewHeight(),
  }

  constructor(props) {
    super(props)

    /* Set shared instance */
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    // Add listener to adjust scrollview height for Web
    this.setScrollViewHeightListenerForWeb(true)

    /* Check if there's already a selected RelationshipInfo */
    this.checkRelationshipInfo()

    setTimeout(() => {
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
    }, 300)
  }

  componentWillUnmount() {
    // Remove scrollview height listener for Web
    this.setScrollViewHeightListenerForWeb(false)
  }

  checkRelationshipInfo = () => {
    let relationshipInfo = JSON.parse(JSON.stringify(this.relationshipInfo))

    for (const e of RelationshipTypes) {
      if (relationshipInfo?.key === e.key) {
        relationshipInfo = e
        break
      }
    }

    this.setState({ relationshipInfo })
  }

  onDone = async () => {
    /* Update parents' blood relation on API */

    const probandID = this.proband?.probandID
    const memberID = this.memberProfile?.memberID

    const payload = {
      proband_id: probandID,
      member_id: memberID,
    }

    const { parentSpecific } = this
    const { relationshipInfo } = this.state

    payload[API_IS_PARENTS_BLOOD_RELATED] =
      parentSpecific?.[BLOOD_RELATED_PARENTS]

    if (relationshipInfo?.key && relationshipInfo?.key != '') {
      const bloodRelationType = relationshipInfo?.key
      payload[API_BLOOD_RELATION_TYPE] = bloodRelationType?.toLowerCase()
    } else {
      payload[API_IS_PARENTS_BLOOD_RELATED] = false
      payload[API_BLOOD_RELATION_TYPE] = null
    }

    /* Disable Done button temporarily */
    this.disableDoneButton(true)

    /* API endpoint for updating Parents' blood relation */
    const updateParentBloodRelationPayload = {
      path: 'member/update_parent_blood_relation/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'SelectParentsBloodRelation.js',
      },
    }

    const response = await apiFetchHandler(updateParentBloodRelationPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.disableDoneButton(false)
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    this.disableDoneButton(false)

    /* Trigger reload of Parents' Blood Relationship Info */
    this.props._setShouldReloadParentsBloodRelation_(true)

    /* Dismiss current component */
    this.props.navigation.pop()
    this.props.navigation.replace('EditProfileView')
  }

  disableDoneButton = (disabled = true) => {
    this.setState({
      doneButtonDisabled: disabled,
      showLoadingView: disabled,
    })
  }

  onPressItem = (item) => {
    if (!item?.id) return
    this.setState({
      doneButtonDisabled: false,
      relationshipInfo: item,
    })
  }

  /* TODO: Find out if this is still needed */
  didSelectRelationshipType = (val, reset = false) => {
    console.log(
      '🚀 \n\n file: SelectParentsBloodRelation.js:180 \n\n SelectParentsBloodRelation \n\n didSelectRelationshipType'
    )

    // TODO: Save selected RelationshipType to redux store */
  }

  render() {
    const { navigation } = this.props
    return (
      <MainWrapper navigation={navigation}>
        <View
          style={{
            width: '100%',
            flex: 1.0,
            ...Platform.select({
              [platforms.WEB]: {
                flex: null,
                height: getScrollViewHeight(),
              },
            }),
          }}
        >
          <FlatList
            nativeID="web_scaled_main_container"
            style={{
              ...Platform.select({
                [platforms.WEB]: {
                  flex: null,
                  height: getScrollViewHeight(),
                },
              }),
            }}
            data={RelationshipTypes}
            keyExtractor={(item) => `${item?.id}`}
            renderItem={({ item, index }) => {
              const selectedItem = this.state.relationshipInfo
              const relationshipType = item?.name

              let BGColor = '#ccc'
              if (selectedItem?.id != item?.id) {
                BGColor = 'white'
              }

              return (
                <View
                  style={[
                    custom_styles.sectionItemContainer,
                    {
                      backgroundColor: BGColor,
                    },
                  ]}
                >
                  <TouchableOpacity
                    style={custom_styles.sectionItemImageLess}
                    activeOpacity={1.0}
                    onPress={() => this.onPressItem(item)}
                  >
                    <Text style={custom_styles.sectionItemLabelText}>
                      {i18n.t(relationshipType).default}
                    </Text>
                  </TouchableOpacity>
                </View>
              )
            }}
          />

          <View
            style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}
          >
            <TouchableOpacity
              style={[
                global_styles.nextButton,
                this.inversedToggleNextButtonColor(
                  this.state.doneButtonDisabled,
                  this.colorSettings
                ),
              ]}
              onPress={this.onDone}
              disabled={this.state.doneButtonDisabled}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: this.colorSettings?.bottom_next_btn_text ?? 'black',
                  },
                ]}
              >
                {i18n.t('done')?.default}
              </Text>
            </TouchableOpacity>
          </View>

          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </View>
      </MainWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    currentProband: probandStore.proband,
    /** MemberProfile Store */
    memberProfile: memberProfileStore.memberProfile,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setShouldReloadParentsBloodRelation_: (data) =>
      dispatch(setShouldReloadParentsBloodRelation(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectParentsBloodRelation)
