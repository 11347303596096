import { Component } from 'react'
import { View } from 'react-native'
import { i18n } from '$localization/config.js'

import { IS_WEB, IS_MOBILE } from '$utils/Platforms.js'

import VitalStatusField from './components/VitalStatusField.js'
import DeceasedFields from './components/DeceasedFields.js'
import BirthdateField from './components/BirthdateField.js'
import BirthdateFieldWeb from './components/BirthdateField.web.js'
import CurrentAgeField from './components/CurrentAgeField.js'
import CollapseButton from '$components/CollapseButton'

import Proband from '$data_models/Proband.js'
import MemberProfile from '$data_models/MemberProfile'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import InputField from './components/InputField'
import GenderIdentity from './components/GenderIdentity'

const PROFILE_DATA = 'profileData'

class PersonalInfoFormView extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  proband = this.props.proband ?? new Proband()

  memberProfile = this.props.memberProfile ?? new MemberProfile()

  currentMember = this.props.currentMember ?? null

  bottomSheetRef = this.props.bottomSheetRef

  state = {
    currentMember: this.currentMember,
    memberProfile: this.memberProfile,
    profileData: this.memberProfile?.profileData,
    allowUserInputDOB: false,
    allowUserInputAge: false,
    fieldIsHidden: true,
  }

  constructor(props) {
    super(props)
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const { currentMember } = newProps
    const profileData = memberProfile?.profileData

    return {
      ...prevState,
      currentMember,
      memberProfile,
      profileData,
    }
  }

  _updateState_ = (key, val) => {
    /* Note: Should use referenced data from state because of changes on DatePicker. */
    const { memberProfile } = this.state
    const profileData = memberProfile?.profileData
    profileData[key] = val

    /* Update parent's state */
    this.parentController?.updateState(PROFILE_DATA, profileData)
  }

  setAllowUserInputDOB = (val = true) => {
    // Note: This is needed to manage auto formatting of DOB when state is updated.
    this.setState({ allowUserInputDOB: val })
  }

  setAllowUserInputAge = (val = true) => {
    this.setState({ allowUserInputAge: val })
  }

  toggleDatePicker = () => {
    this.parentController?.toggleDatePicker()
  }

  onDatePickerChange = ({ selectedDate, dismiss = false }) => {
    this.parentController?.onDatePickerChange({ selectedDate, dismiss })
  }

  showFieldHandler = () => {
    this.setState((prev) => ({
      fieldIsHidden: !prev.fieldIsHidden,
    }))
  }

  setSameValueAges = (value) => {
    this.parentController?.updateSameValuesAges(value)
  }

  render() {
    const {
      memberProfile,
      profileData,
      fieldIsHidden,
      allowUserInputAge,
      allowUserInputDOB,
    } = this.state

    const {
      handleGenderIdentityChange,
      bottomSheetGenderIdentityRef,
      genderIdentityValue,
      onChange,
      ageOfDeceasedType,
      handleAgeUnitChange,
      currentAgeType,
      language,
      handleWebDatepickerWithAgeUnits,
    } = this.props

    const isDeceased = profileData?.deceased ?? false

    return (
      <View style={section_styles.container}>
        <View style={section_styles.content}>
          <InputField
            colorSettings={this.colorSettings}
            placeholder={i18n.t('optional').default}
            label={i18n.t('first_name').default}
            onChangeText={this._updateState_}
            keyField="firstName"
            onChange={onChange}
            value={memberProfile?.profileData?.firstName}
            hasSeparator={false}
          />

          <VitalStatusField
            colorSettings={this.colorSettings}
            parentController={this}
            memberProfile={memberProfile}
          />

          <DeceasedFields
            colorSettings={this.colorSettings}
            parentController={this}
            memberProfile={memberProfile}
            visible={isDeceased}
            bottomSheetRef={this.bottomSheetRef}
            ageOfDeceasedType={ageOfDeceasedType}
            handleAgeUnitChange={handleAgeUnitChange}
            currentAgeType={currentAgeType}
            handleSameValueAges={this.setSameValueAges}
          />

          <CurrentAgeField
            colorSettings={this.colorSettings}
            parentController={this}
            memberProfile={memberProfile}
            visible={!isDeceased}
            allowUserInputAge={allowUserInputAge}
            setAllowUserInputAge={(val) => this.setAllowUserInputAge(val)}
            onChange={onChange}
            bottomSheetRef={this.bottomSheetRef}
            currentAgeType={currentAgeType}
            handleAgeUnitChange={handleAgeUnitChange}
          />

          {!fieldIsHidden && (
            <InputField
              colorSettings={this.colorSettings}
              placeholder={i18n.t('optional').default}
              label={i18n.t('last_name').default}
              onChangeText={this._updateState_}
              keyField="lastName"
              onChange={onChange}
              value={memberProfile?.profileData?.lastName}
            />
          )}

          {IS_MOBILE() && !fieldIsHidden && (
            <BirthdateField
              colorSettings={this.colorSettings}
              parentController={this}
              memberProfile={memberProfile}
              allowUserInputDOB={allowUserInputDOB}
              setAllowUserInputDOB={(val) => this.setAllowUserInputDOB(val)}
            />
          )}

          {IS_WEB() && !fieldIsHidden && (
            <BirthdateFieldWeb
              colorSettings={this.colorSettings}
              parentController={this}
              memberProfile={memberProfile}
              allowUserInputDOB={allowUserInputDOB}
              setAllowUserInputDOB={(val) => this.setAllowUserInputDOB(val)}
              language={language}
              handleWebDatepickerWithAgeUnits={handleWebDatepickerWithAgeUnits}
            />
          )}

          {!fieldIsHidden && (
            <GenderIdentity
              label={i18n.t('gender_identity').default}
              onChange={handleGenderIdentityChange}
              bottomSheetGenderIdentityRef={bottomSheetGenderIdentityRef}
              value={genderIdentityValue}
            />
          )}

          <CollapseButton
            colorSettings={this.colorSettings}
            fieldIsHidden={fieldIsHidden}
            showFieldHandler={this.showFieldHandler}
            arrowCollapsedPosition="up"
            dividerPosition="top"
          />
        </View>
      </View>
    )
  }
}

export default PersonalInfoFormView
