import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'
import styles from './styles'

function ForgotPasswordButton({ onPress }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const forgotPasswordText = `${i18n.t('forgot_your_password').capitalize}?`
  const forgotPasswordButtonID = 'forgot_password_button'

  return (
    <View nativeID={forgotPasswordButtonID}>
      <TouchableOpacity
        style={styles.forgotButtonBorderless}
        onPress={onPress}
        activeOpacity={0.5}
      >
        <Text style={styles.forgotPasswordButtonText(colorSettings)}>
          {forgotPasswordText}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default ForgotPasswordButton
