import { StyleSheet } from 'react-native'

const DEFAULT_PADDING_TOP = 8

const styles = StyleSheet.create({
  switchContainer: (paddingTop) => ({
    alignItems: 'flex-end',
    paddingTop: paddingTop || DEFAULT_PADDING_TOP,
  }),
})

export default styles
