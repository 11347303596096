import { Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import styles from './styles'
import { debounce } from '$utils'

const MORE = 'more'

function GeneListItem({
  item,
  handleSelectedGeneTest,
  loadUMLSGenes,
  inputValue,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const handleSelectGeneListItem = () =>
    item.CUI === MORE
      ? debounce(loadUMLSGenes(inputValue, item.CUI))
      : handleSelectedGeneTest(item)

  return (
    <TouchableOpacity
      style={styles.autocompleteListButtons(item, colorSettings)}
      onPress={handleSelectGeneListItem}
    >
      <Text style={styles.autocompleteListButtonText(colorSettings)}>
        {item.Name}
      </Text>
    </TouchableOpacity>
  )
}

export default GeneListItem
