import { i18n } from '$localization/config.js'

import { getFieldDescription, generateChangeDetails } from './helpers'

import {
  ALIVE_KEY,
  DECEASED_KEY,
  BLANK_KEY,
  NONE_KEY,
  CHANGED_COL_KEY,
  STATUS_KEY,
  ADDED_COL_KEY,
  REMOVED_COL_KEY,
} from './constants'

export default function generateNotificationBody(params) {
  const {
    updatedField,
    oldValue,
    updatedNewValue,
    updatedCodValue,
    updatedDobValue,
    updatedNewAge,
    newStatusValue,
  } = params

  // Get localized values for deceased status, cause of death, birthdate, and age
  const newDeceasedValue =
    i18n.t(
      `${newStatusValue}`
        .toLowerCase()
        .replace('false', ALIVE_KEY)
        .replace('true', DECEASED_KEY)
    )?.title || null
  const newCODValue = updatedCodValue || i18n.t(BLANK_KEY).title
  const newDOBValue = updatedDobValue || i18n.t(NONE_KEY).title
  const newAgeValue = updatedNewAge || i18n.t(NONE_KEY).title

  const STATUS_DOB_AGE_UPDATED = newDeceasedValue && newDOBValue && newAgeValue

  let notificationBody = ''

  if (STATUS_DOB_AGE_UPDATED) {
    notificationBody += `${i18n.t(CHANGED_COL_KEY).capitalize}\n${i18n
      .t(STATUS_KEY)
      .split(' ')
      .map((field) =>
        getFieldDescription(
          field,
          eval(`new${field.replace(/_/g, '').toUpperCase()}Value`)
        )
      )
      .join('\n')}`
  } else if (oldValue && updatedNewValue) {
    notificationBody += generateChangeDetails(
      updatedField,
      oldValue,
      updatedNewValue
    )
  } else if (updatedNewValue) {
    notificationBody += `${i18n.t(ADDED_COL_KEY).capitalize}\n${
      i18n.t(updatedField).capitalize
    }: ${updatedNewValue}`
  } else if (!updatedNewValue || updatedNewValue.trim() === '') {
    notificationBody += `${i18n.t(REMOVED_COL_KEY).capitalize} ${
      i18n.t(updatedField).default
    }`
  }

  return notificationBody
}
