const CHEVRON_DOWN = 'chevron-down-outline'
const CHEVRON_UP = 'chevron-up-outline'
const CHEVRON_FORWARD = 'chevron-forward-outline'

const EMPTY_STRING = ''
const DOWN_POSITION = 'down'
const TOP_POSITION = 'top'
const BOTTOM_POSITION = 'bottom'

export {
  CHEVRON_DOWN,
  CHEVRON_UP,
  CHEVRON_FORWARD,
  EMPTY_STRING,
  DOWN_POSITION,
  TOP_POSITION,
  BOTTOM_POSITION,
}
