import * as actionTypes from './actionTypes'
import { SET_ACCOUNT_SIGNUP_DETAILS } from './actionTypes'

const initialState = {
  authCredentials: null,
  account: {
    userID: null,
    accountID: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    dialingCode: null,
    phoneNumber: null,
    dob: null,
    age: null,
  },
  isLogout: false,
  isAccountDeleted: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_AUTH_CREDENTIALS:
      return {
        ...state,
        authCredentials: action.data,
      }

    case actionTypes.SET_ACCOUNT_SIGNUP_DETAILS:
      return {
        ...state,
        account: {
          ...state.account,
          email: action.data.email,
          password: action.data.password,
          firstName: action.data.firstName,
          lastName: action.data.lastName,
          dob: action.data.dob,
          age: action.data.age,
        },
      }

    case actionTypes.CLEAR_AUTH_CREDENTIALS:
      return {
        ...state,
        authCredentials: null,
      }

    case actionTypes.SET_ACCOUNT:
      return {
        ...state,
        account: action.data,
      }

    case actionTypes.CLEAR_ACCOUNT:
      return {
        ...state,
        account: null,
      }

    case actionTypes.SET_ACCOUNT_EMAIL:
      return {
        ...state,
        account: {
          ...state.account,
          email: action.data,
        },
      }

    case actionTypes.SET_ACCOUNT_PASSWORD:
      return {
        ...state,
        account: {
          ...state.account,
          password: action.data,
        },
      }

    case actionTypes.SET_ACCOUNT_FIRST_NAME:
      return {
        ...state,
        account: {
          ...state.account,
          firstName: action.data,
        },
      }

    case actionTypes.SET_ACCOUNT_LAST_NAME:
      return {
        ...state,
        account: {
          ...state.account,
          lastName: action.data,
        },
      }

    case actionTypes.SET_ACCOUNT_DOB:
      return {
        ...state,
        account: {
          ...state.account,
          dob: action.data,
        },
      }

    case actionTypes.SET_ACCOUNT_AGE:
      return {
        ...state,
        account: {
          ...state.account,
          age: action.data,
        },
      }

    case actionTypes.SET_DIALING_CODE:
      return {
        ...state,
        account: {
          ...state.account,
          dialingCode: action.data,
        },
      }

    case actionTypes.SET_DIALING_NUMBER:
      return {
        ...state,
        account: {
          ...state.account,
          phoneNumber: action.data,
        },
      }

    case actionTypes.SET_ACCOUNT_USER_ID:
      return {
        ...state,
        account: {
          ...state.account,
          userID: action.data,
        },
      }

    case actionTypes.SET_ACCOUNT_USER_ACCOUNT_ID:
      return {
        ...state,
        account: {
          ...state.account,
          accountID: action.data,
        },
      }

    case actionTypes.SET_CUSTOM_WORKFLOW_ACCOUNT_STATUS:
      return {
        ...state,
        custom_workflow: {
          account_status: action.data,
        },
      }

    case actionTypes.SET_IS_LOGOUT:
      return {
        ...state,
        isLogout: action.data,
      }

    case actionTypes.SET_IS_ACCOUNT_DELETED:
      return {
        ...state,
        isAccountDeleted: action.data,
      }

    default:
      return state
  }
}
