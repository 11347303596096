import { useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
  TextInput,
  Keyboard,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

import Icon from 'react-native-vector-icons/Feather'
import { RNChipView } from 'react-native-chip-view'
import Autocomplete from 'react-native-autocomplete-input'
import { i18n } from '$localization/config.js'
import ButtonDone from '$components/ButtonDone'
import ButtonToggle from '$components/ButtonToggle'
import LoadingView from '$components/LoadingView'
import ModalLoading from '$components/ModalLoading'
import FlexContainer from '$components/FlexContainer'
import MainWrapper from '$components/MainWrapper'
import styles from './styles/styles.main'
import { debounce } from '$utils'

export default function GeneTestUI({
  navigation,
  geneTestFromOnboarding,
  colorSettings,
  disableDoneButton,
  isLoading,
  selectedGeneTest,
  scrollViewHeight,
  loadMainQuestion,
  loadSubQuestion,
  mainQuestionValue,
  loadGeneList,
  handleDeleteSelectedGene,
  subQuestionValue,
  handleMainQuestion,
  handleSubQuestion,
  handleSelectedGeneTest,
  handleGeneTestSaving,
  handleToolTip,
  UMLSGenesList,
  buttonText,
  loadUMLSGenes,
  currentMember,
  clickedMember,
}) {
  const [inputValue, setInputValue] = useState('')

  const FALLBACK_COLOR = {
    DARK_SHADE: 'rgba(41,43,47,0.6)',
    PURPLE_SHADE: '#AE9AB6',
  }

  const loadingScreen = (
    <FlexContainer style={{ height: scrollViewHeight }}>
      <LoadingView
        backgroundColor={colorSettings?.onboarding_bgcolor}
        tintColor={colorSettings?.btn_no_fill_text_2}
        textColor={colorSettings?.btn_no_fill_text_2}
        visible={isLoading}
      />
    </FlexContainer>
  )

  const renderMainQuestion = (
    <ButtonToggle
      title={
        i18n.t('have_you_ever_had_genetic_testing_for_hereditary_cancer')
          .default
      }
      colorSettings={colorSettings}
      onPress={(value) => handleMainQuestion(value)}
      setContainerStyle={{ marginTop: 20, marginBottom: 20 }}
      isSelected={mainQuestionValue}
    />
  )

  const renderSubQuestion = () => {
    const isCurrentUser =
      currentMember?.memberID === clickedMember?.member_id
        ? 'did_you_test_positive_for_any_genes'
        : 'did_this_person_test_positive_for_any_genes'

    return (
      <View>
        <Text
          style={[
            styles.subQuestionTitle,
            {
              color: colorSettings?.text_input_label_color_2,
              marginTop: 23,
            },
          ]}
        >
          {i18n.t(isCurrentUser).default}
        </Text>
        <TouchableOpacity style={styles.toolTipButton} onPress={handleToolTip}>
          <Icon
            name="info"
            size={20}
            color={colorSettings?.text_input_label_color_2 || 'black'}
          />
        </TouchableOpacity>
        <ButtonToggle
          colorSettings={colorSettings}
          onPress={(value) => handleSubQuestion(value)}
          setContainerStyle={styles.buttonToggleCustomStyle}
          isSelected={subQuestionValue}
        />
      </View>
    )
  }

  const renderChips = (
    <View style={styles.chipContainer}>
      {selectedGeneTest &&
        selectedGeneTest.map((data) => (
          <View style={styles.chipWrapContainer}>
            <RNChipView
              avatar={false}
              backgroundColor={
                colorSettings?.pill_bgcolor || FALLBACK_COLOR.DARK_SHADE
              }
              cancelable
              cancelableStyle={{
                backgroundColor:
                  colorSettings?.pill_delete_bgcolor ||
                  FALLBACK_COLOR.PURPLE_SHADE,
                color: 'black',
              }}
              title={data.Name}
              titleStyle={styles.chipText(colorSettings)}
              onPress={() => handleDeleteSelectedGene(data)}
            />
          </View>
        ))}
    </View>
  )

  const renderAutocomplete = (
    <Autocomplete
      data={UMLSGenesList}
      containerStyle={{
        width: '100%',
        backgroundColor: 'transparent',
      }}
      inputContainerStyle={styles.autocompleteInputContainer(colorSettings)}
      listContainerStyle={{
        flex: 1,
      }}
      renderTextInput={() => (
        <View style={styles.textInputContainerCentered}>
          <TextInput
            style={styles.autocompleteTextInput(colorSettings)}
            onChangeText={(value) => {
              debounce(loadUMLSGenes(value, ''))
              setInputValue(value)
            }}
            value={inputValue}
            placeholder={`${i18n.t('search_genes').default}: `}
            placeholderTextColor={
              colorSettings?.text_input_label_color_2 || 'black'
            }
            onBlur={Keyboard.dismiss}
          />
          <Icon
            name="search"
            size={20}
            color={colorSettings?.text_input_label_color_2 || 'black'}
          />
        </View>
      )}
      autoCorrect={false}
      hideResults={false}
      flatListProps={{
        showsVerticalScrollIndicator: false,
        style: styles.autocompleteList,
        keyboardShouldPersistTaps: 'always',
        renderItem: ({ item }) => (
          <TouchableOpacity
            style={styles.autocompleteListButtons(item, colorSettings)}
            onPress={() => {
              item.CUI === 'more'
                ? debounce(loadUMLSGenes(inputValue, item.CUI))
                : handleSelectedGeneTest(item)
            }}
          >
            <Text style={styles.autocompleteListButtonText(colorSettings)}>
              {item.Name}
            </Text>
          </TouchableOpacity>
        ),
      }}
    />
  )

  let _switchView
  if (isLoading) {
    _switchView = loadingScreen
  } else {
    _switchView = (
      <FlexContainer
        style={{ height: scrollViewHeight, justifyContent: 'flex-start' }}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.container}
        >
          <ScrollView style={styles.container}>
            {loadMainQuestion && renderMainQuestion}
            {loadSubQuestion && renderSubQuestion()}
            {renderChips}
            {loadGeneList && renderAutocomplete}
          </ScrollView>
        </KeyboardAvoidingView>
      </FlexContainer>
    )
  }

  return (
    <ErrorBoundary>
      <MainWrapper
        navigation={navigation}
        disableLogout={geneTestFromOnboarding}
      >
        <SafeAreaView
          style={[
            styles.container,
            {
              backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
            },
          ]}
        >
          {_switchView}
          <ButtonDone
            colorSettings={colorSettings}
            onPress={() => handleGeneTestSaving()}
            title={i18n.t(buttonText).default}
            disable={disableDoneButton}
          />
          <ModalLoading
            colorSettings={colorSettings}
            isVisible={false}
            loadingTitle={i18n.t('saving_datanplease_wait').default}
          />
        </SafeAreaView>
      </MainWrapper>
    </ErrorBoundary>
  )
}
