import { StyleSheet } from 'react-native'

const arrowColor = 'rgba(212, 212, 212, 1)'

const styles = StyleSheet.create({
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  contentSeparatorLineContainer: { zIndex: -1 },
})

export default styles
export { arrowColor }
