import apiFetchHandler from '$api/apiFetchHandler'

import { store } from '$redux/configureStore'
import { setError } from '$redux/defaults/actions.js'

const fetchCompletedSurveyNotification = async ({ payload }) => {
  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const bodyPayload = {
      path: 'member/send_proband_completed_survey_notification/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await apiFetchHandler(bodyPayload)

    //TODO: remove the response.status === 404 after testing
    if (!response.isError || response.status === 404) return

    store.dispatch(
      setError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    )
  } catch (error) {
    console.log('fetchCompletedSurveyNotification error: ', error)
  }
}

const fetchSaveSurvey = async ({ payload, setIsFetching }) => {
  setIsFetching(true)

  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const bodyPayload = {
      path: 'completed_survey/save_completed_survey/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await apiFetchHandler(bodyPayload)

    if (!response.isError) return response

    store.dispatch(
      setError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    )

    return response
  } catch (error) {
    console.log('fetchSaveSurvey error: ', error)
  } finally {
    setIsFetching(false)
  }
}

const fetchClinicianId = async (familyId) => {
  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const payload = {
      path: `family/${familyId}/clinicians/`,
      method: 'get',
      token: authCredentials.accessToken,
      pageDetails: {
        page: 'CustomScreen - index.js',
      },
    }

    const response = await apiFetchHandler(payload)

    if (response.isError) {
      store.dispatch(
        setError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      )

      return false
    }

    if (response?.length === 0 || !response?.length) return null

    return {
      clinician_id: response?.[0]?.organization?.clinician_id,
    }
  } catch (error) {
    console.log('fetchClinicianId error: ', error)
  }
}

const getClinicianId = async (proband) => {
  if (proband?.clinicianCode || proband?.clinician_code) {
    return {
      clinician_code: proband?.clinicianCode || proband?.clinician_code,
    }
  }

  if (!proband.familyID) return false

  return fetchClinicianId(proband.familyID)
}

const fetchClinicianCheck = async (proband) => {
  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const clinicianCode = await getClinicianId(proband)

    if (!clinicianCode) return null

    const payload = {
      path: 'clinician/check/',
      method: 'post',
      token: authCredentials?.accessToken,
      body: {
        ...clinicianCode,
      },
      pageDetails: {
        page: 'CustomScreen - index.js',
      },
    }

    const response = await apiFetchHandler(payload)

    if (!response.isError) return response?.clinician_code?.id

    store.dispatch(
      setError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    )
  } catch (error) {
    console.log('fetchClinicianCheck error: ', error)
  }
}

const fetchSurvey = async ({ proband, surveyId, setIsFetching }) => {
  setIsFetching(true)

  try {
    const clinicianCodeId = await fetchClinicianCheck(proband)

    const authCredentials = store.getState()?.accountStore?.authCredentials

    const payload = {
      path: 'survey/get_survey/',
      method: 'get',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      params: {
        clinician_id: !clinicianCodeId ? undefined : clinicianCodeId,
        survey_id: surveyId,
        proband_id: proband?.probandID,
      },
    }

    const response = await apiFetchHandler(payload)

    if (response.isError) {
      store.dispatch(
        setError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      )

      return
    }

    return response
  } catch (error) {
    console.log('fetchSurvey error: ', error)
  } finally {
    setIsFetching(false)
  }
}

// TODO: Marc - Pass setIsFetching in case is needed
const fetchSendClinicianNotification = async ({ payload }) => {
  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const bodyPayload = {
      path: 'member/send_notification_to_clinicians_for_updating_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await apiFetchHandler(bodyPayload)

    if (!response.isError) return

    store.dispatch(
      setError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    )
  } catch (error) {
    console.log('fetchSendClinicianNotification error: ', error)
  }
}

const fetchSubmitSurveyAnswers = async ({ payload, callback }) => {
  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const bodyPayload = {
      path: 'completed_survey_answer/create_completed_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await apiFetchHandler(bodyPayload)

    if (response.isError) {
      store.dispatch(
        setError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      )

      return
    }

    callback(response, payload.master_question_id)
  } catch (error) {
    console.log('fetchSubmitSurveyAnswers error: ', error)
  }
}

// TODO: Marc - Pass setIsFetching in case is needed
const fetchDeleteSurveyAnswer = async ({ payload, callback }) => {
  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const bodyPayload = {
      path: 'completed_survey_answer/delete_completed_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await apiFetchHandler(bodyPayload)

    if (response.isError) {
      store.dispatch(
        setError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      )

      return
    }

    callback(response, payload.master_question_id)
  } catch (error) {
    console.log('fetchDeleteSurveyAnswer error: ', error)
  }
}

const fetchUpdateSurveyAnswer = async ({ payload, callback }) => {
  try {
    const authCredentials = store.getState()?.accountStore?.authCredentials

    const bodyPayload = {
      path: 'completed_survey_answer/update_completed_survey_answer/',
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'CustomScreen.js',
      },
      body: {
        ...payload,
      },
    }

    const response = await apiFetchHandler(bodyPayload)

    if (response.isError) {
      store.dispatch(
        setError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      )

      return
    }

    callback(response, payload.master_question_id)
  } catch (error) {
    console.log('fetchUpdateSurveyAnswer error: ', error)
  }
}

export {
  fetchSurvey,
  fetchSendClinicianNotification,
  fetchSubmitSurveyAnswers,
  fetchDeleteSurveyAnswer,
  fetchUpdateSurveyAnswer,
  fetchSaveSurvey,
  fetchCompletedSurveyNotification,
}
