import { FlatList, Text, TouchableOpacity, View } from 'react-native'
import { i18n } from '$localization/config.js'
import styles from './styles'

function Dropdown({
  data,
  isShown = false,
  borderRadius = 7,
  width = 200,
  top = 45,
  right,
  renderList,
  onPress = () => {},
  backgroundColor = 'white',
  selectedItem,
}) {
  const defaultContentList = (handlePressButton, buttonName) => {
    const isSelectedButton = selectedItem === buttonName ? '#E5E7EB' : 'white'

    return (
      <View
        style={[
          styles.bottomSheetButtonContainer,
          {
            backgroundColor: isSelectedButton,
          },
        ]}
      >
        <TouchableOpacity
          onPress={() => handlePressButton(buttonName)}
          style={styles.defaultBottomSheetButton}
        >
          <Text style={styles.defaultBottomSheetButtonText}>
            {i18n.t(buttonName).default}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View
      style={{
        backgroundColor,
        width,
        position: 'absolute',
        top,
        right,
        borderWidth: 0.5,
        borderColor: '#CCCCCC',
        zIndex: 999,
        flex: 1,
        display: isShown ? 'block' : 'none',
        borderRadius,
        overflow: 'hidden',
      }}
    >
      <FlatList
        data={data}
        renderItem={(buttonName) =>
          Boolean(renderList)
            ? renderList(onPress, buttonName.item)
            : defaultContentList(onPress, buttonName.item)
        }
      />
    </View>
  )
}

// Dropdown.propTypes = {
//   data: PropTypes.array.isRequired,
//   isShown: PropTypes.bool.isRequired,
//   borderRadius: PropTypes.number,
//   width: PropTypes.number,
//   top: PropTypes.number,
//   backgroundColor: PropTypes.string,
//   renderList: PropTypes.object,
//   onPress: PropTypes.func,
// }

export default Dropdown
