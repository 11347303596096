import { StyleSheet } from 'react-native'

const BLACK = 'black'

export default StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  inputField: (colorSettings) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    height: 105,
    borderWidth: 0.5,
    alignSelf: 'center',
    borderRadius: 7,
    borderColor: colorSettings?.text_input_color_border_2 || BLACK,
    color: colorSettings?.text_input_color_2 || BLACK,
  }),
})
