import React from 'react'
import { View } from 'react-native'

import TextField from './fields/text-field/TextField'
import MultiSelectField from './fields/multi-select-field/MultiSelectField'
import ButtonToggle from '$components/ButtonToggle'
import SingleSelectField from './fields/single-select-field/SingleSelectField'
import NumberField from './fields/number-field/NumberField'
import DateField from './fields/date-field/DateField'

import styles from '../CustomInputScreen.style'

export default function HandleInputFields({ inputItem, colorSettings }) {
  switch (inputItem.type) {
    case 'text':
      return (
        <TextField
          inputFieldData={inputItem}
          colorSettings={colorSettings}
          keyboardType="email-address"
        />
      )

    case 'toggle':
      return (
        <ButtonToggle
          inputFieldData={inputItem}
          colorSettings={colorSettings}
          subContainerStyle={styles.toggleField}
        />
      )

    case 'multi-select':
      return (
        <MultiSelectField
          inputFieldData={inputItem}
          colorSettings={colorSettings}
        />
      )

    case 'single-select':
      return (
        <SingleSelectField
          inputFieldData={inputItem}
          colorSettings={colorSettings}
        />
      )

    case 'number':
      return (
        <NumberField inputFieldData={inputItem} colorSettings={colorSettings} />
      )

    case 'date':
      return (
        <DateField inputFieldData={inputItem} colorSettings={colorSettings} />
      )

    default:
      return <View />
  }
}
