import { Text } from 'react-native'
import { memo, useCallback } from 'react'

import { i18n } from '$localization/config.js'
import styles from './styles.js'

import { checkForMissingString } from '../../../helpers'

function LabelField({ masterQuestion = {}, colorSettings = {} }) {
  const { question_label: questionLabel, translation_key: translationKey } =
    masterQuestion

  const getLabel = () => {
    if (!masterQuestion) return ''

    const newTranslationKey = `${translationKey}.label`
    const questionLabelTranslation = i18n.t(newTranslationKey)
    const isSameTranslationKey = questionLabelTranslation === newTranslationKey

    const isInvalidTranslation =
      isSameTranslationKey ||
      !questionLabelTranslation ||
      checkForMissingString(questionLabelTranslation)

    if (isInvalidTranslation) {
      return questionLabel
    }

    return questionLabelTranslation
  }

  return <Text style={styles.titleText(colorSettings)}>{getLabel()}</Text>
}

export default memo(LabelField)

// ** typescript reference **

// LabelField.propTypes = {
//   masterQuestion: PropTypes.shape({
//     question_label: PropTypes.string,
//   }),
//   colorSettings: PropTypes.shape({
//     text_input_title_color_2: PropTypes.string,
//   }),
// }

// LabelField.defaultProps = {
//   masterQuestion: {},
//   colorSettings: {},
// }
