import { StyleSheet } from 'react-native'

const FADED_BLACK = 'rgba(0,0,0,0.8)'
const WHITE = '#fff'

const styles = StyleSheet.create({
  modalBackground: {
    backgroundColor: FADED_BLACK,
    flex: 1.0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: '93%',
    marginBottom: 25,
    backgroundColor: WHITE,
    borderRadius: 10,
    overflow: 'hidden',
  },
  modalContent: {
    backgroundColor: WHITE,
    paddingHorizontal: 16,
    paddingTop: 25,
    paddingBottom: 10,
  },
})

export default styles
