import { Platform, SectionList } from 'react-native'
import SectionHeader from './SectionHeader'
import { platforms } from '$constants'
import SectionListItem from './SectionListItem'

const WEB_SCALED_MAIN_CONTAINER = 'web_scaled_main_container'

function FamilyList({
  expandedSections,
  filteredFamilyData,
  setExpandedSections,
  sectionListRefreshing,
  onRefreshSectionList,
  isCustomWorkflow,
  handleAvatarClick,
}) {
  const relationshipList = []
  return (
    <SectionList
      nativeID={WEB_SCALED_MAIN_CONTAINER}
      contentContainerStyle={{
        paddingBottom: 16,
        paddingHorizontal: 16,
      }}
      bounces={Platform.OS === platforms.IOS}
      extraData={expandedSections}
      sections={filteredFamilyData}
      keyExtractor={(item) => item.member_id}
      renderSectionHeader={({ section }) => (
        <SectionHeader
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
          section={section}
        />
      )}
      renderItem={({ item, index, section }) => (
        <SectionListItem
          section={section}
          key={index}
          item={item}
          expandedSections={expandedSections}
          relationshipList={relationshipList}
          isCustomWorkflow={isCustomWorkflow}
          handleAvatarClick={handleAvatarClick}
        />
      )}
      refreshing={sectionListRefreshing}
      onRefresh={onRefreshSectionList}
    />
  )
}

export default FamilyList
