import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { ROUTES, CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'

function checkForMissingString(input) {
  const MISSING_STRING = 'missing'

  return (
    input.startsWith('[') &&
    input.endsWith(']') &&
    input.includes(MISSING_STRING)
  )
}

function decreaseFontSize(name) {
  const nameLength = name ? parseInt(name.length, 10) : 0
  let fontSize = ''

  switch (true) {
    case nameLength < 45:
      fontSize = 18
      break
    case nameLength < 53:
      fontSize = 14
      break
    default:
      fontSize = 12
  }

  return fontSize
}

function checkMissingTranslation({ message, fallback }) {
  const missingTranslationPattern = /\[missing\s".*"\stranslation\]/

  if (missingTranslationPattern.test(message)) {
    return { isMissing: true, default: fallback }
  }

  return { isMissing: false, default: message }
}

function convertBoolToText(item) {
  if (!item) return item

  if (typeof item !== 'boolean') {
    console.warn('convert error: not a boolean')
    return item
  }

  if (item) return 'True'

  return 'False'
}

function convertTextToBool(item) {
  if (!item) return

  if (typeof item !== 'string') {
    console.warn('convert error: not a string')
    return
  }

  if (item.toLowerCase() === 'true') return true

  return false
}

function exitSurvey({ isOnboarding, navigation }) {
  if (!isOnboarding) {
    navigation.popToTop()

    return
  }

  const nextRoute = getNextWorkflowRoute({
    defaultNextRoute: ROUTES.PersonalGender.name,
  })

  handleNavigateToNextScreen({
    navigation,
    routeName: nextRoute,
    selectedScreen: '',
  })
}

function getSurveyId({ selectedSurvey, selectedScreens }) {
  if (selectedSurvey?.surveyID) return selectedSurvey.surveyID

  const selectScreen = selectedScreens.find(
    (screen) => screen.name === CUSTOM_FLOW_ROUTES.SurveyCustom.name
  )

  return selectScreen?.surveyId
}

export {
  convertTextToBool,
  convertBoolToText,
  decreaseFontSize,
  checkMissingTranslation,
  checkForMissingString,
  exitSurvey,
  getSurveyId,
}
