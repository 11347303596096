import { Text, View } from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../../styles'
import DarkGraySwitch from '$components/CustomSwitches/DarkGraySwitch'
import { i18n } from '$localization/config.js'

function RememberMeSection({ rememberMe, setRememberMe }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={[styles.formSection, { paddingVertical: 0 }]}>
      <View style={styles.labeledSwitchContainer}>
        <View style={styles.rememberMeTextContainer}>
          <Text style={styles.labeledSwitchText(colorSettings)}>
            {i18n.t('remember_me').default}
          </Text>
        </View>

        <View style={styles.rememberMeButtonContainer}>
          <DarkGraySwitch
            onValueChange={() => setRememberMe(!rememberMe)}
            value={rememberMe}
          />
        </View>
      </View>
    </View>
  )
}

export default RememberMeSection
