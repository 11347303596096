import apiFetchHandler from '$api/apiFetchHandler'

async function addGeneTest({
  savedGeneTestData,
  probandProfile,
  authCredentials,
  GENE_TEST_TYPE,
  setLoadingView,
  saveError,
}) {
  await Promise.all(
    savedGeneTestData.map(async (item) => {
      const addGeneTestToMemberOnboardingPayload = {
        path: `member/${probandProfile.memberID}/add_genetic_testing/`,
        method: 'post',
        token: authCredentials.accessToken,
        body: {
          proband_id: probandProfile?.memberID,
          umls_id: item.CUI,
          umls_name: item.Name,
          result: GENE_TEST_TYPE,
        },
        pageDetails: {
          page: 'PositiveGeneTestOnboarding.js',
        },
      }

      const response = await apiFetchHandler(
        addGeneTestToMemberOnboardingPayload
      )

      if (response?.isError) {
        setLoadingView(false)
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }
    })
  )
}

export default addGeneTest
