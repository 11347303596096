import { View, Modal } from 'react-native'
import LoadingView from '$components/LoadingView'
import styles from './styles'

export default function ModalLoading({
  colorSettings,
  isVisible,
  loadingTitle,
}) {
  const noneAnimation = 'none'

  return (
    <Modal visible={isVisible} animationType={noneAnimation} transparent>
      <View style={styles.loadingContainer}>
        <LoadingView
          backgroundColor={colorSettings?.onboarding_bgcolor}
          tintColor={colorSettings?.btn_no_fill_text_2}
          textColor={colorSettings?.btn_no_fill_text_2}
          visible={isVisible}
          message={loadingTitle}
        />
      </View>
    </Modal>
  )
}
