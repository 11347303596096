import { relationshipTypes } from '$constants'

const sectionListOrder = {
  [relationshipTypes.PROBAND]: 0,
  [relationshipTypes.SIBLINGS]: 1,
  [relationshipTypes.PARENTS]: 2,
  [relationshipTypes.PATERNAL_AUNTS_UNCLES]: 3,
  [relationshipTypes.MATERNAL_AUNTS_UNCLES]: 4,
  [relationshipTypes.PATERNAL_GRANDPARENTS]: 5,
  [relationshipTypes.MATERNAL_GRANDPARENTS]: 6,
  [relationshipTypes.OTHER_MEMBERS]: 7,
}

export default sectionListOrder
