import axios from 'axios'

import { envConstants } from '$constants'

import {
  logSentNetworkPayload,
  logNetworkResponse,
  logErrorToSentry,
  logNetworkError,
  createAxiosNetworkTimeout,
} from './helpers'

import {
  NETWORK_TIMEOUT_VALUE,
  DEFAULT_CONTENT_TYPE,
  DEFAULT_NETWORK_ERROR,
} from './contants'

const SHOULD_SHOW_NETWORK_LOGS =
  (__DEV__ || process.env.NODE_ENV === 'development') &&
  process.env?.APP_MANIFEST?.extra?.SHOW_NETWORK_LOGS === 'true'

export default async function apiFetchHandler(payload) {
  const {
    path,
    method,
    body,
    token,
    contentType,
    header,
    networkTimeout = NETWORK_TIMEOUT_VALUE,
    params,
    pageDetails,
  } = payload

  const { source, clearNetworkTimeout } =
    createAxiosNetworkTimeout(networkTimeout)

  const url = `${envConstants.BASE_URL}/${path}`

  const headerContentType = contentType ?? DEFAULT_CONTENT_TYPE

  const headers = {
    ...header,
    'Content-Type': `${headerContentType}`,
    Accept: `${headerContentType}`,
  }

  if (!path || !method) {
    throw new Error(
      `apiFetchHandler: path and method are required. Located in ${pageDetails}`
    )
  }

  if (token) headers.Authorization = `Bearer ${token}`
  try {
    if (SHOULD_SHOW_NETWORK_LOGS) {
      logSentNetworkPayload({ method, url, body, params })
    }

    const axiosResponse = await axios({
      cancelToken: source?.token,
      method,
      url,
      headers,
      data: body,
      params,
    })

    if (SHOULD_SHOW_NETWORK_LOGS) {
      logNetworkResponse({ method, url, axiosResponse })
    }

    clearTimeout(clearNetworkTimeout)

    return axiosResponse.data
  } catch (error) {
    let errorMessage =
      error?.response?.data?.message ??
      error?.response?.data?.error ??
      error?.response?.data?.detail

    logErrorToSentry({
      pageDetails,
      path,
      method,
      errorMessage,
      errorObject: error,
    })

    const isAxiosTimeout = axios.isCancel(error)

    if (isAxiosTimeout) {
      errorMessage = error.message
    }

    if (SHOULD_SHOW_NETWORK_LOGS) {
      logNetworkError({
        method,
        url,
        pageDetails,
        errorObject: error,
        errorMessage,
      })
    }

    const formattedError = {
      isError: true,
      error: errorMessage ?? DEFAULT_NETWORK_ERROR.MESSAGE,
      status: error?.response?.status ?? DEFAULT_NETWORK_ERROR.STATUS,
      response: error?.response?.data ?? DEFAULT_NETWORK_ERROR.RESPONSE,
    }

    return formattedError
  }
}
