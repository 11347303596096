import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'

import SurveyScreen from '$screens/main/survey/SurveyScreen/index.js'
import GeneticResourcesView from '$screens/main/dashboard/geneticResources/GeneticResources.view.js'
import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'

const Stack = createStackNavigator()

function SurveyNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SurveysTab"
        component={SurveyScreen}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('surveys').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              customAction={() => navigation.navigate('Home')}
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen name="GeneticResources" component={GeneticResourcesView} />
    </Stack.Navigator>
  )
}

export default SurveyNavigator
