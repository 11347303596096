import { StyleSheet } from 'react-native'

const lightGrey = 'rgba(255,255,255,0.5)'
const white = '#fff'

export default StyleSheet.create({
  searchInputInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  itemButton: (colorSettings) => ({
    marginTop: 5,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 60,
    borderRadius: 30,
    flexDirection: 'row',
    backgroundColor: colorSettings?.selectable_btn_active_1 || 'white',
    borderColor: colorSettings?.selectable_btn_active_1 || 'black',
    borderWidth: 1.0,
  }),
  itemButtonText: (colorSettings) => ({
    color:
      colorSettings?.selectable_btn_text_active_1 || 'rgba(74, 74, 74, 0.5)',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    letterSpacing: 1,
    lineHeight: 22,
    textAlign: 'left',
    flex: 1,
    marginLeft: 24,
  }),
  searchInputList: {
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 10,
    minHeight: 80,
  },
  inputContainer: { marginTop: 14 },
  innerInputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 16,
  },
  iconImage: {
    height: 18,
    width: 18,
    marginRight: 24,
    resizeMode: 'contain',
  },
  listContainer: { flexDirection: 'row', marginBottom: 16 },
  listItemContainer: { flexDirection: 'row', flex: 0.75 },
  listItemText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'left',
    width: '100%',
    color: colorSettings?.selectable_btn_text_active_1 || 'black',
  }),
  removeButtonContainer: { flex: 0.25, flexDirection: 'row-reverse' },
  removeButtonText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'right',
    color: colorSettings?.selectable_btn_active_1 || 'rgba(74, 74, 74, 0.5)',
  }),
})
