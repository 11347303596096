export default function adjustFontSizeByLength(string) {
  const stringLength = string.length

  const THRESHOLD = {
    LARGE: 45,
    MEDIUM: 53,
  }

  const FONT_SIZE = {
    LARGE: 18,
    MEDIUM: 14,
    SMALL: 12,
  }

  switch (true) {
    case stringLength < THRESHOLD.LARGE:
      return FONT_SIZE.LARGE
    case stringLength < THRESHOLD.MEDIUM:
      return FONT_SIZE.MEDIUM
    default:
      return FONT_SIZE.SMALL
  }
}
