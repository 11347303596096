const STYLE = {
  COLOR_WHITE: 'white',
  WIDTH: 42,
  HEIGHT: 42,
  RESIZE_MODE: 'contain',
  COLOR_GREY: 'rgba(204, 204, 204, 0.3)',
  OPACITY: 0.3,
  BORDER_RADIUS: 6,
  HALF: 2,
}

const STRINGS = {
  FEMALE: 'f',
  EMPTY: '',
}

const getGenderIconBorderRadius = (gender, isDeceased = false) => {
  const style = {
    backgroundColor: STYLE.COLOR_WHITE,
    width: STYLE.WIDTH,
    height: STYLE.HEIGHT,
    resizeMode: STYLE.RESIZE_MODE,
  }

  if (isDeceased) {
    style.opacity = STYLE.OPACITY
  }

  if (!gender || gender === STRINGS.EMPTY) {
    style.backgroundColor = STYLE.COLOR_GREY
    style.borderRadius = STYLE.BORDER_RADIUS
    return style
  }

  const lowercaseGender = gender.toLowerCase()

  if (lowercaseGender === STRINGS.FEMALE) {
    style.borderRadius = style.width / STYLE.HALF
  }

  return style
}

export default getGenderIconBorderRadius
