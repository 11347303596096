import { StyleSheet } from 'react-native'
import { FALLBACK_STYLES } from '../../constants'

const styles = StyleSheet.create({
  containerText: {
    width: '100%',
    marginVertical: 16,
  },
  titleText: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_account_text ?? FALLBACK_STYLES.BLACK_COLOR,
  }),
  bodyText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_account_text ?? FALLBACK_STYLES.BLACK_COLOR,
  }),
})

export default styles
