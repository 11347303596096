import { useCallback, useEffect, useState, useMemo } from 'react'
import {
  View,
  ScrollView,
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { useSelector } from 'react-redux'
import uuid from 'react-native-uuid'
import { useNavigation } from '@react-navigation/core'

import { DefaultFullHeight } from '$assets/styles/global.styles.js'
import { platforms } from '$constants'
import FlexContainer from '$components/FlexContainer'
import LabelField from './components/fields/label-field/LabelField'
import ButtonDone from '$components/ButtonDone'
import { i18n } from '$localization/config.js'

import HandleInputFields from './components/HandleInputFields'

// TODO: we will have to attend to this MockData and find out where we should import the correct data from.
import sampleData from './components/mockData'

import styles from './CustomInputScreen.style'

let setTitle = 'Custom Screen'

function CustomInputScreen() {
  const navigation = useNavigation()

  const [scrollViewHeight, setScrollViewHeight] = useState(DefaultFullHeight())
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const screenResizeHandler = useCallback(() => {
    setScrollViewHeight(DefaultFullHeight())
  }, [])

  useEffect(() => {
    if (platforms.WEB === Platform.OS) {
      window.addEventListener('resize', screenResizeHandler)
    }

    return () => {
      if (platforms.WEB === Platform.OS) {
        window.removeEventListener('resize', screenResizeHandler)
      }
    }
  })

  useEffect(() => {
    setTitle = sampleData?.header_title ?? ''

    navigation.setOptions({ title: setTitle })
  }, [navigation])

  const CustomFieldBuilder = () => {
    const fields = useMemo(() => {
      const { custom_categories: customCategories } = sampleData

      return customCategories.map((inputItem) => (
        <View style={styles.fieldContainer} key={uuid.v4()}>
          <HandleInputFields
            inputItem={inputItem}
            colorSettings={colorSettings}
          />
        </View>
      ))
    }, [sampleData, colorSettings])

    return fields
  }

  return (
    <KeyboardAvoidingView behavior="padding" enabled>
      <SafeAreaView>
        <ScrollView
          style={{
            height: scrollViewHeight,
            backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
          }}
        >
          <FlexContainer style={styles.flexContainer}>
            {Boolean(sampleData?.sub_title) && (
              <LabelField
                title={sampleData.sub_title}
                colorSettings={colorSettings}
              />
            )}
            <CustomFieldBuilder />
          </FlexContainer>
        </ScrollView>
        <ButtonDone
          title={i18n.t('next').default}
          onPress={() => {}}
          colorSettings={colorSettings}
        />
      </SafeAreaView>
    </KeyboardAvoidingView>
  )
}

export default CustomInputScreen
