import React from 'react'
import { View, TouchableOpacity, Text, Image, FlatList } from 'react-native'

import { i18n } from '$localization/config.js'
import styles from './styles.js'
import SelectedAncestries from '../SelectedAncestries'

function SearchInput({
  colorSettings,
  ancestryData,
  onPress,
  parent,
  onPressRemove,
}) {
  return (
    <View>
      <View style={styles.inputContainer}>
        <View style={styles.innerInputContainer}>
          <View style={styles.searchInputInnerContainer}>
            <TouchableOpacity
              activeOpacity={1.0}
              style={styles.itemButton(colorSettings)}
              onPress={() => onPress(parent)}
            >
              <Text style={styles.itemButtonText(colorSettings)}>
                {`${i18n.t('search_ancestry').default}...`}
              </Text>
              <Image
                style={styles.iconImage}
                source={
                  colorSettings?.selectable_btn_text_active_1
                    ? require('$assets/images/icon/searchIcon.png')
                    : require('$assets/images/new_assets/icon-search-family.png')
                }
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <FlatList
        style={styles.searchInputList}
        data={ancestryData}
        keyExtractor={(_, index) => `${index}`}
        renderItem={({ item }) => (
          <SelectedAncestries item={item} onPressRemove={onPressRemove} />
        )}
      />
    </View>
  )
}

export default SearchInput
