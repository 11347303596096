import { View, Text, TouchableOpacity } from 'react-native'
import { i18n } from '$localization/config.js'

import styles from './styles.js'

export default function ContinueAndSkipButtons({
  nextButtonAction,
  skipButtonAction,
  colorSettings,
}) {
  const continueText = i18n.t('continue').default
  const skipText = i18n.t('skip').default

  return (
    <>
      <View style={styles.nextButtonContainer}>
        <TouchableOpacity
          style={styles.continueButton(colorSettings)}
          onPress={nextButtonAction}
        >
          <Text style={styles.continueButtonText(colorSettings)}>
            {continueText}
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.nextButtonContainer}>
        <TouchableOpacity style={styles.skipButton} onPress={skipButtonAction}>
          <Text style={styles.skipButtonText(colorSettings)}>{skipText}</Text>
        </TouchableOpacity>
      </View>
    </>
  )
}
