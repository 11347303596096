import { StyleSheet, Dimensions } from 'react-native'
import { DefaultFullHeight } from '../../../../assets/styles/global.styles'
import { IS_WEB } from '$constants/platforms'

const SCREEN_WIDTH = Dimensions.get('window').width
const MARGIN_PERCENTAGE = 0.02

const RESPONSIVE_MARGIN = SCREEN_WIDTH * MARGIN_PERCENTAGE

const styles = StyleSheet.create({
  safeAreaContainer: (colorSettings) => ({
    backgroundColor: colorSettings?.post_onboarding_bgcolor,
    width: '100%',
    flex: 1.0,
  }),
  viewContainer: {
    flex: 1,
    height: DefaultFullHeight(),
    paddingBottom: IS_WEB() ? 30 : 0,
    width: '100%',
  },
  sectionHeaderContainer: (colorSettings) => ({
    backgroundColor: colorSettings?.member_section_bg,
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }),
  sectionHeaderTitleContainer: {
    width: '95%',
    height: 40,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  sectionHeaderTitleText: (colorSettings) => ({
    color: colorSettings?.member_section_title,
    fontFamily: 'karla-bold',
    fontSize: 18,
    marginHorizontal: 5,
  }),
  sectionHeaderTitleButtonContainer: {
    width: '5%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  helperHeaderTextContainer: (colorSettings) => ({
    backgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
    paddingHorizontal: 20,
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  helperHeaderText: (colorSettings) => ({
    textAlign: 'center',
    color: colorSettings?.nav_title_light,
    fontFamily: 'montserrat',
    fontSize: 12,
  }),
  memberSearchContainer: (colorSettings) => ({
    backgroundColor: colorSettings?.splash_bgcolor,
    width: '100%',
    height: 55,
    paddingHorizontal: 8,
    paddingVertical: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    marginBottom: 5,
  }),
  memberSearchBarTextInputContainer: {
    maxWidth: 800,
    alignSelf: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  memberSearchBarInputIconContainer: {
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  memberSearchBarTextInput: (colorSettings) => ({
    paddingHorizontal: 5,
    height: '100%',
    width: '100%',
    color: colorSettings?.text_input_color_1,
    fontSize: 15,
    textAlignVertical: 'center',
  }),
  screeningWorkflowHeaderText: {
    fontFamily: 'karla-bold',
    fontSize: 21,
    color: 'rgb(74,74,74)',
    textAlign: 'center',
  },
  textAvatarsSheet: {
    textAlign: 'center',
  },
  flatListContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarsContainer: {
    height: 105,
    width: 105,
    margin: RESPONSIVE_MARGIN,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelButtonText: (colorSettings) => ({
    fontFamily: 'montserrat-semibold',
    fontSize: 16,
    fontWeight: '600',
    color: colorSettings?.linked_text,
  }),
  cancelButtonContainer: {
    marginTop: 30,
  },
})

export default styles
