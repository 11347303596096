import { StyleSheet } from 'react-native'

const PURPLE_SHADE = '#AE9AB6'
const WHITE = 'white'

const styles = StyleSheet.create({
  chipContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15,
    flexWrap: 'wrap',
    minHeight: 50,
  },
  chipWrapContainer: {
    margin: 5,
  },
  cancelableStyle: (colorSettings) => ({
    backgroundColor: colorSettings?.pill_delete_bgcolor || PURPLE_SHADE,
    color: 'black',
  }),
  chipText: (colorSettings) => ({
    paddingLeft: 10,
    color: colorSettings?.pill_textcolor || WHITE,
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'montserrat',
  }),
})

export default styles
