import { View, TextInput, KeyboardAvoidingView } from 'react-native'
import { useSelector } from 'react-redux'
import styles from './styles'
import { i18n } from '$localization/config'

function OtherPronounTextInput({ textValue, setTextValue }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const preferredPronounPlaceholderTranslation = i18n.t(
    'preferred_pronoun_placeholder'
  ).default

  return (
    <View style={styles.otherPronounTextInputContainer(colorSettings)}>
      <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }} enabled>
        <TextInput
          selectionColor="white"
          placeholderTextColor={colorSettings?.text_input_placeholder_color_2}
          style={styles.otherPronounTextInput(colorSettings)}
          placeholder={preferredPronounPlaceholderTranslation}
          maxLength={50}
          onChangeText={setTextValue}
          value={textValue}
        />
      </KeyboardAvoidingView>
    </View>
  )
}

export default OtherPronounTextInput
