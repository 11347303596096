import { Component } from 'react'
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  FlatList,
  Modal,
  StyleSheet,
} from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import Proband from '$data_models/Proband.js'
import MemberProfile from '$data_models/MemberProfile.js'
import LoadingView from '$components/LoadingView'

import { apiFetchHandler } from '$api'

import DiseaseCategoryButton from '$screens/relatives/profile/sections/health_history/components/disease_list_components/DiseaseCategoryButton.js'

import {
  showGenericServerError,
  handleDefaultError,
  showDefaultAlert,
} from '$navigation/_utils/NavigationUtils.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles from '$assets/styles/global.styles.js'

class DiseaseTypes extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.route.params?.parentController ?? null

  proband = this.props.route.params?.proband ?? new Proband()

  memberProfile = this.props.route.params?.memberProfile ?? new MemberProfile()

  currentMemberID = this.props.route.params?.currentMemberID ?? null

  onDismiss = this.props.route.params?.onDismiss ?? null

  constructor(props) {
    super(props)

    this.state = {
      memberDisease: [],
      memberDiseaseLoad: false,
      memberDiseaseSave: false,
      showModal: false,
      diseaseTypes: null,
    }
  }

  async componentDidMount() {
    const { account, probandID, selectedLanguage } = this.proband
    const { saveError } = this.props

    const payload = {
      proband_id: probandID,
      member_id: this.memberProfile.memberID,
      lang: selectedLanguage,
    }

    const memberProfilePayload = {
      url: 'member/get/',
      method: 'post',
      token: account.authCredentials.accessToken,
      body: payload,
    }

    const {
      status: statusProfile,
      member_disease: memberDisease,
      isError: isErrorProfile,
      error: errorProfileMessage,
    } = await apiFetchHandler(memberProfilePayload)

    if (isErrorProfile) {
      this.setState({ memberDiseaseLoad: false })

      saveError({
        isShown: true,
        status: statusProfile,
        message: errorProfileMessage,
      })
      return
    }

    const diseaseTypePayload = {
      url: 'disease/disease_type/',
      method: 'post',
      token: account.authCredentials.accessToken,
    }

    const {
      status: statusDiseases,
      disease_type: getDiseaseType,
      isError: isErrorDiseases,
      error: errorDiseasesMessage,
    } = await apiFetchHandler(diseaseTypePayload)

    if (isErrorDiseases) {
      this.setState({ memberDiseaseLoad: false })

      saveError({
        isShown: true,
        status: statusDiseases,
        message: errorDiseasesMessage,
      })
      return
    }

    const diseaseTypes = []

    let i = 1
    getDiseaseType.forEach((item) => {
      diseaseTypes.push({
        id: `dc${i}`,
        title: item,
      })
      i += 1
    })

    this.setState({
      memberDisease: memberDisease.length > 0 ? memberDisease : [],
      diseaseTypes,
      memberDiseaseLoad: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.memberDiseaseSave !== this.state.memberDiseaseSave) {
      this.onDismiss('default')
      this.props.navigation.getParent().pop()
      this.setState({ showModal: false })
    }
  }

  toggleNextButtonColor = (allowSubmit) => {
    const style = {
      backgroundColor: this.colorSettings?.btn_filled_disabled_1 || 'white',
      borderColor: this.colorSettings?.btn_filled_disabled_1,
    }

    if (allowSubmit) {
      style.backgroundColor =
        this.colorSettings?.bottom_next_btn_enabled || 'white'
      style.borderColor = this.colorSettings?.bottom_next_btn_enabled
    }

    return style
  }

  saveMemberDisease = async () => {
    const { authCredentials } = this.proband.account
    const memberId = this.memberProfile.memberID
    const diseases = this.state.memberDisease.map((disease) => ({
      disease_id: disease.disease_id,
      age_diagnosed: disease.age_diagnosed,
      skip_logic: disease.skip_logics.map((skipLogic) => ({
        skip_logic_id: skipLogic.skip_logic_id,
        answer: skipLogic.answer,
      })),
    }))

    const updateMemberDiseaseBulkPayload = {
      path: `member/${memberId}/add_disease_bulk/`,
      method: 'post',
      token: authCredentials,
      body: {
        proband_id: parseInt(this.proband.probandID),
        diseases,
      },
      pageDetails: {
        page: 'DiseaseTypes.main.js',
      },
    }

    const response = await apiFetchHandler(updateMemberDiseaseBulkPayload)

    if (response.isError) return

    this.setState({ memberDiseaseSave: true })
  }

  navigateToDiseaseList = (diseaseList, diseaseCategory, title) => {
    const { navigation } = this.props

    navigation.navigate('DiseasesList', {
      diseaseList,
      diseaseCategory,
      diseaseName: title,
      memberProfile: this.memberProfile,
      updateMemberDisease: this.updateMemberDisease,
      proband: this.proband,
      existingMemberDiseases: this.state.memberDisease,
      onDismiss: this.onDismiss,
    })
  }

  showInstructions = () => {
    showDefaultAlert(
      i18n.t('disease').title,
      i18n.t('this_includes_any_type_of_health_problem_disease_diagnosis_etc')
        .default
    )
  }

  // update member disease from disease list
  updateMemberDisease = (item, diseaseCategory) => {
    const { memberDisease } = this.state
    const objIndex = this.state.memberDisease.findIndex(
      (item) => item.disease_type === diseaseCategory
    )
    const diseaseFilter = memberDisease.filter(
      (item) => item.disease_type !== diseaseCategory
    )

    if (objIndex > -1) {
      diseaseFilter.push(...item)
      return this.setState({ memberDisease: diseaseFilter })
    }

    memberDisease.push(...item)
    return this.setState({ memberDisease })
  }

  // remove member disease
  deleteMemberDisease = (disease) => {
    const memberDisease = this.state.memberDisease.filter(
      (item) => item.disease_id !== disease.disease_id
    )

    this.setState({ memberDisease })
  }

  // edit disease coming from skiplogic
  editMemberDisease = (disease) => {
    const { memberDisease } = this.state
    const objIndex = this.state.memberDisease.findIndex(
      (item) => item.disease_id === disease.disease_id
    )

    if (objIndex > -1) {
      memberDisease.splice(objIndex, 1)
      memberDisease.push(disease)
      this.setState({ memberDisease })
    }
  }

  // navigate to skip Logic
  handleNavigateToSkipLogic = (memberDisease) => {
    const { navigation, diseaseCategoryList } = this.props

    const disease = diseaseCategoryList?.diseases.find(
      (item) => item.id === memberDisease.disease_id
    )

    navigation.navigate('EditMemberDisease', {
      memberDisease: [memberDisease],
      diseaseList: disease,
      onUpdateMemberDisease: this.editMemberDisease,
      memberProfile: this.memberProfile,
      proband: this.proband,
      existingMemberDiseases: this.state.memberDisease,
      onDismiss: this.onDismiss,
      editFromMain: true,
    })
  }

  renderCategoryItem = (itemData) => {
    if (!this.state.memberDiseaseLoad) {
      return
    }

    let name = itemData.item.title
      .trim()
      .toLowerCase()
      .replace(/\//g, ' ')
      .replace(/[^\w-\s]/gi, '')
      .replace(/\s/g, '_')
      .replace(/_conditions/, '')

    if (
      !itemData.item.title.includes('cancer') ||
      !itemData.item.title.includes('polyps')
    ) {
      name += '_conditions'
    }

    return (
      <DiseaseCategoryButton
        colorSettings={this.colorSettings}
        title={i18n.t(name).title || ''}
        memberDisease={this.state.memberDisease}
        nameId={itemData.item.title}
        onPress={this.navigateToDiseaseList}
        onDeleteMemberDisease={this.deleteMemberDisease}
        onEdit={this.handleNavigateToSkipLogic}
        fromMain
      />
    )
  }

  headerComponent = () => (
    <View style={{ alignItems: 'center', width: '100%' }}>
      <Text
        style={[
          global_styles.titleText,
          {
            color: this.colorSettings?.text_input_label_color_2,
          },
        ]}
      >
        {
          i18n.t(
            'has_this_person_ever_been_diagnosed_with_any_of_the_following_types_of_conditions_qm'
          ).default
        }
      </Text>
      <TouchableOpacity style={{ margin: 20 }} onPress={this.showInstructions}>
        <Image
          source={require('$assets/images/new_assets/icon-info-white.png')}
          style={{ width: 16, height: 16, resizeMode: 'contain' }}
        />
      </TouchableOpacity>
      <Text
        style={[
          global_styles.titleHelperText,
          {
            color: this.colorSettings?.text_input_label_color_2,
          },
        ]}
      >
        {i18n.t('select_the_type_of_disease').default}
      </Text>
      <View style={{ height: 30 }} />
    </View>
  )

  footerComponent = () => (
    <View style={{ width: '100%' }}>
      {/* {this.handleShowButton ()} */}
      {/* {this.handleHiddenDisease ()} */}
      <View style={{ height: 60, width: '100%' }}>
        <TouchableOpacity
          style={[global_styles.nextButton, { opacity: 1.0 }]}
          onPress={() => {}}
          disabled={false}
        >
          <Text style={global_styles.nextButtonText}>
            {i18n.t('done').title}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )

  handleOnExit = async () => {
    this.setState({ showModal: true })
    await this.saveMemberDisease()
  }

  render() {
    let loadDiseaseCategory = (
      <LoadingView
        backgroundColor={this.colorSettings?.onboarding_bgcolor}
        tintColor={this.colorSettings?.btn_no_fill_text_2}
        textColor={this.colorSettings?.btn_no_fill_text_2}
        visible
      />
    )

    if (this.state.memberDiseaseLoad) {
      loadDiseaseCategory = (
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <View style={{ flex: 1 }}>
            <FlatList
              keyExtractor={(item, index) => `${item.id}`}
              data={this.state.diseaseTypes}
              renderItem={this.renderCategoryItem}
              style={{ width: '100%' }}
              ListHeaderComponent={this.headerComponent}
              ListHeaderComponentStyle={{ width: '100%' }}
              // ListFooterComponent={this.footerComponent}
              // ListFooterComponentStyle={{width: '100%'}}
              contentContainerStyle={{
                paddingHorizontal: 16,
                paddingVertical: 40,
                width: '100%',
              }}
            />
          </View>

          <View
            style={{
              height: 60,
              width: '100%',
              alignSelf: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <View style={{ height: 60, width: '100%' }}>
              <TouchableOpacity
                style={[
                  global_styles.nextButton,
                  {
                    backgroundColor:
                      this.colorSettings?.bottom_next_btn_enabled || 'white',
                    borderColor: this.colorSettings?.bottom_next_btn_enabled,
                  },
                ]}
                onPress={() => this.handleOnExit()}
              >
                <Text
                  style={[
                    global_styles.nextButtonText,
                    {
                      color: this.colorSettings?.btn_filled_text_1,
                    },
                  ]}
                >
                  {i18n.t('save').title}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )
    }

    return (
      <SafeAreaView
        style={[
          styles.container,
          {
            backgroundColor: this.colorSettings?.onboarding_bgcolor,
          },
        ]}
      >
        {loadDiseaseCategory}

        <Modal visible={this.state.showModal} animationType="none" transparent>
          <View
            style={{
              flex: 1.0,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingView
              backgroundColor={this.colorSettings?.onboarding_bgcolor}
              tintColor={this.colorSettings?.btn_no_fill_text_2}
              textColor={this.colorSettings?.btn_no_fill_text_2}
              visible={this.state.showModal}
              message={i18n.t('saving_datanplease_wait').default}
            />
          </View>
        </Modal>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
    width: '100%',
  },
  contentContainer: {
    // backgroundColor: "red",
    // marginHorizontal: 18,
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    diseaseCategory: store.diseaseCategory,
    diseaseCategoryList: store.diseaseCategoryList,
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    selectedLanguage: store.selectedLanguage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapGlobalStateToProps, mapDispatchToProps)(DiseaseTypes)
