import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainContainer: {
    height: 60,
    width: '100%',
    justifyContent: 'flex-end',
  },
})

export default styles
