import { Text, View, TouchableOpacity } from 'react-native'
import { i18n } from '$localization/config.js'
import styles from '$assets/styles/global.styles.js'

export default function LanguageItem({
  item,
  index,
  selectedItem,
  onPressItem,
}) {
  const COLORS = {
    WHITE: 'white',
    SELECTED_BACKGROUND: '#ccc',
  }
  const backgroundColor =
    selectedItem === item ? COLORS.SELECTED_BACKGROUND : COLORS.WHITE

  const languageText = i18n.t(item).title

  return (
    <View key={`${index}`} style={styles.sectionItemContainer(backgroundColor)}>
      <View style={styles.sectionItemHorizontalContainer}>
        <TouchableOpacity
          style={styles.sectionItemTitle}
          activeOpacity={1.0}
          onPress={() => onPressItem(item)}
        >
          <Text style={styles.sectionItemLabelText}>{languageText}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
