import { Animated, View } from 'react-native'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './styles'
import CustomSaeTextInput from '$components/CustomSaeTextInput'
import { i18n } from '$localization/config.js'
import RememberMeSection from './components/RememberMeSection'
import LoginError from './components/LoginError'
import ShowPasswordButton from './components/ShowPasswordButton'
import { IS_MOBILE } from '$constants/platforms'

const ERROR_TYPE = {
  EMAIL: 'emailError',
  EMPTY: 'emptyError',
}

const LOGIN_INPUT_ACCESSORY_VIEW_ID = 'LoginInputAccessoryViewID'
const LOGIN_INPUT_NATIVE_ID_EMAIL = 'login_screen_input_email'
const LOGIN_INPUT_NATIVE_ID_PASSWORD = 'login_screen_input_password'

function LoginForm({
  loginButtonAction,
  email,
  password,
  loginError,
  setEmail,
  setPassword,
  setRememberMe,
  rememberMe,
}) {
  const emailRef = useRef('')
  const passwordRef = useRef('')

  const [showPassword, setShowPassword] = useState(false)
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const showEmailError = loginError === ERROR_TYPE.EMAIL
  const showPasswordError = loginError === ERROR_TYPE.EMPTY

  const inputDefaultColorSettings = {
    borderBottomColor: colorSettings?.text_input_label_color_1,
    labelColor: colorSettings?.text_input_label_color_1,
    textInputFieldColor: colorSettings?.text_input_color_1,
  }

  const handleTabEventInputChange = (event) => {
    if (IS_MOBILE() || event.code !== 'Tab') return

    event.preventDefault()
    passwordRef.current?.focusTextInput()
  }

  return (
    <Animated.View style={styles.formContainer}>
      <View style={styles.formSectionContainer}>
        <View style={styles.formSection}>
          <View style={styles.formSectionRowContainer}>
            <CustomSaeTextInput
              ref={emailRef}
              colorSettings={inputDefaultColorSettings}
              inputAccessoryViewID={LOGIN_INPUT_ACCESSORY_VIEW_ID}
              label={i18n.t('email').default}
              keyboardType="email-address"
              onSubmitEditing={() => passwordRef.current?.focusTextInput()}
              onChangeText={setEmail}
              value={email || ''}
              nativeID={LOGIN_INPUT_NATIVE_ID_EMAIL}
              onKeyPress={(event) => handleTabEventInputChange(event)}
            />
          </View>
          <LoginError shouldShowError={showEmailError} />
        </View>

        <View style={styles.formSection}>
          <View style={styles.formSectionRowContainer}>
            <CustomSaeTextInput
              ref={passwordRef}
              colorSettings={inputDefaultColorSettings}
              inputAccessoryViewID={LOGIN_INPUT_ACCESSORY_VIEW_ID}
              label={i18n.t('password').default}
              secureTextEntry={!showPassword}
              onSubmitEditing={loginButtonAction}
              onChangeText={setPassword}
              value={password || ''}
              nativeID={LOGIN_INPUT_NATIVE_ID_PASSWORD}
            />

            <ShowPasswordButton
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
          </View>
          <LoginError shouldShowError={showPasswordError} />
        </View>

        <RememberMeSection
          setRememberMe={setRememberMe}
          rememberMe={rememberMe}
        />
      </View>
    </Animated.View>
  )
}

export default LoginForm
