import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'

import { adjustFontSizeByLength } from '$utils'
import { i18n } from '$localization/config.js'

import RoundedButton from '$components/RoundedButton'
import LabelText from '../LabelText'

import styles from './styles'

import ComponentGenerator from '../../ComponentGenerator'

import FieldViewer from '../../FieldViewer'
import updateSurveyAnswers from '../../../helpers/updateSurveyAnswers'

const DEFAULTS = {
  ANSWER: null,
  ACTIVE_COLOR: 'rgba(0,0,0,0.2)',
  FIELD_TYPE: 'yes_or_no',
  MASTER_QUESTION_CHOICE_ID: null,
  YES_STRING: 'yes',
  NO_STRING: 'no',
}

export default function YesOrNoField({
  colorSettings,
  setContainerStyle,
  masterQuestion,
  answer,
  surveyData,
  storeFieldSurveyAnswers = () => {},
  deleteAnswer,
}) {
  const defaultValue = answer?.answer || DEFAULTS.ANSWER

  const [isToggled, setIsToggled] = useState(defaultValue)
  const [hasRendered, setHasRendered] = useState(false)

  const { question_label: questionLabel } = masterQuestion

  const handleButtonHighLight = (toggle, value) => {
    const defaultColor = colorSettings?.selectable_btn_border_active_1
    const defaultBorderColor = colorSettings?.selectable_btn_border_inactive_1
    const activeColor =
      colorSettings?.selectable_btn_active_1 || DEFAULTS.ACTIVE_COLOR
    const isToggleNull = toggle === null
    const isToggleEqualToValue = toggle === value

    if (isToggleNull || !isToggleEqualToValue) {
      return { backgroundColor: defaultColor, borderColor: defaultBorderColor }
    }

    if (isToggleEqualToValue) {
      return { backgroundColor: activeColor, borderColor: defaultColor }
    }

    return { backgroundColor: '', borderColor: '' }
  }

  const buildPayload = (getAnswer, repeatClick) => {
    const payload = {
      field_type: DEFAULTS.FIELD_TYPE,
      master_question_id: masterQuestion.id,
      master_question_choice_id: DEFAULTS.MASTER_QUESTION_CHOICE_ID,
      answer: getAnswer,
      repeat_click: repeatClick,
    }

    return payload
  }

  const onPressHandler = (value) => {
    if (!hasRendered) {
      setHasRendered(true)
    }

    deleteAnswer(masterQuestion)

    if (isToggled === value) {
      setIsToggled('')

      const payload = buildPayload(null, true)

      storeFieldSurveyAnswers(payload)

      return
    }

    setIsToggled(value)

    const payload = buildPayload(value, false)

    storeFieldSurveyAnswers(payload)
  }

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  const title = LabelText({
    questionLabel: i18n.t(questionLabel).default || questionLabel,
    colorSettings,
    masterQuestion,
  })

  return (
    <View style={[setContainerStyle]}>
      <FieldViewer fieldName="yes/no field" />
      {title}

      <View style={[styles.buttonContainerStyle]}>
        <RoundedButton
          onPress={() => onPressHandler(DEFAULTS.YES_STRING)}
          buttonStyle={[
            styles.buttonStyle,
            handleButtonHighLight(isToggled, DEFAULTS.YES_STRING),
          ]}
        >
          <Text
            style={styles.roundedButtonTitleText(
              colorSettings,
              adjustFontSizeByLength(capitalizeFirstLetter(DEFAULTS.YES_STRING))
            )}
          >
            {i18n.t(DEFAULTS.YES_STRING).title}
          </Text>
        </RoundedButton>
        <RoundedButton
          onPress={() => onPressHandler(DEFAULTS.NO_STRING)}
          buttonStyle={[
            styles.buttonStyle,
            handleButtonHighLight(isToggled, DEFAULTS.NO_STRING),
          ]}
        >
          <Text
            style={styles.roundedButtonTitleText(
              colorSettings,
              adjustFontSizeByLength(capitalizeFirstLetter(DEFAULTS.NO_STRING))
            )}
          >
            {i18n.t(DEFAULTS.NO_STRING).title}
          </Text>
        </RoundedButton>
      </View>

      {isToggled === 'yes' && (
        <ComponentGenerator
          childQuestionnaire={masterQuestion.child_questionnaire}
          surveyObject={updateSurveyAnswers({ surveyData, hasRendered })}
        />
      )}
    </View>
  )
}

// ** typescript reference **

// YesOrNoField.propTypes = {
//   masterQuestion: PropTypes.isRequired,
//   colorSettings: PropTypes.objectOf(PropTypes.string),
//   answer: PropTypes.number,
//   submitSurveyResponse: PropTypes.func,
//   setContainerStyle: PropTypes.objectOf(PropTypes.string),
// }

// YesOrNoField.defaultProps = {
//   colorSettings: [],
//   answer: '',
//   submitSurveyResponse: () => {},
//   setContainerStyle: {},
// }
