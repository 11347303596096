/* React & React Native libraries */
import { View, SafeAreaView, SectionList, Platform } from 'react-native'

import { HeaderBackButton } from '@react-navigation/elements'

import { connect } from 'react-redux'

import GenericFamilyListView from '../_components/GenericFamilyList'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$components/LoadingView'
import NextButton from '$components/NextButton'
import MainWrapper from '$components/MainWrapper'

import TutorialModal from './_components/TutorialModal'
import ExtendedHeaderView from '$screens/relatives/refactored/_components//ExtendedHeaderView'
import GenericHeaderControls from '$screens/relatives/refactored/_components/GenericHeaderControls'

import MemberProfile from '$data_models/MemberProfile.js'

import { apiFetchHandler } from '$api'

import { relationshipTypes, platforms } from '$constants'

/* Custom Utilities */
import { i18n } from '$localization/config.js'

import { storeCurrentMember } from '$redux/member_profile/actions.js'

import {
  setBasicFamilyMembers,
  setShouldReloadFamilyList,
} from '$redux/proband/actions.js'

import { setError } from '$redux/defaults/actions.js'

import { saveLastScreen } from '$screens/utils'

/* Custom Stylesheets */
import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'
import { IS_MOBILE } from '$constants/platforms'
import AvatarsBottomSheet from '../../../main/family/FamilyListScreen/components/AvatarsBottomSheet'
import { createRef } from 'react'

const webHeightAdjustment = -50
const getScrollViewHeight = () =>
  calculateAvailableContentHeight(webHeightAdjustment)
let isLastScreenBeforeLogin = false

class PaternalAuntsUnclesListController extends GenericFamilyListView {
  /** Note: Do not change. These keys are strictly tied to an API response. */
  sectionListOrder = {
    [relationshipTypes.AUNTSUNCLES_DADS_SIDE]: 0,
  }

  avatarBottomSheetRef = createRef()

  state = {
    ...this.state,
    allowFamilyInvite: false,
    showTutorialModal: true,
    currentMemberAvatar: null,
    modalVisible: false,
  }

  componentDidMount(...args) {
    super.componentDidMount.apply(this, args)
    const { lastScreenLogin, navigation } = this.props
    isLastScreenBeforeLogin =
      lastScreenLogin === RAW_LAST_VISITED_SCREENS.relatives_paternal_side

    const higherScreenList = [
      'relatives_paternal_side',
      'relatives_maternal_side',
    ]

    const screenExist = higherScreenList.find(
      (screen) => screen === lastScreenLogin
    )
    const isModalShown = this.props.route.params?.isModalShown ?? true

    if (screenExist) {
      this.setState({ showTutorialModal: false })
    } else {
      this.setState({ showTutorialModal: isModalShown })
    }
  }

  getBasicFamilyMembers = async () => {
    const { familyID, headers, payload } = super
      .constructor(this.props)
      .getBasicFamilyMembersPayload()

    if (!familyID) return

    /* Initialize Family data */
    const newFamilyData = (this.familyData = [
      { title: relationshipTypes.AUNTSUNCLES_DADS_SIDE, data: [] },
    ])

    /* Activate loading view */
    this.reloadAndRenderView()

    /* API Request to load basic Family Members */
    const getBasicFamilyMembers = {
      path: `family/${familyID}/members/`,
      method: 'post',
      token: headers.accessToken,
      body: { ...payload },
      pageDetails: {
        page: 'PaternalAuntsUnclesList index.js',
      },
    }

    const response = await apiFetchHandler(getBasicFamilyMembers)
    const { saveError } = this.props

    if (response.isError) {
      this.reloadAndRenderView(false)
      this.setState({ sectionListRefreshing: false })
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    /** 
         What to do here?
         * Update family data on Redux.
         * Update the data on SectionList.
         * Stop refreshing of SectionList.
         * Stop loading view.
        */

    const _keys = Object.keys(response)

    // Loop through each family member by relationship to Proband.
    KeyLoop1: for (const i in _keys) {
      const relationshipToProband = `${_keys[i]}`.toLowerCase()

      switch (relationshipToProband) {
        case relationshipTypes.PATERNAL_AUNTS_UNCLES:
          var memberList = response[relationshipToProband]

          /* Modify relationship to proband */
          var _relationshipToProband_ = relationshipTypes.AUNTSUNCLES_DADS_SIDE
          var SECTION_KEY = this.sectionListOrder[_relationshipToProband_]

          const parentsData =
            response?.parents?.map((sibling) => ({
              member: { ...sibling },
            })) ?? []

          newFamilyData[SECTION_KEY] = {
            ...newFamilyData[SECTION_KEY],
            data: this.addToFamilyData(memberList, payload, headers),
            parentsData,
          }

          break KeyLoop1

        default:
          /* Skip for unspecified relationshipTypes */
          continue KeyLoop1
      }
    }

    const familyData = await super.constructor(this.props).getMembers()

    const reduxTreeFamilyData = familyData.filter(
      (data) => data.title === relationshipTypes.AUNTSUNCLES_DADS_SIDE
    )

    this.props._setBasicFamilyMembers_(this.familyData)

    this.reloadAndRenderView(false)

    this.setState({
      sectionListRefreshing: false,
      familyData: reduxTreeFamilyData,
    })
  }

  nextButtonAction = async () => {
    const { account, authCredentials, saveError, navigation } = this.props
    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.relatives_paternal_side,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })
    super.constructor(this.props).nextButtonAction()

    this.navigateTo('MaternalAuntsUnclesList')
  }

  sendAvatarPayload = async (avatarData) => {
    const { currentMemberAvatar } = this.state
    const { authCredentials, saveError } = this.props

    const payload = {
      member_id: currentMemberAvatar,
      skin_tone: avatarData?.skinTone,
      icon: avatarData?.icon,
    }

    const getAvatarPayload = {
      path: `member_avatar/add_member_avatar/`,
      method: 'post',
      token: authCredentials?.accessToken,
      pageDetails: {
        page: 'FamilyListScreen.js',
      },
      body: {
        ...payload,
      },
    }

    try {
      const response = await apiFetchHandler(getAvatarPayload)

      if (response.isError) {
        setTimeout(() => {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
        }, 100)

        return null
      }

      await this.getBasicFamilyMembers()
      return IS_MOBILE() && this.avatarBottomSheetRef.current.close()
    } catch (error) {
      console.log(error)
    }
  }

  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible })
  }

  handleAvatarClick = (member) => {
    this.setState({ currentMemberAvatar: member.member_id })

    if (IS_MOBILE()) {
      this.avatarBottomSheetRef.current.expand()
    } else {
      this.setModalVisible(true)
    }
  }

  toggleTutorialModal = (showTutorialModal = false) => {
    this.setState({ showTutorialModal })
  }
}

class PaternalAuntsUnclesListView extends PaternalAuntsUnclesListController {
  renderSectionHeader = (section) =>
    super.constructor(this.props).renderSectionHeader(section)

  renderItem = ({ item, index, section, handleAvatarClick }) =>
    super
      .constructor(this.props)
      .renderItem({ item, index, section, handleAvatarClick })

  render() {
    const { colorSettings, navigation } = this.props
    const message =
      this.allMembersList.length > 0
        ? i18n.t(
            'be_sure_to_add_details_about_anyone_who_had_a_disease_or_genetic_testing'
          )?.default
        : i18n.t('now_let’s_add_your_aunts_and_uncles_on_your_fathers_side')
            ?.default

    return (
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={[
            global_styles.containerWhite,
            {
              ...Platform.select({
                [platforms.WEB]: {
                  flex: null,
                  height: getScrollViewHeight(),
                },
              }),
            },
          ]}
        >
          {/* Custom Header */}
          <ExtendedHeaderView
            colorSettings={colorSettings}
            message={message}
            controlsView={
              <GenericHeaderControls
                colorSettings={colorSettings}
                primaryButtonAction={({ relationshipToProband }) => {
                  this.requestAddRelative({
                    relationshipToProband,
                    familySide: relationshipTypes.PATERNAL,
                  })
                }}
                leftButton={{ key: relationshipTypes.UNCLE }}
                rightButton={{ key: relationshipTypes.AUNT }}
              />
            }
          />

          {/* Content */}
          <View style={[global_styles.content, { flex: 1.0 }]}>
            <SectionList
              nativeID="web_scaled_main_container"
              style={{
                ...Platform.select({
                  [platforms.WEB]: {
                    height: getScrollViewHeight(),
                  },
                }),
              }}
              bounces
              contentContainerStyle={{ paddingBottom: 16 }}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              sections={this.state.familyData}
              keyExtractor={(item, index) => index}
              renderSectionHeader={({ section }) =>
                this.renderSectionHeader(section)
              }
              renderItem={({ item, index, section }) =>
                this.renderItem({
                  item,
                  index,
                  section,
                  handleAvatarClick: this.handleAvatarClick,
                })
              }
              refreshing={this.state.sectionListRefreshing}
            />
          </View>

          {/* Next button */}
          <NextButton
            buttonStyle={[
              global_styles.nextButton,
              {
                backgroundColor: colorSettings?.bottom_next_btn_enabled,
                borderColor: colorSettings?.bottom_next_btn_enabled,
              },
            ]}
            buttonTitleStyle={[
              global_styles.nextButtonText,
              {
                color: colorSettings?.bottom_next_btn_text_enabled,
              },
            ]}
            buttonTitle={i18n.t('next')?.title}
            primaryAction={() => this.nextButtonAction()}
          />

          <AvatarsBottomSheet
            avatarBottomSheetRef={this.avatarBottomSheetRef}
            sendAvatarPayload={this.sendAvatarPayload}
            setModalVisible={this.setModalVisible}
            modalVisible={this.state.modalVisible}
          />

          {/* Loading View */}
          {this.state.showLoadingView && (
            <LoadingView
              backgroundColor={colorSettings?.splash_bgcolor}
              tintColor={colorSettings?.btn_no_fill_border_1}
              textColor={colorSettings?.btn_no_fill_text_1}
            />
          )}

          {/* Tutorial modal */}
          {this.state.showTutorialModal && (
            <TutorialModal
              onDismiss={() => this.toggleTutorialModal(false)}
              visible={this.state.showTutorialModal}
            />
          )}
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    lastScreenLogin: store.lastScreenLogin,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    account: accountStore.account,
    /** Proband Store */
    proband: probandStore.proband,
    shouldReloadFamilyList: probandStore.shouldReloadFamilyList,
    basicFamilyMembers: probandStore.basicFamilyMembers,
    /** MemberProfile Store */
    memberProfile: memberProfileStore.memberProfile,
    currentMember: memberProfileStore.currentMember,
  }
}

const mapDispatchToProps = (dispatch) => ({
  _setShouldReloadFamilyList_: (data) =>
    dispatch(setShouldReloadFamilyList(data)),
  _saveCurrentMemberToStore_: (data) => dispatch(storeCurrentMember(data)),
  _setBasicFamilyMembers_: (data) => dispatch(setBasicFamilyMembers(data)),
  saveError: (data) => dispatch(setError(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaternalAuntsUnclesListView)
