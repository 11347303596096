import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web

import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import headerNavigationDefaultOptions from '../_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '../_components/HeaderTitleComponent'
import LeftButtonComponent from '../_components/LeftButtonComponent'
import AddFamilyMember from '../../screens/main/family/AddFamilyMemberScreen/AddFamilyMember'

const Stack = createStackNavigator()

const AddMemberNavigator = () => {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const isCustomWorkflow = useSelector(
    (state) => state.customFlowRoutesStore.isCustomWorkflow
  )

  return (
    <Stack.Navigator
      initialRouteName="AddFamilyMemberView"
      screenOptions={{ presentation: 'card' }}
    >
      <Stack.Screen
        name="AddFamilyMemberView"
        component={AddFamilyMember}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => {
            const headerTitle = isCustomWorkflow
              ? i18n.t('family').default
              : i18n.t('add_relative').default

            return (
              <HeaderTitleComponent
                headerTitle={headerTitle || ''}
                headerTintColor={colorSettings?.nav_title_light}
                headerBackgroundColor={
                  colorSettings?.post_onboarding_nav_bgcolor
                }
              />
            )
          },

          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

export default AddMemberNavigator
