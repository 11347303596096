export const SET_AUTH_CREDENTIALS = 'SET_AUTH_CREDENTIALS'
export const CLEAR_AUTH_CREDENTIALS = 'CLEAR_AUTH_CREDENTIALS'

export const SET_ACCOUNT = 'SET_ACCOUNT'
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT'
export const SET_IS_LOGOUT = 'SET_IS_LOGOUT'
export const SET_IS_ACCOUNT_DELETED = 'SET_IS_ACCOUNT_DELETED'
export const SET_ACCOUNT_EMAIL = 'SET_ACCOUNT_EMAIL'
export const SET_ACCOUNT_SIGNUP_DETAILS = 'SET_ACCOUNT_SIGNUP_DETAILS'
export const SET_ACCOUNT_PASSWORD = 'SET_ACCOUNT_PASSWORD'
export const SET_ACCOUNT_FIRST_NAME = 'SET_ACCOUNT_FIRST_NAME'
export const SET_ACCOUNT_LAST_NAME = 'SET_ACCOUNT_LAST_NAME'
export const SET_ACCOUNT_DOB = 'SET_ACCOUNT_DOB'
export const SET_ACCOUNT_AGE = 'SET_ACCOUNT_AGE'
export const SET_DIALING_CODE = 'SET_DIALING_CODE'
export const SET_DIALING_NUMBER = 'SET_DIALING_NUMBER'
export const SET_ACCOUNT_USER_ID = 'SET_ACCOUNT_USER_ID'
export const SET_ACCOUNT_USER_ACCOUNT_ID = 'SET_ACCOUNT_USER_ACCOUNT_ID'

export const SET_CUSTOM_WORKFLOW_ACCOUNT_STATUS =
  'SET_CUSTOM_WORKFLOW_ACCOUNT_STATUS'
