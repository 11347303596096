import { translations } from '$localization/config'

const handleLanguage = (selected) => {
  const supportedLanguages = Object.keys(translations)
  const defaultLanguage =
    supportedLanguages.find(
      (supportedLanguage) => supportedLanguage === selected
    ) || 'en'

  return defaultLanguage
}

export { handleLanguage }
