import { Platform, NativeModules } from 'react-native'
import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/en-AU'
import 'intl/locale-data/jsonp/en-IE'
import 'intl/locale-data/jsonp/en-GB'

export default function formatDate(useMonthName = true, separator = ' ') {
  const locale = Platform.select({
    ios:
      NativeModules.SettingsManager?.settings.AppleLocale ||
      NativeModules.SettingsManager?.settings.AppleLanguages[0], // ios 13
    android: NativeModules.I18nManager?.localeIdentifier || 'en_US',
  })

  const monthFormat = useMonthName ? 'MMM' : 'MM'
  let dateFormat = [monthFormat, 'DD', 'YYYY']

  if (['en-IE', 'en-GB', 'en-AU'].includes(locale)) {
    dateFormat = ['DD', monthFormat, 'YYYY']
  }

  return dateFormat.join(separator)
}
