import { Text, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/Feather'
import { useSelector } from 'react-redux'
import styles from './styles'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import ButtonToggle from '$components/ButtonToggle'
import { i18n } from '$localization/config.js'
import { GENE_QUESTIONS, INFO, BLACK } from './constants'

function SubQuestion({ handleSubQuestion, subQuestionValue }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const currentMember = useSelector(
    (state) => state.memberProfileStore.currentMember
  )
  const ALERTS_MESSAGES = {
    TITLE: i18n.t('test_positive').default,
    MESSAGE: i18n.t(
      'testing_positive_means_that_a_change_or_mutation_was_found_in_the_gene_this_could_also_be_worded_as_pathogenic_likely_pathogenic_deleterious_or_likely_deleterious_on_a_report'
    ).default,
  }

  const probandProfile = useSelector(
    (state) => state.probandStore.probandProfile
  )
  const isCurrentUser =
    probandProfile?.memberID === currentMember?.member_id
      ? GENE_QUESTIONS.YOU
      : GENE_QUESTIONS.OTHERS

  const handleToolTip = () => {
    showDefaultAlert(ALERTS_MESSAGES.TITLE, ALERTS_MESSAGES.MESSAGE)
  }

  return (
    <View>
      <Text style={styles.subQuestionTitle(colorSettings)}>
        {i18n.t(isCurrentUser).default}
      </Text>
      <TouchableOpacity style={styles.toolTipButton} onPress={handleToolTip}>
        <Icon
          name={INFO}
          size={20}
          color={colorSettings?.text_input_label_color_2 || BLACK}
        />
      </TouchableOpacity>
      <ButtonToggle
        colorSettings={colorSettings}
        onPress={(value) => handleSubQuestion(value)}
        setContainerStyle={styles.buttonToggleCustomStyle}
        isSelected={subQuestionValue}
      />
    </View>
  )
}

export default SubQuestion
