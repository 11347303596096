import { SafeAreaView, ScrollView } from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import styles from './assets/styles'
import AncestrySearchModal from './components/AncestrySearchModal'
import { firstListDiff, secondListDiff } from './helpers'
import { setError } from '$redux/defaults/actions.js'
import useAncestry from './hooks'
import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { saveLastScreen } from '../../utils'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'
import NextButtonAbsolute from '$components/NextButtonAbsolute'
import AncestryInputSection from './components/AncestryInputSection'

export const PARENTS = {
  MOTHER: 'mother',
  FATHER: 'father',
}

function AncestryScreen() {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const [showModal, setShowModal] = useState(false)
  const [motherAncestryList, setMotherAncestryList] = useState([])
  const [fatherAncestryList, setFatherAncestryList] = useState([])
  const [selectedParent, setSelectedParent] = useState('')

  const colorSettings = useSelector((state) => state.store.colorSettings)
  const probandProfile = useSelector(
    (state) => state.probandStore.probandProfile
  )
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const account = useSelector((state) => state.accountStore.account)

  const saveError = (error) => dispatch(setError(error))

  const {
    getParentAncestry,
    addAncestries,
    removeAncestries,
    removeParentsAncestries,
    updateAncestry,
  } = useAncestry()

  const selectedParentAncestry =
    selectedParent === PARENTS.MOTHER ? motherAncestryList : fatherAncestryList

  const fetchParentsAncestry = async () => {
    const { mothersAncestry, fathersAncestry } = await getParentAncestry()

    setMotherAncestryList(mothersAncestry)
    setFatherAncestryList(fathersAncestry)
  }

  const handleOnModalPress = (selectedParentSide) => {
    setShowModal(true)
    setSelectedParent(selectedParentSide)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedParent('')
  }

  const handleAddingAncestry = async (selectedAncestries) => {
    try {
      const { parent, ancestryData } = selectedAncestries

      const selectedParentAncestryList =
        parent === PARENTS.MOTHER ? motherAncestryList : fatherAncestryList

      const toAddSelection = firstListDiff(
        ancestryData,
        selectedParentAncestryList
      )
      const toRemoveSelection = secondListDiff(
        ancestryData,
        selectedParentAncestryList
      )

      await addAncestries(parent, toAddSelection)
      await removeAncestries(parent, toRemoveSelection)
    } catch (error) {
      console.log(error)
    }
  }

  const handleRemoveAncestry = async (parent, ancestry) => {
    try {
      await removeParentsAncestries(parent, ancestry)
    } catch (error) {
      console.log(error)
    } finally {
      await fetchParentsAncestry()
    }
  }

  const handleSubmitAncestryList = async (selectedAncestryList) => {
    if (selectedAncestryList.length === 0) return

    try {
      if (selectedParent === PARENTS.FATHER) {
        await handleAddingAncestry({
          parent: PARENTS.FATHER,
          ancestryData: selectedAncestryList,
        })

        setFatherAncestryList(selectedAncestryList)
      } else {
        await handleAddingAncestry({
          parent: PARENTS.MOTHER,
          ancestryData: selectedAncestryList,
        })

        setMotherAncestryList(selectedAncestryList)
      }
    } catch (error) {
      console.log(error)
    } finally {
      await fetchParentsAncestry()
      setShowModal(false)
    }
  }

  const nextButtonAction = async () => {
    const motherID = probandProfile.relationshipData.mother.id
    const fatherID = probandProfile.relationshipData.father.id
    const probandID = probandProfile.memberID

    if (fatherAncestryList !== null && fatherAncestryList.length !== 0) {
      await updateAncestry(
        PARENTS.FATHER,
        probandID,
        fatherID,
        fatherAncestryList
      )
    }

    if (motherAncestryList !== null && motherAncestryList.length !== 0) {
      await updateAncestry(
        PARENTS.MOTHER,
        probandID,
        motherID,
        motherAncestryList
      )
    }

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.ancestry,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.SuccessScreenPersonalHealthInfo.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  useEffect(() => {
    fetchParentsAncestry().catch((error) => console.log(error))
  }, [])

  return (
    <ErrorBoundary>
      <SafeAreaView style={styles.container(colorSettings)}>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={styles.contentContainer}
        >
          <AncestryInputSection
            handleRemoveAncestry={handleRemoveAncestry}
            handleOnModalPress={handleOnModalPress}
            parentSide={PARENTS.MOTHER}
            ancestryList={motherAncestryList}
          />

          <AncestryInputSection
            handleRemoveAncestry={handleRemoveAncestry}
            handleOnModalPress={handleOnModalPress}
            parentSide={PARENTS.FATHER}
            ancestryList={fatherAncestryList}
          />
        </ScrollView>

        <NextButtonAbsolute onPressAction={nextButtonAction} />

        <AncestrySearchModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleSubmitAncestryList={handleSubmitAncestryList}
          selectedAncestries={selectedParentAncestry}
        />
      </SafeAreaView>
    </ErrorBoundary>
  )
}

export default AncestryScreen
