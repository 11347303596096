import { View } from 'react-native'
import { useSelector } from 'react-redux'
import LogoView from './components/LogoView'
import styles from './styles'

function AppLogo() {
  const splashSettings = useSelector((state) => state.store.splashSettings)

  return (
    <View style={styles.logoContainer}>
      <LogoView splashSettings={splashSettings} />
    </View>
  )
}

export default AppLogo
