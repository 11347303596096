import { Text, TouchableOpacity, View } from 'react-native'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import CustomWebDatePicker from '../CustomWebDatePicker'
import { i18n } from '$localization/config.js'
import styles from './styles'
import { FIELD_NAMES } from '$components/SignupCustomInput/constants'
import { IS_MOBILE } from '$constants/platforms'
import SignupCustomInput from '$components/SignupCustomInput'
import { formatDate } from '$screens/utils'

const activeDateFormat = formatDate()

function SignUpDatePicker({
  dateOfBirthday,
  handleInputChange,
  dateOfBirthdateRef,
  toggleDateMobileDatePicker,
}) {
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)
  const colorSettings = useSelector((state) => state.store.colorSettings)

  if (IS_MOBILE()) {
    const currentDate = dateOfBirthday
      ? moment(`${dateOfBirthday}`).format(activeDateFormat)
      : ''

    return (
      <View>
        <SignupCustomInput
          key={FIELD_NAMES.DATE_OF_BIRTHDATE}
          ref={dateOfBirthdateRef}
          inputName={FIELD_NAMES.DATE_OF_BIRTHDATE}
          inputLabel={i18n.t('birthdate.default')}
          inputValue={`${currentDate}`}
        />

        <TouchableOpacity
          style={styles.mobileDatePickerButton}
          onPress={toggleDateMobileDatePicker}
          activeOpacity={1}
        />

        <Text style={styles.webDatePickerHelperText(colorSettings)}>
          {
            i18n.t(
              'you_must_be_18_years_old_to_use_this_app_an_accurate_age_is_also_important_for_assessing_your_risks'
            )?.default
          }
        </Text>
      </View>
    )
  }

  return (
    <>
      <View style={styles.webDatePickerContainer}>
        <Text style={styles.webDatePickerBirthdateTitleText}>
          {i18n.t('birthdate')?.default}
        </Text>

        <CustomWebDatePicker
          containerStyle={styles.container}
          yearStyle={styles.simpleDatePickerYearStyle}
          monthStyle={styles.simpleDatePickerMonthStyle}
          dayStyle={styles.simpleDatePickerDayStyle}
          minDate={moment().subtract(185, 'years')}
          maxDate={moment().subtract(1, 'days')}
          onDatePicked={(date) =>
            handleInputChange(FIELD_NAMES.DATE_OF_BIRTHDATE, date)
          }
          getPromptString={(name) => i18n.t(name.toLowerCase())?.default}
          date={dateOfBirthday}
          language={selectedLanguage}
        />
      </View>

      <Text style={styles.webDatePickerHelperText(colorSettings)}>
        {
          i18n.t(
            'you_must_be_18_years_old_to_use_this_app_an_accurate_age_is_also_important_for_assessing_your_risks'
          )?.default
        }
      </Text>
    </>
  )
}

export default SignUpDatePicker
