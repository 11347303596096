import { IS_WEB } from '$constants/platforms'
import { apiFetchHandler } from '$api'
import { dispatchHelpers, reduxStateHelpers } from './reduxHelpers'
import { errorResponseHandler } from '$screens/onboarding/authorizationHelpers'
import { updateMemberProfile, saveLastScreen } from '$screens/utils'
import { isEmptyObject } from '$utils'
import { handleProbandFamilySaving } from './helpers'

const saveClinician = async (clinicianCode, headers, clinicianId) => {
  const { saveError, saveClinicianCode } = dispatchHelpers()
  if (!clinicianCode && !clinicianId) return

  const clinicianCheckPayload = {
    path: 'clinician/check/',
    method: 'post',
    token: headers.accessToken,
    body: {
      clinician_code: clinicianCode,
      clinician_id: clinicianId,
    },
    pageDetails: {
      page: 'useAuthHelpers hook',
    },
  }

  const response = await apiFetchHandler(clinicianCheckPayload)

  if (response.isError) {
    saveError(response)
    return
  }

  saveClinicianCode(response)
}

const handleSetUserCredentials = async (
  userUUID,
  setPasswordPayload,
  authCredentialsPayload
) => {
  const { showLoadingView, saveError } = dispatchHelpers()

  try {
    const setPatientPasswordPayload = {
      path: `patient_account/${userUUID}/set_credentials/`,
      method: 'put',
      body: {
        ...setPasswordPayload,
      },
      token: authCredentialsPayload?.accessToken,
      pageDetails: {
        page: 'useAuthHelpers hook',
      },
    }

    const setPatientPasswordResponse = await apiFetchHandler(
      setPatientPasswordPayload
    )

    if (setPatientPasswordResponse.isError) {
      showLoadingView(false)
      return errorResponseHandler(setPatientPasswordResponse, saveError)
    }
  } catch (error) {
    console.log(
      '🚀 \n\n file: useAuthHelpers hook.js:446 \n\n handleSetUserCredentials \n\n err:',
      error
    )
  }
}

const mutateLoginUser = async (payload) => {
  const PlatformConst = IS_WEB() ? 'web' : 'mobile'
  const loginAccountPayload = {
    path: 'account/login/',
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=UTF-8',
      platform: PlatformConst,
    },
    body: {
      ...payload,
    },
    pageDetails: {
      page: 'Login.controller.js',
    },
  }

  const loginAccount = await apiFetchHandler(loginAccountPayload)

  return loginAccount
}

const fetchEmailAvailabilityCheck = async (payload) => {
  const emailAvailabilityPayload = {
    path: 'account/check_email/',
    method: 'post',
    body: {
      ...payload,
    },
    pageDetails: {
      page: 'customCreatePassword.js',
    },
  }

  const emailAvailabilityResponse = await apiFetchHandler(
    emailAvailabilityPayload
  )

  return emailAvailabilityResponse
}

const fetchFamilyClinicianId = async (familyID, authCredentials) => {
  const payload = {
    path: `family/${familyID}/clinicians/`,
    method: 'get',
    token: authCredentials?.accessToken,
    pageDetails: {
      page: 'SurveyScreen.js',
    },
  }
  const response = await apiFetchHandler(payload)

  return response?.[0]?.organization?.clinician_id
}

const mutateRegisterAccount = async (payload) => {
  const registerAccountPayload = {
    path: 'account/add_patient_account/',
    method: 'post',
    body: {
      ...payload,
    },
    header: {
      'Content-Type': 'application/json;charset=UTF-8',
      platform: 'patient_account',
    },
    pageDetails: {
      page: 'useAuthHelpers hook',
    },
  }

  const registerAccountData = await apiFetchHandler(registerAccountPayload)

  return registerAccountData
}

const mutateCreateProband = async (probandData, authCredentials) => {
  const createProbandPayload = {
    path: 'proband/add/',
    method: 'post',
    token: authCredentials.accessToken,
    body: { ...probandData },
    pageDetails: {
      page: 'useAuthHelpers hook',
    },
  }

  const response = await apiFetchHandler(createProbandPayload)

  return response
}

const createProband = async (probandData, authCredentials) => {
  const { saveError, saveProbandImmediateFamily, saveProbandProfileToStore } =
    dispatchHelpers()

  try {
    const response = await mutateCreateProband(probandData, authCredentials)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.message,
      })
      return
    }

    const { proband_id: probandID, family_id: familyID } = response.data

    const familyData = {
      familyID,
      probandID,
    }
    const memberData = {
      proband_id: probandID,
      member_id: probandID,
    }

    saveProbandImmediateFamily(familyData)
    /* Get basic member Profile when proband is created */
    const mapMemberProfile = await updateMemberProfile(
      memberData,
      authCredentials
    )

    saveProbandProfileToStore(mapMemberProfile)
  } catch (err) {
    console.log(
      '🚀 \n\n file: CustomValidateDOB.js:537 \n\n createProband \n\n err:',
      err
    )
  }
}

const mutateLoginCustomWorkflow = async (payload) => {
  const PlatformConst = IS_WEB() ? 'web' : 'mobile'

  const loginUserData = {
    path: 'patient_account/invite_auth/',
    method: 'post',
    body: {
      ...payload,
    },
    header: {
      'Content-Type': 'application/json;charset=UTF-8',
      platform: PlatformConst,
    },
    pageDetails: {
      page: 'useAuthHelpers hook',
    },
  }

  const response = await apiFetchHandler(loginUserData)

  return response
}

const fetchOnboardingData = async (loginUserDataResponse) => {
  const getOnboardingDetailsPayload = {
    path: `onboarding/${loginUserDataResponse.account_id}/get_info/`,
    method: 'get',
    token: loginUserDataResponse.accessToken,
    pageDetails: {
      page: 'useAuthHelpers hook',
    },
  }

  const response = await apiFetchHandler(getOnboardingDetailsPayload)

  return response
}

const handleSavingAccountData = async (
  probandProfile,
  authCredentials,
  userData,
  onboardingResponse
) => {
  const { saveProbandToStore, saveProbandProfileToStore, saveNavigationState } =
    dispatchHelpers()
  const { appSettings } = reduxStateHelpers()

  const autoOptInClinician = appSettings?.automatic_opt_in_clinician

  if (autoOptInClinician && !isEmptyObject(autoOptInClinician)) {
    probandProfile.clinicianCode = autoOptInClinician?.code
  }

  handleProbandFamilySaving(userData)

  const memberData = {
    proband_id: userData?.member_id,
    member_id: userData?.member_id,
  }

  probandProfile.probandID = userData?.member_id
  probandProfile.familyID = userData?.family_id

  const mapMemberProfile = await updateMemberProfile(
    memberData,
    authCredentials
  )

  saveProbandToStore({ ...probandProfile, onboarding: true })
  saveProbandProfileToStore(mapMemberProfile)

  const navigationState = onboardingResponse?.navigation_state

  saveNavigationState(navigationState)
}

const handleScreenSaving = async (data, authCredentials, lastScreen) => {
  const { saveError } = dispatchHelpers()

  const screen = {
    last_screen: lastScreen,
  }
  const newData = {
    accountID: data.account_id,
  }

  await saveLastScreen({
    account: newData,
    authCredentials,
    item: screen,
    saveError,
  })
}

export {
  saveClinician,
  handleSetUserCredentials,
  fetchEmailAvailabilityCheck,
  mutateLoginUser,
  fetchFamilyClinicianId,
  mutateRegisterAccount,
  createProband,
  mutateLoginCustomWorkflow,
  fetchOnboardingData,
  handleSavingAccountData,
  handleScreenSaving,
}
