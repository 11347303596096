const DISPLAY_STRINGS = {
  EMPTY: '',
  DECEASED_PREFIX: 'd. ',
}

const DISPLAY_AGE = {
  EMPTY: '',
  ZERO: '0',
}

export default function formatDisplayedMemberName(name, age, isDead) {
  const isAgeValid =
    age || age === DISPLAY_AGE.EMPTY || age === DISPLAY_AGE.ZERO
  const isNameValid = name && name !== DISPLAY_STRINGS.EMPTY

  if (!isAgeValid) return name

  const nameWithComma = `${name}, `

  const nameText = isNameValid ? nameWithComma : DISPLAY_STRINGS.EMPTY
  const deadText = isDead
    ? DISPLAY_STRINGS.DECEASED_PREFIX
    : DISPLAY_STRINGS.EMPTY

  const titleText = `${nameText}${deadText}${age}`

  return titleText
}
