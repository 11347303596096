import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import styles from '../styles'

import { i18n } from '$localization/config.js'

import { handleRelationshipName } from '../helpers/helper'

import GeneticTestingSection from './GeneticTestingSection'
import DiseaseSection from './DiseaseSection'

import { formatDisplayedMemberName } from '$utils'

const RELATIONSHIP_PARTNER = 'partner'
const RELATIONSHIP_PROBAND = 'proband'
const UNKNOWN_AGE = '?'

function SectionListItem({ currentMember }) {
  const { error } = currentMember
  const colorSettings = useSelector((state) => state.store.colorSettings)

  if (currentMember.relationship_to_proband === RELATIONSHIP_PARTNER)
    return null

  if (error) {
    return (
      <View style={styles.sectionListSummaryDetailsContainer}>
        <Text style={styles.sectionListSummaryDetailsText}>
          {i18n.t(error).default}
        </Text>
      </View>
    )
  }

  const familyMemberNameWhenFirstNameEmpty = currentMember.first_name
    ? ` (${currentMember.first_name})`
    : ''

  const familyMemberNameWithRelationship = `${handleRelationshipName(
    currentMember
  )}${familyMemberNameWhenFirstNameEmpty}`

  const isMemberProband =
    currentMember.relationship_to_proband === RELATIONSHIP_PROBAND

  const userName = isMemberProband
    ? `${currentMember.first_name} ${currentMember.last_name}`
    : `${familyMemberNameWithRelationship}`

  const userAge = currentMember?.age_string ?? currentMember?.age ?? UNKNOWN_AGE

  const memberNameDisplayed = formatDisplayedMemberName(
    userName,
    userAge,
    currentMember.is_dead
  )

  return (
    <View style={styles.sectionListItemContainer}>
      <View style={styles.sectionListItemHeaderContainer}>
        <Text style={styles.sectionListItemHeaderText(colorSettings)}>
          {memberNameDisplayed}
        </Text>
      </View>
      <DiseaseSection diseases={currentMember?.diseases} />
      <GeneticTestingSection genes={currentMember?.genes} />
    </View>
  )
}

export default SectionListItem
