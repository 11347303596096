const DEFAULT_COLOR_SETTINGS = {}
const DEFAULT_SUBTRACT_COUNT = () => {}
const DEFAULT_COUNT = ''
const DEFAULT_ADD_COUNT = () => {}
const DEFAULT_INPUT_VALIDATION = () => {}
const DEFAULT_ON_CHANGE_TEXT = () => {}
const DEFAULT_TITLE = ''
const DEFAULT_PLACEHOLDER = ''
const DEFAULT_HAS_VARIABLE_BUTTONS = true

const CIRCLE_WITH_MINUS = 'circle-with-minus'
const CIRCLE_WITH_PLUS = 'circle-with-plus'
const TEXT_ALIGN_CENTER = 'center'
const KEYBOARD_TYPE_NUMERIC = 'numeric'
const RETURN_KEY_TYPE_DONE = 'done'
const DEFAULT_COLOR_BLACK = 'black'

export {
  DEFAULT_COLOR_SETTINGS,
  DEFAULT_SUBTRACT_COUNT,
  DEFAULT_COUNT,
  DEFAULT_ADD_COUNT,
  DEFAULT_INPUT_VALIDATION,
  DEFAULT_ON_CHANGE_TEXT,
  DEFAULT_TITLE,
  DEFAULT_PLACEHOLDER,
  DEFAULT_HAS_VARIABLE_BUTTONS,
  CIRCLE_WITH_MINUS,
  CIRCLE_WITH_PLUS,
  TEXT_ALIGN_CENTER,
  KEYBOARD_TYPE_NUMERIC,
  RETURN_KEY_TYPE_DONE,
  DEFAULT_COLOR_BLACK,
}
