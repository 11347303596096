import { wordReplacements } from '$utils'

export default function translateLongRelationshipName(input) {
  if (!input.includes('_')) return input

  const modifiedInput = input

  const words = modifiedInput.split('_')

  return wordReplacements(words)
}
