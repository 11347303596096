import { StyleSheet } from 'react-native'
import { FALLBACK_STYLES } from '../../constants'

const styles = StyleSheet.create({
  editFromStartContainer: {
    marginTop: 40,
    marginBottom: 25,
  },
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: (colorSettings) => ({
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    height: 63,
    width: 300,
    backgroundColor:
      colorSettings?.success_screen_account_button_bg ??
      FALLBACK_STYLES.WHITE_COLOR,
    borderColor:
      colorSettings?.success_screen_account_button_border ??
      FALLBACK_STYLES.BLACK_COLOR,
    borderWidth: FALLBACK_STYLES.BORDER_WIDTH,
  }),
  nextButtonText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 20,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_account_button_text ??
      FALLBACK_STYLES.BLACK_COLOR,
  }),
})

export default styles
