import { Modal, SafeAreaView, ScrollView, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import styles from './styles'
import ModalHeader from './components/ModalHeader'
import ModalContentRow from './components/ModalContentRow'
import { i18n } from '$localization/config.js'
import ModalFooter from './components/ModalFooter'
import SimpleWebViewer from './components/SimpleWebViewer'
import { IS_WEB } from '$constants/platforms'
import openInNewWebTab from '$utils/openInNewWebTab'

function TermsAndPrivacyModal({
  modalVisible,
  primaryButtonAction,
  secondaryButtonAction,
}) {
  const [isWebViewerModalShown, setIsWebViewerModalShown] = useState(false)
  const [selectedWebsiteURL, setSelectedWebsiteURL] = useState(null)
  const [
    isAgreedToTermsAndConditionsSwitch,
    setIsAgreedToTermsAndConditionsSwitch,
  ] = useState(false)
  const [isAgreedToPrivacyPolicySwitch, setIsAgreedToPrivacyPolicySwitch] =
    useState(false)
  const [
    isAgreedToCustomTermsAndConditionsSwitch,
    setIsAgreedToCustomTermsAndConditionsSwitch,
  ] = useState(false)

  const {
    hide_custom_terms_and_conditions_url: customTermsVisible,
    terms_and_conditions_url: termsAndConditionURL,
    privacy_settings_url: privacyPolicySettingURL,
    custom_terms_and_conditions_url: customTermsAndConditionsURL,
  } = useSelector((state) => state.store.appSettings || {})

  const onModalContentLinkPress = (linkURL) => {
    if (IS_WEB()) {
      return openInNewWebTab(linkURL)
    }

    setSelectedWebsiteURL(linkURL)
    setIsWebViewerModalShown(true)
  }

  const allowSubmit = useMemo(() => {
    const basicConditions =
      isAgreedToTermsAndConditionsSwitch && isAgreedToPrivacyPolicySwitch

    if (!customTermsVisible) {
      return basicConditions && isAgreedToCustomTermsAndConditionsSwitch
    }

    return basicConditions
  }, [
    isAgreedToTermsAndConditionsSwitch,
    isAgreedToPrivacyPolicySwitch,
    isAgreedToCustomTermsAndConditionsSwitch,
    customTermsVisible,
  ])

  return (
    <Modal animationType="fade" transparent visible={modalVisible}>
      <SafeAreaView
        style={styles.modalBackground}
        nativeID="modal_scaled_main_container"
      >
        <View style={styles.modalContainer}>
          <ModalHeader />

          <ScrollView>
            <View style={styles.modalContent}>
              <ModalContentRow
                onLinkPress={() =>
                  onModalContentLinkPress(termsAndConditionURL)
                }
                switchValue={isAgreedToTermsAndConditionsSwitch}
                onSwitchAction={() =>
                  setIsAgreedToTermsAndConditionsSwitch(
                    !isAgreedToTermsAndConditionsSwitch
                  )
                }
                linkText={i18n.t('terms_and_conditions.default')}
                confirmationText={i18n.t(
                  'i_have_read_and_accept_the_terms_and_conditions.default'
                )}
              />

              <ModalContentRow
                onLinkPress={() =>
                  onModalContentLinkPress(privacyPolicySettingURL)
                }
                switchValue={isAgreedToPrivacyPolicySwitch}
                onSwitchAction={() =>
                  setIsAgreedToPrivacyPolicySwitch(
                    !isAgreedToPrivacyPolicySwitch
                  )
                }
                linkText={i18n.t('privacy_policy.default')}
                confirmationText={i18n.t(
                  'i_have_read_and_accept_the_privacy_policy.default'
                )}
              />

              {!customTermsVisible && (
                <ModalContentRow
                  onLinkPress={() =>
                    onModalContentLinkPress(customTermsAndConditionsURL)
                  }
                  switchValue={isAgreedToCustomTermsAndConditionsSwitch}
                  onSwitchAction={() =>
                    setIsAgreedToCustomTermsAndConditionsSwitch(
                      !isAgreedToCustomTermsAndConditionsSwitch
                    )
                  }
                  isWhiteLabeled
                  linkText={i18n.t('terms_and_conditions.default')}
                />
              )}
            </View>

            <ModalFooter
              onSubmitPress={primaryButtonAction}
              onCancelPress={secondaryButtonAction}
              allowSubmitButton={allowSubmit}
            />
          </ScrollView>
        </View>

        {isWebViewerModalShown && (
          <SimpleWebViewer
            backButtonAction={() =>
              setIsWebViewerModalShown(!isWebViewerModalShown)
            }
            modalVisible={isWebViewerModalShown}
            websiteURL={selectedWebsiteURL}
          />
        )}
      </SafeAreaView>
    </Modal>
  )
}

export default TermsAndPrivacyModal
