function doesValueMeetPrerequisite(value, prerequisite) {
  const operators = {
    '==': (a, b) => a === b,
    '!=': (a, b) => a !== b,
    '>': (a, b) => parseInt(a, 10) > parseInt(b, 10),
    '>=': (a, b) => parseInt(a, 10) >= parseInt(b, 10),
    '<': (a, b) => parseInt(a, 10) < parseInt(b, 10),
    '<=': (a, b) => parseInt(a, 10) <= parseInt(b, 10),
    IN: (a, b) =>
      b
        .split(',')
        .map((v) => v.trim())
        .includes(a),
    'NOT IN': (a, b) =>
      !b
        .split(',')
        .map((v) => v.trim())
        .includes(a),
  }

  const operation = operators[prerequisite.operator]

  return operation ? operation(value, prerequisite.value) : false
}

function convertMemberFieldValueToString(memberFieldValue) {
  if (
    typeof memberFieldValue === 'number' ||
    typeof memberFieldValue === 'boolean'
  ) {
    return memberFieldValue.toString()
  }

  return memberFieldValue
}

function checkMemberPrerequisites(masterQuestion, surveyObj) {
  const { proband } = surveyObj

  // eslint-disable-next-line no-restricted-syntax
  for (const prerequisite of masterQuestion.member_question_prerequisites) {
    const memberFieldValue = convertMemberFieldValueToString(
      proband[prerequisite.member_field]
    )

    if (
      !memberFieldValue ||
      !doesValueMeetPrerequisite(memberFieldValue, prerequisite)
    ) {
      return false
    }
  }

  return true
}

export default checkMemberPrerequisites
