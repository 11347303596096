import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  otherPronounTextInputContainer: (colorSettings) => ({
    marginVertical: 10,
    justifyContent: 'center',
    width: '100%',
    height: 60,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderColor: colorSettings?.selectable_btn_border_inactive_1 || 'black',
    marginBottom: 100,
  }),
  otherPronounTextInput: (colorSettings) => ({
    color: colorSettings?.text_input_color_2 || 'black',
    fontFamily: 'montserrat',
    fontSize: 20,
    lineHeight: 24,
    height: '100%',
    textAlign: 'center',
  }),
})

export default styles
