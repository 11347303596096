import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  doneButtonContainer: {
    height: 60,
    width: '100%',
    justifyContent: 'flex-end',
  },
  doneButton: (colorSettings) => ({
    justifyContent: 'center',
    backgroundColor: colorSettings?.bottom_next_btn_enabled || 'white',
    borderColor: colorSettings?.bottom_next_btn_enabled || 'black',
    borderWidth: 1,
    height: '100%',
    width: '100%',
  }),
  doneButtonText: (colorSettings) => ({
    color: colorSettings?.bottom_next_btn_text || 'black',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  }),
})

export default styles
