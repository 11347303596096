export const STORAGE_ITEM_NAMES = {
  EMAIL: 'EMAIL',
  ACCOUNTSTORE: 'ACCOUNTSTORE',
  ON_SPLASH: 'onSplash',
}

export const ERROR_TYPE = {
  EMAIL: 'emailError',
  EMPTY: 'emptyError',
}

export const LOGIN_CONTAINER_NATIVE_ID = 'login_view_content_container'
