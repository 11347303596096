import { memo, useEffect, useState, useCallback } from 'react'
import { View, Text } from 'react-native'
import uuid from 'react-native-uuid'

import { i18n } from '$localization/config.js'
import { decreaseFontSize, checkMissingTranslation } from '../../../helpers'

import ComponentGenerator from '../../ComponentGenerator'

import CustomizableButton from '$components/CustomizableButton'

import LabelText from '../LabelText'

import FieldViewer from '../../FieldViewer'

import { checkPrerequisiteWithOperator } from '../../../pageBuilder/masterQuestionPrerequisitesHandlers'

import updateSurveyAnswers from '../../../helpers/updateSurveyAnswers'

const DEFAULTS = {
  BACKGROUND_COLOR: 'rgba(0,0,0,0.2)',
  FIELD_TYPE: 'select_one',
}

function SingleSelectField({
  masterQuestion,
  answer,
  storeFieldSurveyAnswers,
  colorSettings,
  surveyData,
}) {
  const {
    question_label: questionLabel,
    master_question_choices: masterQuestionChoices,
  } = masterQuestion

  const [userOption, setUserOption] = useState([])
  const [hasRendered, setHasRendered] = useState(false)

  useEffect(() => {
    if (!answer) return
    let getAnswer

    masterQuestionChoices.forEach((element) => {
      const isChoiceIdEqual = answer.master_question_choice_id === element.id

      if (isChoiceIdEqual) {
        getAnswer = element
      }
    })

    setUserOption(getAnswer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer])

  const styleHandler = (selectedItem) => {
    if (selectedItem?.id === userOption?.id) {
      const activeButtonColor =
        colorSettings?.selectable_btn_active_1 || DEFAULTS.BACKGROUND_COLOR

      return {
        backgroundColor: activeButtonColor,
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }

    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  }

  const childQuestionnaireHandler = () => {
    if (masterQuestion.child_questionnaire) {
      const rawChildData = masterQuestion.child_questionnaire.flatMap(
        (questionnaire) => {
          const prerequisiteList =
            questionnaire.master_question.question_prerequisites

          const masterQuestionId = masterQuestion.id

          const prerequisite = prerequisiteList.find(
            (prerequisiteQuestionnaire) =>
              prerequisiteQuestionnaire.master_question_prerequisite_id ===
              masterQuestionId
          )

          const shouldRender = checkPrerequisiteWithOperator(
            prerequisite,
            userOption.value
          )

          if (shouldRender) return questionnaire
          return null
        }
      )

      const childData = rawChildData.filter((element) => element !== null)

      return (
        <ComponentGenerator
          childQuestionnaire={childData}
          surveyObject={updateSurveyAnswers({ surveyData, hasRendered })}
        />
      )
    }
  }

  const buildPayload = (choiceId, repeatClick) => {
    const payload = {
      field_type: DEFAULTS.FIELD_TYPE,
      master_question_id: masterQuestion.id,
      master_question_choice_id: choiceId,
      answer: null,
      repeat_click: repeatClick,
    }

    return payload
  }

  const handleSelectedItem = (item) => {
    if (!hasRendered) {
      setHasRendered(true)
    }

    if (item.id !== userOption?.id || !userOption) {
      setUserOption(item)
      const payload = buildPayload(item.id, false)

      storeFieldSurveyAnswers(payload)
      return
    }

    setUserOption('')

    const payload = buildPayload(item.id, true)

    storeFieldSurveyAnswers(payload)
  }

  const title = LabelText({
    questionLabel: i18n.t(questionLabel).default || questionLabel,
    colorSettings,
    masterQuestion,
  })

  return (
    <View>
      <FieldViewer fieldName="Single Select Field" />
      {title}
      {masterQuestionChoices.map((item) => {
        const translationKey = `${item.translation_key}.choice`
        let choiceLabelTranslation = i18n.t(translationKey)

        const checkTranslation = checkMissingTranslation({
          message: choiceLabelTranslation,
          fallback: item.choice,
        })

        if (
          choiceLabelTranslation === translationKey ||
          checkTranslation.isMissing
        ) {
          choiceLabelTranslation = item.choice
        }

        return (
          <CustomizableButton
            key={uuid.v4()}
            colorSettings={colorSettings}
            onPress={() => handleSelectedItem(item)}
            styleHandler={() => styleHandler(item)}
            fontSizeHandler={() => decreaseFontSize(item.choice)}
            title={choiceLabelTranslation}
          />
        )
      })}
      {childQuestionnaireHandler()}
    </View>
  )
}

export default memo(SingleSelectField)

// ** typescript reference **

// SingleSelectField.propTypes = {
//   masterQuestion: PropTypes.isRequired,
//   colorSettings: PropTypes.objectOf(PropTypes.string),
//   answer: PropTypes.number,
//   storeFieldSurveyAnswers: PropTypes.func,
// }

// SingleSelectField.defaultProps = {
//   colorSettings: [],
//   answer: '',
//   storeFieldSurveyAnswers: () => {},
// }
