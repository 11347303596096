import { Component, createRef } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  TextInput,
  StyleSheet,
  KeyboardAvoidingView,
  SafeAreaView,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import LoadingView from '$components/LoadingView'
import NextButtonAccessoryView from '$components/NextButtonAccessoryView'
import { DOMAIN_CLIENT } from '$utils/globalVariables.js'
import { apiFetchHandler } from '$api'

import Proband from '$data_models/Proband'

import { isEmptyObject, getLanguage } from '$utils'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils'

import global_styles, {
  DefaultFullHeight,
} from '$assets/styles/global.styles.js'
import { platforms } from '$constants'

import {
  setAuthCredentialsAction,
  setAccountAction,
  setUserId,
  setAccountId,
} from '$redux/account/actions.js'

import {
  setProbandAction,
  setProbandProfileAction,
  setProbandClinicianCode,
  setProbandImmediateFamily,
} from '$redux/proband/actions.js'

import { setSelectedClinicianAction } from '$redux/clinician/actions.js'

import { setError } from '$redux/defaults/actions.js'

import {
  addPhoneNumber,
  saveLastScreen,
  updateMemberProfile,
} from '$screens/utils'

import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const styles = StyleSheet.create({
  resendCodeButtonContainer: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  resendCodeButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  resendCodeButtonText: {
    color: '#fff',
    fontFamily: 'montserrat',
    fontSize: 15,
    textAlign: 'center',
  },
})

const inputAccessoryViewID = 'SignupScreenPhoneCode'

class SignupScreenPhoneCode extends Component {
  account = this.props.currentAccount

  proband = new Proband()

  resendCodeInterval = null

  defaultResendCodeTimer =
    this.props.route?.params?.resendPhoneNumberTime ?? null

  appSettings = this.props.appSettings

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.textInput = createRef()
    this.state = {
      showLoadingView: false,
      mainTextValue: '',
      mainTextValueLocked: '',
      didTappedResendButton: false,
      resendCodeTimer: this.defaultResendCodeTimer,
      isNextButtonClicked: false,
      scrollViewHeight: DefaultFullHeight(),
    }

    if (this.colorSettings == null) {
      this.colorSettings = this.props.colorSettings
    }
  }

  componentDidMount() {
    if (platforms.WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }

    if (this.defaultResendCodeTimer) {
      this.showResendCodeText()
    }
  }

  componentWillUnmount() {
    if (platforms.WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
    /* Reset resend code interval counter */
    clearInterval(this.resendCodeInterval)
    this.resendCodeInterval = null
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  createProband = async (probandData, authCredentials) => {
    const { saveProbandImmediateFamily, saveProbandProfileToStore, saveError } =
      this.props

    const createProbandPayload = {
      path: 'proband/add/',
      method: 'post',
      token: authCredentials.accessToken,
      body: { ...probandData },
      pageDetails: {
        page: 'SignupScreenPhoneCode.js',
        line: '193',
      },
    }

    const response = await apiFetchHandler(createProbandPayload)

    if (response?.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const { proband_id, family_id } = response.data

    const familyData = {
      familyID: family_id,
      probandID: proband_id,
    }
    const memberData = {
      proband_id,
      member_id: proband_id,
    }

    saveProbandImmediateFamily(familyData)
    /* Get basic member Profile when proband is created */
    const mapMemberProfile = await updateMemberProfile(
      memberData,
      authCredentials
    )

    saveProbandProfileToStore(mapMemberProfile)
  }

  getFormattedValue = () => {
    const { mainTextValueLocked } = this.state

    const val = mainTextValueLocked.replace(/ /g, '').split('')
    const newVal = val.join('')
    return newVal
  }

  onChangeMainTextInput = (e) => {
    const val = e.replace(/ /g, '').split('')
    const newVal = val.join('   ')

    if (val.length <= 6) {
      this.setState({
        mainTextValue: newVal,
        mainTextValueLocked: newVal,
      })
    } else {
      this.setState({
        mainTextValue: newVal,
      })
    }
  }

  errorResponseHandler = (response) => {
    const { saveError } = this.props
    const { status, error } = response
    switch (status) {
      case 409:
        showDefaultAlert(
          i18n.t('phone_number_not_available').default,
          i18n.t(
            'you_have_entered_a_phone_number_that_is_already_in_use_by_another_account_please_use_a_different_one'
          ).default
        )
        break

      case 400:
        showDefaultAlert(
          i18n.t('oops').default,
          i18n.t('sorry_the_code_you_have_entered_is_incorrect').default
        )
        break

      default:
        saveError({
          isShown: true,
          status,
          message: error,
        })
        break
    }
  }

  nextButtonAction = async () => {
    await this.verifyPhoneNumberCode({
      verification_code: this.getFormattedValue(),
    })
  }

  // Initiate API request to verify phone number code.
  verifyPhoneNumberCode = async (payload) => {
    /* Prepare API request to verify phone number code. */

    this.setState({
      showLoadingView: true,
      isNextButtonClicked: true,
    })

    payload.dialing_code = this.account.dialingCode
    payload.phone_number = this.account.phoneNumber

    const verifyPhoneNumberCodePayload = {
      path: 'phone_number_verification/verify/',
      method: 'post',
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SignupScreenPhoneCode.js',
        line: '305',
      },
    }

    const verifyPhoneNumberCode = await apiFetchHandler(
      verifyPhoneNumberCodePayload
    )

    if (verifyPhoneNumberCode.isError) {
      this.setState({
        showLoadingView: false,
        isNextButtonClicked: false,
      })

      this.errorResponseHandler(verifyPhoneNumberCode)
      return
    }

    if (
      verifyPhoneNumberCode.status_code !== 200 &&
      verifyPhoneNumberCode.status_code !== 201
    ) {
      return
    }

    // Continue to Account Registration.
    await this.registerAccount()
  }

  registerAccount = async () => {
    /* Prepare API request to register Account. */
    const { inviteID } = this.props
    let path = 'account/add/'

    let payload = {
      dialing_code: this.account.dialingCode,
      phone_number: this.account.phoneNumber,
      email: this.account.email,
      password: this.account.password,
      first_name: this.account.firstName,
      last_name: this.account.lastName,
      dob: this.account.dob,
      domain_client: `${DOMAIN_CLIENT}`?.toUpperCase(),
      lang: getLanguage(),
    }

    if (inviteID) {
      const isInvitable = await this.checkInvite({
        dob: this.account.dob,
        inviteID,
      })

      if (isInvitable) {
        path = 'account/add_patient_account/'
        payload = {
          inviteID,
          dialing_code: this.account.dialingCode,
          phone_number: this.account.phoneNumber,
          email: this.account.email,
          dob: this.account.dob,
          password: this.account.password,
          domain_client: `${DOMAIN_CLIENT}`?.toUpperCase(),
          lang: getLanguage(),
        }
      }
    }

    this.setState({ showLoadingView: true })

    const PlatformConst = Platform.OS === 'web' ? 'web' : 'mobile'

    const registerAccountPayload = {
      path,
      method: 'post',
      body: {
        ...payload,
      },
      contentType: 'application/json;charset=UTF-8',
      header: {
        platform: PlatformConst,
      },
      pageDetails: {
        page: 'SignupScreenPhoneCode.js',
        line: '385',
      },
    }

    const registerAccountData = await apiFetchHandler(registerAccountPayload)
    const { saveError } = this.props

    if (registerAccountData.isError) {
      this.setState({ showLoadingView: false })

      switch (registerAccountData.status) {
        case 409:
          showDefaultAlert(
            i18n.t('account_already_exists').default,
            i18n.t(
              'sorry_the_account_you_are_trying_to_register_already_exists'
            ).default
          )
          break

        case 400:
          showDefaultAlert(
            i18n.t('bad_request').default,
            i18n.t('sorry_something_was_wrong_from_your_request').default
          )
          break

        default:
          saveError({
            isShown: true,
            status: registerAccountData.status,
            message: registerAccountData.error,
          })
          break
      }
      return
    }

    /*
      Sample response data
      Object {
        "accessToken": "ueckq8U7QsFS4ORRvPVRnaGE1Su2Bt",
        "account_id": 543,
        "expires": "2021-05-12T04:48:04.089817Z",
        "refreshToken": "qi945GrkrFzHgvjI8hHQcmvaTDpI7B",
        "scope": "read, write",
        "status_code": 201,
        "user_id": 546,
      }
      */

    const authCredentials = {
      accountID: registerAccountData?.account_id,
      accessToken: registerAccountData?.accessToken,
      refreshToken: registerAccountData?.refreshToken,
    }

    const userID = registerAccountData?.user_id
    const accountID = registerAccountData?.account_id

    // Save authCredentials to redux store.
    this.props._saveAuthCredentialsToStore_(authCredentials)
    // DONE: set action items to redux accounts
    this.props._saveUserId_(userID)
    this.props._saveAccountId_(accountID)

    const probandData = {
      proband: {
        account_id: registerAccountData?.account_id,
        email: this.account.email,
        first_name: this.account.firstName,
        middle_name: '',
        last_name: this.account.lastName,
        age: this.account.age,
        dob: this.account.dob,
        phone_number: this.account.phoneNumber,
        gender: '',
        clinician_code: registerAccountData?.clin_code,
        onboarding: true,
        patient_id: registerAccountData?.patient_id,
      },
    }

    // Check automatic clinician opt-in option in App Settings API
    const automatic_opt_in_clinician =
      this.appSettings?.automatic_opt_in_clinician

    if (
      automatic_opt_in_clinician &&
      !isEmptyObject(automatic_opt_in_clinician)
    ) {
      // DONE: remove proband account
      this.props._storeAccount(this.account)
      this.props._saveProbandClinicianCode_(automatic_opt_in_clinician?.code)

      // Save proband to redux store.
      this.props._saveProbandToStore_(this.proband)

      probandData.proband.clinician_code = automatic_opt_in_clinician?.code
      await this.navigateAccountCreated(
        registerAccountData,
        authCredentials,
        probandData
      )
    } else {
      // Navigate to Clinician Code screen.

      this.navigateToClinicianCode(
        registerAccountData,
        authCredentials,
        probandData
      )
    }
  }

  checkInvite = async ({ dob, inviteID }) => {
    const payload = {
      path: 'account/verify_patient_invite/',
      method: 'post',
      body: {
        inviteID,
        dob,
      },
      pageDetails: {
        page: 'SignupScreenPhoneCode.js',
        line: '519',
      },
    }

    const isValid = await apiFetchHandler(payload)
    const { saveError } = this.props

    if (isValid.isError) {
      saveError({
        isShown: true,
        status: isValid.status,
        message: isValid.error,
      })
      return false
    }

    return true
  }

  navigateAccountCreated = async (data, authCredentials, probandData) => {
    const { navigation } = this.props

    await this.handleScreenSaving(
      data,
      authCredentials,
      RAW_LAST_VISITED_SCREENS.account_created_screen
    )
    await this.createProband(probandData, authCredentials)

    navigation.navigate('SuccessScreenAccountCreated')
  }

  navigateToClinicianCode = async (data, authCredentials, probandData) => {
    const { navigation } = this.props

    await this.handleScreenSaving(data, authCredentials, 'sign_up_clinician')
    await this.createProband(probandData, authCredentials)

    navigation.navigate('SignupClinicianNavigator', {
      screen: 'SignupClinicianCode',
    })
  }

  handleScreenSaving = async (data, authCredentials, lastScreen) => {
    const screen = {
      last_screen: lastScreen,
    }
    const newData = {
      accountID: data.account_id,
    }
    const { saveError } = this.props

    await saveLastScreen({
      account: newData,
      authCredentials,
      item: screen,
      saveError,
    })
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    let style = {
      backgroundColor:
        this.colorSettings?.bottom_next_btn_disabled || 'transparent',
      borderColor: this.colorSettings?.bottom_next_btn_disabled,
    }
    const isValid = this.validateInput()
    if (isValid) {
      style = {
        backgroundColor:
          this.colorSettings?.bottom_next_btn_enabled || 'transparent',
        borderColor: this.colorSettings?.bottom_next_btn_enabled,
      }
    }
    return style
  }

  validateInput = () => {
    const newVal = this.getFormattedValue()

    if (newVal.length === 6) return true

    return false
  }

  render() {
    let enableKeyboardAvoiding = false
    if (Platform.OS === 'ios') {
      enableKeyboardAvoiding = true
    }
    let textInputCharsType = 'number-pad'
    if (Platform.OS === 'web') {
      textInputCharsType = 'default'
    }

    return (
      <ErrorBoundary>
        <TouchableWithoutFeedback
          onPress={() =>
            Platform.OS !== platforms.WEB ? Keyboard.dismiss() : {}
          }
        >
          <SafeAreaView
            style={[
              global_styles.container,
              {
                backgroundColor:
                  this.colorSettings?.onboarding_bgcolor || 'white',
              },
            ]}
          >
            <KeyboardAvoidingView
              style={{ height: this.state.scrollViewHeight }}
              contentContainerStyle={{ flex: 1.0 }}
              behavior="position"
              enabled={enableKeyboardAvoiding}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    flex: 3.0,
                    justifyContent: 'center',
                    minHeight: 120,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <View style={[{ justifyContent: 'center' }]}>
                      <Text
                        style={[
                          global_styles.titleText,
                          {
                            color:
                              this.colorSettings?.text_input_title_color_2 ||
                              'black',
                          },
                        ]}
                      >
                        {i18n.t('we_sent_you_a_6-digit_code').default}
                      </Text>
                    </View>
                  </View>

                  {/* <MainTextInput Container> */}
                  <View
                    style={{
                      flex: 0.5,
                      justifyContent: 'center',
                    }}
                  >
                    <TextInput
                      ref={(input) => {
                        this.textInput = input
                      }}
                      nativeID="text_input_web"
                      style={[
                        global_styles.mainTextInput,
                        {
                          color:
                            this.colorSettings?.text_input_title_color_2 ||
                            'black',
                          height: '100%',
                          width: '100%',
                          fontSize: 24,
                        },
                      ]}
                      inputAccessoryViewID={inputAccessoryViewID}
                      placeholder="–– –– –– –– –– ––"
                      placeholderTextColor={
                        this.colorSettings?.text_input_placeholder_color_2 ||
                        'rgba(74,74,74,0.5)'
                      }
                      selectionColor={this.colorSettings?.text_input_color_2}
                      keyboardType={textInputCharsType}
                      autoCapitalize="none"
                      onChangeText={this.onChangeMainTextInput}
                      value={this.state.mainTextValueLocked}
                      textContentType="oneTimeCode"
                      autoComplete="sms-otp"
                      autoFocus
                    />
                  </View>
                  {/* </MainTextInput Container> */}

                  <View style={{ flex: 0.3 }}>
                    {this.renderResendCodeButton()}
                  </View>
                </View>
              </View>
              {/* </ScrollView> */}
            </KeyboardAvoidingView>

            {/* <Next button> */}
            <View style={global_styles.nextButtonContainer}>
              <TouchableOpacity
                style={[global_styles.nextButton, this.toggleNextButtonColor()]}
                disabled={
                  this.toggleNextButton() || this.state.isNextButtonClicked
                }
                onPress={this.nextButtonAction}
              >
                <Text
                  style={[
                    global_styles.nextButtonText,
                    {
                      color:
                        this.colorSettings?.bottom_next_btn_text || 'black',
                    },
                  ]}
                >
                  {i18n.t('next').default}
                </Text>
              </TouchableOpacity>
            </View>

            <NextButtonAccessoryView
              nativeID={inputAccessoryViewID}
              labelText={i18n.t('next').title}
              labelColor={this.colorSettings?.bottom_next_btn_text || 'black'}
              disabled={
                this.toggleNextButton() || this.state.isNextButtonClicked
              }
              style={[global_styles.nextButton, this.toggleNextButtonColor()]}
              onPress={this.nextButtonAction}
            />
            {/* </Next button> */}

            <LoadingView
              visible={this.state.showLoadingView}
              backgroundColor={this.colorSettings?.splash_bgcolor}
              tintColor={this.colorSettings?.btn_no_fill_border_1}
              textColor={this.colorSettings?.btn_no_fill_text_1}
            />
          </SafeAreaView>
        </TouchableWithoutFeedback>
      </ErrorBoundary>
    )
  }

  showLoading = (isShown) => {
    this.setState({ showLoadingView: isShown })
  }

  showResendCodeText = () => {
    this.setState({ didTappedResendButton: true })
    this.resendCodeInterval = setInterval(() => {
      const { resendCodeTimer } = this.state

      if (resendCodeTimer === 0) {
        clearInterval(this.resendCodeInterval)
        this.setState({
          didTappedResendButton: false,
          resendCodeTimer: this.defaultResendCodeTimer,
        })
      } else {
        this.setState({ resendCodeTimer: resendCodeTimer - 1 })
      }
    }, 1000)
  }

  resendCodeButtonAction = async () => {
    const { saveError, navigation, colorSettings } = this.props

    const payload = {
      dialing_code: this.account.dialingCode,
      phone_number: this.account.phoneNumber,
      email: this.account.email,
      password: this.account.password,
      first_name: this.account.firstName,
      last_name: this.account.lastName,
      dob: this.account.dob,
    }

    const phoneNumberResponse = await addPhoneNumber(
      false,
      payload,
      this.showLoading,
      navigation,
      colorSettings,
      saveError
    )

    if (phoneNumberResponse?.backoff_time) {
      this.setState({ resendCodeTimer: phoneNumberResponse?.backoff_time })
      this.showResendCodeText()
    }
  }

  renderResendCodeButton = () => {
    const { resendCodeTimer, didTappedResendButton } = this.state

    if (didTappedResendButton) {
      if (resendCodeTimer >= 0) {
        return (
          <View style={styles.resendCodeButtonContainer}>
            <View style={styles.resendCodeButton}>
              <Text
                style={[
                  styles.resendCodeButtonText,
                  {
                    color:
                      this.colorSettings?.selectable_btn_active_1 || 'black',
                  },
                ]}
              >
                {i18n.t('please_wait_').default}
                {resendCodeTimer}
                {i18n.t('_seconds_to_send_another_code').default}
              </Text>
            </View>
          </View>
        )
      }
    }

    return (
      <View style={styles.resendCodeButtonContainer}>
        <TouchableOpacity
          onPress={this.resendCodeButtonAction}
          style={styles.resendCodeButton}
        >
          <Text
            style={[
              styles.resendCodeButtonText,
              {
                color: this.colorSettings?.text_input_title_color_2 || 'black',
              },
            ]}
          >
            {i18n.t('resend_code').default}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore } = state
  return {
    /** Default Store */
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    inviteID: store.inviteID,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
    /** Proband Store */
    currentProband: probandStore.proband,
    shouldAddPhoneNumber: store.shouldAddPhoneNumber,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    /** Old actions */
    _storeAccount: (account) =>
      dispatch({ type: 'STORE_ACCOUNT', data: account }),
    _storeProband: (proband) =>
      dispatch({ type: 'STORE_PROBAND', data: proband }),
    _toggleAddPhoneNumber_: (toggle) =>
      dispatch({ type: 'SHOULD_ADD_PHONE_NUMBER', data: toggle }),

    /** Refactored actions */
    _saveAuthCredentialsToStore_: (data) =>
      dispatch(setAuthCredentialsAction(data)),
    _saveAccountToStore_: (account) => dispatch(setAccountAction(account)),
    _saveProbandToStore_: (proband) => dispatch(setProbandAction(proband)),
    _saveUserId_: (id) => dispatch(setUserId(id)),
    _saveAccountId_: (id) => dispatch(setAccountId(id)),
    _saveProbandClinicianCode_: (code) =>
      dispatch(setProbandClinicianCode(code)),
    _saveClinician_: (item) => dispatch(setSelectedClinicianAction(item)),
    saveProbandImmediateFamily: (item) =>
      dispatch(setProbandImmediateFamily(item)),
    saveProbandProfileToStore: (probandProfile) =>
      dispatch(setProbandProfileAction(probandProfile)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupScreenPhoneCode)
