import { StyleSheet, Platform } from 'react-native'

import { platforms } from '$constants'

export default StyleSheet.create({
  container: {
    backgroundColor: 'rgb(245,245,245)',
    flex: 1.0,
  },
  contentContainer: {
    backgroundColor: 'rgb(245,245,245)',
  },
  content: {
    backgroundColor: 'rgb(245,245,245)',
    flex: 1.0,
  },
  headerImageBG: {
    backgroundColor: 'rgb(27,156,252)',
    resizeMode: 'center',
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    zIndex: -9,
  },
  headerContentContainer: {
    flex: 1.0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerAvatarImage: {
    width: 42,
    height: 42,
    marginBottom: 16,
  },
  headerTitleText: {
    marginVertical: 2.5,
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'center',
  },
  headerSubtitleText: {
    marginVertical: 2.5,
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 12,
    textAlign: 'center',
  },
  headerCustomText: {
    fontFamily: 'karla-bold',
    fontSize: 21,
    color: 'rgb(74,74,74)',
    textAlign: 'center',
  },
  groupContainer: {
    backgroundColor: 'white',
    marginHorizontal: 16,
    marginVertical: 8,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: 'rgb(234,234,234)',
  },
  groupContent: {
    marginVertical: 8,
    width: '100%',
  },
  groupContentItem: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  groupContentItemLabelTextContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  groupContentItemLabelText: {
    marginBottom: 5,
    fontFamily: 'montserrat',
    fontSize: 12,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  groupContentItemListContainer: {
    margin: 8,
  },
  groupContentItemValueText: {
    fontFamily: 'karla-bold',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  groupContentItemTitleTextContainer: {
    flex: 0.8,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  groupContentItemTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: 'rgb(65,70,97)',
    textAlign: 'left',
  },
  section: {
    backgroundColor: 'rgb(245,245,245)',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingHorizontal: 2,
  },
  sectionTitleView: {
    width: '95%',
    height: 40,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  sectionTitleText: {
    color: 'rgb(65,70,97)',
    fontFamily: 'karla-bold',
    fontSize: 18,
    marginHorizontal: 5,
  },
  itemViewContainer: {
    width: '100%',
  },
  itemView: {
    backgroundColor: 'white',
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 16,
    alignItems: 'center',
    borderColor: 'rgba(200,200,200,0.5)',
    borderWidth: 2,
    borderRadius: 5,
  },
  itemImageContainer: {
    flex: 0.2,
    height: '100%',
    alignItems: 'center',
  },
  itemDetailsContainer: {
    flex: 0.6,
    paddingHorizontal: 8,
  },
  itemDetailsTitleText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
  },
  itemDetailsSubtitleText: {
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    fontSize: 11,
  },
  itemAccessoryContainer: {
    flex: 0.2,
    paddingRight: 8,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  itemAccessoryButton: {
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemAddButton: {
    color: 'rgb(101,209,185)',
    textAlign: 'center',
    fontSize: 26,
  },
  itemViewSeparatorContainer: {
    width: '100%',
    height: 10,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  itemViewSeparator: {
    backgroundColor: '#ddd',
    paddingLeft: 10,
    width: '100%',
    height: 1,
  },

  treeLineContainer: {
    position: 'absolute',
    height: '100%',
    top: -7,
  },
  treeLineVertical: {
    position: 'absolute',
    backgroundColor: 'rgba(200,200,200,1.0)',
    left: 6,
    height: '100%',
    width: 1,
  },
  treeLineHorizontal: {
    position: 'absolute',
    backgroundColor: 'rgba(200,200,200,1.0)',
    left: 6,
    top: '50%',
    height: 1,
    width: 10,
    ...Platform.select({
      [platforms.WEB]: {
        left: -6,
      },
    }),
  },
  searchTextInputContainer: {
    backgroundColor: 'white',
    flexDirection: 'row',
    height: 45,
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  searchIconContainer: {
    flex: 0.1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchTextInput: {
    flex: 0.9,
    height: '100%',
    fontFamily: 'montserrat',
    color: 'black',
    fontSize: 13,
    verticalAlign: 'center',
    letterSpacing: 2,
  },
})
