import { Component } from 'react'
import {
  View,
  Image,
  ImageBackground,
  Modal,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import {
  setIsFirstLogin,
  setShouldShowOtherFamilyMembersTutorial,
} from '$redux/defaults/actions.js'

import styles from '$screens/relatives/_styles/tutorial.modal.styles.js'

import { getImageSize, getModalSize } from '$screens/utils'

const windowDimensions = Dimensions.get('window')

class OtherFamilyMembersTutorial extends Component {
  state = {
    dimensions: windowDimensions,
  }

  onChangeDimensions = () => {
    this.setState({ dimensions: Dimensions.get('window') })
  }

  componentDidMount() {
    this.dimensionsSubscription = Dimensions.addEventListener(
      'change',
      this.onChangeDimensions
    )
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove()
  }

  dismissButtonAction = () => {
    if (this.props?._setShouldShowOtherFamilyMembersTutorial_) {
      this.props?._setShouldShowOtherFamilyMembersTutorial_(false)
    }
  }

  render() {
    const { colorSettings } = this.props

    const { width, height } = this.state.dimensions
    const currentWidth = width
    const currentHeight = height
    const webModalHeight = currentWidth < 850 ? 500 : height / 1.37
    const mobileModalHeight = 520

    return (
      <Modal animationType="fade" visible transparent>
        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <View
              style={[
                styles.content,
                {
                  width: getModalSize(currentWidth),
                  minHeight:
                    Platform.OS === 'web' ? webModalHeight : mobileModalHeight,
                  borderRadius: 10,
                  overflow: 'hidden',
                },
                Platform.OS !== 'web' && { height: mobileModalHeight },
              ]}
            >
              <View style={{ width: '100%', height: '100%' }}>
                <ImageBackground
                  source={require('$assets/images/new_assets/modal-bg-siblings.png')}
                  style={[
                    styles.contentHeader,
                    {
                      height: getImageSize(currentWidth, currentHeight),
                    },
                  ]}
                >
                  <View style={styles.dismissButtonView}>
                    <TouchableOpacity
                      onPress={() => this.dismissButtonAction()}
                    >
                      <Image
                        source={require('$assets/images/new_assets/icon-close-modal-w.png')}
                        style={{ width: 24, height: 24 }}
                      />
                    </TouchableOpacity>
                  </View>
                </ImageBackground>

                <View
                  style={[
                    styles.contentBody,
                    {
                      marginHorizontal: 16,
                      marginVertical: 8,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontFamily: 'karla-bold',
                      fontSize: 21,
                      lineHeight: 24,
                      color: 'rgb(65,70,97)',
                      textAlign: 'center',
                    }}
                  >
                    {
                      i18n.t(
                        'do_you_have_any_other_relatives_with_diseases_who_arent_listed_qm'
                      )?.default
                    }
                  </Text>

                  <Text
                    style={[
                      styles.messageBodyText,
                      currentHeight < 700 && { marginVertical: 10 },
                    ]}
                  >
                    {
                      i18n.t(
                        'for_example_col_cousins_comma_nieces_or_nephews_comma_etc_dot'
                      )?.default
                    }
                  </Text>

                  <Text
                    style={[
                      styles.messageBodyText,
                      currentHeight < 700 && { marginVertical: 10 },
                    ]}
                  >
                    {
                      i18n.t(
                        'use_the_plus_button_on_any_relative_to_add_a_relative_to_them_dot'
                      )?.default
                    }
                  </Text>

                  <TouchableOpacity
                    onPress={() => this.dismissButtonAction()}
                    style={[
                      styles.nextButton,
                      {
                        backgroundColor: colorSettings?.btn_filled_enabled_1,
                        borderColor: colorSettings?.btn_filled_border_1,
                        marginTop: 8,
                        width: 200,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.nextButtonText,
                        {
                          color: colorSettings?.btn_filled_text_1,
                        },
                      ]}
                    >
                      {i18n.t('continue')?.default}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setIsFirstLogin_: (data) => dispatch(setIsFirstLogin(data)),
    _setShouldShowOtherFamilyMembersTutorial_: (data) =>
      dispatch(setShouldShowOtherFamilyMembersTutorial(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherFamilyMembersTutorial)
