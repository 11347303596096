import { View, ImageBackground, Platform, Dimensions } from 'react-native'

import {
  createBottomTabNavigator,
  BottomTabBar,
} from '@react-navigation/bottom-tabs'

import { createStackNavigator } from '@react-navigation/stack' // enabled on web
import { NavigationActions } from '@react-navigation/compat'
import { getFocusedRouteNameFromRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import { platforms, envConstants } from '$constants'

/* Prime Navigators */
import DashboardNavigator from './dashboard/DashboardNavigator'
import FamilyNavigator from './family/FamilyNavigator.js'
import RisksNavigator from './risks/RisksNavigator'
import SurveyNavigator from './survey/SurveyNavigator'

/* Child Navigators */
import AddMemberNavigator from '$navigation/relatives/AddMemberNavigator.js'
import RelativeGeneTestNavigator from '$navigation/relatives/RelativeGeneTestNavigator.js'
import RelativeDiseasesNavigator from '$navigation/relatives/RelativeDiseases.navigator.js'
import SelectParentNavigator from '$navigation/relatives/SelectParent.navigator.js'
import SelectParentBloodRelationNavigator from '$navigation/relatives/SelectParentBloodRelation.navigator.js'
import SelectTwinNavigator from '$navigation/relatives/SelectTwin.navigator.js'
import InviteFamilyMemberNavigator from '$navigation/main/dashboard/invite_family_member/InviteFamilyMember.navigator.js'

import SurveyCancer from '$screens/main/survey/survey_cancer/SurveyCancer.js'
import SurveyCustom from '$screens/main/survey/survey_custom/SurveyCustom.js'
import ViewOnWeb from '$screens/main/dashboard/components/DashboardSettingsComponents/ViewOnWeb.js'
import ChangePassword from '$screens/main/dashboard/components/DashboardSettingsComponents/change_password/ChangePassword.js'
import UpdatePhoneNumber from '$screens/main/dashboard/components/DashboardSettingsComponents/update_phone_number/UpdatePhoneNumber.js'
import ResearchDataSettings from '$screens/main/dashboard/components/DashboardSettingsComponents/research_data_settings/ResearchDataSettings.js'
import ContactUs from '$screens/main/dashboard/components/DashboardSettingsComponents/contact_us/ContactUs.js'
import SubmitIdeas from '$screens/main/dashboard/components/DashboardSettingsComponents/submit_ideas/SubmitIdeas.js'
import SuccessScreenPasswordChanged from '$screens/SuccessScreens/SuccessScreenPasswordChanged'
import AddClinicianCode from '$screens/main/dashboard/clinicians/add_clinician/AddClinician'
import MainTabBadge from '$navigation/main/main_tab_badge_component/MainTabBadge.layout.js'
import BottomTabBarTitle from '$navigation/main/_includes/BottomTabBarTitle'
import CustomScreen from '$screens/CustomScreen'
import BiometricsSettings from '$screens/main/dashboard/components/DashboardSettingsComponents/biometrics_settings/BiometricSettings'

import headerNavigationDefaultOptions from '../_utils/HeaderNavigationDefaultOptions'

import {
  RightButtonComponent,
  LeftButtonComponent,
  HeaderTitleComponent,
} from '../_components'

const Stack = createStackNavigator()
const BottomTabNav = createBottomTabNavigator()

const getTabBarIcon = (route, focused) => {
  const routeName = route.name
  let iconName

  if (routeName === 'Home') {
    iconName = focused
      ? require('$assets/images/icon/Tab_bar/Icons/dashboard/Active.png')
      : require('$assets/images/icon/Tab_bar/Icons/dashboard/Inactive.png')
  }

  if (routeName === 'Family') {
    iconName = focused
      ? require('$assets/images/icon/Tab_bar/Icons/family/Active.png')
      : require('$assets/images/icon/Tab_bar/Icons/family/Inactive.png')
  }

  if (routeName === 'Profile') {
    iconName = focused
      ? require('$assets/images/icon/Tab_bar/Icons/family/Active.png')
      : require('$assets/images/icon/Tab_bar/Icons/family/Inactive.png')
  }

  if (routeName === 'Risks') {
    iconName = focused
      ? require('$assets/images/icon/Tab_bar/Icons/risks/Active.png')
      : require('$assets/images/icon/Tab_bar/Icons/risks/Inactive.png')
  }

  if (routeName === 'Surveys') {
    iconName = focused
      ? require('$assets/images/new_assets/tab-surveys-active.png')
      : require('$assets/images/new_assets/tab-surveys-inactive.png')
  }

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImageBackground source={iconName} style={{ width: 25, height: 25 }}>
        {routeName === 'Surveys' && <MainTabBadge />}
      </ImageBackground>

      <BottomTabBarTitle focused={focused} tabBarName={routeName} />
    </View>
  )
}

let isTabBarVisible = true

const windowWidth = Dimensions.get('window').width

function handleTabBarVisibility(route) {
  const routeName = getFocusedRouteNameFromRoute(route)

  const familyRoutes = route?.state?.routes[1]?.routes ?? []
  const mainRoutes = route?.state?.routes[0]?.routes ?? []

  const isEditProfileViewFromFamily = familyRoutes?.some(
    (_route) => _route.routeName === 'EditProfileView'
  )

  const isEditProfileViewFromMain = mainRoutes?.some(
    (_route) => _route.routeName === 'EditProfileView'
  )

  const isFamilyMainViewTab =
    familyRoutes[0]?.routeName === 'FamilyMainViewTab' &&
    familyRoutes?.length === 1 &&
    route?.state?.index === 1

  if (isFamilyMainViewTab) {
    isTabBarVisible = true
    return true
  }

  if (isEditProfileViewFromFamily || isEditProfileViewFromMain) {
    isTabBarVisible = false
    return false
  }

  isTabBarVisible = true
  return true
}

function handleHomeTabPress({ navigation }) {
  const isHomeTab =
    navigation.state.key === 'Home' || navigation.state.routeName === 'Home'

  const hasMultipleRoutes = navigation.state?.routes?.length > 1

  if (hasMultipleRoutes && isHomeTab) return navigation.popToTop()

  return navigation.navigate(navigation.state.routeName)
}

const handleTabBarPress = (navigation, route, defaultHandler) => {
  // TODO: Add handleHomeTabPress
  const { index, routes, name } = route.state

  const stackNavigation = routes[0]
  const isFamilyRoute = name === 'Family'

  navigation.popToTop()

  setTimeout(() => {
    if (isFamilyRoute) {
      navigation.reset(
        [NavigationActions.navigate({ routeName: 'FamilyMainViewTab' })],
        0
      )
    } else {
      navigation.navigate(name)
    }

    if (index === 0) {
      if (stackNavigation?.params?.scrollToTop) {
        stackNavigation.params.scrollToTop()
      }
    }
  })
}

const tabVisibilityHandler = ({ navigation, route }) => {
  const routeName = getFocusedRouteNameFromRoute(route)
  const { routes } = navigation.getState()
  const routeList = routes[1]?.state?.routeNames ?? []

  switch (routeName) {
    case routeList.includes(routeName):
      return 'none'
    case 'EditProfileView':
      return 'none'
    case 'DiseasesTypeView':
      return 'none'
    case 'DiseasesSkipLogicView':
      return 'none'
    case 'DiseasesListView':
      return 'none'
    case 'PositiveGeneTesting':
      return 'none'
    case 'PositiveGeneTestingMain':
      return 'none'
    default:
      return null
  }
}

function TabBarComponent(props) {
  return <BottomTabBar {...props} />
}

function BottomTabNavigator() {
  return (
    <BottomTabNav.Navigator
      initialRouteName="Home"
      tabBar={(props) => (
        <View
          style={{
            backgroundColor: 'white',
            borderColor: Platform.OS === platforms.WEB ? 'white' : null,
            borderTopColor: '#CECFCF',
          }}
        >
          <TabBarComponent {...props} />
        </View>
      )}
      screenOptions={({ route, navigation }) => ({
        headerVisible: false,
        headerShown: false,
        tabBarIcon: ({ focused }) => getTabBarIcon(route, focused),
        tabBarStyle: {
          display: tabVisibilityHandler({ navigation, route }),
          width: Platform.OS === platforms.WEB ? 350 : '100%',
          alignSelf: 'center',
          borderTopWidth: 0,
        },
        unmountOnBlur: true,
        tabBarShowLabel: false,
      })}
    >
      <BottomTabNav.Screen
        name="Home"
        component={DashboardNavigator}
        options={{
          tabBarLabel: 'Home',
        }}
      />

      <BottomTabNav.Screen name="Family" component={FamilyNavigator} />

      {(envConstants.HIDE_RISK === 'false' || !envConstants.HIDE_RISK) && (
        <BottomTabNav.Screen name="Risks" component={RisksNavigator} />
      )}

      <BottomTabNav.Screen name="Surveys" component={SurveyNavigator} />
    </BottomTabNav.Navigator>
  )
}

function SurveyCancerNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SurveyCancer"
        component={SurveyCancer}
        options={{ headerBackTitle: null, headerShown: false }}
      />
    </Stack.Navigator>
  )
}

function LoginSettingsNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="BiometricsSettings"
        component={BiometricsSettings}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('login_settings').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function SurveyCustomNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SurveyCustom"
        component={SurveyCustom}
        options={() => ({
          headerShown: false,
        })}
      />
    </Stack.Navigator>
  )
}

function GeneralSettingsNavigator() {
  const colorSettings = useSelector((state) => state.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ViewOnWeb"
        component={ViewOnWeb}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={route?.params?.title ?? ''}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function ChangePasswordNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('change_password').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SuccessScreenPasswordChanged"
        component={SuccessScreenPasswordChanged}
        options={{
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('change_password').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_bgcolor}
            />
          ),
        }}
      />
    </Stack.Navigator>
  )
}

function UpdatePhoneNumberNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="UpdatePhoneNumber"
        component={UpdatePhoneNumber}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('update_phone_number').default}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function ResearchDataSettingsNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ResearchDataSettings"
        component={ResearchDataSettings}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('research_data_settings').default}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function ContactUsNavigator() {
  const colorSettings = useSelector((state) => state.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ContactUs"
        component={ContactUs}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('contact_us').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function SubmitIdeasNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SubmitIdeasSettings"
        component={SubmitIdeas}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('submit_ideas').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function AddClinicianNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="AddClinicianCode"
        component={AddClinicianCode}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('add_clinician').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

function RootNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="BottomTabNavigator"
      screenOptions={{ headerShown: false, gestureEnabled: false }}
    >
      <Stack.Screen name="BottomTabNavigator" component={BottomTabNavigator} />
      <Stack.Screen name="AddMemberNavigator" component={AddMemberNavigator} />
      <Stack.Screen
        name="RelativeDiseasesNavigator"
        component={RelativeDiseasesNavigator}
      />
      <Stack.Screen
        name="RelativeGeneTestNavigator"
        component={RelativeGeneTestNavigator}
      />
      <Stack.Screen
        name="SelectParentNavigator"
        component={SelectParentNavigator}
      />
      <Stack.Screen
        name="SelectParentBloodRelationNavigator"
        component={SelectParentBloodRelationNavigator}
      />
      <Stack.Screen
        name="SelectTwinNavigator"
        component={SelectTwinNavigator}
      />
      <Stack.Screen
        name="InviteFamilyMemberNavigator"
        component={InviteFamilyMemberNavigator}
      />
      <Stack.Screen
        name="SurveyCancerNavigator"
        component={SurveyCancerNavigator}
      />
      <Stack.Screen
        name="SurveyCustomNavigator"
        component={SurveyCustomNavigator}
      />
      <Stack.Screen
        name="GeneralSettingsNavigator"
        component={GeneralSettingsNavigator}
      />
      <Stack.Screen
        name="ChangePasswordNavigator"
        component={ChangePasswordNavigator}
      />
      <Stack.Screen
        name="UpdatePhoneNumberNavigator"
        component={UpdatePhoneNumberNavigator}
      />
      <Stack.Screen
        name="ResearchDataSettingsNavigator"
        component={ResearchDataSettingsNavigator}
      />
      <Stack.Screen name="ContactUsNavigator" component={ContactUsNavigator} />
      <Stack.Screen name="LoginSettings" component={LoginSettingsNavigator} />
      <Stack.Screen name="SubmitIdeas" component={SubmitIdeasNavigator} />
      <Stack.Screen
        name="AddClinicianNavigator"
        component={AddClinicianNavigator}
      />
      <Stack.Screen
        name="CustomScreen"
        component={CustomScreen}
        options={({ navigation, route }) => ({
          headerShown: true,
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={route?.params?.title ?? ''}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              backArrowColor={colorSettings?.nav_title_light}
              closeButtonType="custom"
              customAction={route?.params?.customAction}
            />
          ),
          headerRight: () => (
            <RightButtonComponent
              navigation={navigation}
              textColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

export default RootNavigator
