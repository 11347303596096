import { Text, TouchableOpacity } from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../../styles'

function AncestryListItem({ item, setSelectedAncestry }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const isSelected = item?.selected

  return (
    <TouchableOpacity
      activeOpacity={1.0}
      style={styles.itemButton(isSelected)}
      onPress={() => setSelectedAncestry(item)}
    >
      <Text style={styles.itemText(colorSettings)}>{item?.display_name}</Text>
    </TouchableOpacity>
  )
}

export default AncestryListItem
