import React from 'react'
import { View, Text, ScrollView } from 'react-native'

import ButtonShowMore from 'screens/personal_details/onboarding_disease_selection/components/button_show_more'
import { i18n } from '$localization/config.js'
import LoadingView from '$components/LoadingView'

import FlexContainer from '$components/FlexContainer'

import SearchBar from '$screens/personal_details/onboarding_disease_selection/components/search_bar/SearchBar.js'
import SeeMoreButton from '$screens/personal_details/onboarding_disease_selection/components/see_more_button/SeeMoreButton.js'

import styles from './styles'

function DiseaseSubList(props) {
  const {
    currentState,
    currentProps,
    renderDiseaseTitle,
    handleOnChangeSearch,
    renderList,
    renderCollapsedDiseases,
    getDiseaseList,
    buttonShowMoreOnPress,
  } = props

  const {
    isLoading,
    scrollViewHeight,
    searchValue,
    renderShowMoreBtn,
    collapseShowMoreBtn,
    diseaseListCopy,
    isHiddenShowMoreButton,
    isHiddenSeeMoreButton,
    isEmptyDiseaseListMessageShown,
    isLoadingDiseases,
    isEnabledUmlsSearch,
  } = currentState
  const { colorSettings } = currentProps

  return (
    <>
      {isLoading && (
        <FlexContainer style={{ height: scrollViewHeight }}>
          <LoadingView
            backgroundColor={colorSettings?.onboarding_bgcolor}
            tintColor={colorSettings?.btn_no_fill_text_2}
            textColor={colorSettings?.btn_no_fill_text_2}
            visible={isLoading}
          />
        </FlexContainer>
      )}
      <ScrollView
        style={{ height: scrollViewHeight }}
        contentContainerStyle={styles.contentContainer}
      >
        <FlexContainer style={{ alignItems: 'center' }}>
          <Text
            style={[
              styles.titleText,
              {
                color: colorSettings?.text_input_title_color_2 || 'black',
              },
            ]}
          >
            {renderDiseaseTitle}
          </Text>
          <Text
            style={[
              styles.titleText,
              styles.subTitleText,
              {
                color: colorSettings?.text_input_title_color_2 || 'black',
              },
            ]}
          >
            {i18n.t('select_all_that_apply').default}
          </Text>
          <View style={styles.buttonContainerAdopted}>
            <SearchBar
              colorSettings={colorSettings}
              searchValue={searchValue}
              onChange={handleOnChangeSearch}
            />
            {renderList}

            {!isHiddenShowMoreButton && (
              <ButtonShowMore
                onPress={buttonShowMoreOnPress}
                renderThis={renderShowMoreBtn}
                toggleState={collapseShowMoreBtn}
                colorSettings={colorSettings}
              />
            )}
            {renderCollapsedDiseases}
            {!isHiddenSeeMoreButton && (
              <SeeMoreButton
                colorSettings={colorSettings}
                onPress={getDiseaseList}
              />
            )}
            {isEmptyDiseaseListMessageShown && (
              <View style={styles.emptyDiseaseListMessageContainer}>
                <Text
                  style={[
                    styles.emptyDiseaseListMessageText,
                    {
                      color: colorSettings?.text_input_label_color_2 || 'black',
                    },
                  ]}
                >
                  {
                    i18n.t(
                      'sorry_no_diseases_match_your_search_in_this_category_try_searching_in_the_other_conditions_category_for_more_options'
                    ).default
                  }
                </Text>
              </View>
            )}
            {isLoadingDiseases && (
              <LoadingView
                backgroundColor={colorSettings?.onboarding_bgcolor}
                tintColor={colorSettings?.btn_no_fill_text_2}
                textColor={colorSettings?.btn_no_fill_text_2}
                visible
                containerStyle={styles.containerStyleLoadingView}
              />
            )}
          </View>
        </FlexContainer>
      </ScrollView>
    </>
  )
}

export default DiseaseSubList
