import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  loginButton: (colorSettings, isFilled) => ({
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    height: 60,
    width: 280,
    borderWidth: 1.0,
    marginVertical: 5,
    backgroundColor: isFilled
      ? colorSettings?.btn_filled_enabled_1
      : colorSettings?.btn_no_fill_enabled_1,
    borderColor: isFilled
      ? colorSettings?.btn_filled_border_1
      : colorSettings?.btn_no_fill_border_1,
  }),
  loginButtonText: (colorSettings, isFilled) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    color: isFilled
      ? colorSettings?.btn_filled_text_1
      : colorSettings?.btn_no_fill_text_1,
  }),
})

export default styles
