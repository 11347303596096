/* eslint-disable camelcase */

function checkSkipLogicOperator(operator, value1, value2) {
  switch (operator) {
    case '==':
      return value1 === value2
    case '!=':
      return value1 !== value2
    case '>':
      return parseInt(value1, 10) > parseInt(value2, 10)
    case '>=':
      return parseInt(value1, 10) >= parseInt(value2, 10)
    case '<':
      return parseInt(value1, 10) < parseInt(value2, 10)
    case '<=':
      return parseInt(value1, 10) <= parseInt(value2, 10)
    case 'IN':
      if (typeof value2 === 'string') {
        const valuesArray = value2.split(',').map((v) => v.trim())
        return valuesArray.includes(value1)
      }

      return false
    case 'NOT IN':
      if (typeof value2 === 'string') {
        const valuesArray = value2.split(',').map((v) => v.trim())
        return !valuesArray.includes(value1)
      }

      return false
    default:
      return false
  }
}

function checkSkipLogics(skipLogics, probandDiseaseSkipLogics) {
  if (skipLogics.length === 0) return true

  return skipLogics.every((skipLogic) => {
    const foundSkipLogic = probandDiseaseSkipLogics.find(
      (probandSkipLogic) =>
        parseInt(probandSkipLogic.skip_logic_id, 10) ===
        parseInt(skipLogic.skip_logic_id, 10)
    )

    return (
      foundSkipLogic &&
      checkSkipLogicOperator(
        skipLogic.operator,
        foundSkipLogic.answer,
        skipLogic.skip_logic_answer
      )
    )
  })
}

function checkDiseasePrerequisites(masterQuestion, surveyObj) {
  const { proband_diseases, proband_disease_skip_logics } = surveyObj

  return masterQuestion.disease_question_prerequisites.every((prerequisite) => {
    const prerequisiteDiseaseId = parseInt(prerequisite.disease_id, 10)

    const foundDisease = proband_diseases.find(
      (disease) => parseInt(disease.disease_id, 10) === prerequisiteDiseaseId
    )

    if (!foundDisease) return false

    if (!prerequisite.age_diagnosed) return true

    if (
      !checkSkipLogicOperator(
        prerequisite.operator,
        foundDisease.age_diagnosed,
        prerequisite.age_diagnosed
      )
    )
      return false

    return checkSkipLogics(
      prerequisite.disease_skip_logic_question_prerequisites,
      proband_disease_skip_logics
    )
  })
}

export default checkDiseasePrerequisites
