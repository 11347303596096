import { Image } from 'react-native'

import styles from './styles.js'

export default function SuccessIcon() {
  return (
    <Image
      source={require('$assets/images/new_assets/success-check-purple.png')}
      style={styles.successIcon}
    />
  )
}
