import { apiFetchHandler } from '$api'

const updatePersonalInformation = async ({
  proband,
  account,
  selectedGender,
  genderIdentity,
  pronoun,
  otherPronoun,
  authCredentials,
  saveError,
}) => {
  const payload = {
    proband_id: proband.probandID,
    member_id: proband.probandID,
    member_info: {
      gender: account?.gender || selectedGender,
      gender_identity: account?.gender_identity || genderIdentity,
      pronoun: account?.pronoun || pronoun,
      otherPronoun,
    },
  }

  const updatePersonalInfoPayload = {
    path: 'member/update_personal_info/',
    method: 'post',
    token: authCredentials.accessToken,
    body: {
      ...payload,
    },
    pageDetails: {
      page: 'PersonalGender.js',
    },
  }

  const response = await apiFetchHandler(updatePersonalInfoPayload)

  if (response.isError) {
    saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })
  }
}

const updateProbandProfileStorage = async ({
  proband,
  authCredentials,
  updateMemberProfile,
  saveProbandProfileToStore,
}) => {
  const memberData = {
    proband_id: proband.probandID,
    member_id: proband.probandID,
  }

  try {
    const memberProfile = await updateMemberProfile(memberData, authCredentials)
    saveProbandProfileToStore(memberProfile)
  } catch (err) {
    console.log(
      '🚀 \n\n file: PersonalGender.js:344 \n\n PersonalGender \n\n updateProbandProfileStorage= \n\n err:',
      err
    )
  }
}

export { updatePersonalInformation, updateProbandProfileStorage }
