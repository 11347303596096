import { Component, createRef } from 'react'
import { View, Text, TextInput } from 'react-native'
import moment from 'moment'

import { i18n } from '$localization/config.js'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { isNotValidAOD } from '$screens/main/family/edit_profile/_components/personal_info/components/_utils/Utils.js'

import {
  yearIntervalFromNow,
  handleDisplayShorterAgeUnits,
  formatAge,
} from '$utils'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import AgeDropdown from './AgeDropdown'
import { DEFAULT_DATE_FORMAT } from '$constants/dateFormats'

const CURRENT_AGE = 'currentAge'
const DOB = 'dob'
const CURRENT_AGE_STRING = 'currentAgeString'
const INVALID_DATE = 'Invalid date'

class CurrentAgeField extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  memberProfile = this.props.memberProfile

  profileData = this.memberProfile?.profileData

  visible = this.props.visible

  currentAge = 0

  allowUserInputAge = this.props.allowUserInputAge

  setAllowUserInputAge = this.props.setAllowUserInputAge

  bottomSheetRef = this.props.bottomSheetRef ?? createRef()

  constructor(props) {
    super(props)

    const newState = {
      visible: this.visible,
      currentAge: null,
      tempCurrentAge: null,
      showDropdown: false,
      currentAgeType: null,
    }

    const { profileData } = this

    if (profileData) {
      // Calculate currentAge from DOB on first load.
      newState.currentAge = profileData?.currentAge
      newState.dob = profileData?.dob
      newState.currentAgeType = profileData?.currentAgeType

      if (newState.dob) {
        const calculatedAgeFromDOB = yearIntervalFromNow(
          newState.dob,
          true,
          true
        )

        newState.currentAge = `${calculatedAgeFromDOB.split(' ')[0]}`
      }
    }

    this.currentAge = newState.currentAge
    this.state = newState
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const profileData = memberProfile?.profileData
    const { allowUserInputAge } = newProps

    if (profileData?.currentAge === prevState.currentAge) return null
    if (profileData?.currentAge) {
      const newState = {
        ...prevState,
        visible: newProps.visible,
      }

      if (!allowUserInputAge) {
        newState.currentAge = profileData?.currentAge
        newState.dob = profileData?.dob

        if (newState.dob) {
          const calculatedAgeFromDOB = yearIntervalFromNow(
            newState.dob,
            true,
            true
          )

          newState.currentAge = `${calculatedAgeFromDOB.split(' ')[0]}`
        }
      }

      return newState
    }
  }

  updateCurrentAge = (val) => {
    const { currentAgeType } = this.props

    const currentAge = val ?? 0

    if (currentAge <= 0) return

    this.currentAge = `${currentAge}`
    this.parentController?._updateState_(
      `${currentAge}${handleDisplayShorterAgeUnits(currentAgeType)}`
    )

    this.updateDOB(currentAge)

    this.parentController?._updateState_(CURRENT_AGE, currentAge)
    this.parentController?._updateState_(CURRENT_AGE_STRING, currentAge)
  }

  onEndEditingAge = () => {
    const { currentAge } = this.state
    const prevState = this.state
    const memberDisease =
      this.memberProfile.healthHistoryData.diseasesData.diseases
    let updatedAge = prevState.tempCurrentAge
    const updatedCurrentAgeString = `${Number(
      prevState.currentAge
    )}${handleDisplayShorterAgeUnits(this.props.currentAgeType)}`

    if (!prevState.currentAge || prevState.currentAge === '') {
      this.setState({ currentAge: prevState.tempCurrentAge })
    } else if (
      isNotValidAOD(
        prevState.currentAge,
        memberDisease,
        true,
        updatedCurrentAgeString
      )
    ) {
      this.setState({ currentAge: prevState.tempCurrentAge })
    } else {
      const newState = this.state
      newState.tempCurrentAge = updatedAge = prevState.currentAge
      this.setState(newState)
    }

    if (Number(updatedAge > 130)) {
      updatedAge = 130
      showDefaultAlert('Oops', 'Current age must not be greater than 130.')
    } else if (Number(updatedAge < 0)) {
      updatedAge = 0
      showDefaultAlert('Oops', 'Current age cannot be less than 0.')
    }

    this.updateCurrentAge(currentAge)
  }

  handlePressButton = (event) => {
    this.parentController?._updateState_(
      CURRENT_AGE_STRING,
      `${this.profileData.currentAge}${handleDisplayShorterAgeUnits(event)}`
    )
    this.props.handleAgeUnitChange(event)
    this.setState({ showDropdown: false })
  }

  updateDOB(currentAge) {
    if (!currentAge) return

    const { currentAgeType } = this.props

    const { dob = '' } = this.profileData

    if (!dob) return

    const newDob = formatAge(currentAge, currentAgeType)

    let formattedDOB = null

    if (dob) {
      formattedDOB = moment(newDob).format(DEFAULT_DATE_FORMAT)

      formattedDOB = formattedDOB !== INVALID_DATE ? formattedDOB : null
    }

    this.parentController?._updateState_(DOB, formattedDOB)
  }

  render() {
    if (!this.state.visible) return null
    if (!this.profileData) return null

    const { currentAgeType } = this.props

    return (
      <View style={{ zIndex: 2 }}>
        <View style={section_styles.contentSeparatorLine} />

        <View style={section_styles.contentItem}>
          <View
            style={[
              section_styles.contentItemLabelTextContainer,
              {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            ]}
          >
            <Text
              style={[
                section_styles.contentItemLabelText,
                {
                  color: this.colorSettings?.text_input_label_color_1,
                  marginRight: 10,
                },
              ]}
              onPress={() => {
                this.ageInput.focus()
              }}
            >
              {i18n.t('current_age').default}
            </Text>
            <AgeDropdown
              buttonValue={currentAgeType}
              inputRef={this.bottomSheetRef}
              onButtonPress={this.handlePressButton}
            />
          </View>
          <View
            style={[
              section_styles.contentItemTextInputContainer,
              {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              },
            ]}
          >
            <TextInput
              nativeID="web_text_input"
              ref={(input) => (this.ageInput = input)}
              style={[
                section_styles.contentTextInputAge,
                { color: this.colorSettings?.text_input_color_1 },
              ]}
              maxLength={3}
              placeholder="0"
              placeholderTextColor={
                this.colorSettings?.text_input_placeholder_color_1 ||
                'rgba(74,74,74,0.5)'
              }
              keyboardType="number-pad"
              onFocus={() => {
                this.setState({ currentAge: '' })
                this.setAllowUserInputAge(true)
              }}
              onBlur={() => {
                this.onEndEditingAge()
                this.setAllowUserInputAge(false)
              }}
              onChangeText={(val) => {
                this.setState({ currentAge: val })
              }}
              onChange={this.props.onChange}
              value={`${this.state.currentAge}`}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default CurrentAgeField
