const GENDER_NON_BINARY = 'non-binary'
const GENDER_FEMALE = 'female'
const GENDER_MALE = 'male'

const SKIN_TONE_FAIR = 'fair'
const SKIN_TONE_TAN = 'tan'
const SKIN_TONE_DARK = 'dark'

const IMAGE_NON_BINARY_FAIR = 'NonbinaryFairSkinTone'
const IMAGE_NON_BINARY_TAN = 'NonbinaryTanSkinTone'
const IMAGE_NON_BINARY_DARK = 'NonbinaryDarkSkinTone'

const IMAGE_FEMALE_FAIR = 'FemaleFairSkinTone'
const IMAGE_FEMALE_TAN = 'FemaleTanSkinTone'
const IMAGE_FEMALE_DARK = 'FemaleDarkSkinTone'

const IMAGE_MALE_FAIR = 'MaleFairSkinTone'
const IMAGE_MALE_TAN = 'MaleTanSkinTone'
const IMAGE_MALE_DARK = 'MaleDarkSkinTone'

export {
  GENDER_NON_BINARY,
  GENDER_FEMALE,
  GENDER_MALE,
  SKIN_TONE_FAIR,
  SKIN_TONE_TAN,
  SKIN_TONE_DARK,
  IMAGE_NON_BINARY_FAIR,
  IMAGE_NON_BINARY_TAN,
  IMAGE_NON_BINARY_DARK,
  IMAGE_FEMALE_FAIR,
  IMAGE_FEMALE_TAN,
  IMAGE_FEMALE_DARK,
  IMAGE_MALE_FAIR,
  IMAGE_MALE_TAN,
  IMAGE_MALE_DARK,
}
