import AsyncStorage from '@react-native-async-storage/async-storage'

const ERROR_MESSAGE = 'Error in removeItemAsyncStorage:'

export default async function removeItemAsyncStorage(key) {
  if (!key || key.trim() === '') return

  try {
    await AsyncStorage.removeItem(`@App:${key}`)
  } catch (error) {
    console.log(ERROR_MESSAGE, error)
  }
}
