import { useDispatch, useSelector } from 'react-redux'
import { setCliniciansAction } from '$redux/clinician/actions.js'
import {
  setSurveysAction,
  setClinicianSurveysAction,
  setOptionalSurveysAction,
  setSelectedSurveyAction,
} from '$redux/survey/actions.js'
import { setError, setShowLoadingView } from '$redux/defaults/actions.js'

export const useReduxData = () => {
  const reduxData = useSelector((state) => ({
    allowBackgroundRefresh: state.store.allowBackgroundRefresh,
    backgroundRefreshInterval: state.store.backgroundRefreshInterval,
    colorSettings: state.store.colorSettings,
    authCredentials: state.accountStore.authCredentials,
    proband: state.probandStore.proband,
  }))

  return reduxData
}

export const useReduxActions = () => {
  const dispatch = useDispatch()

  return {
    setClinicians: (data) => dispatch(setCliniciansAction(data)),
    setSurveys: (data) => dispatch(setSurveysAction(data)),
    setClinicianSurveys: (data) => dispatch(setClinicianSurveysAction(data)),
    setOptionalSurveys: (data) => dispatch(setOptionalSurveysAction(data)),
    setSelectedSurvey: (data) => dispatch(setSelectedSurveyAction(data)),
    saveError: (data) => dispatch(setError(data)),
    showLoadingView: (data) => dispatch(setShowLoadingView(data)),
  }
}
