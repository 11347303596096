import { StyleSheet } from 'react-native'

const COLORS = {
  WHITE: 'white',
  BLACK: 'black',
  SEMI_TRANSPARENT_GRAY: 'rgba(100,100,100,0.5)',
  LIGHT_GRAY: '#ccc',
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: COLORS.WHITE,
    width: '100%',
    flex: 1.0,
  },
  safeAreaView: {
    backgroundColor: COLORS.WHITE,
    width: '100%',
    flex: 1.0,
  },
  view: {
    backgroundColor: COLORS.WHITE,
    width: '100%',
    paddingHorizontal: 6,
    height: 48,
    borderColor: COLORS.SEMI_TRANSPARENT_GRAY,
    borderBottomWidth: 1.0,
  },
  innerView: {
    flexDirection: 'row',
    flex: 1.0,
    alignItems: 'center',
  },
  flexOne: {
    flex: 0.1,
  },
  touchableOpacity: {
    height: '100%',
    justifyContent: 'center',
  },
  image: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  webViewContainer: {
    flex: 1.0,
    width: '100%',
    backgroundColor: COLORS.LIGHT_GRAY,
  },
  webView: {
    flex: 1.0,
    width: '100%',
  },
})

export default styles
