import { Component } from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native'

import { fixDiseaseName } from '$utils'
import { handleDisplayAgeFromAgeString } from '$screens/main/family/edit_profile/_utils/Utils'
import { i18n } from '$localization/config.js'

import styles from './_styles/section.styles'

import Alert from '$components/Alert'

class MemberDiseasesSection extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  healthHistoryData = this.props.healthHistoryData

  diseasesData = this.props.diseasesData

  state = {
    healthHistoryData: this.healthHistoryData,
    diseasesData: this.diseasesData,
    memberDiseases: this.diseasesData?.diseases ?? [],
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { healthHistoryData } = newProps
    const { diseasesData } = newProps

    return {
      ...prevState,
      healthHistoryData,
      diseasesData,
      memberDiseases: diseasesData?.diseases ?? [],
    }
  }

  /* UPDATED */
  navigateToDiseasesMain = () => {
    this.parentController?.navigateToDiseasesMain()
  }

  /* UPDATED */
  onPressEditDiseaseListItem = (itemID) => {
    this.parentController?.editDiseaseListItem(itemID)
  }

  /* UPDATED */
  onPressDeleteDiseaseListItem = (itemID) => {
    const confirmDelete = () =>
      this.parentController?.deleteDiseaseListItem(itemID)

    Alert.alert(
      i18n.t('confirm_remove')?.default,
      i18n.t('are_you_sure_you_want_to_remove_this_disease')?.default,
      [
        {
          text: i18n.t('yes')?.default,
          style: 'default',
          onPress: () => confirmDelete(),
        },
        { text: i18n.t('no')?.default, style: 'cancel' },
      ]
    )
  }

  render() {
    const { memberDiseases, diseasesData } = this.state
    const { diagnosedWithDisease } = diseasesData

    return (
      <View>
        <View style={styles.contentItem}>
          <View style={[styles.contentItemLabelTextContainer, { flex: 0.7 }]}>
            <Text style={styles.contentItemLabelText}>
              {
                i18n.t('has_this_person_ever_been_diagnosed_with_a_disease')
                  ?.default
              }
            </Text>
          </View>

          <View style={[styles.contentItemTextInputContainer, { flex: 0.3 }]}>
            <TouchableOpacity
              style={styles.healthHistoryAddButton}
              onPress={this.navigateToDiseasesMain}
            >
              <Text
                style={[
                  styles.healthHistoryAddButtonText,
                  {
                    color: this.colorSettings?.linked_text,
                  },
                ]}
              >
                {i18n.t('add')?.default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        {diagnosedWithDisease && (
          <DiseasesListContainer
            diseasesList={memberDiseases}
            onPressAdd={() => this.navigateToDiseasesMain()}
            onPressEdit={(itemID) => this.onPressEditDiseaseListItem(itemID)}
            onPressDelete={(itemID) =>
              this.onPressDeleteDiseaseListItem(itemID)
            }
          />
        )}
      </View>
    )
  }
}

function DiseasesListContainer({
  diseasesList = [],
  onPressEdit = function () {},
  onPressDelete = function () {},
}) {
  const DiseasesList = () => {
    const layout = diseasesList.map((item) => (
      <DiseaseItem
        key={`DiseaseItem-${item?.id}`}
        item={item}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
      />
    ))

    return layout
  }

  return (
    <View style={{ marginHorizontal: 16 }}>
      <View style={styles.contentItemListContainer}>
        <View style={{ marginVertical: 16 }}>
          {diseasesList.length > 0 && (
            <View style={styles.itemListRowContainer}>
              <DiseasesList />
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

function DiseaseItem({
  key,
  item,
  onPressEdit = function () {},
  onPressDelete = function () {},
}) {
  /* Format item display name */

  const _diseaseShortName_ = fixDiseaseName(
    item?.disease_short_name,
    item.disease_id === item.umls_id
  )
  const diseaseShortName =
    i18n.t(_diseaseShortName_)?.default ?? _diseaseShortName_

  const ageOfDiagnosis = handleDisplayAgeFromAgeString(
    item?.age_diagnosed_string
  )
  const displayAge = (ageOfDiagnosis ?? item?.age_diagnosed) || '?'
  const displayName = `${diseaseShortName}, ${displayAge}`
  const itemID = item?.id

  return (
    <View key={key} style={styles.itemListRow}>
      <View style={styles.itemListRowLabelContainer}>
        <Text style={styles.itemListRowLabelText}>
          {i18n.t(displayName)?.default ?? displayName}
        </Text>
      </View>

      <View style={styles.itemListRowAccessoryContainer}>
        <TouchableOpacity
          onPress={() => onPressEdit(item)}
          style={styles.itemListRowAccessory}
        >
          <Image
            source={require('$assets/images/new_assets/icon-edit.png')}
            style={{ width: 14, height: 16, resizeMode: 'contain' }}
          />
        </TouchableOpacity>
      </View>

      <View style={styles.itemListRowAccessoryContainer}>
        <TouchableOpacity
          onPress={() => onPressDelete(itemID)}
          style={styles.itemListRowAccessory}
        >
          <Image
            source={require('$assets/images/new_assets/icon-trash.png')}
            style={{ width: 14, height: 16, resizeMode: 'contain' }}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default MemberDiseasesSection
