import React, { useEffect } from 'react'
import { SafeAreaView, View, Text, Image, StatusBar } from 'react-native'
import { useSelector } from 'react-redux'

import CustomSubmitButton from '$components/CustomSubmitButton'
import { i18n } from '$localization/config.js'

import { IS_WEB, IS_IOS } from '$utils/Platforms.js'
import { calculateAvailableContentHeight } from '$assets/styles/global.styles.js'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { ROUTES } from '$navigation/constants/routes'

import resetReduxStoreData from './helpers.js'

import styles from './styles.js'

export default function SuccessScreenPasswordChanged({ navigation }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const successText = i18n.t('success').default
  const passwordChangedText = i18n.t('your_password_has_been_changed').default
  const loginText = i18n.t('login_to_your_account').default

  useEffect(() => {
    if (!IS_WEB()) return

    const handleResize = () => {
      calculateAvailableContentHeight()
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const gotoLoginScreen = () => {
    resetReduxStoreData()

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: ROUTES.CustomLoginScreen.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  return (
    <SafeAreaView style={styles.mainContainer}>
      {IS_IOS() && <StatusBar barStyle="dark-content" />}

      <View style={styles.bodyContainer}>
        <View style={styles.imageContainer}>
          <Image
            source={require('$assets/images/new_assets/illus-sent.png')}
            style={styles.image}
          />
        </View>

        <View style={styles.titleContainer}>
          <Text style={styles.title(colorSettings)}>{successText}</Text>
        </View>

        <View style={styles.contentContainer}>
          <Text style={styles.content(colorSettings)}>
            {passwordChangedText}
          </Text>
        </View>
      </View>

      <View style={styles.buttonContainer}>
        <CustomSubmitButton
          onPress={gotoLoginScreen}
          title={loginText}
          style={styles.customSubmitButton(colorSettings)}
          titleStyle={styles.customSubmitButtonTitle(colorSettings)}
        />
      </View>
    </SafeAreaView>
  )
}
