import { Image } from 'react-native'
import { useEffect, useState } from 'react'

import { imageURLs } from '$constants/loadImages'
import { IS_WEB, IS_MOBILE } from '$utils/Platforms.js'
import { DOMAIN_CLIENT } from '$utils/globalVariables.js'
import { resizeLogo } from './helpers'

function LogoView({ splashSettings = {} }) {
  const [logoSize, setLogoSize] = useState(resizeLogo)
  const isWhiteLabelClient = DOMAIN_CLIENT === 'COH-APP'

  const {
    use_custom_logo: shouldUseCustomLogo = false,
    custom_logo: customLogoURL = '',
    default_logo: defaultLogoURL = '',
  } = splashSettings

  const mobileLogoSize = '100%'
  const backgroundSignupIconNumber = 2

  let logoSource = shouldUseCustomLogo
    ? customLogoURL
    : imageURLs.FAMGENIX.logo[backgroundSignupIconNumber]?.uri

  if (isWhiteLabelClient) {
    logoSource = shouldUseCustomLogo ? customLogoURL : defaultLogoURL
  }

  const imgStyle = {
    resizeMode: 'contain',
    width: IS_WEB() ? logoSize.width : mobileLogoSize,
    height: IS_WEB() ? logoSize.height : mobileLogoSize,
    aspectRatio: 2,
  }

  useEffect(() => {
    if (IS_MOBILE()) return

    const handleResizeListener = () => setLogoSize(resizeLogo())

    window.addEventListener('resize', handleResizeListener)

    return () => {
      window.removeEventListener('resize', handleResizeListener)
    }
  }, [])

  return <Image style={imgStyle} source={{ uri: logoSource }} />
}

export default LogoView
