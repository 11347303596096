import React from 'react'
import { Platform, Text, View } from 'react-native'
import global_styles from '$assets/styles/global.styles'

const HeaderTitleComponent = ({
  headerTitle = '',
  headerSubtitle = '',
  headerBackgroundColor = 'white',
  headerTintColor = 'black',
}) => {
  let headerTitleOptions
  let headerTitleFontSize = 18

  if (headerTitle) {
    const n = headerTitle.length

    switch (true) {
      case n > 20:
        headerTitleFontSize = 14
        break
      case n > 40:
        headerTitleFontSize = 12
        break
      default:
        headerTitleFontSize = 18
    }
  }

  if (headerSubtitle) {
    headerTitleOptions = (
      <View
        style={[
          global_styles.headerBarTitleContainer,
          global_styles.webTopBarWidth,
          {
            ...Platform.select({
              web: {
                backgroundColor: headerBackgroundColor,
              },
            }),
          },
        ]}
      >
        <Text
          style={[
            global_styles.headerBarMainTitle,
            {
              color: headerTintColor,
              fontSize: headerTitleFontSize,
            },
          ]}
        >
          {headerTitle}
        </Text>
        <Text
          style={[global_styles.headerBarSubtitle, { color: headerTintColor }]}
        >
          {headerSubtitle}
        </Text>
      </View>
    )
  } else {
    headerTitleOptions = (
      <View
        style={[
          global_styles.headerBarTitleContainer,
          global_styles.webTopBarWidth,
          {
            ...Platform.select({
              web: {
                backgroundColor: headerBackgroundColor,
              },
            }),
          },
        ]}
      >
        <Text
          style={[
            global_styles.headerBarMainTitle,
            {
              color: headerTintColor,
              fontSize: headerTitleFontSize,
            },
          ]}
        >
          {headerTitle}
        </Text>
      </View>
    )
  }

  return headerTitleOptions
}

export default HeaderTitleComponent
