import React from 'react'
import { View, Text } from 'react-native'
import uuid from 'react-native-uuid'

import { i18n } from '$localization/config.js'

export default function PageContent(category, content) {
  if (!category?.category_label) return content

  // TODO: From here, the survey adds a weird label without style, check if we really need it
  const categoryLabelTranslation = i18n.t(`${category?.translation_key}.label`)

  return (
    <React.Fragment key={uuid.v4()}>
      <View key={uuid.v4()}>
        <Text>{categoryLabelTranslation}</Text>
      </View>

      {content}
    </React.Fragment>
  )
}
