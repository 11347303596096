import {
  View,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'

import { connect } from 'react-redux'

import moment from 'moment'

import { IS_MOBILE } from '$utils/Platforms.js'
import { platforms } from '$constants'
import { i18n } from '$localization/config.js'

import EditProfileController from '$screens/main/family/edit_profile/EditProfile.controller.js'

import LoadingView from '$components/LoadingView'
import CustomDatePicker from '$components/CustomDatePicker'
import PersonalInfoFormView from './_components/personal_info/PersonalInfoForm.view.js'
import HealthHistorySection from './_components/health_history/HealthHistory.section.js'
import RelationshipInfoSection from './_components/relationship_info/RelationshipInfo.section.js'

import DeleteProfileButton from '$components/DeleteProfileButton'

import FamilyInviteButton from '$screens/main/family/edit_profile/_components/FamilyInviteButton'

import {
  setProbandProfileAction,
  setBasicFamilyMembers,
  setShouldReloadFamilyList,
} from '$redux/proband/actions.js'

import {
  storeMemberProfileAction,
  storeCurrentMember,
  setNewParentsForMember,
  setReloadersToDefault,
  setShouldReloadInviteStatus,
  setShouldReloadTwins,
  setShouldReloadDiseases,
  setShouldReloadGeneticTestings,
} from '$redux/member_profile/actions.js'

import {
  setFromOnboardingDiseases,
  resetDiseasesStore,
  setScreenWhereToNavigate,
  setIndividualDisease,
  setDiseaseProband,
} from '$redux/diseases/actions.js'

import {
  setFromOnboardingGeneTest,
  resetGeneTestingStore,
} from '$redux/gene_testing/actions.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import custom_styles from './_styles/main.styles.js'
import MainWrapper from '$components/MainWrapper'

import BottomSheetComponent from '$components/BottomSheetComponent'

import { handleGetCustomScreenText } from '$navigation/_utils/custom_workflow'
import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import { AGE_TYPE_VALUES, GENDER_IDENTITY_VALUES } from './constants'
import { parseDateOfBirth } from '$utils'

// TODO: Maybe this is a special case
const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.EditProfileCancer?.name
const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

const webHeightAdjustment = -50
const getScrollViewHeight = () =>
  calculateAvailableContentHeight(webHeightAdjustment)

const FAMILY_MEMBERS_RELATIONSHIPS = {
  GREAT_GRANDMOTHER: 'great grandmother',
  GREAT_GRANDFATHER: 'great grandfather',
}

class EditProfileView extends EditProfileController {
  renderBottomSheet = () => {
    const {
      memberProfile,
      currentAgeType,
      ageOfDeceasedType,
      genderIdentityValue,
    } = this.state

    const availableDiagnosisValues = Object.values(AGE_TYPE_VALUES)
    const availableGenderIdentityValues = Object.values(GENDER_IDENTITY_VALUES)

    const isDeceasedField = memberProfile?.profileData.deceased

    const bottomSheetAgeProps = {
      reference: this.bottomSheetAgeRef,
      startPoint: ['35%', '100%'],
      listItems: availableDiagnosisValues,
    }

    const bottomSheetGenderIdentityProps = {
      reference: this.bottomSheetGenderIdentityRef,
      startPoint: ['35%', '100%'],
      listItems: availableGenderIdentityValues,
    }

    if (IS_MOBILE()) {
      const handleBottomSheetAgeButtonActions = isDeceasedField
        ? this.handleBottomSheetDeceasedAgePressButton
        : this.handleBottomSheetCurrentAgePressButton

      const selectedAgeTypeData = isDeceasedField
        ? ageOfDeceasedType
        : currentAgeType

      return (
        <>
          <BottomSheetComponent
            {...bottomSheetGenderIdentityProps}
            onSelectItemAction={this.handleGenderIdentityChange}
            selectedItem={genderIdentityValue}
          />
          <BottomSheetComponent
            {...bottomSheetAgeProps}
            onSelectItemAction={handleBottomSheetAgeButtonActions}
            selectedItem={selectedAgeTypeData}
          />
        </>
      )
    }

    return null
  }

  render() {
    const {
      isScreenActive,
      memberProfile,
      currentMember,
      deleteButtonShown,
      currentAgeType,
      ageOfDeceasedType,
    } = this.state

    const { navigation, twinsList, saveError } = this.props
    const { relationship_to_proband: relationShipToProband = '' } =
      this.currentMember

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView
          style={[
            global_styles.containerWhite,
            {
              backgroundColor:
                this.colorSettings?.post_onboarding_bgcolor ??
                'rgb(245,245,245)',
              ...Platform.select({
                [platforms.WEB]: {
                  flex: null,
                  height: this.state.scrollViewHeight,
                },
              }),
            },
          ]}
        >
          <LoadingView
            backgroundColor={this.colorSettings?.splash_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_border_1}
            textColor={this.colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
            message={i18n.t('please_wait')?.default}
          />
        </SafeAreaView>
      )
    }

    if (!memberProfile?.memberID || memberProfile?.memberID === '') return null

    /* Get current member's relationship to Proband */
    const allowFamilyInvite = currentMember?.allow_family_invite == 'ENABLED'

    /* Convert DOB to Date object to be compatible with DatePicker */
    const dob = memberProfile?.profileData?.dob ?? null

    const isGreatGrandparent =
      relationShipToProband?.includes(
        FAMILY_MEMBERS_RELATIONSHIPS.GREAT_GRANDMOTHER
      ) ||
      relationShipToProband?.includes(
        FAMILY_MEMBERS_RELATIONSHIPS.GREAT_GRANDFATHER
      )

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation} screenActive={isScreenActive}>
          <SafeAreaView
            style={[
              global_styles.containerWhite,
              {
                backgroundColor:
                  this.colorSettings?.post_onboarding_bgcolor ??
                  'rgb(245,245,245)',
                ...Platform.select({
                  [platforms.WEB]: {
                    flex: null,
                    height: getScrollViewHeight(),
                  },
                }),
              },
            ]}
          >
            <ScrollView
              nativeID="web_scaled_main_container"
              style={[
                {
                  ...Platform.select({
                    [platforms.WEB]: {
                      height: getScrollViewHeight(),
                    },
                  }),
                },
                custom_styles.container,
              ]}
              contentContainerStyle={custom_styles.contentContainer}
              ref={(ref) => (this._scrollView_ = ref)}
              scrollEventThrottle={16} /* Note: Might impact performance. */
              onScroll={(e) => this._onScroll_(e)}
              keyboardDismissMode="on-drag"
              bounces={false}
            >
              <View style={custom_styles.content}>
                {/* Personal Info Section */}
                <PersonalInfoFormView
                  colorSettings={this.colorSettings}
                  parentController={this}
                  proband={this.proband}
                  memberProfile={memberProfile}
                  currentMember={currentMember}
                  language={this.props.selectedLanguage}
                  onChange={() =>
                    this.setState((prev) => ({
                      isScreenActive: !prev.isScreenActive,
                    }))
                  }
                  saveError={saveError}
                  currentAgeType={currentAgeType}
                  ageOfDeceasedType={ageOfDeceasedType}
                  bottomSheetRef={this.bottomSheetAgeRef}
                  bottomSheetGenderIdentityRef={
                    this.bottomSheetGenderIdentityRef
                  }
                  handleWebDatepickerWithAgeUnits={
                    this.handleWebDatepickerWithAgeUnits
                  }
                  handleAgeUnitChange={this.handleAgeUnitChange}
                  handleGenderIdentityChange={this.handleGenderIdentityChange}
                  genderIdentityValue={this.state.genderIdentityValue}
                />

                <View style={{ height: 16, zIndex: -1 }} />

                {/* Health History Section */}
                <HealthHistorySection
                  colorSettings={this.colorSettings}
                  parentController={this}
                  proband={this.proband}
                  memberProfile={memberProfile}
                  currentMember={currentMember}
                  saveError={saveError}
                />

                <View style={{ height: 16 }} />

                {/* Relationship Section */}
                <RelationshipInfoSection
                  colorSettings={this.colorSettings}
                  parentController={this}
                  proband={this.proband}
                  memberProfile={memberProfile}
                  currentMember={currentMember}
                  twinsList={twinsList}
                  saveError={saveError}
                />

                <View style={{ height: 16 }} />

                {/* Invite-To-Family Button */}
                {allowFamilyInvite && (
                  <FamilyInviteButton
                    currentMember={currentMember}
                    memberProfile={memberProfile}
                    onPress={this.navigateToInviteFamilyMember}
                  />
                )}

                {/* Delete Profile Button */}
                {deleteButtonShown && (
                  <DeleteProfileButton
                    primaryAction={() => this.deleteProfile()}
                    isGreatGrandparents={isGreatGrandparent}
                  />
                )}

                <View style={{ height: 8 }} />
              </View>
            </ScrollView>

            {/* Done Button */}
            <View
              style={{
                height: 60,
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <TouchableOpacity
                style={[
                  custom_styles.doneButtonContainer,
                  this.toggleNextButtonColor(this.state.disabledDoneButton),
                ]}
                onPress={this.doneButtonAction}
                disabled={this.state.disabledDoneButton}
              >
                <Text
                  style={[
                    custom_styles.doneButtonText,
                    {
                      color:
                        this.colorSettings?.bottom_next_btn_text || 'black',
                    },
                  ]}
                >
                  {i18n.t('done')?.default}
                </Text>
              </TouchableOpacity>
            </View>

            {/* Loading View */}
            <LoadingView
              backgroundColor={this.colorSettings?.splash_bgcolor}
              tintColor={this.colorSettings?.btn_no_fill_border_1}
              textColor={this.colorSettings?.btn_no_fill_text_1}
              visible={this.state.showLoadingView}
              message={i18n.t('please_wait')?.default}
            />

            {/* Date Picker View */}
            {this.state.datePickerVisible && (
              <CustomDatePicker
                colorSettings={this.colorSettings}
                onDatePickerChange={({ selectedDate, dismiss }) =>
                  this.onDatePickerChange({ selectedDate, dismiss })
                }
                date={parseDateOfBirth(dob)}
                visible={this.state.datePickerVisible}
                selectedLanguage={this.props.selectedLanguage}
              />
            )}
            {this.renderBottomSheet()}
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const {
    store,
    accountStore,
    probandStore,
    memberProfileStore,
    diseaseStore,
    clinicianStore,
    customFlowRoutesStore,
  } = state

  return {
    colorSettings: store.colorSettings,
    authCredentials: accountStore.authCredentials,
    probandProfile: probandStore.probandProfile,
    currentProband: probandStore.proband,
    basicFamilyMembers: probandStore.basicFamilyMembers,
    memberProfile: memberProfileStore.memberProfile,
    currentMemberProfile: memberProfileStore.memberProfile,
    currentMember: memberProfileStore.currentMember,
    selectedDiseases: diseaseStore.probandDiseases,
    diseaseFromOnboarding: diseaseStore.fromOnboarding,
    selectedClinician: clinicianStore.selectedClinician,
    shouldReloadParents: memberProfileStore.shouldReloadParents,
    shouldReloadParentsBloodRelation:
      memberProfileStore.shouldReloadParentsBloodRelation,
    shouldReloadTwins: memberProfileStore.shouldReloadTwins,
    shouldReloadInviteStatus: memberProfileStore.shouldReloadInviteStatus,
    shouldReloadDiseases: memberProfileStore.shouldReloadDiseases,
    shouldReloadGeneticTestings: memberProfileStore.shouldReloadGeneticTestings,
    selectedLanguage: store.selectedLanguage,
    twinsList: memberProfileStore.addNewTwinsPayload,
    isCustomWorkflow: customFlowRoutesStore.isCustomWorkflow,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    /** Refactored actions */
    _saveProbandProfileToStore_: (probandProfile) =>
      dispatch(setProbandProfileAction(probandProfile)),
    _setBasicFamilyMembers_: (data) => dispatch(setBasicFamilyMembers(data)),
    _storeMemberProfileAction_: (profile) =>
      dispatch(storeMemberProfileAction(profile)),
    _saveCurrentMemberToStore_: (data) => dispatch(storeCurrentMember(data)),
    _setNewParentsForMember_: (data) => dispatch(setNewParentsForMember(data)),
    _setFromOnboardingDiseases_: (data) =>
      dispatch(setFromOnboardingDiseases(data)),
    _setFromOnboardingGeneTest_: (data) =>
      dispatch(setFromOnboardingGeneTest(data)),
    _resetDiseasesStore_: () => dispatch(resetDiseasesStore()),
    _resetGeneTestingStore_: () => dispatch(resetGeneTestingStore()),
    _saveIndividualDisease_: (data) => dispatch(setIndividualDisease(data)),
    _saveScreenWhereToNavigate_: (data) =>
      dispatch(setScreenWhereToNavigate(data)),
    _saveDiseaseProband_: (data) => dispatch(setDiseaseProband(data)),

    /* Reloader Actions */
    _setReloadersToDefault_: () => dispatch(setReloadersToDefault()),
    _setShouldReloadFamilyList_: (data) =>
      dispatch(setShouldReloadFamilyList(data)),
    _setShouldReloadTwins_: (data) => dispatch(setShouldReloadTwins(data)),
    _setShouldReloadInviteStatus_: (data) =>
      dispatch(setShouldReloadInviteStatus(data)),
    _setShouldReloadDiseases_: (data) =>
      dispatch(setShouldReloadDiseases(data)),
    _setShouldReloadGeneticTestings_: (data) =>
      dispatch(setShouldReloadGeneticTestings(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileView)
