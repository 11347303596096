import { Component } from 'react'
import {
  SafeAreaView,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Platform,
  ScrollView,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'
import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import defaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'
import LoadingView from '$components/LoadingView'

import Account from '$data_models/Account'
import Proband from '$data_models/Proband'

import { convertTextToSnakeCase, isEmptyObject } from '$utils'

import { setSharingSettingMember } from '$redux/member_profile/actions.js'

import global_styles, {
  DefaultFullHeight,
} from '$assets/styles/global.styles.js'
import custom_styles from '$screens/main/dashboard/invite/_styles/main.styles.js'
import { IS_WEB, IS_MOBILE } from '$utils/Platforms.js'
import MainWrapper from '$components/MainWrapper'
import { apiFetchHandler } from '$api'
import {
  showGenericServerError,
  handleDefaultError,
} from '$navigation/_utils/NavigationUtils.js'
import { setError } from '$redux/defaults/actions.js'

const styles = StyleSheet.create({
  headerMessageText: {
    fontFamily: 'montserrat',
    fontSize: 14,
    color: 'rgb(102,102,102)',
  },
})

let listData = []

class SharingSettingsMain extends Component {
  colorSettings = this.props.colorSettings

  proband = this.props.currentProband || new Proband()

  authCredentials = this.props.authCredentials

  constructor(props) {
    super(props)

    // Initialize component state
    this.state = {
      shouldRenderComponent: false,
      showLoadingView: true,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
    setTimeout(async () => {
      this.setState({
        shouldRenderComponent: false,
        showLoadingView: true,
      })

      // API request to get list of Family Members using the app.
      await this.getShareSettingsMemberList()
    }, 500)
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  getShareSettingsMemberList = async () => {
    // Prepare API request to get list of Family Members using the app.
    if (!this.proband.probandID) {
      return
    }

    const shareSettingsMemberListPayload = {
      path: 'invite/share_settings/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband.probandID,
      },
      pageDetails: {
        page: 'SharingSettings.main.js',
      },
    }

    const response = await apiFetchHandler(shareSettingsMemberListPayload)
    const { saveError } = this.props

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    if (response.length > 0) {
      listData = response
    }
    // Update component state
    setTimeout(() => {
      this.setState({
        shouldRenderComponent: true,
        showLoadingView: false,
      })
    }, 300)
  }

  didPressItem = (item, index) => {
    const memberInfo = item

    // Save memberIndo data to redux store
    this.props._setSharingSettingMember_(memberInfo)

    this.props.navigation.navigate('SharingSettingsDetail')
  }

  renderFlatListItem = (item, index) => {
    if (!item || isEmptyObject(item)) {
      return null
    }

    let borderTopLeftRadius = 0
    let borderTopRightRadius = 0
    let borderBottomLeftRadius = 0
    let borderBottomRightRadius = 0
    let borderTopWidth = 1.0

    const member = item
    const memberName = member.name

    let { relationship } = member
    const _relationship_ = convertTextToSnakeCase(relationship)
    relationship = i18n.t(_relationship_).title

    let genderIcon = null

    switch (member.gender.toLowerCase()) {
      case 'm':
        genderIcon = require('$assets/images/icon/male.png')
        break
      case 'f':
        genderIcon = require('$assets/images/icon/female.png')
        break
    }

    if (index === 0) {
      borderTopLeftRadius = 10
      borderTopRightRadius = 10
      borderBottomLeftRadius = 0
      borderBottomRightRadius = 0
      borderTopWidth = 0
    } else if (index === listData.length - 1) {
      borderTopLeftRadius = 0
      borderTopRightRadius = 0
      borderBottomLeftRadius = 10
      borderBottomRightRadius = 10
    }

    return (
      <View
        style={[
          custom_styles.listItemContainer,
          {
            borderTopLeftRadius,
            borderTopRightRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius,
            borderTopWidth,
          },
        ]}
      >
        <TouchableOpacity
          style={custom_styles.listItemContent}
          activeOpacity={1.0}
          onPress={() => this.didPressItem(member, index)}
        >
          <View style={{ backgroundColor: 'rgba(65,70,97,0.1)' }}>
            <Image
              style={{ width: 42, height: 42, resizeMode: 'contain' }}
              source={genderIcon}
            />
          </View>

          <View style={custom_styles.listItemTitleContainer}>
            <View>
              <Text style={custom_styles.listItemTitleText}>{memberName}</Text>
              <Text style={custom_styles.listItemSubtitleText}>
                {relationship}
              </Text>
            </View>
          </View>

          <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
            <Image
              source={require('$assets/images/new_assets/icon-dash-arrow.png')}
              style={{ width: 10, height: 16, resizeMode: 'contain' }}
            />
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    const { navigation } = this.props
    const content = []
    let contentStyle = {}

    if (IS_MOBILE()) {
      contentStyle = custom_styles.content
    }

    if (this.state.shouldRenderComponent) {
      if (listData && listData.length > 0) {
        content.push(
          <View style={[custom_styles.content, { paddingBottom: 16 }]}>
            <View style={custom_styles.headerContainer}>
              <Text style={custom_styles.headerTitleText}>
                {i18n.t('family_members_using_the_app').default}
              </Text>
            </View>

            <View style={custom_styles.listContainer}>
              <FlatList
                data={listData}
                keyExtractor={({ item, index }) => index}
                renderItem={({ item, index }) =>
                  this.renderFlatListItem(item, index)
                }
                showsVerticalScrollIndicator={false}
              />
            </View>
          </View>
        )
      } else {
        content.push(
          <View
            style={[
              contentStyle,
              {
                alignItems: 'center',
                justifyContent: 'center',
                height: this.state.scrollViewHeight,
              },
            ]}
          >
            <Text
              style={[
                custom_styles.headerTitleText,
                {
                  color: 'rgb(74,74,74)',
                  textAlign: 'center',
                  paddingHorizontal: 24,
                },
              ]}
            >
              {
                i18n.t('you_havent_invited_other_people_into_your_family_yet')
                  .default
              }
            </Text>
          </View>
        )
      }
    }

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={custom_styles.container}
            nativeID="web_scaled_main_container"
          >
            <ScrollView
              contentContainerStyle={{ height: this.state.scrollViewHeight }}
            >
              {/* <Content View> */}
              {content}

              {/* <Loading View> */}
              <LoadingView
                visible={this.state.showLoadingView}
                tintColor={this.colorSettings?.post_onboarding_nav_bgcolor}
                textColor={this.colorSettings?.post_onboarding_nav_bgcolor}
              />
            </ScrollView>
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore } = state
  return {
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
    /** Proband Store */
    currentProband: probandStore.proband,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setSharingSettingMember_: (data) =>
      dispatch(setSharingSettingMember(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(SharingSettingsMain)
