import { View, Text, Image } from 'react-native'

import { i18n } from '$localization/config.js'

import Wrapper from './components/Wrapper'

import styles from './styles'

export default function LoadingView({
  message,
  visible = true,
  dimBackground = false,
  useSafeAreaView = true,
  containerStyle = null,
}) {
  const LOADING_VIEW_DEFAULT_MESSAGE = i18n.t('please_wait')?.default
  if (!visible) return null

  return (
    <Wrapper
      useSafeAreaView={useSafeAreaView}
      isDimBackground={dimBackground}
      containerStyle={containerStyle}
    >
      <View style={styles.loaderContainer}>
        <Image
          style={styles.animatedImage}
          source={require('$assets/images/loading.gif')}
        />
        <View style={styles.spacer} />
        <Text style={styles.loaderMessageText}>
          {message ?? LOADING_VIEW_DEFAULT_MESSAGE}
        </Text>
      </View>
    </Wrapper>
  )
}
