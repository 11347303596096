import {
  Image,
  TouchableOpacity,
  TextInput,
  ScrollView,
  Text,
  View,
  SafeAreaView,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'
import { useNavigation } from '@react-navigation/core'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, createRef } from 'react'

import { i18n } from '$localization/config.js'
import { setError } from '$redux/defaults/actions.js'
import NextButtonAccessoryView from '$components/NextButtonAccessoryView'
import LoadingView from '$components/LoadingView'
import MainWrapper from '$components/MainWrapper'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import Alert from '$components/Alert'

import custom_styles from '../_styles/main.styles.js'

import Clinician from '$data_models/Clinician.js'
import { apiFetchHandler } from '$api'

import global_styles, {
  DefaultFullHeight,
} from '$assets/styles/global.styles.js'

const inputAccessoryViewID = 'AddClinicianView'

function AddClinicianCode() {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const clinicianLogoImage = createRef()
  const saveError = (data) => dispatch(setError(data))

  const colorSettings = useSelector((state) => state.store.colorSettings)
  const currentProband = useSelector((state) => state.probandStore.proband)
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )

  const [clinicianCode, setClinicianCode] = useState('')
  const [showLoadingView, setShowLoadingView] = useState(false)
  const [shouldDisplayClinicianLogo, setShouldDisplayClinicianLogo] =
    useState(false)
  const [logoURI, setLogoURI] = useState(null)
  const [shouldEnableSubmit, setShouldEnableSubmit] = useState(false)
  const [scrollViewHeight, setScrollViewHeight] = useState(null)
  const [clinician, setClinician] = useState(new Clinician())

  const validateInput = () => {
    if (clinicianCode && clinicianCode !== '') {
      if (shouldEnableSubmit) return true
    }
    return false
  }

  const toggleNextButtonColor = () =>
    validateInput()
      ? {
          backgroundColor: colorSettings?.bottom_next_btn_enabled || 'white',
          borderColor: colorSettings?.bottom_next_btn_enabled,
        }
      : {
          backgroundColor: colorSettings?.btn_filled_disabled_1 || 'white',
          borderColor: colorSettings?.btn_filled_disabled_1,
        }

  const toggleNextButtonText = () =>
    validateInput()
      ? colorSettings?.bottom_next_btn_text_enabled || 'black'
      : colorSettings?.bottom_next_btn_text_disabled || 'black'

  const updateStateFromClinicianLogo = (logoURIValue, isValid = false) => {
    setShowLoadingView(false)

    if (isValid) {
      setShouldDisplayClinicianLogo(true)
      setLogoURI(logoURIValue)
      setShouldEnableSubmit(true)
    } else {
      setShouldDisplayClinicianLogo(false)
      setLogoURI(null)
      setShouldEnableSubmit(false)
    }
  }

  const handleResponseErrors = (response) => {
    const status = response.status_code ?? response.status

    const wrongClinicianCodeMessage = [
      i18n.t('sorry_the_clinician_code_you_entered_does_not_exist').default,
      i18n.t('please_contact_your_clinician').default,
    ].join(' ')

    switch (status) {
      case 404:
        return showDefaultAlert(
          i18n.t('invalid_clinician_code').default,
          wrongClinicianCodeMessage
        )
      case 203:
        return Alert.alert(
          i18n.t('oops').default,
          i18n.t('you_are_already_connected_to_this_clinician').default
        )
      case 200:
        return Alert.alert(
          i18n.t('connection_request_sent').default,
          i18n.t('your_connection_request_has_been_sent_to_your_clinician')
            .default,
          [
            {
              text: i18n.t('okay').default,
              style: 'default',
              onPress: () => navigation.pop(),
            },
          ]
        )
      default:
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
    }
  }

  const clinicianCheck = async () => {
    const clinicianCodeTrimmed = (clinicianCode || '').trim()

    const clinicianCheckPayload = {
      path: 'clinician/check/',
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        clinician_code: clinicianCodeTrimmed,
      },
      pageDetails: {
        page: 'AddClinicianCode.js',
        line: 145,
      },
    }

    const response = await apiFetchHandler(clinicianCheckPayload)

    let isValid = true

    if (response.isError) {
      setShowLoadingView(false)

      return setTimeout(() => {
        handleResponseErrors(response)
        isValid = false
        updateStateFromClinicianLogo(null, isValid)
      }, 300)
    }

    setClinician(response)

    return updateStateFromClinicianLogo(response.logo, isValid)
  }

  const clinicianConnect = async () => {
    if (!currentProband.probandID) return null
    if (!clinician.id) return null

    const { probandID, familyID } = currentProband

    const clinicianConnectPayload = {
      path: `family/${familyID}/add_clinician/`,
      method: 'post',
      token: authCredentials?.accessToken,
      body: {
        proband_id: probandID,
        clinician_code_id: clinician?.clinician_code.id,
      },
      pageDetails: {
        page: 'AddClinicianCode.js',
        line: 183,
      },
    }

    const response = await apiFetchHandler(clinicianConnectPayload)

    if (response.isError) {
      setShowLoadingView(false)

      handleResponseErrors(response)
    }

    setTimeout(() => {
      handleResponseErrors(response)
    }, 300)

    return setShowLoadingView(false)
  }

  const onEndEditingClinicianCode = async () => {
    if (clinicianCode && clinicianCode !== '') {
      setShowLoadingView(true)

      await clinicianCheck()
    } else {
      setShowLoadingView(false)
      setShouldDisplayClinicianLogo(false)
      setLogoURI(null)
      setShouldEnableSubmit(false)
    }
  }

  const nextButtonAction = async () => {
    try {
      setShowLoadingView(true)
      await clinicianConnect()
    } catch (error) {
      console.log(
        '🚀 \n\n file: AddClinician.js:222 \n\n nextButtonAction \n\n error:',
        error
      )
    }
  }

  const renderClinicianLogo = (show = false) => {
    if (!show) {
      return (
        <View
          style={[
            custom_styles.clinicianLogoContainer,
            {
              backgroundColor: 'transparent',
              marginTop: 16,
            },
          ]}
        />
      )
    }

    return (
      <View
        style={[
          custom_styles.clinicianLogoContainer,
          {
            backgroundColor: '#eee',
            marginTop: 16,
            justifyContent: 'center',
          },
        ]}
      >
        <Image
          ref={clinicianLogoImage}
          style={{ resizeMode: 'contain', height: '100%' }}
          source={{ uri: logoURI }}
          onLoadEnd={() => setShowLoadingView(false)}
        />
      </View>
    )
  }

  useEffect(() => {
    setScrollViewHeight(DefaultFullHeight())
  }, [])

  return (
    <ErrorBoundary>
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={[
            global_styles.container,
            {
              backgroundColor: colorSettings?.onboarding_bgcolor,
            },
          ]}
        >
          <ScrollView
            style={{ height: scrollViewHeight }}
            contentContainerStyle={global_styles.contentContainer}
            nativeID="web_scaled_main_container"
          >
            <View
              style={[
                global_styles.content,
                { justifyContent: 'center', flex: 1 },
              ]}
            >
              <Text
                style={[
                  global_styles.titleText,
                  {
                    color: colorSettings?.text_input_title_color_2,
                    marginTop: 23,
                  },
                ]}
              >
                {
                  i18n.t(
                    'invited_by_a_clinician_enter_the_clinician_code_below'
                  ).default
                }
              </Text>

              <View style={{ marginVertical: 30 }}>
                <TextInput
                  style={[
                    global_styles.mainTextInput,
                    {
                      color: colorSettings?.text_input_color_2 || 'black',
                    },
                  ]}
                  inputAccessoryViewID={inputAccessoryViewID}
                  maxLength={30}
                  placeholder={i18n.t('enter_clinician_code').default}
                  placeholderTextColor={
                    colorSettings?.text_input_placeholder_color_2 ||
                    'rgba(74,74,74,0.5)'
                  }
                  selectionColor={colorSettings?.text_input_color_2}
                  autoCorrect={false}
                  onBlur={() => onEndEditingClinicianCode()}
                  onChangeText={(value) => setClinicianCode(value)}
                  value={clinicianCode}
                />
              </View>

              {renderClinicianLogo(shouldDisplayClinicianLogo)}
            </View>
          </ScrollView>

          <View style={global_styles.nextButtonContainer}>
            <TouchableOpacity
              disabled={!validateInput()}
              style={[global_styles.nextButton, toggleNextButtonColor()]}
              onPress={() => nextButtonAction()}
            >
              <Text
                style={[
                  global_styles.nextButtonText,
                  {
                    color: toggleNextButtonText(),
                  },
                ]}
              >
                {i18n.t('submit').default}
              </Text>
            </TouchableOpacity>
          </View>

          <LoadingView
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            backgroundColor={colorSettings?.splash_bgcolor}
            visible={showLoadingView}
          />

          <NextButtonAccessoryView
            nativeID={inputAccessoryViewID}
            style={[
              global_styles.nextButton,
              {
                backgroundColor:
                  colorSettings?.bottom_next_btn_enabled || 'white',
                borderColor: colorSettings?.bottom_next_btn_enabled,
              },
            ]}
            backgroundColor={colorSettings?.onboarding_bgcolor}
            labelText={i18n.t('done').title}
            labelColor={toggleNextButtonText()}
            onPress={() => console.log()}
          />
        </SafeAreaView>
      </MainWrapper>
    </ErrorBoundary>
  )
}

export default AddClinicianCode
