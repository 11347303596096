/* eslint-disable global-require */
import { Text, TextInput } from 'react-native'
import { FontDisplay } from 'expo-font'

import { i18n, locale } from '$localization/config.js'

import { IS_WEB } from '$utils/Platforms.js'

import { apiFetchHandler } from '$api'

import { APP_SETTINGS_PAYLOAD, REFRESH_INTERVAL } from './constants.js'
import {
  deleteData,
  saveAsyncStorageData,
  getAsyncStorageData,
} from '$utils/SecureStore.js'

import { setShouldReloadFamilyList } from '$redux/proband/actions.js'
import {
  setIsFirstLogin,
  setBottomTabBarTitles,
  setShouldProceedToMain,
  setShouldShowOtherFamilyMembersTutorial,
  setDelayOtherFamilyMembersTutorial,
  setBackgroundRefreshIntervalAll,
  setAppSettings,
  setSplashSettings,
  setColorSettings,
  setLanguage,
  setError,
} from '$redux/defaults/actions.js'
import { injectStyles } from '$utils'

export const setDefaultProps = () => {
  Text.defaultProps = Text.defaultProps ?? {}
  Text.defaultProps.allowFontScaling = false

  TextInput.defaultProps = TextInput.defaultProps ?? {}
  TextInput.defaultProps.allowFontScaling = false

  if (!IS_WEB()) return

  // eslint-disable-next-line global-require

  injectStyles().inject()
}

export const fontsToLoad = {
  montserrat: {
    uri: require('$assets/fonts/Montserrat-Regular.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-black': {
    uri: require('$assets/fonts/Montserrat-Black.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-black-italic': {
    uri: require('$assets/fonts/Montserrat-BlackItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-bold': {
    uri: require('$assets/fonts/Montserrat-Bold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-bold-italic': {
    uri: require('$assets/fonts/Montserrat-BlackItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-bold': {
    uri: require('$assets/fonts/Montserrat-ExtraBold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-bold-italic': {
    uri: require('$assets/fonts/Montserrat-ExtraBoldItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-light': {
    uri: require('$assets/fonts/Montserrat-ExtraLight.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-extra-light-italic': {
    uri: require('$assets/fonts/Montserrat-ExtraLightItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-italic': {
    uri: require('$assets/fonts/Montserrat-Italic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-light': {
    uri: require('$assets/fonts/Montserrat-Light.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-light-italic': {
    uri: require('$assets/fonts/Montserrat-LightItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-medium': {
    uri: require('$assets/fonts/Montserrat-Medium.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-medium-italic': {
    uri: require('$assets/fonts/Montserrat-MediumItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-semibold': {
    uri: require('$assets/fonts/Montserrat-SemiBold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-semibold-italic': {
    uri: require('$assets/fonts/Montserrat-SemiBoldItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-thin': {
    uri: require('$assets/fonts/Montserrat-Thin.ttf'),
    display: FontDisplay.BLOCK,
  },
  'montserrat-thin-italic': {
    uri: require('$assets/fonts/Montserrat-ThinItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
  karla: {
    uri: require('$assets/fonts/Karla-Regular.ttf'),
    display: FontDisplay.BLOCK,
  },
  'karla-bold': {
    uri: require('$assets/fonts/Karla-Bold.ttf'),
    display: FontDisplay.BLOCK,
  },
  'karla-italic': {
    uri: require('$assets/fonts/Karla-Italic.ttf'),
    display: FontDisplay.BLOCK,
  },
  'karla-bold-italic': {
    uri: require('$assets/fonts/Karla-BoldItalic.ttf'),
    display: FontDisplay.BLOCK,
  },
}

export const setInitialLanguage = (dispatch) => {
  try {
    const currentLanguage = `${locale}`.split('-')[0]
    const currentRegion = `${locale}`.split('-')[1]

    i18n.locale = `${currentLanguage}-${currentRegion}`

    dispatch(setLanguage(currentLanguage))
  } catch (e) {
    const currentLanguage = 'en'
    const currentRegion = 'US'

    i18n.locale = `${currentLanguage}-${currentRegion}`

    dispatch(setLanguage(currentLanguage))
  }
}

export function logAppSettingsError() {
  console.groupCollapsed('%cApp Delegate Logs (error):', 'color: red')
  console.log('Error: API is down.')
  console.log('Error: Failed to load App settings.')
  console.groupEnd()
}

const dispatchAppSettings = (dispatch, appSettings, splash, color) => {
  dispatch(setAppSettings(appSettings))
  dispatch(setSplashSettings(splash))
  dispatch(setColorSettings(color))
}

export async function fetchAppSettings(dispatch) {
  const saveFetchAppSettingsError = (data) => dispatch(setError(data))

  saveFetchAppSettingsError({
    isShown: false,
    status: '',
    message: '',
  })

  try {
    const response = await apiFetchHandler(APP_SETTINGS_PAYLOAD)

    if (response.isError) {
      logAppSettingsError()

      saveFetchAppSettingsError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
    }

    const { app_setting: appSettings, splash, color } = response

    dispatchAppSettings(dispatch, appSettings, splash, color)
  } catch (error) {
    console.error('Failed to fetch and dispatch app settings:', error)
  }
}

export async function clearLoginCredentials() {
  try {
    const isFirstLogin = await getAsyncStorageData({ key: 'isFirstLogin' })

    if (isFirstLogin !== null) return

    await Promise.all([
      deleteData({ key: 'email' }),
      deleteData({ key: 'password' }),
      deleteData({ key: 'enabledSecurity' }),
      deleteData({ key: 'hasUserAcceptedBiometric' }),
      saveAsyncStorageData({ key: 'isFirstLogin', value: 'false' }),
    ])
  } catch (error) {
    console.error('Failed to clear login credentials:', error)
  }
}

export function setInitialReduxStateValues(dispatch) {
  dispatch(setIsFirstLogin(false))

  dispatch(setShouldShowOtherFamilyMembersTutorial(false))
  dispatch(setDelayOtherFamilyMembersTutorial(false))

  dispatch(setBottomTabBarTitles())

  dispatch(setShouldReloadFamilyList(false))

  dispatch(setShouldProceedToMain(false))

  dispatch(setBackgroundRefreshIntervalAll(REFRESH_INTERVAL))
}
