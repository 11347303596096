import moment from 'moment'

import { isEmptyObject, yearIntervalFromNow, parseDateOfBirth } from '$utils'

import { dateFormats } from '$constants'

export default class MemberProfile {
  accountID = ''

  memberID = ''

  inviteStatus = null

  isPregnancy = false

  profileData = {
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    currentAge: '',
    deceased: false,
    ageOfDeath: '',
    causeOfDeath: '',
    biologicalGender: '',
    genderIdentity: '',
    pronoun: '',
    ethnicity: '',
    ashkenazi: '',
  }

  healthHistoryData = {
    diseasesData: {
      diagnosedWithDisease: false,
      diseases: [],
    },
    geneTestsData: {
      geneTested: false,
      testings: [],
    },
  }

  relationshipData = {
    mother: null,
    father: null,
    isAdopted: false,
    isTwin: false,
    twinGroup: null,
    parentSpecific: {
      bloodRelatedParents: false,
      relationshipInfo: null,
    },
    childSpecific: {
      isAdoptedOut: false,
      isChildOfRelative: false,
    },
  }

  getFullName = (data) => {
    const firstName = data.first_name ?? ''
    const lastName = data.last_name ?? ''
    const _fullName = []

    if (firstName && firstName !== '') {
      _fullName.push(firstName)
    }

    if (lastName && lastName !== '') {
      _fullName.push(lastName)
    }

    return _fullName.join(' ')
  }

  constructBasicProfile = (data) => {
    const fullName = this.getFullName(data)

    return {
      member_id: data.member_id ?? null,
      name: data.name ?? fullName ?? '',
      first_name: data.first_name ?? '',
      last_name: data.last_name ?? '',
      age: data.age ?? null,
      age_string: data?.age_string ?? null,
      gender: data.gender ?? null,
      gender_identity: data?.gender_identity ?? null,
      is_dead: data.is_dead ?? false,
      diseases: data.diseases ?? [],
      genes: data.genes ?? [],
      member_avatar: data?.member_avatar ?? null,
      is_deleted: data.is_deleted ?? false,
      newly_added: data.newly_added ?? false,
      relationship_to_proband: data.relationship_to_proband ?? null,
      allow_family_invite: data.allow_family_invite ?? 'ENABLED',
    }
  }

  constructBasicMemberData = (data, partnerMode = false) => {
    const memberData = {}
    const relationshipToProband = data.relationship_to_proband ?? null
    const isBloodRelatedToProband = data.is_blood_related_to_proband ?? null

    memberData.member = this.constructBasicProfile(data)

    if (partnerMode) {
      memberData.children = []
    } else {
      memberData.partners = []
    }

    if (relationshipToProband && relationshipToProband !== '') {
      memberData.relationship = relationshipToProband
    }

    if (isBloodRelatedToProband && isBloodRelatedToProband !== '') {
      memberData.is_blood_related_to_proband = isBloodRelatedToProband
    }

    if (data.children?.length > 0) memberData.children = data.children
    if (data.partners?.length > 0) memberData.partners = data.partners

    return memberData
  }

  createPayloadForAddMember = (probandID, memberID, params = {}) =>
    /* Payload format:
    const payload = {
      proband_id: <Number>,
      member_id: <Number>,
      gender: <String>,
      pregnancy: <Bool>,
      relationship_to_proband: "String",
      side: <String or null>,
    }
    */
    ({
      proband_id: probandID ?? null,
      member_id: memberID ?? null,
      gender: params?.gender ?? null,
      pregnancy: params?.pregnancy ?? false,
      relationship_to_proband: params?.relationship_to_proband ?? null,
      side: params?.side ?? null,
    })

  createPayloadForUpdate = (probandID) => {
    const memberProfile = this

    if (!probandID || probandID === '') {
      console.log(
        '🚀 \n\n file: MemberProfile.js:145 \n\n createPayloadForUpdate \n\n Proband data error'
      )

      return null
    }

    if (!memberProfile?.memberID || memberProfile?.memberID === '') {
      console.log(
        '🚀 \n\n file: MemberProfile.js:145 \n\n createPayloadForUpdate \n\n MemberProfile data error'
      )
      return null
    }

    const { profileData, relationshipData } = memberProfile
    // Get current age if still living.
    let age = profileData?.currentAge

    // Date of birth should always be a string, not null.
    let dob = ''
    if (profileData?.dob) {
      // Parse and set date format to be sent to API.
      // Auto correct currentAge based on DOB.
      dob = moment(parseDateOfBirth(profileData?.dob)).format(
        dateFormats.DEFAULT_DATE_FORMAT_API
      )
      age = `${yearIntervalFromNow(dob)}`
    }

    // Use ageOfDeath if deceased.
    let causeOfDeath = ''
    if (profileData?.deceased === true) {
      age = `${profileData?.ageOfDeath}`
      causeOfDeath = profileData?.causeOfDeath || ''
    }

    // Set currentAge or ageOfDeath to null instead of blank or 0
    if (`${age}` === '' || `${age}` === '0') {
      age = null
    }

    // Payload for API request
    const payload = {
      proband_id: probandID,
      member_id: memberProfile.memberID,
      member_info: {
        first_name: profileData?.firstName,
        last_name: profileData?.lastName,
        email: profileData?.email,
        is_dead: profileData?.deceased,
        age_string: profileData?.currentAgeString,
        age,
        dob,
        cause_of_death: causeOfDeath,
        pronoun: profileData?.pronoun,
        gender_identity: profileData?.genderIdentity ?? null,
      },
    }

    const parentSpecific = relationshipData?.parentSpecific
    payload.is_parent_blood_related = parentSpecific?.bloodRelatedParents

    // If parents are blood related
    if (!isEmptyObject(parentSpecific?.relationshipInfo)) {
      const relationshipInfo = parentSpecific?.relationshipInfo
      const bloodRelationType = `${relationshipInfo?.key}`.toLowerCase()
      payload.blood_relation_type = bloodRelationType
      // payload["is_parent_blood_related"] = true
    } else {
      // payload["is_parent_blood_related"] = false
    }

    // If adopted-in or adopted-out
    if (relationshipData?.isAdopted) {
      const childSpecific = relationshipData?.childSpecific
      if (!isEmptyObject(childSpecific)) {
        payload.member_info.adopted_in = !childSpecific?.isAdoptedOut
        payload.member_info.adopted_out = childSpecific?.isAdoptedOut
        payload.member_info.adopted_blood_related =
          childSpecific?.isChildOfRelative
      }
    } else {
      payload.member_info.adopted_in = false
      payload.member_info.adopted_out = false
      payload.member_info.adopted_blood_related = false
    }

    return payload
  }
}
