import { store } from '$redux/configureStore'
import {
  setError,
  setLastLoginScreen,
  setNavigationState,
  setShowLoadingView,
} from '$redux/defaults/actions'
import { setSelectedClinicianAction } from '$redux/clinician/actions'
import {
  setProbandAction,
  setProbandClinicianCode,
  setProbandImmediateFamily,
  setProbandProfileAction,
} from '$redux/proband/actions'
import {
  setAccountAction,
  setAccountId,
  setAuthCredentialsAction,
  setUserId,
} from '$redux/account/actions'
import { setIsGeneticallyTested } from '$redux/gene_testing/actions'

const dispatchHelpers = () => {
  const saveError = (response) =>
    store.dispatch(
      setError({
        isShown: true,
        status: response?.status,
        message: response?.error,
      })
    )

  const saveClinicianCode = (data) =>
    store.dispatch(setSelectedClinicianAction(data))

  const saveProbandImmediateFamily = (data) =>
    store.dispatch(setProbandImmediateFamily(data))

  const saveAuthCredentialsAction = (data) =>
    store.dispatch(setAuthCredentialsAction(data))

  const saveUserId = (data) => store.dispatch(setUserId(data))

  const saveProbandToStore = (data) => store.dispatch(setProbandAction(data))

  const saveProbandProfileToStore = (data) =>
    store.dispatch(setProbandProfileAction(data))

  const saveAccountId = (id) => store.dispatch(setAccountId(id))

  const saveIsGeneticallyTested = (isGeneticallyTested) =>
    store.dispatch(setIsGeneticallyTested(isGeneticallyTested))

  const saveProbandToClinicianCode = (data) =>
    store.dispatch(setProbandClinicianCode(data))

  const saveNavigationState = (data) => store.dispatch(setNavigationState(data))

  const saveAccountData = (data) => store.dispatch(setAccountAction(data))

  const saveLastLoginScreen = (screen) =>
    store.dispatch(setLastLoginScreen(screen))

  const showLoadingView = (data) => store.dispatch(setShowLoadingView(data))

  return {
    saveError,
    saveClinicianCode,
    saveProbandImmediateFamily,
    saveAuthCredentialsAction,
    saveUserId,
    saveProbandToStore,
    saveProbandProfileToStore,
    saveAccountId,
    saveIsGeneticallyTested,
    saveProbandToClinicianCode,
    saveNavigationState,
    saveAccountData,
    saveLastLoginScreen,
    showLoadingView,
  }
}

const reduxStateHelpers = () => {
  const { appSettings = null, inviteID = null } = store.getState().store
  const { account = null } = store.getState().accountStore

  return {
    appSettings,
    userUUID: inviteID,
    patientData: account,
  }
}

export { dispatchHelpers, reduxStateHelpers }
