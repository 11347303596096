/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { useCallback, useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import uuid from 'react-native-uuid'

import styles from './styles'
import { i18n } from '$localization/config.js'
import CustomizableButton from '$components/CustomizableButton'
import {
  decreaseFontSize,
  checkMissingTranslation,
} from '$screens/CustomScreen/helpers'

import ComponentGenerator from '../../ComponentGenerator'
import FieldViewer from '../../FieldViewer'

import LabelText from '../LabelText'

const DEFAULTS = {
  BACKGROUND_COLOR: 'rgba(0,0,0,0.2)',
  ANSWER: null,
}

export default function MultiSelectField({
  masterQuestion,
  colorSettings = {},
  answers,
  storeFieldSurveyAnswers = () => {},
  surveyData,
}) {
  const {
    question_label: questionLabel,
    master_question_choices: masterQuestionChoices,
  } = masterQuestion

  const [selectedItem, setSelectedValues] = useState([])
  const [currentAnswers, setCurrentAnswers] = useState([])

  const storeSelectedValues = (answerList) => {
    answerList.forEach((answerItem) => {
      const payload = {
        field_type: 'select_all_that_apply',
        master_question_id: masterQuestion.id,
        master_question_choice_id: answerItem.id,
        answer: DEFAULTS.ANSWER,
        shouldDeleteExistingItem: false,
        shouldSubmitAnswer: false,
      }

      storeFieldSurveyAnswers(payload)
    })
  }

  const handleSelectedValues = (answerList) => {
    const modifyAnswerList = answerList.map((answerItem) => ({
      ...answerItem,
      shouldSubmitAnswer: false,
    }))

    setSelectedValues(modifyAnswerList)
    setCurrentAnswers(modifyAnswerList)
  }

  useEffect(() => {
    const commonValues = masterQuestionChoices.filter((choice) =>
      answers.some((answer) => answer.master_question_choice_id === choice.id)
    )
    storeSelectedValues(commonValues)
    handleSelectedValues(commonValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkItemIfFound = (item, answerItem) => {
    const found = answerItem.some((selectItem) => selectItem?.id === item?.id)
    return found
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const styleHandler = useCallback((item) => {
    const selectedItemExist = checkItemIfFound(item, selectedItem)
    const defaultBackgroundColor =
      colorSettings?.selectable_btn_active_1 || DEFAULTS.BACKGROUND_COLOR

    if (selectedItemExist) {
      return {
        backgroundColor: defaultBackgroundColor,
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  })

  const handleSelectedItem = (item) => {
    const selectedItemExist = checkItemIfFound(item, selectedItem)
    const currentAnswerExist = checkItemIfFound(item, currentAnswers)

    let shouldDeleteExistingItem
    let shouldSubmitAnswer

    if (currentAnswerExist && selectedItemExist) {
      shouldDeleteExistingItem = true
      shouldSubmitAnswer = false
    } else {
      shouldDeleteExistingItem = false
      shouldSubmitAnswer = !currentAnswerExist && !selectedItemExist
    }

    let newSelectedValues

    if (selectedItemExist) {
      newSelectedValues = selectedItem.filter((value) => value.id !== item.id)
    } else {
      newSelectedValues = [...selectedItem, item]
    }

    setSelectedValues(newSelectedValues)

    const payload = {
      field_type: 'select_all_that_apply',
      master_question_id: masterQuestion.id,
      master_question_choice_id: item.id,
      answer: DEFAULTS.ANSWER,
      shouldDeleteExistingItem,
      shouldSubmitAnswer,
    }

    storeFieldSurveyAnswers(payload)
  }

  const translatedLabel = i18n.t(questionLabel).default || questionLabel

  const title = LabelText({
    questionLabel: translatedLabel,
    colorSettings,
    masterQuestion,
  })

  return (
    <View style={styles.container}>
      <FieldViewer fieldName="multi select field" />
      {title}

      {masterQuestionChoices.map((item) => {
        const translationKey = `${item.translation_key}.choice`
        let choiceLabelTranslation = i18n.t(translationKey)

        const checkTranslation = checkMissingTranslation({
          message: choiceLabelTranslation,
          fallback: item.choice,
        })

        const isChoiceLabelOrMissingTranslation =
          choiceLabelTranslation === translationKey ||
          checkTranslation.isMissing

        if (isChoiceLabelOrMissingTranslation) {
          choiceLabelTranslation = item.choice
        }

        return (
          <CustomizableButton
            key={uuid.v4()}
            colorSettings={colorSettings}
            onPress={() => handleSelectedItem(item)}
            styleHandler={() => styleHandler(item)}
            fontSizeHandler={() => decreaseFontSize(choiceLabelTranslation)}
            title={choiceLabelTranslation}
          />
        )
      })}
      <ComponentGenerator
        childQuestionnaire={masterQuestion.child_questionnaire}
        surveyObject={surveyData}
      />
    </View>
  )
}

// ** typescript reference **

// MultiSelectField.propTypes = {
//   masterQuestion: PropTypes.isRequired,
//   colorSettings: PropTypes.shape({
//     text_input_title_color_2: PropTypes.string,
//     selectable_btn_active_1: PropTypes.string,
//     selectable_btn_border_active_1: PropTypes.string,
//     selectable_btn_border_inactive_1: PropTypes.string,
//   }),
//   answers: PropTypes.isRequired,
//   storeFieldSurveyAnswers: PropTypes.func,
// }

// MultiSelectField.defaultProps = {
//   colorSettings: {
//     selectable_btn_active_1: '',
//     selectable_btn_border_active_1: '',
//   },
//   storeFieldSurveyAnswers: () => {},
// }
