import { StyleSheet, Platform } from 'react-native'

const white = '#fff'
const lightShadowWhite = 'rgb(245,245,245)'
const shadowWhite = 'rgb(187,187,187)'
const grey = 'rgb(65,70,97)'
const darkGray = 'rgb(74,74,74)'
const lightGray = 'rgb(102,102,102)'

const styles = StyleSheet.create({
  container: {
    backgroundColor: lightShadowWhite,
    width: '100%',
    flex: 1,
  },
  content: {
    paddingHorizontal: 16,
    flex: 1,
  },

  contentCentered: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },

  headerContainer: {
    height: 60,
    justifyContent: 'center',
  },
  headerTitleText: {
    fontFamily: 'karla-bold',
    fontSize: 14,
    color: grey,
  },

  listContainer: {
    shadowOpacity: 0.5,
    shadowColor: shadowWhite,
    shadowOffset: { width: 0, height: 2 },
    flex: 1,
  },
  listItemContainer: {
    backgroundColor: white,
    marginVertical: 5,
    marginHorizontal: 0,
    borderRadius: 10,
    shadowOpacity: 0.5,
    shadowColor: shadowWhite,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 2,
  },
  listItemContent: {
    paddingVertical: 16,
    marginHorizontal: 16,
  },

  listItemContentHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemContentHeaderTitle: {
    color: darkGray,
    fontFamily: 'karla-bold',
    fontSize: 13,
    textAlign: 'left',
  },

  listItemContentBody: {},
  listItemContentTitle: {
    color: darkGray,
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'left',
  },
  listItemContentText: {
    color: lightGray,
    fontFamily: 'montserrat',
    fontSize: 12,
    textAlign: 'left',
  },
  listItemDateTimeText: {
    color: darkGray,
    fontFamily: 'karla',
    fontSize: 11,
    textAlign: 'left',
  },

  listItemContentFooter: {
    justifyContent: 'center',
  },

  listItemTitleContainer: {
    justifyContent: 'center',
    paddingHorizontal: 16,
    flex: 1,
  },
  listItemTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    color: darkGray,
  },
  listItemSubtitleText: {
    fontFamily: 'montserrat',
    fontSize: 11,
    color: lightGray,
  },

  listItemButtonContainer: {
    width: 75,
    height: 30,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: 'rgb(219,219,219)',
  },
  listItemButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemButtonTitle: {
    fontFamily: 'montserrat-medium',
    fontSize: 10,
  },
  notificationItemContent: {
    ...Platform.select({
      web: {
        maxWidth: 800,
        width: '100%',
        alignSelf: 'center',
      },
    }),
    marginTop: 20,
  },
  controlsContainer: {
    borderColor: '#ccc',
    borderTopWidth: 1,
    height: 60,
  },
  stackContainerHorizontal: {
    height: 50,
    flexDirection: 'row',
    flex: 1,
  },
  controlButtonContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlButton: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  controlButtonText: {
    color: 'rgb(153,153,153)',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },
  controlButtonSeparator: {
    backgroundColor: '#ccc',
    height: '100%',
    width: 1,
  },
  notifDismissButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 45,
  },
  notifDismissButtonText: {
    color: 'rgba(117,84,136,1.0)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
  },
  scrollViewRenderListContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollViewRenderListContainer2: { paddingVertical: 20 },
  listDataHeaderContainer: {
    color: darkGray,
    textAlign: 'center',
    paddingHorizontal: 24,
  },
  learnMoreButton: {
    height: 30,
    width: '100%',
    justifyContent: 'flex-end',
  },
  learnMoreButtonText: {
    color: 'rgb(155,89,182)',
    fontFamily: 'montserrat',
    fontSize: 13,
    textAlign: 'left',
  },
})

export default styles
