import { apiFetchHandler } from '$api'

const saveLastScreen = async ({
  account,
  authCredentials,
  item,
  saveError,
  navigation,
}) => {
  const navigationState = navigation?.getState()

  const screenData = {
    account_id: account?.accountID,
    navigation_state: JSON.stringify(navigationState) ?? null,
    ...item,
  }

  const saveOnboardingDetailsPayload = {
    path: 'onboarding/update_info/',
    method: 'post',
    token: authCredentials?.accessToken,
    body: {
      ...screenData,
    },
    pageDetails: {
      page: 'SaveLastScreen.js',
      line: '18',
    },
  }

  const response = await apiFetchHandler(saveOnboardingDetailsPayload)

  if (response?.isError) {
    saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })
  }
}

export default saveLastScreen
