import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'
import ButtonToggle from '$components/ButtonToggle'
import styles from './styles'

function MainQuestion({ handleMainQuestion, mainQuestionValue }) {
  const TITLE = i18n.t(
    'have_you_ever_had_genetic_testing_for_hereditary_cancer'
  ).default

  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <ButtonToggle
      title={TITLE}
      colorSettings={colorSettings}
      onPress={(value) => handleMainQuestion(value)}
      setContainerStyle={styles.container}
      isSelected={mainQuestionValue}
    />
  )
}

export default MainQuestion
