import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { setError, setShowLoadingView } from '$redux/defaults/actions.js'

const selectStore = (state) => state.store
const selectProbandStore = (state) => state.probandStore
const selectAccountStore = (state) => state.accountStore
const selectSurveyStore = (state) => state.surveyStore
const selectCustomFlowRoutesStore = (state) => state.customFlowRoutesStore

export const reduxDataSelector = createSelector(
  selectStore,
  selectProbandStore,
  selectAccountStore,
  selectSurveyStore,
  selectCustomFlowRoutesStore,
  (store, probandStore, accountStore, surveyStore, customFlowRoutesStore) => ({
    isOnboarding: store?.isOnboarding,
    colorSettings: store.colorSettings,
    proband: probandStore?.proband,
    account: accountStore?.account,
    authCredentials: accountStore?.authCredentials,
    selectedSurvey: surveyStore?.selectedSurvey,
    selectedScreens: customFlowRoutesStore?.selectedScreens,
    isLoading: store?.showLoadingView,
  })
)

export const useReduxData = () => {
  const reduxData = useSelector(reduxDataSelector)
  return reduxData
}

export const useReduxActions = () => {
  const dispatch = useDispatch()

  return {
    saveError: (data) => dispatch(setError(data)),
    setIsLoading: (data) => dispatch(setShowLoadingView(data)),
  }
}
