import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    borderColor: colorSettings?.text_input_placeholder_color_2,
  }),
  textInputColor: (colorSettings) => ({
    color: colorSettings?.text_input_color_2 || 'rgba(0,0,0,1)',
  }),
})

export default styles
