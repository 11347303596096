import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import styles from './styles'

export default function ButtonDone({
  colorSettings,
  title,
  onPress,
  disable = false,
}) {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        disabled={disable}
        style={styles.nextButton(disable, colorSettings)}
        onPress={() => onPress()}
      >
        <Text style={styles.nextButtonText(colorSettings)}>{title}</Text>
      </TouchableOpacity>
    </View>
  )
}
