import moment from 'moment'

import { DOMAIN_CLIENT } from '$utils/globalVariables.js'
import { emailToLowerCase, yearIntervalFromNow, getLanguage } from '$utils'
import { apiFetchHandler } from '$api'

import { dateFormats } from '$constants'

const fetchSendInvitedMember = async (formData, currentMember) => {
  const payloadFormValues = {
    first_name: formData.firstName,
    last_name: formData.lastName,
    email: emailToLowerCase(formData.email),
    password: formData.password,
    dob: moment(formData.dateOfBirthdate).format(
      dateFormats.DEFAULT_DATE_FORMAT_API
    ),
    age: yearIntervalFromNow(formData.dateOfBirthdate) ?? 0,
  }

  const payload = {
    domain_client: `${DOMAIN_CLIENT}`?.toUpperCase(),
    lang: getLanguage(),
    ...currentMember,
    ...payloadFormValues,
  }

  const familyInvitePayload = {
    path: 'invite/account/',
    method: 'post',
    body: {
      ...payload,
    },
    pageDetails: {
      page: 'SignupScreen.main.js',
    },
  }

  return apiFetchHandler(familyInvitePayload)
}

const validateDobAction = async (dateOfBirthdate, inviteID) => {
  const formattedDateOfBirthdate = moment(dateOfBirthdate).format('YYYY-MM-DD')

  const validateDobPayload = {
    inviteID,
    dob: formattedDateOfBirthdate,
  }

  const validateDOB = {
    path: 'account/verify_patient_invite/',
    method: 'post',
    body: {
      ...validateDobPayload,
    },
    pageDetails: {
      page: 'SignupScreen.main.js',
    },
  }

  return apiFetchHandler(validateDOB)
}

export { fetchSendInvitedMember, validateDobAction }
