// ** Main Imports
import React, { useEffect, useRef, useState } from 'react'
import { SafeAreaView } from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

// ** Utils
import { getLanguage, isEmptyObject } from '$utils'
import { envConstants } from '$constants'
import { DOMAIN_CLIENT } from '$utils/globalVariables.js'
import { IS_WEB } from '$utils/Platforms.js'

import { calculateAvailableContentHeight } from '$assets/styles/global.styles.js'
import { NAVIGATORS } from '$navigation/constants/routes'

// ** Helpers
import {
  resetBackgroundRefreshTimer,
  processSurveyItem,
  fetchFamilyClinician,
  fetchFamilySurvey,
  clinicianCheck,
} from './helpers'
import { i18n } from '$localization/config.js'
// ** Custom Hooks
import { useReduxData, useReduxActions } from './hooks'

// ** Styles
import styles from './styles.js'

// ** Components
import MainWrapper from '$components/MainWrapper'
import { RenderSurveyList } from './components'

// ** Constants
import { RESIZE, CUSTOMER_DOMAIN, EMPTY_LENGTH, FOCUS } from './constants'

export default function SurveyCompleted({ navigation }) {
  const reduxActions = useReduxActions()
  const reduxStore = useReduxData()
  const sectionListData = useRef([])

  const [surveyList, setSurveyList] = useState([])
  const [scrollViewHeight, setScrollViewHeight] = useState(
    calculateAvailableContentHeight()
  )

  const OPTIONAL_SURVEY_HEADER_TEXT = i18n.t('optional_surveys')?.default
  const OPTIONAL_SURVEY_SUBHEADER_TEXT = i18n.t(
    'not_shared_with_your_clinician'
  )?.default

  useEffect(() => {
    let handleResize

    if (IS_WEB()) {
      const handleResize = () =>
        setScrollViewHeight(calculateAvailableContentHeight())

      window.addEventListener(RESIZE, handleResize)
    }

    const willFocusSubscription = navigation.addListener(FOCUS, async () => {
      await getFamilyClinicians()
    })

    return () => {
      if (handleResize) {
        window.removeEventListener(RESIZE, handleResize)
      }

      willFocusSubscription()
    }
  }, [])

  const handleFamilyCliniciansResponse = (response) => {
    const { saveError, showLoadingView, setClinicians } = reduxActions

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      showLoadingView(false)

      return false
    }

    if (response.length > EMPTY_LENGTH) {
      setClinicians(response)

      const newData = {
        title: 'Clinicians',
        data: response,
      }

      sectionListData.current.push(newData)
    }

    return true
  }

  const getFamilyClinicians = async () => {
    const { proband, authCredentials } = reduxStore
    const familyID = proband?.familyID

    if (!familyID) return

    sectionListData.current = []

    reduxActions.showLoadingView(true)

    const response = await fetchFamilyClinician(
      familyID,
      authCredentials,
      'SurveyScreen'
    )

    if (handleFamilyCliniciansResponse(response)) {
      await getByClinicianSurveys(sectionListData.current.length !== 0)
    }
  }

  const processSurveyData = (clinicianSurvey) =>
    clinicianSurvey.surveys.map((survey) =>
      processSurveyItem(survey, clinicianSurvey?.clinician_code)
    )

  const processClinicianSurveys = (clinicianSurveys) =>
    clinicianSurveys.map((clinicianSurvey) => ({
      clinician_header: clinicianSurvey.header,
      clinician_list: processSurveyData(clinicianSurvey),
    }))

  const processOptionalSurveys = (optionalSurveys, connected) => {
    const surveyData = optionalSurveys.map(processSurveyItem)

    if (surveyData.length === EMPTY_LENGTH) {
      return null
    }

    const tempOptionalList = {
      clinician_list: surveyData,
    }

    if (connected) {
      tempOptionalList.clinician_header = OPTIONAL_SURVEY_HEADER_TEXT
      tempOptionalList.clinician_subheader = OPTIONAL_SURVEY_SUBHEADER_TEXT
    }

    return tempOptionalList
  }

  const saveSurveyDataToStore = (
    response,
    clinicianSurveys,
    optionalSurveys
  ) => {
    const { setSurveys, setClinicianSurveys, setOptionalSurveys } = reduxActions

    if (!response || response.length === EMPTY_LENGTH) return

    setSurveys(response)
    setClinicianSurveys(clinicianSurveys)
    setOptionalSurveys(optionalSurveys)
  }

  const processSurveys = (clinicianSurveys, optionalSurveys, connected) => {
    const surveyPerClinicianList = processClinicianSurveys(clinicianSurveys)

    if (DOMAIN_CLIENT === CUSTOMER_DOMAIN) return surveyPerClinicianList

    const optionalList = processOptionalSurveys(optionalSurveys, connected)

    if (optionalList) {
      surveyPerClinicianList.push(optionalList)
    }

    return surveyPerClinicianList
  }

  const getByClinicianSurveys = async (isConnected = false) => {
    const { showLoadingView } = reduxActions

    showLoadingView(true)

    const response = await fetchFamilySurvey(reduxStore.authCredentials)

    if (response.isError) {
      handleError(response)

      return null
    }

    if (!response) return

    const clinicianSurveys = response?.clinician_surveys
    const optionalSurveys = response?.optional_surveys

    saveSurveyDataToStore(response, clinicianSurveys, optionalSurveys)

    const surveyPerClinicianList = processSurveys(
      clinicianSurveys,
      optionalSurveys,
      isConnected
    )

    setSurveyList(surveyPerClinicianList)
    showLoadingView(false)
  }

  const setSurveyItemAndNavigate = ({
    surveyItem,
    accessToken,
    surveyID,
    probandID,
    clinicianCodeId,
  }) => {
    const customURL = `${
      envConstants.CUSTOM_SURVEY_URL
    }/${accessToken}/${surveyID}/${probandID}/${
      clinicianCodeId || ''
    }/${getLanguage()}`

    surveyItem.customURL = customURL
    surveyItem.accessToken = accessToken

    reduxActions.showLoadingView(false)
    navigateToSurvey(surveyItem)
  }

  const handleError = (response) => {
    const { showLoadingView, saveError } = reduxActions

    showLoadingView(false)

    saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })
  }

  const fetchClinicianCodeId = async (accessToken, clinicianCode) => {
    reduxActions.showLoadingView(true)

    const response = await clinicianCheck(accessToken, clinicianCode)

    if (response.isError) {
      handleError(response)

      return null
    }

    return response?.clinician_code?.id
  }

  const navigateWithSurveyItem = async ({
    accessToken,
    surveyItem,
    probandID,
    clinicianCode,
  }) => {
    const clinicianCodeId = clinicianCode
      ? await fetchClinicianCodeId(accessToken, clinicianCode)
      : undefined

    if (clinicianCode && !clinicianCodeId) return

    setSurveyItemAndNavigate({
      surveyItem,
      accessToken,
      surveyID: surveyItem?.surveyID,
      probandID,
      clinicianCodeId,
    })
  }

  const setSurveyUrl = async (item = {}) => {
    const { proband, authCredentials } = reduxStore

    if (!proband || isEmptyObject(proband)) return

    reduxActions.showLoadingView(true)

    const accessToken = authCredentials?.accessToken
    const probandID = proband?.probandID
    const surveyItem = JSON.parse(JSON.stringify(item))

    await navigateWithSurveyItem({
      accessToken,
      surveyItem,
      probandID,
      clinicianCode: surveyItem?.clinician_code,
    })
  }

  const navigateToSurvey = (surveyItem) => {
    reduxActions.setSelectedSurvey(surveyItem)

    navigation.navigate(NAVIGATORS.CustomScreen.name)
  }

  const navigateToScreen = (route, data, isCustomSurvey) => {
    const surveyItem = data

    reduxActions.setSelectedSurvey(surveyItem)

    if (isCustomSurvey) {
      setSurveyUrl(surveyItem)
    } else {
      navigation.navigate(route)
    }
  }

  return (
    <ErrorBoundary>
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={styles.containerWhite(
            reduxStore.colorSettings,
            scrollViewHeight
          )}
        >
          <RenderSurveyList
            surveyList={surveyList}
            colorSettings={reduxStore.colorSettings}
            navigateToScreen={navigateToScreen}
          />
        </SafeAreaView>
      </MainWrapper>
    </ErrorBoundary>
  )
}
