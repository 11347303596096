import * as actionTypes from './actionTypes'

const setAuthCredentialsAction = (data) => ({
  type: actionTypes.SET_AUTH_CREDENTIALS,
  data,
})

const setIsLogoutAction = (data) => ({
  type: actionTypes.SET_IS_LOGOUT,
  data,
})

const setAccountSignupDetailsAction = (data) => ({
  type: actionTypes.SET_ACCOUNT_SIGNUP_DETAILS,
  data,
})

const setIsAccountDeletedAction = (data) => ({
  type: actionTypes.SET_IS_ACCOUNT_DELETED,
  data,
})

const clearAuthCredentialsAction = () => ({
  type: actionTypes.CLEAR_AUTH_CREDENTIALS,
})

const setAccountAction = (data) => ({
  type: actionTypes.SET_ACCOUNT,
  data,
})

const clearAccountAction = () => ({
  type: actionTypes.CLEAR_ACCOUNT,
})

const setAccountEmail = (email) => ({
  type: actionTypes.SET_ACCOUNT_EMAIL,
  data: email,
})

const setAccountPassword = (password) => ({
  type: actionTypes.SET_ACCOUNT_PASSWORD,
  data: password,
})

const setAccountFirstName = (firstName) => ({
  type: actionTypes.SET_ACCOUNT_FIRST_NAME,
  data: firstName,
})

const setAccountLastName = (lastName) => ({
  type: actionTypes.SET_ACCOUNT_LAST_NAME,
  data: lastName,
})

const setAccountDOB = (dob) => ({
  type: actionTypes.SET_ACCOUNT_DOB,
  data: dob,
})

const setAccountAge = (age) => ({
  type: actionTypes.SET_ACCOUNT_AGE,
  data: age,
})

const setDialingCode = (code) => ({
  type: actionTypes.SET_DIALING_CODE,
  data: code,
})

const setDialingNumber = (number) => ({
  type: actionTypes.SET_DIALING_NUMBER,
  data: number,
})

const setUserId = (number) => ({
  type: actionTypes.SET_ACCOUNT_USER_ID,
  data: number,
})

const setAccountId = (number) => ({
  type: actionTypes.SET_ACCOUNT_USER_ACCOUNT_ID,
  data: number,
})

const setCustomWorkflowAccountStatus = (account_status) => ({
  type: actionTypes.SET_CUSTOM_WORKFLOW_ACCOUNT_STATUS,
  data: account_status,
})

export {
  setAuthCredentialsAction,
  clearAuthCredentialsAction,
  setAccountAction,
  clearAccountAction,
  setAccountEmail,
  setAccountPassword,
  setAccountFirstName,
  setAccountLastName,
  setAccountDOB,
  setAccountAge,
  setDialingCode,
  setDialingNumber,
  setUserId,
  setAccountId,
  setCustomWorkflowAccountStatus,
  setIsLogoutAction,
  setIsAccountDeletedAction,
  setAccountSignupDetailsAction,
}
