import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ScrollView, View } from 'react-native'

import { i18n } from '$localization/config.js'

import {
  handleGetCustomScreenText,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { ROUTES } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import {
  SuccessIcon,
  TopAndMiddleTexts,
  EditFromStartButton,
  EditFamilyButton,
  LearnMore,
} from './components'

import { setNextIndexAction } from '$redux/customFlowRoutes/actions'
import { setError } from '$redux/defaults/actions.js'

import { saveLastScreen } from '$screens/utils'

import styles from './styles'

import { CURRENT_SCREEN_NAME, FAMILY_MEMBERS_CANCER } from './constants'

export default function CustomSuccessScreen({ navigation }) {
  const dispatch = useDispatch()
  const saveError = (data) => dispatch(setError(data))

  const {
    colorSettings,
    workflowSelectedScreens,
    account = null,
    authCredentials = null,
  } = useSelector((state) => ({
    colorSettings: state.store.colorSettings,
    workflowSelectedScreens: state.customFlowRoutesStore.selectedScreens,
    account: state.accountStore.account,
    authCredentials: state.accountStore.authCredentials,
  }))

  const firstWorkflowScreenData = workflowSelectedScreens.find(
    (screen) => screen.screen_order === 1
  )

  const familyMembersCancerData = workflowSelectedScreens.find(
    (screen) => screen.name === FAMILY_MEMBERS_CANCER
  )

  const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

  const topScreenText = customScreenText?.topText
    ? customScreenText?.topText
    : i18n.t('youre_done')?.default

  const middleScreenText = customScreenText?.middleText
    ? customScreenText?.middleText
    : i18n.t(
        'thank_you_for_providing_your_health_details_you_may_edit_your_data_anytime'
      )?.default

  const handleSaveLastScreen = async () => {
    await saveLastScreen({
      account,
      authCredentials,
      item: {
        last_screen: RAW_LAST_VISITED_SCREENS.custom_success_screen,
      },
      saveError,
      navigation,
    })
  }

  useEffect(() => {
    handleSaveLastScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateToFirstWorkflowScreen = () => {
    const nextRouteIndex = 2

    dispatch(
      setNextIndexAction({
        nextRouteIndex,
      })
    )

    handleNavigateToNextScreen({
      navigation,
      routeName: firstWorkflowScreenData.name,
    })
  }

  const navigateToEditFamily = () => {
    const isFamilyMembersCancerLastScreenInWorkflow =
      workflowSelectedScreens?.length === familyMembersCancerData.screen_order

    const nextRouteIndex = isFamilyMembersCancerLastScreenInWorkflow
      ? null
      : familyMembersCancerData.screen_order + 1

    dispatch(
      setNextIndexAction({
        nextRouteIndex,
      })
    )

    handleNavigateToNextScreen({
      navigation,
      routeName: familyMembersCancerData.name,
    })
  }

  const navigateToSwitchToFamgenixAccount = () => {
    navigation.navigate(ROUTES.SwitchToFamgenixAccount.name)
  }

  return (
    <View style={styles.successScreencontainer(colorSettings)}>
      <ScrollView>
        <View style={styles.contentContainer}>
          <SuccessIcon />

          <TopAndMiddleTexts
            topScreenText={topScreenText}
            middleScreenText={middleScreenText}
            colorSettings={colorSettings}
          />

          <EditFromStartButton
            navigateToFirstWorkflowScreen={navigateToFirstWorkflowScreen}
            colorSettings={colorSettings}
          />

          {familyMembersCancerData && (
            <EditFamilyButton
              navigateToEditFamily={navigateToEditFamily}
              colorSettings={colorSettings}
            />
          )}

          <LearnMore
            navigateToSwitchToFamgenixAccount={
              navigateToSwitchToFamgenixAccount
            }
            colorSettings={colorSettings}
          />
        </View>
      </ScrollView>
    </View>
  )
}
