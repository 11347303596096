import { StyleSheet } from 'react-native'

const GREY_COLOR = 'rgba(255, 255, 255, 0.5)'

const styles = StyleSheet.create({
  roundedButtonContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    borderRadius: 37.5,
    borderWidth: 2.0,
    borderColor: GREY_COLOR,
  },
  roundedButtonTitleTextContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
