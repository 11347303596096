import { View, Text, TouchableOpacity } from 'react-native'
import { i18n } from '$localization/config.js'

import styles from './styles'

export default function EditFamilyButton({
  navigateToEditFamily,
  colorSettings,
}) {
  const editFamilyText = i18n.t('edit_family')?.default

  return (
    <View style={styles.nextButtonContainer}>
      <TouchableOpacity
        style={styles.nextButton(colorSettings)}
        onPress={navigateToEditFamily}
      >
        <Text style={styles.nextButtonText(colorSettings)}>
          {editFamilyText}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
