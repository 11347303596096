import { View, Text, TouchableOpacity } from 'react-native'
import { i18n } from '$localization/config.js'

import styles from '../../styles.js'

import {
  LIGHT_GREY_COLOR,
  TRANSPATENT,
  BLACK,
  SIGNUP_GENDER_SELECTION_TILE,
  MALE,
  FEMALE,
} from './constants.js'

export default function MaleAndFemaleButtons({
  handleGenderSelection,
  selectedGender,
  colorSettings,
}) {
  const MALE_TEXT = i18n.t('male').default
  const FEMALE_TEXT = i18n.t('female').default

  const toggleGenderButton = (isSelected = false) => {
    const backgroundColor = isSelected
      ? colorSettings?.selectable_btn_active_1 || LIGHT_GREY_COLOR
      : colorSettings?.selectable_btn_inactive_1 || TRANSPATENT

    const borderColor = isSelected
      ? colorSettings?.selectable_btn_border_active_1 || BLACK
      : colorSettings?.selectable_btn_border_inactive_1 || BLACK

    return {
      backgroundColor,
      borderColor,
      flex: 0.5,
    }
  }

  return (
    <View style={[styles.content, styles.genderContent]}>
      <View style={[styles.horizontalContent, styles.innerGenderContent]}>
        <TouchableOpacity
          nativeID={SIGNUP_GENDER_SELECTION_TILE}
          style={[
            styles.roundedTileButton,
            toggleGenderButton(selectedGender === MALE),
          ]}
          activeOpacity={1.0}
          onPress={() => handleGenderSelection(MALE)}
        >
          <View style={[styles.content, styles.genderContentContainer]}>
            <View style={styles.maleIcon(colorSettings)} />
            <Text style={styles.roundedTileButtonText(colorSettings)}>
              {MALE_TEXT}
            </Text>
          </View>
        </TouchableOpacity>

        <View style={styles.genderDivider} />

        <TouchableOpacity
          nativeID={SIGNUP_GENDER_SELECTION_TILE}
          style={[
            styles.roundedTileButton,
            toggleGenderButton(selectedGender === FEMALE),
          ]}
          activeOpacity={1.0}
          onPress={() => handleGenderSelection(FEMALE)}
        >
          <View style={[styles.content, styles.genderContentContainer]}>
            <View style={styles.femaleIcon(colorSettings)} />
            <Text style={styles.roundedTileButtonText(colorSettings)}>
              {FEMALE_TEXT}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}
