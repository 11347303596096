import BaseController from '$components/BaseController.js'

// import Account from "$data_models/Account";
import Proband from '$data_models/Proband'
import Clinician from '$data_models/Clinician.js'
import { apiFetchHandler } from '$api'

import { DefaultFullHeight } from '$assets/styles/global.styles.js'

class ClinicianDetailsController extends BaseController {
  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  colorSettings = this.props.colorSettings

  authCredentials = this.props.authCredentials

  proband = this.props.currentProband || new Proband()

  clinician = this.props.selectedClinician ?? new Clinician()

  clinicianConnectionButton = null

  constructor(props) {
    super(props)

    const currentState = this.state
    currentState.clinician = this.clinician
    currentState.scrollViewHeight = DefaultFullHeight()

    // Re-initialize state
    this.state = currentState

    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    // This event happens after render().
    this.toggleLoadingView(false, 300)
  }

  clinicianConnectionButtonAction = async (item) => {
    let connection = ''

    switch (item.status) {
      case 'two-way-connected' || 'one-way-connected':
        connection = true
        break
      case 'disconnected':
        connection = false
        break
    }

    await this.requestToggleClinician(item.status)
  }

  requestToggleClinician = async (itemStatus) => {
    const { probandID, familyID } = this.proband
    if (!probandID) {
      return null
    }
    if (!this.clinician.organization.clinician_id) {
      return null
    }

    this.clinicianConnectionButton.disabled = true
    this.setState({ showLoadingView: true })

    const token = this.authCredentials.accessToken
    const body = {
      proband_id: probandID,
      clinician_code_id: this.clinician.organization.clinician_id,
    }
    const pageDetails = {
      page: 'ClinicianDetails.controller.js',
    }

    if (itemStatus === 'disconnected') {
      const clinicianReconnectPayload = {
        path: `family/${familyID}/reconnect_clinician/`,
        method: 'post',
        token,
        body,
        pageDetails,
      }

      const response = await apiFetchHandler(clinicianReconnectPayload)

      if (response.isError) {
        return this.catchErrorResponse(response)
      }

      setTimeout(() => {
        this.toggleClinicianStatus(itemStatus)
      }, 300)

      return
    }

    const clinicianDisconnectPayload = {
      path: `family/${familyID}/disconnect_clinician/`,
      method: 'post',
      token,
      body,
      pageDetails,
    }

    const response = await apiFetchHandler(clinicianDisconnectPayload)

    if (response) {
      return this.catchErrorResponse(response)
    }

    setTimeout(() => {
      this.toggleClinicianStatus(itemStatus)
    }, 300)
  }

  toggleClinicianStatus = (itemStatus) => {
    const newState = this.state
    newState.showLoadingView = false
    newState.clinician.status = itemStatus
    this.clinicianConnectionButton.disabled = false
    this.setState(newState)

    this.props.navigation.pop()
  }

  catchErrorResponse = (response) => {
    const { saveError } = this.props
    this.clinicianConnectionButton.disabled = false
    this.setState({ showLoadingView: false })

    return saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })
  }
}

export default ClinicianDetailsController
