import { Component } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import { i18n } from '$localization/config.js'

export default class OptionButton extends Component {
  parent = this.props.parent

  skipLogic = this.props.skipDetails

  state = {
    selectedOption: '',
  }

  options = [i18n.t('yes').default, i18n.t('no').default]

  setSelectedOption = (selectedOption) => {
    this.setState({ selectedOption })
  }

  componentDidMount() {
    this.parent.disableNextButton()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption) {
      if (this.state.selectedOption == 'Yes') {
        this.parent.addToSkip()
        // this.parent.toggleAnswer(this.state.selectedOption, this.skipLogic.id)
      }
      this.parent.enableNextButton()
    }
  }

  renderOption = (option, selected, onSelect, index) => {
    const style = selected
      ? { backgroundColor: 'rgba(245,245,245,.5)', borderWidth: 0 }
      : {}

    return (
      <TouchableOpacity
        onPress={onSelect}
        key={index}
        activeOpacity={1}
        style={[
          {
            justifyContent: 'center',
            borderRadius: 30,
            borderWidth: 2,
            borderColor: 'rgba(255,255,255,.5)',
            height: 60,
            marginVertical: 10,
            width: '48%',
            marginHorizontal: 6,
          },
          style,
        ]}
      >
        <Text style={styles.selectButtonText}>{option}</Text>
      </TouchableOpacity>
    )
  }

  renderContainer = (optionNodes) => (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      {optionNodes}
    </View>
  )

  render() {
    return (
      <View style={{ width: '100%' }}>
        {/* <RadioButtons
		        options={ this.options }
		        onSelection={ this.setSelectedOption }
		        selectedOption={this.state.selectedOption }
		        renderOption={ this.renderOption }
		        renderContainer={ this.renderContainer }
			  /> */}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  selectButton: {
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,.5)',
    height: 60,
    marginVertical: 10,
    width: '48%',
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})
