import { generateComponentDataPerSurveyCategory } from '../../pageBuilder/index.js'

export default function ComponentGenerator({
  childQuestionnaire,
  surveyObject,
}) {
  const removeFalsyValues = (item) => Boolean(item)
  const isQuestionPrerequisiteNeeded = false

  const surveyFieldsCanvas = generateComponentDataPerSurveyCategory(
    childQuestionnaire,
    surveyObject,
    isQuestionPrerequisiteNeeded
  )

  const content = surveyFieldsCanvas.filter(removeFalsyValues)

  if (content.length === 0) {
    return null
  }

  return content
}
