import { useDispatch, useSelector } from 'react-redux'

import {
  setDiseaseProband,
  setFromOnboardingDiseases,
} from '$redux/diseases/actions'
import {
  clearProbandDisease,
  clearProbandSkipLogic,
  setProbandBiologicalGender,
  setProbandProfileAction,
} from '$redux/proband/actions'
import { setFromOnboardingGeneTest } from '$redux/gene_testing/actions'
import { setError, setShowLoadingView } from '$redux/defaults/actions'

const useReduxDispatch = () => {
  const dispatch = useDispatch()

  const saveProbandBiologicalGender = (data) =>
    dispatch(setProbandBiologicalGender(data))

  const saveProbandDisease = (data) => dispatch(setDiseaseProband(data))

  const clearProbandDiseases = () => dispatch(clearProbandDisease())

  const clearProbandSkipLogics = () => dispatch(clearProbandSkipLogic())

  const saveFromOnboardingDiseases = (bool) =>
    dispatch(setFromOnboardingDiseases(bool))

  const saveFromOnboardingGeneTest = (bool) =>
    dispatch(setFromOnboardingGeneTest(bool))

  const saveProbandProfileToStore = (data) =>
    dispatch(setProbandProfileAction(data))

  const saveError = (data) => dispatch(setError(data))

  const showLoadingView = (data) => dispatch(setShowLoadingView(data))

  return {
    saveProbandBiologicalGender,
    saveProbandDisease,
    clearProbandDiseases,
    clearProbandSkipLogics,
    saveFromOnboardingDiseases,
    saveFromOnboardingGeneTest,
    saveProbandProfileToStore,
    saveError,
    showLoadingView,
  }
}

const useReduxSelectors = () => {
  const {
    colorSettings,
    account,
    proband,
    selectedClinician,
    probandProfile,
    authCredentials,
  } = useSelector((state) => ({
    colorSettings: state.store.colorSettings,
    account: state.accountStore.account,
    proband: state.probandStore.proband,
    selectedClinician: state.clinicianStore.selectedClinician,
    probandProfile: state.probandStore.probandProfile,
    authCredentials: state.accountStore.authCredentials,
  }))

  return {
    colorSettings,
    account,
    proband,
    selectedClinician,
    probandProfile,
    authCredentials,
  }
}

export { useReduxDispatch, useReduxSelectors }
