import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  SafeAreaView,
  Platform,
} from 'react-native'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/Feather'
import { i18n } from '$localization/config.js'
// API
import { apiFetchHandler } from '$api'
// components
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import ButtonDone from '$components/ButtonDone'
import ButtonToggle from '$components/ButtonToggle'
import CustomizableButton from '$components/CustomizableButton'
import CustomList from './components/custom_list'
import LoadingView from '$components/LoadingView'
import ModalLoading from '$components/ModalLoading'
import { DefaultFullHeight } from '$assets/styles/global.styles.js'
import { platforms } from '$constants'
import FlexContainer from '$components/FlexContainer'
import MainWrapper from '$components/MainWrapper'
// utils
import GeneTestingMethods from './utils/geneTestingMethods'
import {
  getMemberDetails,
  convertIncomingGeneTest,
  convertOutgoingGeneTest,
  memberGeneticTesting,
  convertGeneTest,
  addRemoveDataGeneTest,
  findGeneTestMemberID,
} from './utils/_utils'
import { getLanguage } from '$utils'

// actions
import {
  setPositiveTest,
  setNegativeTest,
  resetPositiveList,
} from '$redux/gene_testing/actions.js'

import { setError } from '$redux/defaults/actions.js'

import {
  setProbandGeneticTesting,
  clearProbandEthnicity,
  clearProbandAshkenazi,
} from '$redux/proband/actions.js'

import {
  setShouldReloadDiseases,
  setShouldReloadGeneticTestings,
} from '$redux/member_profile/actions.js'

class PositiveGeneTesting extends Component {
  colorSettings = this.props.colorSettings

  positiveList = this.props.positiveList

  geneTestFromOnboarding = this.props.geneTestFromOnboarding

  currentSelectedMember = this.props.currentSelectedMember

  authCredentials = this.props.authCredentials

  proband = this.props.proband

  probandProfile = this.props.probandProfile

  negativeList = this.props.negativeList

  constructor(props) {
    super(props)

    this.state = {
      hadGeneTesting: null,
      testNegative: null,
      geneList: null,
      isLoading: true,
      disableDoneButton: true,
      negativeList: [],
      savingDiseases: false,
      prevMemberGeneTests: [],
      memberData: [],
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  async componentDidMount() {
    if (platforms.WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
    const {
      _setShouldReloadGeneticTestings_,
      navigation,
      colorSettings,
      saveError,
      selectedLanguage,
    } = this.props
    /* Set flag to reload current member's profile */
    _setShouldReloadGeneticTestings_(true)

    const loadMainQuestion = !this.geneTestFromOnboarding
      ? true
      : this.state.hadGeneTesting

    const getGeneTestingListPayload = {
      path: 'genetic_testing/',
      method: 'get',
      body: {
        params: {
          lang: getLanguage().toString(),
        },
      },
      pageDetails: {
        page: 'NegativeGeneTesting.js',
      },
    }

    const response = await apiFetchHandler(getGeneTestingListPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const geneList = getGeneTestList.data
    // Store Gene Test list to redux store.
    if (!this.geneTestFromOnboarding) {
      getMemberDetails({
        authCredentials: this.authCredentials,
        memberId: this?.currentSelectedMember.member_id ?? null,
        // probandId: this?.probandProfile.memberID ?? null,
        probandId: this.proband?.probandID ?? null, // <- Use this
        APIfetchAction: (memberData) => {
          if (memberData.isError) {
            saveError({
              isShown: true,
              status: memberData.status,
              message: memberData.message,
            })
            return
          }
          const convertedPositiveList = convertIncomingGeneTest(
            memberData.member_genetic_testing,
            'positive'
          )
          const convertedNegativeList = convertIncomingGeneTest(
            memberData.member_genetic_testing,
            'negative'
          )
          const list = new GeneTestingMethods(convertedPositiveList)
          const filteredGeneList = list.filterMainList(geneList)

          this.props._saveNegativeTestList_(memberData.member_genetic_testing)
          this.setState({
            geneList: filteredGeneList,
            isLoading: false,
            hadGeneTesting: loadMainQuestion,
            negativeList: convertedNegativeList,
            memberData,
            testNegative:
              convertedNegativeList.length !== 0
                ? true
                : this.state.testNegative,
          })
        },
        selectedLanguage,
      })
    } else {
      this.setState({
        geneList,
        isLoading: false,
        hadGeneTesting: loadMainQuestion,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.testNegative !== this.state.testNegative) {
      this.setState({
        disableDoneButton: false,
      })
    }
  }

  componentWillUnmount() {
    if (platforms.WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  toolTipHandler = () => {
    showDefaultAlert(
      i18n.t('test_positive').default,
      i18n.t(
        'testing_positive_means_that_a_change_or_mutation_was_found_in_the_gene_this_could_also_be_worded_as_pathogenic_likely_pathogenic_deleterious_or_likely_deleterious_on_a_report'
      ).default
    )
  }

  handleStoreList = (toStore, item) => {
    const list = new GeneTestingMethods(this.state.negativeList)
    let newList
    if (toStore) {
      newList = list.insert(item)
    } else {
      newList = list.remove(item)
    }

    // this.props._savePositiveTestList_(newList)
    this.setState({
      negativeList: newList,
    })
  }

  renderItems = (item) => {
    const list = new GeneTestingMethods(this.state.negativeList)
    const isSelected = list.getIndex(item.id)

    return (
      <CustomizableButton
        key={item.id}
        title={item.gene}
        colorSettings={this.colorSettings}
        onPress={(val) => this.handleStoreList(val, item)}
        setToggle={isSelected !== null}
      />
    )
  }

  loadList = () => {
    if (this.state.testNegative) {
      return (
        <View style={{ width: '100%' }}>
          <View View style={{ marginBottom: 30 }}>
            <Text
              style={[
                styles.titleText,
                {
                  color:
                    this.colorSettings?.text_input_title_color_2 || 'black',
                  fontSize: 18,
                },
              ]}
            >
              {i18n.t('select_all_that_apply').default}
            </Text>
          </View>
          {/* <FlatList
                  horizontal={false}
                  data={!this.state.isLoading ? this.state.geneList : []}
                  renderItem={this.renderItems}
                  keyExtractor={item => item.id}
                  style={{ width: '100%' }}
                /> */}
          <CustomList
            data={this.state.geneList}
            renderItem={this.renderItems}
          />
        </View>
      )
    }
    return <View />
  }

  handleOnPressMainQuestion = (val) => {
    if (val) {
      this.setState({
        disableDoneButton: true,
        negativeList: [],
      })
    } else {
      this.setState({
        disableDoneButton: false,
        testNegative: null,
      })
    }
    this.setState({ hadGeneTesting: val })
  }

  shouldLoadMainQuestion = () => {
    if (this.geneTestFromOnboarding)
      return (
        <ButtonToggle
          title={
            i18n.t('have_you_ever_had_genetic_testing_for_hereditary_cancer')
              .default
          }
          colorSettings={this.colorSettings}
          onPress={(val) => this.handleOnPressMainQuestion(val)}
          setContainerStyle={{ marginVertical: 20 }}
          isSelected={this.state.hadGeneTesting}
        />
      )
  }

  handlePositiveGeneTestOnPress = (val) => {
    this.setState({
      testNegative: val,
      disableDoneButton: false,
      negativeList: [],
    })
  }

  handleDoneButtonTitle = () => {
    let title

    if (this.geneTestFromOnboarding) {
      title = i18n.t('next').default
    } else {
      title = i18n.t('done').default
    }

    return title
  }

  loadScreen = () => {
    if (this.state.isLoading) {
      return (
        // <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <FlexContainer style={{ height: this.state.scrollViewHeight }}>
          <LoadingView
            backgroundColor={this.colorSettings?.onboarding_bgcolor}
            tintColor={this.colorSettings?.btn_no_fill_text_2}
            textColor={this.colorSettings?.btn_no_fill_text_2}
            visible={this.state.isLoading}
            // message={i18n.t('saving_datanplease_wait').default}
          />
        </FlexContainer>
        // </View>
      )
    }
    return (
      <ScrollView
        style={{ height: this.state.scrollViewHeight }}
        contentContainerStyle={styles.contentContainer}
      >
        <FlexContainer>
          {/* content 1 */}
          {this.shouldLoadMainQuestion()}
          {this.state.hadGeneTesting ? (
            <View style={{ width: '100%' }}>
              <Text
                style={{
                  color: this.colorSettings?.text_input_label_color_2,
                  fontFamily: 'karla-bold',
                  fontSize: 24,
                  textAlign: 'center',
                  marginBottom: 20,
                }}
              >
                {i18n.t('did_you_test_negative_for_any_genes').default}
              </Text>
              <TouchableOpacity
                style={{ alignSelf: 'center' }}
                onPress={() => this.toolTipHandler()}
              >
                <Icon
                  name="info"
                  size={20}
                  color={
                    this.colorSettings?.text_input_title_color_2 || 'black'
                  }
                />
              </TouchableOpacity>
              <ButtonToggle
                colorSettings={this.colorSettings}
                onPress={(val) => this.handlePositiveGeneTestOnPress(val)}
                setContainerStyle={{ marginVertical: 20 }}
                isSelected={this.state.testNegative}
              />
            </View>
          ) : (
            <View />
          )}
          {this.loadList()}
        </FlexContainer>
      </ScrollView>
    )
  }

  handleNextButton = async () => {
    const {
      _saveProbandGeneticTesting_,
      _clearProbandEthnicity_,
      _clearProbandAshkenazi_,
      navigation,
      negativeList,
    } = this.props
    const list = new GeneTestingMethods(this.state.negativeList)
    const convertList = list.convert()

    _saveProbandGeneticTesting_(convertList)

    if (this.geneTestFromOnboarding) {
      _clearProbandEthnicity_()
      _clearProbandAshkenazi_()

      navigation.navigate('PersonalEthnicity', {
        account: this.account,
        proband: this.proband,
      })
    } else {
      const convertedGeneTest = convertOutgoingGeneTest(
        this.state.negativeList,
        'negative',
        this.currentSelectedMember
      )
      const headers = this.authCredentials

      this.setState({
        savingDiseases: true,
      })

      const selectedGeneticTesting = memberGeneticTesting(
        negativeList,
        convertedGeneTest,
        'negative'
      )
      const payload = {
        member_id: this?.currentSelectedMember.member_id ?? null,
        // proband_id: this?.probandProfile.memberID ?? null,
        proband_id: this.proband?.probandID ?? null, // <- Use this
        member_genetic_testing: {
          genetic_testing: selectedGeneticTesting,
        },
      }

      await this.handleMemberGeneTestSaving(payload, headers)
    }
  }

  handleMemberGeneTestSaving = async (payload = [], headers) => {
    if (payload.length === 0) {
      this.props.navigation.pop()
    }
    const prevData = convertGeneTest(
      this.state.memberData.member_genetic_testing
    )
    const currentData = payload.member_genetic_testing?.genetic_testing || []
    const geneTestData = addRemoveDataGeneTest(currentData, prevData)
    const addGeneTestData = geneTestData?.addGeneTest ?? []
    const removeGeneTestData = geneTestData.removeGeneTest

    let saveAddCount = addGeneTestData.length
    let saveRemoveCount = removeGeneTestData.length
    let addGeneTestComplete = !(addGeneTestData.length > 0)
    const removeGeneTestComplete = !(removeGeneTestData.length > 0)
    // add Gene Test to member
    if (addGeneTestData.length > 0) {
      for (const val in addGeneTestData) {
        const newPayload = {
          proband_id: payload.proband_id,
          gene_id: addGeneTestData[val].genetic_testing_id,
          result: addGeneTestData[val].result,
        }

        const addGeneTestToMemberOnboardingPayload = {
          path: `member/${payload.member_id}/add_genetic_testing/`,
          method: 'post',
          token: this.authCredentials.accessToken,
          body: {
            proband_id: payload.proband_id,
            gene_id: addGeneTestData[val].genetic_testing_id,
            result: addGeneTestData[val].result,
          },
          pageDetails: {
            page: 'NegativeGeneTesting.js',
          },
        }

        const response = await apiFetchHandler(
          addGeneTestToMemberOnboardingPayload
        )

        const { saveError } = this.props

        if (response.isError) {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        saveAddCount--

        if (saveAddCount === 0) {
          if (removeGeneTestComplete) {
            this.setState({
              savingDiseases: false,
            })
            this.props.navigation.pop()
          } else {
            addGeneTestComplete = true
          }
        }
      }
    }
    // remove Gene Test to member
    if (removeGeneTestData.length > 0) {
      for (const val in removeGeneTestData) {
        const memberGeneTestID = findGeneTestMemberID(
          this.state.memberData.member_genetic_testing,
          removeGeneTestData[val].genetic_testing_id
        )
        const newPayload = {
          proband_id: payload.proband_id,
          member_genetic_testing_id: memberGeneTestID,
        }

        const removeMemberGeneTestingPayload = {
          path: `member/${payload.member_id}/remove_genetic_testing/`,
          method: 'post',
          token: this.authCredentials.accessToken,
          body: {
            proband_id: payload.proband_id,
            member_genetic_testing_id: memberGeneTestID,
          },
          pageDetails: {
            page: 'NegativeGeneTesting.js',
          },
        }

        const response = await apiFetchHandler(removeMemberGeneTestingPayload)

        if (response.isError) {
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          return
        }

        saveRemoveCount--
        if (saveRemoveCount === 0) {
          this.setState({
            savingDiseases: false,
          })
          this.props.navigation.pop()
        }
      }
    }

    if (addGeneTestData.length === 0 && removeGeneTestData.length === 0) {
      this.setState({
        savingDiseases: false,
      })
      this.props.navigation.pop()
    }
  }

  render() {
    const { navigation, geneTestFromOnboarding } = this.props
    return (
      <MainWrapper
        navigation={navigation}
        disableLogout={geneTestFromOnboarding}
      >
        <SafeAreaView
          style={{
            ...styles.container,
            backgroundColor: this.colorSettings?.onboarding_bgcolor || 'white',
          }}
        >
          {this.loadScreen()}

          {/* Next button */}
          <ButtonDone
            colorSettings={this.colorSettings}
            onPress={() => this.handleNextButton()}
            title={this.handleDoneButtonTitle()}
            disable={this.state.disableDoneButton}
          />
          <ModalLoading
            colorSettings={this.colorSettings}
            isVisible={this.state.savingDiseases}
            loadingTitle={i18n.t('saving_datanplease_wait').default}
          />
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "rgba(155,89,182,1.0)",
    flex: 1.0,
  },
  contentContainer: {
    // backgroundColor: "red",
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    width: '105%',
  },
})

// <Redux functions>
function mapGlobalStateToProps(state) {
  const {
    store,
    geneTestingStore,
    memberProfileStore,
    accountStore,
    probandStore,
  } = state
  return {
    colorSettings: store.colorSettings,
    positiveList: geneTestingStore.positiveTest,
    negativeList: geneTestingStore.negativeTest,
    geneTestFromOnboarding: geneTestingStore.fromOnboarding,
    currentSelectedMember: memberProfileStore.currentMember,
    authCredentials: accountStore.authCredentials,
    proband: probandStore.proband,
    probandProfile: probandStore.probandProfile,
  }
}

const actionCreator = {
  _savePositiveTestList_: setPositiveTest,
  _saveNegativeTestList_: setNegativeTest,
  _resetPositiveList_: resetPositiveList,
  _saveProbandGeneticTesting_: setProbandGeneticTesting,
  _clearProbandEthnicity_: clearProbandEthnicity,
  _clearProbandAshkenazi_: clearProbandAshkenazi,

  /* MemberProfile Reloader Actions */
  _setShouldReloadDiseases_: setShouldReloadDiseases,
  _setShouldReloadGeneticTestings_: setShouldReloadGeneticTestings,
  saveError: setError,
}

export default connect(
  mapGlobalStateToProps,
  actionCreator
)(PositiveGeneTesting)
