import { StyleSheet } from 'react-native'

const BLACK = 'black'

const styles = StyleSheet.create({
  titleText: (colorSettings) => ({
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: colorSettings?.text_input_title_color_2 || BLACK,
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    marginBottom: 17,
  }),
})

export default styles
