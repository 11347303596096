import { CommonActions } from '@react-navigation/native'

const resetSavedNavigationStack = async (
  navigation,
  navigationState,
  clearLastScreenLogin
) => {
  try {
    if (!navigationState) throw Error

    const _savedNavigationState = JSON.parse(navigationState)

    navigation.dispatch(
      CommonActions.reset({
        index: _savedNavigationState.index,
        routes: _savedNavigationState.routes,
      })
    )
    clearLastScreenLogin()
  } catch (error) {
    console.log(
      '🚀 \n\n file: resetSavedNavigationStack.js:20 \n\n error:',
      error
    )

    return null
  }
}

export default resetSavedNavigationStack
