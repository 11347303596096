import { StyleSheet } from 'react-native'

const GREY = 'rgb(74, 74, 74)'
const FADED_WHITE = 'rgb(204, 204, 204)'
const GREYISH_BLACK = 'rgba(74,74,74,0.5)'

const styles = StyleSheet.create({
  webDatePickerContainer: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  webDatePickerBirthdateTitleText: {
    marginTop: 10,
    fontSize: 14,
    color: GREYISH_BLACK,
    fontFamily: 'montserrat',
    alignSelf: 'flex-start',
  },
  simpleDatePickerYearStyle: {
    borderStyle: 'solid',
    borderColor: FADED_WHITE,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: GREY,
    flex: 1,
    alignSelf: 'center',
  },
  simpleDatePickerMonthStyle: {
    borderStyle: 'solid',
    borderColor: FADED_WHITE,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: GREY,
    flex: 1,
  },
  simpleDatePickerDayStyle: {
    borderStyle: 'solid',
    borderColor: FADED_WHITE,
    borderRadius: 3,
    marginTop: 7,
    height: 34,
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: GREY,
    flex: 1,
  },
  container: {
    width: '100%',
    flexDirection: 'row',
  },
  webDatePickerHelperText: (colorSettings) => ({
    marginVertical: 5,
    color: colorSettings?.text_input_label_color_1 || GREYISH_BLACK,
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  }),
  mobileDatePickerButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
})

export default styles
