import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  genderIdentityOptionsItemsButton: {
    borderRadius: 20,
    borderWidth: 2,
    flex: 0.33,
    height: '100%',
    borderColor: '#bababa',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  selectedGenderIdentityOptionsItemButton: (colorSettings) => ({
    borderRadius: 20,
    borderWidth: 2,
    flex: 0.33,
    height: '100%',
    borderColor: colorSettings?.selectable_btn_border_active_1 || 'black',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
    backgroundColor: colorSettings?.selectable_btn_active_1 || 'black',
  }),
  genderIdentityOptionsItemsButtonText: (colorSettings) => ({
    textAlign: 'center',
    color: colorSettings?.selectable_btn_text_active_1 || 'black',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
  }),
  maleIcon: (colorSettings) => ({
    backgroundColor: colorSettings?.selectable_btn_text_active_1 || 'black',
    marginTop: 8,
    marginBottom: 16,
    width: 40,
    height: 40,
  }),
  femaleIcon: (colorSettings) => ({
    backgroundColor: colorSettings?.selectable_btn_text_active_1 || 'black',
    marginTop: 8,
    marginBottom: 16,
    width: 40,
    height: 40,
    borderRadius: 20,
  }),
  nonBinaryIcon: (colorSettings) => ({
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colorSettings?.selectable_btn_text_active_1 || 'black',
    borderTopColor: 'white',
    borderLeftWidth: 20,
    borderRightWidth: 20,
    borderBottomWidth: 20,
    marginTop: 8,
  }),
  nonBinaryIconBottom: (colorSettings) => ({
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'white',
    borderTopColor: colorSettings?.selectable_btn_text_active_1 || 'black',
    borderLeftWidth: 20,
    marginBottom: 16,
    borderRightWidth: 20,
    borderTopWidth: 20,
  }),
})

export default styles
