import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
  },
  phoneInputText: (prefixTextColor, colorSettings) => ({
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'left',
    minWidth: 50,
    color: prefixTextColor || colorSettings?.text_input_color_1,
  }),
  phoneInputPadding: {
    paddingLeft: 4,
  },
  containerButtonStyle: {
    height: 0,
    width: 0,
  },
  phoneInputContainer: {
    flex: 1.0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default styles
