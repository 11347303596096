const DEFAULT_HEADER_SETTINGS = {
  headerBackTitle: null,
  gesturesEnabled: false,
  headerShown: true,
  headerStyle: {
    shadowColor: 'transparent',
    elevation: 0,
  },
}

export default DEFAULT_HEADER_SETTINGS
