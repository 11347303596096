import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import resetSavedNavigationStack from '../../navigation/_utils/navigationHandlers/resetSavedNavigationStack'
import { setLastLoginScreen } from '../../redux/defaults/actions'

function NavigationStateManagement({ children, navigationRef }) {
  const dispatch = useDispatch()
  const clearLastScreenLogin = () => dispatch(setLastLoginScreen(null))
  const navigation = navigationRef?.current
  const { lastScreenLogin, navigationState } = useSelector(
    (state) => state.store
  )

  if (lastScreenLogin) {
    setTimeout(
      () =>
        resetSavedNavigationStack(
          navigation,
          navigationState,
          clearLastScreenLogin
        ),
      1500
    )
  }

  return <>{children}</>
}

export default NavigationStateManagement
