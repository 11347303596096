const DEFAULT_SHADOW_COLOR = '#000'
const DEFAULT_SHADOW_OFFSET_WIDTH = 0
const DEFAULT_SHADOW_OFFSET_HEIGHT = 12
const DEFAULT_SHADOW_OPACITY = 0.58
const DEFAULT_SHADOW_RADIUS = 16.0
const DEFAULT_ELEVATION = 24
const DEFAULT_Z_INDEX = 99999
const DEFAULT_INDEX = -1
const SELECTED_BUTTON_COLOR = '#E5E7EB'
const DEFAULT_BUTTON_COLOR = 'white'
const CONTENT_HEIGHT = 'CONTENT_HEIGHT'

export {
  DEFAULT_SHADOW_COLOR,
  DEFAULT_SHADOW_OFFSET_WIDTH,
  DEFAULT_SHADOW_OFFSET_HEIGHT,
  DEFAULT_SHADOW_OPACITY,
  DEFAULT_SHADOW_RADIUS,
  DEFAULT_ELEVATION,
  DEFAULT_Z_INDEX,
  DEFAULT_INDEX,
  SELECTED_BUTTON_COLOR,
  DEFAULT_BUTTON_COLOR,
  CONTENT_HEIGHT,
}
