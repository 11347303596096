import {
  SafeAreaView,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Platform,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'
import { connect } from 'react-redux'
import Alert from '$components/Alert'
import BaseController from '$components/BaseController.js'

import { i18n } from '$localization/config.js'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'

import LoadingView from '$components/LoadingView'
import HelperModal from './Helper.modal.js'

import { apiFetchHandler } from '$api'

import AuthCredentials from '$data_models/AuthCredentials.js'
import Proband from '$data_models/Proband'

import { IS_WEB } from '$utils/Platforms.js'
import { platforms } from '$constants'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import { setError } from '$redux/defaults/actions.js'

import {
  capitalizeFirstLetter,
  getFamilySideAndRelationshipGender,
  wordReplacements,
  simpleStorage,
} from '$utils'

import {
  storeCurrentMember,
  setShowInviteHelperModal,
  setInvitedMember,
} from '$redux/member_profile/actions.js'

import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import custom_styles from '$screens/main/dashboard/invite/_styles/main.styles.js'
import MainWrapper from '$components/MainWrapper'

let listData = []

const FOR_VERIFICATION = 'for_verification'
const PENDING = 'pending'
const VERIFIED = 'verified'
const SHOULD_SHOW_INVITE_FAMILY_MEMBERS_HELPER_MODAL =
  'shouldShowInviteFamilyMemberHelperModal'

class InviteFamilyMemberMain extends BaseController {
  storage = simpleStorage()

  backgroundRefreshTimer = null

  authCredentials = this.props.authCredentials ?? new AuthCredentials()

  proband = this.props.proband ?? new Proband()

  showInviteHelperModal = this.props.showInviteHelperModal

  state = {
    shouldRenderComponent: false,
    showLoadingView: true,
    showHelperModal: false,
    shouldShowHelperModal: true,
    scrollViewHeight: calculateAvailableContentHeight(),
  }

  constructor(props) {
    super(props)

    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  componentDidMount() {
    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: calculateAvailableContentHeight() })
      )
    }

    // Set the component's navigation event listeners
    this.setNavigationEventListener('focus')

    // Check if Helper modal should be shown.
    this.storage
      .getItem(SHOULD_SHOW_INVITE_FAMILY_MEMBERS_HELPER_MODAL)
      .then((response) => {
        const shouldShowHelperModal = response != 'false'

        setTimeout(async () => {
          this.setState({
            shouldRenderComponent: false,
            showLoadingView: true,
            showHelperModal: shouldShowHelperModal,
            shouldShowHelperModal,
          })

          // API request to get list of people to invite.
          await this.getInviteList()
        }, 500)
      })
  }

  setNavigationEventAction = async () => {
    if (this.props.shouldReloadInviteStatus) await this.getInviteList()
  }

  componentWillUnmount() {
    /* This method is called when a component is being removed from the DOM. */

    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: calculateAvailableContentHeight() })
      )
    }

    // Clear auto background refresh timer when component unmounts.
    clearInterval(this.backgroundRefreshTimer)
    this.backgroundRefreshTimer = null
  }

  handleBackgroundRefresh = () => {
    if (this.props.allowBackgroundRefresh) {
      this.backgroundRefreshTimer = setInterval(async () => {
        // Call API request again.
        await this.getInviteList()
      }, this.props.backgroundRefreshInterval)
    }
  }

  errorResponseHandler = (response) => {
    const { saveError } = this.props
    const { status, error } = response
    switch (status) {
      case 500:
        showDefaultAlert(
          i18n.t('server_error')?.default,
          i18n.t(
            'sorry_the_server_is_not_responding_appropriately_please_try_again_later'
          )?.default
        )
        break
      case 404:
      case 400:
        showDefaultAlert(
          i18n.t('oops')?.default,
          i18n.t(
            'sorry_there_was_an_error_processing_your_request_please_try_again_later'
          )?.default
        )
        break
      default:
        saveError({
          isShown: true,
          status,
          message: error,
        })
        break
    }
  }

  getInviteList = async () => {
    /* Prepare API request to get list of people to invite. */

    if (!this.proband?.probandID) return

    const inviteListPayload = {
      path: 'invite/all/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        family_id: this.proband?.familyID,
      },
      pageDetails: {
        page: 'InviteFamilyMember.main.js',
      },
    }

    const response = await apiFetchHandler(inviteListPayload)

    if (response.isError) {
      this.setState({ showLoadingView: false })
      this.errorResponseHandler(response)

      return
    }

    if (response.length > 0) listData = response

    // Update component state
    this.setState({
      shouldRenderComponent: true,
      showLoadingView: false,
    })
  }

  toggleShouldShowHelperModal = (value) => {
    const val = !value

    this.storage.setItem(SHOULD_SHOW_INVITE_FAMILY_MEMBERS_HELPER_MODAL, val)

    this.setState({ shouldShowHelperModal: val })
  }

  toggleHelperModalVisible = (value) => {
    this.setState({
      showHelperModal: value,
    })
  }

  inviteMemberButtonAction = (member, inviteStatusText) => {
    if (!member?.member_id) return

    const defaultAction = async () => {
      await this.deleteMemberInvitation(member?.member_id)
    }

    if (`${inviteStatusText}`.toLowerCase() === 'pending') {
      Alert.alert(
        `${i18n.t('cancel_invite')?.default}?`,
        i18n.t(
          'are_you_sure_you_want_to_cancel_your_invitation_with_this_member'
        )?.default,
        [
          {
            text: i18n.t('yes').default,
            style: 'default',
            onPress: defaultAction,
          },
          { text: i18n.t('no').default, style: 'cancel' },
        ]
      )
      return
    }

    // Save selected member to member_profile store
    this.props._setInvitedMember_(member)

    // TODO: Probably need to change to InviteFamilyMemberNavigator
    this.props.navigation.navigate('InviteFamilyMemberNavigator')
  }

  deleteMemberInvitation = async (memberID) => {
    const probandID = this.proband?.probandID

    if (!probandID || probandID === '') return
    if (!memberID || memberID === '') return

    /* Activate loading view */
    this.setState({ showLoadingView: true })

    const deleteInvitePayload = {
      path: `member/${memberID}/delete_invite/`,
      method: 'post',
      token: this?.authCredentials?.accessToken,
      body: {
        proband_id: probandID,
      },
      pageDetails: {
        page: 'InviteFamilyMember.main.js',
        line: '291',
      },
    }

    const response = await apiFetchHandler(deleteInvitePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      const status = response?.status
      switch (status) {
        case 500:
          showDefaultAlert(
            i18n.t('server_error')?.default,
            i18n.t(
              'sorry_the_server_is_not_responding_appropriately_please_try_again_later'
            )?.default
          )
          break
        case 404:
        case 400:
          showDefaultAlert(
            i18n.t('oops')?.default,
            i18n.t(
              'sorry_there_was_an_error_processing_your_request_please_try_again_later'
            )?.default
          )
          break
        default:
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
      }
      return
    }

    await this.getInviteList()

    /* Note: This is just to make sure that loading view will not be stuck. */
    setTimeout(() => {
      this.setState({ showLoadingView: false })
    }, 1000)
  }

  renderFlatListItem = ({ item, index }) => {
    const { colorSettings } = this.props

    const member = item
    const memberName = member?.name
    const memberGender = member?.gender ? member?.gender : ''
    const memberInviteStatus = member?.invite_status
      ? `${member?.invite_status}`
      : 'invite'

    // Get Member's relationship to Proband.
    const _relationshipToProband = getFamilySideAndRelationshipGender(
      `${member?.relationship}`.toLowerCase().replace(/[\/\s]/g, '_'),
      memberGender.toLowerCase()
    )

    const _relationship = _relationshipToProband[0]?.toLowerCase()
    const _familySide = _relationshipToProband[1]?.toLowerCase()
    const _greatCounter = Math.max(_relationshipToProband[2], 0)

    const relationship =
      i18n.t(_relationship)?.default ??
      capitalizeFirstLetter(_relationship, true)

    // Append Family side if available.
    let memberRelationship = _familySide
      ? `${relationship} (${i18n.t(_familySide)?.default})`
      : relationship

    // Insert 'great' if needed.
    memberRelationship = Array.from(
      { length: _greatCounter },
      () => i18n.t('great')?.default
    )
      .concat([memberRelationship])
      .join(' ')

    const inviteStatusText = memberInviteStatus.toLowerCase().replace(' ', '_')
    let inviteStatusTextColor =
      colorSettings?.btn_no_fill_text_3 ?? 'rgba(153,153,153,0.7)'
    let inviteStatusBGColor = colorSettings?.btn_no_fill_enabled_3 ?? 'white'
    let inviteStatusBorderWidth = 2.0

    switch (inviteStatusText) {
      case FOR_VERIFICATION:
      case PENDING:
        inviteStatusTextColor =
          colorSettings?.btn_no_fill_text_3 ?? 'rgb(236,236,236)'
        inviteStatusBGColor =
          colorSettings?.btn_no_fill_disabled_3 ?? 'rgba(153,153,153,0.7)'
        break
      case VERIFIED:
        inviteStatusTextColor = 'white'
        inviteStatusBGColor =
          colorSettings?.invite_family_bgcolor ?? 'rgb(71,190,216)'
        inviteStatusBorderWidth = 0
        break
    }

    let genderIcon = null
    switch (memberGender.toLowerCase()) {
      case 'm':
        genderIcon = require('$assets/images/icon/male.png')
        break
      case 'f':
        genderIcon = require('$assets/images/icon/female.png')
        break
    }

    let borderTopLeftRadius = 0
    let borderTopRightRadius = 0
    let borderBottomLeftRadius = 0
    let borderBottomRightRadius = 0
    let borderTopWidth = 1.0

    if (index === 0) {
      borderTopLeftRadius = 10
      borderTopRightRadius = 10
      borderBottomLeftRadius = 0
      borderBottomRightRadius = 0
      borderTopWidth = 0
    } else if (index === listData.length - 1) {
      borderTopLeftRadius = 0
      borderTopRightRadius = 0
      borderBottomLeftRadius = 10
      borderBottomRightRadius = 10
    }

    return (
      <View
        style={[
          custom_styles.listItemContainer,
          {
            borderTopLeftRadius,
            borderTopRightRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius,
            borderTopWidth,
          },
        ]}
      >
        <View style={custom_styles.listItemContent}>
          <View style={{ backgroundColor: 'rgba(65,70,97,0.1)' }}>
            <Image style={{ width: 42, height: 42 }} source={genderIcon} />
          </View>

          <View style={custom_styles.listItemTitleContainer}>
            <View>
              <Text style={custom_styles.listItemTitleText}>{memberName}</Text>
              <Text style={custom_styles.listItemSubtitleText}>
                {memberRelationship}
              </Text>
            </View>
          </View>

          <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
            <View
              style={[
                custom_styles.listItemButtonContainer,
                {
                  borderWidth: inviteStatusBorderWidth,
                  backgroundColor: inviteStatusBGColor,
                },
              ]}
            >
              <TouchableOpacity
                style={custom_styles.listItemButton}
                disabled={inviteStatusText === VERIFIED}
                onPress={() =>
                  this.inviteMemberButtonAction(member, inviteStatusText)
                }
              >
                <Text
                  style={[
                    custom_styles.listItemButtonTitle,
                    {
                      color: inviteStatusTextColor,
                    },
                  ]}
                >
                  {i18n.t(inviteStatusText)?.title}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    )
  }

  render() {
    const { navigation } = this.props
    const { colorSettings } = this.props
    const content = []

    if (this.state.shouldRenderComponent) {
      if (listData && listData.length > 0) {
        content.push(
          <View style={[custom_styles.content]}>
            <View style={custom_styles.headerContainer}>
              <Text style={custom_styles.headerTitleText}>
                {i18n.t('family_members_you_can_invite_to_the_app')?.default}
              </Text>
            </View>

            <View style={custom_styles.listContainer}>
              <FlatList
                style={{
                  ...Platform.select({
                    [platforms.WEB]: {
                      height: this.state.scrollViewHeight,
                    },
                  }),
                }}
                data={listData}
                keyExtractor={({ item, index }) => index}
                renderItem={this.renderFlatListItem}
                showsVerticalScrollIndicator={false}
                ListFooterComponent={<View style={{ marginBottom: 20 }} />}
              />
            </View>
          </View>
        )
      } else {
        content.push(
          <View
            style={[
              custom_styles.content,
              {
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={[
                custom_styles.headerTitleText,
                {
                  color: 'rgb(74,74,74)',
                  textAlign: 'center',
                  paddingHorizontal: 16,
                },
              ]}
            >
              {
                i18n.t(
                  'there_are_currently_no_members_that_you_can_invite_to_your_family'
                )?.default
              }
            </Text>
          </View>
        )
      }
    }

    const setContainerStyleByPlatform = () => {
      switch (Platform.OS) {
        case platforms.WEB:
          return {
            flex: null,
            height: this.state.scrollViewHeight,
          }
      }
    }

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[custom_styles.container, setContainerStyleByPlatform()]}
          >
            {content}

            <HelperModal
              parentController={this}
              visible={this.state.showHelperModal}
              preventVisible={!this.state.shouldShowHelperModal}
            />

            <LoadingView
              visible={this.state.showLoadingView}
              tintColor={colorSettings?.post_onboarding_nav_bgcolor}
              textColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    colorSettings: store.colorSettings,
    allowBackgroundRefresh: store.allowBackgroundRefresh,
    backgroundRefreshInterval: store.backgroundRefreshInterval,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    proband: probandStore.proband,
    /** MemberProfile Store */
    shouldReloadInviteStatus: memberProfileStore.shouldReloadInviteStatus,
    showInviteHelperModal: memberProfileStore.showInviteHelperModal,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _storeCurrentMember_: (data) => dispatch(storeCurrentMember(data)),
    _setShowInviteHelperModal_: (data) =>
      dispatch(setShowInviteHelperModal(data)),
    _setInvitedMember_: (data) => dispatch(setInvitedMember(data)),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteFamilyMemberMain)
