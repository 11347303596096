import { StyleSheet } from 'react-native'

const FALLBACK_COLOR = {
  BLACK: 'black',
  WHITE: 'white',
}

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  fullBackgroundImage: (colorSettings) => ({
    flex: 1.0,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: colorSettings?.success_bgcolor ?? FALLBACK_COLOR.WHITE,
  }),
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  titleText: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 36,
    textAlign: 'center',
    color: colorSettings?.text_input_color_2 ?? FALLBACK_COLOR.BLACK,
    width: '100%',
    marginVertical: 16,
  }),
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    marginHorizontal: 16,
  },
  image: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
    marginTop: 60,
  },
})

export default styles
