const CLIN_PORTAL_URL = require('$utils/globalVariables').CLIN_PORTAL_URL

const LOAD_DELAY = 1000
const TOKEN_DELAY = 100

function createIframeElement(src) {
  const iframeEl = document.createElement('iframe')
  iframeEl.setAttribute('src', src)
  iframeEl.setAttribute(
    'style',
    'border: none; outline: none; width: auto; height: 100%;'
  )
  return iframeEl
}

function sendTokenToIframe(iframeEl, token, iframeContainerId, iframeUri) {
  iframeEl.onload = () => {
    setTimeout(() => {
      iframeEl.contentWindow.postMessage(
        {
          token,
          type: iframeContainerId,
          url: CLIN_PORTAL_URL + iframeUri,
          route: iframeUri,
        },
        '*'
      )
    }, TOKEN_DELAY)
  }
}

const IframeManager = function () {
  let iframeUri = null
  let iframeContainerId = null
  let token = ''

  const openInIframe = (uri, iframeContId, tokenStr) => {
    iframeUri = uri
    iframeContainerId = iframeContId
    token = tokenStr

    setTimeout(iframeLoadDelayed, LOAD_DELAY)
  }

  const iframeLoadDelayed = () => {
    const clinicianPortalDomain = CLIN_PORTAL_URL
    const patientPedigreeLandingPageRoute = '/patient-pedigree/'

    const iframeSrc =
      iframeContainerId === 'web-pedigree-iframe'
        ? clinicianPortalDomain + patientPedigreeLandingPageRoute
        : iframeUri

    const iframeEl = createIframeElement(iframeSrc)
    const iframeCont = document.getElementById(iframeContainerId)
    iframeCont.appendChild(iframeEl)

    if (iframeContainerId === 'web-pedigree-iframe') {
      sendTokenToIframe(iframeEl, token, iframeContainerId, iframeUri)
    }

    iframeEl.focus()
  }

  return {
    openInIframe,
  }
}

export default IframeManager
