import { Keyboard, TextInput, View } from 'react-native'
import Icon from 'react-native-vector-icons/Feather'
import { useSelector } from 'react-redux'
import styles from './styles'
import { i18n } from '$localization/config.js'
import { debounce } from '$utils'

const BLACK = 'black'

const EMPTY_VALE = ''
const SEARCH = 'search'

function GenesTextInput({ loadUMLSGenes, inputValue, setInputValue }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const PLACEHOLDER = `${i18n.t('search_genes').default}: `

  return (
    <View style={styles.textInputContainerCentered}>
      <TextInput
        style={styles.autocompleteTextInput(colorSettings)}
        onChangeText={(value) => {
          debounce(loadUMLSGenes(value, EMPTY_VALE))
          setInputValue(value)
        }}
        value={inputValue}
        placeholder={PLACEHOLDER}
        placeholderTextColor={colorSettings?.text_input_label_color_2 || BLACK}
        onBlur={Keyboard.dismiss}
      />
      <Icon
        name={SEARCH}
        size={20}
        color={colorSettings?.text_input_label_color_2 || BLACK}
      />
    </View>
  )
}

export default GenesTextInput
