const PARENTS_TITLE = 'Parents'
const FAMILY_MEMBER = {
  GRANDPARENT: 'grandparent',
  SISTER: 'sister',
  BROTHER: 'brother',
  PARENT: 'parent',
  MOM: 'mom',
  DAD: 'dad',
}

const OPERATION = {
  ADDITION: 'addition',
  SUBSTRACTION: 'substraction',
}
const INCREMENT = 1
const DECREMENT = 1
const COUNT_ZERO = 0
const DECIMAL_RADIX = 10
const PARENT_COUNT_WHEN_ADDED = 1
const PARENT_COUNT_WHEN_NOT_ADDED = 0
const PARENT_COUNT_WHEN_SWITCH_OFF = 1
const PARENT_COUNT_WHEN_SWITCH_ON = 0

const EMPTY_STRING = ''

const WINDOW_DIMENSION = 'window'

const STYLE_CONSTANTS = {
  BLACK_COLOR: 'black',
  FONT_SIZE: 16,
  LINE_HEIGHT: 22,
  MARGIN_HORIZONTAL: 18,
  SMALL_SCREEN_WIDTH: 360,
  SMALL_SCREEN_MIN_WIDTH: 140,
  LARGE_SCREEN_MIN_WIDTH: 200,
  SWITCH_CONTAINER_HEIGHT: 66,
  BUTTON_CONTAINER_MIN_WIDTH: 180,
}

export {
  PARENTS_TITLE,
  OPERATION,
  FAMILY_MEMBER,
  EMPTY_STRING,
  WINDOW_DIMENSION,
  INCREMENT,
  DECREMENT,
  COUNT_ZERO,
  DECIMAL_RADIX,
  PARENT_COUNT_WHEN_ADDED,
  PARENT_COUNT_WHEN_NOT_ADDED,
  PARENT_COUNT_WHEN_SWITCH_OFF,
  PARENT_COUNT_WHEN_SWITCH_ON,
  STYLE_CONSTANTS,
}
