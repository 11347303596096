import { StyleSheet } from 'react-native'

const FALLBACK_STYLES = {
  WHITE_COLOR: 'white',
}

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    flex: 1.0,
    backgroundColor:
      colorSettings?.success_screen_personal_health_info_bg ||
      FALLBACK_STYLES.WHITE_COLOR,
  }),
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
    marginTop: 50,
  },
  buttonsContainer: {
    marginTop: 25,
    marginHorizontal: 16,
  },
})

export default styles
