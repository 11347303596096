const HTML_STYLE = require('./constants').htmlStyle

export default function injectStyles() {
  const inject = () => {
    const styleElement = document.createElement('style')
    styleElement.innerHTML = HTML_STYLE
    document.querySelector('head').appendChild(styleElement)
  }

  return { inject }
}
