// **  Main Imports
import React from 'react'
import { View, Text, ScrollView } from 'react-native'

// **  Styles & Utils Imports
import styles from './styles'
import { i18n } from '$localization/config.js'
// **  Components Imports
import RenderClinician from '$screens/main/survey/SurveyScreen/components/RenderClinician'

// **  Constants Imports
import { EMPTY_LENGTH, WEB_NATIVE_ID } from './constants'

export default function RenderSurveyList({
  surveyList,
  colorSettings,
  navigateToScreen,
}) {
  const SUBTITLE_TEXT = i18n.t(
    'you_do_not_have_any_additional_surveys_to_complete_right_now'
  ).default

  if (surveyList.length !== EMPTY_LENGTH) {
    return (
      <ScrollView
        style={styles.surveyListScrollView}
        nativeID={WEB_NATIVE_ID}
        bounces={false}
      >
        {surveyList.map((clinician, index) => (
          <RenderClinician
            key={`${index}-${clinician?.clinician_header}`}
            colorSettings={colorSettings}
            clinician={clinician}
            navigateToScreen={navigateToScreen}
          />
        ))}
      </ScrollView>
    )
  }

  return (
    <View style={styles.surveyListContainer}>
      <Text style={styles.emptyMessageText}>{SUBTITLE_TEXT}</Text>
    </View>
  )
}
