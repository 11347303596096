import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  SafeAreaView,
  Platform,
  StatusBar,
} from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'

import { MaterialIndicator } from 'react-native-indicators'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import NextButtonAccessoryView from '$components/NextButtonAccessoryView'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'

import { setError } from '$redux/defaults/actions.js'

import { apiFetchHandler } from '$api'
import custom_styles from './styles/main.styles.js'
import { platforms } from '$constants'
import { DefaultFullHeight } from '$assets/styles/global.styles.js'

const inputAccessoryViewID = 'ForgotPWScreenEnterCode'

class ForgotPWScreenEnterCode extends Component {
  accountID = this.props.route.params?.accountID

  email = this.props.route.params?.email

  phoneNumber = this.props.route.params?.phoneNumber

  type = this.props.route.params?.type

  resendCodeAction = this.props.route.params?.resendCodeAction ?? null

  resendCodeInterval = null

  defaultResendCodeTimer = 240

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      showLoadingIndicator: false,
      mainTextValue: '',
      mainTextValueLocked: '',
      didTappedResendButton: false,
      resendCodeTimer: this.defaultResendCodeTimer,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentWillUnmount() {
    if (platforms.WEB === Platform.OS) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
    // Reset resend code interval counter
    clearInterval(this.resendCodeInterval)
    this.resendCodeInterval = null
  }

  componentDidMount() {
    if (platforms.WEB === Platform.OS) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  getEmailOrPhoneNumber = () => {
    let value = 'UNDEFINED'

    switch (this.type) {
      case 1:
        value = this.email
        break
      case 2:
        value = this.phoneNumber
        break
    }

    return value
  }

  getFormattedValue = () => {
    const { mainTextValueLocked } = this.state
    const val = mainTextValueLocked.replace(/ /g, '').split('')
    const newVal = val.join('')
    return newVal
  }

  onChangeMainTextInput = (e) => {
    const val = e.replace(/ /g, '').split('')
    const newVal = val.join('   ')

    if (val.length <= 6) {
      this.setState({
        mainTextValue: newVal,
        mainTextValueLocked: newVal,
      })
    } else {
      this.setState({
        mainTextValue: newVal,
      })
    }
  }

  toggleNextButton = () => {
    let nextButtonEnabled = false
    const isValid = this.validateInput()
    if (isValid) {
      nextButtonEnabled = true
    }
    return !nextButtonEnabled
  }

  toggleNextButtonColor = () => {
    const isValid = this.validateInput()
    const style = isValid
      ? 'bottom_next_btn_enabled'
      : 'bottom_next_btn_disabled'

    return {
      backgroundColor: this.colorSettings?.[style],
      borderColor: this.colorSettings?.[style],
    }
  }

  toggleNextButtonText = () => {
    let style = this.colorSettings?.bottom_next_btn_text_disabled || 'black'

    const enabled = this.validateInput()
    if (enabled) {
      style = this.colorSettings?.bottom_next_btn_text_enabled || 'black'
    }

    return style
  }

  validateInput = () => {
    const newVal = this.getFormattedValue()
    if (newVal.length === 6) {
      return true
    }
    return false
  }

  nextButtonAction = async () => {
    const { navigation } = this.props
    this.setState({ showLoadingIndicator: true })

    // Request for phone code verification
    const verifyPhoneCodePayload = {
      path: 'account/verify_new_verification_code/',
      method: 'post',
      body: {
        account_id: this.accountID,
        verification_code: this.getFormattedValue(),
        mode: 'password_reset',
      },
      pageDetails: {
        page: 'ForgotPWScreenEnterCode.js',
      },
    }

    const response = await apiFetchHandler(verifyPhoneCodePayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingIndicator: false })

      switch (response.status) {
        case 400:
          showDefaultAlert(
            i18n.t('verification_failed').default,
            i18n.t(
              'the_verification_code_that_you_entered_is_invalid_please_enter_the_correct_code_to_complete_the_sign-up_process'
            ).default
          )
          break

        default:
          saveError({
            isShown: true,
            status: response.status,
            message: response.error,
          })
          break
      }
      return
    }

    this.setState({ showLoadingIndicator: false })

    navigation.push('ForgotPWEnterNewPassword', {
      accountID: this.accountID,
      email: this.email,
      token: response.token,
    })
  }

  // ##########################################################################
  // ## Main render function ##
  // ##########################################################################
  render() {
    let enableKeyboardAvoiding = false
    if (Platform.OS === 'ios') {
      enableKeyboardAvoiding = true
    }

    return (
      <ErrorBoundary>
        <SafeAreaView
          style={[
            custom_styles.container,
            { backgroundColor: this.colorSettings?.splash_bgcolor },
          ]}
        >
          {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}

          {/* <Content Container> */}
          <KeyboardAvoidingView
            style={{ flex: 1.0 }}
            contentContainerStyle={{ flex: 1.0 }}
            behavior="position"
            enabled={enableKeyboardAvoiding}
          >
            <ScrollView
              style={{ height: this.state.scrollViewHeight }}
              contentContainerStyle={[
                custom_styles.contentContainer,
                { alignItems: 'center' },
              ]}
            >
              {/* <Content> */}
              <View
                style={[
                  custom_styles.content,
                  {
                    marginHorizontal: 32,
                    ...Platform.select({
                      web: {
                        width: '30%',
                        minWidth: 370,
                      },
                      default: {
                        width: '100%',
                      },
                    }),
                  },
                ]}
              >
                <View style={{ flex: 0.5, width: '100%' }}>
                  <View style={{ flex: 1.0, justifyContent: 'center' }}>
                    {/* <Title Text Container> */}
                    <View style={{ flex: 0.3, justifyContent: 'center' }}>
                      <View style={custom_styles.titleTextContainer}>
                        {/* <Title Text> */}
                        <Text
                          style={[
                            custom_styles.titleText,
                            {
                              color:
                                this.colorSettings?.text_input_title_color_1,
                            },
                          ]}
                        >
                          {i18n.t('we_sent_you_an6-digit_code').default}
                        </Text>
                        {/* </Title Text> */}
                      </View>
                    </View>
                    {/* </Title Text Container> */}

                    {/* <MainTextInput Container> */}
                    <View style={{ flex: 0.4, justifyContent: 'center' }}>
                      {/* <MainTextInput> */}
                      <View style={{ justifyContent: 'center' }}>
                        <View style={custom_styles.mainTextInputContainer}>
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'center',
                              height: '100%',
                            }}
                          >
                            <TextInput
                              nativeID="web_text_input"
                              style={[
                                custom_styles.mainTextInput,
                                {
                                  fontSize: 24,
                                  textAlign: 'center',
                                  width: '100%',
                                  color:
                                    this.colorSettings?.text_input_color_1 ||
                                    'rgba(74, 74, 74, 1)',
                                },
                              ]}
                              // placeholder={ i18n.t ('enter_verification_code').default }
                              placeholder="–– –– –– –– –– ––"
                              placeholderTextColor={
                                this.colorSettings
                                  ?.text_input_placeholder_color_1
                              }
                              inputAccessoryViewID={inputAccessoryViewID}
                              keyboardType="number-pad"
                              selectionColor={
                                this.colorSettings?.text_input_color_1
                              }
                              autoCapitalize="none"
                              onChangeText={this.onChangeMainTextInput}
                              onSubmitEditing={this.nextButtonAction}
                              value={this.state.mainTextValueLocked}
                            />
                          </View>
                        </View>
                      </View>
                    </View>

                    {/* <Helper Text Container> */}
                    <View style={{ flex: 0.2, justifyContent: 'center' }}>
                      <View
                        style={[
                          custom_styles.helperTextContainer,
                          { flex: 1.0 },
                        ]}
                      >
                        <Text
                          style={[
                            custom_styles.helperText,
                            {
                              textAlign: 'center',
                              color:
                                this.colorSettings?.text_input_label_color_1,
                            },
                          ]}
                        >
                          {`${
                            i18n.t(
                              'please_enter_the_password_reset_code_we_sent_to'
                            ).default
                          } `}
                          {this.getEmailOrPhoneNumber()}
                        </Text>
                      </View>
                    </View>
                    {/* </Helper Text Container> */}

                    <View style={{ flex: 0.1, backgroundColor: '' }} />
                  </View>
                </View>

                {/* <Resend Code Button> */}
                <View style={{ height: 40 }}>
                  {this.renderResendCodeButton()}
                </View>
                {/* </Resend Code Button> */}
              </View>
              {/* </Content> */}

              {/* <Loading Indicator> */}
              <View
                style={{
                  position: 'absolute',
                  bottom: 32,
                  height: 60,
                  width: '100%',
                }}
              >
                {this.toggleLoadingIndicator(this.state.showLoadingIndicator)}
              </View>
              {/* </Loading Indicator> */}
            </ScrollView>
          </KeyboardAvoidingView>
          {/* </Content Container> */}

          {/* <Next button> */}
          <View style={custom_styles.nextButtonContainer}>
            <TouchableOpacity
              disabled={this.toggleNextButton()}
              style={[
                custom_styles.nextButton,
                this.toggleNextButtonColor(),
                {
                  backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
                },
              ]}
              onPress={this.nextButtonAction}
            >
              <Text
                style={[
                  custom_styles.nextButtonText,
                  {
                    color: this.toggleNextButtonText(),
                  },
                ]}
              >
                {i18n.t('next').default}
              </Text>
            </TouchableOpacity>
          </View>

          <NextButtonAccessoryView
            nativeID={inputAccessoryViewID}
            backgroundColor="white"
            labelText={i18n.t('next').default}
            labelColor={this.toggleNextButtonText()}
            disabled={this.toggleNextButton()}
            style={[
              custom_styles.nextButton,
              this.toggleNextButtonColor(),
              {
                backgroundColor: this.colorSettings?.bottom_next_btn_enabled,
              },
            ]}
            onPress={this.nextButtonAction}
          />
          {/* </Next button> */}
        </SafeAreaView>
      </ErrorBoundary>
    )
  }

  toggleLoadingIndicator = (show = false) => {
    if (!show) {
      return
    }
    return <MaterialIndicator color={this.colorSettings?.btn_no_fill_text_1} />
  }

  // ##########################################################################
  // ## Resend Code Button render function ##
  // ##########################################################################
  renderResendCodeButton = () => {
    const { didTappedResendButton } = this.state
    const { resendCodeTimer } = this.state

    if (didTappedResendButton) {
      if (resendCodeTimer >= 0) {
        return (
          <View style={styles.resendCodeButtonContainer}>
            <View style={styles.resendCodeButton}>
              <Text
                style={[
                  styles.resendCodeButtonText,
                  {
                    color: this.colorSettings?.text_input_placeholder_color_1,
                  },
                ]}
              >
                {`${i18n.t('please_wait').default} `}
                {resendCodeTimer}
                {` ${i18n.t('seconds_to_send_another_code').default}`}
              </Text>
            </View>
          </View>
        )
      }
    }

    return (
      <View style={styles.resendCodeButtonContainer}>
        <TouchableOpacity
          onPress={this.resendCodeButtonAction}
          style={styles.resendCodeButton}
        >
          <Text
            style={[
              styles.resendCodeButtonText,
              {
                color: this.colorSettings?.btn_no_fill_text_1,
              },
            ]}
          >
            {i18n.t('resend_verification_code').default}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  resendCodeButtonAction = () => {
    /* Initiate Resend Code timer. */

    this.setState({ didTappedResendButton: true })

    this.resendCodeInterval = setInterval(() => {
      const { resendCodeTimer } = this.state

      if (resendCodeTimer === 0) {
        clearInterval(this.resendCodeInterval)
        this.setState({
          didTappedResendButton: false,
          resendCodeTimer: this.defaultResendCodeTimer,
        })
      } else {
        this.setState({ resendCodeTimer: resendCodeTimer - 1 })
      }
    }, 1000)

    // Initiate API request for another verification code.
    if (this.resendCodeAction) {
      this.resendCodeAction()
    }
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(ForgotPWScreenEnterCode)

const styles = StyleSheet.create({
  resendCodeButtonContainer: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  resendCodeButton: {
    alignItems: 'center',
    height: '100%',
  },
  resendCodeButtonText: {
    color: 'rgb(101,209,185)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
  },
})
