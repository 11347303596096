import { Alert } from 'react-native'
import {
  checkBiometricPermissionDataEnrolled,
  getBiometricName,
  handleBiometricsSupportAndPermissions,
} from '../utils/Biometrics.js'
import { getData, saveSecureStoreData } from '../utils/SecureStore.js'
import { i18n } from '$localization/config.js'
import { DOMAIN_CLIENT_NAME } from '../utils/globalVariables.js'
import biometricsTypeName from '$utils/BiometricsType'

const useBiometrics = () => {
  const saveAsyncStorageValue = async (payload) => {
    try {
      const dataToSave = [
        { key: 'email', value: payload.email },
        { key: 'password', value: payload.password },
        { key: 'enabledSecurity', value: 'true' },
        { key: 'hasUserAcceptedBiometric', value: 'true' },
      ]

      await Promise.all(dataToSave.map((item) => saveSecureStoreData(item)))
    } catch (err) {
      console.log(
        '🚀 \n\n file: useBiometrics \n\n saveAsyncStorageValue= \n\n err:',
        err
      )
    }
  }

  const handleNoBiometricsSave = async () => {
    try {
      await saveSecureStoreData({ key: 'enabledSecurity', value: 'false' })
    } catch (error) {
      console.log(
        '🚀 \n\n file: useBiometrics \n\n handleNoBiometricsSave= \n\n error:',
        error
      )
    }
  }

  const asyncBiometricsAlert = async (payload) => {
    const biometricType = await getBiometricName()
    const biometricsAlertTitle = i18n.t('biometricsTitle.default', {
      biometricsType: biometricsTypeName(biometricType),
    })
    const biometricsAlertSubtitle = i18n.t('biometricsSubtitle.default', {
      biometricType: biometricsTypeName(biometricType),
      AppName: DOMAIN_CLIENT_NAME,
    })

    return new Promise((resolve) => {
      Alert.alert(
        biometricsAlertTitle,
        biometricsAlertSubtitle,
        [
          {
            text: i18n.t('yes').default,
            onPress: async () => {
              await saveAsyncStorageValue(payload)
              resolve()
            },
          },
          {
            text: i18n.t('no').default,
            onPress: async () => {
              await handleNoBiometricsSave(payload)
              resolve()
            },
          },
        ],
        {
          cancelable: false,
        }
      )
    })
  }

  const askForBiometricsSave = async (payload) => {
    const isBiometricEnrolled = await checkBiometricPermissionDataEnrolled()

    try {
      const response = await handleBiometricsSupportAndPermissions()
      const enabledSecurity = await getData({ key: 'enabledSecurity' })

      if (response && enabledSecurity === null)
        return await asyncBiometricsAlert(payload, isBiometricEnrolled)
    } catch (err) {
      console.log(err)
    }
  }

  return {
    askForBiometricsSave,
  }
}

export default useBiometrics
