import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    height: 60,
    paddingHorizontal: 10,
  },
  titleText: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 24,
    color: colorSettings?.text_input_label_color_2,
    textAlign: 'center',
    marginBottom: 20,
  }),
})

export default styles
