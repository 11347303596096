import { StyleSheet } from 'react-native'
import { IS_WEB } from '$utils/Platforms.js'

const WHITE = '#fff'
const BLACK = '#000'

const styles = StyleSheet.create({
  invitedPersonHeaderInfoContainer: (colorSettings) => ({
    backgroundColor: colorSettings?.post_onboarding_nav_bgcolor ?? WHITE,
    width: '100%',
    paddingVertical: 16,
    paddingHorizontal: 16,
    alignSelf: IS_WEB() ? 'center' : 'auto',
  }),
  invitedPersonHeaderInfoText: (colorSettings) => ({
    color: colorSettings?.btn_filled_text_1 || BLACK,
    fontFamily: 'montserrat',
    fontSize: 12,
    textAlign: IS_WEB() ? 'center' : 'auto',
  }),
})

export default styles
