import { useDispatch, useSelector } from 'react-redux'
import {
  clearProbandDisease,
  clearProbandSkipLogic,
  setProbandGenderIdentity,
  setProbandProfileAction,
  setProbandPronoun,
} from '$redux/proband/actions'
import {
  setDiseaseProband,
  setFromOnboardingDiseases,
} from '$redux/diseases/actions'
import { setFromOnboardingGeneTest } from '$redux/gene_testing/actions'
import {
  setError,
  setLastLoginScreen,
  setShowLoadingView,
} from '$redux/defaults/actions'

const useReduxDispatch = () => {
  const dispatch = useDispatch()

  const saveProbandPronoun = (data) => dispatch(setProbandPronoun(data))
  const saveProbandGenderIdentity = (data) =>
    dispatch(setProbandGenderIdentity(data))

  const clearProbandDiseases = () => dispatch(clearProbandDisease())
  const clearProbandSkipLogics = () => dispatch(clearProbandSkipLogic())
  const saveFromOnboardingDiseases = (bool) =>
    dispatch(setFromOnboardingDiseases(bool))
  const saveFromOnboardingGeneTest = (bool) =>
    dispatch(setFromOnboardingGeneTest(bool))

  const saveDiseaseProband = (arg) => dispatch(setDiseaseProband(arg))

  const saveProbandProfileAction = (data) =>
    dispatch(setProbandProfileAction(data))
  const saveLastLoginScreen = (data) => dispatch(setLastLoginScreen(data))
  const saveError = (data) => dispatch(setError(data))
  const showLoadingView = (data) => dispatch(setShowLoadingView(data))

  return {
    saveProbandPronoun,
    saveProbandGenderIdentity,
    clearProbandDiseases,
    clearProbandSkipLogics,
    saveFromOnboardingDiseases,
    saveFromOnboardingGeneTest,
    saveDiseaseProband,
    saveProbandProfileAction,
    saveLastLoginScreen,
    saveError,
    showLoadingView,
  }
}

const useReduxSelectors = () => {
  const {
    colorSettings,
    proband,
    probandProfile,
    account,
    selectedClinician,
    diseaseFromOnboarding,
    lastScreenLogin,
    authCredentials,
  } = useSelector((state) => ({
    colorSettings: state.store.colorSettings,
    proband: state.probandStore.proband,
    probandProfile: state.probandStore.probandProfile,
    account: state.accountStore.account,
    selectedClinician: state.clinicianStore.selectedClinician,
    authCredentials: state.accountStore.authCredentials,
    diseaseFromOnboarding: state.diseaseStore.diseaseFromOnboarding,
    lastScreenLogin: state.store.lastScreenLogin,
  }))

  return {
    colorSettings,
    proband,
    probandProfile,
    account,
    authCredentials,
    selectedClinician,
    diseaseFromOnboarding,
    lastScreenLogin,
  }
}

export { useReduxDispatch, useReduxSelectors }
