import { StyleSheet } from 'react-native'

const FALLBACK_STYLES = {
  WHITE_COLOR: 'white',
}

const styles = StyleSheet.create({
  topScreenTextContainer: {
    width: '100%',
    marginTop: 10,
  },
  titleText: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_personal_health_info_header_text ||
      FALLBACK_STYLES.WHITE_COLOR,
  }),
  middleAndBottomScreenTextContainer: {
    width: '100%',
    marginTop: 25,
    paddingHorizontal: 16,
  },
  middleText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_personal_health_info_text ||
      FALLBACK_STYLES.WHITE_COLOR,
  }),
  bottomText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_personal_health_info_text ||
      FALLBACK_STYLES.WHITE_COLOR,
    marginTop: 20,
  }),
})

export default styles
