import React, { useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { WebView } from 'react-native-webview'
import { useDispatch } from 'react-redux'

import { setShowLoadingView as setShowLoadingViewAction } from '$redux/defaults/actions'

function ViewOnWeb({ route, navigation }) {
  const uri = route?.params?.uri

  const title = route?.params?.title

  const dispatch = useDispatch()

  const setShowLoadingView = (data) => dispatch(setShowLoadingViewAction(data))

  useEffect(() => {
    navigation.setOptions({ title })
    setShowLoadingView(true)
  }, [])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <WebView
        style={{ flex: 1 }}
        source={{ uri }}
        onLoad={() => setShowLoadingView(false)}
      />
    </SafeAreaView>
  )
}

export default ViewOnWeb
