import { Component } from 'react'
import { SafeAreaView, View } from 'react-native'

import { connect } from 'react-redux'

import { WebView } from 'react-native-webview'
import { MaterialIndicator } from 'react-native-indicators'

import { i18n } from '$localization/config.js'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'

import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import { IS_WEB } from '$utils/Platforms.js'
import MainWrapper from '$components/MainWrapper'
import { webIframe } from '$utils'

const WEBVIEW_IFRAME_CONTAINER_ID = 'genetic_resources_iframe_container'

class GeneticResourcesWebView extends Component {
  state = {
    shouldShowComponent: false,
    showLoadingView: true,
    uri: this.props.customWebViewUri,
    scrollViewHeight: calculateAvailableContentHeight(),
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { customWebViewUri } = this.props
    this.setState({
      shouldShowComponent: true,
      uri: customWebViewUri,
    })

    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)

      webIframe().openInIframe(customWebViewUri, WEBVIEW_IFRAME_CONTAINER_ID)
    }
  }

  componentWillUnmount() {
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }
  }

  handleHeaderNavigation = () => {
    const { navigation, colorSettings, route } = this.props
    const headerTitle = route.params?.t
    const showLoadingView = route.params.lv ?? true
    let newHeaderButton

    if (showLoadingView) {
      newHeaderButton = (
        <View style={global_styles.headerButton}>
          <MaterialIndicator color={colorSettings?.nav_title_light} size={24} />
        </View>
      )
    } else {
      newHeaderButton = null
    }

    navigation.setOptions({
      headerTitle: i18n.t(headerTitle).default ?? '',
      headerRight: () => newHeaderButton,
    })
  }

  screenResizeHandler = () => {
    const height = calculateAvailableContentHeight()
    this.setState({ scrollViewHeight: height })
  }

  toggleLoadingView = (start = true) => {
    const { navigation } = this.props

    navigation.setParams({ lv: start })
  }

  render() {
    const { navigation, colorSettings } = this.props

    /* For Web only */
    if (IS_WEB()) {
      const content = (
        <MainWrapper navigation={navigation}>
          <View
            style={{
              height: this.state.scrollViewHeight,
              backgroundColor: colorSettings?.onboarding_bgcolor,
            }}
          >
            <View
              style={{
                flex: 1.0,
                height: '100%',
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              nativeID={WEBVIEW_IFRAME_CONTAINER_ID}
              onLayout={() => this.toggleLoadingView(false)}
            />
          </View>
        </MainWrapper>
      )

      return content
    }

    if (this.state.shouldShowComponent) {
      return (
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.container,
              {
                backgroundColor: colorSettings?.post_onboarding_bgcolor,
              },
            ]}
          >
            <WebView
              style={{ flex: 1.0, width: '100%' }}
              source={{ uri: this.state.uri }}
              onLoadStart={() => this.toggleLoadingView(true)}
              onLoad={() => this.toggleLoadingView(false)}
            />
          </SafeAreaView>
        </MainWrapper>
      )
    }
    return (
      <SafeAreaView
        style={[
          global_styles.container,
          {
            backgroundColor: colorSettings?.post_onboarding_bgcolor,
          },
        ]}
      />
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    customWebViewUri: store.customWebViewUri,
  }
}

export default connect(mapGlobalStateToProps, null)(GeneticResourcesWebView)
