const SECONDS = 60
const MILLISECONDS = 1000
const DAY = 24
const WEEK = 7

export default function calculateTimeDifference(startDate, endDate) {
  const timeDifferenceInMillis = endDate - startDate

  const calculateUnit = (unitInSeconds) =>
    timeDifferenceInMillis / unitInSeconds

  return {
    seconds: calculateUnit(MILLISECONDS),
    minutes: calculateUnit(MILLISECONDS * SECONDS),
    hours: calculateUnit(MILLISECONDS * SECONDS * SECONDS),
    days: calculateUnit(MILLISECONDS * SECONDS * SECONDS * DAY),
    weeks: calculateUnit(MILLISECONDS * SECONDS * SECONDS * DAY * WEEK),
  }
}
