const getGenderIcon = (gender, isDeceased = false) => {
  const genderIcons = {
    m: {
      normal: require('$assets/images/new_assets/gender-male.png'),
      deceased: require('$assets/images/new_assets/gender-male-deceased.png'),
    },
    f: {
      normal: require('$assets/images/new_assets/gender-female.png'),
      deceased: require('$assets/images/new_assets/gender-female-deceased.png'),
    },
  }

  let genderIcon = null

  if (gender && gender !== '') {
    const genderKey = gender.toLowerCase()
    const iconPath = isDeceased
      ? genderIcons[genderKey]?.deceased
      : genderIcons[genderKey]?.normal

    if (iconPath) {
      genderIcon = iconPath
    }
  }

  return genderIcon
}

export default getGenderIcon
