import { StatusBar } from 'react-native'

import { IS_IOS } from '$utils/Platforms.js'

export default function CustomStatusBar() {
  return IS_IOS() ? (
    <StatusBar barStyle="dark-content" />
  ) : (
    <StatusBar backgroundColor="white" barStyle="dark-content" />
  )
}
