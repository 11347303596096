import { TouchableOpacity, View } from 'react-native'

import { useSelector } from 'react-redux'

import { Switch } from 'react-native-switch'

import {
  DEFAULT_ACTIVE_BG,
  DEFAULT_INACTIVE_BG,
  DEFAULT_ACTIVE_DOT,
  DEFAULT_INACTIVE_DOT,
} from './constants'

import styles from './styles'

export default function ResponsiveDarkGraySwitch({ value, onValueChange }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.switchContainer} activeOpacity={1.0}>
        <Switch
          backgroundActive={
            colorSettings?.switch_bg_active || DEFAULT_ACTIVE_BG
          }
          backgroundInactive={
            colorSettings?.switch_bg_inactive || DEFAULT_INACTIVE_BG
          }
          circleActiveColor={
            colorSettings?.switch_dot_active || DEFAULT_ACTIVE_DOT
          }
          circleInActiveColor={
            colorSettings?.switch_dot_inactive || DEFAULT_INACTIVE_DOT
          }
          circleSize={16}
          circleBorderWidth={0}
          barHeight={10}
          renderActiveText={false}
          renderInActiveText={false}
          switchWidthMultiplier={1.5}
          onValueChange={onValueChange}
          value={value}
        />
      </TouchableOpacity>
    </View>
  )
}
