import relationshipList from '../data'
import removeGreatFromRelationship from './removeGreatFromRelationship'

const USER_ROLES = { PROBAND: 'proband', YOU: 'you' }
const DEFAULT_RELATIONSHIP_TYPE = 'default'

export default function getRelationshipGender(relationship, gender) {
  const hasRelationship = !relationship || relationship === ''

  if (hasRelationship) return null

  const { newRelationship, greatCounter } =
    removeGreatFromRelationship(relationship)

  const mappedRelationship = relationshipList[newRelationship] || {
    default: newRelationship,
  }

  let relationshipGender =
    mappedRelationship[gender] || mappedRelationship[DEFAULT_RELATIONSHIP_TYPE]

  if (newRelationship === USER_ROLES.PROBAND)
    relationshipGender = USER_ROLES.YOU

  const relationshipAndRelationshipGender = {
    greatCounter,
    relationshipGender,
  }

  return relationshipAndRelationshipGender
}
