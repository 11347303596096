import { useEffect } from 'react'
import { TouchableOpacity } from 'react-native'

import { useSelector } from 'react-redux'

import { Switch } from 'react-native-switch'

import useStateWithCallback from 'use-state-with-callback'

import styles from './styles'

import {
  DEFAULT_ACTIVE_BG,
  DEFAULT_INACTIVE_BG,
  DEFAULT_ACTIVE_DOT,
  DEFAULT_INACTIVE_DOT,
} from './constants'

export default function DarkGraySwitch({ value, onValueChange, paddingTop }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const [stateValue, setStateValue] = useStateWithCallback(
    value,
    (prevValue) => {
      if (value) setStateValue(prevValue)
    }
  )

  useEffect(() => {
    setStateValue(value)
  }, [value])

  const applyChange = () => {
    setStateValue(() => !value)

    if (onValueChange) {
      onValueChange(!stateValue)
    }
  }

  return (
    <TouchableOpacity
      style={styles.switchContainer(paddingTop)}
      onPress={applyChange}
      activeOpacity={1.0}
    >
      <Switch
        backgroundActive={colorSettings?.switch_bg_active || DEFAULT_ACTIVE_BG}
        backgroundInactive={
          colorSettings?.switch_bg_inactive || DEFAULT_INACTIVE_BG
        }
        circleActiveColor={
          colorSettings?.switch_dot_active || DEFAULT_ACTIVE_DOT
        }
        circleInActiveColor={
          colorSettings?.switch_dot_inactive || DEFAULT_INACTIVE_DOT
        }
        circleSize={16}
        circleBorderWidth={0}
        barHeight={10}
        renderActiveText={false}
        renderInActiveText={false}
        switchWidthMultiplier={1.5}
        onValueChange={onValueChange}
        value={stateValue}
      />
    </TouchableOpacity>
  )
}
