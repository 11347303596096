import { ScrollView, View, Text } from 'react-native'
import ErrorBoundary from 'react-native-error-boundary'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import styles from './styles'
import GenderIdentityOptions from './components/GenderIdentityOptions'
import PronounOptions from './components/PronounOptions'
import { OTHER_PRONOUN } from './constants'
import OtherPronounTextInput from './components/OtherPronounTextInput'
import NextButtonAbsolute from '$components/NextButtonAbsolute'
import { useReduxDispatch, useReduxSelectors } from './reduxHandlers'
import {
  extendSelectedGenderIdentity,
  getSelectedGenderIdentity,
  getSelectedPronoun,
} from './helpers'
import { mutateUpdatePersonalInfo } from './fetchHandlers'
import updateMemberProfile from '../../utils/updateMemberProfile'
import saveLastScreen from '$screens/utils/saveLastScreen'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'
import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { i18n } from '$localization/config'

function PersonalGenderIdentity({ getGenderOtherPronounInfo }) {
  const navigation = useNavigation()

  const [selectedGenderIdentity, setSelectedGenderIdentity] = useState(null)
  const [selectedPronoun, setSelectedPronoun] = useState(null)
  const [textValue, setTextValue] = useState('')

  const pronounTranslation = i18n.t('pronoun').default
  const whatIsYourGenderIdentityTranslation = i18n.t(
    'what_is_your_gender_identity'
  ).default

  const {
    probandProfile,
    proband,
    authCredentials,
    account,
    selectedClinician,
    colorSettings,
  } = useReduxSelectors()
  const {
    saveProbandPronoun,
    saveError,
    saveProbandProfileAction,
    showLoadingView,
  } = useReduxDispatch()

  useEffect(() => {
    const handleGenderIdentityOnMount = () => {
      if (probandProfile.profileData.genderIdentity) {
        const identity = getSelectedGenderIdentity(
          probandProfile.profileData.genderIdentity
        )

        if (!identity) return

        setSelectedGenderIdentity(identity)
      }

      if (probandProfile.profileData.pronoun) {
        const pronoun = getSelectedPronoun(probandProfile.profileData.pronoun)

        if (!pronoun) return

        setSelectedPronoun(pronoun?.value)
      }
    }

    handleGenderIdentityOnMount()
  }, [probandProfile])

  const handleNextButtonClick = async () => {
    const { show_gender_identity: showGenderIdentity = false } =
      selectedClinician || false

    if (showGenderIdentity) {
      getGenderOtherPronounInfo(textValue)
    }

    const extendedGenderIdentity = extendSelectedGenderIdentity(
      selectedGenderIdentity
    )

    const payload = {
      proband_id: proband.probandID,
      member_id: proband.probandID,
      member_info: {
        gender_identity: extendedGenderIdentity,
        pronoun: selectedPronoun,
        other_pronoun: textValue,
      },
    }

    showLoadingView(true)
    try {
      const response = await mutateUpdatePersonalInfo({ payload })

      if (response.isError) {
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }

      const memberData = {
        proband_id: proband.probandID,
        member_id: proband.probandID,
      }

      const memberProfile = await updateMemberProfile(
        memberData,
        authCredentials
      )

      const screen = {
        last_screen: RAW_LAST_VISITED_SCREENS.gender_identity,
      }

      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })

      saveProbandProfileAction(memberProfile)
      saveProbandPronoun(selectedPronoun)

      const nextRoute = getNextWorkflowRoute({
        defaultNextRoute: CUSTOM_FLOW_ROUTES.DiseaseListMainScreen.name,
      })

      handleNavigateToNextScreen({
        navigation,
        routeName: nextRoute,
        selectedScreen: '',
      })
    } catch (err) {
      console.log(err)
    } finally {
      showLoadingView(false)
    }
  }

  return (
    <ErrorBoundary>
      <SafeAreaView style={styles.container(colorSettings)}>
        <View style={styles.contentContainer}>
          <ScrollView style={styles.scrollViewContainer}>
            <View
              nativeID="web_scaled_main_container"
              style={styles.scaledContainer}
            >
              <Text
                style={styles.personalGenderIdentityTitleText(colorSettings)}
              >
                {whatIsYourGenderIdentityTranslation}
              </Text>
              <GenderIdentityOptions
                setSelectedGenderIdentity={setSelectedGenderIdentity}
                selectedGenderIdentity={selectedGenderIdentity}
              />
              <Text
                style={styles.personalGenderIdentityPronounText(colorSettings)}
              >
                {pronounTranslation}
              </Text>
              <PronounOptions
                setSelectedPronoun={setSelectedPronoun}
                selectedPronoun={selectedPronoun}
              />
              {selectedPronoun === OTHER_PRONOUN && (
                <OtherPronounTextInput
                  setTextValue={setTextValue}
                  textValue={textValue}
                />
              )}
            </View>
          </ScrollView>
        </View>

        <NextButtonAbsolute onPressAction={handleNextButtonClick} />
      </SafeAreaView>
    </ErrorBoundary>
  )
}

export default PersonalGenderIdentity
