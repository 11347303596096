import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { i18n } from '$localization/config.js'
import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import styles, { arrowColor } from './styles'

import {
  CHEVRON_DOWN,
  CHEVRON_UP,
  CHEVRON_FORWARD,
  EMPTY_STRING,
  DOWN_POSITION,
  TOP_POSITION,
  BOTTOM_POSITION,
} from './constants'

export default function CollapseButton({
  colorSettings = {
    linked_text: EMPTY_STRING,
  },
  fieldIsHidden = true,
  showFieldHandler = () => {},
  title = EMPTY_STRING,
  arrowCollapsedPosition = DOWN_POSITION,
  titleTextStyle = {},
  dividerPosition = EMPTY_STRING,
  children,
}) {
  const SHOW_MORE = i18n.t('show_more').default
  const SHOW_LESS = i18n.t('show_less').default

  const arrowPosition = () =>
    arrowCollapsedPosition === DOWN_POSITION ? CHEVRON_DOWN : CHEVRON_UP

  const arrowImage = fieldIsHidden ? CHEVRON_FORWARD : arrowPosition()

  const textField = fieldIsHidden ? SHOW_MORE : SHOW_LESS
  const defaultTitle = title || textField

  return (
    <View style={styles.contentSeparatorLineContainer}>
      {dividerPosition === TOP_POSITION && (
        <View style={section_styles.contentSeparatorLine} />
      )}
      <TouchableOpacity
        style={section_styles.contentItem}
        onPress={showFieldHandler}
      >
        <View style={section_styles.contentItemLabelTextContainer}>
          {children || (
            <Text
              style={[
                section_styles.contentItemLabelText,
                { color: colorSettings.linked_text },
                titleTextStyle,
              ]}
            >
              {defaultTitle}
            </Text>
          )}
        </View>

        <View
          style={[
            section_styles.contentItemTextInputContainer,
            styles.iconContainer,
          ]}
        >
          <Ionicons name={arrowImage} size={28} color={arrowColor} />
        </View>
      </TouchableOpacity>
      {dividerPosition === BOTTOM_POSITION && (
        <View style={section_styles.contentSeparatorLine} />
      )}
    </View>
  )
}
