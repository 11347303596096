import React from 'react'
import { View } from 'react-native'

import LabelField from '../LabelField'

export default function LabelText({
  questionLabel,
  colorSettings = [],
  masterQuestion = '',
}) {
  if (!questionLabel) return <View />

  return (
    <LabelField colorSettings={colorSettings} masterQuestion={masterQuestion} />
  )
}
