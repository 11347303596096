import { StyleSheet } from 'react-native'

const FALLBACK_COLORS = {
  GRAY_HALF_OPACITY: 'rgba(74, 74, 74, 0.5)',
  GRAY_FULL_OPACITY: 'rgb(74, 74, 74)',
}

const styles = StyleSheet.create({
  textInputContainer: (colorSettings) => ({
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor:
      colorSettings?.borderBottomColor || FALLBACK_COLORS.GRAY_HALF_OPACITY,
  }),
  textInputLabel: (colorSettings) => ({
    fontFamily: 'montserrat',
    fontSize: 18,
    color: colorSettings?.labelColor || FALLBACK_COLORS.GRAY_HALF_OPACITY,
    marginBottom: 10,
  }),
  textInputField: (colorSettings) => ({
    color:
      colorSettings?.textInputFieldColor || FALLBACK_COLORS.GRAY_FULL_OPACITY,
  }),
})

export default styles
