import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web

import { useSelector } from 'react-redux'

/* Child Navigators */
import PositiveGeneTestMain from 'screens/personal_details/genetic_testing/PositiveGeneTestMain.js'
import RelativeGeneTestNavigator from '$navigation/relatives/RelativeGeneTestNavigator.js'
import RelativeDiseasesNavigator from '$navigation/relatives/RelativeDiseases.navigator.js'
import SelectParentNavigator from '$navigation/relatives/SelectParent.navigator.js'
import SelectParentBloodRelationNavigator from '$navigation/relatives/SelectParentBloodRelation.navigator.js'
import SelectTwinNavigator from '$navigation/relatives/SelectTwin.navigator.js'

/* New screens */
import ParentsGrandparentsList from '$screens/relatives/refactored/ParentsGrandparentsList'
import ChildrenList from '$screens/relatives/refactored/ChildrenList'
import SiblingsList from '$screens/relatives/refactored/SiblingsList'
import PaternalAuntsUnclesList from '$screens/relatives/refactored/PaternalAuntsUnclesList'
import MaternalAuntsUnclesList from '$screens/relatives/refactored/MaternalAuntsUnclesList'

import EditProfileView from '$screens/main/family/edit_profile/EditProfile.view.js'
import DiseaseMainList from '$screens/personal_details/onboarding_disease_selection/DiseaseMainList.js'
import DiseaseSubList from '$screens/personal_details/onboarding_disease_selection/DiseaseSubList.js'
import DiseasesSkipLogic from '$screens/personal_details/onboarding_disease_selection/DiseaseSkipLogic'
import NegativeGeneTesting from '$screens/personal_details/genetic_testing/NegativeGeneTesting.js'

import { fixDiseaseName } from '$utils'

import {
  capitalizeFirstLetter,
  getFamilySideAndRelationshipGender,
} from '$utils'

import { i18n } from '$localization/config.js'

import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'

const Stack = createStackNavigator()

function MainStack() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator initialRouteName="ParentsGrandparentsList">
      <Stack.Screen
        name="ParentsGrandparentsList"
        component={ParentsGrandparentsList}
        options={() => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('parents_slash_grandparents').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
        })}
      />
      <Stack.Screen
        name="ChildrenList"
        component={ChildrenList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('children').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="SiblingsList"
        component={SiblingsList}
        options={({ navigation, route }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('siblings').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PaternalAuntsUnclesList"
        component={PaternalAuntsUnclesList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('aunts_slash_uncles').default}
              headerSubtitle={i18n.t('dads_side')?.default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="MaternalAuntsUnclesList"
        component={MaternalAuntsUnclesList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('aunts_slash_uncles').default}
              headerSubtitle={i18n.t('moms_side')?.default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="EditProfileView"
        component={EditProfileView}
        options={({ route, navigation }) => {
          const currentMember = route.params?.cm

          const gender = currentMember?.gender ?? null

          const relationshipToProband =
            currentMember?.relationship_to_proband ?? null

          const _relationshipToProband = getFamilySideAndRelationshipGender(
            relationshipToProband,
            gender
          )

          const _relationship = _relationshipToProband[0]?.toLowerCase()
          const _familySide = _relationshipToProband[1]?.toLowerCase()

          let relationship =
            i18n.t(fixDiseaseName(_relationship))?.default ?? _relationship
          let familySide = i18n.t(_familySide)?.default ?? _familySide

          relationship = capitalizeFirstLetter(relationship, true, ' ')
          familySide = capitalizeFirstLetter(familySide, true, ' ')

          const options = {
            ...headerNavigationDefaultOptions(
              colorSettings?.nav_title_light,
              colorSettings?.post_onboarding_nav_bgcolor
            ),
            headerTitle: () => (
              <HeaderTitleComponent
                headerTitle={relationship}
                headerSubtitle={familySide}
                headerTintColor={colorSettings?.nav_title_light}
                headerBackgroundColor={
                  colorSettings?.post_onboarding_nav_bgcolor
                }
              />
            ),
            headerLeft: () => (
              <LeftButtonComponent
                navigation={navigation}
                closeButtonType="back"
                backArrowColor={colorSettings?.nav_title_light}
              />
            ),
          }

          return options
        }}
      />
      <Stack.Screen
        name="DiseasesTypeView"
        component={DiseaseMainList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DiseasesListView"
        component={DiseaseSubList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DiseasesSkipLogicView"
        component={DiseasesSkipLogic}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PositiveGeneTesting"
        component={PositiveGeneTestMain}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="NegativeGeneTesting"
        component={NegativeGeneTesting}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

export default function RelativesNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="MainStack"
      screenOptions={{
        gesturesEnabled: false,
        headerShown: false,
      }}
    >
      <Stack.Screen name="MainStack" component={MainStack} />
      <Stack.Screen
        name="RelativeDiseasesNavigator"
        component={RelativeDiseasesNavigator}
      />
      <Stack.Screen
        name="RelativeGeneTestNavigator"
        component={RelativeGeneTestNavigator}
      />
      <Stack.Screen
        name="SelectParentNavigator"
        component={SelectParentNavigator}
      />
      <Stack.Screen
        name="SelectParentBloodRelationNavigator"
        component={SelectParentBloodRelationNavigator}
      />
      <Stack.Screen
        name="SelectTwinNavigator"
        component={SelectTwinNavigator}
      />
    </Stack.Navigator>
  )
}
