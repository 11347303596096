const GENDER_IDENTITY_VALUES = {
  FEMALE: 'female',
  MALE: 'male',
  NON_BINARY: 'non-binary',
}

const GENDER_VALUES = {
  FEMALE: 'f',
  MALE: 'm',
}

const AGE_TYPE_VALUES = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
}

const excludedRelationshipToCount = [
  'proband',
  'mother',
  'father',
  'grandmother',
  'grandfather',
]

const AJUSTED_GENDERS_NAMES = {
  AFAB: 'AFAB',
  AMAB: 'AMAB',
  UAAB: 'UAAB',
}

export {
  AGE_TYPE_VALUES,
  GENDER_IDENTITY_VALUES,
  GENDER_VALUES,
  AJUSTED_GENDERS_NAMES,
  excludedRelationshipToCount,
}
