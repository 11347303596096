import React, { useState, useEffect } from 'react'
import { SafeAreaView, View } from 'react-native'
import { useSelector } from 'react-redux'

import { WebView } from 'react-native-webview'
import { MaterialIndicator } from 'react-native-indicators'

import global_styles from '$assets/styles/global.styles.js'
import styles from './styles'
import MainWrapper from '$components/MainWrapper'

export default function CustomWebView({ navigation, route }) {
  const [shouldShowComponent, setShouldShowComponent] = useState(false)
  const [uri, setUri] = useState(null)

  const { colorSettings, customWebViewUri } = useSelector(
    (state) => state.store
  )

  useEffect(() => {
    handleHeaderNavigation()
    setShouldShowComponent(true)
    setUri(customWebViewUri)
  }, [customWebViewUri])

  const handleHeaderNavigation = () => {
    const showLoadingView = route.params?.lv ?? true
    let headerButton

    if (showLoadingView) {
      headerButton = (
        <View style={global_styles.headerButton}>
          <MaterialIndicator color={colorSettings?.nav_title_light} size={24} />
        </View>
      )
    } else {
      headerButton = null
    }

    navigation.setOptions({
      headerRight: () => headerButton,
    })
  }

  const toggleLoadingView = (start = true) => {
    navigation.setParams({ lv: start })
  }

  if (shouldShowComponent) {
    return (
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={[global_styles.container, styles.background(colorSettings)]}
        >
          <WebView
            style={styles.webView}
            source={{ uri }}
            onLoadStart={() => toggleLoadingView(true)}
            onLoad={() => toggleLoadingView(false)}
          />
        </SafeAreaView>
      </MainWrapper>
    )
  }

  return (
    <MainWrapper navigation={navigation}>
      <SafeAreaView
        style={[global_styles.container, styles.background(colorSettings)]}
      />
    </MainWrapper>
  )
}
