import { useState, useEffect } from 'react'
import { View, StyleSheet } from 'react-native'

import { preventAutoHideAsync, hideAsync } from 'expo-splash-screen'
import { useFonts } from 'expo-font'
import { useDispatch, useSelector } from 'react-redux'

import AppNavigator from '$navigation/AppNavigator'

import { setShowLoadingView } from '$redux/defaults/actions.js'

import { saveSecureStoreData } from '$utils/SecureStore.js'
import { LIGHT_GRAY } from './constants'

import {
  setDefaultProps,
  fontsToLoad,
  setInitialLanguage,
  fetchAppSettings,
  clearLoginCredentials,
  setInitialReduxStateValues,
} from './helpers'

import ErrorView from './components/ErrorView'
import CustomStatusBar from './components/CustomStatusBar'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: LIGHT_GRAY,
  },
})

export default function AppDelegate() {
  const [areFontsLoaded, areFontsLoadingError] = useFonts(fontsToLoad)
  const [isAppReady, setIsAppReady] = useState(false)

  const dispatch = useDispatch()
  const isFetchAppSettingsError = useSelector((state) => state.store.isError)

  const prepareScreen = async () => {
    try {
      await preventAutoHideAsync()
      await fetchAppSettings(dispatch)
      await saveSecureStoreData({ key: 'isLogout', value: 'false' })
      await clearLoginCredentials()

      setInitialReduxStateValues(dispatch)
      setDefaultProps()
      setInitialLanguage(dispatch)
    } catch (error) {
      console.error('Failed to prepare screen in AppDelegate.js:', error)

      setIsAppReady(false)
    } finally {
      setIsAppReady(true)
      dispatch(setShowLoadingView(false))
    }
  }

  useEffect(() => {
    prepareScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLayoutRootView = async () => {
    if (!areFontsLoaded && !areFontsLoadingError) return

    await hideAsync()
  }

  if (!isAppReady) return null

  return (
    <View style={styles.container} onLayout={onLayoutRootView}>
      {isFetchAppSettingsError?.isShown && (
        <ErrorView error={isFetchAppSettingsError} dispatch={dispatch} />
      )}

      <CustomStatusBar />

      <AppNavigator />
    </View>
  )
}
