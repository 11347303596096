import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web

import { useSelector } from 'react-redux'
import { Text, TouchableOpacity } from 'react-native'

import { i18n } from '$localization/config.js'
import SelectLanguage from '$screens/language/SelectLanguage'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'

import global_styles from '$assets/styles/global.styles.js'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'

const Stack = createStackNavigator()

export default function SettingsNavigator(props) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const onLanguageSelectedFunction =
    props.route.params?.onLanguageSelected ?? function () {}

  const handleNavigationHeader = (navigation, route) => {
    const onPressDone =
      route.params?.onDone ??
      console.log(
        '🚀 \n\n file: Settings.navigator.js:24 \n\n handleNavigationHeader \n\n onDone is not defined'
      )

    return (
      <TouchableOpacity
        style={global_styles.headerButton}
        onPress={onPressDone}
      >
        <Text
          style={[
            global_styles.headerRightButtonText,
            {
              color: colorSettings?.nav_title_dark,
            },
          ]}
        >
          {i18n.t('done').default}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SelectLanguage"
        component={SelectLanguage}
        options={({ navigation, route }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_dark,
            colorSettings?.splash_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('select_language').default}
              headerTintColor={colorSettings?.nav_title_dark}
              headerBackgroundColor={colorSettings?.splash_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_dark}
            />
          ),
          headerRight: () => handleNavigationHeader(navigation, route),
        })}
        initialParams={{ onLanguageSelected: onLanguageSelectedFunction }}
      />
    </Stack.Navigator>
  )
}
