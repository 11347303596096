import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1.0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
