import { Text, TouchableOpacity } from 'react-native'

import styles from './styles'

export default function CustomSubmitButton({
  onPress,
  style,
  titleStyle,
  title,
}) {
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() => onPress()}
      style={[styles.container, style]}
    >
      <Text style={[styles.title, titleStyle]}>{title}</Text>
    </TouchableOpacity>
  )
}
