import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web
import { useSelector } from 'react-redux'

import { printPdf } from './helpers/helpers'
import { i18n } from '$localization/config.js'
import {
  capitalizeFirstLetter,
  getFamilySideAndRelationshipGender,
  fixDiseaseName,
} from '$utils'

import SurveyScreen from '$screens/main/survey/SurveyScreen/index.js'
import EditProfileView from '$screens/main/family/edit_profile/EditProfile.view.js'
import DiseaseMainList from '$screens/personal_details/onboarding_disease_selection/DiseaseMainList.js'
import DiseaseSubList from '$screens/personal_details/onboarding_disease_selection/DiseaseSubList.js'
import DiseasesSkipLogic from '$screens/personal_details/onboarding_disease_selection/DiseaseSkipLogic'
import PositiveGeneTestMain from '$screens/personal_details/genetic_testing/PositiveGeneTestMain.js'
import NegativeGeneTesting from '$screens/personal_details/genetic_testing/NegativeGeneTesting.js'
import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'
import FamilyMainTabView from '$screens/main/family/FamilyMainTabView/FamilyMainTabView'
import PrintPdfButton from './components/PrintPdfButton'
import ExpandAndCollapseButtons from './components/ExpandAndCollapseButtons'

const Stack = createStackNavigator()

const FamilyNavigator = () => {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="FamilyMainViewTab"
      screenOptions={{ presentation: 'card' }}
    >
      <Stack.Screen
        name="FamilyMainViewTab"
        component={FamilyMainTabView}
        options={({ navigation, route }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('family').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
              customAction={() => navigation.navigate('Home')}
            />
          ),
          headerRight: () => {
            const handleExpandFamilyMembers =
              route.params?.handleExpandFamilyMembers
            const handleCollapseFamilyMembers =
              route.params?.handleCollapseFamilyMembers
            const familyTabsNavigationButtonTypes =
              route.params?.familyTabsNavigationButtonTypes
            const generatedHTML = route.params?.htmlTemplate
            const printPedigree = route.params?.printPedigree

            switch (+familyTabsNavigationButtonTypes) {
              case 0:
                return (
                  <ExpandAndCollapseButtons
                    handleExpandFamilyMembers={handleExpandFamilyMembers}
                    handleCollapseFamilyMembers={handleCollapseFamilyMembers}
                  />
                )
              case 1:
                return <PrintPdfButton onPress={printPedigree} />
              case 2:
                return (
                  <PrintPdfButton onPress={() => printPdf(generatedHTML)} />
                )
              default:
                return null
            }
          },
        })}
      />
      <Stack.Screen
        name="EditProfileView"
        component={EditProfileView}
        options={({ navigation, route }) => {
          const getCurrentMember = route.params?.cm

          const gender = getCurrentMember?.gender ?? null
          const relationshipType =
            getCurrentMember?.relationship_to_proband ?? null

          const relationshipToProband = getFamilySideAndRelationshipGender(
            relationshipType,
            gender
          )

          const getRelationship = relationshipToProband[0]?.toLowerCase()
          const getFamilySide = relationshipToProband[1]?.toLowerCase()
          let greatCounter = relationshipToProband[2]

          let relationship =
            i18n.t(fixDiseaseName(getRelationship))?.default ?? getRelationship
          let familySide = i18n.t(getFamilySide)?.default ?? getFamilySide

          relationship = capitalizeFirstLetter(relationship, true, ' ')
          familySide = capitalizeFirstLetter(familySide, true, ' ')

          while (greatCounter > 0) {
            let relationshipCount = relationship

            if (
              relationshipCount.includes('Grandfather') ||
              relationshipCount.includes('Grandmother')
            ) {
              relationshipCount = relationshipCount.replace(/\d/g, '')
            }

            relationship = `${i18n.t('great')?.default} ${relationshipCount}`
            greatCounter--
          }

          return {
            ...headerNavigationDefaultOptions(
              colorSettings?.nav_title_light,
              colorSettings?.post_onboarding_nav_bgcolor
            ),
            headerTitle: () => (
              <HeaderTitleComponent
                headerTitle={relationship}
                headerSubtitle={familySide}
                headerTintColor={colorSettings?.nav_title_light}
                headerBackgroundColor={
                  colorSettings?.post_onboarding_nav_bgcolor
                }
              />
            ),
            headerLeft: () => (
              <LeftButtonComponent
                navigation={navigation}
                closeButtonType="back"
                backArrowColor={colorSettings?.nav_title_light}
              />
            ),
          }
        }}
      />
      <Stack.Screen name="SurveyScreenView" component={SurveyScreen} />
      <Stack.Screen
        name="DiseasesTypeView"
        component={DiseaseMainList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DiseasesListView"
        component={DiseaseSubList}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DiseasesSkipLogicView"
        component={DiseasesSkipLogic}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="PositiveGeneTesting"
        component={PositiveGeneTestMain}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="NegativeGeneTesting"
        component={NegativeGeneTesting}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('personal_details').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

export default FamilyNavigator
