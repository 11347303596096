import {
  GENDER_IDENTITIES,
  PRONOUN_LIST,
  SHORTEN_GENDER_IDENTITIES,
} from './constants'

const getSelectedGenderIdentity = (genderIdentity) => {
  switch (genderIdentity) {
    case GENDER_IDENTITIES.FEMALE:
      return SHORTEN_GENDER_IDENTITIES.FEMALE
    case GENDER_IDENTITIES.MALE:
      return SHORTEN_GENDER_IDENTITIES.MALE
    case GENDER_IDENTITIES.NON_BINARY:
      return SHORTEN_GENDER_IDENTITIES.NON_BINARY
    default:
      return null
  }
}

const extendSelectedGenderIdentity = (genderIdentity) => {
  switch (genderIdentity) {
    case SHORTEN_GENDER_IDENTITIES.FEMALE:
      return GENDER_IDENTITIES.FEMALE
    case SHORTEN_GENDER_IDENTITIES.MALE:
      return GENDER_IDENTITIES.MALE
    case SHORTEN_GENDER_IDENTITIES.NON_BINARY:
      return GENDER_IDENTITIES.NON_BINARY
    default:
      return null
  }
}

const getSelectedPronoun = (pronoun) => {
  const selectedPronoun = PRONOUN_LIST.find((val) => val.value === pronoun)

  return selectedPronoun
}

export {
  getSelectedGenderIdentity,
  getSelectedPronoun,
  extendSelectedGenderIdentity,
}
