import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web

import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import SelectParent from '$screens/main/family/edit_profile/_components/relationship_info/_components/modals/SelectParent.js'

import { navigationConstants } from '$constants'

import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'

const Stack = createStackNavigator()

function SelectParentNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator
      initialRouteName="SelectParent"
      screenOptions={{
        ...navigationConstants.DEFAULT_HEADER_SETTINGS,
        mode: 'card',
      }}
    >
      <Stack.Screen
        name="SelectParent"
        component={SelectParent}
        options={({ route, navigation }) => {
          const currentParentGender = route.params?.cpg
          let navTitle = ''

          if (currentParentGender) {
            switch (`${currentParentGender}`.toLowerCase()) {
              case 'm':
                navTitle = i18n.t('select_father')?.title
                break
              case 'f':
                navTitle = i18n.t('select_mother')?.title
                break
              default:
                navTitle = i18n.t('select_parent')?.title
                break
            }
          }

          return {
            ...headerNavigationDefaultOptions(
              colorSettings?.nav_title_light,
              colorSettings?.modal_nav_bgcolor
            ),
            headerTitle: () => (
              <HeaderTitleComponent
                headerTitle={navTitle}
                headerTintColor={colorSettings?.nav_title_light}
                headerBackgroundColor={colorSettings?.modal_nav_bgcolor}
              />
            ),
            headerLeft: () => (
              <LeftButtonComponent
                navigation={navigation}
                closeButtonType="back"
                backArrowColor={colorSettings?.nav_title_light}
              />
            ),
          }
        }}
      />
    </Stack.Navigator>
  )
}

export default SelectParentNavigator
