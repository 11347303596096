const getParentBloodRelationTypes = () => {
  const relationshipTypes = [
    {
      id: 1,
      key: 'siblings',
      name: 'siblings',
      definition: 'parents_shares_both_parents_in_common',
    },
    {
      id: 2,
      key: 'half_siblings',
      name: 'half-siblings',
      definition: 'parents_shares_one_parent_in_common',
    },
    {
      id: 3,
      key: 'father-daughter',
      name: 'father_slash_daughter',
      definition:
        'male_parent_having_a_sexual_relationship_with_one_of_his_female_offspring',
    },
    {
      id: 4,
      key: 'mother-son',
      name: 'mother_slash_son',
      definition:
        'female_parent_having_a_sexual_relationship_with_one_of_her_male_offspring',
    },
    {
      id: 5,
      key: 'first_cousins',
      name: 'first_cousins',
      definition: 'parents_shares_common_grandparents_but_different_parents',
    },
    {
      id: 6,
      key: 'first_cousins_once_removed',
      name: 'first_cousins_once_removed',
      definition: 'parents_first_cousin_or_child_of_first_cousin',
    },
    {
      id: 7,
      key: 'second_cousins',
      name: 'second_cousins',
      definition:
        'parents_shares_common_great-grandparents_but_different_parents_and_grandparents',
    },
    {
      id: 8,
      key: 'farther_than_second_cousins',
      name: 'farther_than_second_cousins',
      definition: 'very_distant_relative',
    },
    {
      id: 9,
      key: 'grandfather-granddaughter',
      name: 'grandfather_slash_granddaughter',
      definition:
        'male_parent_having_a_sexual_relationship_with_one_of_his_female_grandchild',
    },
    {
      id: 10,
      key: 'grandmother-grandson',
      name: 'grandmother_slash_grandson',
      definition:
        'female_parent_having_a_sexual_relationship_with_one_of_her_male_grandchild',
    },
    {
      id: 11,
      key: 'uncle-niece',
      name: 'uncle_slash_niece',
      definition:
        'male_parent_having_a_sexual_relationship_with_one_of_his_siblings_female_offspring',
    },
    {
      id: 12,
      key: 'aunt-nephew',
      name: 'aunt_slash_nephew',
      definition:
        'female_parent_having_a_sexual_relationship_with_one_of_her_siblings_male_offspring',
    },
    {
      id: 13,
      key: 'other_not_sure',
      name: 'other_slash_not_sure',
      definition: 'just_some_relative',
    },
  ]

  return relationshipTypes
}

export default getParentBloodRelationTypes
