import { StyleSheet } from 'react-native'

const WHITE = 'white'
const BLACK = 'black'

export default StyleSheet.create({
  container: {
    width: '100%',
  },
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginVertical: 10,
  },
  incrementButton: { paddingVertical: 10 },
  inputContainer: (colorSettings) => ({
    borderBottomColor: colorSettings?.text_input_color_border_2 || WHITE,
    borderBottomWidth: 1,
    width: '30%',
    padding: 10,
  }),
  input: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    color: colorSettings?.text_input_color_2 || BLACK,
    width: '100%',
    textAlign: 'center',
  }),
  decrementButton: { paddingVertical: 10 },
  textTitle: {
    fontFamily: 'montserrat-medium',
    fontSize: 15,
  },
  outerInputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
