import React, { useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import CustomizableButton from '$components/CustomizableButton'
import { i18n } from '$localization/config.js'
import styles from './styles'

export default function ButtonToggle({
  colorSettings,
  isSelected = null,
  currentState,
  title,
  onPress,
  setContainerStyle,
}) {
  const [isToggled, setToggled] = useState(isSelected)

  const YES_TITLE = i18n.t('yes').default
  const NO_TITLE = i18n.t('no').default

  useEffect(() => {
    if (currentState) currentState(isToggled)
  }, [isToggled])

  const toggleSwitcher = (val) => {
    setToggled(val)
    onPress(val)
  }

  const toggledOn = () => {
    if (isToggled === null) return false
    if (isToggled) return true
    return false
  }

  const ToggledOff = () => {
    if (isToggled === null) return false
    if (isToggled) return false
    return true
  }

  return (
    <View style={[styles.mainContainer, setContainerStyle]}>
      <Text style={styles.titleText(colorSettings)}>{title}</Text>
      <View style={styles.container}>
        <View style={styles.buttonContainer}>
          <CustomizableButton
            title={YES_TITLE}
            onPress={() => toggleSwitcher(true)}
            colorSettings={colorSettings}
            setToggle={toggledOn()}
            isDisabled={toggledOn()}
          />
        </View>
        <View style={styles.buttonContainer}>
          <CustomizableButton
            title={NO_TITLE}
            onPress={() => toggleSwitcher(false)}
            colorSettings={colorSettings}
            setToggle={ToggledOff()}
            isDisabled={ToggledOff()}
          />
        </View>
      </View>
    </View>
  )
}
