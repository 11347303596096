import { store } from '../../../redux/configureStore'

const selectorHelpers = () => {
  const { colorSettings, inviteID, selectedLanguage } = store.getState().store
  const { currentMember } = store.getState().memberProfileStore

  const isInvited = currentMember?.is_invited || false
  const firstName = currentMember?.first_name || ''

  return {
    colorSettings,
    inviteID,
    selectedLanguage,
    isInvited,
    firstName,
    currentMember,
  }
}

export { selectorHelpers }
