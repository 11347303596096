import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  successIcon: {
    width: 180,
    height: 180,
    resizeMode: 'contain',
  },
})

export default styles
