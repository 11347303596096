import { useSelector } from 'react-redux'
import { useState } from 'react'
import Autocomplete from 'react-native-autocomplete-input'
import styles from './styles'
import GenesTextInput from './components/GenesTextInput'
import GeneListItem from './components/GeneListItem'

const ALWAYS = 'always'

function GenesAutocompleteComponent({
  UMLSGenesList,
  loadUMLSGenes,
  handleSelectedGeneTest,
}) {
  const [inputValue, setInputValue] = useState('')

  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Autocomplete
      data={UMLSGenesList}
      containerStyle={styles.autoCompleteContainer}
      inputContainerStyle={styles.autocompleteInputContainer(colorSettings)}
      listContainerStyle={styles.listContainer}
      renderTextInput={() => (
        <GenesTextInput
          inputValue={inputValue}
          loadUMLSGenes={loadUMLSGenes}
          setInputValue={setInputValue}
        />
      )}
      autoCorrect={false}
      hideResults={false}
      flatListProps={{
        showsVerticalScrollIndicator: false,
        style: styles.autocompleteList,
        keyboardShouldPersistTaps: ALWAYS,
        renderItem: ({ item }) => (
          <GeneListItem
            item={item}
            loadUMLSGenes={loadUMLSGenes}
            inputValue={inputValue}
            handleSelectedGeneTest={handleSelectedGeneTest}
          />
        ),
      }}
    />
  )
}

export default GenesAutocompleteComponent
