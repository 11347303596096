import { StyleSheet } from 'react-native'
import { FALLBACK_COLOR } from './constants'

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    flex: 1.0,
    backgroundColor:
      colorSettings?.success_screen_account_bg ?? FALLBACK_COLOR.WHITE,
  }),
  fullBackgroundImage: {
    flex: 1.0,
    width: '100%',
    justifyContent: 'center',
  },
  messageContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 18,
  },
  titleText: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
    width: '100%',
    marginVertical: 16,
    color:
      colorSettings?.success_screen_account_header_text ?? FALLBACK_COLOR.BLACK,
  }),
  bodyText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    width: '100%',
    paddingHorizontal: 16,
    color: colorSettings?.success_screen_account_text ?? FALLBACK_COLOR.BLACK,
  }),
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    marginHorizontal: 16,
    marginBottom: 40,
  },
  image: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
    marginTop: 50,
  },
})

export default styles
