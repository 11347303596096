import { StyleSheet } from 'react-native'

const COLORS = {
  GRAY_SHADE: 'rgb(74,74,74)',
  LIGHT_GRAY_SHADE: 'rgb(102,102,102)',
  WHITE: 'white',
}

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    backgroundColor: colorSettings?.splash_bgcolor || COLORS.WHITE,
    flex: 1.0,
  }),
  scrollViewHeight: (scrollViewHeight) => ({
    height: scrollViewHeight,
  }),
  contentContainer: {
    flex: 1.0,
    width: '100%',
  },
  content: {
    flex: 1.0,
    marginHorizontal: 13,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    flex: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    marginLeft: 15,
    width: 152,
    height: 141,
    resizeMode: 'contain',
  },
  textContainer: {
    flex: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
    color: colorSettings?.text_input_title_color_1 || COLORS.GRAY_SHADE,
  }),
  subTitleText: (colorSettings) => ({
    marginTop: 24,
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    color: colorSettings?.text_input_title_color_1 || COLORS.LIGHT_GRAY_SHADE,
  }),
  buttonContainer: {
    height: 60,
    width: '100%',
    justifyContent: 'flex-end',
  },
  nextButtonContainer: {
    justifyContent: 'center',
    height: 60,
    width: '100%',
    borderWidth: 1,
  },
})

export default styles
