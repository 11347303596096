import {
  InputAccessoryView,
  View,
  TouchableOpacity,
  Text,
  Keyboard,
} from 'react-native'

import { IS_IOS } from '$constants/platforms'

import { i18n } from '$localization/config.js'

import global_styles from '$assets/styles/global.styles.js'
import styles from './styles'

export default function NextButtonAccessoryView({
  style: buttonStyle,
  backgroundColor: initialBackgroundColor,
  labelText,
  labelColor,
  disabled: buttonDisabled,
  nativeID,
  onPress: onPressProp,
}) {
  const NEXT_BUTTON_TEXT = i18n.t('next').default

  const onPress = () => {
    Keyboard.dismiss()
    onPressProp()
  }

  if (IS_IOS()) {
    return (
      <InputAccessoryView nativeID={nativeID}>
        <View style={styles.nextButtonContainer(initialBackgroundColor)}>
          <View style={styles.mainContainer}>
            <View>
              <TouchableOpacity
                disabled={buttonDisabled}
                style={buttonStyle}
                onPress={onPress}
              >
                <Text
                  style={[
                    global_styles.nextButtonText,
                    styles.textColor(labelColor),
                  ]}
                >
                  {labelText || NEXT_BUTTON_TEXT}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </InputAccessoryView>
    )
  }
  return null
}
