import { useState, useEffect, memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { View, TouchableOpacity, TextInput } from 'react-native'
import { Entypo } from '@expo/vector-icons'

import LabelField from '../LabelField'
import styles from './styles'
import { i18n } from '$localization/config.js'

const DEFAULTS = {
  COUNT: 1,
  PLACEHOLDER_TEXT_COLOR: 'black',
}
const TEXT_INPUT_CONSTANTS = {
  TEXT_ALIGN: 'center',
  KEYBOARD_TYPE: 'numeric',
  RETURN_KEY_TYPE: 'done',
}
const ENTYPO_CONSTANTS = {
  MINUS: 'circle-with-minus',
  PLUS: 'circle-with-plus',
  SIZE: 24,
}

function NumberField({
  masterQuestion,
  answer = '',
  storeFieldSurveyAnswers = () => {},
  colorSettings = {},
  hasVariableButtons = false,
}) {
  const [count, setCount] = useState('')

  useEffect(() => {
    if (!answer) return
    setCount(answer.answer)
  }, [answer.answer])

  const { question_label: questionLabel, translation_key: translationKey } =
    masterQuestion

  const labelHandler = (label) => {
    let newLabel = <View />
    if (!label) return newLabel

    newLabel = (
      <LabelField
        title={label}
        colorSettings={colorSettings}
        masterQuestion={masterQuestion}
      />
    )

    return newLabel
  }

  const subtractCount = () => {
    if (count <= 1) return

    setCount((prevCount) => prevCount + DEFAULTS.COUNT)
  }

  const addCount = () => {
    setCount((prevCount) => prevCount - DEFAULTS.COUNT)
  }

  const changeTextHandler = useCallback(
    (countValue) => {
      const payload = {
        master_question_id: masterQuestion.id,
        master_question_choice_id: null,
        answer: countValue,
      }

      storeFieldSurveyAnswers(payload)
      setCount(countValue)
    },
    [masterQuestion, setCount]
  )

  const memoizedCount = useMemo(() => count, [count])

  const title = labelHandler(i18n.t(questionLabel).default || questionLabel)

  const placeHolderTranslation = i18n.t(translationKey).placeholder || ''
  const placeHolderTextColor =
    colorSettings?.text_input_placeholder_color_2 ||
    DEFAULTS.PLACEHOLDER_TEXT_COLOR

  return (
    <View style={styles.container}>
      {title}
      <View style={styles.mainContainer}>
        <View style={styles.outerInputContainer}>
          {hasVariableButtons && (
            <TouchableOpacity
              style={styles.incrementButton}
              onPress={subtractCount}
            >
              <Entypo
                name={ENTYPO_CONSTANTS.MINUS}
                size={ENTYPO_CONSTANTS.SIZE}
                color={colorSettings?.selectable_btn_text_active_1}
              />
            </TouchableOpacity>
          )}

          <View style={styles.inputContainer(colorSettings)}>
            <TextInput
              value={memoizedCount}
              textAlign={TEXT_INPUT_CONSTANTS.TEXT_ALIGN}
              style={styles.input(colorSettings)}
              placeholder={placeHolderTranslation}
              onChangeText={changeTextHandler}
              keyboardType={TEXT_INPUT_CONSTANTS.KEYBOARD_TYPE}
              returnKeyType={TEXT_INPUT_CONSTANTS.RETURN_KEY_TYPE}
              placeholderTextColor={placeHolderTextColor}
            />
          </View>
          {hasVariableButtons && (
            <TouchableOpacity style={styles.decrementButton} onPress={addCount}>
              <Entypo
                name={ENTYPO_CONSTANTS.PLUS}
                size={ENTYPO_CONSTANTS.SIZE}
                color={colorSettings?.selectable_btn_text_active_1}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

export default memo(NumberField)

// ** typescript reference **

// NumberField.propTypes = {
//   masterQuestion: PropTypes.isRequired,
//   colorSettings: PropTypes.objectOf(PropTypes.string),
//   answer: PropTypes.number,
//   hasVariableButtons: PropTypes.bool,
//   storeFieldSurveyAnswers: PropTypes.func,
// }

// NumberField.defaultProps = {
//   colorSettings: {},
//   hasVariableButtons: false,
//   answer: '',
//   storeFieldSurveyAnswers: () => {},
// }
