import { apiFetchHandler } from '$api'

const fetchCheckIfUserHasEnteredEmail = async (userUUID) => {
  const getIfUserHasEmailEntered = {
    path: `patient_account/${userUUID}/validate_email/`,
    method: 'get',
    pageDetails: {
      page: 'CustomCreatePassword.js',
    },
  }

  return apiFetchHandler(getIfUserHasEmailEntered)
}

const fetchCheckPassword = async (userUUID) => {
  const passwordCheckPayload = {
    path: `patient_account/${userUUID}/check_password/`,
    method: 'get',
    pageDetails: {
      page: 'Login.controller.js',
    },
  }

  return apiFetchHandler(passwordCheckPayload)
}

const fetchClearOnboardingInfo = async (authCredentials, account) => {
  const clearOnboardingPayload = {
    path: `onboarding/clear/`,
    method: 'post',
    token: authCredentials.accessToken,
    pageDetails: {
      page: 'CustomCreatePassword.js',
    },
    body: {
      account_id: account?.accountID,
    },
  }

  return apiFetchHandler(clearOnboardingPayload)
}

export {
  fetchCheckIfUserHasEnteredEmail,
  fetchCheckPassword,
  fetchClearOnboardingInfo,
}
