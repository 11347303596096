import apiFetchHandler from '$api/apiFetchHandler'
import { DOMAIN_CLIENT } from '$utils/globalVariables.js'

export async function fetchFamilyClinician(
  familyID,
  authCredentials,
  screenName
) {
  const payload = {
    path: `family/${familyID}/clinicians/`,
    method: 'get',
    token: authCredentials?.accessToken,
    pageDetails: {
      page: 'SurveyScreen.js',
    },
  }
  const response = await apiFetchHandler(payload)

  return response
}

export async function fetchFamilySurvey(authCredentials) {
  const payload = {
    path: 'family/surveys/',
    method: 'post',
    token: authCredentials?.accessToken,
    body: {
      domain_client: DOMAIN_CLIENT?.toUpperCase(),
    },
    pageDetails: {
      page: 'SurveyScreen.js',
    },
  }
  const response = await apiFetchHandler(payload)

  return response
}

export async function clinicianCheck(accessToken, clinicianCode) {
  const clinicianCheckPayload = {
    path: 'clinician/check/',
    method: 'post',
    token: accessToken,
    body: {
      clinician_code: clinicianCode,
    },
    pageDetails: {
      page: 'SurveyScreen.js',
    },
  }

  const response = await apiFetchHandler(clinicianCheckPayload)

  return response
}
