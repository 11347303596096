import { apiFetchHandler } from '$api'
import { store } from '$redux/configureStore'

export const mutateUpdatePersonalInfo = async ({ payload }) => {
  const { authCredentials = '' } = store.getState().accountStore

  const updatePersonalInfoPayload = {
    path: 'member/update_personal_info/',
    method: 'post',
    token: authCredentials.accessToken,
    body: payload,
    pageDetails: {
      page: 'PersonalGenderIdentity.js',
    },
  }

  const response = await apiFetchHandler(updatePersonalInfoPayload)

  return response
}
