import { useNavigation } from '@react-navigation/core'
import { useLayoutEffect, useState } from 'react'
import { SafeAreaView, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '$hooks/useAuth'
import { i18n } from '$localization/config'
import { saveLastScreen } from '$screens/utils'

import { DOMAIN_CLIENT_NAME } from '$utils/globalVariables.js'
import { NAVIGATORS } from '$navigation/constants/routes'

import { setError, setShowLoadingView } from '$redux/defaults/actions'

import NextButtonAbsolute from '$components/NextButtonAbsolute'
import FormFields from './components/FormFields'
import HeaderText from './components/HeaderText'

import styles from './styles'
import { errorResponseHandler } from '../authorizationHelpers'
import {
  fetchCheckIfUserHasEnteredEmail,
  fetchCheckPassword,
  fetchClearOnboardingInfo,
} from './fetchHandlers'

function SwitchToFamgenixAccount() {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const {
    handleSetUserCredentials,
    checkPasswordValidity,
    checkEmailAvailability,
  } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hasUserPassword, setHasUserPassword] = useState(false)
  const [hasUserEnteredEmail, setHasUserEnteredEmail] = useState(false)

  const userUUID = useSelector((state) => state.store.inviteID)
  const { authCredentials = null, account = null } = useSelector(
    (state) => state.accountStore
  )

  const saveShowLoadingView = (data) => dispatch(setShowLoadingView(data))
  const saveError = (data) => dispatch(setError(data))

  const checkPassword = async () => {
    if (!userUUID) return setHasUserPassword(true)

    try {
      const response = await fetchCheckPassword(userUUID)

      if (response.isError) {
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }

      setHasUserPassword(response)
    } catch (error) {
      console.log(
        '🚀 \n\n file: customCreatePassword.js:188 \n\n checkPassword \n\n err:',
        error
      )
    }
  }

  const checkIfUserHasEnteredEmail = async () => {
    if (!userUUID) return

    let response
    try {
      response = await fetchCheckIfUserHasEnteredEmail(userUUID)

      if (response?.isError) {
        return errorResponseHandler(response, saveError)
      }
    } catch (err) {
      console.log(
        '🚀 \n\n file: customCreatePassword.js:188 \n\n checkIfUserHasEmailEntered \n\n err:',
        err
      )
    }

    const { validation } = response

    if (!validation) return

    setEmail(response.email)
    setHasUserEnteredEmail(true)
  }

  const clearOnboardingInfo = async () => {
    try {
      const response = await fetchClearOnboardingInfo(authCredentials, account)

      if (response.isError) {
        return saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmitButton = async () => {
    if (hasUserPassword) {
      await clearOnboardingInfo()
      return navigation.navigate(NAVIGATORS.Main.name)
    }

    saveShowLoadingView(true)

    const isPasswordValid = checkPasswordValidity(password)

    if (!isPasswordValid) return saveShowLoadingView(false)

    try {
      if (!hasUserEnteredEmail) {
        const isEmailValid = await checkEmailAvailability(email)

        if (!isEmailValid) return saveShowLoadingView(false)
      }

      const setPasswordPayload = {
        ...(!hasUserEnteredEmail && { email }),
        password,
      }

      const authCredentialsPayload = {
        accountID: authCredentials?.account_id,
        accessToken: authCredentials?.accessToken,
        refreshToken: authCredentials?.refreshToken,
      }

      const setCredentialsResponse = await handleSetUserCredentials(
        userUUID,
        setPasswordPayload,
        authCredentialsPayload
      )
      const screen = {
        last_screen: '',
      }

      if (setCredentialsResponse?.isError) {
        saveError({
          isShown: true,
          status: setCredentialsResponse.status,
          message: setCredentialsResponse.message,
        })
        return
      }

      await saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })
      await clearOnboardingInfo()
      navigation.navigate(NAVIGATORS.Main.name)
    } catch (error) {
      console.log(error)
    } finally {
      saveShowLoadingView(false)
    }
  }

  useLayoutEffect(() => {
    checkIfUserHasEnteredEmail().catch((error) =>
      saveError({
        isShown: true,
        message: error.message,
      })
    )
    checkPassword().catch((error) =>
      saveError({
        isShown: true,
        message: error.message,
      })
    )
  }, [])

  return (
    <SafeAreaView style={styles.safeAreaStyles}>
      <ScrollView contentContainerStyle={styles.screenContentContainer}>
        <HeaderText hasUserPassword={hasUserPassword} />

        {!hasUserPassword && (
          <FormFields
            hasUserEnteredEmail={hasUserEnteredEmail}
            password={password}
            setEmail={setEmail}
            email={email}
            setPassword={setPassword}
          />
        )}
      </ScrollView>

      <NextButtonAbsolute
        buttonText={
          hasUserPassword
            ? i18n.t('continue_to_client_name.default', {
                clientName: DOMAIN_CLIENT_NAME,
              })
            : i18n.t('create_password.default')
        }
        isDisabled={
          (!hasUserPassword && password.length === 0) ||
          (!hasUserPassword && password.length <= 7)
        }
        onPressAction={handleSubmitButton}
      />
    </SafeAreaView>
  )
}

export default SwitchToFamgenixAccount
