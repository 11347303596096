import { DOMAIN_CLIENT } from '$utils/globalVariables.js'

const oneMinute = 60000
export const REFRESH_INTERVAL = __DEV__ ? oneMinute * 5 : oneMinute

export const APP_SETTINGS_PAYLOAD = {
  path: 'domain_client/app_settings/',
  method: 'post',
  body: {
    domain_client: DOMAIN_CLIENT?.toUpperCase(),
  },
  pageDetails: {
    page: 'AppDelegate.js',
  },
}

export const LIGHT_GRAY = '#ccc'
