import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Image, ScrollView, Text, View } from 'react-native'

import { i18n } from '$localization/config.js'

import {
  setIsFirstLogin,
  setShouldProceedToMain,
  setShowLoadingView,
} from '$redux/defaults/actions.js'

import { saveLastScreen } from '$screens/utils'
import ContinueButton from './components/ContinueButton'

import {
  getFirstWorkflowScreen,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { CUSTOM_FLOW_ROUTES, FLOWS } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import saveClinician from './fetchHandler'
import styles from './styles'

import { EMPTY_CLINICIAN_ID } from './constants'

export default function SuccessScreenAccountCreated({
  route,
  saveError,
  navigation,
}) {
  const dispatch = useDispatch()
  const setIsLoadingViewShown = (data) => dispatch(setShowLoadingView(data))

  const message = [
    i18n.t('your_account_has_successfully_been_created')?.default,
    i18n.t('now_lets_get_some_information_about_your_health')?.default,
    i18n.t(
      'dont_worry_if_you_dont_know_the_information_-_you_can_enter_it_later'
    )?.default,
  ].join('\n\n')

  const congratulationMessage = i18n.t('congratulations')?.default
  const continueText = i18n.t('continue')?.default

  const {
    colorSettings,
    shouldProceedToMain,
    appSettings,
    authCredentials,
    account,
  } = useSelector((state) => ({
    colorSettings: state.store.colorSettings,
    shouldProceedToMain: state.store.shouldProceedToMain,
    appSettings: state.store.appSettings,
    authCredentials: state.accountStore.authCredentials,
    account: state.accountStore.account,
  }))

  const isClinicianCodeID = route.params?.clinicianCodeID ?? EMPTY_CLINICIAN_ID

  const fetchClinician = async () => {
    const clinician = appSettings?.automatic_opt_in_clinician

    if (!clinician) return

    const headers = authCredentials
    const payload = {
      clinician_code: clinician.code,
    }

    setIsLoadingViewShown(true)
    await saveClinician(payload, headers, dispatch)
  }

  useEffect(() => {
    fetchClinician().catch((error) => console.log(error))
  }, [])

  const nextButtonAction = async () => {
    if (shouldProceedToMain) {
      dispatch(setShouldProceedToMain(false))
      dispatch(setIsFirstLogin(true))
      navigation.navigate(FLOWS?.MainStack?.name)

      return
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: {
        last_screen: RAW_LAST_VISITED_SCREENS?.account_created_screen,
      },
      saveError,
      navigation,
    })

    const firstWorkflowScreen = await getFirstWorkflowScreen({
      id: isClinicianCodeID,
      fileName: 'SuccessScreenAccountCreated.js',
      defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender?.name,
      lastVisitedScreen: '',
      isShortCustomWorkflow: false,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: firstWorkflowScreen,
      selectedScreen: '',
      params: {
        nextPage: 0,
        prevPage: 0,
        navEditMode: false,
        navCompletedSurvey: null,
      },
    })
  }

  return (
    <View style={styles.container(colorSettings)}>
      <ScrollView>
        <View style={styles.messageContentContainer}>
          <Image
            source={require('$assets/images/icon/success/badge.png')}
            style={styles.image}
          />

          <Text style={styles.titleText(colorSettings)}>
            {congratulationMessage}
          </Text>

          <Text style={styles.bodyText(colorSettings)}>{message}</Text>

          <View style={styles.nextButtonContainer}>
            <ContinueButton
              nextButtonAction={nextButtonAction}
              colorSettings={colorSettings}
              continueText={continueText}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
