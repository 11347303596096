import moment from 'moment'
import { i18n } from '$localization/config.js'
import { handleRelationshipName } from '../helpers/helper'

import { formatDisplayedMemberName } from '$utils'

const generateDiseases = (diseases) =>
  diseases
    .map((disease) => {
      const ageDiagnosed = disease.age_diagnosed_string ?? '?'
      return `<div style="color: rgb(102,102,102);
        font-size: 14px;
        padding-left: 60px;
        font-family: 'Montserrat', sans-serif;
        padding-bottom: 3px;
        padding-top: 3px;">
    ${`${disease.disease_short_name}, ${ageDiagnosed}`}
  </div>`
    })
    .join('')

const generateGenes = (genes) => {
  const positiveGenesData = genes.filter(
    (gene) => gene.result !== 'n' && gene.result !== 'ln'
  )
  const userHasAnyPositiveGenes = positiveGenesData?.length > 0
  const hasNegativeGeneticTestingResults = genes.some(
    (gene) => gene.result === 'n' || gene.result === 'ln'
  )
  const negativeResult = {
    umls_name: i18n.t('negative_genetic_testing.default'),
    result: 'n',
  }

  const genesData = userHasAnyPositiveGenes
    ? positiveGenesData
    : hasNegativeGeneticTestingResults && [negativeResult]

  const renderGeneticResultText = (item) => {
    if (!item?.result) return

    switch (item.result) {
      case 'unsure':
        return `${item.umls_name} - ${i18n.t('unsure.title')}`
      case 'vus':
        return `${item.umls_name} - VUS`
      case 'n':
        return `${item.umls_name}`
      default:
        return `${item.umls_name} - ${i18n.t('positive').lowercase}`
    }
  }

  if (!genesData) return ''

  return genesData
    .map((gene) => {
      const geneName = renderGeneticResultText(gene)
      return `<div style="color: rgb(102,102,102);
        font-size: 14px;
        padding-left: 60px;
        font-family: 'Montserrat', sans-serif;
        padding-bottom: 3px;
        padding-top: 3px;">
    ${geneName}
  </div>`
    })
    .join('')
}

const generateUser = (section) =>
  section.data
    .map((member) => {
      if (!member || member.relationship_to_proband === 'partner') return
      const familyMemberNameWhenFirstNameEmpty = member?.first_name
        ? `(${member?.first_name})`
        : ''

      const familyMemberNameWithRelationship = `${handleRelationshipName(
        member
      )} ${familyMemberNameWhenFirstNameEmpty}`

      const isMemberProband = member.relationship_to_proband === 'proband'

      const userName = isMemberProband
        ? `${member?.first_name} ${member.last_name}`
        : `${familyMemberNameWithRelationship}`

      if (!member.genes || !member.diseases) return ''

      const userAge = member?.age_string ?? member?.age

      const UNKNOWN_AGE = '?'
      const memeberIsDead = member.is_dead

      const memberNameDisplayed = formatDisplayedMemberName(
        userName,
        userAge ?? UNKNOWN_AGE,
        memeberIsDead
      )

      return `<div style="color: rgb(65,70,97);
        font-size: 16px;
        padding-left: 30px;
        font-family: 'Karla', sans-serif;
        padding-bottom: 3px;
        padding-top: 3px;">
    ${memberNameDisplayed}
  </div>
    ${generateDiseases(member.diseases)}
    ${generateGenes(member.genes)}`
    })
    .join('')

const generateContext = (data) =>
  data
    .map(
      (el) => `
  <div style="font-size: 18px;
        margin-top: 20px;
        color: rgb(65,70,97);
        font-family: 'Karla', sans-serif;
        padding-top: 5px;
        padding-bottom: 5px;" id="sectionTitle">
    ${i18n.t(el?.title).default}
  </div>
    ${generateUser(el)}`
    )
    .join('')

const GenerateHtmlTemplate = (familySummaryData) => `
  <!doctype html>
  <html>
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Karla:wght@700&family=Montserrat:wght@500&display=swap"
          rel="stylesheet">
    <meta charset="UTF-8">
    <meta name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <style>
        @page {
            margin: 20px;
        }
    </style>
  </head>
  <body id="content">
    <div style="background-color: #ffffff;">
      ${generateContext(familySummaryData)}
    </div>
    <div style="margin-top: 30px;
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;" >
      Generated on ${moment().format('ll')} by FamGenix
    </div>
  </body>
  </html>
`

export { GenerateHtmlTemplate }
