// TODO: Refactor this screen to have cleaner code
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Dimensions, SafeAreaView, ScrollView, View } from 'react-native'

import LoadingView from '$components/LoadingView'

import { currentRegion, i18n } from '$localization/config.js'
import { styles } from './styles.main'
import AppInfo from '../AppInfo'
import {
  setBottomTabBarTitles,
  setError,
  setLanguage,
  setIsOnboarding,
} from '$redux/defaults/actions.js'
import { apiFetchHandler } from '$api'

import ROUTES from '$navigation/constants/routes'
import ClinicianLogo from './components/ClinicianLogo'
import ClinicianWelcomeText from './components/ClinicianWelcomeText'
import ClinicianWelcomeButton from './components/ClinicianWelcomeButton'
import LogoView from './components/LogoView'
import { errorResponseHandler, resizeFamgenixLogo } from './helpers/helpers'
import { IS_WEB } from '$constants/platforms'

const window = Dimensions.get('window')

function WelcomeScreen({ navigation }) {
  const dispatch = useDispatch()
  const setCurrentLanguage = (data) => dispatch(setLanguage(data))
  const resetBottomTabBarTitles = () => dispatch(setBottomTabBarTitles())
  const saveError = (data) => dispatch(setError(data))
  const saveIsOnboarding = (data) => dispatch(setIsOnboarding(data))

  const colorSettings = useSelector((state) => state.store.colorSettings)
  const colorSplashSettings = useSelector((state) => state.store.splashSettings)
  const selectedLanguagee = useSelector((state) => state.store.selectedLanguage)
  const userUUID = useSelector((state) => state.store.inviteID)

  const [welcomeScreenText, setWelcomeScreenText] = useState('')
  const [welcomeScreenClinicianLogo, setWelcomeScreenClinicianLogo] =
    useState('')
  const [dimensions, setDimensions] = useState(window)
  const [logoSizer, setLogoSizer] = useState({})
  const [selectedLanguage, setSelectedLanguage] = useState(
    selectedLanguagee ?? 'en-US'
  )
  const [isLoading, setIsLoading] = useState(true)

  const isDesktopBrowser = dimensions.height > 970

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', () => {
      setDimensions(Dimensions.get('window'))
    })

    return () => subscription?.remove()
  })

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      saveIsOnboarding(true)
    })

    return unsubscribe
  }, [navigation])

  const onChangeLanguage = (lang) => {
    const newLocale = `${lang}-${currentRegion}`
    i18n.locale = newLocale

    if (IS_WEB()) {
      localStorage?.setItem('currentLanguage', i18n.locale)
    }

    setCurrentLanguage(lang)
    resetBottomTabBarTitles()
  }

  const openLanguageSettings = () => {
    navigation.navigate('SettingsNavigator', {
      screen: ROUTES.SelectLanguage.name,
      onLanguageSelected: (item) => onChangeLanguage(item),
    })
  }

  const handleStartSurveyClick = () => {
    navigation.navigate(ROUTES.CustomValidateDOB.name)
  }

  const getBasicWelcomeScreenInformation = async () => {
    if (!userUUID) return
    try {
      const getOrganizationTextAndLogo = {
        path: `patient_account/${userUUID}/get_org_welcome_data/`,
        method: 'get',
        pageDetails: {
          page: 'WelcomeScreen.js',
        },
      }

      const response = await apiFetchHandler(getOrganizationTextAndLogo)

      if (response.isError) {
        errorResponseHandler(response, saveError)
        throw new Error(response.error)
      }

      const { welcome_text, logo } = response

      setWelcomeScreenText(welcome_text)
      setWelcomeScreenClinicianLogo(logo)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(
        '🚀 \n\n file: WelcomeScreen.js:190 \n\n getBasicWelcomeScreenInformation \n\n error:',
        error
      )
    }
  }

  useEffect(() => {
    getBasicWelcomeScreenInformation()
  }, [userUUID])

  useEffect(() => {
    setLogoSizer(resizeFamgenixLogo(dimensions))
  }, [dimensions])

  if (isLoading) return <LoadingView />

  return (
    <SafeAreaView
      style={[
        styles.container,
        {
          backgroundColor: colorSplashSettings?.custom_welcome_backgroundColor,
        },
      ]}
    >
      <ScrollView contentContainerStyle={styles.subContainer}>
        <View style={styles.mainLogo}>
          <ClinicianLogo
            dimensions={dimensions}
            welcomeScreenClinicianLogo={welcomeScreenClinicianLogo}
          />

          <ClinicianWelcomeText welcomeScreenText={welcomeScreenText} />

          <ClinicianWelcomeButton
            dimensions={dimensions}
            handleStartSurveyClick={handleStartSurveyClick}
          />

          <View
            style={[
              styles.mainLogoInnerContainer,
              isDesktopBrowser && { flex: 0.7 },
            ]}
          >
            <LogoView logoSizes={logoSizer} />
          </View>
          <View style={styles.appInfoContainer}>
            <AppInfo openLanguageSettings={() => openLanguageSettings()} />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

export default WelcomeScreen
