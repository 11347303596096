import Alert from '$components/Alert'
import { i18n } from '$localization/config.js'

export const onPressDelete = (primaryAction, isGreatGrandparents) => {
  const alertTitle = i18n.t('confirm_delete')?.default
  const alertSubTitleGrandparentsPart = isGreatGrandparents
    ? i18n.t('the_other_great-grandparent_will_also_be_deleted')?.default
    : ''

  const alertSubTitle = `${
    i18n.t('are_you_sure_you_want_to_delete_this_member')?.default
  }? ${alertSubTitleGrandparentsPart}`

  return Alert.alert(alertTitle, alertSubTitle, [
    {
      text: i18n.t('yes')?.default,
      style: 'default',
      onPress: primaryAction,
    },
    { text: i18n.t('no')?.default, style: 'cancel' },
  ])
}
