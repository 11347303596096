import { i18n } from '$localization/config.js'
import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

const onlySpacesChecker = (string) => string?.trim()?.length === 0

export default function nameHandler(member, siblingsList = []) {
  if (siblingsList?.length === 0) {
    return {
      memberName: '',
      nameTextStyle: {},
    }
  }

  const existingMemberList = siblingsList?.find(
    (sibling) => sibling?.member_id === member?.id
  )

  const relationshipName = onlySpacesChecker(existingMemberList?.name)
    ? existingMemberList?.relationship.split(' ')
    : []

  const i18nRelationshipString =
    relationshipName?.length === 3
      ? `${relationshipName[0]?.toLowerCase()}_${relationshipName[1]}`
      : relationshipName[0]?.toLowerCase()

  const relationshipCount =
    relationshipName?.length === 3 ? relationshipName[2] : relationshipName[1]

  const siblingName = onlySpacesChecker(member?.name)
    ? `${i18n.t(i18nRelationshipString).default} ${relationshipCount ?? ''}`
    : `${i18n.t(member?.name).default ?? member?.name}`

  const nameTextStyle = existingMemberList?.relationship
    ? custom_styles.itemDetailsSubtitleText
    : custom_styles.memberListItemTitleText

  return {
    memberName: siblingName ?? '',
    nameTextStyle: nameTextStyle ?? {},
  }
}
