import { Platform, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  backButtonContainer: {
    paddingLeft: 20,
    width: 40,
  },
  backButton: { flexDirection: 'row', alignItems: 'center' },
  backButtonText: { fontSize: 16, paddingLeft: 5 },
  modalContainer: (colorSettings) => ({
    backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
    flex: 1.0,
    width: '100%',
  }),
  contentContainerHead: {
    height: 60,
    flexDirection: 'row',
    paddingHorizontal: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 1.0,
    borderColor: 'rgba(216,216,216,0.2)',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  textInputHead: {
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'left',
    // marginHorizontal: 17,
    width: '100%',
    ...Platform.select({
      web: {
        outlineColor: '#ccc',
      },
    }),
    maxWidth: 700,
  },
  cancelButton: {
    color: 'rgba(255,255,255,0.4)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  },
  itemButton: (isSelected) => ({
    height: 60,
    justifyContent: 'center',
    width: '100%',
    backgroundColor: isSelected && 'rgba(0,0,0,0.2)',
  }),
  itemText: (colorSettings) => ({
    paddingHorizontal: 16,
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'left',
    color: colorSettings?.text_input_title_color_2 || 'black',
  }),
  innerHeadContainer: {
    flexDirection: 'row',
    flex: 1,
    ...Platform.select({
      web: {
        justifyContent: 'space-between',
      },
    }),
  },
  inputContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        maxWidth: 760,
        minWidth: 100,
        width: '100%',
        alignSelf: 'center',
      },
    }),
  },
  modalBackground: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  doneButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  doneButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  ancestryList: {
    width: '100%',
    maxWidth: 700,
  },
})
export default styles
