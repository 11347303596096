import React, { forwardRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import CustomSaeTextInput from '$components/CustomSaeTextInput'
import styles from './styles'
import { i18n } from '$localization/config.js'
import { FIELD_NAMES } from './constants'
import { IS_WEB } from '$constants/platforms'

const LOGIN_SCREEN_INPUT_EMAIL = 'login_screen_input_email'
const LOGIN_SCREEN_INPUT_PASSWORD = 'login_screen_input_password'
const inputAccessoryViewID = 'SignupMainScreen'

const SignupCustomInput = forwardRef(
  (
    {
      handleInputChange,
      inputValue,
      inputName,
      inputLabel,
      onChangeRefAction,
      hasUerEmailEntered = false,
    },
    ref
  ) => {
    const colorSettings = useSelector((state) => state.store.colorSettings)
    const [isPasswordShown, setIsPasswordShown] = useState(true)

    const isPasswordInputField = inputName === FIELD_NAMES.PASSWORD
    const isEmailInputField = inputName === FIELD_NAMES.EMAIL

    const handleTabInputChange = (event) => {
      if (IS_WEB() && event.code === 'Tab') {
        event.preventDefault()
        onChangeRefAction()
      }
    }

    if (isPasswordInputField) {
      const showPasswordIcon = isPasswordShown
        ? require('$assets/images/icon/eye-close/black.png')
        : require('$assets/images/icon/eye/black.png')

      return (
        <View style={styles.inputContainer}>
          <View style={styles.passwordInputContainer}>
            <CustomSaeTextInput
              ref={ref}
              colorSettings={{
                borderBottomColor: colorSettings?.text_input_label_color_1,
                labelColor: colorSettings?.text_input_label_color_1,
                textInputFieldColor: colorSettings?.text_input_color_1,
              }}
              secureTextEntry={isPasswordShown}
              inputAccessoryViewID={inputAccessoryViewID}
              label={inputLabel}
              onChangeText={(value) => handleInputChange(inputName, value)}
              value={inputValue}
              nativeID={LOGIN_SCREEN_INPUT_EMAIL}
            />

            <View style={styles.showPasswordContainer(colorSettings)}>
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={() => setIsPasswordShown(!isPasswordShown)}
              >
                <Image
                  style={styles.showPasswordImage}
                  source={showPasswordIcon}
                />
              </TouchableOpacity>
            </View>
          </View>

          <Text style={styles.passwordHelperText(colorSettings)}>
            {
              i18n.t(
                'minimum_of_12_characters_one_uppercase_letter_one_lowercase_letter_one_number'
              )?.default
            }
          </Text>
        </View>
      )
    }

    return (
      <View style={styles.inputContainer}>
        <CustomSaeTextInput
          ref={ref}
          colorSettings={{
            borderBottomColor: colorSettings?.text_input_label_color_1,
            labelColor: colorSettings?.text_input_label_color_1,
            textInputFieldColor: colorSettings?.text_input_color_1,
          }}
          keyboardType={isEmailInputField ? 'email-address' : 'default'}
          onKeyPress={(event) => handleTabInputChange(event)}
          returnKeyType="next"
          onSubmitEditing={onChangeRefAction}
          inputAccessoryViewID={inputAccessoryViewID}
          label={inputLabel}
          disabled={hasUerEmailEntered}
          onChangeText={(value) => handleInputChange(inputName, value)}
          value={inputValue}
          nativeID={LOGIN_SCREEN_INPUT_PASSWORD}
        />
      </View>
    )
  }
)

export default SignupCustomInput
