const MATERNAL_SIDE = 'moms_side'
const PATERNAL_SIDE = 'dads_side'
const MATERNAL = 'maternal'
const PATERNAL = 'paternal'

export default function getFamilySide(relationship) {
  if (relationship.includes(MATERNAL)) {
    relationship.shift()
    return MATERNAL_SIDE
  }

  if (relationship.includes(PATERNAL)) {
    relationship.shift()
    return PATERNAL_SIDE
  }
}
