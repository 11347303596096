import { StyleSheet } from 'react-native'

import { FALLBACK_STYLES } from '../../constants'

const AMETHYST_COLOR = 'rgb(155,89,182)'

const styles = StyleSheet.create({
  learnMoreButtonContainer: {
    width: '100%',
    paddingHorizontal: 16,
    marginTop: 40,
  },
  bodyText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_account_text ?? FALLBACK_STYLES.BLACK_COLOR,
  }),
  learnMoreButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    color: AMETHYST_COLOR,
  },
})

export default styles
