import moment from 'moment'

const DATE_FORMAT_SHORT = 'll'
const TIME_FORMAT_SHORT = 'LT'
const DATE_TIME_FORMAT_LONG = 'lll'
const DEFAULT_FORMAT = null

const formatDateTimeCreated = (datetime) => {
  const today = new Date()
  const newDateTime = new Date(datetime)

  const dateToday = moment(today).format(DATE_FORMAT_SHORT)
  const newDate = moment(newDateTime).format(DATE_FORMAT_SHORT)

  let formattedDateTime = DEFAULT_FORMAT

  if (dateToday === newDate) {
    formattedDateTime = moment(newDateTime).format(TIME_FORMAT_SHORT)
  } else {
    formattedDateTime = moment(newDateTime).format(DATE_TIME_FORMAT_LONG)
  }

  return formattedDateTime
}

export default formatDateTimeCreated
