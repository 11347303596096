import {
  FemaleFairSkinTone,
  FemaleTanSkinTone,
  FemaleDarkSkinTone,
  FemaleDeceased,
  MaleFairSkinTone,
  MaleTanSkinTone,
  MaleDarkSkinTone,
  MaleDeceased,
  NonbinaryFairSkinTone,
  NonbinaryTanSkinTone,
  NonbinaryDarkSkinTone,
  NonbinaryDeceased,
} from '$components/SVGIcon'

const avatarComponents = {
  female: {
    fair: FemaleFairSkinTone,
    dark: FemaleDarkSkinTone,
    default: FemaleTanSkinTone,
    tan: FemaleTanSkinTone,
    deceased: FemaleDeceased,
  },
  male: {
    fair: MaleFairSkinTone,
    dark: MaleDarkSkinTone,
    default: MaleTanSkinTone,
    tan: MaleTanSkinTone,
    deceased: MaleDeceased,
  },
  'non-binary': {
    fair: NonbinaryFairSkinTone,
    dark: NonbinaryDarkSkinTone,
    default: NonbinaryTanSkinTone,
    tan: NonbinaryTanSkinTone,
    deceased: NonbinaryDeceased,
  },
}

export default function ProfileAvatar({ member, width, height, viewBox }) {
  const {
    member_avatar: memberAvatar,
    gender,
    gender_identity: genderIdentity,
    is_dead: isDead,
  } = member

  const GENDER_FEMALE = 'f'
  const GENDER_NAME_FEMALE = 'female'
  const GENDER_NAME_MALE = 'male'
  const avatarIconInstance = memberAvatar?.[0]
  const genderNameExpand =
    gender === GENDER_FEMALE ? GENDER_NAME_FEMALE : GENDER_NAME_MALE

  const avatarIconSkinTone =
    avatarComponents[avatarIconInstance?.icon]?.[avatarIconInstance?.skin_tone]

  const genderDefaultAvatar =
    genderIdentity?.length > 0
      ? avatarComponents[genderIdentity]?.default
      : avatarComponents[genderNameExpand]?.default

  const deceasedAvatar =
    genderIdentity?.length > 0
      ? avatarComponents[genderIdentity]?.deceased
      : avatarComponents[genderNameExpand]?.deceased

  const AvatarComponent = isDead
    ? deceasedAvatar
    : avatarIconSkinTone || genderDefaultAvatar || null

  if (!AvatarComponent) return null

  return <AvatarComponent width={width} height={height} viewBox={viewBox} />
}
