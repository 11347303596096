import { View, TouchableOpacity, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config'
import { PRONOUN_LIST } from '../../constants'
import styles from './styles'

function PronounOptions({ selectedPronoun, setSelectedPronoun }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View>
      {PRONOUN_LIST.map((item) => {
        const pronounName = `${item.name}`.replace('/', '_slash_').toLowerCase()
        const isSelected = selectedPronoun === item.value

        return (
          <TouchableOpacity
            style={
              isSelected
                ? styles.selectedPronounOption(colorSettings)
                : styles.pronounOptionsButton(colorSettings)
            }
            onPress={() => setSelectedPronoun(item.value)}
          >
            <Text style={styles.pronounOptionsButtonText(colorSettings)}>
              {i18n.t(pronounName).default}
            </Text>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

export default PronounOptions
