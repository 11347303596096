import { Platform } from 'react-native'
import { platforms } from '$constants'

const { WEB, IOS, ANDROID } = platforms

export const IS_MOBILE = () => Platform.OS === IOS || Platform.OS === ANDROID
export const IS_IOS = () => Platform.OS === IOS
export const IS_ANDROID = () => Platform.OS === ANDROID
export const IS_WEB = () => Platform.OS === WEB

export function IS_MOBILE_DEVICE_WEB_BROWSER() {
  const { userAgent, platform } = navigator

  const mobileDevices =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

  return mobileDevices.test(userAgent) || mobileDevices.test(platforms)
}
