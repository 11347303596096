export default function fixDiseaseName(
  diseaseName = '',
  isUmlsDisease = false
) {
  if (!diseaseName) return ''

  if (isUmlsDisease) return diseaseName

  const text = diseaseName
    .toLowerCase()
    .replace(/\//g, ' ')
    .replace(/[^\w-\s]/gi, '')
    .replaceAll(' ', '_')

  return text
}
