import { useEffect, useState } from 'react'
import {
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
} from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import ErrorBoundary from 'react-native-error-boundary'
import { useIsFocused } from '@react-navigation/native'

import { setError } from '$redux/defaults/actions.js'
import { setShowLoadingView } from '$redux/defaults/actions'
import { i18n } from '$localization/config.js'
import { DefaultFullHeight } from '$assets/styles/global.styles.js'
import styles from './styles'

import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import { saveLastScreen } from '$screens/utils'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import MainQuestion from '../components/MainQuestion'
import SubQuestion from '../components/SubQuestion'
import Chips from '../components/Chips'
import GenesAutocompleteComponent from '../components/GenesAutocompleteComponent'
import FlexContainer from '$components/FlexContainer'
import MainWrapper from '$components/MainWrapper'
import ButtonDone from '$components/ButtonDone'

import {
  addGeneTest,
  fetchUmlsGenes,
  removeGeneTest,
  saveGeneTestHelpers,
  loadMemberGeneTestHelpers,
} from './helpers'

import { platforms } from '$constants'
import {
  INCREMENTS,
  MORE,
  NO_INCREMENT,
  ERROR_MESSAGE,
  EMPTY_SELECTED_SCREEN,
  PADDING,
  HEIGHT,
  RESIZE,
  GENE_TEST_TYPE,
} from './constants'

export default function PositiveGeneTestOnboarding({ navigation }) {
  const dispatch = useDispatch()
  const isScreenFocused = useIsFocused()
  const saveError = (data) => dispatch(setError(data))
  const setLoadingView = (data) => dispatch(setShowLoadingView(data))
  const BUTTON_TITLE = i18n.t('next.default')

  const colorSettings = useSelector((state) => state.store.colorSettings)
  const geneTestFromOnboarding = useSelector(
    (state) => state.geneTestingStore.fromOnboarding
  )
  const authCredentials = useSelector(
    (state) => state.accountStore.authCredentials
  )
  const selectedClinician = useSelector(
    (state) => state.clinicianStore.selectedClinician
  )
  const probandProfile = useSelector(
    (state) => state.probandStore.probandProfile
  )

  const account = useSelector((state) => state.accountStore.account)
  const lastScreenLogin = useSelector((state) => state.store.lastScreenLogin)
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)

  const [startCount, setStartCount] = useState(0)
  const [mainQuestionValue, setMainQuestionValue] = useState(null)
  const [loadSubQuestion, setLoadSubQuestion] = useState(false)
  const [subQuestionValue, setSubQuestionValue] = useState(null)
  const [loadGeneList, setLoadGeneList] = useState(false)
  const [disableDoneButton, setDisableDoneButton] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [scrollViewHeight, setScrollViewHeight] = useState(DefaultFullHeight())
  const [selectedGeneTest, setSelectedGeneTest] = useState([])
  const [onLoadSelectedGenes, setOnLoadSelectedGenes] = useState([])
  const [UMLSGenesList, setUMLSGenesList] = useState([])

  const screenResizeHandler = () => {
    const height = DefaultFullHeight()
    setScrollViewHeight(height)
  }

  const loadUMLSGenes = async (gene, moreButton) => {
    const isMoreButtonSelected = moreButton === MORE
    const count = isMoreButtonSelected ? startCount + INCREMENTS : NO_INCREMENT

    const response = await fetchUmlsGenes({
      gene,
      count,
      authCredentials,
      saveError,
    })

    const UMLSGenes = response.ConceptList

    const filteredGenes = UMLSGenes.map((item) => {
      const isSelectedGenes = selectedGeneTest.some(
        (selectedGene) => selectedGene.CUI === item.CUI
      )
      return { ...item, isSelected: isSelectedGenes }
    })

    setUMLSGenesList(
      isMoreButtonSelected
        ? (prevState) => [...prevState.slice(0, -2), ...filteredGenes]
        : filteredGenes
    )

    setStartCount(count)
  }
  const handleMainQuestion = (value) => {
    setSubQuestionValue(null)
    setLoadGeneList(false)
    setSelectedGeneTest([])

    switch (value) {
      case true:
        setMainQuestionValue(true)
        setLoadSubQuestion(true)
        setDisableDoneButton(true)

        break
      case false:
        setMainQuestionValue(false)
        setLoadSubQuestion(false)
        setDisableDoneButton(false)
        break
      default:
        setDisableDoneButton(true)
    }
  }
  const handleSubQuestion = (value) => {
    setDisableDoneButton(false)
    setSelectedGeneTest([])

    switch (value) {
      case true:
        setSubQuestionValue(true)
        setLoadGeneList(true)
        break
      case false:
        setSubQuestionValue(false)
        setLoadGeneList(false)
        break
      default:
        setDisableDoneButton(true)
    }
  }
  const handleDeleteSelectedGene = (itemSelected) => {
    const filteredSelectedGeneTest = selectedGeneTest.filter(
      (gene) => gene.CUI !== itemSelected.CUI
    )

    const selectedGenesList = UMLSGenesList.map((item) => ({
      ...item,
      isSelected: item.CUI === itemSelected.CUI ? false : item.isSelected,
    }))

    setUMLSGenesList(selectedGenesList)
    setSelectedGeneTest(filteredSelectedGeneTest)
  }
  const handleSelectedGeneTest = (itemSelected) => {
    const isGeneSelected = itemSelected.isSelected

    const selectedGenes = UMLSGenesList.map((item) => ({
      ...item,
      isSelected:
        item.CUI === itemSelected.CUI ? !isGeneSelected : item.isSelected,
    }))

    setUMLSGenesList(selectedGenes)
    setSelectedGeneTest((prevState) => [...prevState, itemSelected])

    if (isGeneSelected) handleDeleteSelectedGene(itemSelected)
  }

  const loadMemberGeneTests = async () => {
    setLoadingView(true)

    const fetchMemberProfilePayload = {
      authCredentials,
      probandProfile,
      selectedLanguage,
      saveError,
    }
    const fetchOnboardingMemberDetailsPayload = {
      account,
      authCredentials,
      saveError,
    }

    const { filterGeneList, handleGeneTest, fetchDetails } =
      loadMemberGeneTestHelpers

    try {
      const { onboardingMemberDetails, memberGeneTest } = await fetchDetails({
        fetchMemberProfilePayload,
        fetchOnboardingMemberDetailsPayload,
      })

      const filteredMemberGeneList = filterGeneList({
        memberGeneTest,
        GENE_TEST_TYPE,
      })

      if (onboardingMemberDetails.genetic_testing_selected) {
        handleMainQuestion(true)
        handleSubQuestion(filteredMemberGeneList.length > 0)
        handleGeneTest({
          filteredMemberGeneList,
          handleSelectedGeneTest,
          setOnLoadSelectedGenes,
        })
      } else {
        handleMainQuestion(onboardingMemberDetails.genetic_testing_selected)
      }
    } catch (error) {
      console.log(ERROR_MESSAGE.loadMemberGeneTests, error)
    } finally {
      setLoadingView(false)
    }
  }

  const componentMountHandler = async () => {
    setIsLoading(true)

    let hasGenderIdentity = false
    const isLastScreenBeforeLogin =
      lastScreenLogin === RAW_LAST_VISITED_SCREENS.gene_testing

    try {
      if (platforms.WEB === Platform.OS) {
        window.addEventListener(RESIZE, screenResizeHandler)
      }

      if (probandProfile?.profileData?.genderIdentity) {
        hasGenderIdentity = true
      }

      navigation.setParams({
        hasGenderIdentity,
        isLastScreenBeforeLogin,
      })

      await loadMemberGeneTests()
    } catch (error) {
      console.log(ERROR_MESSAGE.componentMountHandler, error)
    } finally {
      setIsLoading(false)
    }
  }

  const saveGeneTestHandler = async () => {
    const {
      getRaceEthnicityDisplay,
      getRouteName,
      getSavedAndRemovedGeneTestData,
    } = saveGeneTestHelpers

    const displayedEthnicity = getRaceEthnicityDisplay(selectedClinician)
    const routeName = getRouteName(displayedEthnicity)
    const nextRoute = getNextWorkflowRoute({ defaultNextRoute: routeName })
    const { savedGeneTestData, removedGeneTestData } =
      getSavedAndRemovedGeneTestData(selectedGeneTest, onLoadSelectedGenes)

    setLoadingView(true)

    await addGeneTest({
      savedGeneTestData,
      probandProfile,
      authCredentials,
      GENE_TEST_TYPE,
      setLoadingView,
      saveError,
    })

    await removeGeneTest({
      removedGeneTestData,
      probandProfile,
      authCredentials,
      setLoadingView,
      saveError,
    })

    const screen = {
      last_screen: RAW_LAST_VISITED_SCREENS.gene_testing,
      genetic_testing_selected: mainQuestionValue,
    }

    await saveLastScreen({
      account,
      authCredentials,
      item: screen,
      saveError,
      navigation,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: EMPTY_SELECTED_SCREEN,
    })

    setLoadingView(false)
  }

  const platformBehavior = Platform.OS === platforms.IOS ? PADDING : HEIGHT

  useEffect(() => {
    componentMountHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenFocused])

  return (
    <ErrorBoundary>
      <MainWrapper
        navigation={navigation}
        disableLogout={geneTestFromOnboarding}
      >
        <SafeAreaView style={styles.mainContainer(colorSettings)}>
          <FlexContainer style={styles.flexContainer(scrollViewHeight)}>
            <KeyboardAvoidingView
              behavior={platformBehavior}
              style={styles.container}
            >
              {!isLoading && (
                <ScrollView
                  style={styles.scrollViewContainer}
                  showsHorizontalScrollIndicator={false}
                >
                  <MainQuestion
                    handleMainQuestion={handleMainQuestion}
                    mainQuestionValue={mainQuestionValue}
                  />

                  {loadSubQuestion && (
                    <SubQuestion
                      handleSubQuestion={handleSubQuestion}
                      subQuestionValue={subQuestionValue}
                    />
                  )}

                  <Chips
                    handleDeleteSelectedGene={handleDeleteSelectedGene}
                    selectedGeneTest={selectedGeneTest}
                  />

                  {loadGeneList && (
                    <GenesAutocompleteComponent
                      handleSelectedGeneTest={handleSelectedGeneTest}
                      loadUMLSGenes={loadUMLSGenes}
                      UMLSGenesList={UMLSGenesList}
                    />
                  )}
                </ScrollView>
              )}
            </KeyboardAvoidingView>
          </FlexContainer>
          <ButtonDone
            colorSettings={colorSettings}
            onPress={() => saveGeneTestHandler()}
            title={BUTTON_TITLE}
            disable={disableDoneButton}
          />
        </SafeAreaView>
      </MainWrapper>
    </ErrorBoundary>
  )
}
