import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'
import {
  getFirstWorkflowScreen,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'
import {
  emailValidator,
  errorResponseHandler,
} from '$screens/onboarding/authorizationHelpers'
import Proband from '$data_models/Proband'
import { isEmptyObject, emailToLowerCase } from '$utils'
import {
  clinicianCheck,
  getOnboardingInfo,
} from '$screens/onboarding/customValidateDOB/helpers'
import AuthCredentials from '$data_models/AuthCredentials'
import Account from '$data_models/Account'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'
import { fetchWorkflow } from '$navigation/_utils/custom_workflow/helpers'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils'
import { i18n } from '$localization/config.js'
import { IS_MOBILE } from '$utils/Platforms'
import { checkBiometricPermissionDataEnrolled } from '$utils/Biometrics'
import useBiometrics from './useBiometrics'
import {
  createProband,
  fetchEmailAvailabilityCheck,
  fetchFamilyClinicianId,
  fetchOnboardingData,
  handleSavingAccountData,
  handleSetUserCredentials,
  mutateLoginCustomWorkflow,
  mutateLoginUser,
  mutateRegisterAccount,
  saveClinician,
  handleScreenSaving,
} from './useAuthHelpers/fetchHandlers'
import {
  dispatchHelpers,
  reduxStateHelpers,
} from './useAuthHelpers/reduxHelpers'
import {
  createAuthCredentialsPayload,
  generateProbandPayload,
  handleProbandFamilySaving,
  passwordValiditySchema,
} from './useAuthHelpers/helpers'
import { SECRET_SCREEN_USER } from './useAuthHelpers/constants'

const useAuth = () => {
  const {
    saveAuthCredentialsAction,
    saveUserId,
    saveProbandToStore,
    saveAccountId,
    saveIsGeneticallyTested,
    saveProbandToClinicianCode,
    saveAccountData,
    saveLastLoginScreen,
    saveError,
  } = dispatchHelpers()

  const { userUUID, patientData, appSettings } = reduxStateHelpers()

  const { askForBiometricsSave } = useBiometrics()

  const handleLoginCustomWorkflowUser = async (
    navigation,
    email,
    password,
    hasUserEmailEntered
  ) => {
    let lastScreen

    const payload = {
      inviteID: userUUID,
      dob: patientData?.dob,
    }

    try {
      const loginUserDataResponse = await mutateLoginCustomWorkflow(payload)

      if (loginUserDataResponse.isError) {
        return errorResponseHandler(loginUserDataResponse)
      }

      const authCredentialsPayload = createAuthCredentialsPayload(
        loginUserDataResponse
      )

      saveAuthCredentialsAction(authCredentialsPayload)

      const userID = loginUserDataResponse?.user_id
      const accountID = loginUserDataResponse?.account_id

      if (password) {
        const setPasswordPayload = {
          ...(!hasUserEmailEntered && { email }),
          password,
        }

        await handleSetUserCredentials(
          userUUID,
          setPasswordPayload,
          authCredentialsPayload
        )
      }

      saveAccountData(patientData)
      saveUserId(userID)
      saveAccountId(accountID)

      const clinicianId = await fetchFamilyClinicianId(
        loginUserDataResponse.family_id,
        authCredentialsPayload
      )

      await saveClinician(
        appSettings?.automatic_opt_in_clinician?.code,
        authCredentialsPayload,
        clinicianId
      )

      const response = await fetchOnboardingData(loginUserDataResponse)

      if (response.isError) {
        saveError({
          isShown: true,
          status: response.status,
          message: response.error,
        })
        lastScreen = null
        return
      }

      lastScreen = response.last_screen

      saveIsGeneticallyTested(response.genetic_testing_selected)
      saveLastLoginScreen(lastScreen)

      const probandProfile = new Proband()

      if (lastScreen) {
        await handleSavingAccountData(
          probandProfile,
          authCredentialsPayload,
          loginUserDataResponse,
          response
        )

        const routeToNavigate = await getFirstWorkflowScreen({
          id: userUUID,
          fileName: 'CustomValidateDOB.js',
          defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender.name,
          lastVisitedScreen: lastScreen,
          isShortCustomWorkflow: true,
        })

        setTimeout(() => {
          handleNavigateToNextScreen({
            navigation,
            routeName: routeToNavigate,
            selectedScreen: lastScreen,
          })
        }, 500)
      } else {
        probandProfile.probandID = loginUserDataResponse?.member_id
        probandProfile.familyID = loginUserDataResponse?.family_id

        saveProbandToStore(probandProfile)

        navigation.navigate('MainStack')
      }
    } catch (err) {
      console.log(
        '🚀 \n\n file: useAuth \n\n handleLoginCustomWorkflowUser \n\n err:',
        err
      )
    }
  }

  const checkAndNavigateToPasswordScreen = async () => {
    try {
      const response = await fetchWorkflow({
        id: userUUID,
        fileName: 'CustomValidateDOB.js',
      })

      return response?.hasForcedPassword
    } catch (error) {
      console.log(
        '🚀 \n\n file: useAuth \n\n checkAndNavigateToPasswordScreen \n\n err:',
        error
      )

      return false
    }
  }

  const createPatientAccount = async (
    navigation,
    setShowTermsPrivacy,
    email,
    password
  ) => {
    const payloadLogin = {
      inviteID: userUUID,
      email: email ?? patientData?.email,
      dob: patientData?.dob,
      password,
    }

    const payloadDOB = {
      inviteID: userUUID,
      dob: patientData?.dob,
    }

    const selectedPayload = email ? payloadLogin : payloadDOB

    try {
      const firstWorkflowScreen = await getFirstWorkflowScreen({
        id: userUUID,
        fileName: 'CustomValidateDOB.js',
        defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender.name,
        lastVisitedScreen: '',
        isShortCustomWorkflow: true,
      })

      const registerAccountData = await mutateRegisterAccount(selectedPayload)

      if (
        registerAccountData?.response ===
        'Account already exists for that patient id'
      ) {
        await handleLoginCustomWorkflowUser(navigation, email, password)
        setShowTermsPrivacy(false)

        return
      }

      if (registerAccountData.isError) {
        setShowTermsPrivacy(false)
        if (registerAccountData.status === 409 && !password) {
          return registerAccountData
        }

        return errorResponseHandler(registerAccountData, saveError)
      }

      const authCredentialsPayload =
        createAuthCredentialsPayload(registerAccountData)

      const userID = registerAccountData?.user_id
      const accountID = registerAccountData?.account_id

      saveAuthCredentialsAction(authCredentialsPayload)

      const probandDataPayload = generateProbandPayload(
        registerAccountData,
        patientData,
        email
      )

      const automaticOptInClinician = appSettings?.automatic_opt_in_clinician

      if (automaticOptInClinician && !isEmptyObject(automaticOptInClinician)) {
        await saveClinician(
          automaticOptInClinician?.code,
          authCredentialsPayload
        )

        saveProbandToClinicianCode(automaticOptInClinician?.code)
        probandDataPayload.proband.clinician_code =
          automaticOptInClinician?.code
      } else {
        await saveClinician(
          registerAccountData?.clinician_code,
          authCredentialsPayload
        )
        saveProbandToClinicianCode(registerAccountData?.clinician_code)
      }

      saveAccountData(patientData)
      saveUserId(userID)
      saveAccountId(accountID)
      saveProbandToStore(probandDataPayload.proband)

      await createProband(probandDataPayload, authCredentialsPayload)

      await handleScreenSaving(
        registerAccountData,
        authCredentialsPayload,
        'gender_selection'
      )

      setShowTermsPrivacy(false)

      handleNavigateToNextScreen({
        navigation,
        routeName: firstWorkflowScreen,
        selectedScreen: '',
        params: {
          nextPage: 0,
          prevPage: 0,
          navEditMode: false,
          navCompletedSurvey: null,
        },
      })
    } catch (error) {
      setShowTermsPrivacy(false)

      console.log(
        '🚀 \n\n file: useAuth \n\n createPatientAccount \n\n error:',
        error
      )
    }
  }

  const handleLoginUser = async (
    navigation,
    email,
    password,
    isInvitedUser = false
  ) => {
    const payload = {
      username: emailToLowerCase(email),
      email: emailToLowerCase(email),
      password,
      invite_id: userUUID,
    }

    const isPayloadWithSecretEmail = payload.email === SECRET_SCREEN_USER.EMAIL
    const isPayloadWithSecretPassword =
      payload.password === SECRET_SCREEN_USER.PASSWORD

    if (isPayloadWithSecretEmail && isPayloadWithSecretPassword) {
      return navigation.navigate('EnvSecretScreen')
    }

    try {
      const loginAccount = await mutateLoginUser(payload)

      if (loginAccount.isError) {
        return errorResponseHandler(loginAccount, saveError)
      }
      const biometricEnrolled = await checkBiometricPermissionDataEnrolled()

      if (IS_MOBILE() && biometricEnrolled) {
        await askForBiometricsSave(payload)
      }

      const loginAccountData = loginAccount?.account
      const authCredentials = new AuthCredentials(loginAccount?.token)
      saveAuthCredentialsAction(authCredentials)
      const accountData = new Account(loginAccountData)
      saveAccountData(accountData)

      const clinicianId = await fetchFamilyClinicianId(
        loginAccount.family_id,
        authCredentials
      )

      await saveClinician(
        appSettings?.automatic_opt_in_clinician?.code,
        authCredentials,
        clinicianId
      )

      const onboardingInfo = await getOnboardingInfo(
        accountData,
        authCredentials,
        saveError
      )

      let lastScreen = onboardingInfo.last_screen

      saveIsGeneticallyTested(onboardingInfo?.genetic_testing_selected)

      if (lastScreen === RAW_LAST_VISITED_SCREENS.sign_up_clinician) {
        const hasClinician = await clinicianCheck(
          loginAccount.family_id,
          authCredentials,
          saveError
        )

        if (hasClinician) {
          lastScreen = RAW_LAST_VISITED_SCREENS.account_created_screen
        }
      }

      saveLastLoginScreen(lastScreen)

      const probandProfile = new Proband()

      if (lastScreen) {
        await handleSavingAccountData(
          probandProfile,
          authCredentials,
          loginAccount,
          onboardingInfo
        )

        const routeToNavigate = await getFirstWorkflowScreen({
          id: userUUID ?? clinicianId,
          fileName: 'CustomValidateDOB.js',
          defaultFirstRoute: CUSTOM_FLOW_ROUTES?.PersonalGender.name,
          lastVisitedScreen: lastScreen,
          isShortCustomWorkflow: true,
        })

        return handleNavigateToNextScreen({
          navigation,
          routeName: routeToNavigate,
          selectedScreen: lastScreen,
        })
      }

      probandProfile.probandID = loginAccount.member_id
      probandProfile.familyID = loginAccount.family_id

      saveProbandToStore(probandProfile)

      return isInvitedUser
        ? navigation.navigate('SuccessScreenFamilyInvite')
        : navigation.navigate('MainStack')
    } catch (error) {
      console.log(
        '🚀 \n\n file: useAuth \n\n handleLoginUser \n\n error:',
        error
      )
    }
  }

  const checkPasswordValidity = (password) => {
    const isPasswordCorrect = passwordValiditySchema(password)

    if (!isPasswordCorrect) {
      showDefaultAlert(
        i18n.t('password_security')?.default,
        i18n.t(
          'your_password_does_not_meet_the_requirements_please_choose_a_more_secure_password'
        )?.default
      )
      return false
    }

    return true
  }

  const checkEmailAvailability = async (email) => {
    const emailIsValid = emailValidator(email)
    if (!emailIsValid) {
      showDefaultAlert(
        i18n.t('server_error')?.default,
        i18n.t('please_enter_a_valid_email')?.default
      )

      return false
    }

    const payload = {
      email,
    }

    try {
      const emailAvailabilityResponse = await fetchEmailAvailabilityCheck(
        payload
      )

      if (emailAvailabilityResponse.isError) {
        errorResponseHandler(emailAvailabilityResponse, saveError)
        return false
      }

      return true
    } catch (error) {
      console.log(
        '🚀 \n\n file: useAuth \n\n checkEmailAvailability \n\n error:',
        error
      )
    }
  }

  return {
    handleProbandFamilySaving,
    saveClinician,
    handleLoginCustomWorkflowUser,
    createPatientAccount,
    handleLoginUser,
    handleSetUserCredentials,
    checkEmailAvailability,
    checkPasswordValidity,
    checkAndNavigateToPasswordScreen,
  }
}

export default useAuth
