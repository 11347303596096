import apiFetchHandler from '$api/apiFetchHandler'

const fetchUmlsGenes = async ({ gene, count, authCredentials, saveError }) => {
  const getUmlsGenesPayload = {
    path: `umls/gene/?term=${gene}&start=${count}`,
    method: 'get',
    token: authCredentials.accessToken,
    pageDetails: {
      page: 'PositiveGeneTestOnboarding.js',
    },
  }

  const response = await apiFetchHandler(getUmlsGenesPayload)

  if (response?.isError) {
    saveError({
      isShown: true,
      status: response.status,
      message: response.error,
    })
    return
  }

  return response
}

export default fetchUmlsGenes
