import { useState, useEffect } from 'react'
import {
  View,
  Modal,
  SafeAreaView,
  Text,
  TouchableOpacity,
  Appearance,
} from 'react-native'

import moment from 'moment'
import DateTimePicker from '@react-native-community/datetimepicker'

import { i18n } from '$localization/config.js'
import { IS_IOS, IS_ANDROID } from '$constants/platforms'

import { fixedTimeZoneOffset } from '$utils'

import global_styles from '$assets/styles/global.styles.js'
import styles from './styles/main.styles'

import {
  MINIMUM_YEARS_AGO,
  MINIMUM_DATE_FOR_ANDROID,
  DEFAULT_COLOR_SCHEME,
  DATE_FORMAT,
  TIME_UNIT,
  DAY_UNIT,
  YEAR_UNIT,
  MINUTES_UNIT,
  NO_PREFERENCE,
  SET_EVENT_TYPE,
  DISMISS_TRUE,
  DATE_MODE,
  SPINNER_DISPLAY,
} from './constants'

const timezoneOffset = fixedTimeZoneOffset()

export default function CustomDatePicker({
  colorSettings,
  onDatePickerChange,
  selectedLanguage,
  maxDate: propMaxDate,
  minDate: propMinDate,
  date: propDate,
  visible: propVisible,
}) {
  const cancelButtonText = i18n.t('cancel').title
  const selectDateText = i18n.t('select_date').title
  const doneButtonText = i18n.t('done').title

  const formattedPropDate = propDate ? new Date(propDate) : new Date()
  const formattedPropMaxDate = propMaxDate ?? new Date()
  const formattedPropMinDate = propMinDate ?? new Date()

  const [maxDate, setMaxDate] = useState(formattedPropMaxDate)
  const [minDate, setMinDate] = useState(formattedPropMinDate)

  const [date, setDate] = useState(formattedPropDate)
  const [visible, setVisible] = useState(propVisible)
  const [showPickerModal, setShowPickerModal] = useState(false)
  const [colorScheme, setColorScheme] = useState(DEFAULT_COLOR_SCHEME)

  let localSelectedDate = date

  useEffect(() => {
    setVisible(propVisible)
  }, [propVisible])

  useEffect(() => {
    const formattedCurrentDate = moment(date).format(DATE_FORMAT)
    const formattedMaxDate = moment(maxDate).format(DATE_FORMAT)

    const offsetCurrentDate = moment(new Date(formattedCurrentDate))
      .add(timezoneOffset, TIME_UNIT)
      .add(1, DAY_UNIT)

    const offsetMaxDate = moment(new Date(formattedMaxDate)).add(
      timezoneOffset,
      TIME_UNIT
    )

    const formattedMinDate = moment(new Date(offsetMaxDate)).subtract(
      MINIMUM_YEARS_AGO,
      YEAR_UNIT
    )

    setDate(new Date(offsetCurrentDate))
    setMaxDate(new Date(offsetMaxDate))
    setMinDate(new Date(formattedMinDate))
  }, [])

  const getColorScheme = () => {
    const bgAppearance = Appearance.getColorScheme()
    if (bgAppearance !== NO_PREFERENCE || bgAppearance !== null) {
      return bgAppearance
    }
    return colorScheme
  }

  useEffect(() => {
    const bgAppearance = getColorScheme()
    setColorScheme(bgAppearance)

    setTimeout(() => {
      setShowPickerModal(true)
    }, 100)
  }, [])

  const onChangeAndroid = (e, selectedDate) => {
    if (e.type === SET_EVENT_TYPE) {
      const adjustedDate = selectedDate
      return onDatePickerChange({
        selectedDate: adjustedDate,
        dismiss: DISMISS_TRUE,
      })
    }

    return onDatePickerChange({ selectedDate: null, dismiss: DISMISS_TRUE })
  }

  const onChangeIOS = (e, selectedDate) => {
    const defaultDate = new Date(0)
    localSelectedDate = selectedDate ?? defaultDate

    const dateDiff = moment(minDate).diff(localSelectedDate, MINUTES_UNIT)
    if (dateDiff > 0) localSelectedDate = minDate

    setDate(localSelectedDate)
  }

  const onDoneIOS = () => {
    onDatePickerChange({ selectedDate: localSelectedDate, dismiss: true })
  }

  const onCancelIOS = () => {
    onDatePickerChange({ selectedDate: null, dismiss: true })
  }

  if (!visible) return null

  return (
    <SafeAreaView style={styles.container}>
      {IS_IOS() && (
        <Modal animationType="slide" visible={showPickerModal} transparent>
          {IS_IOS() && (
            <TouchableOpacity
              onPress={() => onCancelIOS()}
              activeOpacity={1.0}
              style={styles.modalContainer}
            />
          )}

          <View style={styles.pickerContainer(getColorScheme)}>
            <View style={styles.toolbarContainer}>
              <TouchableOpacity
                style={styles.toolbarButton}
                onPress={() => onCancelIOS()}
              >
                <Text style={styles.toolbarButtonText}>{cancelButtonText}</Text>
              </TouchableOpacity>

              <Text
                style={[global_styles.headerBarMainTitle, styles.titleStyle]}
              >
                {selectDateText}
              </Text>

              <TouchableOpacity
                style={styles.toolbarButton}
                onPress={() => onDoneIOS()}
              >
                <Text style={styles.toolbarButtonTextDone(colorSettings)}>
                  {doneButtonText}
                </Text>
              </TouchableOpacity>
            </View>

            <DateTimePicker
              minimumDate={minDate}
              maximumDate={maxDate}
              mode={DATE_MODE}
              display={SPINNER_DISPLAY}
              onChange={onChangeIOS}
              value={date}
              locale={selectedLanguage}
            />
          </View>
        </Modal>
      )}
      {IS_ANDROID() && (
        <DateTimePicker
          minimumDate={MINIMUM_DATE_FOR_ANDROID}
          maximumDate={maxDate}
          mode={DATE_MODE}
          display={SPINNER_DISPLAY}
          onChange={onChangeAndroid}
          value={date}
          locale={selectedLanguage}
        />
      )}
    </SafeAreaView>
  )
}
