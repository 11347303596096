import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  showPasswordButtonContainer: {
    marginLeft: -26,
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  showPasswordIcon: { height: 24, width: 24, resizeMode: 'contain' },
})

export default styles
