import { useSelector } from 'react-redux'
import { Text, View } from 'react-native'
import styles from '../../assets/styles'
import SearchInput from '../SearchInput'
import { PARENTS } from '../../index'
import { i18n } from '$localization/config.js'

function AncestryInputSection({
  parentSide,
  handleRemoveAncestry,
  ancestryList,
  handleOnModalPress,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const sectionTitle =
    parentSide === PARENTS.MOTHER
      ? i18n.t('what_is_your_mothers_ancestry').default
      : i18n.t('what_is_your_fathers_ancestry').default

  return (
    <View style={styles.searchInputMainContainer}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text style={styles.titleText(colorSettings)}>{sectionTitle}</Text>
        <Text style={styles.subTitleText(colorSettings)}>
          {i18n.t('select_all_that_apply').default}
        </Text>
      </View>
      <SearchInput
        parent={parentSide}
        onPress={(parent) => handleOnModalPress(parent)}
        ancestryData={ancestryList}
        onPressRemove={(ancestry) => handleRemoveAncestry(parentSide, ancestry)}
        colorSettings={colorSettings}
      />
    </View>
  )
}

export default AncestryInputSection
