import { View, Text } from 'react-native'

import { i18n } from '$localization/config.js'

/* Custom Stylesheets */
import global_styles from '$assets/styles/global.styles.js'
import custom_styles from '$screens/main/family/_styles/main.styles.js'
import styles from '$screens/relatives/_styles/main.styles.js'

const ExtendedHeaderView = ({ ...props }) => {
  const colorSettings = props.colorSettings
  const message = props.message
  const controlsView = props.controlsView

  return (
    <View
      nativeID={'web_scaled_main_container'}
      style={[
        styles.headerContainer,
        {
          backgroundColor:
            colorSettings?.post_onboarding_nav_bgcolor ?? 'white',
        },
      ]}
    >
      {controlsView}
      {message && (
        <View style={{ paddingVertical: 12 }}>
          <Text
            style={[
              styles.headerText,
              { color: colorSettings?.nav_title_light ?? 'black' },
            ]}
          >
            {message}
          </Text>
        </View>
      )}
    </View>
  )
}

export default ExtendedHeaderView
