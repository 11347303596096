import React, { useEffect, useState } from 'react'
import { TabView } from 'react-native-tab-view'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { i18n } from '$localization/config.js'
import FamilyPedigreeScreen from '../FamilyPedigreeScreen/FamilyPedigreeScreen'
import FamilySummaryScreen from '../FamilySummaryScreen/FamilySummaryScreen'
import LoadingView from '../../../../components/LoadingView'
import FamilyListScreen from '../FamilyListScreen/FamilyListScreen'
import CustomTabBarComponent from './components/CustomTabBarComponent'

export default function FamilyMainTabView() {
  const navigation = useNavigation()
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const [index, setIndex] = useState(0)

  const [routes] = useState([
    { key: 'list', title: i18n.t('list.default') },
    { key: 'pedigree', title: i18n.t('pedigree.default') },
    { key: 'summary', title: i18n.t('summary.default') },
  ])

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'list':
        return <FamilyListScreen />
      case 'pedigree':
        return <FamilyPedigreeScreen navigation={navigation} />
      case 'summary':
        return <FamilySummaryScreen />
      default:
        return null
    }
  }

  useEffect(() => {
    navigation.setParams({
      familyTabsNavigationButtonTypes: index,
    })
  }, [index])

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      lazy
      renderLazyPlaceholder={() => (
        <LoadingView
          backgroundColor={colorSettings?.splash_bgcolor}
          tintColor={colorSettings?.btn_no_fill_border_1}
          textColor={colorSettings?.btn_no_fill_text_1}
          visible
        />
      )}
      swipeEnabled={false}
      onIndexChange={setIndex}
      renderTabBar={({ navigationState, jumpTo }) => (
        <CustomTabBarComponent navigation={navigationState} jumpTo={jumpTo} />
      )}
    />
  )
}
