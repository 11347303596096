import { Component } from 'react'
import {
  SafeAreaView,
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native'

import ErrorBoundary from 'react-native-error-boundary'
import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import LoadingView from '$components/LoadingView'
import DarkGraySwitch from '$components/CustomSwitches/DarkGraySwitch'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { convertTextToSnakeCase } from '$utils'

import {
  setSharingSettingMember,
  clearSharingSettingMember,
} from '$redux/member_profile/actions.js'

import custom_styles from '$screens/main/dashboard/invite/_styles/main.styles.js'
import section_styles from './_styles/section.styles.js'
import { DefaultFullHeight } from '$assets/styles/global.styles.js'
import { IS_WEB } from '$utils/Platforms.js'

import MainWrapper from '$components/MainWrapper'
import { apiFetchHandler } from '$api'

import { setError } from '$redux/defaults/actions.js'

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    borderBottomWidth: 2.0,
    borderBottomColor: 'rgb(234,234,234)',
  },
  headerContent: {
    padding: 16,
    flexDirection: 'row',
  },
  headerTitleContainer: {
    justifyContent: 'center',
    paddingHorizontal: 16,
    flex: 1.0,
  },
  headerTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    color: 'rgb(74,74,74)',
  },
  headerSubtitleText: {
    fontFamily: 'montserrat',
    fontSize: 11,
    color: 'rgb(102,102,102)',
  },
  rowItem: {
    marginHorizontal: 16,
    marginVertical: 8,
    flexDirection: 'row',
    paddingTop: 12,
    minHeight: 50,
    justifyContent: 'space-between',
  },
  labelAndIconContainer: {
    flex: 0.8,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  iconContainer: {
    paddingLeft: 8,
  },
  switchContainer: {
    paddingRight: 4,
  },
})

// Sample data
// const sampleData = {
//   member_id: 1,
//   name: 'Unknown White',
//   relationship: 'Father',
//   gender: 'm',
//   sharingSettings: {
//     notifyMe: true,
//     notifyMembers: true,
//   },
// }

class SharingSettingsDetail extends Component {
  memberInfo = this.props.memberInfo

  colorSettings = this.props.colorSettings

  constructor(props) {
    super(props)

    this.state = {
      showLoadingView: false,
      memberInfo: this.memberInfo,
      scrollViewHeight: DefaultFullHeight(),
    }
  }

  componentDidMount() {
    if (IS_WEB()) {
      window.addEventListener('resize', this.screenResizeHandler)
    }
  }

  componentWillUnmount() {
    const { _clearSharingSettingMember_ } = this.props
    // This event happens before component is removed from memory.
    if (IS_WEB()) {
      window.removeEventListener('resize', this.screenResizeHandler)
    }

    // Clears memberInfo data to redux store
    _clearSharingSettingMember_()
  }

  screenResizeHandler = () => {
    const height = DefaultFullHeight()
    this.setState({ scrollViewHeight: height })
  }

  updateMemberSharingSettings = async (
    allowSending = null,
    allowReceiving = null
  ) => {
    this.setState({ showLoadingView: true })

    const { currentProband, authCredentials } = this.props

    const { probandID } = currentProband
    const { member_id: memberID, sharingSettings } = this.memberInfo

    const payload = { proband_id: probandID }

    if (allowSending) {
      payload.send_notification = allowSending === 'yes'
    } else {
      payload.send_notification = sharingSettings?.notifyMembers
    }

    if (allowReceiving) {
      payload.received_notification = allowReceiving === 'yes'
    } else {
      payload.received_notification = sharingSettings?.notifyMe
    }

    const updateMemberSharingSettingsPayload = {
      path: `member/${memberID}/update_sharing_settings/`,
      method: 'post',
      token: authCredentials.accessToken,
      body: {
        ...payload,
      },
      pageDetails: {
        page: 'SharingSettings.detail.js',
      },
    }

    const response = await apiFetchHandler(updateMemberSharingSettingsPayload)
    const { saveError } = this.props

    if (response.isError) {
      this.setState({ showLoadingView: false })

      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return showDefaultAlert(
        i18n.t('server_error').default,
        i18n.t(
          'sorry_we_cannot_process_your_request_at_this_time_please_try_again_later'
        ).default
      )
    }

    setTimeout(() => {
      this.setState({ showLoadingView: false })
    }, 300)

    if (allowSending) {
      this.setState((prevState) => ({
        memberInfo: {
          ...prevState.memberInfo,
          sharingSettings: {
            ...prevState.memberInfo.sharingSettings,
            notifyMembers: allowSending === 'yes',
          },
        },
      }))
    }

    if (allowReceiving) {
      this.setState((prevState) => ({
        memberInfo: {
          ...prevState.memberInfo,
          sharingSettings: {
            ...prevState.memberInfo.sharingSettings,
            notifyMe: allowReceiving === 'yes',
          },
        },
      }))
    }

    // Save memberInfo data to redux store
    this.props._setSharingSettingMember_(this.state.memberInfo)
  }

  toggleNotifyMeSwitch = async (val) => {
    let allowReceiving

    switch (val) {
      case true:
        allowReceiving = 'yes'
        break
      case false:
        allowReceiving = 'no'
        break
      default:
        allowReceiving = null
        break
    }

    await this.updateMemberSharingSettings(null, allowReceiving)
  }

  toggleNotifyMembersSwitch = async (val) => {
    let allowSending

    switch (val) {
      case true:
        allowSending = 'yes'
        break
      case false:
        allowSending = 'no'
        break
      default:
        allowSending = null
        break
    }

    await this.updateMemberSharingSettings(allowSending, null)
  }

  toggleNotifyMeTooltip = () => {
    showDefaultAlert(
      i18n.t('notify_me').default,
      i18n.t(
        'you_will_get_notifications_of_changes_your_family_member_makes_to_their_record_and_you_will_have_a_chance_to_accept_or_decline_those_changes_if_you_decline_the_changes_it_will_only_affect_your_record_not_theirs'
      ).default
    )
  }

  toggleNotifyMembersTooltip = () => {
    showDefaultAlert(
      i18n.t('notify_family_member').default,
      i18n.t(
        'when_you_make_changes_your_family_member_will_get_a_notification_they_will_have_a_chance_to_accept_or_decline_those_changes_for_their_own_record_if_they_decline_a_change_it_will_not_affect_your_record_just_theirs'
      ).default
    )
  }

  render() {
    const { navigation } = this.props

    if (!this.state.memberInfo) {
      return null
    }

    const { memberInfo } = this.state
    const memberName = memberInfo.name

    let { relationship } = memberInfo
    const _relationship_ = convertTextToSnakeCase(relationship)
    relationship = i18n.t(_relationship_).title

    let genderIcon = null

    switch (memberInfo.gender.toLowerCase()) {
      case 'm':
        genderIcon = require('$assets/images/icon/male.png')
        break
      case 'f':
        genderIcon = require('$assets/images/icon/female.png')
        break
    }

    const contentMarginWeb = IS_WEB() ? { marginTop: 16 } : {}

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={custom_styles.container}
            nativeID="web_scaled_main_container"
          >
            <ScrollView style={{ height: this.state.scrollViewHeight }}>
              <View style={[styles.headerContainer, contentMarginWeb]}>
                <View style={styles.headerContent}>
                  <View style={{ backgroundColor: 'rgba(65,70,97,0.1)' }}>
                    <Image
                      style={{ width: 42, height: 42, resizeMode: 'contain' }}
                      source={genderIcon}
                    />
                  </View>

                  <View style={styles.headerTitleContainer}>
                    <View>
                      <Text style={styles.headerTitleText}>{memberName}</Text>
                      <Text style={styles.headerSubtitleText}>
                        {relationship}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View ew style={[custom_styles.content, { marginTop: 16 }]}>
                <View style={[section_styles.container, { paddingBottom: 8 }]}>
                  <View style={[section_styles.content]}>
                    <View style={section_styles.titleContainer}>
                      <View style={section_styles.titleTextContainer}>
                        <Text style={section_styles.titleText}>
                          {i18n.t('sharing_settings').default}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.rowItem}>
                      <View style={styles.labelAndIconContainer}>
                        <Text style={[section_styles.contentItemLabelText]}>
                          {
                            i18n.t(
                              'receive_notifications_when_family_member_updates_data'
                            ).default
                          }
                        </Text>

                        <TouchableOpacity
                          style={[
                            section_styles.infoButton,
                            styles.iconContainer,
                          ]}
                          activeOpacity={0.3}
                          onPress={() => this.toggleNotifyMeTooltip()}
                        >
                          <Image
                            style={section_styles.infoButtonIcon}
                            source={require('$assets/images/new_assets/icon-info-teal.png')}
                          />
                        </TouchableOpacity>
                      </View>

                      <View style={styles.switchContainer}>
                        <DarkGraySwitch
                          paddingTop={5}
                          value={memberInfo.sharingSettings.notifyMe}
                          onValueChange={this.toggleNotifyMeSwitch}
                        />
                      </View>
                    </View>

                    <View style={section_styles.contentSeparatorLine} />

                    <View style={styles.rowItem}>
                      <View style={styles.labelAndIconContainer}>
                        <Text style={section_styles.contentItemLabelText}>
                          {
                            i18n.t(
                              'send_notifications_to_family_member_when_i_update_data'
                            ).default
                          }
                        </Text>

                        <TouchableOpacity
                          style={[
                            section_styles.infoButton,
                            styles.iconContainer,
                          ]}
                          activeOpacity={0.3}
                          onPress={() => this.toggleNotifyMembersTooltip()}
                        >
                          <Image
                            style={section_styles.infoButtonIcon}
                            source={require('$assets/images/new_assets/icon-info-teal.png')}
                          />
                        </TouchableOpacity>
                      </View>

                      <View style={styles.switchContainer}>
                        <DarkGraySwitch
                          paddingTop={5}
                          value={memberInfo.sharingSettings.notifyMembers}
                          onValueChange={this.toggleNotifyMembersSwitch}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <LoadingView
                backgroundColor={this.colorSettings?.splash_bgcolor}
                tintColor={this.colorSettings?.btn_no_fill_border_1}
                textColor={this.colorSettings?.btn_no_fill_text_1}
                visible={this.state.showLoadingView}
              />
            </ScrollView>
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store, accountStore, probandStore, memberProfileStore } = state
  return {
    colorSettings: store.colorSettings,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    /** Proband Store */
    currentProband: probandStore.proband,
    /** MemberProfile Store */
    memberInfo: memberProfileStore.sharingSettingMember,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setSharingSettingMember_: (data) =>
      dispatch(setSharingSettingMember(data)),
    _clearSharingSettingMember_: () => dispatch(clearSharingSettingMember()),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(
  mapGlobalStateToProps,
  mapDispatchToProps
)(SharingSettingsDetail)
