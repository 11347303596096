import { View, Text, TouchableOpacity } from 'react-native'
import { i18n } from '$localization/config.js'

import styles from './styles'

export default function EditFromStartButton({
  navigateToFirstWorkflowScreen,
  colorSettings,
}) {
  const editFromStartText = i18n.t('edit_from_start')?.default
  return (
    <View style={styles.editFromStartContainer}>
      <View style={styles.nextButtonContainer}>
        <TouchableOpacity
          style={styles.nextButton(colorSettings)}
          onPress={navigateToFirstWorkflowScreen}
        >
          <Text style={styles.nextButtonText(colorSettings)}>
            {editFromStartText}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
