const INCREMENTS = 10
const MORE = 'more'
const NO_INCREMENT = 0
const ERROR_MESSAGE = {
  loadMemberGeneTests:
    '🚀 \n\n file: PositiveGeneTestOnboarding.js:279 \n\n loadMemberGeneTests \n\n error:',
  componentMountHandler:
    '🚀 \n\n file: PositiveGeneTestOnboarding.js:304 \n\n componentMountHandler \n\n error:',
}
const EMPTY_SELECTED_SCREEN = ''
const PADDING = 'padding'
const HEIGHT = 'height'
const RESIZE = 'resize'
const GENE_TEST_TYPE = 'p'

export {
  INCREMENTS,
  MORE,
  NO_INCREMENT,
  ERROR_MESSAGE,
  EMPTY_SELECTED_SCREEN,
  PADDING,
  HEIGHT,
  RESIZE,
  GENE_TEST_TYPE,
}
