import { StyleSheet } from 'react-native'

const BLACK = 'black'

const styles = StyleSheet.create({
  textInputContainerCentered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  autocompleteTextInput: (colorSettings) => ({
    height: 50,
    paddingLeft: 5,
    fontSize: 16,
    fontWeight: '500',
    width: '80%',
    paddingHorizontal: 18,
    color: colorSettings?.text_input_label_color_2 || BLACK,
  }),
})

export default styles
