import iconSurveyWarn from '$assets/images/new_assets/icon-survey-warn.png'
import iconSurveyOk from '$assets/images/new_assets/icon-survey-ok.png'

export default function processSurveyItem(survey, clinicianCode) {
  const buttonIcon =
    survey.status === 'in_progress' ? iconSurveyWarn : iconSurveyOk

  const surveyItemPayload = {
    id: survey?.id,
    buttonTitle: survey?.name,
    buttonIcon,
    navItem: 'SurveyCustom',
    dataItems: {
      surveyID: survey?.id,
      clinician_code: clinicianCode ?? null,
    },
    isCustomSurvey: true,
  }

  return surveyItemPayload
}
