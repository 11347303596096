import { useState } from 'react'
import { TextInput, View } from 'react-native'

import styles from './styles'
import { i18n } from '$localization/config.js'

import LabelText from '../LabelText'

const DEFAULTS = {
  ANSWER: '',
  CHOICE_ID: null,
  PLACEHOLDER_COLOR: 'rgba(74, 74, 74, 0.5)',
}

const TEXT_INPUT_PROPS = {
  NATIVE_ID: 'web_text_input',
  RETURN_KEY_TYPE: 'done',
  TEXT_ALIGN: 'left',
  NUMBER_OF_LINES: 4,
}

export default function TextLong({
  colorSettings,
  masterQuestion,
  answer,
  storeFieldSurveyAnswers,
}) {
  const defaultValue = answer?.answer || DEFAULTS.ANSWER

  const [inputData, setInputData] = useState(defaultValue)

  const { place_holder_text: placeHolderText, question_label: questionLabel } =
    masterQuestion

  const saveOnBlur = () => {
    const payload = {
      master_question_id: masterQuestion.id,
      master_question_choice_id: DEFAULTS.CHOICE_ID,
      answer: inputData,
    }

    storeFieldSurveyAnswers(payload)
  }

  const title = LabelText({
    questionLabel: i18n.t(questionLabel).default || questionLabel,
    colorSettings,
    masterQuestion,
  })

  const placeholderTextColor =
    colorSettings?.text_input_placeholder_color_2 || DEFAULTS.PLACEHOLDER_COLOR

  return (
    <View style={styles.mainContainer}>
      {title}
      <TextInput
        nativeID={TEXT_INPUT_PROPS.NATIVE_ID}
        onChangeText={(item) => setInputData(item)}
        value={inputData}
        placeholder={placeHolderText}
        placeholderTextColor={placeholderTextColor}
        style={styles.inputField(colorSettings)}
        returnKeyType={TEXT_INPUT_PROPS.RETURN_KEY_TYPE}
        multiline
        numberOfLines={TEXT_INPUT_PROPS.NUMBER_OF_LINES}
        textAlign={TEXT_INPUT_PROPS.TEXT_ALIGN}
        onBlur={saveOnBlur}
      />
    </View>
  )
}

// ** typescript reference **

// TextLong.propTypes = {
//   masterQuestion: PropTypes.isRequired,
//   colorSettings: PropTypes.objectOf(PropTypes.string),
//   answer: PropTypes.number,
//   storeFieldSurveyAnswers: PropTypes.func,
// }

// TextLong.defaultProps = {
//   colorSettings: [],
//   answer: '',
//   storeFieldSurveyAnswers: () => {},
// }
