import { dispatchHelpers } from './reduxHelpers'

const handleProbandFamilySaving = (data) => {
  const { saveProbandImmediateFamily } = dispatchHelpers()

  const familyData = {
    familyID: data.family_id,
    probandID: data.member_id,
  }

  saveProbandImmediateFamily(familyData)
}

const generateProbandPayload = (accountData, patientData, email) => {
  return {
    proband: {
      account_id: accountData?.account_id,
      email: email ?? patientData?.email,
      first_name: patientData?.first_name,
      middle_name: patientData?.middle_name ?? '',
      last_name: patientData?.last_name,
      age: patientData?.age,
      dob: patientData?.dob,
      phone_number: patientData?.phone_number,
      gender: patientData?.gender ?? '',
      clinician_code: accountData?.clin_code,
      onboarding: true,
      patient_id: accountData?.patient_id,
    },
  }
}

const passwordValiditySchema = (password) => {
  const PasswordValidator = require('password-validator')
  const schema = new PasswordValidator()
  schema
    .is()
    .min(12)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .not()
    .spaces()

  return schema.validate(password)
}

const createAuthCredentialsPayload = (authData) => {
  return {
    accountID: authData?.account_id,
    accessToken: authData?.accessToken,
    refreshToken: authData?.refreshToken,
  }
}

export {
  handleProbandFamilySaving,
  generateProbandPayload,
  createAuthCredentialsPayload,
  passwordValiditySchema,
}
