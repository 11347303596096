import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { envConstants } from '$constants'
import styles from '../../styles'
import { i18n, currentLanguage } from '$localization/config'
import { IS_WEB } from '$constants/platforms'
import { handleLanguage } from './helpers'

function LanguageControlView({ openLanguageSettings }) {
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)

  const language = selectedLanguage || currentLanguage

  const currentSelectedLanguage = IS_WEB() ? handleLanguage(language) : language

  if (envConstants.MULTI_LANGUAGE !== 'true') {
    return <View style={styles.appInfoLeftContent} />
  }

  return (
    <View style={styles.appInfoLeftContent}>
      <Text style={styles.languageTitle}>
        {`${i18n.t('language')?.capitalize}:`}
      </Text>
      <TouchableOpacity
        onPress={openLanguageSettings}
        style={styles.appLanguage}
      >
        <Text style={styles.appInfoLanguage}>
          {i18n.t(currentSelectedLanguage)?.capitalize}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default LanguageControlView
