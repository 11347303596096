import { View } from 'react-native'
import GenderIdentityOptionsItem from './components/GenderIdentityOptionsItem'
import styles from './styles'
import { SHORTEN_GENDER_IDENTITIES } from '../../constants'
import { i18n } from '$localization/config'

function GenderIdentityOptions({
  selectedGenderIdentity,
  setSelectedGenderIdentity,
}) {
  const maleTranslation = i18n.t('male').default
  const femaleTranslation = i18n.t('female').default
  const nonBinaryTranslation = i18n.t('non-binary_other').default

  return (
    <View style={styles.genderIdentityOptionsContainer}>
      <GenderIdentityOptionsItem
        buttonLabel={maleTranslation}
        iconType={SHORTEN_GENDER_IDENTITIES.MALE}
        selected={selectedGenderIdentity === SHORTEN_GENDER_IDENTITIES.MALE}
        onSelect={setSelectedGenderIdentity}
      />
      <GenderIdentityOptionsItem
        buttonLabel={femaleTranslation}
        iconType={SHORTEN_GENDER_IDENTITIES.FEMALE}
        selected={selectedGenderIdentity === SHORTEN_GENDER_IDENTITIES.FEMALE}
        onSelect={setSelectedGenderIdentity}
      />
      <GenderIdentityOptionsItem
        buttonLabel={nonBinaryTranslation}
        iconType={SHORTEN_GENDER_IDENTITIES.NON_BINARY}
        selected={
          selectedGenderIdentity === SHORTEN_GENDER_IDENTITIES.NON_BINARY
        }
        onSelect={setSelectedGenderIdentity}
      />
    </View>
  )
}

export default GenderIdentityOptions
