import { View, TouchableOpacity, Text } from 'react-native'

import { i18n } from '$localization/config.js'

import styles from '../../styles'

export default function DifferentGenderIdentityButton({
  navigateToGenderIdentity,
  colorSettings,
}) {
  const DIFFERENT_GENDER_IDENTITY_TEXT = i18n.t(
    'have_a_different_gender_identity'
  ).default

  return (
    <View style={[styles.content, styles.genderIdentityDivider]}>
      <TouchableOpacity onPress={navigateToGenderIdentity}>
        <Text style={styles.linkedText(colorSettings)}>
          {DIFFERENT_GENDER_IDENTITY_TEXT}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
