import FemaleFair from '$assets/images/svgGenderIdentityAvatars/female1.svg'
import FemaleTan from '$assets/images/svgGenderIdentityAvatars/female2.svg'
import FemaleDark from '$assets/images/svgGenderIdentityAvatars/female3.svg'
import FemaleIsDead from '$assets/images/svgGenderIdentityAvatars/female-deceased.svg'

import MaleFair from '$assets/images/svgGenderIdentityAvatars/male1.svg'
import MaleTan from '$assets/images/svgGenderIdentityAvatars/male2.svg'
import MaleDark from '$assets/images/svgGenderIdentityAvatars/male3.svg'
import MaleIsDead from '$assets/images/svgGenderIdentityAvatars/male-deceased.svg'

import NonbinaryFair from '$assets/images/svgGenderIdentityAvatars/nonbinary1.svg'
import NonbinaryTan from '$assets/images/svgGenderIdentityAvatars/nonbinary2.svg'
import NonbinaryDark from '$assets/images/svgGenderIdentityAvatars/nonbinary3.svg'
import NonbinaryIsDead from '$assets/images/svgGenderIdentityAvatars/nonbinary-deceased.svg'

export function FemaleFairSkinTone({
  height = '100%',
  width = '100%',
  viewBox,
}) {
  return <FemaleFair width={width} height={height} viewBox={viewBox} />
}

export function FemaleTanSkinTone({
  height = '100%',
  width = '100%',
  viewBox,
}) {
  return <FemaleTan width={width} height={height} viewBox={viewBox} />
}

export function FemaleDarkSkinTone({
  height = '100%',
  width = '100%',
  viewBox,
}) {
  return <FemaleDark width={width} height={height} viewBox={viewBox} />
}

export function FemaleDeceased({ height = '100%', width = '100%', viewBox }) {
  return <FemaleIsDead width={width} height={height} viewBox={viewBox} />
}

export function MaleFairSkinTone({ height = '100%', width = '100%', viewBox }) {
  return <MaleFair width={width} height={height} viewBox={viewBox} />
}

export function MaleTanSkinTone({ height = '100%', width = '100%', viewBox }) {
  return <MaleTan width={width} height={height} viewBox={viewBox} />
}

export function MaleDarkSkinTone({ height = '100%', width = '100%', viewBox }) {
  return <MaleDark width={width} height={height} viewBox={viewBox} />
}

export function MaleDeceased({ height = '100%', width = '100%', viewBox }) {
  return <MaleIsDead width={width} height={height} viewBox={viewBox} />
}

export function NonbinaryFairSkinTone({
  height = '100%',
  width = '100%',
  viewBox,
}) {
  return <NonbinaryFair width={width} height={height} viewBox={viewBox} />
}

export function NonbinaryTanSkinTone({
  height = '100%',
  width = '100%',
  viewBox,
}) {
  return <NonbinaryTan width={width} height={height} viewBox={viewBox} />
}

export function NonbinaryDarkSkinTone({
  height = '100%',
  width = '100%',
  viewBox,
}) {
  return <NonbinaryDark width={width} height={height} viewBox={viewBox} />
}

export function NonbinaryDeceased({
  height = '100%',
  width = '100%',
  viewBox,
}) {
  return <NonbinaryIsDead width={width} height={height} viewBox={viewBox} />
}

export default {
  FemaleFairSkinTone,
  FemaleTanSkinTone,
  FemaleDarkSkinTone,
  FemaleDeceased,
  MaleFairSkinTone,
  MaleTanSkinTone,
  MaleDarkSkinTone,
  MaleDeceased,
  NonbinaryFairSkinTone,
  NonbinaryTanSkinTone,
  NonbinaryDarkSkinTone,
  NonbinaryDeceased,
}
