import { FlatList, Modal, SafeAreaView, TextInput, View } from 'react-native'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './styles'
import { i18n } from '$localization/config.js'
import AncestryListItem from './components/AncestryListItem'
import useAncestry from '../../hooks'
import DoneButton from './components/DoneButton'
import BackArrow from './components/BackArrow'

function AncestrySearchModal({
  showModal,
  handleCloseModal,
  handleSubmitAncestryList,
  selectedAncestries,
}) {
  const [searchInput, setSearchInput] = useState('')
  const [ancestryList, setAncestryList] = useState([])
  const [filteredAncestryList, setFilteredAncestryList] = useState([])

  const colorSettings = useSelector((state) => state.store.colorSettings)

  const { sortAncestryList } = useAncestry()

  const findSelectedAncestry = (element) =>
    selectedAncestries.find(
      (selected) => selected.ancestry_id === element.ancestry_id
    )

  const loadAncestryList = async () => {
    try {
      const sortedAncestryList = await sortAncestryList()

      const ancestryListWithSelectedAncestries = sortedAncestryList.map(
        (element) => findSelectedAncestry(element) || element
      )

      setAncestryList(ancestryListWithSelectedAncestries)
      setFilteredAncestryList(ancestryListWithSelectedAncestries)
    } catch (err) {
      console.log(
        '🚀 \n\n file: AncestrySearchModal.js:26 \n\n loadAncestryList \n\n error:',
        err
      )
    }
  }

  const selectedAncestryList = ancestryList.filter(
    (ancestry) => ancestry?.selected
  )

  const handleSelectAncestry = (selectedAncestry) => {
    const updateAncestryList = (list) =>
      list.map((element) => {
        if (element.ancestry_id === selectedAncestry.ancestry_id) {
          const { selected, ...rest } = element
          return selected ? rest : { ...element, selected: true }
        }
        return element
      })

    const newAncestryList = updateAncestryList(filteredAncestryList)
    const ancestryListWithSelectedAncestries = updateAncestryList(ancestryList)

    setAncestryList(ancestryListWithSelectedAncestries)
    setFilteredAncestryList(newAncestryList)
  }

  const handleSearchAncestry = (inputText) => {
    setSearchInput(inputText)

    const lowerCasedInputText = inputText?.toLowerCase()
    const newAncestry = ancestryList.filter((ancestry) =>
      ancestry?.display_name?.toLowerCase().startsWith(lowerCasedInputText)
    )

    setFilteredAncestryList(inputText?.length > 0 ? newAncestry : ancestryList)
  }

  return (
    <Modal animationType="slide" visible={showModal} onShow={loadAncestryList}>
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: colorSettings?.onboarding_bgcolor || 'white',
        }}
      >
        <View style={styles.modalContainer(colorSettings)}>
          <View style={styles.contentContainerHead}>
            <View style={styles.innerHeadContainer}>
              <BackArrow handleCloseModal={handleCloseModal} />
              <View style={styles.inputContainer}>
                <TextInput
                  style={[
                    styles.textInputHead,
                    {
                      color: colorSettings?.text_input_color_2 || 'black',
                    },
                  ]}
                  inputAccessoryViewID="Index"
                  maxLength={30}
                  placeholder={i18n.t('search_ancestry').default}
                  placeholderTextColor={
                    colorSettings?.text_input_placeholder_color_2 ||
                    'rgba(74, 74, 74, 0.5)'
                  }
                  selectionColor={colorSettings?.text_input_color_2}
                  autoCorrect={false}
                  autoCapitalize="sentences"
                  onChangeText={(text) => handleSearchAncestry(text)}
                  value={searchInput}
                />
              </View>
              <View />
            </View>
          </View>
          <View style={styles.contentContainer}>
            <FlatList
              style={styles.ancestryList}
              maxToRenderPerBatch={50}
              keyExtractor={(item, index) => item + index}
              data={filteredAncestryList}
              renderItem={({ item }) => (
                <AncestryListItem
                  item={item}
                  setSelectedAncestry={handleSelectAncestry}
                />
              )}
            />
          </View>
        </View>

        <DoneButton
          handleSubmitAncestryList={() =>
            handleSubmitAncestryList(selectedAncestryList)
          }
        />
      </SafeAreaView>
    </Modal>
  )
}

export default AncestrySearchModal
