import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import { connect } from 'react-redux'
import { i18n } from '$localization/config.js'

import { fixDiseaseName } from '$utils'

const CategoryButtonList = (props) => {
  const { colorSettings } = props

  const { disease_name, age_diagnosed, question, answer } = props.data.item
    ? props.data.item
    : props.data

  const withRemoveButton = (render = true) => {
    if (render) {
      return (
        <TouchableOpacity
          onPress={props.onDeleteMemberDisease.bind(this, props.data.item)}
        >
          <Text
            style={[
              styles.listTextStyle,
              {
                paddingLeft: 16,
                color: colorSettings?.text_input_label_color_2,
              },
            ]}
          >
            {i18n.t('remove').title}
          </Text>
        </TouchableOpacity>
      )
    }
  }

  const handleChangDiseaseName = (Name) => {
    if (Name === undefined) {
      return ''
    }
    if (Name.includes('Was there') || Name.includes('Was it')) {
      let diseaseName = Name
      let capitalize = ''
      let firstWord = ''
      let lastWord = ''
      let numberOfSlice = 0
      let includedWords = ''

      if (Name.includes('Was there')) {
        includedWords = 'Was there '
      } else {
        includedWords = 'Was it '
      }

      numberOfSlice = includedWords.length
      diseaseName = Name.slice(numberOfSlice)
      firstWord = diseaseName.replace(/ .*/, '')
      capitalize = firstWord[0].toUpperCase() + firstWord.slice(1)
      lastWord = diseaseName
        .substring(0, diseaseName.length - 1)
        .replace(firstWord, '')

      return capitalize.concat(lastWord)
    }
    return Name || ''
  }

  const translateText = (text) => fixDiseaseName(text)

  const handleTitle = (disease_name, age_diagnosed) => {
    const newDiseaseName = handleChangDiseaseName(disease_name)

    if (
      (newDiseaseName === 'Age of Diagnosis' && !age_diagnosed) ||
      !age_diagnosed ||
      answer === 'y' ||
      answer === 'n' ||
      age_diagnosed < 1
    ) {
      return (
        <Text
          style={[
            styles.listTextStyle,
            {
              color: colorSettings?.text_input_label_color_2,
            },
          ]}
        >
          {i18n.t(translateText(newDiseaseName)).default}
        </Text>
      )
    }
    if (newDiseaseName === 'Age of Diagnosis') {
      return (
        <Text
          style={[
            styles.listTextStyle,
            {
              color: colorSettings?.text_input_label_color_2,
            },
          ]}
        >
          {`${i18n.t(translateText(newDiseaseName)).default}: ${age_diagnosed}`}
        </Text>
      )
    }
    return (
      <Text
        style={[
          styles.listTextStyle,
          {
            color: colorSettings?.text_input_label_color_2,
          },
        ]}
      >
        {`${i18n.t(translateText(newDiseaseName)).default}: ${age_diagnosed}`}
      </Text>
    )
  }

  return (
    <View style={[styles.listContainer, props.containerStyle]}>
      <View style={{ flex: 1.5 }}>
        {props.renderSkipLogic
          ? handleTitle(question, answer)
          : handleTitle(disease_name, age_diagnosed)}
      </View>

      <View style={styles.listButtonStyle}>
        <TouchableOpacity
          onPress={() => {
            props.fromMain ? props.onEdit(props.data.item) : props.onEdit()
          }}
        >
          <Text
            style={[
              styles.listTextStyle,
              {
                color: colorSettings?.text_input_label_color_2,
              },
            ]}
          >
            {i18n.t('edit').title}
          </Text>
        </TouchableOpacity>

        {withRemoveButton(props.withButtonRemove)}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    marginBottom: 16,
    flex: 1,
  },
  listTextStyle: {
    // color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
  },
  listButtonStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
})

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps)(CategoryButtonList)
