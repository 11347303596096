import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScrollView,
  View,
} from 'react-native'

import moment from 'moment'
import { i18n } from '$localization/config.js'
import styles from './main.styles'

import { setError } from '$redux/defaults/actions.js'
import CustomDatePicker from '$components/CustomDatePicker'
import { apiFetchHandler } from '$api'
import { setAccountAction, setAccountDOB } from '$redux/account/actions'
import TermsAndPrivacyModal from '$components/TermsAndPrivacyModal'
import useAuth from '$hooks/useAuth'
import { errorResponseHandler } from '../authorizationHelpers'
import LoginForm from './components/LoginForm'
import ScreenDivider from './components/ScreenDivider'
import CreatePasswordNavigationText from './components/CreatePasswordNavigationText'
import CustomValidateDobHeaderText from './components/CustomValidateDobHeaderText'
import { accountAlreadyExistsModal } from './helpers'
import SignUpDatePicker from '$components/SignUpDatePicker'
import NextButtonAbsolute from '$components/NextButtonAbsolute'
import { IS_IOS } from '$utils/Platforms.js'

const isWEB = Platform.OS === 'web' ? 'web' : 'mobile'

function CustomValidateDOB({ navigation }) {
  const dispatch = useDispatch()
  const dateOfBirthdateRef = useRef(null)

  const {
    handleLoginCustomWorkflowUser,
    createPatientAccount,
    handleLoginUser,
    checkAndNavigateToPasswordScreen,
  } = useAuth()

  const setPatientDOB = (data) => dispatch(setAccountDOB(data))
  const saveAccountData = (data) => dispatch(setAccountAction(data))
  const saveError = (data) => dispatch(setError(data))

  const colorSettings = useSelector((state) => state.store.colorSettings)

  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)
  const userUUID = useSelector((state) => state.store.inviteID)

  const patientData = useSelector((state) => state.accountStore.account)

  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [isInputsValid, setIsInputsValid] = useState(false)
  const [showPassword, setShowPassword] = useState(true)
  const [email, setEmail] = useState('')
  const [dateOfBirthday, setDateOfBirthday] = useState(null)
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [isDOBCorrect, setIsDOBCorrect] = useState(false)
  const [showTermsPrivacy, setShowTermsPrivacy] = useState(false)

  const isPatientInviteSent =
    patientData?.invite_status === 'InviteSent' ||
    patientData?.invite_status === '2'

  const isSubmitButtonEnabled = !isInputsValid && !dateOfBirthday

  const validateDobAction = async () => {
    if (!dateOfBirthday) return

    try {
      const formattedDateOfBirthdate =
        moment(dateOfBirthday).format('YYYY-MM-DD')

      const validateDOB = {
        path: `patient_account/${userUUID}/get/?dob=${formattedDateOfBirthdate}`,
        method: 'get',
        pageDetails: {
          page: 'CustomValidateDOB.js',
        },
      }

      const response = await apiFetchHandler(validateDOB)

      if (response.isError) {
        setIsDOBCorrect(false)

        return errorResponseHandler(response, saveError)
      }

      saveAccountData(response)
      setIsDOBCorrect(true)
      setIsInputsValid(false)
      setPatientDOB(formattedDateOfBirthdate)
    } catch (err) {
      console.log(
        '🚀 \n\n file: CustomValidateDOB.js:281 \n\n validateDobAction \n\n err:',
        err
      )
    }
  }

  const onDatePickerChange = (selectedDate = false, dismiss = false, date) => {
    if (isWEB) {
      setDateOfBirthday(date)
    }

    if (selectedDate) {
      setDateOfBirthday(selectedDate)
    }

    if (!isWEB) {
      setDatePickerVisible(!dismiss)
    }
  }

  const navigateToPasswordScreen = () => {
    const formattedDateOfBirthdate = moment(dateOfBirthday).format('YYYY-MM-DD')

    setPatientDOB(formattedDateOfBirthdate)
    navigation.navigate('CustomCreatePassword')
  }

  const handleIsInputValid = () => {
    setIsInputsValid(false)
    const emailValidator = require('email-validator')
    const isEmailInvalid = email && !emailValidator.validate(email)

    const isEmailEmpty = !email?.length

    if (isEmailInvalid || isEmailEmpty) {
      setLoginError('emailError')
      return false
    }

    const isPasswordEmpty = !password?.length

    if (isPasswordEmpty || isEmailEmpty) {
      setLoginError('emptyError')
      return false
    }

    setIsInputsValid(true)
    setLoginError(null)

    return true
  }

  const navigateToTermsAndPrivacy = async () => {
    if (isInputsValid) return handleLoginUser(navigation, email, password)

    const shouldNavigateToPasswordScreen =
      await checkAndNavigateToPasswordScreen()

    if (shouldNavigateToPasswordScreen) return navigateToPasswordScreen()

    if (isPatientInviteSent) {
      setShowTermsPrivacy(true)
    } else {
      try {
        await handleLoginCustomWorkflowUser(navigation)
      } catch (error) {
        console.log(
          '🚀 \n\n file: customCreatePassword.js:11 \n\n handleSetPassAndLogin \n\n error:',
          error
        )
      }
    }
  }
  const handleCreateAccount = async () => {
    try {
      const response = await createPatientAccount(
        navigation,
        setShowTermsPrivacy
      )

      if (response?.status === 409)
        return accountAlreadyExistsModal(setDateOfBirthday, setIsDOBCorrect)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    validateDobAction().catch((error) => console.error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirthday])

  useEffect(() => {
    handleIsInputValid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password])

  return (
    <SafeAreaView
      style={[
        styles.container,
        {
          backgroundColor: colorSettings?.splash_bgcolor,
        },
      ]}
    >
      <KeyboardAvoidingView
        style={styles.keyboardAvoiding}
        behavior={IS_IOS() ? 'padding' : 'height'}
        keyboardVerticalOffset={60}
      >
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          nativeID="web_scaled_main_container"
          bounces={false}
        >
          <View style={styles.signUpContainer}>
            <View style={styles.validateDobContainer}>
              <CustomValidateDobHeaderText patientData={patientData} />

              <SignUpDatePicker
                handleInputChange={(_, value) => setDateOfBirthday(value)}
                dateOfBirthday={dateOfBirthday}
                dateOfBirthdateRef={dateOfBirthdateRef}
                toggleDateMobileDatePicker={() =>
                  setDatePickerVisible(!datePickerVisible)
                }
              />

              {isDOBCorrect && (
                <CreatePasswordNavigationText
                  navigateToPasswordScreen={navigateToPasswordScreen}
                />
              )}
            </View>

            {!isDOBCorrect && <ScreenDivider />}

            {!isDOBCorrect && (
              <LoginForm
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                password={password}
                email={email}
                setEmail={setEmail}
                setPassword={setPassword}
              />
            )}
          </View>
        </ScrollView>

        <NextButtonAbsolute
          onPressAction={navigateToTermsAndPrivacy}
          buttonText={i18n.t('next.default')}
          isDisabled={isSubmitButtonEnabled}
        />
      </KeyboardAvoidingView>

      {datePickerVisible && (
        <CustomDatePicker
          colorSettings={colorSettings}
          onDatePickerChange={onDatePickerChange}
          date={dateOfBirthday ?? new Date(0)}
          visible={datePickerVisible}
          selectedLanguage={selectedLanguage}
        />
      )}

      <TermsAndPrivacyModal
        modalVisible={showTermsPrivacy}
        primaryButtonAction={handleCreateAccount}
        secondaryButtonAction={() => setShowTermsPrivacy(!showTermsPrivacy)}
      />
    </SafeAreaView>
  )
}

export default CustomValidateDOB
