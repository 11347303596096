import moment from 'moment'
import MemberProfile from '$data_models/MemberProfile.js'

import { dateFormats } from '$constants'

import { isEmptyObject, getTimezoneOffset, yearIntervalFromNow } from '$utils'

export default function mapDataToMemberProfile(data) {
  const memberProfile = new MemberProfile()

  if (isEmptyObject(data)) {
    return memberProfile
  }

  memberProfile.memberID = data.member_info.member_id
  memberProfile.inviteStatus = data.invite_status

  const { profileData } = memberProfile
  let dob = null

  if (data.member_info.dob && data.member_info.dob !== '') {
    const timezoneOffset = getTimezoneOffset(new Date(data.member_info.dob))
    const newDob = moment(
      new Date(data.member_info.dob),
      dateFormats.DEFAULT_DATE_FORMAT_API
    ).add(parseInt(timezoneOffset, 10), 'hours')

    dob = newDob.format(dateFormats.DEFAULT_DATE_FORMAT)
  }

  let { age } = data.member_info

  if (dob && !data.member_info.viral_status) {
    age = yearIntervalFromNow(dob)
  }

  profileData.deceased = data.member_info.viral_status

  if (profileData.deceased === true) {
    profileData.ageOfDeath = data.member_info?.age || ''
    profileData.causeOfDeath = data.member_info?.cause_of_death || ''
  }

  profileData.dob = dob
  profileData.currentAge = age || ''
  profileData.currentAgeString = data?.member_info.age_string ?? ''
  profileData.firstName = data.member_info.first_name
  profileData.lastName = data.member_info.last_name
  profileData.email = data.member_info.email
  profileData.genderIdentity = data.member_info.gender_identity
  profileData.pronoun = data.member_info.pronoun
  profileData.ethnicity = data.member_info.ethnicity
  profileData.ashkenazi = data.member_info.ashkenazi
  profileData.ancestry = data.member_info.ancestry

  let biologicalGender = ''

  if (data.member_info.gender && data.member_info.gender !== '') {
    biologicalGender = data.member_info.gender
  }

  profileData.biologicalGender = biologicalGender.toLowerCase()

  const { healthHistoryData } = memberProfile
  const diagnosedWithDisease = data.member_disease.length > 0
  const geneTested = data.member_genetic_testing.length > 0

  healthHistoryData.diseasesData.diagnosedWithDisease = diagnosedWithDisease
  healthHistoryData.geneTestsData.geneTested = geneTested
  healthHistoryData.diseasesData.diseases = data.member_disease
  healthHistoryData.geneTestsData.testings = data.member_genetic_testing

  memberProfile.healthHistoryData = healthHistoryData

  const { relationshipData } = memberProfile

  if (!isEmptyObject(data.parents.father)) {
    let father = null
    if (data.parents.father.member_id) {
      let memberName = data.parents.father.member_name
      memberName = memberName.trim() !== '' ? memberName : 'unnamed'

      father = {
        id: data.parents.father.member_id,
        name: memberName,
        isEditable: data.parents.father.is_editable,
        gender: 'M',
      }
    }
    relationshipData.father = father
  }

  if (!isEmptyObject(data.parents.mother)) {
    let mother = null

    if (data.parents.mother.member_id) {
      let memberName = data.parents.mother.member_name
      memberName = memberName.trim() !== '' ? memberName : 'unnamed'

      mother = {
        id: data.parents.mother.member_id,
        name: memberName,
        isEditable: data.parents.mother.is_editable,
        gender: 'F',
      }
    }
    relationshipData.mother = mother
  }

  if (!isEmptyObject(data.blood_related_parents)) {
    const obj = data.blood_related_parents
    const isEnabled = obj.is_enable
    let relationshipInfo = null
    let bloodRelationType = null

    if (obj.blood_relation_type && obj.blood_relation_type !== '') {
      bloodRelationType = obj.blood_relation_type
      relationshipInfo = {
        key: bloodRelationType,
        name: null,
        definition: null,
      }
    }

    relationshipData.parentSpecific.bloodRelatedParents = isEnabled
    relationshipData.parentSpecific.relationshipInfo = relationshipInfo
  }

  if (!isEmptyObject(data.adopted) && data.adopted !== 'disabled') {
    const adoptionDetails = data.adopted
    relationshipData.isAdopted = true
    relationshipData.childSpecific.isAdoptedOut = adoptionDetails.adopted_out
    relationshipData.childSpecific.isChildOfRelative =
      adoptionDetails.is_adopted_in_blood_related
  } else {
    relationshipData.isAdopted = false
    relationshipData.childSpecific.isAdoptedOut = false
    relationshipData.childSpecific.isChildOfRelative = false
  }

  if (!isEmptyObject(data.twin) && data.twin !== 'disabled') {
    const twinGroup = {
      twinGroupID: data.twin.twin_id,
      twinMembers: data.twin.twin_members,
    }
    relationshipData.twinGroup = twinGroup
    relationshipData.isTwin = true
  } else {
    relationshipData.twinGroup = null
    relationshipData.isTwin = false
  }

  return memberProfile
}
