import { StyleSheet } from 'react-native'

const FALLBACK_COLORS = {
  BLACK_COLOR: 'black',
  WHITE_COLOR: 'white',
}

export default StyleSheet.create({
  container: {
    height: 60,
    width: '100%',
    justifyContent: 'flex-end',
  },
  nextButtonText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    color: colorSettings?.bottom_next_btn_text || FALLBACK_COLORS.BLACK_COLOR,
  }),
  nextButton: (disable, colorSettings) => ({
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderWidth: 1,
    opacity: disable ? 0.5 : 1,
    backgroundColor:
      colorSettings?.bottom_next_btn_enabled || FALLBACK_COLORS.WHITE_COLOR,
    borderColor:
      colorSettings?.bottom_next_btn_enabled || FALLBACK_COLORS.BLACK_COLOR,
  }),
})
