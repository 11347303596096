import { FlatList, View, TouchableOpacity, Text } from 'react-native'

import { useSelector } from 'react-redux'
import { IS_MOBILE } from '$constants/platforms'

import { i18n } from '$localization/config.js'

import BottomSheetComponent from '$components/BottomSheetComponent'
import AvatarsModalWeb from './components/AvatarsModalWeb'
import {
  FemaleFairSkinTone,
  FemaleTanSkinTone,
  FemaleDarkSkinTone,
  MaleFairSkinTone,
  MaleTanSkinTone,
  MaleDarkSkinTone,
  NonbinaryFairSkinTone,
  NonbinaryTanSkinTone,
  NonbinaryDarkSkinTone,
} from '$components/SVGIcon'

import {
  GENDER_NON_BINARY,
  GENDER_FEMALE,
  GENDER_MALE,
  SKIN_TONE_FAIR,
  SKIN_TONE_TAN,
  SKIN_TONE_DARK,
  IMAGE_NON_BINARY_FAIR,
  IMAGE_NON_BINARY_TAN,
  IMAGE_NON_BINARY_DARK,
  IMAGE_FEMALE_FAIR,
  IMAGE_FEMALE_TAN,
  IMAGE_FEMALE_DARK,
  IMAGE_MALE_FAIR,
  IMAGE_MALE_TAN,
  IMAGE_MALE_DARK,
} from './constants'

import styles from '../../styles'

export default function AvatarsBottomSheet({
  avatarBottomSheetRef,
  sendAvatarPayload,
  setModalVisible,
  modalVisible,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const AVATAR_SELECT_TITLE = i18n.t('select_avatar').title

  const genderAvatarOptions = [
    {
      gender: GENDER_NON_BINARY,
      skinTone: SKIN_TONE_FAIR,
      image: IMAGE_NON_BINARY_FAIR,
    },
    {
      gender: GENDER_NON_BINARY,
      skinTone: SKIN_TONE_TAN,
      image: IMAGE_NON_BINARY_TAN,
    },
    {
      gender: GENDER_NON_BINARY,
      skinTone: SKIN_TONE_DARK,
      image: IMAGE_NON_BINARY_DARK,
    },
    {
      gender: GENDER_FEMALE,
      skinTone: SKIN_TONE_FAIR,
      image: IMAGE_FEMALE_FAIR,
    },
    { gender: GENDER_FEMALE, skinTone: SKIN_TONE_TAN, image: IMAGE_FEMALE_TAN },
    {
      gender: GENDER_FEMALE,
      skinTone: SKIN_TONE_DARK,
      image: IMAGE_FEMALE_DARK,
    },
    { gender: GENDER_MALE, skinTone: SKIN_TONE_FAIR, image: IMAGE_MALE_FAIR },
    { gender: GENDER_MALE, skinTone: SKIN_TONE_TAN, image: IMAGE_MALE_TAN },
    { gender: GENDER_MALE, skinTone: SKIN_TONE_DARK, image: IMAGE_MALE_DARK },
  ]

  const avatarIcons = {
    NonbinaryFairSkinTone,
    NonbinaryTanSkinTone,
    NonbinaryDarkSkinTone,
    FemaleFairSkinTone,
    FemaleTanSkinTone,
    FemaleDarkSkinTone,
    MaleFairSkinTone,
    MaleTanSkinTone,
    MaleDarkSkinTone,
  }

  const getAvatarComponent = (name) => {
    const AvatarComponent = avatarIcons[name]
    return <AvatarComponent /> ?? null
  }

  if (!IS_MOBILE())
    return (
      <AvatarsModalWeb
        genderAvatarOptions={genderAvatarOptions}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        sendAvatarPayload={sendAvatarPayload}
        colorSettings={colorSettings}
        getAvatarComponent={getAvatarComponent}
      />
    )

  return (
    <BottomSheetComponent reference={avatarBottomSheetRef}>
      <View style={{ height: 400, width: '100%' }}>
        <Text style={styles.textAvatarsSheet}>{AVATAR_SELECT_TITLE}</Text>

        <View style={styles.flatListContainer}>
          <FlatList
            data={genderAvatarOptions}
            numColumns={3}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={async () => {
                  await sendAvatarPayload({
                    icon: item.gender,
                    skinTone: item.skinTone,
                  })
                }}
              >
                <View style={styles.avatarsContainer}>
                  {getAvatarComponent(item.image)}
                </View>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </BottomSheetComponent>
  )
}
