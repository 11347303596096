import {
  fetchSubmitSurveyAnswers,
  fetchDeleteSurveyAnswer,
  fetchUpdateSurveyAnswer,
} from './fetchHandlers'

async function handleSelectAllThatApply({
  payload,
  matchingAnswers,
  callback,
}) {
  const answers = matchingAnswers.filter(
    (answer) =>
      parseInt(answer.master_question_choice_id, 10) ===
      parseInt(payload.master_question_choice_id, 10)
  )

  const { shouldDeleteExistingItem, shouldSubmitAnswer } = payload
  if (shouldDeleteExistingItem) {
    payload.id = answers[0]?.id

    await fetchDeleteSurveyAnswer({
      payload,
      callback,
    })

    return
  }

  if (shouldSubmitAnswer) {
    await fetchSubmitSurveyAnswers({ payload, callback })
  }
}

async function handleMatchingAnswers({ answers, payload, callback }) {
  if (answers.length === 0) {
    await fetchSubmitSurveyAnswers({
      payload,
      callback,
    })

    return
  }

  if (answers.length === 1) {
    payload.id = answers[0]?.id

    await fetchUpdateSurveyAnswer({
      payload,
      callback,
    })

    return
  }

  console.log(
    'Error: Should only have multiple answers if question type Select All That Apply'
  )
}

async function handleConditionalSurveyAnswerDeletion({
  payload,
  matchingAnswers,
  callback,
}) {
  const isFieldTypeValid =
    payload.field_type &&
    ['select_one', 'yes_or_no'].includes(payload.field_type)

  const isRepeatClick = payload.repeat_click

  const isSingleMatchingAnswer = matchingAnswers.length === 1

  if (!isFieldTypeValid || !isRepeatClick || !isSingleMatchingAnswer) {
    return false
  }

  payload.id = matchingAnswers[0]?.id

  await fetchDeleteSurveyAnswer({ payload, callback })

  return true
}

export {
  handleSelectAllThatApply,
  handleMatchingAnswers,
  handleConditionalSurveyAnswerDeletion,
}
