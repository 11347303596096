/* React & React Native libraries */
import { Text, View, TouchableOpacity } from 'react-native'

/* Custom Components and Constants */
import GenericFamilyListController from './controller.js'
import ProfileAvatar from '$components/FamilyCard/components/ProfileAvatar'
/* Custom Utilities */
import { i18n } from '$localization/config.js'
import {
  WIDTH_AND_HEIGHT_ADJUSTMENT,
  VIEW_BOX_ADJUSTMENT,
} from '$components/FamilyCard/constants'
import { formatDisplayedMemberName } from '$utils'
import DiseasesView from '$screens/main/family/_includes/diseases/Diseases.view'
/* Custom Stylesheets */
import custom_styles from '$screens/main/family/_styles/main.styles.js'

import calculateRelationship from '../../../../main/family/FamilyListScreen/helpers/calculateRelationship'

class GenericFamilyListView extends GenericFamilyListController {
  renderSectionHeader = (section) => {
    const { colorSettings } = this.props

    if (!section || !section.data || section.data.length === 0) return null

    const _title_ = section.title ? section.title.toLowerCase() : ''
    const translatedTitle = i18n.t(_title_)?.default ?? '--'

    // Render section header
    return (
      <View
        style={[
          custom_styles.section,
          {
            backgroundColor: colorSettings?.member_section_bg,
          },
        ]}
      >
        <View style={custom_styles.sectionTitleView}>
          <Text
            style={[
              custom_styles.sectionTitleText,
              {
                color: colorSettings?.member_section_title,
              },
            ]}
          >
            {translatedTitle}
          </Text>
        </View>
      </View>
    )
  }

  renderItem = ({ item, relationshipList = [], handleAvatarClick }) => {
    if (!item) return null

    /* Make a copy of member */
    const member = item

    /* Check if Member is valid or is still existing. */
    if (!member?.member_id || member?.is_deleted) return null

    /* Get Member name, age, and vital status. */
    const titleText = formatDisplayedMemberName(
      member?.name,
      member?.age_string ?? member?.age,
      member?.is_dead
    )

    const { relationship } = calculateRelationship(member, relationshipList)

    return (
      <View key={member?.member_id} style={{ width: '100%', paddingBottom: 0 }}>
        <View
          style={[custom_styles.itemViewContainer, { paddingHorizontal: 10 }]}
        >
          <TouchableOpacity
            onPress={() => this.navigateToMemberProfile(member)}
            activeOpacity={0.8}
            style={{ width: '100%' }}
          >
            <View style={[custom_styles.itemView, { marginVertical: 8 }]}>
              <TouchableOpacity
                style={custom_styles.itemImageContainer}
                onPress={() => handleAvatarClick(member)}
              >
                <ProfileAvatar
                  member={member}
                  width={WIDTH_AND_HEIGHT_ADJUSTMENT}
                  height={WIDTH_AND_HEIGHT_ADJUSTMENT}
                  viewBox={VIEW_BOX_ADJUSTMENT}
                />
              </TouchableOpacity>

              <View style={custom_styles.itemDetailsContainer}>
                <Text style={custom_styles.itemDetailsTitleText}>
                  {titleText}
                </Text>

                <View style={{ height: 4.0 }} />

                <Text
                  style={[
                    custom_styles.itemDetailsSubtitleText,
                    { fontFamily: 'montserrat-italic' },
                  ]}
                >
                  {relationship}

                  {/* //TODO: I added this in order to add a number to the family members without a name
                  {noNameFamilyIndex} */}
                </Text>

                <View style={{ height: 2.0 }} />
                <DiseasesView diseases={member?.diseases} />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

export default GenericFamilyListView
