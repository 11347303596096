import { StyleSheet } from 'react-native'

const WHITE = 'white'
const BLACK = 'black'

const styles = StyleSheet.create({
  autocompleteListButtons: (item, colorSettings) => ({
    borderRadius: 40,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    height: 50,
    width: '85%',
    borderWidth: 1,
    marginBottom: 5,
    alignSelf: 'center',
    backgroundColor: item.isSelected
      ? colorSettings.selectable_btn_active_1 || WHITE
      : colorSettings.selectable_btn_inactive_1 || BLACK,
    borderColor: colorSettings.selectable_btn_border_inactive_1 || BLACK,
  }),
  autocompleteListButtonText: (colorSettings) => ({
    height: 50,
    fontSize: 16,
    paddingTop: 5,
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colorSettings?.text_input_label_color_2 || BLACK,
  }),
})

export default styles
