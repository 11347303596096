export default function formatText(text) {
  if (!text) return text

  const inputValue = text
    .split(' ')
    .join('_')
    .toLowerCase()
    .replace(/[^-0-9a-zA-Z _]/g, '')

  return inputValue
}
