import { StyleSheet } from 'react-native'

const DARKER_GREY = 'rgb(74,74,74)'

export default StyleSheet.create({
  surveyListScrollView: { width: '100%' },
  surveyListContainer: {
    paddingHorizontal: 16,
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyMessageText: {
    fontFamily: 'karla-bold',
    fontSize: 14,
    color: DARKER_GREY,
    textAlign: 'center',
    paddingHorizontal: 24,
  },
})
