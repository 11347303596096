import { Dimensions } from 'react-native'
import { DOMAIN_CLIENT } from '$utils/globalVariables.js'

export const resizeLogo = () => {
  const logoSize = {}
  const screenWidth = Dimensions.get('window').width
  const isWhiteLabelClient = DOMAIN_CLIENT === 'COH-APP'

  if (screenWidth <= 500) {
    logoSize.width = 300
    logoSize.height = isWhiteLabelClient ? 300 : 150
  } else {
    logoSize.width = isWhiteLabelClient ? 350 : 500
    logoSize.height = isWhiteLabelClient ? 350 : 200
  }

  return logoSize
}
