import { mapDataToMemberProfile } from '$utils'
import { apiFetchHandler } from '$api'

const updateMemberProfile = async (memberData, authCredentials) => {
  const getMemberProfilePayload = {
    path: 'member/get/',
    method: 'post',
    token: authCredentials.accessToken,
    body: { ...memberData },
    pageDetails: {
      page: 'UpdateMemberProfile.js',
    },
  }

  const response = await apiFetchHandler(getMemberProfilePayload)

  if (response.isError) return

  const mapMemberProfile = mapDataToMemberProfile(response)

  return mapMemberProfile
}

export default updateMemberProfile
