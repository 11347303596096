import pagerBuilder from '../pageBuilder'
import { store } from '$redux/configureStore'

function calculateCurrentPage({ currentPage, allowedPages = [] }) {
  if (currentPage === 0) return 1

  if (allowedPages.includes(currentPage)) {
    return currentPage
  }

  const pagesGreaterThanCurrent = allowedPages.filter(
    (page) => page > currentPage
  )

  const newCurrentPage =
    pagesGreaterThanCurrent.length > 0
      ? Math.min(...pagesGreaterThanCurrent)
      : currentPage

  return newCurrentPage
}

function calculatePreviousPage({ currentPage = 1, allowedPages = [1, 2] }) {
  if (currentPage === 0 || currentPage === 1) return 0

  const pagesLessThanCurrent = allowedPages.filter((page) => page < currentPage)

  const newPreviousPage =
    pagesLessThanCurrent.length > 0
      ? Math.max(...pagesLessThanCurrent)
      : currentPage

  return newPreviousPage
}

function calculateAllowedSurveyPages(buildPagePayload, totalPages) {
  const renderedPages = []

  while (buildPagePayload.current_page <= totalPages) {
    const surveyFieldsCanvas = pagerBuilder(buildPagePayload)

    const removeFalsyValues = (item) => Boolean(item)
    const content = surveyFieldsCanvas.filter(removeFalsyValues)

    if (content.length > 0) {
      renderedPages.push(buildPagePayload.current_page)
    }

    buildPagePayload.current_page += 1
  }

  return renderedPages
}

async function handleGetAllowedSurveyPages({ surveyApiResponse, callback }) {
  const { colorSettings } = store.getState().store

  const buildPagePayload = {
    current_page: 1,
    survey: surveyApiResponse?.survey,
    proband: surveyApiResponse?.proband,
    proband_diseases: surveyApiResponse?.proband_diseases,
    proband_disease_skip_logics: surveyApiResponse?.proband_disease_skip_logics,
    completed_survey_answers: surveyApiResponse?.completed_survey_answers,
    storeFieldSurveyAnswers: () => null,
    deleteCompletedSurveyAnswer: () => null,
    deleteAnswer: () => null,
    colorSettings,
  }

  let result

  try {
    result = await new Promise((resolve, reject) => {
      try {
        const output = calculateAllowedSurveyPages(
          buildPagePayload,
          surveyApiResponse?.survey?.total_pages
        )

        resolve(output)
      } catch (error) {
        console.log('error', error)
        reject(error)
      }
    })

    callback(result)
  } catch (error) {
    result = []
    callback(result)
  }

  return result
}

export {
  calculateCurrentPage,
  calculatePreviousPage,
  handleGetAllowedSurveyPages,
}
