import * as SecureStore from 'expo-secure-store'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'

export const saveSecureStoreData = async ({ key, value }) => {
  if (Platform.OS === 'web') return
  try {
    await SecureStore.setItemAsync(key, value)
    return true
  } catch (error) {
    console.log(
      '🚀 \n\n file: SecureStore.js: 11 \n\n saveSecureStoreData \n\n error:',
      error
    )

    return false
  }
}

export const getData = async ({ key }) => {
  if (Platform.OS === 'web') return
  try {
    const value = await SecureStore.getItemAsync(key)
    return value
  } catch (error) {
    console.log(
      '🚀 \n\n file: SecureStore.js:26 \n\n getData \n\n error:',
      error
    )

    return false
  }
}

export const deleteData = async ({ key }) => {
  if (Platform.OS === 'web') return
  try {
    await SecureStore.deleteItemAsync(key)
    return true
  } catch (error) {
    console.log(
      '🚀 \n\n file: SecureStore.js:41 \n\n deleteData \n\n error:',
      error
    )

    return false
  }
}

export const saveAsyncStorageData = async ({ key, value }) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem(key, jsonValue)
  } catch (error) {
    console.log(
      '🚀 \n\n file: SecureStore.js:26 \n\n saveAsyncStorageData \n\n error:',
      e
    )

    return false
  }
}

export const getAsyncStorageData = async ({ key }) => {
  try {
    const value = await AsyncStorage.getItem(key)
    if (value !== null) {
      return value
    }
    return null
  } catch (error) {
    console.log(
      '🚀 \n\n file: SecureStore.js:26 \n\n getAsyncStorageData \n\n error:',
      error
    )

    return false
  }
}
