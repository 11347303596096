import { Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux'
import { Ionicons } from '@expo/vector-icons'
import styles from '../../styles'
import { i18n } from '$localization/config.js'

function BackArrow({ handleCloseModal }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.backButtonContainer}>
      <TouchableOpacity onPress={handleCloseModal} style={styles.backButton}>
        <Ionicons
          name="arrow-back"
          size={24}
          color={colorSettings?.selectable_btn_text_active_1}
        />
        <Text
          style={[
            styles.backButtonText,
            { color: colorSettings?.selectable_btn_text_active_1 },
          ]}
        >
          {i18n.t('back.default')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default BackArrow
