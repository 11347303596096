const DEFAULT_ACTIVE_BG = 'green'
const DEFAULT_INACTIVE_BG = 'gray'
const DEFAULT_ACTIVE_DOT = 'white'
const DEFAULT_INACTIVE_DOT = 'white'

export {
  DEFAULT_ACTIVE_BG,
  DEFAULT_INACTIVE_BG,
  DEFAULT_ACTIVE_DOT,
  DEFAULT_INACTIVE_DOT,
}
