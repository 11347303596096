import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  genderIdentityOptionsContainer: {
    flexDirection: 'row',
    height: 120,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    marginVertical: 25,
  },
})

export default styles
