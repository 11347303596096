import { StyleSheet } from 'react-native'

const greyishBlack = 'rgba(74,74,74,0.5)'
const lightGreen = 'rgba(132,209,192,1)'
const white = '#fff'
const black = 'black'
const grey = 'rgb(74, 74, 74)'
const fadedWhite = 'rgb(204, 204, 204)'

export default StyleSheet.create({
  container: {
    backgroundColor: white,
    width: '100%',
    flex: 1,
  },
  scrollViewContentContainer: {
    alignItems: 'center',
    width: '100%',
  },
  simpleDatePickerContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1.0,
  },
  yearStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
    alignSelf: 'center',
  },
  datePickerContainer: {
    marginTop: 10,
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  monthStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
  },
  dayStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginTop: 7,
    height: 34,
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
  },
  content: {
    justifyContent: 'center',
    flex: 1.0,
  },
  textInputField: {
    fontFamily: 'montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: black,
  },
  helperTextContainer: {
    marginVertical: 10,
  },
  passwordHelperText: {
    color: greyishBlack,
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  },
  submitButton: {
    justifyContent: 'center',
    backgroundColor: lightGreen,
    width: '100%',
    height: '100%',
    borderWidth: 1.0,
  },
  submitButtonText: {
    color: white,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  keyboardAvoiding: {
    flex: 1,
  },
  scrollViewContainer: {
    height: 500,
  },
  textInputPasswordContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  textInputPassword: {
    paddingRight: 24,
  },
  showPasswordContainer: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
  },
  showPasswordImage: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  signUpContainer: {
    width: '100%',
    alignItems: 'center',
  },
  nextButtonContainer: {
    height: 60,
    width: '100%',
  },
  validateBirthdateTextContainer: {
    maxWidth: 800,
    alignItems: 'center',
  },
  validateBirthdateText: {
    marginTop: 15,
    fontSize: 24,
    fontWeight: '300',
    fontFamily: 'montserrat',
  },
  birthdateTitle: {
    marginTop: 10,
    fontSize: 14,
    color: 'rgba(74,74,74,0.5)',
    fontFamily: 'montserrat',
    alignSelf: 'flex-start',
  },
  createPasswordButtonContainer: {
    marginTop: 25,
    maxWidth: 800,
    alignItems: 'center',
  },
  createPasswordButtonText: {
    fontSize: 16,
    fontFamily: 'montserrat',
    textAlign: 'center',
  },
  dividerOrContainer: {
    flex: 0.2,
    marginTop: 35,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dividerOrLines: {
    backgroundColor: '#000000',
    width: 30,
    height: 1,
  },
  dividerOrText: {
    fontSize: 14,
    fontFamily: 'montserrat',
    marginHorizontal: 15,
    marginVertical: 5,
  },
  loginAccountContainer: {
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 35,
    marginTop: 25,
  },
  loginAccountHeaderContainer: {
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginAccountHeaderText: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'montserrat',
  },
  validateDobContainer: {
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 35,
    marginTop: 5,
    alignItems: 'center',
  },
})
