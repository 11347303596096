const cca2List = require('./cca2.json')

const getExcludedCountries = () => {
  const excludedCountries = cca2List || []
  const includedCountries = new Set([
    'AE',
    'AR',
    'AU',
    'AT',
    'BE',
    'BH',
    'BR',
    'IE',
    'US',
    'CC',
    'CX',
    'CA',
    'CL',
    'CO',
    'CZ',
    'DK',
    'EE',
    'FO',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LT',
    'LV',
    'MK',
    'MT',
    'MX',
    'NL',
    'NZ',
    'NO',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'GB',
    'VA',
    'TR',
    'IL',
    'JP',
    'HK',
    'ID',
    'FJ',
    'SG',
    'TW',
    'ZA',
    'IN',
  ])

  return excludedCountries.filter((cca2) => !includedCountries.has(cca2))
}

export default getExcludedCountries
