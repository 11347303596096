import { Component } from 'react'
import {
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  SafeAreaView,
  Platform,
} from 'react-native'

import Icon from 'react-native-vector-icons/Feather'
import ErrorBoundary from 'react-native-error-boundary'

import { connect } from 'react-redux'
import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
} from '$navigation/_utils/custom_workflow'

import { IS_WEB } from '$utils/Platforms.js'
import { platforms } from '$constants'
import LoadingView from '$components/LoadingView'
import CancerRiskCollapseView from './CancerRiskCollapseView'

import Account from '$data_models/Account'
import Proband from '$data_models/Proband'
import MemberProfile from '$data_models/MemberProfile.js'
import RiskFactorCriteria from '$data_models/RiskFactorCriteria.js'

import { saveLastScreen } from '$screens/utils'
import { apiFetchHandler } from '$api'

import {
  setRiskFactorsAction,
  clearRiskFactorsAction,
} from '$redux/risk_factor/actions.js'

import { setError } from '$redux/defaults/actions.js'

import global_styles, {
  calculateAvailableContentHeight,
} from '$assets/styles/global.styles.js'

import MainWrapper from '$components/MainWrapper'
import NextButtonAbsolute from '$components/NextButtonAbsolute'
import { FLOWS } from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

const styles = StyleSheet.create({
  container: {
    flex: 1.0,
  },
  contentContainerStyle: {
    marginHorizontal: 16,
  },
  content: {
    marginBottom: 16,
    marginTop: 6,
  },
})

class RisksScreen extends Component {
  static sharedInstance = null

  static setSharedInstance = (instance) => {
    this.sharedInstance = instance
  }

  authCredentials = this.props.authCredentials

  account = this.props.currentAccount || new Account()

  proband = this.props.currentProband || new Proband()

  probandProfile = this.props.currentProbandProfile || new MemberProfile()

  colorSettings = this.props.colorSettings

  backgroundRefreshTimer = null

  state = {
    ...this.state,
    shouldRenderComponent: false,
    showLoadingView: true,
    riskFactors: [],
    scrollViewHeight: calculateAvailableContentHeight(),
  }

  constructor(props) {
    super(props)

    // Set shared instance
    this.constructor.setSharedInstance(this)
  }

  async componentDidMount() {
    // Add listener to adjust scrollview height for Web
    if (IS_WEB()) {
      window.addEventListener('resize', () =>
        this.setState({ scrollViewHeight: calculateAvailableContentHeight() })
      )
    }

    this.props.navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={global_styles.navigationBarButton}
          onPress={this.getRiskFactors}
        >
          <Icon
            name="refresh-ccw"
            size={24}
            color={this.props.colorSettings?.nav_title_light}
          />
        </TouchableOpacity>
      ),
    })
    // Initial API request
    await this.getRiskFactors()

    // Activate auto background refresh.
    this.handleBackgroundRefresh(false)
  }

  componentWillUnmount() {
    this.resetBackgroundRefreshTimer()

    // Remove scrollview height listener for Web
    if (IS_WEB()) {
      window.removeEventListener('resize', () =>
        this.setState({ scrollViewHeight: calculateAvailableContentHeight() })
      )
    }
  }

  handleBackgroundRefresh = (activate = true) => {
    this.resetBackgroundRefreshTimer()

    if (!activate) return
    const { allowBackgroundRefresh, backgroundRefreshInterval } = this.props

    if (allowBackgroundRefresh) {
      this.backgroundRefreshTimer = setInterval(async () => {
        // Call API request again.

        await this.getRiskFactors()
      }, backgroundRefreshInterval)
    }
  }

  resetBackgroundRefreshTimer = () => {
    clearInterval(this.backgroundRefreshTimer)
    this.backgroundRefreshTimer = null
  }

  getRiskFactors = async () => {
    const { _setRiskFactorsAction_, saveError } = this.props

    this.setState({
      shouldRenderComponent: false,
      showLoadingView: true,
    })

    const getRiskFactorsPayload = {
      path: 'risk/check_risk/',
      method: 'post',
      token: this.authCredentials.accessToken,
      body: {
        proband_id: this.proband.probandID,
      },
      pageDetails: {
        page: 'RisksScreen.js',
      },
    }

    const response = await apiFetchHandler(getRiskFactorsPayload)

    if (response.isError) {
      saveError({
        isShown: true,
        status: response.status,
        message: response.error,
      })
      return
    }

    const { result } = response

    const riskFactors = []

    for (let i = 0; i < result.length; i++) {
      const element = result[i]
      const _riskFactor_ = new RiskFactorCriteria()

      // Convert risk factor text to translatable string.
      let riskFactorCode = `${element.app_risk_factors}`
      riskFactorCode = riskFactorCode.toLowerCase()
      riskFactorCode = riskFactorCode.replace(/ /g, '_')
      riskFactorCode = riskFactorCode.replace(/\./g, '_dot')
      riskFactorCode = riskFactorCode.replace(/\?/g, '_qm')
      riskFactorCode = riskFactorCode.replace(/\,/g, '_comma')
      riskFactorCode = riskFactorCode.replace(/\'/g, '')
      riskFactorCode = riskFactorCode.replace(/\"/g, 'dquote')
      riskFactorCode = riskFactorCode.replace(/\:/g, '_col')
      riskFactorCode = riskFactorCode.replace(/\;/g, '_semicol')
      riskFactorCode = riskFactorCode.replace(/\//g, '_slash_')

      _riskFactor_.criteriaID = element.criteria_id
      _riskFactor_.riskFactor = element.app_risk_factors
      _riskFactor_.riskFactorCode = riskFactorCode
      _riskFactor_.clinicianRiskFactor = element.clinician_risk_factors

      riskFactors.push(_riskFactor_)
    }

    // Save riskFactors to redux store.
    _setRiskFactorsAction_(riskFactors)

    // this.setState(this.state);
    this.setState({
      shouldRenderComponent: true,
      showLoadingView: false,
    })
  }

  nextButtonAction = () => {
    const {
      navigation,
      isCustomWorkflow,
      account,
      authCredentials,
      saveError,
    } = this.props

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: FLOWS.MainStack.name,
    })

    if (isCustomWorkflow) {
      const screen = {
        last_screen: RAW_LAST_VISITED_SCREENS.risk_screen,
      }

      saveLastScreen({
        account,
        authCredentials,
        item: screen,
        saveError,
        navigation,
      })
    }

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  navigateToGeneticResources = (routeName) => {
    const { navigate } = this.props.navigation

    navigate('GeneticResources')
  }

  navigateToRiskInfo = () => {
    const { navigate } = this.props.navigation
    navigate('RiskInfoView')
  }

  render() {
    const { navigation } = this.props
    const { colorSettings, isCustomWorkflow } = this.props

    if (!this.state.shouldRenderComponent) {
      return (
        <SafeAreaView
          style={[
            global_styles.containerWhite,
            {
              backgroundColor:
                colorSettings?.post_onboarding_bgcolor ?? 'rgb(245,245,245)',
              ...Platform.select({
                [platforms.WEB]: {
                  flex: null,
                  height: this.state.scrollViewHeight,
                },
              }),
            },
          ]}
        >
          <LoadingView
            backgroundColor={colorSettings?.splash_bgcolor}
            tintColor={colorSettings?.btn_no_fill_border_1}
            textColor={colorSettings?.btn_no_fill_text_1}
            visible={this.state.showLoadingView}
          />
        </SafeAreaView>
      )
    }

    return (
      <ErrorBoundary>
        <MainWrapper navigation={navigation}>
          <SafeAreaView
            style={[
              global_styles.containerWhite,
              {
                backgroundColor:
                  colorSettings?.post_onboarding_bgcolor ?? 'rgb(245,245,245)',
                ...Platform.select({
                  [platforms.WEB]: {
                    flex: 1,
                  },
                }),
              },
            ]}
          >
            <ScrollView
              nativeID="web_scaled_main_container"
              bounces={false}
              contentContainerStyle={styles.contentContainerStyle}
            >
              <View style={styles.content}>
                <CancerRiskCollapseView
                  colorSettings={colorSettings}
                  data={this.props.riskFactors}
                  isCustomWorkflow={this.props.isCustomWorkflow}
                  navigateToGeneticResources={this.navigateToGeneticResources}
                  navigateToRiskInfo={this.navigateToRiskInfo}
                />
              </View>
            </ScrollView>
            {isCustomWorkflow && (
              <NextButtonAbsolute onPressAction={this.nextButtonAction} />
            )}
          </SafeAreaView>
        </MainWrapper>
      </ErrorBoundary>
    )
  }
}

function mapGlobalStateToProps(state) {
  const {
    store,
    accountStore,
    probandStore,
    riskFactorStore,
    customFlowRoutesStore,
  } = state
  return {
    /** Default Store */
    allowBackgroundRefresh: store.allowBackgroundRefresh,
    backgroundRefreshInterval: store.backgroundRefreshInterval,
    appSettings: store.appSettings,
    colorSettings: store.colorSettings,
    account: accountStore.account,
    isCustomWorkflow: customFlowRoutesStore.isCustomWorkflow,
    /** Account Store */
    authCredentials: accountStore.authCredentials,
    currentAccount: accountStore.account,
    /** Proband Store */
    currentProband: probandStore.proband,
    currentProbandProfile: probandStore.probandProfile,
    /** Risk Factor Store */
    riskFactors: riskFactorStore.riskFactors,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    _setRiskFactorsAction_: (data) => dispatch(setRiskFactorsAction(data)),
    _clearRiskFactorsAction_: () => dispatch(clearRiskFactorsAction()),
    saveError: (data) => dispatch(setError(data)),
  }
}

export default connect(mapGlobalStateToProps, mapDispatchToProps)(RisksScreen)
