import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'
import styles from './styles'

function ModalFooter({ onCancelPress, onSubmitPress, allowSubmitButton }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.modalFooterContainer}>
      <TouchableOpacity
        style={styles.modalFooterButton}
        onPress={onCancelPress}
      >
        <Text style={styles.modalFooterButtonCancelButtonText}>
          {i18n.t('cancel').default}
        </Text>
      </TouchableOpacity>

      <View style={styles.modalFooterButtonSeparator} />

      <TouchableOpacity
        disabled={!allowSubmitButton}
        style={styles.modalFooterButton}
        onPress={onSubmitPress}
      >
        <Text
          style={styles.modalFooterButtonSubmitButtonText(
            allowSubmitButton,
            colorSettings
          )}
        >
          {i18n.t('submit').default}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default ModalFooter
