import ErrorView from '$components/ErrorView'

import { setError } from '$redux/defaults/actions.js'

export default function ErrorViewComponent({ error, dispatch }) {
  const saveError = (data) => dispatch(setError(data))

  return (
    <ErrorView
      title={error?.status}
      messageBody={error?.message}
      onPress={(val) =>
        saveError({
          isShown: val,
          status: '',
          message: '',
        })
      }
      type={error?.type}
    />
  )
}
