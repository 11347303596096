import { CUSTOM_FLOW_ROUTES } from '$navigation/constants/routes'

const FALLBACK_STYLES = {
  BLACK_COLOR: 'black',
  WHITE_COLOR: 'white',
  BORDER_WIDTH: 1.0,
}
const CURRENT_SCREEN_NAME = CUSTOM_FLOW_ROUTES?.CustomSuccessScreen?.name
const FAMILY_MEMBERS_CANCER = CUSTOM_FLOW_ROUTES?.FamilyMembersCancer?.name

export { FALLBACK_STYLES, CURRENT_SCREEN_NAME, FAMILY_MEMBERS_CANCER }
