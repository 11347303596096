import { Component } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from 'accordion-collapse-react-native'

export default class RenderMultipleQuestion extends Component {
  question = this.props.question

  questionType = this.props.questionType

  parent = this.props.parent

  skipDetails = this.props.skipDetails

  skipId = this.props.skipId

  toggleEdit =
    typeof this.props.toggleEdit === 'undefined' ? false : this.props.toggleEdit

  skipLogicList = this.props.skipLogicList

  state = {
    selectOption: false,
    collapse: false,
  }

  onSelect = () => {
    this.setState((prev) => ({
      selectOption: !prev.selectOption,
    }))

    if (!this.state.selectOption) {
      this.parent.addToggleToSkipLogic(this.skipDetails)
    } else {
      this.parent.removeFromSelectedSkipLogic(this.skipDetails.id)
    }
  }

  componentDidMount() {
    const skipList = this.skipLogicList.skip_logic_list

    if (this.toggleEdit) {
      for (const val in skipList) {
        if (skipList[val].id == this.skipId) {
          this.setState({ selectOption: true })
        }
      }
    }
  }

  render() {
    return (
      <View style={{ width: '100%' }}>
        <Collapse
          style={{ width: '100%' }}
          isCollapsed={this.state.selectOption}
        >
          <CollapseHeader>
            <TouchableOpacity
              style={[
                styles.buttonSelect,
                this.state.selectOption
                  ? { backgroundColor: 'rgba(245,245,245,.5)', borderWidth: 0 }
                  : null,
              ]}
              onPress={this.onSelect}
              activeOpacity={1}
            >
              <Text style={styles.selectButtonText}>{this.question}</Text>
            </TouchableOpacity>
          </CollapseHeader>
          <CollapseBody>
            {this.parent.renderQuestion(
              this.questionType,
              this.skipDetails,
              this.skipId
            )}
          </CollapseBody>
        </Collapse>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#fff',
    height: 60,
    width: '100%',
    marginBottom: 10,
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})
