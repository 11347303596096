import { View, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'

import PhoneInput from 'react-native-phone-input'

// TODO: Update package, for now we are using the most recent compatible version
import CountryPicker from 'react-native-country-picker-modal'

import getExcludedCountries from './helpers'

import {
  INITIAL_COUNTRY_CODE,
  COUNTRY_PICKER_VISIBLE,
  ACTIVE_OPACITY,
  OFFSET,
  ANIMATION_TYPE,
  FILTER_PLACEHOLDER,
  MAIN_CONTAINER_ID,
  COUNTRY_PICKER_ID,
  PHONE_FLAG_AND_CODE_ID,
  TEXT_INPUT_ID,
  LANGUAGE_FR,
  LANGUAGE_NL,
  LANGUAGE_DE,
  TRANSLATION_KEY_FR,
  TRANSLATION_KEY_NL,
  TRANSLATION_KEY_DE,
  TRANSLATION_KEY_COMMON,
  PREFIX_TEXT_COLOR,
  EMPTY_STRING,
} from './constants'

import styles from './styles'

export default function CountryCodePhoneInput({
  sender,
  initialCountryCode = INITIAL_COUNTRY_CODE,
  countryPickerVisible = COUNTRY_PICKER_VISIBLE,
  colorSettings,
  prefixTextColor = PREFIX_TEXT_COLOR,
}) {
  const { selectedLanguage } = useSelector((state) => state.store)

  const handleGetTranslationKey = () => {
    switch (selectedLanguage) {
      case LANGUAGE_FR:
        return TRANSLATION_KEY_FR
      case LANGUAGE_NL:
        return TRANSLATION_KEY_NL
      case LANGUAGE_DE:
        return TRANSLATION_KEY_DE
      default:
        return TRANSLATION_KEY_COMMON
    }
  }

  const excludedCountries = getExcludedCountries()
  const initialCountry = initialCountryCode.toLowerCase()
  const translationKey = handleGetTranslationKey()

  return (
    <View nativeID={MAIN_CONTAINER_ID} style={styles.container}>
      <CountryPicker
        nativeID={COUNTRY_PICKER_ID}
        containerButtonStyle={styles.containerButtonStyle}
        placeholder={EMPTY_STRING}
        ref={(ref) => {
          // eslint-disable-next-line no-param-reassign
          sender.countryPicker = ref
        }}
        excludeCountries={excludedCountries}
        onSelect={(value) => sender?.countryPickerOnSelect(value)}
        onClose={() => sender?.toggleCountryPicker(false)}
        translation={translationKey}
        filterable
        autoFocusFilter={false}
        closeable
        filterPlaceholder={FILTER_PLACEHOLDER}
        showCallingCode
        animationType={ANIMATION_TYPE}
        visible={countryPickerVisible}
        withFilter
        withCallingCode
      />

      <TouchableOpacity
        style={styles.phoneInputContainer}
        activeOpacity={ACTIVE_OPACITY}
        onPress={sender.onPressFlag}
      >
        <View nativeID={PHONE_FLAG_AND_CODE_ID}>
          <PhoneInput
            style={styles.phoneInputPadding}
            nativeID={TEXT_INPUT_ID}
            ref={(ref) => {
              // eslint-disable-next-line no-param-reassign
              sender.phone = ref
            }}
            initialCountry={initialCountry}
            disabled
            onSelectCountry={sender.countryDidSelect}
            textStyle={styles.phoneInputText(prefixTextColor, colorSettings)}
            offset={OFFSET}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}
