import Constants from 'expo-constants'

export const {
  HIDE_RISK,
  SHOW_DOCUMENTS,
  GENETIC_RESOURCES_URL,
  SHOW_NEGATIVE_TEST,
  SHOW_POWERED_BY_LOGO,
  CUSTOM_SURVEY_URL,
  MULTI_LANGUAGE,
  BASE_URL,
  SHOW_NETWORK_LOGS,
  SHOW_ONLY_CONSOLE_LOGS,
  WEB_BUILD_NUMBER,
} = Constants.expoConfig.extra
