import { Text, TouchableOpacity, View } from 'react-native'

import { i18n } from '$localization/config.js'

import styles from './styles'

import { onPressDelete } from './helpers'

function DeleteProfileButton({ primaryAction, isGreatGrandparents }) {
  const deleteButtonText =
    i18n.t('delete_family_member')?.default ?? 'Delete Family Member'

  return (
    <View style={styles.buttonContainer}>
      <TouchableOpacity
        style={styles.button}
        activeOpacity={0.5}
        onPress={() => onPressDelete(primaryAction, isGreatGrandparents)}
      >
        <Text style={styles.buttonText}>{deleteButtonText}</Text>
      </TouchableOpacity>
    </View>
  )
}

export default DeleteProfileButton
