/* Built-in Javascript libraries */
import { Text, View, SafeAreaView, ScrollView } from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

/* Custom Components and Constants */
import BaseController from '$components/BaseController.js'

import MainWrapper from '$components/MainWrapper'

/* Custom Stylesheets */
import global_styles from '$assets/styles/global.styles.js'

class RiskInfoView extends BaseController {
  criteriaMetTitleText = [
    // "Meets one or more criteria from NCCN Guidelines®1,2 or ACMG Practice Guidelines3",
    i18n.t('meets_one_or_more_criteria_from')?.default,
    ' ',
    i18n.t('nccn_guidelines')?.default,
    '®',
    '[superscript]1,2',
    ' ',
    i18n.t('or')?.default,
    ' ',
    i18n.t('acmg_practice_guidelines')?.default,
    '[superscript]3',
  ]

  criteriaNotMetTitleText = [
    // "Assessed criteria includes NCCN Guidelines®1,2 or ACMG Practice Guidelines3",
    i18n.t('assessed_criteria_includes')?.default,
    ' ',
    i18n.t('nccn_guidelines')?.default,
    '®',
    '[superscript]1,2',
    ' ',
    i18n.t('or')?.default,
    ' ',
    i18n.t('acmg_practice_guidelines')?.default,
    '[superscript]3',
  ]

  numberedDescriptionText = [
    {
      number: '1',
      text: i18n.t(
        'referenced_with_permission_from_the_nccn_clinical_practice_guidelines_in_oncology_nccn_guidelines_for_genetic_slash_familial_col_high_risk_assessment_col_breast_comma_ovarian_comma_and_pancreatic_v_dot2_dot2021_dot_©_national_comprehensive_cancer_network_comma_inc_dot_2020_dot_all_rights_reserved_dot_accessed_february_9_comma_2021_dot_to_view_the_most_recent_and_complete_version_of_the_guideline_comma_go_online_to_nccn_dotorg_dot'
      )?.default,
    },
    {
      number: '2',
      text: i18n.t(
        'referenced_with_permission_from_the_nccn_clinical_practice_guidelines_in_oncology_nccn_guidelines_for_genetic_slash_familial_col_high_risk_assessment_col_colorectal_v_dot1_dot2020_dot_national_comprehensive_cancer_network_comma_inc_dot_2020_dot_all_rights_reserved_dot_accessed_august_27_comma_2020_dot_to_view_the_most_recent_and_complete_version_of_the_guideline_comma_go_online_to_nccn_dotorg_dotdisclaimer_col_the_nccn_guidelines®_and_other_content_provided_by_nccn_are_works_in_progress_that_may_be_refined_as_often_as_new_significant_data_becomes_available_dot_they_are_statements_of_consensus_of_its_authors_regarding_their_views_of_currently_accepted_approaches_to_treatment_dot_any_clinician_seeking_to_apply_or_consult_any_nccn_guidelines®_or_other_nccn_content_is_expected_to_use_independent_medical_judgment_in_the_context_of_individual_clinical_circumstances_to_determine_any_patient’s_care_or_treatment_dot_the_national_comprehensive_cancer_network_makes_no_warranties_of_any_kind_whatsoever_regarding_their_content_comma_use_or_application_and_disclaims_any_responsibility_for_their_application_or_use_in_any_way_dot'
      )?.default,
    },
    {
      number: '3',
      text: i18n.t(
        'hampel_h_comma_bennett_rl_comma_buchanan_a_comma_pearlman_r_comma_wiesner_gl_semicol_guideline_development_group_comma_american_college_of_medical_genetics_and_genomics_professional_practice_and_guidelines_committee_and_national_society_of_genetic_counselors_practice_guidelines_committee_dot_a_practice_guideline_from_the_american_college_of_medical_genetics_and_genomics_and_the_national_society_of_genetic_counselors_col_referral_indications_for_cancer_predisposition_assessment_dot_genet_med_dot_2015_jan_semicol171_col70-87_dot_doi_col_10_dot1038_slash_gim_dot2014_dot147_dot_epub_2014_nov_13_dot_pmid_col_25394175_dot'
      )?.default,
    },
  ]

  criteriaMet = this.props.riskFactors?.length > 0 || false

  render() {
    const { navigation } = this.props
    const { colorSettings } = this.props

    function TitleText({
      criteriaMet,
      criteriaMetTitleText,
      criteriaNotMetTitleText,
    }) {
      const layout = []
      const _titleText_ = criteriaMet
        ? criteriaMetTitleText
        : criteriaNotMetTitleText

      for (const i in _titleText_) {
        // TODO: Find a way to position superscript text higher than normal text.
        // Current status: Superscript texts appear like Subscript in App.
        const e = `${_titleText_[i]}`
        if (e.includes('[superscript]')) {
          // Modify style of superscript text.
          const temp_e = e.replace('[superscript]', '')
          layout.push(<Text style={{ fontSize: 7 }}>{`${temp_e}`}</Text>)
          layout.push(<Text style={{ fontSize: 7 }}> </Text>)
        } else {
          layout.push(<Text>{`${e}`}</Text>)
        }
      }

      return (
        <Text
          style={[
            global_styles.contentBulletedText,
            {
              fontFamily: 'montserrat-medium',
              textAlign: 'left',
            },
          ]}
        >
          {layout}
          {'\n'}
        </Text>
      )
    }

    function NumberedText({ numberedDescriptionText }) {
      const layout = []
      // TODO: REFACTOR THIS TO MAP
      for (const i in numberedDescriptionText) {
        const item = numberedDescriptionText[i]

        layout.push(
          <View
            style={{
              paddingRight: 36,
              paddingVertical: 3,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 25, height: 25 }}>
                <Text
                  style={[
                    global_styles.contentBulletedText,
                    {
                      textAlign: 'left',
                      fontFamily: 'montserrat-medium',
                    },
                  ]}
                >
                  {`${item.number}.`}
                </Text>
              </View>
              <Text
                style={[
                  global_styles.contentBulletedText,
                  {
                    textAlign: 'left',
                  },
                ]}
              >
                {item.text}
              </Text>
            </View>
          </View>
        )
      }

      return <View style={{ paddingHorizontal: 0 }}>{layout}</View>
    }

    return (
      <MainWrapper navigation={navigation}>
        <SafeAreaView
          style={[
            global_styles.containerWhite,
            {
              backgroundColor: colorSettings?.splash_bgcolor ?? 'white',
            },
          ]}
        >
          <ScrollView>
            <View style={global_styles.contentContainerPadded}>
              <TitleText
                criteriaMetTitleText={this.criteriaMetTitleText}
                criteriaMet={this.criteriaMet}
                criteriaNotMetTitleText={this.criteriaNotMetTitleText}
              />
              <NumberedText
                numberedDescriptionText={this.numberedDescriptionText}
              />
            </View>
          </ScrollView>
        </SafeAreaView>
      </MainWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { store, riskFactorStore } = state
  return {
    /** Default Store */
    colorSettings: store.colorSettings,
    /** Risk Factor Store */
    riskFactors: riskFactorStore.riskFactors,
  }
}

export default connect(mapStateToProps, null)(RiskInfoView)
