import { Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import styles from './styles'

function BorderedButton({
  buttonText = '',
  isFilled = false,
  onPress = () => {},
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <TouchableOpacity
      style={styles.loginButton(colorSettings, isFilled)}
      onPress={() => onPress()}
    >
      <Text style={styles.loginButtonText(colorSettings, isFilled)}>
        {buttonText}
      </Text>
    </TouchableOpacity>
  )
}

export default BorderedButton
