import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'
import axios from 'axios'

import { store } from '$redux/configureStore'

import { envConstants } from '$constants'

export function createAxiosNetworkTimeout(networkTimeout) {
  let source
  let clearNetworkTimeout

  if (!networkTimeout) {
    return { source, clearNetworkTimeout }
  }

  const { CancelToken } = axios
  source = CancelToken?.source()

  clearNetworkTimeout = setTimeout(() => {
    source.cancel('Network connection timeout')
  }, networkTimeout)

  return { source, clearNetworkTimeout }
}

export function logSentNetworkPayload({ method, url, body, params }) {
  console.groupCollapsed(`%c${method} ${url} - fetch response`, 'color: purple')
  console.log(`METHOD:\n  ${method}`)
  console.log(`URL:\n  ${url}`)
  console.log(`BODY:\n\n  ${JSON.stringify(body, null, 4)}`)
  console.log(`PARAMS:\n\n  ${JSON.stringify(params, null, 4)}`)
  console.groupEnd()
}

export function logNetworkResponse({ method, url, response }) {
  console.groupCollapsed(`%c${method} ${url} - fetch response`, 'color: purple')
  console.log(
    `RESPONSE.DATA:\n\n${JSON.stringify(response?.data, null, 4)}\n\n`
  )
  console.log(`RESPONSE:\n\n${JSON.stringify(response, null, 4)}`)
  console.groupEnd()
}

export function logErrorToSentry({
  pageDetails,
  path,
  method,
  errorMessage,
  errorObject,
}) {
  const { account = {} } = store.getState().accountStore
  const SentryInstance = Platform.OS === 'web' ? Sentry.Browser : Sentry.Native

  SentryInstance.withScope((scope) => {
    scope.setTag('USED_ID', account?.userID)
    scope.setTag('USER_ACCOUNT_ID', account?.accountID)
    scope.setTag('USER_EMAIL', account?.email)

    scope.setTag('envConstants.BASE_URL', envConstants.BASE_URL ?? null)
    scope.setTag('FILE_NAME', pageDetails?.page)
    scope.setTag('FILE_LINE', pageDetails?.line)

    scope.setTag('URL_PATH', path ?? null)
    scope.setTag('URL_METHOD', method ?? null)
    scope.setTag('SERVER_ERROR_MESSAGE', errorMessage ?? null)
    scope.setTag('SERVER_ERROR_STATUS', errorObject?.response?.status)

    SentryInstance.captureException(errorObject)
  })
}

export function logNetworkError({
  method,
  url,
  pageDetails,
  errorObject,
  errorMessage,
}) {
  console.groupCollapsed(
    `%c${method} ${url} - fetch error location`,
    'color: purple'
  )
  console.log(`ERROR IN\n\n  ${pageDetails?.page}\n`)
  console.log(`ERROR URL\n\n  ${url}\n`)
  console.log(`ERROR IN LINE\n\n  ${pageDetails?.line}`)
  console.groupEnd()

  console.groupCollapsed(
    `%c${method} ${url} - fetch error config`,
    'color: purple'
  )
  console.log(
    `ERROR.CONFIG:\n\n  ${JSON.stringify(errorObject?.config, null, 4)}`
  )
  console.groupEnd()

  console.groupCollapsed(
    `%c${method} ${url} - fetch error details`,
    'color: purple'
  )
  console.log(`ERROR STATUS:\n\n  ${errorObject?.response?.status}\n`)
  console.log(`ERRORMESSAGE:\n\n  ${errorMessage}\n`)
  console.log(
    `ERROR.RESPONSE:\n\n  ${JSON.stringify(errorObject?.response, null, 4)}\n`
  )
  console.log(`ERROR.MESSAGE:\n\n  ${errorObject?.message}`)
  console.groupEnd()

  console.groupCollapsed(
    `%c${method} ${url} - fetch error request`,
    'color: purple'
  )
  console.log(
    `ERROR.REQUEST:\n\n  ${JSON.stringify(errorObject?.request, null, 4)}\n`
  )
  console.log(`ERROR.REQUEST.RESPONSE:\n\n  ${errorObject?.request?._response}`)
  console.groupEnd()
}
