import { View, Text } from 'react-native'
import { i18n } from '$localization/config.js'
import RoundedButton from '$components/RoundedButton'
import { adjustFontSizeByLength } from '$utils'
import styles from './styles'

export default function ToggleButtonComponent({
  colorSettings,
  isToggled,
  onToggle,
  title,
  containerStyle,
}) {
  const yesTitleText = i18n.t('yes').title
  const noTitleText = i18n.t('no').title

  const handleYesButtonHighlight = () => {
    let bgColor = ''
    let borderColor = ''

    if (isToggled === null || !isToggled) {
      bgColor = colorSettings?.selectable_btn_border_active_1
      borderColor = colorSettings?.selectable_btn_border_inactive_1
    } else if (isToggled) {
      bgColor = colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
      borderColor = colorSettings?.selectable_btn_border_active_1
    }

    return { backgroundColor: bgColor, borderColor }
  }

  const handleNoButtonHighlight = () => {
    let bgColor = ''
    let borderColor = ''

    if (isToggled === null || isToggled) {
      bgColor = colorSettings?.selectable_btn_border_active_1
      borderColor = colorSettings?.selectable_btn_border_inactive_1
    } else if (!isToggled) {
      bgColor = colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
      borderColor = colorSettings?.selectable_btn_border_active_1
    }
    return { backgroundColor: bgColor, borderColor }
  }

  return (
    <View style={[containerStyle]}>
      <View style={styles.titleContainer}>
        <Text style={styles.title(colorSettings)}>{title}</Text>
      </View>
      <View style={[styles.buttonContainerStyle]}>
        <RoundedButton
          onPress={() => onToggle(true)}
          buttonStyle={[styles.buttonStyle, handleYesButtonHighlight()]}
        >
          <Text
            style={[
              styles.roundedButtonTitleText,
              {
                color: colorSettings?.selectable_btn_text_active_1,
                fontSize: adjustFontSizeByLength('Yes'),
              },
            ]}
          >
            {yesTitleText}
          </Text>
        </RoundedButton>
        <RoundedButton
          onPress={() => onToggle(false)}
          buttonStyle={[styles.buttonStyle, handleNoButtonHighlight()]}
        >
          <Text
            style={[
              styles.roundedButtonTitleText,
              {
                color: colorSettings?.selectable_btn_text_active_1,
                fontSize: adjustFontSizeByLength('No'),
              },
            ]}
          >
            {noTitleText}
          </Text>
        </RoundedButton>
      </View>
    </View>
  )
}
