import { apiFetchHandler } from '$api'

const fetchArchiveNotifications = async (
  notificationID,
  accessToken,
  probandID
) => {
  const setNotificationToArchivePayload = {
    path: `notification/${notificationID}/archive/`,
    method: 'post',
    token: accessToken,
    body: {
      proband_id: probandID,
    },
    pageDetails: {
      page: 'ListContainer.main.js',
    },
  }

  return apiFetchHandler(setNotificationToArchivePayload)
}

const fetchRespondToNotification = async (itemID, accessToken, payload) => {
  const respondToNotificationPayload = {
    path: `notification/${itemID}/response/`,
    method: 'post',
    token: accessToken,
    body: { ...payload },
    pageDetails: {
      page: 'ListContainer.js',
    },
  }

  return apiFetchHandler(respondToNotificationPayload)
}

export { fetchArchiveNotifications, fetchRespondToNotification }
