import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 45,
    alignSelf: 'center',
    borderColor: '#ccc',
    borderWidth: 1.0,
    borderRadius: 6,
    width: 360,
    backgroundColor: 'white',
  },
  contentContainer: {
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
  logoContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 1.0,
    borderColor: '#ccc',
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 16,
  },
  titleText: {
    fontFamily: 'montserrat',
  },
  arrowImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowImage: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    alignSelf: 'flex-start',
  },
  logoImage: {
    width: 40,
    height: 40,
    resizeMode: 'center',
  },
  placeholderImage: {
    backgroundColor: '#aaa',
    width: 40,
    height: 40,
    resizeMode: 'center',
  },
})

export default styles
