/* eslint-disable no-magic-numbers */

import { store } from '$redux/configureStore'
import {
  setNextIndexAction,
  setHasForcePassword,
} from '$redux/customFlowRoutes/actions'

import {
  FLOWS,
  NAVIGATORS,
  CUSTOM_FLOW_ROUTES,
} from '$navigation/constants/routes'

import { MAPPED_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

import {
  fetchWorkflow,
  setReduxWorkflowState,
  setInitialReduxWorkflowState,
  getFirstWorkflowRouteName,
  getCustomTextTranslation,
  handleNavigateToNormalWorkflowPreviousScreen,
} from './helpers'

// This function determine the 1st screen to go to in the:
// - Regular workflow
// - Short custom workflow
//    - with regular screens
//    - with custom screens
// - Clinician custom workflow
//    - with regular screens
//    - with custom screens

// SuccessScreenAccountCreated use the function to determine the first route on:
// - Regular workflow
// - Clinician custom workflow

// CustomValidateDOB use the function to determine the first route on:
// - Short custom workflow
export async function getFirstWorkflowScreen({
  id,
  fileName,
  defaultFirstRoute,
  lastVisitedScreen,
  isShortCustomWorkflow,
}) {
  setInitialReduxWorkflowState({
    isShortCustomWorkflow,
    hasLastVisitedScreen: Boolean(lastVisitedScreen),
  })

  const { screens, isCustomSignUp, hasForcedPassword, workflowType } =
    await fetchWorkflow({
      id,
      fileName,
    })

  if (hasForcedPassword) {
    store.dispatch(
      setHasForcePassword({
        hasForcePassword: true,
      })
    )
  }

  setReduxWorkflowState(screens, lastVisitedScreen, workflowType)

  const firstRoute = getFirstWorkflowRouteName(screens, lastVisitedScreen)

  if (!firstRoute) return defaultFirstRoute

  return firstRoute
}

// This function determine the next route to go:
// - If there is a 'custom workflow', it will determine the next route based on it
// - If there is no 'custom workflow' (meaning a 'regular worflow'), it will determine the next route based on the `defaultNextRoute` passed from the screen using this function
export function getNextWorkflowRoute({ defaultNextRoute }) {
  const { selectedScreens, nextRouteIndex, isCustomWorkflow } =
    store.getState().customFlowRoutesStore

  if (!isCustomWorkflow) return defaultNextRoute

  if (!nextRouteIndex) return FLOWS?.MainStack?.name

  const nextRoute = selectedScreens.find(
    (screen) => screen.screen_order === nextRouteIndex
  )

  const isLastRoute = selectedScreens.length === nextRoute?.screen_order

  store.dispatch(
    setNextIndexAction({
      nextRouteIndex:
        isLastRoute || !nextRoute ? null : nextRoute.screen_order + 1,
    })
  )

  if (!nextRoute) return defaultNextRoute

  return nextRoute.name
}

// This function navigate to the next screen based on the `getNextWorkflowRoute` result
//  - It handles also the navigation to the next route in case passing 'selectedScreen' param
//  - It handles constructing the necessary stack to navigate to any screen part of the custom workflow (CUSTOM_FLOW_ROUTES)
export function handleNavigateToNextScreen({
  navigation,
  routeName,
  selectedScreen = '',
  params = {},
}) {
  const { workflowType } = store.getState().customFlowRoutesStore

  const isScreeningWorkflow = workflowType === 'screening'

  const routeId = !selectedScreen ? routeName : selectedScreen

  const accessKey = !selectedScreen ? 'routeName' : 'selected_screen'

  switch (routeId) {
    // isSuccessScreenAccountCreated
    case MAPPED_LAST_VISITED_SCREENS.SuccessScreenAccountCreated[accessKey]:
      navigation.navigate(FLOWS.AuthStack.name, {
        screen: routeName,
        params: {
          ...params,
        },
      })
      break

    // isSignupClinicianCodeScreen
    case MAPPED_LAST_VISITED_SCREENS.SignupClinicianCode[accessKey]:
      navigation.navigate(FLOWS.AuthStack.name, {
        screen: NAVIGATORS.AuthNavigator.name,
        params: {
          screen: NAVIGATORS.SignupClinicianNavigator.name,
          params: {
            screen: routeName,
            params: {
              ...params,
            },
          },
        },
      })
      break

    // isPersonalInfoScreen
    case MAPPED_LAST_VISITED_SCREENS.PersonalGender[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.PersonalGenderIdentity[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.DiseaseListMainScreen[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.PersonalGeneticTesting[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.PersonalEthnicity[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.PersonalAncestry[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.SurveyCustom[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.FamilyMembersCancer[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.AddFamilyMemberViewCancer[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.FamilyMainViewCancer[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.RisksScreenView[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.EditProfileCancer[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.PersonalGeneticTestingCancer[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.DiseaseListMainScreenCancer[accessKey]:
      navigation.navigate(FLOWS.OnboardingStack.name, {
        screen: NAVIGATORS.PersonalDetailsNavigator.name,
        params: {
          screen: NAVIGATORS.PersonalDetailsNavigator.name,
          params: {
            screen: routeName,
            params: {
              ...params,
            },
          },
        },
      })
      break

    // isSuccessScreenPersonalHealthInfo
    case MAPPED_LAST_VISITED_SCREENS.SuccessScreenPersonalHealthInfo[accessKey]:
      navigation.navigate(FLOWS.OnboardingStack.name, {
        screen: NAVIGATORS.PersonalDetailsNavigator.name,
        params: {
          screen: routeName,
          params: {
            ...params,
          },
        },
      })
      break

    // isRelativesScreen
    case MAPPED_LAST_VISITED_SCREENS.ParentsGrandparentsList[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.ChildrenList[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.SiblingsList[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.PaternalAuntsUnclesList[accessKey]:
    case MAPPED_LAST_VISITED_SCREENS.MaternalAuntsUnclesList[accessKey]:
      navigation.navigate(FLOWS.PostOnboardingStack.name, {
        screen: NAVIGATORS.RelativesNavigator.name,
        params: {
          screen: NAVIGATORS.MainStack.name,
          params: {
            screen: routeName,
            params: {
              ...params,
            },
          },
        },
      })
      break

    default:
      if (isScreeningWorkflow) {
        return navigation.navigate(FLOWS.MainStack.name, {
          screen: CUSTOM_FLOW_ROUTES.CustomSuccessScreen.name,
        })
      }

      navigation.navigate(FLOWS.MainStack.name)

      break
  }
}

// This function determines the header back button behavior, and either show it or not
export function handleNavigateToPreviousScreen(currentRouteName = null) {
  const { selectedScreens, nextRouteIndex, isCustomWorkflow } =
    store.getState().customFlowRoutesStore

  if (!isCustomWorkflow) {
    return {
      canNavigationPop: true,
      dispatchPreviousRoute: () => null,
      handleNavigateToNormalWorkflowPreviousScreen: () =>
        handleNavigateToNormalWorkflowPreviousScreen(),
    }
  }

  const isLastScreenIndexNull = !nextRouteIndex
    ? selectedScreens.length
    : nextRouteIndex - 1

  const previousRoute = selectedScreens.find(
    (screen) => screen.screen_order === isLastScreenIndexNull
  )

  const dispatchPreviousRoute = () => {
    if (previousRoute === undefined) return null

    const isScreenFromCustomWorkflow = selectedScreens.some(
      (screen) => screen?.name === currentRouteName
    )

    if (isScreenFromCustomWorkflow) {
      store.dispatch(
        setNextIndexAction({
          nextRouteIndex: previousRoute.screen_order,
        })
      )
    }
  }

  const handlePreviousScreenToNavigate = () => {
    if (previousRoute === undefined) return null

    return selectedScreens.find(
      (element) => element?.screen_order === previousRoute.screen_order
    )
  }

  if (!handlePreviousScreenToNavigate()) {
    return {
      canNavigationPop: false,
      dispatchPreviousRoute: () => null,
    }
  }

  if (previousRoute === undefined)
    return {
      canNavigationPop: false,
      dispatchPreviousRoute: () => null,
    }

  return {
    canNavigationPop: true,
    dispatchPreviousRoute: () => dispatchPreviousRoute(),
    handlePreviousScreenToNavigate: () => handlePreviousScreenToNavigate(),
  }
}

export function handleGetCustomScreenText(currentScreenName) {
  const { selectedScreens } = store.getState().customFlowRoutesStore

  const currentScreen = selectedScreens.find(
    (screen) => screen.name === currentScreenName
  )

  const hasCustomScreenText = currentScreen?.texts?.length > 0

  let rawCustomTextMapped = {
    hasCustomText: false,
    topText: '',
    middleText: '',
    bottomText: '',
    nextScreenText: {
      topText: '',
      middleText: '',
      bottomText: '',
    },
  }

  if (!hasCustomScreenText)
    return {
      hasCustomText: false,
      topText: '',
      middleText: '',
      bottomText: '',
      nextScreenText: {
        topText: '',
        middleText: '',
        bottomText: '',
      },
    }

  currentScreen?.texts.forEach((element) => {
    rawCustomTextMapped = getCustomTextTranslation(
      element,
      element.text_number,
      rawCustomTextMapped
    )
  })

  return rawCustomTextMapped
}
