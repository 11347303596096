import { View, TouchableOpacity, Text } from 'react-native'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import uuid from 'react-native-uuid'
import DiseasesView from '$screens/main/family/_includes/diseases/Diseases.view'
import GenesView from '$screens/main/family/_includes/genes/GenesView'

import FamilyCardButton from './components/FamilyCardButton'
import ProfileAvatar from './components/ProfileAvatar'

import styles from './FamilyCard.style'

import { IS_MOBILE } from '$constants/platforms'
import {
  DEFAULT_MEMBER,
  DEFAULT_FUNCTION,
  DEFAULT_RELATIONSHIP,
  DEFAULT_CUSTOM_STYLES,
  DEFAULT_TITLE_TEXT,
  DEFAULT_COLOR_SETTINGS,
  DEFAULT_COMPONENT,
  DEFAULT_CHILDREN_VIEW,
  PERSON_ADD_ICON_NAME,
  PENCIL_OUTLINE_ICON_NAME,
  WIDTH_AND_HEIGHT_ADJUSTMENT,
  VIEW_BOX_ADJUSTMENT,
} from './constants'

export default function FamilyCard({
  member = DEFAULT_MEMBER,
  navigateToMemberProfile = DEFAULT_FUNCTION,
  getGenderIconBorderRadius = DEFAULT_FUNCTION,
  addToMemberButtonAction = DEFAULT_FUNCTION,
  relationship = DEFAULT_RELATIONSHIP,
  customStyles = DEFAULT_CUSTOM_STYLES,
  titleText = DEFAULT_TITLE_TEXT,
  colorSettings = DEFAULT_COLOR_SETTINGS,
  component = DEFAULT_COMPONENT,
  childrenView = DEFAULT_CHILDREN_VIEW,
  handleAvatarClick,
}) {
  return (
    <View
      listKey={uuid.v4()}
      key={member?.member_id}
      style={childrenView ? styles.childrenCardContainer : styles.cardContainer}
    >
      <View
        style={
          childrenView ? styles.childrenMiddleContainer : styles.middleContainer
        }
      >
        <View
          style={[
            customStyles.itemViewContainer,
            childrenView
              ? styles.childrenInnerContainer
              : styles.innerContainer,
          ]}
        >
          <TouchableOpacity
            onPress={navigateToMemberProfile}
            activeOpacity={0.8}
            style={styles.cardButton}
          >
            <View style={[customStyles.itemView, styles.profileContainer]}>
              <View
                style={[customStyles.itemImageContainer, styles.profileImage]}
              >
                <TouchableOpacity
                  onPress={() => handleAvatarClick(member)}
                  style={[customStyles.itemImageContainer, styles.profileImage]}
                >
                  <View styles={getGenderIconBorderRadius}>
                    <ProfileAvatar
                      width={WIDTH_AND_HEIGHT_ADJUSTMENT}
                      height={WIDTH_AND_HEIGHT_ADJUSTMENT}
                      viewBox={VIEW_BOX_ADJUSTMENT}
                      member={member}
                    />
                  </View>
                </TouchableOpacity>
              </View>

              <View style={customStyles.itemDetailsContainer}>
                <Text style={customStyles.itemDetailsTitleText}>
                  {titleText}
                </Text>

                <View style={styles.divider} />

                <Text
                  style={[
                    customStyles.itemDetailsSubtitleText,
                    styles.subtitleText,
                  ]}
                >
                  {relationship}
                </Text>

                <View style={styles.divider} />

                <DiseasesView diseases={member?.diseases} />
                <GenesView genes={member?.genes} />
              </View>

              <View
                style={[
                  customStyles.itemAccessoryContainer,
                  styles.addButtonContainer,
                ]}
              >
                <FamilyCardButton
                  customStyles={customStyles}
                  addToMemberButtonAction={addToMemberButtonAction}
                  icon={
                    <Ionicons
                      name={PERSON_ADD_ICON_NAME}
                      size={19}
                      color={colorSettings?.post_onboarding_nav_bgcolor}
                      style={styles.iconPadding}
                    />
                  }
                />
                <FamilyCardButton
                  customStyles={customStyles}
                  addToMemberButtonAction={navigateToMemberProfile}
                  icon={
                    <MaterialCommunityIcons
                      name={PENCIL_OUTLINE_ICON_NAME}
                      size={20}
                      color={colorSettings?.post_onboarding_nav_bgcolor}
                      style={styles.materilaIconPadding}
                    />
                  }
                />
              </View>
            </View>
          </TouchableOpacity>

          {component}
        </View>
      </View>
    </View>
  )
}
