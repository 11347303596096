/* eslint-disable camelcase */

function getPageCategories(survey, currentPage) {
  return survey.survey_categories
    .filter((category) => category.page_num === currentPage)
    .sort((a, b) => a.order - b.order)
}

function handleIsFieldShown(questionWorkflow) {
  const { show: isShown } = questionWorkflow

  if (isShown) return true

  return false
}

// eslint-disable-next-line import/prefer-default-export
export { getPageCategories, handleIsFieldShown }
