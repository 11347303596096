import { StyleSheet } from 'react-native'
import { FALLBACK_COLOR } from '../../constants'

const styles = StyleSheet.create({
  nextButton: (colorSettings) => ({
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    height: 60,
    width: 280,
    backgroundColor:
      colorSettings?.success_screen_account_button_bg ?? FALLBACK_COLOR.WHITE,
    borderColor:
      colorSettings?.success_screen_account_button_border ??
      FALLBACK_COLOR.BLACK,
    borderWidth: 1.0,
  }),
  nextButtonText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
    color:
      colorSettings?.success_screen_account_button_text ?? FALLBACK_COLOR.BLACK,
  }),
})

export default styles
