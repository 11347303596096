import React from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { saveLastScreen } from '$screens/utils'

import { setError, setTurnOnIsFirstLogin } from '$redux/defaults/actions.js'

import { i18n } from '$localization/config.js'

import { SuccessIcon, ContinueAndSkipButtons, Content } from './components'
import {
  getNextWorkflowRoute,
  handleNavigateToNextScreen,
  handleGetCustomScreenText,
} from '$navigation/_utils/custom_workflow'

import styles from './styles.js'

import {
  CUSTOM_FLOW_ROUTES,
  NAVIGATORS,
  FLOWS,
} from '$navigation/constants/routes'
import { RAW_LAST_VISITED_SCREENS } from '$navigation/constants/lastVisitedScreensRoutes'

export default function SuccessScreenPersonalHealthInfo({ navigation }) {
  const dispatch = useDispatch()

  const { colorSettings, account, authCredentials } = useSelector((state) => ({
    colorSettings: state.store.colorSettings,
    account: state.accountStore.account,
    authCredentials: state.accountStore.authCredentials,
  }))

  const CURRENT_SCREEN_NAME =
    CUSTOM_FLOW_ROUTES?.SuccessScreenPersonalHealthInfo?.name

  const customScreenText = handleGetCustomScreenText(CURRENT_SCREEN_NAME)

  const topScreenText = customScreenText?.topText
    ? customScreenText?.topText
    : i18n.t('personal_health_information_complete').default

  const middleScreenText = customScreenText?.middleText
    ? customScreenText?.middleText
    : i18n.t('now_lets_add_your_family_members').default

  const bottomScreenText = customScreenText?.bottomText
    ? customScreenText?.bottomText
    : i18n.t(
        'or_skip_this_part_if_you_are_adopted_and_dont_have_health_information_about_your_blood_relatives'
      ).default

  const nextButtonAction = () => {
    saveLastScreen({
      account,
      authCredentials,
      item: {
        last_screen: RAW_LAST_VISITED_SCREENS.relatives_grandparents,
      },
      saveError: (data) => dispatch(setError(data)),
    })

    const nextRoute = getNextWorkflowRoute({
      defaultNextRoute: CUSTOM_FLOW_ROUTES.ParentsGrandparentsList.name,
    })

    handleNavigateToNextScreen({
      navigation,
      routeName: nextRoute,
      selectedScreen: '',
    })
  }

  const skipButtonAction = () => {
    saveLastScreen({
      account,
      authCredentials,
      item: {
        last_screen: '',
      },
      saveError: (data) => dispatch(setError(data)),
    })

    dispatch(setTurnOnIsFirstLogin())

    navigation.navigate(FLOWS?.MainStack?.name, {
      screen: NAVIGATORS?.Main?.name,
    })
  }

  return (
    <View style={styles.container(colorSettings)}>
      <ScrollView>
        <View style={styles.contentContainer}>
          <SuccessIcon />

          <Content
            topScreenText={topScreenText}
            middleScreenText={middleScreenText}
            bottomScreenText={bottomScreenText}
            colorSettings={colorSettings}
          />

          <View style={styles.buttonsContainer}>
            <ContinueAndSkipButtons
              nextButtonAction={nextButtonAction}
              skipButtonAction={skipButtonAction}
              colorSettings={colorSettings}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
