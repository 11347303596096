import React, { useState } from 'react'
import {
  Modal,
  Image,
  TouchableOpacity,
  View,
  SafeAreaView,
} from 'react-native'

import { WebView } from 'react-native-webview'
import { useSelector } from 'react-redux'

import LoadingView from '$components/LoadingView'

import styles from './styles'

export default function SimpleWebViewer({
  backButtonAction = () => {},
  modalVisible,
  websiteURL,
}) {
  const [showLoadingView, setShowLoadingView] = useState(true)

  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Modal
      animationType="slide"
      visible={modalVisible}
      transparent
      style={styles.modal}
    >
      <SafeAreaView style={styles.safeAreaView}>
        <View style={styles.view}>
          <View style={styles.innerView}>
            <View style={styles.flexOne}>
              <TouchableOpacity
                style={styles.touchableOpacity}
                onPress={backButtonAction}
              >
                <Image
                  source={require('$assets/images/new_assets/back/black.png')}
                  style={styles.image}
                />
              </TouchableOpacity>
            </View>

            <View style={styles.flexOne} />
          </View>
        </View>
        <View style={styles.webViewContainer}>
          <WebView
            style={styles.webView}
            source={{ uri: websiteURL ?? '' }}
            onLoadStart={() => setShowLoadingView(true)}
            onLoad={() => setShowLoadingView(false)}
          />
        </View>
        <LoadingView
          backgroundColor={colorSettings?.splash_bgcolor}
          tintColor={colorSettings?.btn_no_fill_border_1}
          textColor={colorSettings?.btn_no_fill_text_1}
          visible={showLoadingView}
        />
      </SafeAreaView>
    </Modal>
  )
}
