import { StyleSheet } from 'react-native'
import { IS_MOBILE } from '$utils/Platforms.js'

const COLORS = {
  GRAY_SHADE: 'rgb(74,74,74)',
  LIGHT_GRAY_SHADE: 'rgb(102,102,102)',
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: IS_MOBILE() ? 1.0 : null,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyContainer: {
    width: '80%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    justifyContent: 'flex-end',
    height: '43%',
  },
  titleContainer: {
    justifyContent: 'center',
    height: '14%',
    marginTop: 10,
  },
  title: (colorSettings) => ({
    fontFamily: 'karla-bold',
    fontSize: 24,
    color: colorSettings?.text_input_title_color_1 || COLORS.GRAY_SHADE,
    textAlign: 'center',
  }),
  contentContainer: {
    justifyContent: 'flex-start',
    height: '43%',
  },
  content: (colorSettings) => ({
    fontFamily: 'montserrat',
    fontSize: 20,
    color: colorSettings?.text_input_title_color_1 || COLORS.LIGHT_GRAY_SHADE,
    textAlign: 'center',
  }),
  buttonContainer: {
    width: '100%',
  },
  image: {
    width: 152,
    height: 141,
    resizeMode: 'contain',
  },
  customSubmitButton: (colorSettings) => ({
    backgroundColor: colorSettings?.bottom_next_btn_enabled,
  }),
  customSubmitButtonTitle: (colorSettings) => ({
    color: colorSettings?.bottom_next_btn_text,
  }),
})

export default styles
