import { StyleSheet } from 'react-native'

const BLACK = 'black'

export default StyleSheet.create({
  title: {
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
  },
  buttonContainerStyle: {
    flexDirection: 'row',
  },
  buttonStyle: {
    flex: 1,
    marginHorizontal: 10,
  },
  roundedButtonTitleText: (colorSettings, customFontSize) => ({
    fontFamily: 'montserrat-semibold',
    fontSize: customFontSize || 18,
    textAlign: 'center',
    color: colorSettings?.selectable_btn_text_active_1 || BLACK,
  }),
})
