import { StyleSheet } from 'react-native'

const COLORS = {
  TRANSPARENT_COLOR: 'transparent',
}

const FALLBACK_STYLES = {
  BLACK_COLOR: 'black',
  WHITE_COLOR: 'white',
  BORDER_WIDTH: 1.0,
  TEXT_DECORATION_LINE: 'underline',
}

const styles = StyleSheet.create({
  nextButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  continueButton: (colorSettings) => ({
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    height: 60,
    width: 280,
    borderColor:
      colorSettings?.success_screen_personal_health_info_button_border ||
      FALLBACK_STYLES.BLACK_COLOR,
    backgroundColor:
      colorSettings?.success_screen_personal_health_info_button_bg ||
      FALLBACK_STYLES.WHITE_COLOR,
    borderWidth: FALLBACK_STYLES.BORDER_WIDTH,
  }),
  continueButtonText: (colorSettings) => ({
    color:
      colorSettings?.success_screen_personal_health_info_button_text ||
      FALLBACK_STYLES.BLACK_COLOR,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  }),
  skipButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.TRANSPARENT_COLOR,
    borderRadius: 30,
    height: 60,
    width: 280,
  },
  skipButtonText: (colorSettings) => ({
    color:
      colorSettings?.success_screen_personal_health_info_skip_button_text ||
      FALLBACK_STYLES.BLACK_COLOR,
    textDecorationLine: FALLBACK_STYLES.TEXT_DECORATION_LINE,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  }),
})

export default styles
