import { useState } from 'react'
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  StyleSheet,
  FlatList,
} from 'react-native'

import CategoryButtonList from '$screens/relatives/profile/sections/health_history/components/disease_list_components/CategoryButtonList.js'

import { adjustFontSizeByLength } from '$utils'

import custom_styles from '$screens/relatives/profile/sections/health_history/_styles/custom.styles.js'

const DiseaseCategoryButton = (props) => {
  const { colorSettings } = props

  const selectedDisease = props.memberDisease.filter(
    (item) => item.disease_type === props.nameId
  )

  const renderDiseaseList = (itemData) => (
    <CategoryButtonList
      colorSettings={colorSettings}
      data={itemData}
      onEdit={props.onEdit}
      onDeleteMemberDisease={props.onDeleteMemberDisease}
      fromMain={props.fromMain}
    />
  )

  return (
    <View>
      <TouchableOpacity
        style={[
          custom_styles.buttonContainer,
          {
            backgroundColor: colorSettings?.btn_no_fill_enabled_2,
            borderColor: colorSettings?.btn_no_fill_border_2,
          },
        ]}
        onPress={() =>
          props.onPress(selectedDisease, props.nameId, props.title)
        }
      >
        <View style={custom_styles.buttonTitleTextContainer}>
          <Text
            style={[
              custom_styles.buttonTitleText,
              {
                color: colorSettings?.btn_no_fill_text_2,
                fontSize: adjustFontSizeByLength(props.title),
              },
            ]}
          >
            {props.title}
          </Text>
        </View>

        <View style={custom_styles.buttonAccessoryButtonContainer}>
          <Image
            source={require('$assets/images/new_assets/icon-dash-arrow-w.png')}
            style={{ width: 12, height: 20, resizeMode: 'contain' }}
          />
        </View>
      </TouchableOpacity>

      <FlatList
        keyExtractor={(item, index) => item.disease_id}
        data={selectedDisease}
        renderItem={renderDiseaseList}
        style={{ width: '100%' }}
      />
    </View>
  )
}

export default DiseaseCategoryButton
