import { Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../SearchInput/styles'
import { i18n } from '$localization/config.js'

function SelectedAncestries({ item, onPressRemove }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.listContainer}>
      <View style={styles.listItemContainer}>
        <Text style={styles.listItemText(colorSettings)}>
          {item?.display_name}
        </Text>
      </View>
      <TouchableOpacity
        style={styles.removeButtonContainer}
        onPress={() => onPressRemove(item)}
      >
        <Text style={styles.removeButtonText(colorSettings)}>
          {i18n.t('remove').default}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default SelectedAncestries
