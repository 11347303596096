import { useState } from 'react'
import { View } from 'react-native'

import NumericalTextInput from '$components/NumericalTextInput'
import LabelField from '../label-field/LabelField'
import styles from './NumberField.style'

export default function NumberField({ inputFieldData, colorSettings }) {
  const [count, setCount] = useState(0)

  const subtractCount = () => {
    if (count <= 1) return
    setCount((value) => value - 1)
  }

  const addCount = () => {
    setCount((value) => value + 1)
  }
  return (
    <View style={styles.container}>
      <LabelField title={inputFieldData.label} colorSettings={colorSettings} />
      <NumericalTextInput
        colorSettings={colorSettings}
        hasToggle={inputFieldData.has_toggle}
        placeholder={inputFieldData.placeholder}
        onChangeText={(value) => {
          setCount(value)
        }}
        subtractCount={() => subtractCount()}
        addCount={() => addCount()}
        count={count}
      />
    </View>
  )
}

// NumberField.propTypes = {
//   inputFieldData: PropTypes.shape({
//     input_choices: PropTypes.arrayOf(
//       PropTypes.shape({
//         id: PropTypes.number,
//         choice: PropTypes.string,
//         order: PropTypes.number,
//       })
//     ),
//     label: PropTypes.string,
//     placeholder: PropTypes.string,
//     has_toggle: PropTypes.bool,
//   }),
//   colorSettings: PropTypes.objectOf(PropTypes.string),
// }

NumberField.defaultProps = {
  inputFieldData: {},
  colorSettings: {},
}
