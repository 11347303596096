import { useNavigation } from '@react-navigation/core'
import { Text, View } from 'react-native'
import React, { useRef } from 'react'
import styles from '../main.styles'
import { i18n } from '$localization/config.js'
import ForgotPasswordButton from '$components/ForgotPasswordButton'
import SignupCustomInput from '$components/SignupCustomInput'
import { FIELD_NAMES } from '$components/SignupCustomInput/constants'

function LoginForm({ email, setEmail, password, setPassword }) {
  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const navigation = useNavigation()

  return (
    <View style={styles.loginAccountContainer}>
      <View style={styles.loginAccountHeaderContainer}>
        <Text style={styles.loginAccountHeaderText}>
          {i18n.t('already_have_an_account_login_instead')?.default}
        </Text>
      </View>

      <SignupCustomInput
        key={FIELD_NAMES.EMAIL}
        handleInputChange={(_, value) => setEmail(value)}
        inputValue={email}
        inputName={FIELD_NAMES.EMAIL}
        ref={emailRef}
        onChangeRefAction={() => passwordRef.current?.focusTextInput()}
        inputLabel={i18n.t('email.default')}
      />

      <SignupCustomInput
        key={FIELD_NAMES.PASSWORD}
        handleInputChange={(_, value) => setPassword(value)}
        inputValue={password}
        inputName={FIELD_NAMES.PASSWORD}
        ref={passwordRef}
        inputLabel={i18n.t('password.default')}
      />

      <ForgotPasswordButton
        onPress={() => navigation.navigate('ForgotPWSelectOption')}
      />
    </View>
  )
}

export default LoginForm
