import { StyleSheet } from 'react-native'

const WHITE = '#fff'

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    backgroundColor: colorSettings?.splash_bgcolor || WHITE,
    width: '100%',
    flex: 1,
  }),
  contentContainer: {
    flex: 1,
  },
  signUpSectionContainer: {
    width: '100%',
    maxWidth: 500,
    alignSelf: 'center',
    paddingHorizontal: 50,
    marginTop: 20,
  },
})

export default styles
