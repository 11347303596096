import { Dimensions, View } from 'react-native'
import { useEffect, useState } from 'react'
import styles from './styles'
import GlobalStyles from '$assets/styles/global.styles'
import LanguageControlView from './components/LanguageControlView'
import { APP_BUILD_NUMBER } from '$utils/envVariables'
import { VERSION_MODE } from '$utils/globalVariables'
import { capitalizeFirstLetter } from '$utils'
import { i18n } from '$localization/config'
import VersionBuild from './components/VersionBuild'

const windowDimensions = Dimensions.get('window')
const SMALL_DEVICE_BREAKPOINT = 500
const packageJsonFile = require('../../../package.json')

function AppInfo({ openLanguageSettings }) {
  const versionNumber = packageJsonFile?.version ?? Date.now()
  const buildNumber = APP_BUILD_NUMBER || Date.now().toString()
  const mode =
    VERSION_MODE?.toLowerCase() !== 'live'
      ? `(${capitalizeFirstLetter(VERSION_MODE)})`
      : ''

  const versionBuildText = `v: ${versionNumber} ${mode} | ${
    i18n.t('build')?.capitalize
  }: ${buildNumber}`

  const [width, setWidth] = useState(windowDimensions.width)

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', (_) => {
      setWidth(Dimensions.get('window').width)
    })

    return () => subscription?.remove()
  })

  const marginTop = width <= SMALL_DEVICE_BREAKPOINT ? 60 : 0
  const paddingHorizontal = width <= SMALL_DEVICE_BREAKPOINT ? 24 : 32

  return (
    <View
      style={[
        styles.container,
        {
          marginTop,
          paddingHorizontal,
        },
      ]}
    >
      <View style={GlobalStyles.bottomHorizontalContentFull}>
        <LanguageControlView openLanguageSettings={openLanguageSettings} />
        <VersionBuild versionBuildText={versionBuildText} />
      </View>
    </View>
  )
}

export default AppInfo
