import Constants from 'expo-constants'

const VERSION_MODE_VALUE = {
  TEST: 'test',
  LIVE: 'live',
}

function getAppConstants(appSlug) {
  const STORES_LINKS = {
    'famgenix-app-dev': {
      APP_STORE_LINK:
        'https://apps.apple.com/us/app/famgenix-family-health-history/id1483520084',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.famgenix&hl=en&gl=US',
      VERSION_MODE: VERSION_MODE_VALUE.TEST,
      DOMAIN_CLIENT: 'FAMGENIX',
      DOMAIN_CLIENT_NAME: 'FamGenix',
      CLIN_PORTAL_URL: 'https://dev.famgenix.com',
    },
    'famgenix-app-beta': {
      APP_STORE_LINK:
        'https://apps.apple.com/us/app/famgenix-family-health-history/id1483520084',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.famgenix&hl=en&gl=US',
      VERSION_MODE: VERSION_MODE_VALUE.TEST,
      DOMAIN_CLIENT: 'FAMGENIX',
      DOMAIN_CLIENT_NAME: 'FamGenix',
      CLIN_PORTAL_URL: 'https://beta.famgenix.com',
    },
    'cityofhope-app-beta': {
      APP_STORE_LINK:
        'https://apps.apple.com/us/app/city-of-hope-family-history/id1547843421',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.cityofhopedist&hl=en&gl=US',
      VERSION_MODE: VERSION_MODE_VALUE.TEST,
      DOMAIN_CLIENT: 'COH-APP',
      DOMAIN_CLIENT_NAME: 'City of Hope',
      CLIN_PORTAL_URL: 'https://portal-coh-test.famgenix.com',
    },
    'cityofhope-app-dist': {
      APP_STORE_LINK:
        'https://apps.apple.com/us/app/city-of-hope-family-history/id1547843421',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.cityofhopedist&hl=en&gl=US',
      VERSION_MODE: VERSION_MODE_VALUE.LIVE,
      DOMAIN_CLIENT: 'COH-APP',
      DOMAIN_CLIENT_NAME: 'City of Hope',
      CLIN_PORTAL_URL: 'https://portal-coh.famgenix.com',
    },
    'famgenix-app': {
      APP_STORE_LINK:
        'https://apps.apple.com/us/app/famgenix-family-health-history/id1483520084',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.famgenix&hl=en&gl=US',
      VERSION_MODE: VERSION_MODE_VALUE.LIVE,
      DOMAIN_CLIENT: 'FAMGENIX',
      DOMAIN_CLIENT_NAME: 'FamGenix',
      CLIN_PORTAL_URL: 'https://portal.famgenix.com',
    },
    'famgenix-app-ca': {
      APP_STORE_LINK: 'https://apps.apple.com/ca/app/famgenix-ca/id1534039209',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.famgenix.ca',
      VERSION_MODE: VERSION_MODE_VALUE.LIVE,
      DOMAIN_CLIENT: 'FAMGENIX',
      DOMAIN_CLIENT_NAME: 'FamGenix',
      CLIN_PORTAL_URL: 'https://portal-ca.famgenix.com',
    },
    'famgenix-app-eu': {
      APP_STORE_LINK:
        'https://apps.apple.com/ie/app/famgenix-eu-uk/id1530610619',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.famgenix.eu',
      VERSION_MODE: VERSION_MODE_VALUE.LIVE,
      DOMAIN_CLIENT: 'FAMGENIX',
      DOMAIN_CLIENT_NAME: 'FamGenix',
      CLIN_PORTAL_URL: 'https://portal-eu.famgenix.com',
    },
    'famgenix-apac': {
      APP_STORE_LINK:
        'https://apps.apple.com/us/app/famgenix-apac/id6444232211',
      PLAY_STORE_LINK:
        'https://play.google.com/store/apps/details?id=com.famhis.famgenix.apac',
      VERSION_MODE: VERSION_MODE_VALUE.LIVE,
      DOMAIN_CLIENT: 'FAMGENIX',
      DOMAIN_CLIENT_NAME: 'FamGenix',
      CLIN_PORTAL_URL: 'https://portal-apac.famgenix.com',
    },
  }

  return STORES_LINKS[appSlug]
}

const oneSecond = 1000
const oneMinute = oneSecond * 60

export const LOGOUT_TIMER = oneMinute * 30

export const APP_STORE_LINK = getAppConstants(
  Constants.expoConfig.slug
)?.APP_STORE_LINK

export const PLAY_STORE_LINK = getAppConstants(
  Constants.expoConfig.slug
)?.PLAY_STORE_LINK

export const VERSION_MODE = getAppConstants(
  Constants.expoConfig.slug
)?.VERSION_MODE

export const DOMAIN_CLIENT = getAppConstants(
  Constants.expoConfig.slug
)?.DOMAIN_CLIENT

export const DOMAIN_CLIENT_NAME = getAppConstants(
  Constants.expoConfig.slug
)?.DOMAIN_CLIENT_NAME

export const CLIN_PORTAL_URL = getAppConstants(
  Constants.expoConfig.slug
)?.CLIN_PORTAL_URL

function logGlobalVariables() {
  if (!__DEV__) return

  console.groupCollapsed('%cglobal Variables:', 'color: cadetblue')
  console.log(`LOGOUT_TIMER:\n  ${LOGOUT_TIMER}\n`)
  console.log(`APP_STORE_LINK:\n  ${APP_STORE_LINK}\n`)
  console.log(`PLAY_STORE_LINK:\n  ${PLAY_STORE_LINK}\n`)
  console.log(`VERSION_MODE:\n  ${VERSION_MODE}\n`)
  console.log(`DOMAIN_CLIENT:\n  ${DOMAIN_CLIENT}\n`)
  console.log(`DOMAIN_CLIENT_NAME:\n  ${DOMAIN_CLIENT_NAME}\n`)
  console.log(`CLIN_PORTAL_URL:\n  ${CLIN_PORTAL_URL}\n`)
  console.groupEnd()
}

logGlobalVariables()
