import { Image, TouchableOpacity, View } from 'react-native'
import React from 'react'
import styles from './styles'

function ShowPasswordButton({ showPassword, setShowPassword }) {
  const showPasswordIcon = showPassword
    ? require('$assets/images/icon/eye/black.png')
    : require('$assets/images/icon/eye-close/black.png')

  return (
    <View style={styles.showPasswordButtonContainer}>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => setShowPassword(!showPassword)}
      >
        <Image style={styles.showPasswordIcon} source={showPasswordIcon} />
      </TouchableOpacity>
    </View>
  )
}

export default ShowPasswordButton
