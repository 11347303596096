import { Linking } from 'react-native'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils'
import { i18n } from '$localization/config.js'
import Alert from '$components/Alert'

const handleContactUs = async () => {
  try {
    await Linking.openURL('https://famgenix.com/faq-app')
  } catch (err) {
    console.log(
      '🚀 \n\n file: CustomValidateDOB.js:166 \n\n handleContactUs \n\n err:',
      err
    )
  }
}

const errorResponseHandler = (response, saveError) => {
  const { status, error } = response

  switch (status) {
    case 500:
      showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t('looks_like_something_went_wrong')?.default
      )
      break
    case 403:
      showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t('looks_like_you_entered_the_wrong_birthdate_please_try_again')
          ?.default
      )
      break
    case 404:
      showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t('looks_like_something_went_wrong')?.default
      )
      break
    case 423:
      Alert.alert(
        i18n.t('oops')?.default,
        i18n.t(
          'you_entered_the_wrong_birthdate_too_many_times_your_account_is_now_locked'
        )?.default,
        [
          {
            text: i18n.t('contact_us')?.default,
            onPress: () => handleContactUs(),
          },
          { text: i18n.t('okay').title, style: 'cancel' },
        ]
      )
      break
    case 409:
      showDefaultAlert(
        i18n.t('account_already_exists').default,
        i18n.t('sorry_the_account_you_are_trying_to_register_already_exists')
          .default
      )
      break

    case 400:
      showDefaultAlert(
        i18n.t('oops').default,
        i18n.t('the_email_or_password_you_entered_is_incorrect').default
      )
      break
    default:
      saveError({
        isShown: true,
        status,
        message: error,
      })
      break
  }
}

const toggleNextButtonText = (allowSubmit, colorSettings) =>
  allowSubmit
    ? colorSettings?.bottom_next_btn_text_enabled ?? 'black'
    : colorSettings?.bottom_next_btn_text_disabled ?? 'black'

const toggleNextButtonColor = (allowSubmit, colorSettings) => {
  const btnStyle = allowSubmit
    ? 'bottom_next_btn_enabled'
    : 'bottom_next_btn_disabled'
  return {
    backgroundColor: colorSettings?.[btnStyle] ?? 'white',
    borderColor: colorSettings?.[btnStyle],
  }
}

const emailValidator = (email) => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/

  return emailRegex.test(email)
}

export {
  errorResponseHandler,
  toggleNextButtonText,
  toggleNextButtonColor,
  emailValidator,
}
