import { View, TouchableOpacity, Text } from 'react-native'
import { Entypo } from '@expo/vector-icons'
import { TextInput } from 'react-native-gesture-handler'
import styles from './NumericalTextinput.styles'

import {
  DEFAULT_COLOR_SETTINGS,
  DEFAULT_SUBTRACT_COUNT,
  DEFAULT_COUNT,
  DEFAULT_ADD_COUNT,
  DEFAULT_INPUT_VALIDATION,
  DEFAULT_ON_CHANGE_TEXT,
  DEFAULT_TITLE,
  DEFAULT_PLACEHOLDER,
  DEFAULT_HAS_VARIABLE_BUTTONS,
  CIRCLE_WITH_MINUS,
  CIRCLE_WITH_PLUS,
  TEXT_ALIGN_CENTER,
  KEYBOARD_TYPE_NUMERIC,
  RETURN_KEY_TYPE_DONE,
  DEFAULT_COLOR_BLACK,
} from './constants'

export default function NumericalTextInput({
  colorSettings = DEFAULT_COLOR_SETTINGS,
  subtractCount = DEFAULT_SUBTRACT_COUNT,
  addCount = DEFAULT_ADD_COUNT,
  inputValidation = DEFAULT_INPUT_VALIDATION,
  count = DEFAULT_COUNT,
  onChangeText = DEFAULT_ON_CHANGE_TEXT,
  title = DEFAULT_TITLE,
  isEditable = true,
  placeholder = DEFAULT_PLACEHOLDER,
  hasVariableButtons = DEFAULT_HAS_VARIABLE_BUTTONS,
  onFocus,
  relativeSide,
}) {
  return (
    <View style={styles.mainContainer}>
      <View>
        {Boolean(title) && (
          <Text style={styles.textTitle(colorSettings)}>{title}</Text>
        )}
      </View>
      <View style={styles.outerInputContainer}>
        {hasVariableButtons && (
          <TouchableOpacity
            style={styles.incrementButton}
            onPress={subtractCount}
          >
            <Entypo
              name={CIRCLE_WITH_MINUS}
              size={24}
              color={colorSettings?.selectable_btn_text_active_1}
            />
          </TouchableOpacity>
        )}

        <View style={styles.inputContainer(colorSettings)}>
          <TextInput
            value={count}
            textAlign={TEXT_ALIGN_CENTER}
            style={styles.input(colorSettings)}
            placeholder={placeholder || ''}
            onBlur={() => inputValidation(relativeSide)}
            onFocus={() => onFocus(relativeSide)}
            onChangeText={onChangeText}
            keyboardType={KEYBOARD_TYPE_NUMERIC}
            returnKeyType={RETURN_KEY_TYPE_DONE}
            editable={isEditable}
            placeholderTextColor={
              colorSettings?.text_input_placeholder_color_2 ??
              DEFAULT_COLOR_BLACK
            }
          />
        </View>
        {hasVariableButtons && (
          <TouchableOpacity style={styles.decrementButton} onPress={addCount}>
            <Entypo
              name={CIRCLE_WITH_PLUS}
              size={24}
              color={colorSettings?.selectable_btn_text_active_1}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

// NumericalTextInput.propTypes = {
//   colorSettings: PropTypes.shape({
//     selectable_btn_text_active_1: PropTypes.string,
//   }),
//   subtractCount: PropTypes.func,
//   count: PropTypes.number,
//   addCount: PropTypes.func,
//   inputValidation: PropTypes.func,
//   onChangeText: PropTypes.func,
//   title: PropTypes.string,
//   placeholder: PropTypes.string,
//   hasVariableButtons: PropTypes.bool,
// }
